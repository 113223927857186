import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InactiveUsersComponent } from './inactive-users.component';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [InactiveUsersComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class InactiveUsersModule {}
