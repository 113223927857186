import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import * as sellerSelectors from '../root-store/sellers-store/seller.selectors';
import * as sellerActions from '../root-store/sellers-store/seller.actions';
import { environment } from 'src/environments/sr/environment';

import { SellerState } from '../root-store/sellers-store/seller.reducer';
import { Seller } from '../shared/models/seller';
import { Update } from '@ngrx/entity';
import { fileValidator, imageValidator } from '../shared/utils/file-validator';
import { preventImageCache } from '../shared/utils/add-timestamp-prevent-cache';

@Component({
	selector: 'app-sellers',
	templateUrl: './sellers.component.html',
	styleUrls: ['./sellers.component.scss'],
})
export class SellersComponent implements OnInit {
	updateSellerModal: BsModalRef;
	deactivateSellerModal: BsModalRef;
	sellerForm: any;

	firstPageElementIndex = 0;

	sellerAdultImage: string;
	showExistingImage = true;

	selectedSellerImage: string;
	selectedSellerImageExtension: string;

	environment = environment;

	translate: any;
	translateFormValidationErrorMsgs: any;
	submitted: boolean;
	imageName: string = null;
	validFile: boolean;
	validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

	imageRestrictionMessage: string;

	sellersLoading$: Observable<boolean>;
	sellers$: Observable<Seller[]>;
	sellersSubscriber: Subscription;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'fullName', title: 'FULL_NAME', width: 30 },
		{ name: 'program', title: 'PROGRAM', width: 25 },
		{ name: 'function', title: 'FUNCTION', width: 25 },
		{ name: 'active', title: 'ACTIVE', width: 15 },
	];

	currentSeller: Seller = new Seller();

	constructor(
		private translateService: TranslateService,
		private store: Store<SellerState>,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private formBuilder: FormBuilder
	) {}

	ngOnInit() {
		this.translateService.get('SELLERS').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));

		this.translateService
			.get('SELLERS.IMAGE_RESTRICTION', {
				width: environment.mediaRestrictions.seller_images.resolution.width,
				height: environment.mediaRestrictions.seller_images.resolution.height,
			})
			.subscribe((text: string) => (this.imageRestrictionMessage = text));

		this.sellers$ = this.store.select(sellerSelectors.selectAllSellers);
		this.sellersLoading$ = this.store.select(sellerSelectors.selectSellersLoading);
		this.store.dispatch(sellerActions.loadSellers());
	}

	openSellersModal(updateSellerTemplate: TemplateRef<any>, user: Seller) {
		this.sellerAdultImage = null;
		this.submitted = false;
		this.showExistingImage = true;

		this.sellerForm = this.formBuilder.group(
			user
				? {
						...user,
						firstName: [user.firstName, [Validators.required, Validators.maxLength(50)]],
						lastName: [user.lastName, [Validators.required, Validators.maxLength(50)]],
						program: [user.program, [Validators.required, Validators.maxLength(255)]],
						email: [user.email, [Validators.maxLength(50), Validators.email]],
						msn: [user.msn, [Validators.maxLength(50)]],
						phone: [user.phone, [Validators.maxLength(50)]],
						mobile: [user.mobile, [Validators.maxLength(50)]],
						position: [user.position, [Validators.required, Validators.maxLength(50)]],
						username: [user.username, [Validators.required, Validators.maxLength(50)]],
						imageFile: '',
				  }
				: {
						...new Seller(),
						firstName: ['', [Validators.required, Validators.maxLength(50)]],
						lastName: ['', [Validators.required, Validators.maxLength(50)]],
						program: ['', [Validators.required, Validators.maxLength(255)]],
						email: ['', [Validators.maxLength(50), Validators.email]],
						msn: ['', [Validators.maxLength(50)]],
						phone: ['', [Validators.maxLength(50)]],
						mobile: ['', [Validators.maxLength(50)]],
						position: ['', [Validators.required, Validators.maxLength(50)]],
						username: ['', [Validators.required, Validators.maxLength(50)]],
				  }
		);

		if (this.sellerForm.value.adultImage) {
			this.sellerAdultImage = preventImageCache(
				`${environment.mediaLocation.sellerImages}seller_${user.id}.${user.adultImageExtension}`
			);
		}

		this.selectedSellerImage = null;

		this.updateSellerModal = this.modalService.show(updateSellerTemplate, {
			class: 'modal-md',
			ignoreBackdropClick: true,
		});
	}

	submitSellerForm() {
		this.submitted = true;
		if (this.sellerForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		if (this.sellerForm.value.id) {
			const tempSeller: Update<Seller> = {
				id: this.sellerForm.value.id,
				changes: this.sellerForm.value,
			};
			if (this.selectedSellerImage) {
				tempSeller.changes.imageFile = this.selectedSellerImage;
				tempSeller.changes.adultImageExtension = this.selectedSellerImageExtension;
				tempSeller.changes.adultImage = `seller_${tempSeller.id}.${this.selectedSellerImageExtension}`;
			}
			this.store.dispatch(sellerActions.updateSeller({ seller: tempSeller }));
		} else {
			this.sellerForm.value.adultImage = this.selectedSellerImage;
			this.sellerForm.value.adultImageExtension = this.selectedSellerImageExtension;
			this.store.dispatch(sellerActions.addSeller({ seller: this.sellerForm.value }));

			this.firstPageElementIndex = 0;
		}

		this.updateSellerModal.hide();
	}

	deactivateSelectedSeller(): void {
		this.currentSeller.active = !this.currentSeller.active;
		const tempSeller: Update<Seller> = {
			id: this.currentSeller.id,
			changes: this.currentSeller,
		};
		this.store.dispatch(sellerActions.updateSeller({ seller: tempSeller }));
		this.deactivateSellerModal.hide();
	}

	select(event: any, fileUploadViewChild: any) {
		const fileReader = new FileReader();
		for (const file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				const uploadedFile = fileReader.result
					.toString()
					.substring(fileReader.result.toString().lastIndexOf(',') + 1, fileReader.result.toString().length);
				const uploadedFileExtension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);

				const img = new Image();
				img.src = fileReader.result as string;
				img.onload = () => {
					if (fileValidator(file, 'size', environment.mediaRestrictions.seller_images)) {
						if (imageValidator(img, 'sizeLt', environment.mediaRestrictions.seller_images.resolution)) {
							this.selectedSellerImage = uploadedFile;
							this.selectedSellerImageExtension = uploadedFileExtension;
						} else {
							fileUploadViewChild.clear();
							this.translateService
								.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_LT', {
									width: environment.mediaRestrictions.seller_images.resolution.width,
									height: environment.mediaRestrictions.seller_images.resolution.height,
								})
								.subscribe((text: string) => this.toastr.warning(text));
							this.showExistingImage = true;
						}
					} else {
						fileUploadViewChild.clear();
						const size = environment.mediaRestrictions.seller_images.size / 1024 + 'KB';
						this.translateService
							.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
								size,
							})
							.subscribe((text: string) => this.toastr.warning(text));
						this.showExistingImage = true;
					}
				};
			};
		}
	}

	resetImageUpload() {
		this.selectedSellerImage = null;
		this.showExistingImage = true;
	}

	openConfirmationModal(deactivateSellerTemplate: TemplateRef<any>, user: Seller) {
		this.currentSeller = Object.assign({}, user);
		this.deactivateSellerModal = this.modalService.show(deactivateSellerTemplate, { class: 'modal-md' });
	}
}
