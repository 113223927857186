export class VendorlandGameResult {
	id: number;
	customerId: string;
	companyName: string;
	customerName: string;
	gameName: string;
	time: string;
	moves: string;
	totalScore: number;
	playedOn: string;
	isWinner: number;
}
