import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { PromotionsComponent } from './promotions.component';

@NgModule({
	declarations: [PromotionsComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class PromotionsModule {}
