import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { ProductImagesComponent } from './product-images/product-images.component';
import { ProductFilesComponent } from './product-files/product-files.component';
import { ProductLinksComponent } from './product-links/product-links.component';
import { ProductsComponent } from './products.component';

@NgModule({
	declarations: [ProductImagesComponent, ProductFilesComponent, ProductLinksComponent, ProductsComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class ProductsModule {}
