import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { GuestBookComponent } from './guest-book.component';

@NgModule({
	declarations: [GuestBookComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class GuestBookModule {}
