import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DashboardState } from '../root-store/dashboard-store/dashboard.reducer';

import * as dashboardSelectors from '../root-store/dashboard-store/dashboard.selectors';
import * as dashboardActions from '../root-store/dashboard-store/dashboard.actions';
import { generateRandomColour } from '../shared/utils/random-color-generator';
import { takeUntil } from 'rxjs/operators';

interface Year {
	name: string;
	value: number;
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
	translate: any;
	translateMonths: any;
	ngUnsubscribe: Subject<object> = new Subject();
	months: string[] = [];
	years: Year[] = [];
	selectedYear: Year = { name: 'currentYear', value: new Date().getFullYear() };

	browsers: { keys: string[]; values: unknown[] };
	browserChartData: {
		labels: string[];
		datasets: {
			data: any[];
			backgroundColor: string[];
			hoverBackgroundColor: string[];
		}[];
	};

	devices: any[][];
	deviceChartData: {
		labels: string[];
		datasets: {
			data: any;
			backgroundColor: string[];
			hoverBackgroundColor: string[];
		}[];
	};

	operatingSystems: { keys: string[]; values: unknown[] };
	osChartData: {
		labels: string[];
		datasets: {
			data: any[];
			backgroundColor: string[];
			hoverBackgroundColor: string[];
		}[];
	};

	visits: { keys: string[]; values: unknown[] };
	visitsChartData: {
		labels: string[];
		datasets: {
			label: string[];
			data: any[];
			fill: boolean;
			borderColor: string;
		}[];
	};

	dashboardLoading$: Observable<boolean>;

	constructor(private translateService: TranslateService, private dashboardStore: Store<DashboardState>) {}

	ngOnInit(): void {
		this.translateService.get('DASHBOARD').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('MONTHS').subscribe((resp: any) => {
			this.translateMonths = resp;
			for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
				this.months[monthIndex] = this.translateMonths[monthIndex];
			}

			this.months.unshift('');
		});

		let i = 0;
		for (let year = new Date().getFullYear(); year >= 2009; year--) {
			this.years[i] = { name: `${year}`, value: year };
			i++;
		}

		this.loadDataForSelectedYear(this.selectedYear);
	}

	loadDataForSelectedYear(selectedYear: Year) {
		this.dashboardStore.dispatch(dashboardActions.loadDashboardLogsPerYear({ year: selectedYear.value }));
		this.loadDashboardData();
		this.dashboardLoading$ = this.dashboardStore.select(dashboardSelectors.selectDashboardLogsLoading);
	}

	loadDashboardData(): void {
		this.dashboardStore
			.select(dashboardSelectors.selectAllBrowsers)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (data) {
					this.browsers = data;
					this.browserChartData = {
						labels: this.browsers.keys,
						datasets: [
							{
								data: this.browsers.values,
								backgroundColor: generateRandomColour(this.browsers.keys.length),
								hoverBackgroundColor: generateRandomColour(this.browsers.keys.length),
							},
						],
					};
				}
			});

		this.dashboardStore
			.select(dashboardSelectors.selectAllDevices)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (data) {
					this.devices = data;

					const labels: string[] = [];
					const dataSet: number[] = [];

					for (const device of this.devices) {
						labels.push(device[0]);
						dataSet.push(device[1]);
					}

					this.deviceChartData = {
						labels,
						datasets: [
							{
								data: dataSet,
								backgroundColor: generateRandomColour(labels.length),
								hoverBackgroundColor: generateRandomColour(labels.length),
							},
						],
					};
				}
			});

		this.dashboardStore
			.select(dashboardSelectors.selectAllOperatingSystems)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (data) {
					this.operatingSystems = data;
					this.osChartData = {
						labels: this.operatingSystems.keys,
						datasets: [
							{
								data: this.operatingSystems.values,
								backgroundColor: generateRandomColour(this.operatingSystems.keys.length),
								hoverBackgroundColor: generateRandomColour(this.operatingSystems.keys.length),
							},
						],
					};
				}
			});

		this.dashboardStore
			.select(dashboardSelectors.selectAllVisitsPerMonth)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (data) {
					this.visits = data;
					const parsedMonthNames: string[] = [];
					for (let i = 0; i < this.visits.keys.length; i++) {
						parsedMonthNames[i] = this.months[Number.parseInt(this.visits.keys[i], 0)];
					}
					this.visitsChartData = {
						labels: parsedMonthNames,
						datasets: [
							{
								label: this.translate.VISITS,
								data: data.values,
								fill: false,
								borderColor: '#4bc0c0',
							},
						],
					};
				}
			});
	}
	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
