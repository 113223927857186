import { createAction, props } from '@ngrx/store';

import { ServiceReport } from 'src/app/shared/models/service-report';
import { Update } from '@ngrx/entity';
import { ServiceReportsFlags } from 'src/app/shared/models/service-reports-flags';

export const loadServiceReports = createAction(
	'[Service Reports Page] Load Service Reports',
	props<{ reportsToLoad: ServiceReportsFlags; reportsLoadedFlags: ServiceReportsFlags }>()
);

export const loadServiceReportsSuccess = createAction(
	'[Service Reports API] Loaded Service Reports Success',
	props<{ serviceReports: ServiceReport[]; reportsLoadedFlags: ServiceReportsFlags }>()
);

export const loadServiceReportsFailure = createAction(
	'[Service Reports API] Loaded Service Reports Failure',
	props<{ error: any }>()
);

export const updateServiceReport = createAction(
	'[Service Report Page] Update Service Report',
	props<{ serviceReport: Update<ServiceReport> }>()
);

export const updateServiceReportSuccess = createAction(
	'[Service Report API] Update Service Report Success',
	props<{ serviceReport: Update<ServiceReport> }>()
);

export const updateServiceReportFailure = createAction(
	'[Service Report API] Update Service Report Failure',
	props<{ error: any }>()
);
