import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faReply } from '@fortawesome/free-solid-svg-icons';

import { Store } from '@ngrx/store';

import { ServiceReportState } from '../root-store/service-reports-store/service-report.reducer';
import { ServiceReplyState } from '../root-store/service-replies-store/service-reply.reducer';

import * as serviceReportSelectors from '../root-store/service-reports-store/service-report.selectors';
import * as serviceReportActions from '../root-store/service-reports-store/service-report.actions';
import * as serviceReplySelectors from '../root-store/service-replies-store/service-reply.selectors';
import * as serviceReplyActions from '../root-store/service-replies-store/service-reply.actions';

import { Observable, Subject } from 'rxjs';
import { ServiceReport } from '../shared/models/service-report';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ServiceReply } from '../shared/models/service-reply';
import { Update } from '@ngrx/entity';
import { ServiceStatus } from '../shared/enums/serviceStatus.enum';
import { Permissions } from '../shared/enums/permissions.enum';
import { AuthService } from '../core/authentication/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Table } from 'primeng/table';
import { ServiceReportsFlags } from '../shared/models/service-reports-flags';

@Component({
	selector: 'app-service-reports',
	templateUrl: './service-reports.component.html',
	styleUrls: ['./service-reports.component.scss'],
})
export class ServiceReportsComponent implements OnInit, OnDestroy {
	@ViewChild('serviceReportsTable') table: Table;
	viewServiceReportModal: BsModalRef;
	viewServiceRepliesModal: BsModalRef;
	confirmStatusChangeModal: BsModalRef;
	ngUnsubscribe: Subject<object> = new Subject();
	perms = {};

	translate: any;
	faReply = faReply;

	openReports: boolean;
	pendingReports: boolean;
	closedReports: boolean;

	loadedFlags: ServiceReportsFlags;

	showAddReplyCont = false;
	serviceReplyText = '';
	selectedStatus = null;

	currentPageFirstElementNumber = 0;

	enumStatuses = ServiceStatus;

	statuses = [
		{ status: this.enumStatuses.OPEN, name: 'Open', title: 'IS_OPEN' },
		{ status: this.enumStatuses.PENDING, name: 'Pending', title: 'IS_PENDING' },
		{ status: this.enumStatuses.CLOSED, name: 'Closed', title: 'IS_CLOSED' },
	];

	serviceReports$: Observable<ServiceReport[]>;
	serviceReportsLoading$: Observable<boolean>;
	serviceReplies$: Observable<ServiceReply[]>;
	serviceRepliesLoading$: Observable<boolean>;

	filteredServiceReports: ServiceReport[] = [];

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'caseNumber', title: 'LOGIN_PASS', width: 15 },
		{ name: 'subject', title: 'SHORT_DESC', width: 30 },
		{ name: 'contactPerson', title: 'CONTACT_PERSON', width: 18 },
		{ name: 'date', title: 'REPORT_DATE', width: 12 },
		{ name: 'status', title: 'STATUS', width: 13 },
		{ name: '', title: '', width: 7 },
	];
	selectedServiceReport: ServiceReport = null;

	constructor(
		private translateService: TranslateService,
		private serviceReportStore: Store<ServiceReportState>,
		private serviceRepliesStore: Store<ServiceReplyState>,
		private modalService: BsModalService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.setPermissions();

		this.translateService.get('SERVICE_REPORTS').subscribe((resp: any) => (this.translate = resp));

		this.serviceReportStore
			.select(serviceReportSelectors.selectServiceReportsFlags)
			.pipe(take(1))
			.subscribe(loadedFlags => (this.loadedFlags = loadedFlags));

		this.openReports = true;
		this.pendingReports = true;
		this.closedReports = false;

		this.serviceReportStore
			.select(serviceReportSelectors.selectAllServiceReports)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(reports => {
				if (!this.openReports) {
					reports = reports.filter(report => report.status !== this.enumStatuses.OPEN);
				}
				if (!this.pendingReports) {
					reports = reports.filter(report => report.status !== this.enumStatuses.PENDING);
				}
				if (!this.closedReports) {
					reports = reports.filter(report => report.status !== this.enumStatuses.CLOSED);
				}
				this.filteredServiceReports = reports;
			});

		this.serviceReportsLoading$ = this.serviceReportStore.select(serviceReportSelectors.selectServiceReportsLoading);

		if (!this.filteredServiceReports.length) {
			this.serviceReportStore.dispatch(
				serviceReportActions.loadServiceReports({
					reportsToLoad: {
						open: cloneDeep(this.openReports),
						pending: cloneDeep(this.pendingReports),
						closed: cloneDeep(this.closedReports),
					},
					reportsLoadedFlags: this.loadedFlags,
				})
			);
		}
	}

	setPermissions() {
		this.perms[Permissions.ServiceReportsClosed] = this.authService.hasPermission(Permissions.ServiceReportsClosed);
		this.perms[Permissions.ServiceReportsOpen] = this.authService.hasPermission(Permissions.ServiceReportsOpen);
		this.perms[Permissions.ServiceReportsQueue] = this.authService.hasPermission(Permissions.ServiceReportsQueue);
	}

	filterServiceReports(checkboxName: string) {
		const currentPageIndex = this.currentPageFirstElementNumber / this.table.rows + 1;
		const totalNumberOfPages = Math.floor(this.filteredServiceReports.length / this.table.rows + 1);
		const totalNumberOfItems = this.filteredServiceReports.length;
		let numberOfPagesForStatus: number;

		if (this.loadedFlags[checkboxName]) {
			if (
				(checkboxName === 'open' && !this.openReports) ||
				(checkboxName === 'pending' && !this.pendingReports) ||
				(checkboxName === 'closed' && !this.closedReports)
			) {
				this.filteredServiceReports = this.filteredServiceReports.filter(
					report => report.status !== this.enumStatuses[checkboxName.toUpperCase()]
				);
				numberOfPagesForStatus =
					Math.floor((totalNumberOfItems - this.filteredServiceReports.length) / this.table.rows) + 1;
				if (totalNumberOfPages - numberOfPagesForStatus < currentPageIndex) {
					this.currentPageFirstElementNumber = 0;
				}
			} else {
				this.serviceReportStore
					.select(serviceReportSelectors.selectReportsByStatus, {
						status: this.enumStatuses[checkboxName.toUpperCase()],
					})
					.pipe(take(1))
					.subscribe(reports => {
						this.filteredServiceReports = [...this.filteredServiceReports, ...reports];
					});
			}
		} else {
			this.loadedFlags = { ...this.loadedFlags, [checkboxName]: true };
			this.serviceReportStore.dispatch(
				serviceReportActions.loadServiceReports({
					reportsToLoad: {
						open: checkboxName === 'open',
						pending: checkboxName === 'pending',
						closed: checkboxName === 'closed',
					},
					reportsLoadedFlags: this.loadedFlags,
				})
			);
		}
	}

	getStatusCode(status: string) {
		switch (status) {
			case this.enumStatuses.OPEN:
				return this.translate.IS_OPEN;
			case this.enumStatuses.PENDING:
				return this.translate.IS_PENDING;
			case this.enumStatuses.CLOSED:
				return this.translate.IS_CLOSED;
		}
	}

	openReportModal(viewServiceReportTemplate: TemplateRef<any>, serviceReport: ServiceReport) {
		this.selectedServiceReport = { ...serviceReport };
		this.viewServiceReportModal = this.modalService.show(viewServiceReportTemplate, { class: 'modal-lg' });
	}

	openServiceRepliesModal(viewServiceRepliesTemplate: TemplateRef<any>, serviceReport: ServiceReport) {
		this.selectedServiceReport = { ...serviceReport };
		this.showAddReplyCont = false;
		this.serviceReplyText = '';

		this.serviceReplies$ = this.serviceRepliesStore.select(serviceReplySelectors.selectAllServiceReplies);
		this.serviceRepliesLoading$ = this.serviceRepliesStore.select(serviceReplySelectors.selectServiceRepliesLoading);
		this.serviceRepliesStore.dispatch(serviceReplyActions.loadServiceReplies({ id: serviceReport.id }));

		this.viewServiceRepliesModal = this.modalService.show(viewServiceRepliesTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	submitNewReply() {
		const serviceReply: ServiceReply = {
			id: 0,
			idDeviceFailure: this.selectedServiceReport.id,
			text: this.serviceReplyText,
			date: '',
			author: '',
		};
		this.serviceRepliesStore.dispatch(serviceReplyActions.addServiceReply({ serviceReply }));
	}

	openConfirmStatusModal(confirmStatusChangeTemplate: TemplateRef<any>, report: ServiceReport) {
		this.selectedServiceReport = { ...report };
		this.confirmStatusChangeModal = this.modalService.show(confirmStatusChangeTemplate, {
			class: 'status-change-modal',
		});
	}

	submitStatusChange() {
		const tempPromotion: Update<ServiceReport> = {
			id: this.selectedServiceReport.id,
			changes: { ...this.selectedServiceReport, status: this.selectedStatus },
		};
		this.serviceReportStore.dispatch(serviceReportActions.updateServiceReport({ serviceReport: tempPromotion }));
		this.confirmStatusChangeModal.hide();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
