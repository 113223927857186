import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ExchangeRate } from 'src/app/shared/models/exchange-rate';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import * as ExchangeRateActions from './exchange-rates.actions';

export const exchangeRatesFeatureKey = 'exchangeRates';

export interface ExchangeRateState extends EntityState<ExchangeRate> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ExchangeRate> = createEntityAdapter<ExchangeRate>({
	selectId: (exchangeRate: ExchangeRate) => exchangeRate.currencyCode,
});

export const initialState: ExchangeRateState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const exchangeRatesReducer = createReducer(
	initialState,
	on(ExchangeRateActions.loadExchangeRates, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ExchangeRateActions.loadExchangeRatesSuccess, (state, action) =>
		adapter.setAll(action.exchangeRates, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ExchangeRateActions.loadExchangeRatesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ExchangeRateState | undefined, action: Action) {
	return exchangeRatesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
