import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as productFilterActions from './product-filter.actions';
import { SpecialPriceService } from 'src/app/core/services/special-price.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ProductFilterEffects {
	constructor(private actions$: Actions, private specialPriceService: SpecialPriceService) {}

	loadProductFilters$ = createEffect(() =>
		this.actions$.pipe(
			ofType(productFilterActions.loadProductFilters),
			mergeMap(action =>
				this.specialPriceService.getProductFilters(action.listFilter).pipe(
					map(productFilters => productFilterActions.loadProductFiltersSuccess({ productFilters })),
					catchError(error => of(productFilterActions.loadProductFiltersFailure({ error })))
				)
			)
		)
	);
}
