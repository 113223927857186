import { manufacturersFeatureKey, ManufacturerState, selectAll } from './manufacturer.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectManufacturerState = createFeatureSelector<ManufacturerState>(manufacturersFeatureKey);

export const selectAllManufacturers = createSelector(selectManufacturerState, selectAll);

export const selectManufacturersLoading = createSelector(
	selectManufacturerState,
	state => state.statusState === LoadingState.LOADING
);

export const selectManufacturersError = createSelector(selectManufacturerState, (state: ManufacturerState) =>
	getError(state.statusState)
);
