<ng-container *ngIf="translate && { promSlidersLoading: promSlidersLoading$ | async } as observables">
	<div class="prom-sliders-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openPromSliderModal(updatePromSliderTemplate, null)"
			[disabled]="observables.promSlidersLoading"
			data-tid="addPromotionBanner"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.promSlidersLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.promSlidersLoading">
			<p-table
				#promsliderstable
				[value]="promSliders$ | async"
				[resizableColumns]="true"
				[autoLayout]="true"
				class="sliders-prime-table"
				[paginator]="true"
				[rows]="10"
				[(first)]="firstPageElementIndex"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				sortField="id"
				[sortOrder]="-1"
				[globalFilterFields]="['id', 'name', 'url', 'activeSrb', 'orderNumber', 'isPosted', 'image1Url', 'image2Url']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="promsliderstable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" [style.width.%]="4" class="text-center">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'name'" [style.width.%]="12">
							{{ translate.NAME }}
							<p-sortIcon [field]="'name'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'url'" [style.width.%]="15">
							{{ translate.WEB_ADDRESS }}
							<p-sortIcon [field]="'url'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'activeSrb'" [style.width.%]="6" class="text-center">
							{{ translate.ACTIVE }}
							<p-sortIcon [field]="'activeSrb'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'orderNumber'" [style.width.%]="6" [style.white-space]="'normal'">
							{{ translate.ORDER_NO }}
							<p-sortIcon [field]="'orderNumber'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'isPosted'" [style.width.%]="6" class="text-center">
							{{ translate.POSTED }}
							<p-sortIcon [field]="'isPosted'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'image1Url'" [style.width.%]="13">
							{{ translate.DESKTOP_IMG }}
							<p-sortIcon [field]="'image1Url'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'image2Url'" [style.width.%]="13">
							{{ translate.MOBILE_IMG }}
							<p-sortIcon [field]="'image2Url'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'location'" [style.width.%]="13">
							{{ translate.LOCATION }}
							<p-sortIcon [field]="'location'"></p-sortIcon>
						</th>
						<th [style.width.%]="6">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-slide let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ slide.id }}</b>
						</td>
						<td>{{ slide.name }}</td>
						<td class="line-breaker">{{ slide.url }}</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="slide.activeSrb"></fa-icon></td>
						<td>{{ slide.orderNumber }}</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="slide.isPosted"></fa-icon></td>
						<td class="line-breaker">{{ slide.image1Url }}</td>
						<td class="line-breaker">{{ slide.image2Url }}</td>
						<td>{{ translate[slide.location | uppercase] }}</td>

						<td class="text-right">
							<button
								type="button"
								class="button button-gray"
								(click)="openPromSliderModal(updatePromSliderTemplate, slide)"
								data-tid="editPromotionBanner"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- Add/Edit promotion slider modal -->
	<ng-template #updatePromSliderTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left" *ngIf="promSliderForm.value.id">{{ translate.EDIT_PROM_SLIDER }}</h4>
			<h4 class="modal-title pull-left" *ngIf="!promSliderForm.value.id">{{ translate.ADD_PROM_SLIDER }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updatePromSliderModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="promSliderForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.NAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.name.errors }"
							formControlName="name"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.name.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.WEB_ADDRESS }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.url.errors }"
							formControlName="url"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.url.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.ACTIVE }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="promSliderForm.value.activeSrb">
							<input
								type="checkbox"
								formControlName="activeSrb"
								[ngClass]="{ checked: promSliderForm.value.activeSrb }"
								id="activeButton"
							/>
							<span class="chbx-checkmark">
								<svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon>
							</span>
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.IS_MODAL }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [ngClass]="{ checked: promSliderForm.value.isModal }">
							<input type="checkbox" formControlName="isModal" [ngClass]="{ checked: promSliderForm.value.isModal }" />

							<span class="chbx-checkmark">
								<svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon>
							</span>
						</label>
					</div>
				</div>
				<ng-container
					*ngIf="
						promSliderForm.controls.isModal.value && {
							selectedPromotionSliderLoading: selectedPromotionSliderLoading$ | async,
							selectedPromotionSliderFileLoading: selectedPromotionSliderFileLoading$ | async
						} as selectedSliderObservables
					"
				>
					<div class="row">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.CHOOSE_MODAL_IMAGES }}: <span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12 mb-2 modal-image-buttons">
							<ng-container *ngIf="!changeOrder">
								<label for="add-modal-img" class="button mr-2 add-images">
									<input
										type="file"
										[ngModelOptions]="{ standalone: true }"
										[(ngModel)]="uploadImgs"
										accept="image/*"
										id="add-modal-img"
										(change)="selectModalImages($event)"
										hidden
										multiple
									/>
									<div class="button-text">
										<i class="pi pi-plus"></i>
										{{ selectedImages?.length ? translate.ADD_MORE_IMAGES : translate.ADD_IMAGES }}
									</div>
								</label>
							</ng-container>

							<button
								*ngIf="selectedPromoSlider?.productActionSliderItems?.length && !changeOrder"
								(click)="setModalImagesOrder()"
								type="button"
								[disabled]="selectedSliderObservables.selectedPromotionSliderLoading"
								class="button-height-match button button-gray mr-2"
							>
								{{ translate.EDIT_IMAGES_ORDER }}
							</button>

							<ng-container *ngIf="!selectedImages.length && changeOrder">
								<button
									type="button"
									class="button-height-match button button-gray mr-2 mb-2"
									(click)="submitNewOrder()"
									[disabled]="selectedSliderObservables.selectedPromotionSliderLoading || !isOrderChanged"
								>
									{{ translate.SUBMIT_NEW_ORDER }}
								</button>

								<button
									type="button"
									class="button-height-match button button-gray mr-2 mb-2"
									(click)="cancelNewOrder()"
								>
									{{ translate.CANCEL_ORDERING }}
								</button>
							</ng-container>

							<button
								*ngIf="selectedImages?.length"
								type="button"
								class="button-height-match button button-gray"
								(click)="resetImageUpload()"
							>
								{{ 'CANCEL' | translate }}
							</button>
						</div>
					</div>
					<div
						class="loading-cont"
						*ngIf="selectedSliderObservables.selectedPromotionSliderLoading; else imagesDisplayContainer"
					>
						<div class="spinner-border"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>
					<ng-template #imagesDisplayContainer>
						<div
							class="row"
							*ngIf="selectedPromoSlider?.productActionSliderItems?.length; else newPromoSlideModalImages"
						>
							<div class="modal-image-upload-container">
								<ng-container *ngFor="let item of selectedPromoSlider?.productActionSliderItems; let i = index">
									<div class="col-lg-12 col-sm-10">
										<div class="image-cont">
											<fa-icon
												icon="times"
												class="remove"
												title="{{ 'REMOVE' | translate }}"
												(click)="deleteModalImage(item)"
											></fa-icon>

											<img src="{{ environment.mediaLocation.promotionSlider.modalImagePath }}{{ item.imageUrl }}" />

											<span class="image-name">
												{{ item.imageUrl }}
											</span>

											<div class="move-icons" *ngIf="changeOrder">
												<button (click)="moveItem('existing', 'up', item, i)" [disabled]="i == 0">
													<fa-icon [icon]="faArrowUp"></fa-icon>
												</button>
												<br />
												<button
													(click)="moveItem('existing', 'down', item, i)"
													[disabled]="i == selectedPromoSlider.productActionSliderItems.length - 1"
												>
													<fa-icon [icon]="faArrowDown"></fa-icon>
												</button>
											</div>

											<b class="order-number">{{ item.order }}.</b>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
						<ng-template #newPromoSlideModalImages>
							<div class="row" *ngIf="selectedImages?.length">
								<div class="modal-image-upload-container">
									<ng-container *ngFor="let item of selectedImages; let i = index">
										<div class="col-lg-12 col-sm-10">
											<div class="image-cont">
												<fa-icon
													icon="times"
													class="remove"
													(click)="removeSelectedModalImage(i)"
													title="{{ 'REMOVE' | translate }}"
												></fa-icon>

												<img [src]="selectedImagesDisplay[i]" />

												<span class="image-name">
													{{ item.imageUrl }}
												</span>

												<div class="move-icons">
													<button (click)="moveItem('new', 'up', item, i)" [disabled]="i == 0">
														<fa-icon [icon]="faArrowUp"></fa-icon>
													</button>
													<br />
													<button
														(click)="moveItem('new', 'down', item, i)"
														[disabled]="i == selectedImages.length - 1"
													>
														<fa-icon [icon]="faArrowDown"></fa-icon>
													</button>
												</div>

												<b class="order-number">{{ item.order }}.</b>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-template>
					</ng-template>

					<div class="row">
						<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.UPLOAD_MODAL_DOCUMENT }} :</div>
						<div class="col-lg-8 col-sm-12 modal-file-cont">
							<p-fileUpload
								#modalFileUpload
								name="modalFile"
								accept=".docx, .doc, .xls, .xlsx, application/pdf, .txt, .zip, .rar"
								chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
								(onSelect)="select($event, 'modalFile', modalFileUpload)"
								(onRemove)="remove('modalFile')"
								[showUploadButton]="false"
								[showCancelButton]="false"
							>
							</p-fileUpload>

							<div
								class="loading-cont"
								*ngIf="selectedSliderObservables.selectedPromotionSliderFileLoading; else fileDisplay"
							>
								<div class="spinner-border"></div>
								<span>{{ 'LOADING' | translate }}</span>
							</div>
							<ng-template #fileDisplay>
								<div class="documents-cont mb-4">
									<div *ngIf="!modalFile && documentUrl">
										<a
											class="file-link"
											href="{{ documentUrl }}"
											download="{{ fileNameForDisplay }}{{ modalFileExtension }}"
											target="_blank"
											title="{{ 'DOWNLOAD' | translate }} '{{ fileNameForDisplay }}{{ modalFileExtension }}'"
										>
											<div class="document-card">
												<svg-icon key="pdf" *ngIf="modalFileExtension === '.pdf'"></svg-icon>
												<svg-icon key="zip" *ngIf="modalFileExtension === '.zip' || modalFileExtension === '.rar'">
												</svg-icon>
												<svg-icon key="text" *ngIf="modalFileExtension !== '.pdf' && modalFileExtension !== '.zip'">
												</svg-icon>
												<div class="modalFile-info">
													<div class="modalFile-title">
														<span class="file-title">{{ fileNameForDisplay }}</span>
														<span class="file-ext">{{ modalFileExtension }}</span>
													</div>
												</div>

												<button
													class="button button-gray delete-file-button"
													(click)="$event.preventDefault(); deleteModalFile()"
													title="{{ 'REMOVE' | translate }}"
												>
													<fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
												</button>
											</div>
										</a>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</ng-container>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.ORDER_NO }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="number"
							class="form-control"
							numbersOnly
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.orderNumber.errors }"
							formControlName="orderNumber"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.orderNumber.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.POSTED }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="promSliderForm.value.isPosted">
							<input
								type="checkbox"
								formControlName="isPosted"
								[ngClass]="{ checked: promSliderForm.value.isPosted }"
								id="postedButton"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.LOCATION }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							#locationDropdown
							styleClass="form-control"
							formControlName="location"
							[options]="options"
							(onChange)="locationDropdownChange($event, desktopImageViewChild, mobileImageViewChild)"
							data-tid="chooseDesktopImage"
						></p-dropdown>

						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.location.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3" *ngIf="selectedLocation === promotionSliderLocationEnum.PRODUCTLIST">
					<div class="col-lg-4 col-sm-12 nowrap">
						{{ translate.PATH }}
						<span class="location-info">
							<em> ({{ translate.OPTIONAL }}) </em>
						</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="path"
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.path.errors }"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.path.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<p *ngIf="selectedLocation === promotionSliderLocationEnum.PRODUCTLIST" class="location-info mb-3">
					<b>{{ translate.NOTE }}</b> {{ translate.SLIDER_LOCATION_INFO }}
				</p>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.DESKTOP_IMG }}
						{{ desktopImageMediaRestrictions.width }}×
						{{ desktopImageMediaRestrictions.height }}
						(W×H): <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#desktopImageViewChild
							name="desktopImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="select($event, 'desktopImage', desktopImageViewChild)"
							(onRemove)="remove('desktopImage')"
							[ngClass]="{
								'is-invalid': submitted && !selectedPromotionSliderDesktopImage && !promotionSliderDesktopImage
							}"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedPromotionSliderDesktopImage && promotionSliderDesktopImage"
							[src]="promotionSliderDesktopImage"
							class="modal-responsive-image"
							alt="desktop image"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && !selectedPromotionSliderDesktopImage && !promotionSliderDesktopImage"
							class="invalid-feedback"
							[errors]="customErrors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.MOBILE_IMG }} {{ mobileImageMediaRestrictions.width }}×
						{{ mobileImageMediaRestrictions.height }}
						(W×H): <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#mobileImageViewChild
							name="mobileImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="select($event, 'mobileImage', mobileImageViewChild)"
							(onRemove)="remove('mobileImage')"
							[ngClass]="{
								'is-invalid': submitted && !selectedPromotionSliderMobileImage && !promotionSliderMobileImage
							}"
							data-tid="chooseMobileImage"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedPromotionSliderMobileImage && promotionSliderMobileImage"
							[src]="promotionSliderMobileImage"
							class="modal-responsive-image"
							alt="mobile image"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && !selectedPromotionSliderMobileImage && !promotionSliderMobileImage"
							class="invalid-feedback"
							[errors]="customErrors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FROM_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="fromDate"
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.fromDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.FROM_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.fromDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.TO_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="toDate"
							[ngClass]="{ 'is-invalid': submitted && promSliderForm.controls.toDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.TO_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && promSliderForm.controls.toDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updatePromSliderModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="
							promSliderForm.dirty ||
							selectedPromotionSliderDesktopImage ||
							selectedPromotionSliderMobileImage ||
							modalFile
								? false
								: true || observables.promSlidersLoading
						"
						(click)="submitPromSliderForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
