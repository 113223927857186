import { customersFeatureKey, CustomerState, selectAll } from './customer.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { Customer } from 'src/app/shared/models/customer';

export const selectCustomerState = createFeatureSelector<CustomerState>(customersFeatureKey);

export const selectAllCustomers = createSelector(selectCustomerState, selectAll);

export const selectCustomersLoading = createSelector(
	selectCustomerState,
	state => state.statusState === LoadingState.LOADING
);

export const selectCustomersError = createSelector(selectCustomerState, (state: CustomerState) =>
	getError(state.statusState)
);

export const selectCustomerInfoById = createSelector(selectCustomerState, selectAll);

export const selectCustomerInfoByIdLoading = createSelector(
	selectCustomerState,
	state => state.selectedCustomerStatusState === LoadingState.LOADING
);

export const selectCustomerInfoByIdError = createSelector(selectCustomerState, (state: CustomerState) =>
	getError(state.selectedCustomerStatusState)
);
export const selectRemoveCustomerLogo = createSelector(selectCustomerState, selectAll);

export const selectCustomerById = createSelector(selectCustomerInfoById, (customer: Customer[], params: any) => {
	return customer.find(c => c.no === params.no);
});
