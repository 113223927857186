import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromInactiveUsersActions from './inactive-users.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { InactiveUsersService } from 'src/app/core/services/inactive-users.service';

@Injectable()
export class InactiveUsersEffects {
	constructor(private actions$: Actions, private inactiveUsersService: InactiveUsersService) {}

	loadInactiveUsersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromInactiveUsersActions.loadInactiveUsers),
			mergeMap(action =>
				this.inactiveUsersService.getInactiveUsers(action.fromDate).pipe(
					map(users => fromInactiveUsersActions.loadInactiveUsersSuccess({ users })),
					catchError(error => of(fromInactiveUsersActions.loadInactiveUsersFailure({ error })))
				)
			)
		)
	);
}
