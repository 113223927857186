import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromPriceListActions from './price-list.actions';
import { PriceListService } from '../../core/services/price-list.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PriceListEffects {
	constructor(
		private actions$: Actions,
		private priceListService: PriceListService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadPriceListEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPriceListActions.loadPriceList),
			mergeMap(() =>
				this.priceListService.getPriceList().pipe(
					map(PriceList => fromPriceListActions.loadPriceListSuccess({ PriceList })),
					catchError(error => of(fromPriceListActions.loadPriceListFailure({ error })))
				)
			)
		)
	);

	addPriceListEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPriceListActions.addPriceList),
			mergeMap(action =>
				this.priceListService.addPriceList(action.PriceList).pipe(
					switchMap(PriceList =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromPriceListActions.addPriceListSuccess({ PriceList });
							}),
							catchError(error => of(fromPriceListActions.addPriceListFailure({ error })))
						)
					)
				)
			)
		)
	);

	updatePriceListEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPriceListActions.updatePriceList),
			mergeMap(action =>
				this.priceListService.updatePriceList(action.PriceList).pipe(
					switchMap(PriceList =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromPriceListActions.updatePriceListSuccess({ PriceList });
							}),
							catchError(error => of(fromPriceListActions.updatePriceListFailure({ error })))
						)
					)
				)
			)
		)
	);
}
