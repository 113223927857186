import { Action, createReducer, on } from '@ngrx/store';
import * as TargetActions from './targets.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Target } from 'src/app/shared/models/target';

export const targetFeatureKey = 'target';

export interface TargetState extends EntityState<Target> {
	statusState: StatusState;
	emptyResult: boolean;
}

export const adapter: EntityAdapter<Target> = createEntityAdapter<Target>();

export const initialState: TargetState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	emptyResult: false,
});

const targetReducer = createReducer(
	initialState,

	on(TargetActions.loadTargets, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(TargetActions.loadTargetsSuccess, (state, action) => {
		if (action.targets.length > 0) {
			return adapter.addMany(action.targets, {
				...state,
				emptyResult: false,
				statusState: LoadingState.LOADED,
			});
		} else {
			return {
				...state,
				emptyResult: true,
				statusState: LoadingState.LOADED,
			};
		}
	}),
	on(TargetActions.loadTargetsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(TargetActions.addTarget, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(TargetActions.addTargetSuccess, (state, action) =>
		adapter.addOne(action.target, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(TargetActions.addTargetFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(TargetActions.updateTarget, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(TargetActions.updateTargetSuccess, (state, action) =>
		adapter.updateOne(action.target, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(TargetActions.updateTargetFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(TargetActions.setEmptyResultFlag, (state, action) => {
		return {
			...state,
			emptyResult: action.emptyResultFlag,
			statusState: LoadingState.LOADED,
		};
	})
);

export function reducer(state: TargetState | undefined, action: Action) {
	return targetReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
