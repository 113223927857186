import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import * as administratorSelectors from '../root-store/administrators-store/administrator.selectors';
import * as administratorActions from '../root-store/administrators-store/administrator.actions';
import { AdministratorState } from '../root-store/administrators-store/administrator.reducer';

import * as groupSelectors from '../root-store/groups-store/group.selectors';
import * as groupActions from '../root-store/groups-store/group.actions';
import { GroupState } from '../root-store/groups-store/group.reducer';

import * as featureSelectors from '../root-store/features-store/feature.selectors';
import * as featureActions from '../root-store/features-store/feature.actions';
import { FeatureState } from '../root-store/features-store/feature.reducer';

import { Administrator } from '../shared/models/administrator';
import { Update } from '@ngrx/entity';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { Feature } from '../shared/models/feature';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
	selector: 'app-administrators',
	templateUrl: './administrators.component.html',
	styleUrls: ['./administrators.component.scss'],
})
export class AdministratorsComponent implements OnInit, OnDestroy {
	updateAdministratorModal: BsModalRef;
	administratorForm: any;

	firstPageElementIndex = 0;

	translate: any;
	translateFeatures: any;
	translateErrors: any;
	translateFormValidationErrorMsgs: any;

	ngUnsubscribe: Subject<object> = new Subject();
	currentAdmin: any = null;

	usernameInputField = new Subject<string>();
	username$: Observable<boolean>;
	usernameFieldDisabled = false;
	userId: number;
	isUserAvailableMessage = '';
	isUserAvailableMessageClass = '';

	administratorsLoading$: Observable<boolean>;
	administrators$: Observable<Administrator[]>;

	features: Feature[];
	featuresLoading$: Observable<boolean>;
	featuresChanged: boolean;
	submitted: boolean;

	groupDropdown: SelectItem[];

	currentAdministrator: Administrator = new Administrator();

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'name', title: 'FNAME', width: 15 },
		{ name: 'surname', title: 'LNAME', width: 17 },
		{ name: 'email', title: 'EMAIL', width: 30 },
		{ name: 'username', title: 'UNAME', width: 14 },
		{ name: 'groupName', title: 'GROUP', width: 15 },
		{ name: '', title: '', width: 5 },
	];

	constructor(
		private translateService: TranslateService,
		private administratorStore: Store<AdministratorState>,
		private groupStore: Store<GroupState>,
		private featureStore: Store<FeatureState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('ADMINISTRATORS').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('GROUPS').subscribe((resp: any) => (this.translateFeatures = resp));
		this.translateService.get('ERROR').subscribe((resp: any) => (this.translateErrors = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));

		this.administrators$ = this.administratorStore.select(administratorSelectors.selectAllAdministrators);
		this.administratorsLoading$ = this.administratorStore.select(administratorSelectors.selectAdministratorsLoading);

		this.administratorStore.dispatch(administratorActions.loadAdministrators());

		this.featureStore
			.select(featureSelectors.selectAllFeatures)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(features => (this.features = cloneDeep(features)));

		this.featuresLoading$ = this.featureStore.select(featureSelectors.selectFeaturesLoading);
		this.featureStore.dispatch(featureActions.loadFeatures());

		this.username$ = this.usernameInputField.pipe(
			debounceTime(250),
			distinctUntilChanged(),
			switchMap(username =>
				this.groupStore.select(administratorSelectors.selectAdministratorByUsername, {
					username,
					id: this.userId,
				})
			)
		);

		this.groupStore
			.select(groupSelectors.selectAllGroups)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(groups => {
				if (!groups.length) {
					this.groupStore.dispatch(groupActions.loadGroups());
				} else {
					this.groupDropdown = groups.map(group => ({
						label: group.title,
						value: group.id,
					}));
					this.groupDropdown.unshift({
						label: this.translate.SELECT_GROUP,
						value: null,
					});
				}
			});

		this.username$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(found => {
			if (found) {
				this.isUserAvailableMessage = `${this.translate.ERROR_EXISTING_USERNAME}`;
				this.isUserAvailableMessageClass = 'username-exists';
				this.usernameFieldDisabled = true;
			} else {
				this.isUserAvailableMessage = `${this.translate.AVAILABLE_USERNAME}`;
				this.isUserAvailableMessageClass = 'username-available';
				this.usernameFieldDisabled = false;
			}
		});
	}

	openAdministratorsModal(updateAdministratorTemplate: TemplateRef<any>, user: Administrator) {
		this.submitted = false;
		this.usernameFieldDisabled = false;
		this.userId = 0;
		this.isUserAvailableMessage = '';
		this.administratorForm = this.formBuilder.group(
			user
				? {
						...user,
						name: [user.name, [Validators.required, Validators.maxLength(64)]],
						surname: [user.surname, [Validators.required, Validators.maxLength(64)]],
						username: [user.username, [Validators.required, Validators.maxLength(32)]],
						email: [user.email, [Validators.required, Validators.email, Validators.maxLength(64)]],
						idGroup: [user.idGroup, [Validators.required]],
				  }
				: {
						...new Administrator(),
						name: ['', [Validators.required, Validators.maxLength(64)]],
						surname: ['', [Validators.required, Validators.maxLength(64)]],
						username: ['', [Validators.required, Validators.maxLength(32)]],
						email: ['', [Validators.required, Validators.email, Validators.maxLength(64)]],
						idGroup: ['', [Validators.required]],
				  }
		);

		if (user) {
			this.userId = user.id;
		}

		this.updateAdministratorModal = this.modalService.show(updateAdministratorTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});

		if (this.userId) {
			this.getAdditionalFeaturesArrayForUser(this.administratorForm.value.id, this.administratorForm.value.idGroup);
		} else {
			this.getAdditionalFeaturesArrayForGroup(this.administratorForm.value.idGroup);
		}
	}

	filterUsernames(username: string) {
		this.usernameInputField.next(username);
	}

	/**
	 * Updates group label in modal by group id.
	 */
	groupNameUpdate() {
		const selectedGroup = this.groupDropdown.find(group => group.value === this.administratorForm.value.idGroup);
		this.administratorForm.value.groupName = selectedGroup.label;
	}

	/**
	 * Loads features for selected group, selects them and disables them.
	 * @param groupId Id of the user group.
	 */
	getAdditionalFeaturesArrayForGroup(groupId: number) {
		if (groupId) {
			this.groupStore
				.select(groupSelectors.selectGroupById, {
					id: groupId,
				})
				.pipe(take(1))
				.subscribe(group => {
					const featuresIds = group.featuresArray.split(',');

					for (const feature of this.features) {
						feature.checked = feature.disabled = featuresIds.some(id => Number(id) === feature.id);
					}
				});
		}
	}

	/**
	 * Loads features for user, based on their group and their additionally selected features.
	 * @param userId Id of the user, to select their additional features array.
	 * @param groupId Id of the user's group.
	 */
	getAdditionalFeaturesArrayForUser(userId: number, groupId: number) {
		if (userId) {
			let baseGroupFeatures: string[];

			this.groupStore
				.select(groupSelectors.selectGroupById, {
					id: groupId,
				})
				.pipe(
					take(1),
					switchMap(group => {
						baseGroupFeatures = group.featuresArray.split(',');
						return this.administratorStore
							.select(administratorSelectors.selectAdministratorById, { id: userId })
							.pipe(take(1));
					})
				)
				.subscribe(administrator => {
					if (administrator.additionalFeaturesArray) {
						const featuresIds = administrator.additionalFeaturesArray.split(',');

						for (const feature of this.features) {
							if (baseGroupFeatures.some(id => Number(id) === feature.id)) {
								feature.checked = feature.disabled = true;
							} else {
								feature.checked = featuresIds.some(id => Number(id) === feature.id);
							}
						}
					} else {
						for (const feature of this.features) {
							feature.checked = feature.disabled = baseGroupFeatures.some(id => Number(id) === feature.id);
						}
					}
				});
		}
	}

	submitAdministratorForm() {
		this.submitted = true;
		if (this.administratorForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		const tempFeatures = this.administratorForm;
		tempFeatures.value.featuresArray = '';
		if (this.featuresChanged && this.features.some(ft => ft.checked)) {
			for (const [i, ft] of this.features.entries()) {
				if (ft.checked) {
					if (i !== 0 && tempFeatures.value.featuresArray !== '') {
						tempFeatures.value.featuresArray += ',';
					}
					tempFeatures.value.featuresArray += ft.id.toString();
				}
			}
		}

		if (this.administratorForm.value.id) {
			const tempAdmin: Update<Administrator> = {
				id: this.administratorForm.value.id,
				changes: {
					...this.administratorForm.value,
					additionalFeaturesArray: tempFeatures.value.featuresArray,
				},
			};

			this.administratorStore.dispatch(administratorActions.updateAdministrator({ administrator: tempAdmin }));
		} else {
			this.administratorStore.dispatch(
				administratorActions.addAdministrator({
					administrator: {
						...this.administratorForm.value,
						additionalFeaturesArray: tempFeatures.value.featuresArray,
						lastDateModifiedPassword: new Date(),
					},
				})
			);

			this.firstPageElementIndex = 0;
		}

		this.isUserAvailableMessage = '';
		this.updateAdministratorModal.hide();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
