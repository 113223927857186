import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductGroupsNav from './product-groups-nav.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductGroupsNavEffects } from './product-groups-nav.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductGroupsNav.productGroupsNavFeatureKey, fromProductGroupsNav.reducer),
		EffectsModule.forFeature([ProductGroupsNavEffects]),
	],
})
export class ProductGroupsNavStoreModule {}
