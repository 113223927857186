<div
	id="product-configuration"
	*ngIf="
		translate && {
			productDataLoading: productDataLoading$ | async
		} as observables
	"
>
	<div class="page-title mb-5">
		<h2>{{ title }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<div class="col-12">
		<div class="search-container">
			<div class="row pt-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 search-field">
					<div class="loading-cont" *ngIf="observables.productDataLoading">
						<div class="spinner-border"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>
					<div *ngIf="!observables.productDataLoading">
						<div #autoComplete class="autocomplete-container">
							<p-autoComplete
								class="form-control-autocomplete live-search-padding"
								[class]="!itemSearch ? 'hide-autocomplete-loader-icon' : ''"
								[(ngModel)]="itemSearch"
								[suggestions]="results"
								(completeMethod)="filterProductList($event)"
								(onSelect)="showProductData()"
								(onFocus)="forceReferenceReload()"
								emptyMessage="{{ noResultsMessage }}"
								completeOnFocus="true"
								minLength="3"
								[placeholder]="translate.ITEM_NO"
								delay="250"
								data-tid="productConfigurationSearch"
							></p-autoComplete>

							<!-- !TODO: REMOVE BUTTONS AND KEEP FAs ONLY -->
							<button class="clear-filter" *ngIf="itemSearch?.length >= 3" (click)="clearFilterAndCloseTabs()">
								<fa-icon icon="times" class="remove" title="{{ translate.CLEAR_FILTER }}"></fa-icon>
							</button>

							<button class="search-icon-button">
								<fa-icon icon="search"> </fa-icon>
							</button>

							<div class="previous-searches" *ngIf="showPreviousSearches && itemSearch?.length === 0">
								<ul>
									<li class="prev-search-title">
										{{ translate.PREV_SEARCH }}
										<fa-icon
											icon="times"
											(click)="showPreviousSearches = false"
											class="remove close-recent-search"
											title="{{ 'CLOSE' | translate }}"
										></fa-icon>
									</li>

									<li class="no-prev-search" *ngIf="noPreviousSearches">{{ this.translate.NO_PREVIOUS_SEARCHES }}</li>

									<li *ngFor="let item of previousSearches" (click)="itemSearch = item; showProductData()">
										<i class="pi pi-clock"></i>
										<a> {{ item }} </a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="productNoSelected">
			<p-tabView [(activeIndex)]="activeIndex" (onChange)="showProductData()">
				<p-tabPanel
					*ngIf="perms['Menu.ProductImages']"
					header="{{ translate.IMAGES_TAB_PANEL_HEADER }}"
					leftIcon="pi pi-image"
				>
					<app-product-images [inputProductId]="productId" [activeIndex]="activeIndex"> </app-product-images>
				</p-tabPanel>

				<p-tabPanel header="{{ translate.FILES_TAB_PANEL_HEADER }}" leftIcon="pi pi-file-o">
					<ng-template pTemplate="content">
						<app-files [inputProductId]="productId" [activeIndex]="activeIndex"> </app-files>
					</ng-template>
				</p-tabPanel>

				<p-tabPanel header="{{ translate.LINKS_TAB_PANEL_HEADER }}" leftIcon="pi pi-link">
					<ng-template pTemplate="content">
						<app-links [inputProductId]="productId" [activeIndex]="activeIndex"> </app-links>
					</ng-template>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
</div>
