import { createAction, props } from '@ngrx/store';

import { ManufacturerFiltered } from '../../shared/models/manufacturer-filtered';

export const loadManufacturersByCustomerAndGroup = createAction(
	'[Manufacturers Filtered Page] Load Manufacturers by Customer and Group',
	props<{ productGroupId: string; customerId: string }>()
);

export const loadManufacturersByCustomerAndGroupSuccess = createAction(
	'[Manufacturers Filtered API] Loaded Manufacturers by Customer and Group Success',
	props<{ manufacturersFiltered: ManufacturerFiltered[] }>()
);

export const loadManufacturersByCustomerAndGroupFailure = createAction(
	'[Manufacturers Filtered API] Loaded Manufacturers by Customer and Group Failure',
	props<{ error: any }>()
);
