import { createAction, props } from '@ngrx/store';
import { CustomerGroup } from 'src/app/shared/models/customer-group';

export const loadCustomerGroups = createAction('[Customers Page] Load Customer Groups');

export const loadCustomerGroupsSuccess = createAction(
	'[Customers API] Loaded Customer Groups Success',
	props<{ customerGroups: CustomerGroup[] }>()
);

export const loadCustomerGroupsFailure = createAction(
	'[Customers API] Loaded Customer Groups Failure',
	props<{ error: any }>()
);
