import { AuthService } from 'src/app/core/authentication/auth.service';
import { Permissions } from '../../shared/enums/permissions.enum';

/**
 * Returns if user can access page
 */
export function canAccessPage(url: string, authService: AuthService): boolean {
	switch (url) {
		case '/home': {
			return authService.hasPermission(Permissions.HomePage);
		}
		case '/user-logs': {
			return authService.hasPermission(Permissions.PortalAccessLog);
		}
		case '/dashboard': {
			return authService.hasPermission(Permissions.UsersDashboard);
		}
		case '/pricelists': {
			return authService.hasPermission(Permissions.Pricelists);
		}
		case '/promotions': {
			return authService.hasPermission(Permissions.Promotions);
		}
		case '/sellers': {
			return authService.hasPermission(Permissions.Sellers);
		}
		case '/service-reports': {
			return authService.hasPermission(Permissions.Service);
		}
		case '/administrators': {
			return authService.hasPermission(Permissions.Users) && authService.hasPermission(Permissions.UsersAdmins);
		}
		case '/product-assign': {
			return (
				authService.hasPermission(Permissions.Users) &&
				authService.hasPermission(Permissions.UsersCustomersAddGroupOrManufacturerCode)
			);
		}
		case '/groups': {
			return authService.hasPermission(Permissions.Users) && authService.hasPermission(Permissions.UsersGroups);
		}
		case '/customers': {
			return authService.hasPermission(Permissions.UsersPortalUsers);
		}
		case '/service-users': {
			return authService.hasPermission(Permissions.Users) && authService.hasPermission(Permissions.UsersUsersSW);
		}
		case '/additional-specification': {
			return authService.hasPermission(Permissions.MenuAddSpecification);
		}
		case '/product-images': {
			return authService.hasPermission(Permissions.MenuProductImages);
		}
		case '/product-configuration': {
			return authService.hasPermission(Permissions.MenuProductImages);
		}
		case '/promotion-slider': {
			return authService.hasPermission(Permissions.MenuPromotionSlider);
		}
		case '/vendorland': {
			return authService.hasPermission(Permissions.MenuVendorland);
		}
		case '/service-reports': {
			return authService.hasPermission(Permissions.ServiceStatus);
		}
		case '/registered-service': {
			return authService.hasPermission(Permissions.RegisteredService);
		}
		case '/group-connections': {
			return authService.hasPermission(Permissions.ProductGroupConnections);
		}
		case '/easy-web': {
			return (
				authService.hasPermission(Permissions.UsersSpecialPrice) &&
				!authService.deactivatedMenu.some(i => i.url === url)
			);
		}
		default:
			return true;
	}
}
