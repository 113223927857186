import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { InactiveUserState } from '../root-store/inactive-users-store/inactive-users.reducer';
import * as inactiveUsersSelectors from '../root-store/inactive-users-store/inactive-users.selectors';
import * as inactiveUsersActions from '../root-store/inactive-users-store/inactive-users.actions';
import { InactiveUser } from '../shared/models/inactive-user';

@Component({
	selector: 'app-inactive-users',
	templateUrl: './inactive-users.component.html',
	styleUrls: ['./inactive-users.component.scss'],
})
export class InactiveUsersComponent implements OnInit {
	translate: any;

	lookupDays = 30;
	currentDate = new Date();

	inactiveUsers$: Observable<InactiveUser[]>;
	inactiveUsersLoading$: Observable<boolean>;

	columns: any[] = [
		{ name: 'clientId', title: 'CLIENT_ID', width: 17 },
		{ name: 'customerId', title: 'CUSTOMER_ID', width: 17 },
		{ name: 'customerName', title: 'CUSTOMER_NAME', width: 17 },
		{ name: 'username', title: 'USERNAME', width: 17 },
		{ name: 'loginDate', title: 'LOGIN_DATE', width: 17 },
		{ name: 'orderDate', title: 'ORDER_DATE', width: 17 },
		{ name: 'haveOrder', title: 'HAVE_ORDER', width: 17 },
	];

	constructor(private translateService: TranslateService, private inactiveUsersStore: Store<InactiveUserState>) {}

	ngOnInit(): void {
		this.translateService.get('INACTIVE_USERS').subscribe((resp: any) => {
			this.translate = resp;
		});

		this.inactiveUsers$ = this.inactiveUsersStore.select(inactiveUsersSelectors.selectAllInactiveUser);
		this.inactiveUsersLoading$ = this.inactiveUsersStore.select(inactiveUsersSelectors.selectInactiveUserLoading);
		this.currentDate.setDate(this.currentDate.getDate() - this.lookupDays);

		this.inactiveUsersStore.dispatch(
			inactiveUsersActions.loadInactiveUsers({ fromDate: this.currentDate.toDateString() })
		);
	}

	searchInactiveUsersByDays() {
		this.currentDate = new Date();
		this.currentDate.setDate(this.currentDate.getDate() - this.lookupDays);

		this.inactiveUsersStore.dispatch(
			inactiveUsersActions.loadInactiveUsers({ fromDate: this.currentDate.toDateString() })
		);
	}
}
