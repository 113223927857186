import { webUsersFeatureKey, WebUserState, selectAll } from './web-user.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectWebUserState = createFeatureSelector<WebUserState>(webUsersFeatureKey);

export const selectAllWebUsers = createSelector(selectWebUserState, selectAll);

export const selectWebUsersLoading = createSelector(
	selectWebUserState,
	state => state.statusState === LoadingState.LOADING
);

export const selectWebUsersError = createSelector(selectWebUserState, (state: WebUserState) =>
	getError(state.statusState)
);
