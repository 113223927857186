import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductGroupState } from '../root-store/product-groups-store/product-group.reducer';
import { ProductGroup } from '../shared/models/product-group';
import * as productGroupSelectors from '../root-store/product-groups-store/product-group.selectors';
import * as productGroupActions from '../root-store/product-groups-store/product-group.actions';
import * as productGroupNavSelectors from '../root-store/product-groups-nav-store/product-groups-nav.selectors';
import * as productGroupNavActions from '../root-store/product-groups-nav-store/product-groups-nav.actions';
import { Update } from '@ngrx/entity';
import { deeperCopy } from '../shared/utils/deeper-copy';
import { ProductGroupNavState } from '../root-store/product-groups-nav-store/product-groups-nav.reducer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep, sortBy } from 'lodash-es';
import { environment } from 'src/environments/sr/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { fileValidator, imageValidator } from '../shared/utils/file-validator';
import { preventImageCache } from '../shared/utils/add-timestamp-prevent-cache';
import { formatDate } from '@angular/common';
@Component({
	selector: 'app-product-groups',
	templateUrl: './product-groups.component.html',
	styleUrls: ['./product-groups.component.scss'],
})
export class ProductGroupsComponent implements OnInit, OnDestroy {
	discardChangesModal: BsModalRef;
	ngUnsubscribe: Subject<object> = new Subject();
	translate: any;

	productGroupLevel: number;

	selectedImage: any;
	selectedImageName: string;
	imagePreviewUrl: string;
	oldFileName: string;

	navProductGroups$: Observable<ProductGroup[]>;
	navProductGroupsLoading$: Observable<boolean>;
	navProductGroupsDropdown = [];

	productGroups$: Observable<ProductGroup[]>;
	productGroups: ProductGroup[];
	productGroupsBackup: ProductGroup[];
	productGroupsLoading$: Observable<boolean>;

	numberOfColumns = [1, 2, 3, 4];
	dropdownOptions: any;
	navTabsDropdown: ProductGroup[] = [];
	dropdownText: string;
	currentSection: ProductGroup;

	// Modal
	productGroupModal: BsModalRef;
	productGroupForm: any;
	translateErrors: any;
	submitted = false;
	modalDropdownSection = 'createCustom';
	environment = environment;

	constructor(
		private translateService: TranslateService,
		public store: Store<ProductGroupState>,
		private modalService: BsModalService,
		private toastr: ToastrService,
		public navStore: Store<ProductGroupNavState>,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.translateService.get('PRODUCT_GROUPS').subscribe((resp: any) => {
			this.translate = resp;
		});
		this.translateService.get('ERROR.FORM_VALIDATIONS').subscribe((resp: any) => (this.translateErrors = resp));

		this.navProductGroups$ = this.store.select(productGroupNavSelectors.selectAllProductGroupsNav);
		this.navProductGroupsLoading$ = this.store.select(productGroupNavSelectors.selectProductGroupsNavLoading);
		this.navProductGroups$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if (!data.length) {
				this.store.dispatch(productGroupNavActions.loadProductGroupsNav());
			} else if (data) {
				this.navProductGroupsDropdown = [{ label: this.translate.SELECT_PRODUCT_GROUP, value: '' }];
				for (const element of data) {
					this.navProductGroupsDropdown.push({
						label: element.code,
						value: element.code,
					});
				}
			}
		});

		this.productGroups$ = this.store.select(productGroupSelectors.selectAllProductGroupsSortedByOrder);
		this.productGroupsLoading$ = this.store.select(productGroupSelectors.selectProductGroupsLoading);

		this.productGroups$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if (!data.length) {
				this.store.dispatch(productGroupActions.loadProductGroups());
			} else if (data) {
				this.navTabsDropdown = [];
				this.productGroups = deeperCopy(data);

				this.productGroupsBackup = deeperCopy(data);
				this.dropdownOptions = [];

				for (const navItem of data) {
					if (navItem.level === 0) {
						this.navTabsDropdown.push(navItem);
					}
				}

				this.currentSection = this.currentSection ? this.currentSection : this.navTabsDropdown[0];
				this.dropdownText = this.currentSection ? this.currentSection.description : '';

				if (this.translate) {
					this.dropdownOptions = [{ label: this.translate.SELECT_PRODUCT_GROUP, value: null }];

					for (const element of this.productGroups) {
						// Prevents drop on last level
						if (element.level !== 2) {
							const parent = this.productGroups.find(x => x.code === element.parentCode);
							let grandParent: ProductGroup;
							let descriptionPrefix = '';
							if (parent) {
								descriptionPrefix = parent.description + '/';
								grandParent = this.productGroups.find(x => x.code === parent.parentCode);
								if (grandParent) {
									descriptionPrefix = descriptionPrefix + grandParent.description + '/';
								}
							}

							if (element.level !== 3) {
								this.dropdownOptions.push({ label: descriptionPrefix + element.description, value: element.code });
							}
						}
					}
				}
			}
		});
	}

	openModal(
		productGroupModalTemplate: TemplateRef<any>,
		productGroup: ProductGroup,
		level: number,
		columns: number,
		parent: ProductGroup = null
	) {
		this.productGroupLevel = level;
		this.submitted = false;
		this.imagePreviewUrl = '';
		this.productGroupForm = this.formBuilder.group(
			productGroup
				? {
						...productGroup,
						code: [productGroup.code, [Validators.required, Validators.maxLength(20)]],
						description: [productGroup.description, [Validators.required, Validators.maxLength(40)]],
						order: [productGroup.order, [Validators.required, Validators.min(0)]],
						columns: [productGroup.columns, [Validators.required, Validators.min(0), Validators.max(4)]],
						bannerToDate: [
							productGroup.bannerToDate ? formatDate(productGroup.bannerToDate, 'dd-MM-yyyy', 'en-US') : null,
						],
						bannerFromDate: [
							productGroup.bannerFromDate ? formatDate(productGroup.bannerFromDate, 'dd-MM-yyyy', 'en-US') : null,
						],
						actionLink: [productGroup.actionLink, [Validators.maxLength(255)]],
						level: [productGroup.level],
						parentCode: [productGroup.parentCode, [Validators.required]],
						image: [''],
						imageUrl: [''],
						isNav: [productGroup.isNav],
				  }
				: {
						...new ProductGroup(),
						code: ['', [Validators.required, Validators.maxLength(20)]],
						description: ['', [Validators.required, Validators.maxLength(40)]],
						order: ['', [Validators.required, Validators.min(0)]],
						columns: [columns, [Validators.required, Validators.min(0), Validators.max(4)]],
						bannerToDate: [null],
						bannerFromDate: [null],
						actionLink: ['', [Validators.maxLength(255)]],
						level: [level],
						parentCode: new FormControl({ value: parent ? parent.code : '', disabled: true }),
						image: [''],
						imageUrl: [''],
						isNav: [false],
				  }
		);
		this.modalDropdownSection = productGroup?.isNav ? 'isNav' : 'createCustom';

		if (productGroup?.imageUrl) {
			this.oldFileName = productGroup.imageUrl;
			this.imagePreviewUrl = preventImageCache(environment.mediaLocation.menuBanner + productGroup.imageUrl);
		}

		this.productGroupModal = this.modalService.show(productGroupModalTemplate, {
			class: 'modal-md',
			ignoreBackdropClick: true,
		});
	}

	handleSectionClick(section: ProductGroup) {
		this.dropdownText = section.description;
		this.currentSection = section;
	}

	selectMenuBannerImage(event: any, fileUploadViewChild: any) {
		const fileReader = new FileReader();
		for (const file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				const uploadedFile = fileToBase64(fileReader);
				const uploadedFileName = file.name;

				const img = new Image();
				img.src = fileReader.result as string;
				img.onload = () => {
					if (fileValidator(file, 'size', environment.mediaRestrictions.product_groups)) {
						if (imageValidator(img, 'sizeEq', environment.mediaRestrictions.product_groups)) {
							this.selectedImage = uploadedFile;
							this.selectedImageName = uploadedFileName;
							this.productGroupForm.controls.imageUrl.setValue(this.selectedImageName);
							this.productGroupForm.controls.image.setValue(uploadedFile);
						} else {
							fileUploadViewChild.clear();
							this.translateService
								.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_EQ', {
									width: environment.mediaRestrictions.product_groups.width,
									height: environment.mediaRestrictions.product_groups.height,
								})
								.subscribe((text: string) => this.toastr.warning(text));
						}
					} else {
						fileUploadViewChild.clear();
						const size = environment.mediaRestrictions.promotions.small_image.size / 1024 + 'KB';
						this.translateService
							.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
								size,
							})
							.subscribe((text: string) => this.toastr.warning(text));
					}
				};
			};
		}
	}

	removeMenuBannerImage() {
		this.selectedImage = null;
		this.selectedImageName = this.oldFileName;
	}

	changeModalDropdown(modalDropdownSection: string) {
		this.modalDropdownSection = modalDropdownSection;
		this.productGroupForm.get('code').reset();
		this.productGroupForm.get('description').reset();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	submitProductGroupForm() {
		this.submitted = true;
		if (this.productGroupForm.value.id) {
			const returnedData = this.affectedProductGroupsOnEdit(this.productGroupForm.getRawValue());
			const updateObject: Update<ProductGroup> = {
				id: this.productGroupForm.value.id,
				changes: {
					...this.productGroupForm.getRawValue(),
					order: returnedData.currentOrder,
				},
			};

			this.store.dispatch(productGroupActions.updateProductGroup({ updateObject }));
			if (returnedData) {
				if (returnedData.affectedProductGroupsArray.length > 0) {
					this.store.dispatch(
						productGroupActions.updateProductGroupArray({ updateObject: returnedData.affectedProductGroupsArray })
					);
				} else {
					if (returnedData[0]) {
						this.store.dispatch(
							productGroupActions.updateProductGroup({ updateObject: returnedData.affectedProductGroupsArray[0] })
						);
					}
				}
			}
		} else {
			if (this.productGroupForm.invalid) {
				this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
				return;
			} else {
				const returnedData = this.affectedProductGroupsOnAdd(this.productGroupForm.getRawValue());
				if (returnedData.updatedOrder) {
					this.productGroupForm.controls.order.setValue(returnedData.updatedOrder);
				}
				if (this.modalDropdownSection === 'isNav') {
					this.productGroupForm.controls.isNav.setValue(true);
					this.productGroupForm.value.isNav = true;
				}
				this.store.dispatch(productGroupActions.addProductGroup({ addObject: this.productGroupForm.getRawValue() }));
				if (returnedData.affectedArray?.length > 0) {
					if (returnedData.affectedArray.length > 1) {
						this.store.dispatch(
							productGroupActions.updateProductGroupArray({ updateObject: returnedData.affectedArray })
						);
					} else {
						this.store.dispatch(
							productGroupActions.updateProductGroup({ updateObject: returnedData.affectedArray[0] })
						);
					}
				}
			}
		}
		this.modalDropdownSection = 'createCustom';
		this.productGroupModal.hide();
	}

	affectedProductGroupsOnAdd(pg: ProductGroup) {
		const order = pg.order;
		let updatedOrder = order;
		let array: ProductGroup[];

		if (pg?.level && pg.level === 2) {
			array = cloneDeep(this.productGroups.filter(x => x.parentCode === pg.parentCode));
		} else {
			array = cloneDeep(this.productGroups.filter(x => x.parentCode === pg.parentCode && x.columns === pg.columns));
		}
		const arrayLastItem = array[array.length - 1];
		const affectedArray: Update<ProductGroup>[] = [];

		if (arrayLastItem) {
			if (order > arrayLastItem.order) {
				updatedOrder = arrayLastItem.order + 1;
			} else {
				for (let i = order - 1; i < array.length; i++) {
					array[i].order = array[i].order + 1;
					const pom: Update<ProductGroup> = {
						id: array[i].id,
						changes: array[i],
					};
					affectedArray.push(pom);
				}
			}
		} else {
			updatedOrder = 1;
		}

		return { affectedArray, updatedOrder };
	}

	affectedProductGroupsOnEdit(pg: ProductGroup) {
		const previousParams = cloneDeep(this.productGroups.find(c => c.id === pg.id));
		const affectedProductGroupsArray: Update<ProductGroup>[] = [];
		const currentArray = cloneDeep(
			this.productGroups.filter(x => x.parentCode === pg.parentCode && x.columns === pg.columns && x.id !== pg.id)
		);
		let previousArray = cloneDeep(
			this.productGroups.filter(x => x.parentCode === previousParams.parentCode && x.columns === previousParams.columns)
		);
		let currentOrder = pg.order;
		const previousOrder = previousArray.findIndex(x => x.id === previousParams.id);

		// same column and same parent, but user tries to push inner Product Group out of bounds of the array
		// so the PG in question is moved to end, and elements' orders between previous and current index are decremented
		if (
			previousParams.columns === pg.columns &&
			previousParams.parentCode === pg.parentCode &&
			currentOrder > currentArray.length
		) {
			currentOrder = currentArray.length + 1;
			for (let i = previousOrder; i < currentArray.length; i++) {
				if (currentArray[i]) {
					--currentArray[i].order;
				}
				const changedProductGroup: Update<ProductGroup> = {
					id: currentArray[i].id,
					changes: currentArray[i],
				};
				affectedProductGroupsArray.push(changedProductGroup);
			}
		} else {
			// Same column, same parent
			if (previousParams.columns === pg.columns && previousParams.parentCode === pg.parentCode) {
				if (currentOrder > previousOrder) {
					for (let i = previousOrder; i < currentOrder - 1; i++) {
						if (currentArray[i]) {
							currentArray[i].order = i + 1;
						}
						const changedProductGroup: Update<ProductGroup> = {
							id: currentArray[i].id,
							changes: currentArray[i],
						};
						affectedProductGroupsArray.push(changedProductGroup);
					}
				} else {
					for (let i = previousOrder - 1; i > currentOrder - 2; i--) {
						currentArray[i].order = i + 2;
						const pom: Update<ProductGroup> = {
							id: currentArray[i].id,
							changes: currentArray[i],
						};
						affectedProductGroupsArray.push(pom);
					}
				}
				sortBy(this.productGroups, 'order');
			}

			// Different column, same parent
			else if (previousParams.columns !== pg.columns && previousParams.parentCode === pg.parentCode) {
				previousArray = cloneDeep(this.productGroups.filter(x => x.id !== pg.id));
				previousArray = cloneDeep(
					this.productGroups.filter(
						x =>
							x.parentCode === previousParams.parentCode &&
							x.columns === previousParams.columns &&
							x.id !== previousParams.id
					)
				);
				const changedProductGroup: Update<ProductGroup> = {
					id: pg.id,
					changes: pg,
				};
				affectedProductGroupsArray.push(changedProductGroup);

				// Reorder current column
				for (let i = currentOrder - 1; i < currentArray.length; i++) {
					currentArray[i].order = i + 2;
					const pom: Update<ProductGroup> = {
						id: currentArray[i].id,
						changes: currentArray[i],
					};
					affectedProductGroupsArray.push(pom);
				}

				// Reorder previous column
				for (let i = previousOrder; i < previousArray.length; i++) {
					previousArray[i].order = i + 1;
					const pom: Update<ProductGroup> = {
						id: previousArray[i].id,
						changes: previousArray[i],
					};
					affectedProductGroupsArray.push(pom);
				}
			}

			// Different column, different parent
			else {
				// reordering previous parent array
				for (let i = previousOrder + 1; i < previousArray.length; i++) {
					if (previousArray[i]) {
						--previousArray[i].order;

						const changedProductGroup: Update<ProductGroup> = {
							id: previousArray[i].id,
							changes: previousArray[i],
						};
						affectedProductGroupsArray.push(changedProductGroup);
					}
				}
				// if user enters order value greater than new array's last order
				if (currentOrder > currentArray.length) {
					currentOrder = currentArray.length + 1;
				}
				// else push array into the middle and increment succeeding elements
				else {
					for (let i = currentOrder - 1; i < currentArray.length; i++) {
						if (currentArray[i]) {
							++currentArray[i].order;
						}
						const changedProductGroup: Update<ProductGroup> = {
							id: currentArray[i].id,
							changes: currentArray[i],
						};
						affectedProductGroupsArray.push(changedProductGroup);
					}
				}
			}
		}
		return { affectedProductGroupsArray, currentOrder };
	}

	openDeleteModal(modalTemplate: TemplateRef<any>) {
		this.productGroupModal.hide();
		this.productGroupModal = this.modalService.show(modalTemplate, { class: 'modal-md', ignoreBackdropClick: true });
	}

	onModalHide(modalTemplate: TemplateRef<any>) {
		this.selectedImage = [];
		this.productGroupModal.hide();
		this.productGroupModal = this.modalService.show(modalTemplate, { class: 'modal-md', ignoreBackdropClick: true });
	}

	delete(type: string) {
		const pg = this.productGroupForm.getRawValue();
		const returnedData = this.affectedProductGroupsOnDelete(pg);
		if (returnedData.affectedArray.length > 1) {
			this.store.dispatch(productGroupActions.updateProductGroupArray({ updateObject: returnedData.affectedArray }));
		} else if (returnedData.affectedArray.length > 0) {
			this.store.dispatch(productGroupActions.updateProductGroup({ updateObject: returnedData.affectedArray[0] }));
		}

		if (type === 'all') {
			if (returnedData.childrenKeys.length > 0) {
				this.store.dispatch(productGroupActions.removeProductGroupArray({ keys: returnedData.childrenKeys }));
			}
			this.store.dispatch(productGroupActions.removeProductGroup({ id: pg.id }));
		} else {
			this.store.dispatch(productGroupActions.removeProductGroup({ id: pg.id }));
		}
		this.productGroupModal.hide();
	}

	affectedProductGroupsOnDelete(pg: ProductGroup) {
		const order = pg.order;
		const array = cloneDeep(
			this.productGroups.filter(x => x.parentCode === pg.parentCode && x.columns === pg.columns && x.code !== pg.code)
		);
		const affectedArray = [];
		for (let i = order - 1; i < array.length; i++) {
			if (order !== array[i].order) {
				array[i].order = array[i].order - 1;
				const pom: Update<ProductGroup> = {
					id: array[i].id,
					changes: array[i],
				};
				affectedArray.push(pom);
			}
		}
		const childrenKeys = [];
		const children = this.productGroups.filter(x => x.parentCode === pg.code);

		for (const group of this.productGroups) {
			for (const parent of children) {
				if (group.parentCode === parent.code) {
					childrenKeys.push(group.id);
				}
			}
		}

		for (const element of children) {
			childrenKeys.push(element.id);
		}
		return { affectedArray, childrenKeys };
	}

	removeImage(productGroup: ProductGroup) {
		this.productGroupForm.controls.imageUrl.setValue('');
		this.imagePreviewUrl = '';
		this.selectedImage = null;

		const updateObject: Update<ProductGroup> = {
			id: productGroup.id,
			changes: productGroup,
		};
		this.store.dispatch(productGroupActions.removeProductGroupImage({ productGroup: updateObject }));
	}
}
