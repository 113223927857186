import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromServiceReport from './service-report.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ServiceReportEffects } from './service-report.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromServiceReport.serviceReportsFeatureKey, fromServiceReport.reducer),
		EffectsModule.forFeature([ServiceReportEffects]),
	],
})
export class ServiceReportsStoreModule {}
