import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductImage from './product-image.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductImageEffects } from './product-image.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductImage.productImagesFeatureKey, fromProductImage.reducer),
		EffectsModule.forFeature([ProductImageEffects]),
	],
})
export class ProductImagesStoreModule {}
