import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import * as promotionSliderSelectors from '../root-store/promotion-sliders-store/promotion-slider.selectors';
import * as promotionSliderActions from '../root-store/promotion-sliders-store/promotion-slider.actions';

import { PromotionSliderState } from '../root-store/promotion-sliders-store/promotion-slider.reducer';
import { PromotionSlider } from '../shared/models/promotion-slider';
import { Update } from '@ngrx/entity';
import { environment } from 'src/environments/sr/environment';
import { fileValidator, imageValidator } from '../shared/utils/file-validator';
import { ToastrService } from 'ngx-toastr';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { fileExtensionParser } from '../shared/utils/file-extension-parser';
import { SelectItem } from 'primeng/api';
import { promotionSliderLocationEnum } from '../shared/enums/promotion-slider-location.enum';
import { preventImageCache } from '../shared/utils/add-timestamp-prevent-cache';
import { cloneDeep } from 'lodash-es';
import { PromotionSliderItem } from '../shared/models/promotion-slider-item';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-promotion-slider',
	templateUrl: './promotion-slider.component.html',
	styleUrls: ['./promotion-slider.component.scss'],
})
export class PromotionSliderComponent implements OnInit {
	updatePromSliderModal: BsModalRef;
	deleteModalImageModal: BsModalRef;
	ngUnsubscribe: Subject<object> = new Subject();
	promSliderForm: any;

	firstPageElementIndex = 0;

	translate: any;
	translateFormValidationErrorMsgs: any;
	environment = environment;

	desktopImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.desktop_image;
	mobileImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.mobile_image;

	faArrowUp = faArrowUp;
	faArrowDown = faArrowDown;

	promSlidersLoading$: Observable<boolean>;
	selectedPromotionSliderLoading$: Observable<boolean>;
	selectedPromotionSliderFileLoading$: Observable<boolean>;

	promSliders$: Observable<PromotionSlider[]>;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'name', title: 'NAME', width: 12 },
		{ name: 'url', title: 'WEB_ADDRESS', width: 15 },
		{ name: 'active', title: 'ACTIVE', width: 6 },
		{ name: 'orderNumber', title: 'ORDER_NO', width: 6 },
		{ name: 'isPosted', title: 'POSTED', width: 6 },
		{ name: 'image1Url', title: 'DESKTOP_IMG', width: 13 },
		{ name: 'image2Url', title: 'MOBILE_IMG', width: 13 },
		{ name: 'location', title: 'LOCATION', width: 7 },
	];

	modalFile: string;
	modalFileExtension: string;
	fileName: string;
	fileNameForDisplay: string;
	documentUrl: string;

	uploadImgs: any;

	selectedImagesDisplay: string[] = [];
	selectedImages: any[] = [];
	uploadImageQueue: any[] = [];
	uploadFinished = false;

	changeOrder = false;
	isOrderChanged = false;
	modalItemsBackup: PromotionSliderItem[] = [];

	isModal = false;

	promoSliderId: number;
	selectedPromoSlider: PromotionSlider;

	imageOrder = 0;

	promotionSliderMobileImage: string;
	promotionSliderDesktopImage: string;
	selectedPromotionSliderMobileImage: string;
	selectedPromotionSliderMobileImageName: string;
	selectedPromotionSliderDesktopImage: string;
	selectedPromotionSliderDesktopImageName: string;
	customErrors = { required: true };

	options: SelectItem[] = [];

	promotionSliderLocationEnum = promotionSliderLocationEnum;

	selectedLocation = '';

	submitted: boolean;
	constructor(
		private translateService: TranslateService,
		private store: Store<PromotionSliderState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('PROM_SLIDERS').subscribe((resp: any) => {
			this.translate = resp;

			this.options = [
				{ value: this.promotionSliderLocationEnum.HOMEPAGE, label: this.translate.HOMEPAGE },
				{ value: this.promotionSliderLocationEnum.PRODUCTLIST, label: this.translate.PRODUCTLIST },
			];
		});

		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));

		this.promSliders$ = this.store.select(promotionSliderSelectors.selectAllPromotionSliders);

		this.promSlidersLoading$ = this.store.select(promotionSliderSelectors.selectPromotionSlidersLoading);
		this.selectedPromotionSliderLoading$ = this.store.select(
			promotionSliderSelectors.selectSelectedPromotionSlidersLoading
		);
		this.selectedPromotionSliderFileLoading$ = this.store.select(
			promotionSliderSelectors.selectSelectedPromotionSliderFileLoading
		);

		this.store.dispatch(promotionSliderActions.loadPromotionSliders());
	}

	openPromSliderModal(updatePromSliderTemplate: TemplateRef<any>, promSlide: PromotionSlider) {
		this.selectedLocation = promSlide?.location ? promSlide.location : this.promotionSliderLocationEnum.HOMEPAGE;

		if (this.selectedLocation === this.promotionSliderLocationEnum.PRODUCTLIST) {
			this.desktopImageMediaRestrictions =
				environment.mediaRestrictions.promotion_slider.product_list_image.desktop_image;
			this.mobileImageMediaRestrictions =
				environment.mediaRestrictions.promotion_slider.product_list_image.mobile_image;
		} else if (this.selectedLocation === this.promotionSliderLocationEnum.HOMEPAGE) {
			this.desktopImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.desktop_image;
			this.mobileImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.mobile_image;
		}

		this.changeOrder = false;

		this.modalFile = null;
		this.modalFileExtension = null;
		this.documentUrl = null;
		this.selectedImagesDisplay = [];
		this.selectedImages = [];

		this.promotionSliderMobileImage = null;
		this.promotionSliderDesktopImage = null;
		this.selectedPromotionSliderMobileImage = null;
		this.selectedPromotionSliderMobileImageName = null;
		this.selectedPromotionSliderDesktopImage = null;
		this.selectedPromotionSliderDesktopImageName = null;

		this.submitted = false;
		let fromDate: any;
		let toDate: any;

		if (promSlide) {
			this.promoSliderId = promSlide.id;

			this.store
				.select(promotionSliderSelectors.selectPromotionSliderById, {
					id: promSlide.id,
				})
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(data => {
					if (data) {
						if (data.productActionSliderItems?.length) {
							const clonedArray = cloneDeep(data.productActionSliderItems);

							const sortedModalImages = clonedArray.sort((a, b) =>
								a.order > b.order ? 1 : b.order > a.order ? -1 : 0
							);

							if (!data.documentUrl) {
								this.documentUrl = '';
							}

							this.selectedPromoSlider = {
								...data,
								productActionSliderItems: sortedModalImages,
							};
						} else {
							this.selectedPromoSlider = {
								...data,
							};
						}
					} else {
						this.selectedPromoSlider = null;
					}
				});

			if (this.selectedPromoSlider?.productActionSliderItems?.length) {
				this.imageOrder =
					this.selectedPromoSlider.productActionSliderItems[
						this.selectedPromoSlider.productActionSliderItems.length - 1
					].order + 1;
			} else {
				this.imageOrder = 0;
			}

			if (promSlide.fromDate && promSlide.toDate) {
				fromDate = new Date(promSlide.fromDate);
				toDate = new Date(promSlide.toDate);
			} else {
				fromDate = '';
				toDate = '';
			}
		} else {
			this.imageOrder = 0;
			this.promoSliderId = null;
			this.selectedPromoSlider = null;
		}

		this.promSliderForm = this.formBuilder.group(
			promSlide
				? {
						...promSlide,
						name: [promSlide.name, [Validators.required, Validators.maxLength(100)]],
						url: [promSlide.url, [Validators.maxLength(1000)]],
						orderNumber: [promSlide.orderNumber, [Validators.required]],
						fromDate: [fromDate, [Validators.required]],
						toDate: [toDate, [Validators.required]],
						location: [this.selectedLocation, [Validators.maxLength(50)]],
						path: [promSlide.path, [Validators.maxLength(100)]],
						isModal: [promSlide.isModal],
						documentUrl: [promSlide.documentUrl],
						productActionSliderItems: [],
				  }
				: {
						...new PromotionSlider(),
						name: ['', [Validators.required, Validators.maxLength(100)]],
						url: ['', [Validators.maxLength(1000)]],
						orderNumber: ['', [Validators.required, Validators.maxLength(64)]],
						fromDate: ['', [Validators.required]],
						toDate: ['', [Validators.required]],
						location: [this.selectedLocation, [Validators.maxLength(50)]],
						path: ['', [Validators.maxLength(100)]],
						isModal: [false],
						documentUrl: [''],
						productActionSliderItems: [[]],
				  }
		);

		if (this.promSliderForm.value.documentUrl) {
			const savedFileName = this.promSliderForm.value.documentUrl as string;
			this.modalFileExtension = fileExtensionParser(savedFileName);
			this.fileNameForDisplay = savedFileName.substring(0, savedFileName.lastIndexOf('.'));

			this.documentUrl = environment.mediaLocation.promotionSlider.modalFile + this.promSliderForm.value.documentUrl;
		}

		if (this.promSliderForm.value.image1Url) {
			this.promotionSliderDesktopImage = preventImageCache(
				environment.mediaLocation.promotionSlider.imagePath + promSlide.image1Url
			);
		}

		if (this.promSliderForm.value.image2Url) {
			this.promotionSliderMobileImage = preventImageCache(
				environment.mediaLocation.promotionSlider.imagePath + promSlide.image2Url
			);
		}

		this.updatePromSliderModal = this.modalService.show(updatePromSliderTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	locationDropdownChange(event, desktopImageViewChild, mobileImageViewChild) {
		this.selectedLocation = event.value;

		if (this.selectedLocation === this.promotionSliderLocationEnum.PRODUCTLIST) {
			this.desktopImageMediaRestrictions =
				environment.mediaRestrictions.promotion_slider.product_list_image.desktop_image;
			this.mobileImageMediaRestrictions =
				environment.mediaRestrictions.promotion_slider.product_list_image.mobile_image;
		} else if (this.selectedLocation === this.promotionSliderLocationEnum.HOMEPAGE) {
			this.desktopImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.desktop_image;
			this.mobileImageMediaRestrictions = environment.mediaRestrictions.promotion_slider.mobile_image;
		}

		desktopImageViewChild.clear();
		mobileImageViewChild.clear();

		this.selectedPromotionSliderDesktopImage = null;
		this.selectedPromotionSliderDesktopImageName = null;
		this.selectedPromotionSliderMobileImage = null;
		this.selectedPromotionSliderMobileImageName = null;
	}

	selectModalImages(event: any) {
		this.uploadFinished = false;

		for (const file of event.target.files) {
			const reader = new FileReader();
			reader.onload = (ev: any) => {
				if (fileValidator(file, 'size', environment.mediaRestrictions.promotion_slider.modal_image)) {
					const image = new Image();
					image.src = ev.target.result;

					image.onload = () => {
						if (imageValidator(image, 'sizeLt', environment.mediaRestrictions.promotion_slider.modal_image)) {
							if (this.selectedImagesDisplay.length === 0) {
								this.selectedImagesDisplay.push(ev.target.result);
							} else if (!this.selectedImagesDisplay.some(imageForDisplay => imageForDisplay === ev.target.result)) {
								this.selectedImagesDisplay.push(ev.target.result);
							}

							const base64File = fileToBase64(ev.target);

							if (this.promoSliderId) {
								// Send images data directly to backend, if user is editing the slider
								const newImage = {
									productActionSliderId: this.promoSliderId,
									imageUrl: file.name,
									imageString: base64File,
									active: true,
									lastOrderNumber: this.selectedPromoSlider?.productActionSliderItems
										? this.selectedPromoSlider?.productActionSliderItems?.length + 1
										: 0,
								};

								this.uploadImageQueue.push(newImage);

								setTimeout(() => {
									if (!this.uploadFinished) {
										this.store.dispatch(
											promotionSliderActions.uploadModalImages({
												selectedPromotionSlider: this.selectedPromoSlider,
												extraItems: this.uploadImageQueue,
											})
										);

										this.uploadFinished = true;

										this.uploadImageQueue = [];
									}
								}, 0);
							} else {
								// Otherwise send images to selectedImages queue
								if (this.selectedImages.length === 0) {
									const newImage = {
										imageUrl: file.name,
										imageString: base64File,
										order: this.imageOrder++,
										active: true,
									};

									this.selectedImages.push(newImage);
								} else if (!this.selectedImages.some(imageFile => imageFile.imageString === base64File)) {
									const newImage = {
										imageUrl: file.name,
										imageString: base64File,
										order: this.imageOrder++,
										active: true,
									};

									this.selectedImages.push(newImage);
								}
							}
						} else {
							this.translateService
								.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_LT', {
									width: environment.mediaRestrictions.promotion_slider.modal_image.width,
									height: environment.mediaRestrictions.promotion_slider.modal_image.height,
								})
								.subscribe((text: string) => this.toastr.warning(text));
						}
					};
				} else {
					this.translateService
						.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
							size: environment.mediaRestrictions.promotion_slider.modal_image.size / 1048576 + 'MB',
						})
						.subscribe((imagesTooBig: string) =>
							this.toastr.warning(file.name + ' - ' + imagesTooBig, this.translate.FILE_SIZE)
						);
				}
			};
			reader.readAsDataURL(file);
		}

		this.uploadImgs = null;
	}

	setModalImagesOrder() {
		this.changeOrder = true;
		this.modalItemsBackup = cloneDeep(this.selectedPromoSlider.productActionSliderItems);
	}

	submitNewOrder() {
		this.store.dispatch(
			promotionSliderActions.changeModalImagesOrder({
				promotionSlider: this.selectedPromoSlider,
			})
		);

		this.changeOrder = false;
		this.isOrderChanged = false;
	}

	cancelNewOrder() {
		this.changeOrder = false;
		this.selectedPromoSlider = {
			...this.selectedPromoSlider,
			productActionSliderItems: this.modalItemsBackup,
		};

		this.modalItemsBackup = [];
		this.isOrderChanged = false;
	}

	moveItem(arrayToChange: string, direction: string, image: any, index: number) {
		let array: any[] = [];
		if (arrayToChange === 'new') {
			array = this.selectedImages;
		} else {
			array = this.selectedPromoSlider.productActionSliderItems;
		}

		if ((index !== 0 && direction === 'up') || (index !== array.length - 1 && direction === 'down')) {
			array.splice(index, 1);
			array.splice(direction === 'up' ? index - 1 : index + 1, 0, image);

			if (arrayToChange === 'new') {
				const movedImage = this.selectedImagesDisplay[index];
				this.selectedImagesDisplay.splice(index, 1);
				this.selectedImagesDisplay.splice(direction === 'up' ? index - 1 : index + 1, 0, movedImage);
			}

			const arrayCopy = cloneDeep(array);

			for (const [i, newImage] of arrayCopy.entries()) {
				newImage.order = i;
			}

			if (arrayToChange === 'new') {
				this.selectedImages = arrayCopy;
			} else {
				this.selectedPromoSlider = {
					...this.selectedPromoSlider,
					productActionSliderItems: arrayCopy,
				};
			}
		}

		this.isOrderChanged = true;
	}

	removeSelectedModalImage(index: number) {
		for (let i = index + 1; i < this.selectedImages.length; i++) {
			const newOrder = this.selectedImages[i].order - 1;
			this.selectedImages[i].order = newOrder;
		}

		this.selectedImages.splice(index, 1);
		this.selectedImagesDisplay.splice(index, 1);

		this.imageOrder = this.selectedImages[this.selectedImages.length - 1].order + 1;
	}

	resetImageUpload() {
		this.selectedImages = [];
		this.selectedImagesDisplay = [];
		this.imageOrder = 0;
	}

	select(event: any, name: string, fileUploadViewChild: any) {
		const fileReader = new FileReader();
		for (const file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				const uploadedFile = fileToBase64(fileReader);
				if (name === 'modalFile') {
					this.promSliderForm.value.modalFile = uploadedFile;
					this.modalFile = uploadedFile;
					this.fileName = file.name;
				} else {
					const img = new Image();
					img.src = fileReader.result as string;
					img.onload = () => {
						if (name === 'mobileImage') {
							if (fileValidator(file, 'size', this.mobileImageMediaRestrictions)) {
								if (imageValidator(img, 'sizeLt', this.mobileImageMediaRestrictions)) {
									this.selectedPromotionSliderMobileImage = uploadedFile;
									this.selectedPromotionSliderMobileImageName = file.name;
								} else {
									fileUploadViewChild.clear();
									this.remove('mobileImage');
									this.translateService
										.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_LT', {
											width: this.mobileImageMediaRestrictions.width,
											height: this.mobileImageMediaRestrictions.height,
										})
										.subscribe((text: string) => this.toastr.warning(text));
								}
							} else {
								fileUploadViewChild.clear();
								this.remove('mobileImage');
								const size = this.mobileImageMediaRestrictions.size / 1024 + 'KB';
								this.translateService
									.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
										size,
									})
									.subscribe((text: string) => this.toastr.warning(text));
							}
						} else {
							if (fileValidator(file, 'size', this.desktopImageMediaRestrictions)) {
								if (imageValidator(img, 'sizeLt', this.desktopImageMediaRestrictions)) {
									this.selectedPromotionSliderDesktopImage = uploadedFile;
									this.selectedPromotionSliderDesktopImageName = file.name;
								} else {
									fileUploadViewChild.clear();
									this.remove('desktopImage');
									this.translateService
										.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_LT', {
											width: this.desktopImageMediaRestrictions.width,
											height: this.desktopImageMediaRestrictions.height,
										})
										.subscribe((text: string) => this.toastr.warning(text));
								}
							} else {
								fileUploadViewChild.clear();
								this.remove('desktopImage');
								const size = this.desktopImageMediaRestrictions.size / 1024 + 'KB';
								this.translateService
									.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
										size,
									})
									.subscribe((text: string) => this.toastr.warning(text));
							}
						}
					};
				}
			};
		}
	}

	remove(name: string) {
		if (name === 'mobileImage') {
			this.selectedPromotionSliderMobileImage = null;
			this.selectedPromotionSliderMobileImageName = null;
		} else if (name === 'desktopImage') {
			this.selectedPromotionSliderDesktopImage = null;
			this.selectedPromotionSliderDesktopImageName = null;
		} else if (name === 'modalFile') {
			this.modalFile = null;
			this.promSliderForm.value.modalFile = null;
			this.fileName = null;
		}
	}

	deleteModalImage(item: PromotionSliderItem) {
		this.store.dispatch(
			promotionSliderActions.deleteModalImage({
				promoSlider: this.selectedPromoSlider,
				item,
			})
		);
	}

	deleteModalFile() {
		this.store.dispatch(promotionSliderActions.deleteModalFile({ promoSlider: this.selectedPromoSlider }));
	}

	submitPromSliderForm() {
		this.submitted = true;
		if (this.promSliderForm.value.id) {
			if (
				this.promSliderForm.invalid ||
				(!this.selectedPromotionSliderDesktopImage && !this.promotionSliderDesktopImage) ||
				(!this.selectedPromotionSliderMobileImage && !this.promotionSliderMobileImage)
			) {
				this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
				return;
			}

			const tempPromSlider: Update<PromotionSlider> = {
				id: this.promSliderForm.value.id,
				changes: this.promSliderForm.value,
			};

			if (this.promSliderForm.value.isModal === null) {
				this.promSliderForm.value.isModal = false;
			}

			if (this.modalFile) {
				tempPromSlider.changes.documentUrl = this.fileName;
				tempPromSlider.changes.modalFile = this.modalFile;
			}

			if (this.selectedPromotionSliderDesktopImage) {
				tempPromSlider.changes.desktopImage = this.selectedPromotionSliderDesktopImage;
				tempPromSlider.changes.image1Url = `${this.promSliderForm.value.id}_Desktop${fileExtensionParser(
					this.selectedPromotionSliderDesktopImageName
				)}`;
			}

			if (this.selectedPromotionSliderMobileImage) {
				tempPromSlider.changes.mobileImage = this.selectedPromotionSliderMobileImage;
				tempPromSlider.changes.image2Url = `${this.promSliderForm.value.id}_Mobile${fileExtensionParser(
					this.selectedPromotionSliderMobileImageName
				)}`;
			}

			this.store.dispatch(promotionSliderActions.updatePromotionSlider({ promotionSlider: tempPromSlider }));
		} else {
			if (
				this.promSliderForm.invalid ||
				!this.selectedPromotionSliderDesktopImage ||
				!this.selectedPromotionSliderMobileImage
			) {
				this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
				return;
			}

			if (this.promSliderForm.value.isModal && this.selectedImages.length === 0) {
				this.toastr.warning(this.translate.MODAL_IMAGES_REQUIRED);
				return;
			}

			const stringDate = new Date().toISOString();
			this.promSliderForm.value.synchDate = stringDate;
			this.promSliderForm.value.updateDate = stringDate;
			this.promSliderForm.value.url = this.promSliderForm.value.url ? this.promSliderForm.value.url : '';
			this.promSliderForm.value.desktopImage = this.selectedPromotionSliderDesktopImage;
			this.promSliderForm.value.image1Url = this.selectedPromotionSliderDesktopImageName;
			this.promSliderForm.value.mobileImage = this.selectedPromotionSliderMobileImage;
			this.promSliderForm.value.image2Url = this.selectedPromotionSliderMobileImageName;

			if (this.promSliderForm.value.isModal) {
				this.promSliderForm.value.documentUrl = this.fileName;
				this.promSliderForm.value.modalFile = this.modalFile;
				this.promSliderForm.value.productActionSliderItems = this.selectedImages;
			}

			this.store.dispatch(promotionSliderActions.addPromotionSlider({ promotionSlider: this.promSliderForm.value }));

			this.firstPageElementIndex = 0;
		}

		this.updatePromSliderModal.hide();
	}
}
