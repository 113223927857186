import { OAuthStorage } from 'angular-oauth2-oidc';

export class StorageService extends OAuthStorage {
	private readonly storage: Storage;

	constructor(private readonly appName: string) {
		super();
		if (typeof localStorage !== 'undefined') {
			this.storage = localStorage;
		}
	}

	getItem(key: any) {
		return this.storage.getItem(`${this.appName}_${key}`);
	}

	setItem(key: any, data: any) {
		return this.storage.setItem(`${this.appName}_${key}`, data);
	}

	removeItem(key: any) {
		return this.storage.removeItem(`${this.appName}_${key}`);
	}
}
