import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FilterUtils } from 'primeng/utils';
import * as userlogSelectors from '../root-store/user-logs-store/user-logs.selectors';
import * as userLogActions from '../root-store/user-logs-store/user-logs.actions';
import { UserLogState } from '../root-store/user-logs-store/user-logs.reducer';
import { UserLog } from '../shared/models/user-log';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { dateRangeFilter } from 'src/app/shared/utils/date-range-filter';
@Component({
	selector: 'app-user-logs',
	templateUrl: './user-logs.component.html',
	styleUrls: ['./user-logs.component.scss'],
})
export class UserLogsComponent implements OnInit, OnDestroy {
	userLogsLoading$: Observable<boolean>;
	userLogs$: Observable<UserLog[]>;
	rangeDates: Date[];
	selectedUserName: string;
	translate: any;
	ngUnsubscribe: Subject<object> = new Subject();
	columns: any[] = [
		{ name: 'USER_LOGS.USERNAME', title: 'USERNAME', pSortableColumn: 'userName', field: 'userName' },
		{ name: 'USER_LOGS.CREATED', title: 'CREATED', pSortableColumn: 'created', field: 'created' },
		{ name: 'USER_LOGS.BROWSER', title: 'BROWSER', pSortableColumn: 'browser', field: 'browser' },
		{ name: 'USER_LOGS.OS', title: 'OS', pSortableColumn: 'os', field: 'os' },
		{ name: 'USER_LOGS.DEVICE', title: 'DEVICE', pSortableColumn: 'device', field: 'device' },
		{ name: 'USER_LOGS.IP_ADDRESS', title: 'IP_ADDRESS', pSortableColumn: 'ipAddress', field: 'ipAddress' },
		{ name: 'USER_LOGS.COUNTRY', title: 'COUNTRY', pSortableColumn: 'country', field: 'country' },
		{ name: 'USER_LOGS.URL', title: 'URL', pSortableColumn: 'url', field: 'url' },
	];
	dropdownOptions: any;
	constructor(private translateService: TranslateService, private store: Store<UserLogState>) {
		const rangeFilter = 'dateRangeFilter';
		FilterUtils[rangeFilter] = (value: any): boolean => {
			return dateRangeFilter(value, this.rangeDates);
		};
	}
	ngOnInit(): void {
		this.translateService.get('USER_LOGS').subscribe((resp: any) => {
			this.translate = resp;
			this.dropdownOptions = [{ label: this.translate.SELECT_USERNAME, value: null }];
		});

		this.userLogs$ = this.store.select(userlogSelectors.selectAllUserLogs);
		this.userLogsLoading$ = this.store.select(userlogSelectors.selectUserLogsLoading);
		this.store
			.select(userlogSelectors.selectAllUserLogs)
			.pipe(take(1))
			.subscribe(userLogs => {
				if (!userLogs.length) {
					this.store.dispatch(userLogActions.loadUserLogs());
				}
			});
		this.store
			.select(userlogSelectors.selectAllUserLogsIds)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				data.forEach(element => {
					this.dropdownOptions.push({ label: element, value: element });
				});
			});
	}
	getUserLogs(userLog: UserLog) {
		const tempUserLog: Update<UserLog> = {
			id: userLog.id,
			changes: userLog,
		};
		if (!userLog.childrenUserLogs) {
			this.store.dispatch(userLogActions.loadUserLogsByUserName({ userLog: tempUserLog }));
		}
	}
	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
