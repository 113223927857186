import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromDashboardActions from './dashboard.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { DashboardLog } from 'src/app/shared/models/dashboard-log';

@Injectable()
export class DashboardEffects {
	constructor(private actions$: Actions, private dashboardService: DashboardService) {}

	loadDashboardEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDashboardActions.loadDashboardLogsPerYear),
			mergeMap(action =>
				this.dashboardService.getDashboardLogs(action.year).pipe(
					map(dashboardLogs => {
						return fromDashboardActions.loadDashboardLogsPerYearSuccess({
							browsersUsed: this.parseBrowserUsage(dashboardLogs),
							devicesUsed: this.calculateVisitsFromDevice(dashboardLogs),
							operatingSystemsUsed: this.calculateVisitsFromOS(dashboardLogs),
							visitsPerMonth: this.calculateVisits(dashboardLogs),
						});
					}),
					catchError(error => of(fromDashboardActions.loadDashboardLogsPerYearFailure({ error })))
				)
			)
		)
	);

	private parseBrowserUsage(logs: DashboardLog[]) {
		const browserCount = logs.reduce((array, log) => {
			if (!array.hasOwnProperty(log.browser)) {
				array[log.browser] = 0;
			}
			array[log.browser]++;
			return array;
		}, {});

		return {
			keys: Object.keys(browserCount),
			values: Object.values(browserCount),
		};
	}

	private calculateVisits(logs: DashboardLog[]) {
		const visitCount = logs.reduce((array, log) => {
			const month = new Date(log.created).getMonth() + 1;
			if (!array.hasOwnProperty(month)) {
				array[month] = 0;
			}
			array[month]++;
			return array;
		}, {});

		return {
			keys: Object.keys(visitCount),
			values: Object.values(visitCount),
		};
	}

	private calculateVisitsFromDevice(logs: DashboardLog[]) {
		const deviceCount = logs.reduce((array, log) => {
			const device = `${log.device}`;
			if (!array.hasOwnProperty(device)) {
				array[device] = 0;
			}
			array[device]++;
			return array;
		}, {});

		let result = Object.keys(deviceCount).map(key => [key, deviceCount[key]]);

		result = result.sort((a, b) => (a[1] > b[1] ? -1 : b[1] > a[1] ? 1 : 0));

		let foundOtherCategory = result.findIndex(x => x[0] === 'Other');
		let other: any[];

		if (foundOtherCategory === -1) {
			other = ['Other', 0];
			result.unshift(other);
			foundOtherCategory = 0;
		}

		for (let i = 0; i < result.length; i++) {
			const item = result[i];
			if (i >= 10) {
				result[foundOtherCategory] = ['Other', (result[foundOtherCategory][1] += item[1])];
			}
		}

		result.splice(10, result.length - 10);

		return result;
	}

	private calculateVisitsFromOS(logs: DashboardLog[]) {
		const operatingSystemCount = logs.reduce((array, log) => {
			const os = `${log.os} ${log.osVersion ? log.osVersion : ''}`;
			if (!array.hasOwnProperty(os)) {
				array[os] = 0;
			}
			array[os]++;
			return array;
		}, {});

		return {
			keys: Object.keys(operatingSystemCount),
			values: Object.values(operatingSystemCount),
		};
	}
}
