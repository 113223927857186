import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromViberPromotionActions from './viber-promotion.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ViberPromotionsService } from 'src/app/core/services/viber-promotions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ViberPromotionEffects {
	constructor(
		private actions$: Actions,
		private viberPromotionService: ViberPromotionsService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadPromotionsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromViberPromotionActions.loadViberPromotions),
			mergeMap(() =>
				this.viberPromotionService.getViberPromotions().pipe(
					map(viberPromotions => fromViberPromotionActions.loadViberPromotionsSuccess({ viberPromotions })),
					catchError(error => of(fromViberPromotionActions.loadViberPromotionsFailure({ error })))
				)
			)
		)
	);

	addViberPromotionEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromViberPromotionActions.addViberPromotion),
			mergeMap(action =>
				this.viberPromotionService.addViberPromotion(action.promotion).pipe(
					switchMap(promotions =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromViberPromotionActions.addViberPromotionSuccess({ updatedPromotions: promotions });
							}),
							catchError(error => of(fromViberPromotionActions.addViberPromotionFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateViberPromotionEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromViberPromotionActions.updateViberPromotion),
			mergeMap(action =>
				this.viberPromotionService.updateViberPromotion(action.promotion).pipe(
					switchMap(promotion =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromViberPromotionActions.updateViberPromotionSuccess({ promotion });
							}),
							catchError(error => of(fromViberPromotionActions.updateViberPromotionFailure({ error })))
						)
					)
				)
			)
		)
	);
}
