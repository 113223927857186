import { Component, OnInit, ViewChild, ElementRef, TemplateRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { Subscription, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProductImage } from '../../shared/models/product-image';
import { Permissions } from '../../shared/enums/permissions.enum';

import { ProductImageState } from '../../root-store/product-images-store/product-image.reducer';
import * as productImageActions from '../../root-store/product-images-store/product-image.actions';
import * as productImageSelectors from '../../root-store/product-images-store/product-image.selectors';
import { AuthService } from '../../core/authentication/auth.service';
import { environment } from 'src/environments/sr/environment';
import { fileToBase64 } from '../../shared/utils/file-to-base64';
import { ProductImagePostModel } from '../../shared/models/product-image-post-model';

@Component({
	selector: 'app-product-images',
	templateUrl: './product-images.component.html',
	styleUrls: ['./product-images.component.scss'],
})
export class ProductImagesComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('pic') pic: ElementRef;
	@Input() inputProductId: string;
	@Input() activeIndex: number;
	ngUnsubscribe: Subject<object> = new Subject();
	environment = environment;

	perms = {};

	viewImageModal: BsModalRef;
	confirmUpdateImageModal: BsModalRef;

	faArrowUp = faArrowUp;
	faArrowDown = faArrowDown;

	translate: any;
	selectedImage: any;
	selectedImages: any = [];
	uploadImgs: any;
	selectedImageWidth: number;
	selectedImageHeight: number;

	recommendedWidth: number;
	recommendedHeight: number;
	recommendedAspectRatioWidth: number;
	recommendedAspectRatioHeight: number;
	recommendedResolutionMessage: string;
	maxFileSize: string;

	imageSet: Set<string>;
	urls: string[] = [];
	imageValid = false;
	widthNotValid = false;
	heightNotValid = false;
	editOrder = false;
	confirmModalView: string;

	disableIfNoMainImageSelected: boolean;
	allInactive: boolean;
	disableSubmitButton = false;

	productImagesLoading$: Observable<boolean>;
	productImages$: Observable<ProductImage[]>;
	productImages: ProductImage[];
	productImagesSubscriber: Subscription;

	productImagesCopy: ProductImage[];
	currentProductImage: ProductImage;

	constructor(
		private translateService: TranslateService,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private store: Store<ProductImageState>,
		private authService: AuthService
	) {}

	/**
	 * Triggers showing product images if product images tab is selected
	 */
	ngOnChanges(): void {
		if (this.activeIndex === 0) {
			this.showProductImages();
		}
	}

	ngOnInit() {
		this.perms[Permissions.MenuPictureOrder] = this.authService.hasPermission(Permissions.MenuPictureOrder);
		this.perms[Permissions.ProductImagesUpdate] = this.authService.hasPermission(Permissions.ProductImagesUpdate);

		this.recommendedWidth = environment.mediaRestrictions.product_images.resolution.width;
		this.recommendedHeight = environment.mediaRestrictions.product_images.resolution.height;
		this.recommendedAspectRatioWidth = environment.mediaRestrictions.product_images.aspect_ratio.width;
		this.recommendedAspectRatioHeight = environment.mediaRestrictions.product_images.aspect_ratio.height;
		this.maxFileSize = `${environment.mediaRestrictions.product_images.size / 1048576}`;

		this.translateService.get('PRODUCT_IMAGES').subscribe((resp: any) => (this.translate = resp));

		this.translateService
			.get('PRODUCT_IMAGES.MAX_IMAGE_SIZE', {
				size: this.maxFileSize,
			})
			.subscribe((message: string) => {
				if (message) {
					this.maxFileSize = `${message}MB`;
				}
			});
	}

	showProductImages() {
		this.productImages$ = this.store.select(productImageSelectors.selectAllProductImages);

		this.productImagesLoading$ = this.store.select(productImageSelectors.selectProductImagesLoading);

		this.store.dispatch(productImageActions.loadProductImages({ commonItemNo: this.inputProductId }));

		this.productImages$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(images => {
			if (images) {
				this.productImages = images;
				this.allInactive = this.productImages.every(img => !img.active);
			}

			this.disableIfNoMainImageSelected = !this.productImages.some(image => image.default);

			if (this.disableIfNoMainImageSelected && this.allInactive) {
				this.disableIfNoMainImageSelected = false;
			}
		});

		this.cancelInsertImages();
	}

	onSelectFile(event: any) {
		if (!this.imageSet) {
			this.imageSet = new Set();
			if (this.imageSet.size === 0) {
				this.urls = [];
			}
		}
		for (const file of event.target.files) {
			const reader = new FileReader();
			if (file.size <= environment.mediaRestrictions.product_images.size) {
				reader.onload = (ev: any) => {
					const image = new Image();
					image.src = ev.target.result;

					image.onload = () => {
						if (this.checkImageSize(image.width, image.height)) {
							if (!this.imageSet.has(ev.target.result)) {
								this.imageSet.add(ev.target.result);

								const newImage: ProductImagePostModel = {
									name: file.name,
									defaultImage: false,
									productId: this.inputProductId,
									imageString: fileToBase64(ev.target),
								};

								this.selectedImages.push(newImage);

								if (
									(this.productImages.length === 0 && !this.selectedImages.some(img => img.defaultImage)) ||
									this.allInactive
								) {
									this.selectedImages[0].defaultImage = true;
								}
							}
						} else {
							this.translateService
								.get(this.translate.INADEQUATE_ASPECT_RATIO)
								.subscribe((aspectRatioInvalid: string) => {
									this.toastr.warning(file.name + aspectRatioInvalid, this.translate.ASPECT_RATIO, {
										timeOut: 10000,
									});
								});
						}
						this.urls = Array.from(this.imageSet);
					};
				};
				reader.readAsDataURL(file);
			} else {
				this.translateService
					.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
						size: environment.mediaRestrictions.product_images.size / 1048576 + 'MB',
					})
					.subscribe((imagesTooBig: string) =>
						this.toastr.warning(file.name + ' - ' + imagesTooBig, this.translate.FILE_SIZE, {
							timeOut: 10000,
						})
					);
			}
		}

		this.uploadImgs = null;
	}

	removeSelectedFile(index: number) {
		this.selectedImages.splice(index, 1);
		this.imageSet.delete(this.urls[index]);
		this.urls.splice(index, 1);
		if (this.selectedImages[0] && this.disableIfNoMainImageSelected) {
			this.selectedImages[0].defaultImage = true;
		}
	}

	selectInsertImageAsMain(index: number) {
		if (this.productImages.length === 0 || this.allInactive) {
			for (const [i, item] of this.selectedImages.entries()) {
				if (!(item.defaultImage && i === index)) {
					item.defaultImage = i !== index ? false : !item.defaultImage;
				}
			}
		} else {
			for (const [i, item] of this.selectedImages.entries()) {
				item.defaultImage = i !== index ? false : !item.defaultImage;
			}
		}
	}

	insertNewImages() {
		this.store.dispatch(
			productImageActions.addProductImages({
				productImages: this.selectedImages,
				productId: this.inputProductId,
			})
		);
		this.selectedImages = [];
		this.imageSet = null;
		this.urls = null;
	}

	cancelInsertImages() {
		this.imageSet = null;
		this.selectedImages = [];
		this.urls = [];
	}

	setEditImagesOrder() {
		this.editOrder = true;
		this.productImagesSubscriber = this.productImages$
			.pipe(
				tap(productImages => {
					if (productImages && productImages.length > 0) {
						this.productImagesCopy = JSON.parse(JSON.stringify(productImages));
					}
				})
			)
			.subscribe();
	}

	moveItem(direction: string, image: ProductImage, index: number) {
		if ((index !== 0 && direction === 'up') || (index !== this.productImagesCopy.length - 1 && direction === 'down')) {
			this.productImagesCopy.splice(index, 1);
			this.productImagesCopy.splice(direction === 'up' ? index - 1 : index + 1, 0, image);

			for (const [i, newImage] of this.productImagesCopy.entries()) {
				newImage.orderNumber = i;
			}
		}
	}

	submitNewOrder() {
		this.store.dispatch(
			productImageActions.changeImagesOrder({
				productImages: this.productImagesCopy,
			})
		);
		this.editOrder = false;
	}

	cancelNewOrder() {
		this.editOrder = false;
		this.productImagesCopy = null;
	}

	openViewImageModal(viewImageTemplate: TemplateRef<any>, image: ProductImage) {
		this.translateService
			.get('PRODUCT_IMAGES.RCMD_IMG_SIZE_NOTE', {
				width: this.recommendedWidth,
				height: this.recommendedHeight,
			})
			.subscribe((message: string) => {
				if (message) {
					this.recommendedResolutionMessage = message;
				}
			});
		this.selectedImage = image;
		this.selectedImageHeight = null;
		this.selectedImageWidth = null;
		this.imageValid = false;
		this.viewImageModal = this.modalService.show(
			viewImageTemplate,
			Object.assign({}, { class: 'images-modal', ignoreBackdropClick: true })
		);
	}

	closeViewImageModal() {
		this.viewImageModal.hide();
	}

	openConfirmationModal(confirmUpdateImageTemplate: TemplateRef<any>, image: ProductImage, option: string) {
		this.disableSubmitButton = false;
		this.confirmModalView = option;
		this.currentProductImage = Object.assign({}, image);

		switch (option) {
			case 'changeMainImg':
				if (!this.currentProductImage.active) {
					this.translateService
						.get(this.translate.CANNOT_SET_INACTIVE_AS_MAIN)
						.subscribe((text: string) => this.toastr.warning(text));
				} else if (this.currentProductImage.default) {
					this.translateService.get(this.translate.ALREADY_MAIN).subscribe((text: string) => this.toastr.warning(text));
				} else {
					this.confirmUpdateImageModal = this.modalService.show(confirmUpdateImageTemplate, {
						class: 'modal-md',
					});
				}
				break;

			case 'deactivate':
				if (this.currentProductImage.default) {
					this.translateService
						.get(this.translate.CANNOT_DISABLE_MAIN)
						.subscribe((text: string) => this.toastr.warning(text));
				} else {
					this.confirmUpdateImageModal = this.modalService.show(confirmUpdateImageTemplate, {
						class: 'modal-md',
					});
				}
				break;
		}
	}

	onLoad(event) {
		if (event.target && event.target.naturalWidth && event.target.naturalHeight) {
			this.selectedImageWidth = event.target.naturalWidth;
			this.selectedImageHeight = event.target.naturalHeight;
			this.imageValid = this.checkImageSize(this.selectedImageWidth, this.selectedImageHeight);
			this.widthNotValid = (this.selectedImageWidth * 3) / 4 > this.selectedImageHeight;
			this.heightNotValid = (this.selectedImageWidth * 3) / 4 < this.selectedImageHeight;
		} else {
			this.widthNotValid = true;
			this.heightNotValid = true;
			this.selectedImageHeight = null;
			this.selectedImageWidth = null;
			this.imageValid = false;
		}
	}

	checkImageSize(width: number, height: number) {
		return (width * this.recommendedAspectRatioHeight) / this.recommendedAspectRatioWidth === height;
	}

	confirmModalAction() {
		if (this.confirmModalView === 'deactivate') {
			this.disableSubmitButton = true;
			this.currentProductImage.active = !this.currentProductImage.active;
			this.store.dispatch(productImageActions.changeImageStatus({ productImage: this.currentProductImage }));
			this.confirmUpdateImageModal.hide();
		} else if (this.confirmModalView === 'changeMainImg') {
			this.disableSubmitButton = true;
			this.store.dispatch(productImageActions.setDefaultImage({ productImage: this.currentProductImage }));
			this.confirmUpdateImageModal.hide();
		}
	}

	ngOnDestroy() {
		if (this.productImagesSubscriber) {
			this.productImagesSubscriber.unsubscribe();
		}
	}
}
