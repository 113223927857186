import { homePageFeatureKey, HomePageState, selectAll } from './home-page.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectHomePageState = createFeatureSelector<HomePageState>(homePageFeatureKey);

export const selectHomePage = createSelector(selectHomePageState, selectAll);

export const selectHomePageLoading = createSelector(
	selectHomePageState,
	state => state.statusState === LoadingState.LOADING
);

export const selectHomePageError = createSelector(selectHomePageState, (state: HomePageState) =>
	getError(state.statusState)
);
