import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { MenuItem, MenuItemDB } from 'src/app/shared/models/menu-item';
import { environment } from 'src/environments/sr/environment';

@Injectable()
export class MenuItemService {
	constructor(private http: HttpClient) {}

	getMenuItems(): Observable<MenuItem[]> {
		return this.http.get<MenuItem[]>(environment.baseApiUrl + 'api/Menu/menu-items');
	}
	/**
	 * Returns deactivated menu items only
	 */
	getDeactivatedMenuItems(): Observable<MenuItemDB[]> {
		return this.http.get<MenuItemDB[]>(environment.baseApiUrl + 'api/Menu/deactivated-menu-items');
	}
	/**
	 * Deactivates array of menu items by id
	 */
	deactivateMenuItems(array: number[]): Observable<number[]> {
		return this.http.post<number[]>(environment.baseApiUrl + 'api/Menu/deactivate-menu-items', { array });
	}
}
