import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductDetails } from '../../shared/models/product-details';
import * as ProductDetailActions from './product-detail.actions';
import { StatusState, LoadingState } from '../status-store/status.reducer';

export const productDetailsFeatureKey = 'productDetails';

export interface ProductDetailState extends EntityState<ProductDetails> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ProductDetails> = createEntityAdapter<ProductDetails>({
	selectId: (productDetails: ProductDetails) => productDetails.productNo,
});

export const initialState: ProductDetailState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const productDetailReducer = createReducer(
	initialState,
	on(ProductDetailActions.loadProductDetails, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductDetailActions.loadProductDetailsSuccess, (state, action) =>
		adapter.addOne(action.productDetails, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductDetailActions.loadProductDetailsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductDetailState | undefined, action: Action) {
	return productDetailReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
