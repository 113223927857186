import { customerGroupFeatureKey, CustomerGroupState, selectAll } from './customer-group.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectCustomerGroupState = createFeatureSelector<CustomerGroupState>(customerGroupFeatureKey);

export const selectAllCustomerGroups = createSelector(selectCustomerGroupState, selectAll);

export const selectCustomerGroupsLoading = createSelector(
	selectCustomerGroupState,
	state => state.statusState === LoadingState.LOADING
);

export const selectCustomerGroupsError = createSelector(selectCustomerGroupState, (state: CustomerGroupState) =>
	getError(state.statusState)
);
