import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromPromotionActions from './promotion.actions';
import { PromotionService } from '../../core/services/promotion.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PromotionEffects {
	constructor(
		private actions$: Actions,
		private promotionService: PromotionService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadPromotionsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionActions.loadPromotions),
			mergeMap(() =>
				this.promotionService.getPromotions().pipe(
					map(promotions => fromPromotionActions.loadPromotionsSuccess({ promotions })),
					catchError(error => of(fromPromotionActions.loadPromotionsFailure({ error })))
				)
			)
		)
	);
	loadActivePromotionsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionActions.loadActivePromotions),
			mergeMap(() =>
				this.promotionService.getActivePromotions().pipe(
					map(promotions => fromPromotionActions.loadActivePromotionsSuccess({ promotions })),
					catchError(error => of(fromPromotionActions.loadActivePromotionsFailure({ error })))
				)
			)
		)
	);

	addPromotionEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionActions.addPromotion),
			mergeMap(action =>
				this.promotionService.addPromotion(action.promotion).pipe(
					switchMap(promotion =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromPromotionActions.addPromotionSuccess({ promotion });
							}),
							catchError(error => of(fromPromotionActions.addPromotionFailure({ error })))
						)
					)
				)
			)
		)
	);

	updatePromotionEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionActions.updatePromotion),
			mergeMap(action =>
				this.promotionService.updatePromotion(action.promotion).pipe(
					switchMap(promotion =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromPromotionActions.updatePromotionSuccess({ promotion });
							}),
							catchError(error => of(fromPromotionActions.updatePromotionFailure({ error })))
						)
					)
				)
			)
		)
	);
}
