<ng-container *ngIf="translate && { viberPromotionsLoading: viberPromotionsLoading$ | async } as observables">
	<div>
		<div class="page-title">
			<h2>{{ translate.VIBER_PROMOTIONS }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			[disabled]="observables.viberPromotionsLoading"
			(click)="openViberPromotionModal(updateViberPromotionTemplate, null)"
		>
			<fa-icon icon="plus"></fa-icon>
			{{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.viberPromotionsLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.viberPromotionsLoading">
			<p-table
				#viberPromotionTable
				[value]="viberPromotions$ | async"
				[autoLayout]="true"
				class="promotions-prime-table"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				sortField="id"
				[sortOrder]="-1"
				[(first)]="firstPageElementIndex"
				[globalFilterFields]="[
					'id',
					'name',
					'imageUrl',
					'url',
					'activeSrb',
					'orderNumber',
					'orderNumber',
					'fromDate',
					'toDate'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="viberPromotionTable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'name'">
							{{ translate.NAME }}
							<p-sortIcon [field]="'name'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'imageUrl'">
							{{ translate.IMAGE_URL }}
							<p-sortIcon [field]="'imageUrl'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'url'">
							{{ translate.URL }}
							<p-sortIcon [field]="'url'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'activeSrb'">
							{{ translate.ACTIVE }}
							<p-sortIcon [field]="'activeSrb'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'orderNumber'">
							{{ translate.ORDER_NUMBER }}
							<p-sortIcon [field]="'orderNumber'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'fromDate'">
							{{ translate.FROM_DATE }}
							<p-sortIcon [field]="'fromDate'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'toDate'">
							{{ translate.TO_DATE }}
							<p-sortIcon [field]="'toDate'"></p-sortIcon>
						</th>

						<th [style.width.px]="90">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-promotion let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ promotion.id }}</b>
						</td>
						<td>{{ promotion.name }}</td>
						<td class="line-breaker">{{ promotion.imageUrl }}</td>
						<td class="line-breaker">
							<a href="{{ promotion.url }}" *ngIf="promotion.url" [title]="promotion.url" target="_blank">
								{{ promotion.url }}
							</a>
						</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="promotion.activeSrb"></fa-icon></td>
						<td>{{ promotion.orderNumber }}</td>
						<td>{{ promotion.fromDate | date: 'dd.MM.yyyy' }}</td>
						<td>{{ promotion.toDate | date: 'dd.MM.yyyy' }}</td>
						<td>
							<button
								type="button"
								class="button button-gray"
								(click)="openViberPromotionModal(updateViberPromotionTemplate, promotion)"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<ng-template #updateViberPromotionTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				{{ viberPromotionForm.value.id ? translate.EDIT_VIBER_PROMOTION : translate.ADD_VIBER_PROMOTION }}
			</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updateViberPromotionModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="viberPromotionForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.NAME }}:<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="name"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.name.errors }"
							maxlength="200"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.name.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PRODUCTS }}:<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="products"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.products.errors }"
							maxlength="200"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.products.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.ACTIVE }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="viberPromotionForm.value.activeSrb">
							<input
								type="checkbox"
								formControlName="activeSrb"
								[ngClass]="{ checked: viberPromotionForm.value.activeSrb }"
							/>
							<span class="chbx-checkmark">
								<svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon>
							</span>
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.URL }}:<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="url"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.url.errors }"
							maxlength="200"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.url.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.ORDER_NUMBER }}:<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="number"
							formControlName="orderNumber"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.orderNumber.errors }"
							maxlength="200"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.orderNumber.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PROMOTION_IMAGE }}
						{{ environment.mediaRestrictions.viber_action_images.width }}×{{
							environment.mediaRestrictions.viber_action_images.height
						}}
						(W×H):<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#viberImageViewChild
							type="file"
							name="viberImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="selectPromoImage($event, viberImageViewChild)"
							(onRemove)="removeViberPromoImage()"
							[ngClass]="{ 'is-invalid': submitted && !selectedImage && !imagePreviewUrl }"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedImage && imagePreviewUrl"
							[src]="imagePreviewUrl"
							class="modal-responsive-image"
							alt="Viber image"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && !selectedImage && !imagePreviewUrl"
							class="invalid-feedback"
							[errors]="customErrors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FROM_DATE }}<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="fromDate"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.fromDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.FROM_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.fromDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.TO_DATE }}<span class="required-field"> *</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="toDate"
							[ngClass]="{ 'is-invalid': submitted && viberPromotionForm.controls.toDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.TO_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && viberPromotionForm.controls.toDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">{{ translateErrors.REQUIRED_FIELDS_DESCRIPTION }}</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateViberPromotionModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="submit"
						class="button"
						[disabled]="viberPromotionForm.dirty || selectedImage ? false : true || observables.viberPromotionsLoading"
						(click)="submitViberPromotionForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
