import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerAdvancedFilter } from 'src/app/shared/models/customer-filter';
import { Deputy } from 'src/app/shared/models/deputy';
import { Product } from 'src/app/shared/models/product';
import { ProductFilters } from 'src/app/shared/models/product-filters';
import { ProductListFilter } from 'src/app/shared/models/product-list-filter';
import { ProductListItems } from 'src/app/shared/models/product-list-items';
import { ProductProcurement } from 'src/app/shared/models/product-procurement';
import { PurchasedProduct } from 'src/app/shared/models/purchased-product';
import { ReservedProduct } from 'src/app/shared/models/reserved-product';
import { Salesperson } from 'src/app/shared/models/salespersonPurchaser';
import { LatestChange, SpecialPrice, SpecialPriceDeactivate, SpecialPriceQuery } from 'src/app/shared/models/special-price';
import { environment } from 'src/environments/sr/environment';

@Injectable({
	providedIn: 'root',
})
export class SpecialPriceService {
	constructor(private http: HttpClient) { }

	/**
	 * Creates special price
	 */
	createSpecialPrice(specialPrice: SpecialPrice): Observable<SpecialPrice> {
		return this.http.post<SpecialPrice>(environment.baseApiUrl + 'api/SpecialPrice', specialPrice);
	}

	/**
	 * Inserts special prices array
	 */
	createSpecialPriceArray(array: SpecialPrice[], hasSalesCode: boolean): Observable<SpecialPrice[]> {
		return this.http.post<SpecialPrice[]>(environment.baseApiUrl + 'api/SpecialPrice/insert-array', {
			array,
			hasSalesCode,
		});
	}

	/**
	 * Get product list filters
	 */
	getProductFilters(listFilters: ProductListFilter): Observable<ProductFilters> {
		return this.http.post<ProductFilters>(environment.baseApiUrl + 'api/SpecialPrice/filter', listFilters);
	}

	/**
	 * Get product list items
	 */
	getProductListItems(listFilters: ProductListFilter): Observable<ProductListItems> {
		// TODO (temporary only until product image situation is resolved)
		return this.http.post<ProductListItems>(environment.baseApiUrl + 'api/SpecialPrice/list', listFilters).pipe(
			map((productListItems: ProductListItems) => {
				productListItems.products.forEach((product: Product) => {
					// TODO - later change manuf logo without without UploadImages/...
					product.manufacturerLogo = product.manufacturerLogo?.split('/').pop();
					product.selectedQtty = 1;
					product.isInCart = false;
					product.isInCompare = false;
					// TODO: currency
				});
				return productListItems;
			})
		);
	}

	/**
	 * Get special price list by customerGroupId or customerNo
	 */
	getSpecialPriceListById(specialPriceQuery: SpecialPriceQuery): Observable<SpecialPriceDeactivate[]> {
		return this.http.post<SpecialPriceDeactivate[]>(environment.baseApiUrl + 'api/SpecialPrice/getListById', specialPriceQuery);
	}

	/**
	 * Deactivate special price
	 */
	deactivateSpecialPrice(id: number): Observable<any> {
		return this.http.put<any>(environment.baseApiUrl + 'api/SpecialPrice/deactivate', { id });
	}

	/**
	 * Returns list of reserved products for selected product
	 */
	getReservationsByProductNo(no: string): Observable<ReservedProduct[]> {
		return this.http.get<ReservedProduct[]>(
			environment.baseApiUrl + 'api/SpecialPrice/reservations/' + encodeURIComponent(no)
		);
	}

	/**
	 * Returns list of purchased products for selected product
	 */
	getPurchasesByProductNo(no: string): Observable<PurchasedProduct[]> {
		return this.http.get<PurchasedProduct[]>(
			environment.baseApiUrl + 'api/SpecialPrice/purchases/' + encodeURIComponent(no)
		);
	}

	/**
	 * Returns list of customers for advanced filter
	 */
	getCustomersForAdvancedFilter(hasSalesCode: boolean): Observable<CustomerAdvancedFilter[]> {
		return this.http
			.get<CustomerAdvancedFilter[]>(environment.baseApiUrl + 'api/SpecialPrice/advanced-filter-list/' + hasSalesCode)
			.pipe(map(customers => customers.filter(customer => customer.no !== 'KU000000')));
	}

	/**
	 * Creates new deputy/ies
	 */
	addDeputies(array: Deputy[]): Observable<Deputy[]> {
		return this.http
			.post<Deputy[]>(environment.baseApiUrl + 'api/SpecialPrice/deputies', { array })
			.pipe(map(customers => customers.filter(customer => customer.deputyCode !== 'KU000000')));
	}

	/**
	 * Returns list of deputies by current user
	 */
	getDeputes(): Observable<Deputy[]> {
		return this.http.get<Deputy[]>(environment.baseApiUrl + 'api/SpecialPrice/deputies').pipe(
			map(deputy => {
				for (const element of deputy) {
					element.fromDB = true;
				}
				return deputy;
			})
		);
	}

	/**
	 * Deletes selected customer by id for current user
	 */
	deleteDeputy(id: number): Observable<any> {
		return this.http.delete<any>(environment.baseApiUrl + 'api/SpecialPrice/deputy/' + id);
	}

	/**
	 * Returns list of salesperson
	 */
	getSalespersons(): Observable<Salesperson[]> {
		return this.http.get<Salesperson[]>(environment.baseApiUrl + 'api/SpecialPrice/salespersons');
	}

	/**
	 * Returns list of product procurement
	 */
	getProductProcurement(no: string): Observable<ProductProcurement[]> {
		return this.http.get<ProductProcurement[]>(
			environment.baseApiUrl + 'api/SpecialPrice/procurement/' + encodeURIComponent(no)
		);
	}

	/**
	 * Returns list of latest changes for special prices, for the last seven days
	 */
	getLatestChanges(): Observable<LatestChange[]> {
		return this.http.get<LatestChange[]>(environment.baseApiUrl + 'api/SpecialPrice/latest-changes');
	}

	/***
	 * Removes all specials by customer ids
	 */
	removeSpecialsByCustomer(ids: string[]): Observable<any> {
		return this.http.post<any>(environment.baseApiUrl + 'api/SpecialPrice/deactivate-all-by-customer', { ids });
	}
	/***
	 * Removes all specials by customer group ids
	 */
	removeSpecialsByGroup(ids: string[]): Observable<any> {
		return this.http.post<any>(environment.baseApiUrl + 'api/SpecialPrice/deactivate-all-by-customer-group', { ids });
	}
}
