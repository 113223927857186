import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromHomePageActions from './home-page.actions';
import { HomePageService } from '../../core/services/home-page.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class HomePageEffects {
	constructor(private actions$: Actions, private homePageService: HomePageService) {}

	loadHomePageEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromHomePageActions.loadHomePage),
			mergeMap(() =>
				this.homePageService.getHomePageWidgetData().pipe(
					map(homePage => fromHomePageActions.loadHomePageSuccess({ homePage })),
					catchError(error => of(fromHomePageActions.loadHomePageFailure({ error })))
				)
			)
		)
	);
}
