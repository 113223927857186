import { allowedQuantityFeatureKey, AllowedQuantityState, selectAll } from './allowed-quantity.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { AllowedQuantity } from 'src/app/shared/models/allowed-quantity';

export const selectAllowedQuantityState = createFeatureSelector<AllowedQuantityState>(allowedQuantityFeatureKey);

export const selectAllAllowedQuantity = createSelector(selectAllowedQuantityState, selectAll);

export const selectAllowedQuantityLoading = createSelector(
	selectAllowedQuantityState,
	state => state.statusState === LoadingState.LOADING
);

export const selectAllowedQuantityError = createSelector(selectAllowedQuantityState, (state: AllowedQuantityState) =>
	getError(state.statusState)
);
export const selectAllowedQuantityByProductGroup = createSelector(
	selectAllAllowedQuantity,
	(allowedQuantity: AllowedQuantity[], params: any) => {
		return allowedQuantity.find(c => c.productGroup === params.productGroup);
	}
);
