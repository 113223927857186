import { Pipe, PipeTransform } from '@angular/core';
// import { normalizeText } from 'normalize-text';
@Pipe({
	name: 'atributeSearch',
})
export class AtributeFilterSearchPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (!value) return null;
		if (!args) return value;

		return value.filter(data => {
			const optionValueLocal = 'optionValueLocal';
			return JSON.stringify(data[optionValueLocal]).toLowerCase().includes(args.toLowerCase());
		});
	}
}
