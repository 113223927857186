import { productGroupsNavFeatureKey, ProductGroupNavState, selectAll } from './product-groups-nav.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductGroupsNavState = createFeatureSelector<ProductGroupNavState>(productGroupsNavFeatureKey);

export const selectAllProductGroupsNav = createSelector(selectProductGroupsNavState, selectAll);

export const selectProductGroupsNavLoading = createSelector(
	selectProductGroupsNavState,
	state => state.statusState === LoadingState.LOADING
);

export const selectProductGroupsNavError = createSelector(selectProductGroupsNavState, (state: ProductGroupNavState) =>
	getError(state.statusState)
);
