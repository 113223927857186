import { createAction, props } from '@ngrx/store';

import { Manufacturer } from '../../shared/models/manufacturer';

export const loadManufacturers = createAction('[Manufacturers Page] Load Manufacturers');

export const loadManufacturersSuccess = createAction(
	'[Manufacturers API] Loaded Manufacturers Success',
	props<{ manufacturers: Manufacturer[] }>()
);

export const loadManufacturersFailure = createAction(
	'[Manufacturers API] Loaded Manufacturers Failure',
	props<{ error: any }>()
);
