import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Promotion } from '../../shared/models/promotion';
import * as PromotionActions from './promotion.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const promotionsFeatureKey = 'promotions';

export interface PromotionState extends EntityState<Promotion> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Promotion> = createEntityAdapter<Promotion>();

export const initialState: PromotionState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const promotionReducer = createReducer(
	initialState,
	on(PromotionActions.loadPromotions, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionActions.loadPromotionsSuccess, (state, action) =>
		adapter.setAll(action.promotions, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionActions.loadPromotionsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(PromotionActions.loadActivePromotions, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionActions.loadActivePromotionsSuccess, (state, action) =>
		adapter.setAll(action.promotions, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionActions.loadActivePromotionsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(PromotionActions.addPromotion, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionActions.addPromotionSuccess, (state, action) =>
		adapter.addOne(action.promotion, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionActions.addPromotionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(PromotionActions.updatePromotion, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionActions.updatePromotionSuccess, (state, action) =>
		adapter.updateOne(action.promotion, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionActions.updatePromotionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: PromotionState | undefined, action: Action) {
	return promotionReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
