import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromSellerActions from './seller.actions';
import { SellerService } from '../../core/services/seller.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SellerEffects {
	constructor(
		private actions$: Actions,
		private sellerService: SellerService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadSellersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSellerActions.loadSellers),
			mergeMap(() =>
				this.sellerService.getSellers().pipe(
					map(sellers => fromSellerActions.loadSellersSuccess({ sellers })),
					catchError(error => of(fromSellerActions.loadSellersFailure({ error })))
				)
			)
		)
	);

	addSellerEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSellerActions.addSeller),
			mergeMap(action =>
				this.sellerService.addSeller(action.seller).pipe(
					switchMap(seller =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromSellerActions.addSellerSuccess({ seller });
							}),
							catchError(error => of(fromSellerActions.addSellerFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateSellerEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSellerActions.updateSeller),
			mergeMap(action =>
				this.sellerService.updateSeller(action.seller).pipe(
					switchMap(seller =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromSellerActions.updateSellerSuccess({ seller });
							}),
							catchError(error => of(fromSellerActions.updateSellerFailure({ error })))
						)
					)
				)
			)
		)
	);
}
