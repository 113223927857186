import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { SpecialPriceComponent } from './special-price.component';
import { NgxSliderModule } from '@m0t0r/ngx-slider';

@NgModule({
	declarations: [SpecialPriceComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule, NgxSliderModule],
})
export class SpecialPriceModule {}
