import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { ProductGroup } from '../../shared/models/product-group';

export const loadProductGroups = createAction('[Product Groups Page] Load Product Groups');
export const loadProductGroupsSuccess = createAction(
	'[Product Groups API] Loaded Product Groups Success',
	props<{ productGroups: ProductGroup[] }>()
);
export const loadProductGroupsFailure = createAction(
	'[Product Groups API] Loaded Product Groups Failure',
	props<{ error: any }>()
);

export const addProductGroup = createAction(
	'[Product Group Page] Add Product Group',
	props<{ addObject: ProductGroup }>()
);
export const addProductGroupSuccess = createAction(
	'[Product Group API] Add Product Group Success',
	props<{ productGroup: ProductGroup }>()
);
export const addProductGroupFailure = createAction(
	'[Product Group API] Add Product Group Failure',
	props<{ error: any }>()
);

export const updateProductGroup = createAction(
	'[Product Group Page] Update Product Group',
	props<{ updateObject: Update<ProductGroup> }>()
);
export const updateProductGroupSuccess = createAction(
	'[Product Group API] Update Product Group Success',
	props<{ productGroup: Update<ProductGroup> }>()
);
export const updateProductGroupFailure = createAction(
	'[Product Group API] Update ProductGroup Failure',
	props<{ error: any }>()
);

export const updateProductGroupArray = createAction(
	'[Product Group Page] Update Product Group Array',
	props<{ updateObject: Update<ProductGroup>[] }>()
);
export const updateProductGroupArraySuccess = createAction(
	'[Product Group API] Update Product Group Array Success',
	props<{ productGroup: Update<ProductGroup>[] }>()
);
export const updateProductGroupArrayFailure = createAction(
	'[Product Group API] Update ProductGroup Failure Array',
	props<{ error: any }>()
);

export const removeProductGroup = createAction('[Product Group Page] Remove Product Group', props<{ id: number }>());
export const removeProductGroupSuccess = createAction(
	'[Product Group API] Remove Product Group Success',
	props<{ id: number }>()
);
export const removeProductGroupFailure = createAction(
	'[Product Group API] Remove Product Group Failure',
	props<{ error: any }>()
);

export const removeProductGroupArray = createAction(
	'[Product Group Page] Remove Product Group Array',
	props<{ keys: number[] }>()
);
export const removeProductGroupArraySuccess = createAction(
	'[Product Group API] Remove Product Group Array Success',
	props<{ ids: any }>()
);
export const removeProductGroupArrayFailure = createAction(
	'[Product Group API] Remove Product Group Array Failure',
	props<{ error: any }>()
);

export const removeProductGroupImage = createAction(
	'[Product Group Page] Remove Product Group Image',
	props<{ productGroup: Update<ProductGroup> }>()
);
export const removeProductGroupImageSuccess = createAction(
	'[Product Group API] Remove Product Group Image Success',
	props<{ productGroup: Update<ProductGroup> }>()
);
export const removeProductGroupImageFailure = createAction(
	'[Product Group API] Remove Product Group Image Failure',
	props<{ error: any }>()
);
