<div
	id="product-images"
	*ngIf="
		translate && {
			productImagesLoading: productImagesLoading$ | async,
			productImages: productImages$ | async
		} as observables
	"
>
	<div class="col-12">
		<div *ngIf="observables.productImages">
			<div class="loading-cont" *ngIf="observables.productImagesLoading">
				<div class="spinner-border"></div>
				<span>{{ 'LOADING' | translate }}</span>
			</div>
			<div *ngIf="!observables.productImagesLoading">
				<!-- If no images were found -->
				<ng-template #noImages>
					<div class="p-grid">
						<div class="p-col-6">
							<div class="product-configuration-preview" *ngIf="!observables.productImages.length">
								<h6>{{ translate.NO_PRODUCT_IMAGES }}</h6>
							</div>
							<div class="mb-3">
								<label
									for="add-product-img"
									class="btn custom-btn upload-files mr-2"
									*ngIf="perms['ProductImages.Update'] && !selectedImages.length && !editOrder"
								>
									<input
										type="file"
										accept="image/*"
										id="add-product-img"
										class="btn btn-default"
										[(ngModel)]="uploadImgs"
										(change)="onSelectFile($event)"
										hidden
										multiple
									/>

									<fa-icon icon="plus" [style.font-size.px]="14"></fa-icon>
									{{ translate.ADD_NEW_IMAGES }}
								</label>
							</div>
						</div>
						<div class="p-col-4 p-col-6">
							<div class="product-images-info-messages">
								<p>
									{{ translate.RECOMMENDED_RESOLUTION }}
									{{ recommendedWidth }}px &times; {{ recommendedHeight }}px
								</p>
								<p>
									{{ translate.RECOMMENDED_ASPECT_RATIO }}
									{{ recommendedAspectRatioWidth }} : {{ recommendedAspectRatioHeight }}
								</p>
								<p>
									{{ maxFileSize }}
								</p>
							</div>
						</div>
					</div>
				</ng-template>

				<div>
					<div *ngIf="observables.productImages.length; else noImages">
						<div class="p-grid">
							<div class="p-col-6">
								<div class="product-configuration-preview">
									{{ translate.PRODUCT_IMAGES_PREVIEW }} #{{ observables.productImages[0].commonItemNo }}
								</div>
								<div class="mb-3">
									<label
										for="add-product-img"
										class="button upload-files mr-2"
										*ngIf="perms['ProductImages.Update'] && !selectedImages.length && !editOrder"
										[ngClass]="{ disabled: disableIfNoMainImageSelected }"
										[style.padding-top.px]="9"
									>
										<input
											type="file"
											accept="image/*"
											id="add-product-img"
											[disabled]="disableIfNoMainImageSelected"
											[(ngModel)]="uploadImgs"
											(change)="onSelectFile($event)"
											hidden
											multiple
										/>

										<fa-icon icon="plus" [style.font-size.px]="14"></fa-icon>
										{{ translate.ADD_NEW_IMAGES }}
									</label>

									<button
										type="button"
										class="btn custom-btn mr-2"
										(click)="setEditImagesOrder()"
										*ngIf="perms['Menu.PictureOrder'] && !selectedImages.length && !editOrder"
										[disabled]="disableIfNoMainImageSelected"
									>
										{{ translate.EDIT_IMAGES_ORDER }}
									</button>
									<ng-container *ngIf="!selectedImages.length && editOrder">
										<button
											type="button"
											class="btn custom-btn mr-2 mb-2"
											(click)="submitNewOrder()"
											[disabled]="observables.productImagesLoading"
										>
											{{ translate.SUBMIT_NEW_ORDER }}
										</button>

										<button type="button" class="btn custom-btn mr-2 mb-2" (click)="cancelNewOrder()">
											{{ translate.CANCEL_ORDERING }}
										</button>
									</ng-container>
								</div>
							</div>

							<div class="p-col-4 p-offset-2">
								<div class="product-images-info-messages">
									<span *ngIf="disableIfNoMainImageSelected; else recommendedValues">
										<p class="no-main-image-warning">{{ translate.NO_MAIN_IMAGE }}</p>
									</span>
									<ng-template #recommendedValues>
										<p>
											{{ translate.RECOMMENDED_RESOLUTION }}
											{{ recommendedWidth }}px &times; {{ recommendedHeight }}px
										</p>
										<p>
											{{ translate.RECOMMENDED_ASPECT_RATIO }}
											{{ recommendedAspectRatioWidth }} : {{ recommendedAspectRatioHeight }}
										</p>
										<p>
											{{ maxFileSize }}
										</p>
									</ng-template>
								</div>
							</div>
						</div>
					</div>

					<!-- Inserting new images -->
					<div class="add-images-cont" *ngIf="selectedImages.length">
						<div class="d-inline-block">
							<div class="mb-1">{{ translate.INSERT_IMAGES_INFO }}:</div>
							<div class="new-img-cont" *ngFor="let item of selectedImages; let i = index">
								<div class="image-cont">
									<img [src]="urls[i]" />
									<fa-icon
										icon="check"
										class="check"
										(click)="selectInsertImageAsMain(i)"
										[ngClass]="{ selected: item.defaultImage }"
										title="{{ translate.SET_AS_MAIN }}"
									></fa-icon>
									<fa-icon
										icon="times"
										class="remove"
										(click)="removeSelectedFile(i)"
										title="{{ translate.DISCARD }}"
									></fa-icon>
								</div>
							</div>
							<label class="add-img-label" for="add-more-imgs">
								{{ 'ADD' | translate }}
								<fa-icon icon="plus"></fa-icon>
								<input
									type="file"
									id="add-more-imgs"
									class="btn btn-default"
									[(ngModel)]="uploadImgs"
									(change)="onSelectFile($event)"
									hidden
									multiple
								/>
							</label>
							<div class="text-left mt-1">
								<button type="button" class="btn btn-default mr-2" (click)="cancelInsertImages()">
									{{ 'CANCEL' | translate }}
								</button>
								<button
									type="button"
									class="btn btn-submit mr-1"
									(click)="insertNewImages()"
									[disabled]="observables.productImagesLoading"
								>
									{{ 'SUBMIT' | translate }}
								</button>
							</div>
						</div>
					</div>

					<br *ngIf="selectedImages.length" />

					<!-- Table container -->
					<div *ngIf="observables.productImages.length > 0">
						<div class="mx-3 my-2"></div>

						<p-table
							#productImagesTable
							[value]="observables.productImages"
							[autoLayout]="true"
							[rows]="10"
							*ngIf="!editOrder"
							[globalFilterFields]="['name', 'modifiedBy']"
						>
							<ng-template pTemplate="caption">
								<div class="global-filter-cont">
									<fa-icon icon="search"></fa-icon>
									<input
										type="text"
										class="form-control"
										pInputText
										size="50"
										[placeholder]="'GLOBAL_FILTER' | translate"
										data-tid="globalFilter"
										(input)="productImagesTable.filterGlobal($event.target.value, 'contains')"
									/>
								</div>
							</ng-template>

							<ng-template pTemplate="header">
								<tr>
									<th [pSortableColumn]="'orderNumber'" class="text-center">
										{{ translate.ORDER_NO }}
										<p-sortIcon [field]="'orderNumber'"></p-sortIcon>
									</th>
									<th>&nbsp;</th>
									<th class="text-center">
										{{ translate.MAIN_IMAGE }}
									</th>
									<th>
										{{ translate.NAME }}
									</th>
									<th [pSortableColumn]="'updatedDate'">
										{{ translate.UPDATED_DATE }}
										<p-sortIcon [field]="'updatedDate'"></p-sortIcon>
									</th>
									<th [pSortableColumn]="'modifiedBy'">
										{{ translate.UPDATED_BY }}
										<p-sortIcon [field]="'modifiedBy'"></p-sortIcon>
									</th>
									<th>&nbsp;</th>
								</tr>
							</ng-template>

							<ng-template pTemplate="body" let-item let-i="rowIndex" *ngIf="!editOrder">
								<tr [ngClass]="{ inactive: !item.active }">
									<td class="change-order">
										<b>{{ item.orderNumber }}.</b>
									</td>
									<td class="text-center">
										<img
											src="{{ environment.mediaLocation.productImageRoot }}{{ item.url }}"
											(click)="openViewImageModal(viewImageTemplate, item)"
										/>
									</td>
									<td class="text-center">
										<label class="custom-chkbox container">
											<span
												*ngIf="perms['ProductImages.Update']; else disabledCheckbox"
												class="checkmark"
												[style.left.%]="45"
												(click)="openConfirmationModal(confirmUpdateImageTemplate, item, 'changeMainImg')"
												[ngClass]="{ checked: item.default }"
											></span>
											<ng-template #disabledCheckbox>
												<span
													class="checkmark"
													[style.left.%]="45"
													[ngClass]="{ checked: item.default, disabled: !perms['ProductImages.Update'] }"
												>
												</span>
											</ng-template>
										</label>
									</td>
									<td>{{ item.name }}</td>
									<td>{{ item.updatedDate | date: 'dd.MM.yyyy' }}</td>
									<td>{{ item.modifiedBy }}</td>
									<td>
										<button
											type="button"
											class="btn custom-btn mr-2 mb-2"
											*ngIf="perms['ProductImages.Update']"
											(click)="openConfirmationModal(confirmUpdateImageTemplate, item, 'deactivate')"
										>
											{{ item.active ? ('DEACTIVATE' | translate) : ('ACTIVATE' | translate) }}
											<fa-icon icon="chevron-right"></fa-icon>
										</button>

										<button
											type="button"
											class="btn custom-btn mb-2"
											(click)="openViewImageModal(viewImageTemplate, item)"
										>
											{{ 'SHOW' | translate }}
											<fa-icon icon="chevron-right"></fa-icon>
										</button>
									</td>
								</tr>
							</ng-template>

							<ng-template pTemplate="emptymessage">
								<tr>
									<td class="empty-msg">
										{{ 'NO_DATA' | translate }}
									</td>
								</tr>
							</ng-template>
						</p-table>

						<div class="product-configuration-table" *ngIf="editOrder">
							<table>
								<thead>
									<tr>
										<th class="text-center">{{ translate.ORDER_NO }}</th>
										<th>&nbsp;</th>
										<th class="text-center">{{ translate.MAIN_IMAGE }}</th>
										<th>{{ translate.NAME }}</th>
										<th>{{ translate.UPDATED_DATE }}</th>
										<th>{{ translate.UPDATED_BY }}</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of productImagesCopy; let i = index" [ngClass]="{ inactive: !item.active }">
										<td class="change-order">
											<div class="move-icons" *ngIf="editOrder">
												<button (click)="moveItem('up', item, i)" [disabled]="i == 0">
													<fa-icon [icon]="faArrowUp"></fa-icon>
												</button>
												<br />
												<button (click)="moveItem('down', item, i)" [disabled]="i == productImagesCopy.length - 1">
													<fa-icon [icon]="faArrowDown"></fa-icon>
												</button>
											</div>
											<b>{{ item.orderNumber }}.</b>
										</td>
										<td class="text-center">
											<img src="{{ environment.mediaLocation.productImageRoot }}{{ item.url }}" />
										</td>
										<td class="text-center">
											<label class="custom-chkbox container">
												<span
													class="checkmark"
													[style.left.%]="45"
													[ngClass]="{ checked: item.default, disabled: editOrder }"
												></span>
											</label>
										</td>
										<td>{{ item.name }}</td>
										<td>{{ item.updatedDate | date: 'dd.MM.yyyy' }}</td>
										<td>{{ item.modifiedBy }}</td>
										<td>
											<button
												*ngIf="perms['ProductImages.Update']"
												type="button"
												class="btn custom-btn mr-2 mb-2 disabled"
												disabled="disabled"
											>
												{{ item.active ? ('DEACTIVATE' | translate) : ('ACTIVATE' | translate) }}
												<fa-icon icon="chevron-right"></fa-icon>
											</button>
											<button type="button" class="btn custom-btn mb-2 disabled" disabled="disabled">
												{{ 'SHOW' | translate }}
												<fa-icon icon="chevron-right"></fa-icon>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Images display and validation modal -->
<ng-template #viewImageTemplate>
	<div class="modal-body">
		<button type="button" class="close pull-right" (click)="closeViewImageModal()">
			<span aria-hidden="true">&times;</span>
		</button>
		<div>
			<div>
				<h5>{{ translate.REAL_IMG_SIZE }}</h5>
				<div>
					{{ translate.IMG_SIZE_VALID }}:
					<span [ngStyle]="{ color: imageValid ? 'green' : 'red' }">{{ imageValid ? ' OK' : ' NOT OK' }}</span>
				</div>
				<div *ngIf="selectedImageWidth && selectedImageHeight">
					{{ recommendedResolutionMessage }}
					({{ translate.ACTUAL_IMG_SIZE }} {{ selectedImageWidth }}px &times; {{ selectedImageHeight }}px)
				</div>
				<br />
				<div *ngIf="!imageValid">
					{{ translate.RECOMMENDED_ASPECT_RATIO }} {{ recommendedAspectRatioWidth }} :
					{{ recommendedAspectRatioHeight }}
				</div>
				<div class="img-cont-box">
					<div class="box-border" [ngClass]="{ 'not-valid': !imageValid }"></div>
					<img
						#pic
						src="{{ environment.mediaLocation.productImageRoot }}{{ selectedImage.url }}"
						(load)="onLoad($event)"
						[ngClass]="{ 'width-invalid': widthNotValid, 'height-invalid': heightNotValid }"
					/>
				</div>
			</div>
			<hr />
			<h5>{{ translate.SCALED_PORTAL_IMG }}</h5>
			<div class="row">
				<div class="col-6">
					<h6>{{ translate.LIST_VIEW }}</h6>
					<img src="{{ environment.mediaLocation.productImageRoot }}{{ selectedImage.url }}" height="144" />
				</div>
				<div class="col-6">
					<h6>{{ translate.GRID_VIEW }}</h6>
					<img src="{{ environment.mediaLocation.productImageRoot }}{{ selectedImage.url }}" height="187" />
				</div>
			</div>
			<hr />
			<div>
				<h5>
					{{ translate.SCALED_PORTAL_IMG_DETAILS }}
				</h5>
				<img src="{{ environment.mediaLocation.productImageRoot }}{{ selectedImage.url }}" height="240" />
			</div>
		</div>
	</div>
</ng-template>

<!-- Confirmation Modal -->
<ng-template #confirmUpdateImageTemplate>
	<div class="modal-header">
		<h4 class="modal-title">
			<span *ngIf="confirmModalView === 'deactivate'">{{ translate.CHANGE_IMAGE_STATUS_TITLE }}</span>
			<span *ngIf="confirmModalView === 'changeMainImg'">{{ translate.CHANGE_MAIN_IMAGE_TITLE }}</span>
		</h4>
		<button type="button" class="close pull-right" (click)="confirmUpdateImageModal.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p *ngIf="confirmModalView === 'deactivate'">{{ translate.CHANGE_IMAGE_STATUS_BODY }}</p>
		<p *ngIf="confirmModalView === 'changeMainImg'">{{ translate.CHANGE_MAIN_IMAGE_BODY }}</p>
	</div>
	<div class="modal-footer modal-footer-remove">
		<div class="modal-footer-buttons">
			<button type="button" class="btn btn-default" (click)="confirmUpdateImageModal.hide()">
				{{ 'CLOSE' | translate }}
			</button>
			<button type="button" class="btn btn-submit" (click)="confirmModalAction()" [disabled]="disableSubmitButton">
				{{ 'SUBMIT' | translate }}
			</button>
		</div>
	</div>
</ng-template>
