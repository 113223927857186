import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
	faPlus,
	faTimes,
	faCheck,
	faSearch,
	faChevronRight,
	faPencilAlt,
	faKey,
	faSignInAlt,
	faInfoCircle,
	faFileDownload,
	faFileUpload,
	faFilter,
	faUser,
	faUsers,
	faExclamationTriangle,
	faDownload,
	faEye,
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faTrashAlt as farTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SvgIconsModule } from '@ngneat/svg-icon';
import {
	appMainPage,
	appPricelist,
	appProducts,
	appServices,
	appUsers,
	appCheckmark,
	appPdf,
	appZip,
	appText,
	appExcel,
	appDownSmall,
	appGroup,
} from 'src/assets/images/Svg';

import { CustomSearchByPropertyPipe } from './pipes/customSearchByProperty.pipe';
import { NumbersOnlyDirective } from './directives/numbersOnly.directive';
import { SkeletonScreenComponent } from './components/skeleton-screen/skeleton-screen.component';
import { FormValidationErrorMsgComponent } from './components/form-validation-error-msg/form-validation-error-msg.component';
import { FilterByProperty } from './pipes/filterByProperty.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AtributeFilterSearchPipe } from './pipes/atribute-filter-search.pipe';
import { SpecialOfferValuePipe } from './pipes/special-offer-value.pipe';
defineLocale('en-gb', enGbLocale);
@NgModule({
	declarations: [
		CustomSearchByPropertyPipe,
		NumbersOnlyDirective,
		SkeletonScreenComponent,
		FilterByProperty,
		FormValidationErrorMsgComponent,
		SearchFilterPipe,
		SpecialOfferValuePipe,
		AtributeFilterSearchPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		BsDatepickerModule.forRoot(),
		TableModule,
		ButtonModule,
		TranslateModule,
		CalendarModule,
		DropdownModule,
		AutoCompleteModule,
		TabViewModule,
		FileUploadModule,
		CheckboxModule,
		TooltipModule.forRoot(),
		DragDropModule,
		MultiSelectModule,
		SvgIconsModule.forRoot({
			icons: [
				appMainPage,
				appPricelist,
				appProducts,
				appServices,
				appUsers,
				appCheckmark,
				appPdf,
				appZip,
				appText,
				appExcel,
				appDownSmall,
				appGroup,
			],
		}),
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		FontAwesomeModule,
		TableModule,
		ButtonModule,
		BsDatepickerModule,
		ChartModule,
		DropdownModule,
		AutoCompleteModule,
		TabViewModule,
		ProgressSpinnerModule,
		CustomSearchByPropertyPipe,
		NumbersOnlyDirective,
		SkeletonScreenComponent,
		NgSelectModule,
		FilterByProperty,
		CalendarModule,
		DropdownModule,
		FileUploadModule,
		CheckboxModule,
		TooltipModule,
		MultiSelectModule,
		FormValidationErrorMsgComponent,
		DragDropModule,
		SvgIconsModule,
		SearchFilterPipe,
		SpecialOfferValuePipe,
		AtributeFilterSearchPipe,
	],
	providers: [CustomSearchByPropertyPipe, FilterByProperty, DatePipe, SpecialOfferValuePipe],
})
export class SharedModule {
	locale = 'en-gb';
	locales = listLocales();

	constructor(library: FaIconLibrary, private localeService: BsLocaleService) {
		library.addIcons(
			faPlus,
			faTimes,
			faCheck,
			faSearch,
			faChevronRight,
			faPencilAlt,
			farTrashAlt,
			faKey,
			faSignInAlt,
			faInfoCircle,
			faFileDownload,
			faFileUpload,
			faFilter,
			faUser,
			faUsers,
			faCheckCircle,
			faTimesCircle,
			faExclamationTriangle,
			faDownload,
			faEye,
			faChevronLeft
		);
		this.localeService.use(this.locale);
	}
}
