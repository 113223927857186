import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ServiceReport } from '../../shared/models/service-report';
import * as ServiceReportActions from './service-report.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const serviceReportsFeatureKey = 'serviceReports';

export interface ServiceReportState extends EntityState<ServiceReport> {
	// additional entities state properties
	openLoaded: boolean;
	pendingLoaded: boolean;
	closedLoaded: boolean;

	statusState: StatusState;
}

export const adapter: EntityAdapter<ServiceReport> = createEntityAdapter<ServiceReport>();

export const initialState: ServiceReportState = adapter.getInitialState({
	// additional entity state properties
	openLoaded: true,
	pendingLoaded: true,
	closedLoaded: false,

	statusState: LoadingState.INIT,
});

export const serviceReportReducer = createReducer(
	initialState,
	on(ServiceReportActions.loadServiceReports, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServiceReportActions.loadServiceReportsSuccess, (state, action) =>
		adapter.addMany(action.serviceReports, {
			...state,
			openLoaded: action.reportsLoadedFlags.open,
			pendingLoaded: action.reportsLoadedFlags.pending,
			closedLoaded: action.reportsLoadedFlags.closed,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServiceReportActions.loadServiceReportsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ServiceReportActions.updateServiceReport, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServiceReportActions.updateServiceReportSuccess, (state, action) =>
		adapter.updateOne(action.serviceReport, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServiceReportActions.updateServiceReportFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ServiceReportState | undefined, action: Action) {
	return serviceReportReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
