import { Injectable } from '@angular/core';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private currentLanguageSubject: BehaviorSubject<string>;
	public currentLanguage: Observable<string>;

	constructor(private translateService: TranslateService) {
		this.currentLanguageSubject = new BehaviorSubject(localStorage.getItem('admin_language'));
		this.currentLanguage = this.currentLanguageSubject.asObservable();
	}

	public get currentLanguageValue(): string {
		return this.currentLanguageSubject.value;
	}

	setLanguage(language: string): Observable<string> {
		localStorage.setItem('admin_language', language);
		this.currentLanguageSubject.next(language);
		this.translateService.use(language);
		return of(language);
	}
}
