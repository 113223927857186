export enum ExcelKeys_sr {
	PRODUCT_NO = 'ID proizvoda',
	CUSTOMER_NO = 'ID kupca',
	CUSTOMER_GROUP_ID = 'Grupa kupaca',
	FROM_DATE = 'Datum od',
	TO_DATE = 'Datum do',
	PRICE = 'Specijal',
	ERROR_FIELD = 'Polje greške',
	MESSAGE_TYPE = 'Tip poruke',
	MESSAGE_TEXT = 'Tekst poruke',
}
