import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { GroupConnectionState } from '../root-store/group-connections-store/group-connections.reducer';
import { GroupConnection } from '../shared/models/group-connection';
import * as groupConnectionSelectors from '../root-store/group-connections-store/group-connections.selectors';
import * as groupConnectionActions from '../root-store/group-connections-store/group-connections.actions';
import * as productGroupSelectors from '../root-store/product-groups-store/product-group.selectors';
import * as productGroupActions from '..//root-store/product-groups-store/product-group.actions';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Update } from '@ngrx/entity';
import { ProductGroup } from '../shared/models/product-group';
import { ProductGroupState } from '../root-store/product-groups-store/product-group.reducer';
import { take, takeUntil } from 'rxjs/operators';
import { deeperCopy } from '../shared/utils/deeper-copy';
import { ToastrService } from 'ngx-toastr';
@Component({
	selector: 'app-group-connections',
	templateUrl: './group-connections.component.html',
	styleUrls: ['./group-connections.component.scss'],
})
export class GroupConnectionsComponent implements OnInit, OnDestroy {
	translate: any;
	translateFormValidationErrorMsgs: any;
	submitted: boolean;
	columns: any[] = [
		{
			name: 'GROUP_CONNECTIONS.PRODUCT_GROUP',
			title: 'PRODUCT_GROUP',
			pSortableColumn: 'productGroup',
			field: 'productGroup',
		},
		{
			name: 'GROUP_CONNECTIONS.CONNECTED_PRODUCT_GROUP',
			title: 'CONNECTED_PRODUCT_GROUP',
			pSortableColumn: 'connectedProductGroup',
			field: 'connectedProductGroup',
		},
		{
			name: 'GROUP_CONNECTIONS.CONNECTION_TYPE',
			title: 'CONNECTION_TYPE',
			pSortableColumn: 'connectionType',
			field: 'connectionType',
		},
	];
	groupConnectionType: any;
	groupConnections$: Observable<GroupConnection[]>;
	groupConnectionsLoading$: Observable<boolean>;
	groupConnectionForm: any;
	groupConnectionModal: BsModalRef;
	deleteGroupConnectionModal: any;
	currentGroupConnection: GroupConnection;
	productGroup: ProductGroup[];
	productGroups$: Observable<ProductGroup[]>;
	productGroupsLoading$: Observable<boolean>;
	dropdownOptions: any;
	connectedProductGroupDropdownOptions$: Observable<ProductGroup>;
	connectedProductGroupDropdownOptions: any;
	ngUnsubscribe: Subject<object> = new Subject();
	otherGroupFilter = true;
	connectedSaleFilter = true;
	selectedProductGroup: string;
	filterDropdownOptions: any;

	firstPageElementIndex = 0;

	constructor(
		private translateService: TranslateService,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private store: Store<GroupConnectionState>,
		private productGroupStore: Store<ProductGroupState>,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.translateService.get('GROUP_CONNECTIONS').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
		this.groupConnections$ = this.store.select(groupConnectionSelectors.selectAllGroupConnections);
		this.groupConnectionsLoading$ = this.store.select(groupConnectionSelectors.selectGroupConnectionsLoading);
		this.filterDropdownOptions = [{ label: this.translate.SELECT_PRODUCT_GROUP, value: null }];
		this.store
			.select(groupConnectionSelectors.selectAllGroupConnections)
			.pipe(take(1))
			.subscribe(groupConnection => {
				if (!groupConnection.length) {
					this.store.dispatch(groupConnectionActions.loadGroupConnections());
				}
			});
	}

	getFilterDropdownOptions() {
		this.filterDropdownOptions = [{ label: this.translate.SELECT_PRODUCT_GROUP, value: null }];
		this.store
			.select(groupConnectionSelectors.selectDistinctGroupConnectionByID)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(groupConnection => {
				if (groupConnection.length) {
					for (const element of groupConnection) {
						this.filterDropdownOptions.push({ label: element.label, value: element.value });
					}
				}
			});
	}

	openGroupConnectionModal(groupConnectionTemplate: TemplateRef<any>) {
		this.submitted = false;
		this.groupConnectionForm = this.formBuilder.group({
			...new GroupConnection(),
			productGroup: ['', [Validators.required]],
			connectedProductGroup: ['', [Validators.required]],
			connectionType: ['', [Validators.required]],
		});
		this.productGroups$ = this.productGroupStore.select(productGroupSelectors.selectAllProductGroups);
		this.productGroupsLoading$ = this.productGroupStore.select(productGroupSelectors.selectProductGroupsLoading);
		this.groupConnectionType = [
			{ label: this.translate.SELECT_CONNECTION_TYPE, value: null },
			{ label: 'OtherGroup', value: 'OtherGroup' },
			{ label: 'ConnectedSale', value: 'ConnectedSale' },
		];
		this.dropdownOptions = [{ label: this.translate.SELECT_PRODUCT_GROUP, value: null }];

		this.productGroupStore
			.select(productGroupSelectors.selectAllProductGroups)
			.pipe(take(1))
			.subscribe(productGroup => {
				if (!productGroup.length) {
					this.productGroupStore.dispatch(productGroupActions.loadProductGroups());
				}
			});
		this.productGroupStore
			.select(productGroupSelectors.selectAllProductGroups)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(productGroup => {
				for (const element of productGroup) {
					this.dropdownOptions.push({
						label: element.description + ' ( ' + element.code + ' ) ',
						value: element.code,
					});
				}
			});
		this.groupConnectionModal = this.modalService.show(groupConnectionTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	submitGroupConnectionForm() {
		this.submitted = true;
		if (this.groupConnectionForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		if (this.groupConnectionForm.value.id) {
			const tempGroupConnection: Update<GroupConnection> = {
				id: this.groupConnectionForm.value.id,
				changes: this.groupConnectionForm.value,
			};
			this.store.dispatch(groupConnectionActions.updateGroupConnection({ groupConnection: tempGroupConnection }));
		} else {
			const tempGroupConnection: GroupConnection = this.groupConnectionForm.value;
			this.productGroupStore
				.select(productGroupSelectors.selectProductGroupByCode, {
					productGroup: this.groupConnectionForm.value.productGroup,
				})
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(productGroup => {
					tempGroupConnection.productGroupDescription = productGroup.description;
					this.store.dispatch(groupConnectionActions.addGroupConnection({ groupConnection: tempGroupConnection }));
				});

			this.firstPageElementIndex = 0;
		}
		this.groupConnectionModal.hide();
	}

	openConfirmationModal(deleteGroupConnectionTemplate: TemplateRef<any>, groupConnection: GroupConnection) {
		this.currentGroupConnection = groupConnection;
		this.deleteGroupConnectionModal = this.modalService.show(deleteGroupConnectionTemplate, {
			class: 'modal-md',
		});
	}

	select(event: any) {
		this.connectedProductGroupDropdownOptions = deeperCopy(this.dropdownOptions);
		this.store
			.select(groupConnectionSelectors.selectAllConnectedProductGroupsByCode, {
				code: event,
			})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(groupConnections => {
				for (const item of groupConnections) {
					const index = this.connectedProductGroupDropdownOptions.indexOf(
						this.connectedProductGroupDropdownOptions.find(c => c.value === item.connectedProductGroup)
					);
					this.connectedProductGroupDropdownOptions.splice(index, 1);
				}
			});
	}

	loadEdit(event: any) {
		this.connectedProductGroupDropdownOptions = deeperCopy(this.dropdownOptions);
		this.store
			.select(groupConnectionSelectors.selectAllConnectedProductGroupsByCode, {
				code: event,
			})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(groupConnections => {
				if (groupConnections) {
					for (const item of groupConnections) {
						const index = this.connectedProductGroupDropdownOptions.indexOf(
							this.connectedProductGroupDropdownOptions.find(
								c =>
									c.value === item.connectedProductGroup &&
									this.groupConnectionForm.value.connectedProductGroup !== c.value
							)
						);
						this.connectedProductGroupDropdownOptions.splice(index, 1);
					}
				}
			});
	}

	filterGroupConnections(groupConnectionsTable: any) {
		switch (true) {
			case this.connectedSaleFilter && this.otherGroupFilter:
				groupConnectionsTable.filter('', 'connectionType', 'contains');
				break;
			case this.connectedSaleFilter && !this.otherGroupFilter:
				groupConnectionsTable.filter('ConnectedSale', 'connectionType', 'equals');
				break;
			case !this.connectedSaleFilter && this.otherGroupFilter:
				groupConnectionsTable.filter('OtherGroup', 'connectionType', 'equals');
				break;
			case !this.connectedSaleFilter && !this.otherGroupFilter:
				groupConnectionsTable.filter('null', 'connectionType', 'equals');
				break;
		}
	}

	deleteGroupConnection() {
		this.store.dispatch(groupConnectionActions.removeGroupConnection({ id: this.currentGroupConnection.id }));
		this.deleteGroupConnectionModal.hide();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
