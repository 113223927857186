import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUserLogsActions from './user-logs.actions';
import { UserLogService } from '../../core/services/user-log.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { deeperCopy } from 'src/app/shared/utils/deeper-copy';
@Injectable()
export class UserLogEffects {
	constructor(private actions$: Actions, private userLogService: UserLogService) {}

	loadUserLogEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromUserLogsActions.loadUserLogs),
			mergeMap(() =>
				this.userLogService.getUserLogs().pipe(
					map(userLogs => fromUserLogsActions.loadUserLogsSuccess({ userLogs })),
					catchError(error => of(fromUserLogsActions.loadUserLogsFailure({ error })))
				)
			)
		)
	);
	loadUserLogByUserNameEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromUserLogsActions.loadUserLogsByUserName),
			mergeMap(action =>
				this.userLogService.getUserLogsByUserName(action.userLog.changes.userName).pipe(
					map(userLogs => {
						const pom = deeperCopy(action.userLog);
						pom.changes.childrenUserLogs = userLogs;
						pom.changes.childrenUserLogs.shift();
						return fromUserLogsActions.loadUserLogsByUserNameSuccess({ userLog: pom });
					}),
					catchError(error => of(fromUserLogsActions.loadUserLogsByUserNameFailure({ error })))
				)
			)
		)
	);
}
