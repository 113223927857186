<div class="customers-cont" *ngIf="translate && { customersLoading: customersLoading$ | async } as observables">
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<app-skeleton-screen
		*ngIf="observables.customersLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<div *ngIf="!observables.customersLoading">
		<p-table
			#customerTable
			[value]="customers$ | async"
			[autoLayout]="true"
			class="customers-prime-table"
			[paginator]="true"
			[rows]="10"
			[(first)]="firstPageElementIndex"
			[rowsPerPageOptions]="[5, 10, 20, 50]"
			sortField="id"
			[sortOrder]="-1"
			[globalFilterFields]="['no', 'name']"
		>
			<ng-template pTemplate="caption">
				<div class="global-filter-cont">
					<fa-icon icon="search"></fa-icon>
					<input
						type="text"
						class="form-control"
						pInputText
						size="50"
						[placeholder]="'GLOBAL_FILTER' | translate"
						data-tid="globalFilter"
						(input)="customerTable.filterGlobal($event.target.value, 'contains')"
					/>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th [pSortableColumn]="'no'" class="text-center" [style.width.px]="140">
						{{ translate.ID_NO }}
						<p-sortIcon [field]="'no'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'name'">
						{{ translate.NAME }}
						<p-sortIcon [field]="'name'"></p-sortIcon>
					</th>
					<th [style.width.px]="90">&nbsp;</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-customer let-i="rowIndex">
				<tr>
					<td class="text-center">
						<b>{{ customer.no }}</b>
					</td>
					<td>{{ customer.name }}</td>
					<td>
						<button
							type="button"
							class="button button-gray"
							(click)="openCustomerModal(viewCustomerTemplate, customer)"
						>
							{{ 'SHOW' | translate }}
						</button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td [attr.colspan]="columns.length" class="empty-msg">
						{{ 'NO_DATA' | translate }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<ng-template #viewCustomerTemplate>
	<div
		*ngIf="{
			selectedCustomer: selectedCustomer$ | async,
			selectedCustomersLoading: selectedCustomersLoading$ | async
		} as observables"
		class="modal-body"
	>
		<div class="modal-header">
			<h4 class="modal-title">{{ translate.VIEW_CUSTOMER }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="closeModalAndCleanUp()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="customer-cont">
			<div class="header-info">
				<div class="logo-cont">
					<div class="logo-img-box" *ngIf="observables.selectedCustomer.logo">
						<button
							type="button"
							class="close pull-right"
							aria-label="Close"
							(click)="removeLogo(observables.selectedCustomer)"
							tooltip="{{ translate.REMOVE_LOGO }}"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<img src="data:image/jpeg;base64,{{ observables.selectedCustomer.logo }}" />
					</div>
					<label class="no-logo-box m-0" *ngIf="!observables.selectedCustomer.logo">
						{{ translate.CHOOSE_LOGO }}
						<input
							type="file"
							accept="image/*"
							(change)="uploadCustomerLogo($event, observables.selectedCustomer)"
							name="picture"
							hidden
						/>
					</label>
				</div>
				<div class="customer-name">
					<div class="title">
						{{ observables.selectedCustomer.name }}
					</div>
				</div>
			</div>
			<div class="details-cont">
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.CUSTOMER_NO }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.no }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.CITY }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.city }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.ADDRESS }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.address }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.EMAIL }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.eMail }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.CONTACT }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.contact }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.CONTACT_PHONE }}:</div>
					<div class="col-md-8">
						{{ observables.selectedCustomer.phoneNo }}
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-control-text">{{ translate.FILES }}:</div>
					<div class="col-md-8 mb-3">
						<label class="button button-gray d-inline-flex mr-2 mr-2">
							<input
								type="file"
								multiple
								[disabled]="observables.selectedCustomersLoading"
								[accept]="allowedFileType"
								[(ngModel)]="uploadedFiles"
								(change)="selectCustomerFiles(observables.selectedCustomer, $event)"
								hidden
							/>

							<fa-icon class="mr-1 fs-14" icon="plus"></fa-icon>
							{{ 'CHOOSE_FILES' | translate }}
						</label>
						<ng-container *ngIf="selectedFiles?.length > 0">
							<button
								type="button"
								[disabled]="observables.selectedCustomersLoading"
								class="button mr-2"
								(click)="submitCustomerFiles(observables.selectedCustomer)"
							>
								{{ 'SUBMIT' | translate }}
							</button>
							<button type="button" class="button button-gray" (click)="emptyUploadQueue()">
								{{ 'CANCEL' | translate }}
							</button>

							<div>
								<div class="mb-3">{{ translate.INSERT_FILES_INFO }}</div>
								<div *ngFor="let file of selectedFiles; let i = index">
									{{ file.fileName }}
									<fa-icon
										icon="times"
										class="remove"
										(click)="removeFromUploadQueue(i)"
										[tooltip]="translate.REMOVE_FILE"
									></fa-icon>

									<textarea
										class="form-control"
										[(ngModel)]="uploadedFilesDescription[i]"
										[placeholder]="translate.DESCRIPTION"
										rows="2"
										cols="50"
									></textarea>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<div class="documents-cont col-12">
					<div class="loading-cont" *ngIf="observables.selectedCustomersLoading">
						<div class="spinner-border"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>

					<div class="row" *ngIf="!observables.selectedCustomersLoading">
						<div class="col-lg-12 col-12" *ngFor="let doc of observables.selectedCustomer.customerFiles">
							<a
								class="file-link"
								href="{{ customerFileDownloadPrefix }}{{ doc.fileName }}{{ doc.fileExtension }}"
								download="{{ doc.fileName }}{{ doc.fileExtension }}"
								target="_blank"
								title="{{ doc.fileName }}{{ doc.fileExtension }}"
							>
								<div class="document-card">
									<svg-icon key="pdf" *ngIf="doc.fileExtension === '.pdf'"></svg-icon>
									<svg-icon key="zip" *ngIf="doc.fileExtension === '.zip' || doc.fileExtension === '.rar'"></svg-icon>
									<svg-icon key="text" *ngIf="doc.fileExtension !== '.pdf' && doc.fileExtension !== '.zip'"></svg-icon>
									<div class="doc-info">
										<div class="doc-title">
											<span class="file-title">{{ doc.fileName }}</span>
											<span class="file-ext">{{ doc.fileExtension }}</span>
										</div>
										<div class="muted-text">
											{{ doc.description }}
											<br />
											{{ doc.createdDate | date: 'dd-MM-yyyy' }}
										</div>
									</div>

									<button
										class="button button-gray delete-file-button"
										(click)="$event.preventDefault(); removeFile(observables.selectedCustomer, doc)"
									>
										<fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
									</button>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer modal-footer-remove">
		<div class="modal-footer-buttons">
			<button type="button" class="button button-gray" (click)="closeModalAndCleanUp()">
				{{ 'CLOSE' | translate }}
			</button>
		</div>
	</div>
</ng-template>
