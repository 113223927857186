import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductData from './product-data.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductDataEffects } from './product-data.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductData.productDataFeatureKey, fromProductData.reducer),
		EffectsModule.forFeature([ProductDataEffects]),
	],
})
export class ProductDataStoreModule {}
