import { createAction, props } from '@ngrx/store';

import { Seller } from '../../shared/models/seller';
import { Update } from '@ngrx/entity';

export const loadSellers = createAction('[Sellers Page] Load Sellers');

export const loadSellersSuccess = createAction('[Sellers API] Loaded Sellers Success', props<{ sellers: Seller[] }>());

export const loadSellersFailure = createAction('[Sellers API] Loaded Sellers Failure', props<{ error: any }>());

export const addSeller = createAction('[Sellers Page] Add Seller', props<{ seller: Seller }>());

export const addSellerSuccess = createAction('[Sellers API] Add Seller Success', props<{ seller: Seller }>());

export const addSellerFailure = createAction('[Sellers API] Add Seller Failure', props<{ error: any }>());

export const updateSeller = createAction('[Sellers Page] Update Seller', props<{ seller: Update<Seller> }>());

export const updateSellerSuccess = createAction(
	'[Sellers API] Update Seller Success',
	props<{ seller: Update<Seller> }>()
);

export const updateSellerFailure = createAction('[Sellers API] Update Seller Failure', props<{ error: any }>());
