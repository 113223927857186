import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromHomePage from './home-page.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HomePageEffects } from './home-page.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromHomePage.homePageFeatureKey, fromHomePage.reducer),
		EffectsModule.forFeature([HomePageEffects]),
	],
})
export class HomePageStoreModule {}
