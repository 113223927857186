<ng-container *ngIf="translate && { productGroupsLoading: productGroupsLoading$ | async } as observables">
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<div class="row">
		<div class="spinner-border small" *ngIf="observables.productGroupsLoading"></div>

		<ng-container *ngIf="productGroups">
			<div class="nav-dropdown">
				<ul class="nav nav-pills">
					<li class="nav-item dropdown">
						<a
							class="nav-link dropdown-toggle"
							data-toggle="dropdown"
							href="#"
							role="button"
							aria-haspopup="true"
							aria-expanded="false"
						>
							{{ dropdownText }}
						</a>
						<div class="dropdown-menu">
							<a
								*ngFor="let productGroup of navTabsDropdown; index as i"
								class="dropdown-item"
								id="{{ productGroup.id }}"
								[href]="'#' + productGroup.code"
								role="tab"
								(click)="handleSectionClick(productGroup)"
								data-toggle="pill"
							>
								{{ productGroup.description }}
								<!-- First Level - Edit  -->
								<fa-icon
									icon="pencil-alt"
									(click)="openModal(productGroupModalTemplate, productGroup, 0, 4)"
									class="product-groups-edit"
								>
								</fa-icon>
							</a>
						</div>
					</li>
				</ul>
			</div>

			<div class="nav-dropdown-add-button">
				<!-- First level - Add for small screens -->
				<button
					type="button"
					class="button product-group-add-sm"
					(click)="openModal(productGroupModalTemplate, null, 0, 4)"
					[disabled]="observables.productGroupsLoading"
				>
					<fa-icon icon="plus" class="pr-1"></fa-icon>{{ 'ADD' | translate }}
				</button>
			</div>

			<div class="d-flex align-items-start">
				<div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
					<ng-container *ngFor="let productGroup of navTabsDropdown; index as i">
						<a
							class="product-group-first-level product-group-nav-link nav-link"
							[ngClass]="{ active: i === 0 }"
							id="{{ productGroup.id }}"
							data-toggle="pill"
							[href]="'#' + productGroup.code"
							role="tab"
							(click)="handleSectionClick(productGroup)"
						>
							{{ productGroup.description }}
							<fa-icon
								icon="pencil-alt"
								(click)="openModal(productGroupModalTemplate, productGroup, 0, productGroup.columns)"
								class="product-groups-edit"
							>
							</fa-icon>
						</a>
					</ng-container>
					<!-- First level - ADD Big screens -->
					<button type="button" class="button mt-2" (click)="openModal(productGroupModalTemplate, null, 0, 4)">
						<fa-icon icon="plus" class="pr-1"></fa-icon>{{ 'ADD' | translate }}
					</button>
				</div>
			</div>

			<div class="tab-content" id="nav-tabContent">
				<ng-container *ngFor="let productGroup of navTabsDropdown; index as i">
					<div
						*ngIf="productGroup.level === 0"
						class="tab-pane"
						[class]="i === 0 ? 'active show' : 'fade'"
						id="{{ productGroup.code }}"
						role="tabpanel"
						[attr.aria-labelledby]="productGroup.id"
					>
						<div class="row custom-row">
							<ng-container *ngFor="let element of numberOfColumns">
								<ng-container *ngIf="productGroup.columns >= element">
									<div class="col-lg-{{ 12 / productGroup.columns }} drop-zone" [id]="productGroup.code + element">
										<ng-container *ngFor="let productGroupChild of productGroups">
											<ng-container
												*ngIf="
													productGroupChild.parentCode === productGroup.code && productGroupChild.columns === element
												"
											>
												<div [id]="productGroupChild.code" class="product-group-second-level">
													<div class="product-group-nav-tab product-group-second-level-title">
														<span>{{ productGroupChild.description }}</span>
														<fa-icon
															icon="pencil-alt"
															(click)="
																openModal(productGroupModalTemplate, productGroupChild, 1, productGroupChild.columns)
															"
															class="product-groups-edit"
														>
														</fa-icon>
													</div>

													<div [id]="productGroupChild.code">
														<ng-container *ngFor="let productGroupGrandChild of productGroups">
															<div
																*ngIf="productGroupGrandChild.parentCode === productGroupChild.code"
																[id]="productGroupGrandChild.code"
																class="product-group-nav-tab-item product-group-third-level"
															>
																<span>{{ productGroupGrandChild.description }}</span>
																<fa-icon
																	icon="pencil-alt"
																	(click)="
																		openModal(
																			productGroupModalTemplate,
																			productGroupGrandChild,
																			2,
																			productGroupGrandChild.columns
																		)
																	"
																	class="product-groups-edit"
																>
																</fa-icon>
															</div>
														</ng-container>
													</div>
													<!-- Third level - ADD -->
													<div
														class="add-button-icon-only"
														(click)="openModal(productGroupModalTemplate, null, 2, 4, productGroupChild)"
													>
														<fa-icon icon="plus"> </fa-icon>
														<span class="add-product-group-text">{{ translate.ADD_ITEM }}</span>
													</div>
												</div>
											</ng-container>
										</ng-container>
										<!-- 2nd level - ADD -->
										<button
											type="button"
											class="button"
											[style.width.%]="100"
											[disabled]="observables.productGroupsLoading"
											(click)="openModal(productGroupModalTemplate, null, 1, element, productGroup)"
										>
											<fa-icon icon="plus" class="pr-1"></fa-icon>{{ 'ADD' | translate }}
										</button>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>

	<ng-template #productGroupModalTemplate>
		<div class="modal-wrap">
			<div class="modal-header">
				<h4 class="modal-title">
					{{ productGroupForm.value.id ? ('EDIT' | translate) : ('ADD' | translate) }}
					{{ translate.PRODUCT_GROUP }}
				</h4>
				<button type="button" class="close pull-right" aria-label="Close" (click)="productGroupModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<!-- Modal body -->
			<form [formGroup]="productGroupForm" (ngSubmit)="submitProductGroupForm()">
				<div class="modal-body">
					<div *ngIf="!productGroupForm.value.id" class="row mb-3">
						<div class="col-12 d-inline-flex">
							<div class="col-6">
								<label>
									<input
										type="radio"
										name="create"
										value="createCustom"
										(click)="changeModalDropdown('createCustom')"
										checked
									/>
								</label>
								<span class="form-control-text">
									{{ translate.CREATE_CUSTOM }}
								</span>
							</div>

							<div class="col-6">
								<label>
									<input type="radio" name="create" value="isNav" (click)="changeModalDropdown('isNav')" />
								</label>
								<span class="form-control-text">
									{{ translate.IS_NAV }}
								</span>
							</div>
						</div>
					</div>

					<ng-container *ngIf="modalDropdownSection === 'createCustom'">
						<div class="row mb-3">
							<div class="col-lg-4 col-sm-12 form-control-text">
								{{ translate.CODE }}:
								<span class="required-field">*</span>
							</div>
							<div class="col-lg-8 col-sm-12">
								<input
									class="form-control input-field"
									type="text"
									formControlName="code"
									[ngClass]="{
										'is-invalid': submitted && productGroupForm.controls.code.errors
									}"
								/>
								<app-form-validation-error-msg
									*ngIf="submitted && productGroupForm.controls.code.errors; let errors"
									class="invalid-feedback"
									[errors]="errors"
									[errorMessages]="translateErrors"
								>
								</app-form-validation-error-msg>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-lg-4 col-sm-12 form-control-text">
								{{ translate.DESCRIPTION }}:
								<span class="required-field">*</span>
							</div>
							<div class="col-lg-8 col-sm-12">
								<input
									class="form-control input-field"
									type="text"
									formControlName="description"
									[ngClass]="{
										'is-invalid': submitted && productGroupForm.controls.description.errors
									}"
								/>
								<app-form-validation-error-msg
									*ngIf="submitted && productGroupForm.controls.description.errors; let errors"
									class="invalid-feedback"
									[errors]="errors"
									[errorMessages]="translateErrors"
								>
								</app-form-validation-error-msg>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="modalDropdownSection === 'isNav'">
						<div class="row mb-3">
							<div class="col-lg-4 col-sm-12 form-control-text">
								{{ translate.CODE }}:
								<span class="required-field">*</span>
							</div>
							<div class="col-lg-8 col-sm-12">
								<p-dropdown
									[options]="navProductGroupsDropdown"
									styleClass="form-control input-field"
									[ngClass]="{ 'is-invalid': submitted }"
									formControlName="code"
									[filter]="true"
									filterBy="label"
								></p-dropdown>

								<app-form-validation-error-msg
									*ngIf="submitted && productGroupForm.controls.code.errors; let errors"
									class="invalid-feedback"
									[errors]="errors"
									[errorMessages]="translateErrors"
								>
								</app-form-validation-error-msg>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-lg-4 col-sm-12 form-control-text">
								{{ translate.DESCRIPTION }}:
								<span class="required-field">*</span>
							</div>
							<div class="col-lg-8 col-sm-12">
								<input
									class="form-control input-field"
									type="text"
									formControlName="description"
									[ngClass]="{
										'is-invalid': submitted && productGroupForm.controls.description.errors
									}"
								/>
								<app-form-validation-error-msg
									*ngIf="submitted && productGroupForm.controls.description.errors; let errors"
									class="invalid-feedback"
									[errors]="errors"
									[errorMessages]="translateErrors"
								>
								</app-form-validation-error-msg>
							</div>
						</div>
					</ng-container>

					<div class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.ORDER }}:
							<span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<input
								class="form-control input-field"
								type="number"
								formControlName="order"
								[ngClass]="{
									'is-invalid': submitted && productGroupForm.controls.order.errors
								}"
							/>
							<app-form-validation-error-msg
								*ngIf="submitted && productGroupForm.controls.order.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>
					<div class="row mb-3" *ngIf="productGroupLevel === 0">
						<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.IMAGE }}:</div>
						<div class="col-lg-8 col-sm-12 custom-file-upload">
							<p-fileUpload
								#menuBannerViewChild
								type="file"
								name="viberImage"
								chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
								accept="image/*"
								[showUploadButton]="false"
								[showCancelButton]="false"
								(onSelect)="selectMenuBannerImage($event, menuBannerViewChild)"
								(onRemove)="removeMenuBannerImage()"
								[ngClass]="{ 'is-invalid': submitted && !selectedImage && !imagePreviewUrl }"
							>
							</p-fileUpload>
							<div *ngIf="!selectedImage && imagePreviewUrl; else selectedImagePreview" class="banner-image mt-2">
								<img [src]="imagePreviewUrl" class="modal-responsive-image" alt="Menu banner" />
								<fa-icon
									(click)="removeImage(productGroupForm.value)"
									class="delete-menu-banner"
									icon="times"
									class="close-button"
									[tooltip]="'REMOVE' | translate"
								></fa-icon>
							</div>
							<ng-template #selectedImagePreview>
								<div class="banner-image mt-2" *ngIf="selectedImage">
									<img
										[src]="'data:image/png;base64,' + selectedImage"
										class="modal-responsive-image"
										alt="Menu banner"
									/>
									<fa-icon
										(click)="removeImage(productGroupForm.value)"
										class="delete-menu-banner"
										icon="times"
										class="close-button"
										[tooltip]="'REMOVE' | translate"
									></fa-icon>
								</div>
							</ng-template>
							<app-form-validation-error-msg
								*ngIf="submitted && !selectedImage && !imagePreviewUrl; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>

					<div *ngIf="productGroupLevel === 0" class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.ACTION_LINK }}:</div>
						<div class="col-lg-8 col-sm-12">
							<input type="text" formControlName="actionLink" class="form-control input-field" />
						</div>
					</div>
					<app-form-validation-error-msg
						*ngIf="submitted && productGroupForm.controls.actionLink.errors; let errors"
						class="invalid-feedback"
						[errors]="errors"
						[errorMessages]="translateErrors"
					>
					</app-form-validation-error-msg>

					<div *ngIf="productGroupLevel != 2" class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.COLUMNS }}:
							<span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<input
								class="form-control input-field"
								type="number"
								formControlName="columns"
								[ngClass]="{
									'is-invalid': submitted && productGroupForm.controls.columns.errors
								}"
							/>
							<app-form-validation-error-msg
								*ngIf="submitted && productGroupForm.controls.columns.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>

					<div *ngIf="productGroupLevel === 0" class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.FROM_DATE }}: <span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<input
								bsDatepicker
								class="form-control date-picker"
								formControlName="bannerFromDate"
								[ngClass]="{ 'is-invalid': submitted && productGroupForm.controls.bannerFromDate.errors }"
								[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
								[placeholder]="translate.FROM_DATE"
								placement="top"
								readonly
							/>

							<app-form-validation-error-msg
								*ngIf="submitted && productGroupForm.controls.bannerFromDate.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>

					<div *ngIf="productGroupLevel === 0" class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.TO_DATE }}: <span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<input
								bsDatepicker
								class="form-control date-picker"
								formControlName="bannerToDate"
								[ngClass]="{ 'is-invalid': submitted && productGroupForm.controls.bannerToDate.errors }"
								[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
								[placeholder]="translate.TO_DATE"
								placement="top"
								readonly
							/>

							<app-form-validation-error-msg
								*ngIf="submitted && productGroupForm.controls.bannerToDate.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>

					<div class="row mb-3" *ngIf="productGroupLevel != 0">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.PARENT }}:
							<span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<p-dropdown
								[options]="dropdownOptions"
								styleClass="form-control input-field"
								[ngClass]="{ 'is-invalid': submitted }"
								formControlName="parentCode"
								[filter]="true"
								filterBy="label"
							></p-dropdown>

							<app-form-validation-error-msg
								*ngIf="submitted && productGroupForm.controls.parentCode.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors"
							>
							</app-form-validation-error-msg>
						</div>
					</div>
				</div>
				<!-- Modal footer -->
				<div class="modal-footer">
					<div class="required-field-text">
						{{ translateErrors.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
					</div>
					<div class="modal-footer-buttons">
						<div *ngIf="productGroupForm.value.id" class="modal-delete-button">
							<button
								type="button"
								class="button button-gray button-delete"
								(click)="openDeleteModal(deleteModalTemplate)"
							>
								{{ 'DELETE' | translate }}
							</button>
						</div>
						<div class="float-right">
							<button type="button" class="button button-gray" (click)="productGroupModal.hide()">
								{{ 'CLOSE' | translate }}
							</button>
							<button
								type="submit"
								class="button"
								[disabled]="productGroupForm.dirty || selectedImage ? false : true || observables.productGroupsLoading"
							>
								{{ 'SUBMIT' | translate }}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</ng-template>
	<!-- Remove -->
	<ng-template #deleteModalTemplate>
		<div class="modal-header">
			<h4 class="modal-title">{{ 'DELETE' | translate }} {{ translate.TITLE }}</h4>
			<button
				type="button"
				class="close pull-right"
				aria-label="Close"
				(click)="onModalHide(productGroupModalTemplate)"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.DELETE_PRODUCT_GROUP_BODY_HAS_CHILD }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons remove-buttons">
				<button type="button" class="button button-gray" (click)="onModalHide(productGroupModalTemplate)">
					{{ 'CLOSE' | translate }}
				</button>
				<button (click)="delete('one')" type="button" class="button button-gray button-delete mr-1">
					{{ translate.REMOVE_ONE }}
				</button>
				<button (click)="delete('all')" type="button" class="button button-gray button-delete mr-1">
					{{ translate.REMOVE_ALL_CHILDREN }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
