import { createAction, props } from '@ngrx/store';

import { WebUser } from '../../shared/models/web-user';
import { Update } from '@ngrx/entity';

export const loadWebUsers = createAction('[Web Users Page] Load Web Users');

export const loadWebUsersSuccess = createAction(
	'[Web Users API] Loaded Web Users Success',
	props<{ webUsers: WebUser[] }>()
);

export const loadWebUsersFailure = createAction('[Web Users API] Loaded Web Users Failure', props<{ error: any }>());

export const addWebUser = createAction('[Web Users Page] Add Web User', props<{ webUser: WebUser }>());

export const addWebUserSuccess = createAction('[Web Users API] Add Web User Success', props<{ webUser: WebUser }>());

export const addWebUserFailure = createAction('[Web Users API] Add Web User Failure', props<{ error: any }>());

export const updateWebUser = createAction('[Web Users Page] Update Web User', props<{ webUser: Update<WebUser> }>());

export const updateWebUserSuccess = createAction(
	'[Web Users API] Update Web User Success',
	props<{ webUser: Update<WebUser> }>()
);

export const updateWebUserFailure = createAction('[Web Users API] Update Web User Failure', props<{ error: any }>());

export const deleteWebUser = createAction('[Web Users Page] Delete Web User', props<{ webUserId: number }>());

export const deleteWebUserSuccess = createAction(
	'[Web Users API] Delete Web User Success',
	props<{ webUserId: number }>()
);

export const deleteWebUserFailure = createAction('[Web Users API] Delete Web User Failure', props<{ error: any }>());
