import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromWebUser from './web-user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WebUserEffects } from './web-user.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromWebUser.webUsersFeatureKey, fromWebUser.reducer),
		EffectsModule.forFeature([WebUserEffects]),
	],
})
export class WebUsersStoreModule {}
