import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { Product } from '../../shared/models/product';
import * as ProductActions from './product-list.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { ActionBanner } from 'src/app/shared/models/action-banner';

export const productListFeatureKey = 'product-list';

export interface ProductListState extends EntityState<Product> {
	// additional entities state properties
	listTitle?: string;
	banner?: ActionBanner;
	statusState: StatusState;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
	selectId: (product: Product) => product.productNo,
});

export const initialState: ProductListState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const productReducer = createReducer(
	initialState,

	// Load Product List Items
	on(ProductActions.loadProductListItems, state => {
		return {
			...state,
			listTitle: '',
			banner: null,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductActions.loadProductListItemsSuccess, (state, action) =>
		adapter.setAll(action.productListItems.products, {
			...state,
			listTitle: action.productListItems.title,
			banner: action.productListItems.banner,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductActions.loadProductListItemsFailure, (state, action) => {
		return {
			...state,
			listTitle: '',
			banner: null,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductListState | undefined, action: Action) {
	return productReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
