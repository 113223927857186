import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'specialOfferValue',
})
export class SpecialOfferValuePipe implements PipeTransform {
	transform(specialOffer: number): string {
		// 1: Action
		// 2: Discount on quantity
		// 3: New product
		// 4: Soon
		// 5: By order
		// 6: End of life
		// 7: Call for price
		// 8: Special
		// 9: Viber action
		// 10: Vendorland
		switch (specialOffer) {
			case 1:
				return 'A';
			case 2:
				return 'K';
			case 3:
				return 'N';
			case 4:
				return 'U';
			case 5:
				return 'P';
			case 6:
				return 'E';
			case 7:
				return 'C';
			case 8:
				return 'S';
			case 9:
				return 'V';
			case 10:
				return 'W';
			default:
				return 'L';
		}
	}
}
