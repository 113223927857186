/**
 * *Based on: https://stackoverflow.com/a/49339932*
 *
 * ---
 *
 * Forces image URL refresh by adding ```Date.now()``` timestamp at the end of the URL.\
 * This helps prevent image caching, as browsers cache images if image url is not changed,
 * which is to be avoided when updating images.
 * @param url - Image URL
 * @returns Timestamped Image URL
 */
export function preventImageCache(url: string) {
	return `${url}?time=${Date.now()}`;
}
