import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/sr/environment';
import { User } from '../../shared/models/user';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(private router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('admin_loggedUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(user: User): Observable<User> {
		if (user.username === 'b2bteam' && user.password === 'b2b2020..') {
			localStorage.setItem('admin_loggedUser', JSON.stringify(user));
			this.currentUserSubject.next(user);
			return of(user);
		} else {
			return of(null);
		}
	}

	logout(withoutRedirect?: boolean): void {
		localStorage.removeItem('admin_loggedUser');
		this.currentUserSubject.next(null);
		if (!withoutRedirect) {
			this.router.navigate(['/login']);
		}
	}

	public changePassword(): void {
		sessionStorage.setItem('emptyLocalStorage', '1');
		localStorage.removeItem('admin_loggedUser');
		document.location.href = environment.identityServerConf.url + environment.identityServerConf.changePasswordPath;
	}
}
