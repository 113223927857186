import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromInactiveUser from './inactive-users.reducer';
import { InactiveUsersEffects } from './inactive-users.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromInactiveUser.inactiveUserFeatureKey, fromInactiveUser.reducer),
		EffectsModule.forFeature([InactiveUsersEffects]),
	],
})
export class InactiveUsersStoreModule {}
