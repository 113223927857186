import { createAction, props } from '@ngrx/store';

export const loadDashboardLogsPerYear = createAction(
	'[Dashboard Logs Page] Load Dashboard Logs',
	props<{ year: number }>()
);

export const loadDashboardLogsPerYearSuccess = createAction(
	'[Dashboard Logs API] Loaded Dashboard Logs Success',
	props<{
		browsersUsed: { keys: string[]; values: unknown[] };
		devicesUsed: any[][];
		operatingSystemsUsed: { keys: string[]; values: unknown[] };
		visitsPerMonth: { keys: string[]; values: unknown[] };
	}>()
);

export const loadDashboardLogsPerYearFailure = createAction(
	'[Dashboard Logs API] Loaded Dashboard Logs Failure',
	props<{ error: any }>()
);
