/* 🤖 this file was generated by svg-to-ts*/

import { MyIcon } from './icons/my-icons.model';

export type IconNameSubset<T extends Readonly<MyIcon[]>> = T[number]['name'];

export * from './icons/app-checkmark.icon';
export * from './icons/app-down-small.icon';
export * from './icons/app-excel.icon';
export * from './icons/app-group.icon';
export * from './icons/app-main-page.icon';
export * from './icons/app-pdf.icon';
export * from './icons/app-pricelist.icon';
export * from './icons/app-products.icon';
export * from './icons/app-services.icon';
export * from './icons/app-text.icon';
export * from './icons/app-users.icon';
export * from './icons/app-zip.icon';
export * from './icons/my-icons.model';
