export class AllowedQuantity {
	id: number;
	productGroup: string;
	productName: string;
	allowedQtty: number;

	constructor() {
		this.id = 0;
		this.productGroup = '';
		this.productName = '';
		this.allowedQtty = 0;
	}
}
