import { customerRecordsFeatureKey, CustomerRecordState, selectAll } from './customer-record.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectCustomerRecordState = createFeatureSelector<CustomerRecordState>(customerRecordsFeatureKey);

export const selectAllCustomerRecords = createSelector(selectCustomerRecordState, selectAll);

export const selectCustomerRecordsLoading = createSelector(
	selectCustomerRecordState,
	state => state.statusState === LoadingState.LOADING
);

export const selectCustomerRecordsError = createSelector(selectCustomerRecordState, (state: CustomerRecordState) =>
	getError(state.statusState)
);
