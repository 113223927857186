import { Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/sr/environment';

import { ProductExtraState } from 'src/app/root-store/product-extras-store/product-extras.reducer';
import * as productExtraSelectors from '../../root-store/product-extras-store/product-extras.selectors';
import * as productExtraActions from '../../root-store/product-extras-store/product-extras.actions';

import { ProductAdditionalTypeState } from 'src/app/root-store/product-additional-types-store/product-additional-types.reducer';
import * as productAdditionalTypeSelectors from '../../root-store/product-additional-types-store/product-additional-types.selectors';
import * as productAdditionalTypeActions from '../../root-store/product-additional-types-store/product-additional-types.actions';

import { Store } from '@ngrx/store';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { fileToBase64 } from 'src/app/shared/utils/file-to-base64';
import { SelectItem } from 'primeng/api';
import { productAdditionalType } from 'src/app/shared/enums/product-additional-type.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { fileExtensionParser } from '../../shared/utils/file-extension-parser';
import { ProductAdditionalType } from 'src/app/shared/models/product-additional-type';
import { ProductExtra } from 'src/app/shared/models/product-extra';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-files',
	templateUrl: './product-files.component.html',
	styleUrls: ['./product-files.component.scss'],
})
export class ProductFilesComponent implements OnInit, OnChanges {
	@Input() inputProductId: string;
	@Input() activeIndex: number;
	ngUnsubscribe: Subject<object> = new Subject();
	deleteProductFileModal: BsModalRef;

	currentProductFile: ProductExtra = null;

	uploadFiles: any;

	translateProductAdditionalTypes: any;
	translate: any;
	environment = environment;

	productFileDownloadPrefix: string;

	additionalTypesLoading$: Observable<boolean>;
	additionalTypes$: Observable<ProductAdditionalType[]>;
	additionalTypes: ProductAdditionalType[];

	productFilesLoading$: Observable<boolean>;
	productFiles$: Observable<ProductExtra[]>;
	productFiles: any[];
	storedProductId: string;

	fileTypeDescriptionDropdown: SelectItem[] = [];

	fileExtensionParser = fileExtensionParser;

	selectedFiles: any[];

	constructor(
		private translateService: TranslateService,
		private modalService: BsModalService,
		private productExtraStore: Store<ProductExtraState>,
		private productAdditionalTypesStore: Store<ProductAdditionalTypeState>,
		private toastr: ToastrService
	) {}

	/**
	 * Triggers showing product files if product files tab is selected
	 */
	ngOnChanges(): void {
		if (this.activeIndex === 1) {
			this.showProductFiles();
		}
	}

	ngOnInit(): void {
		this.productFileDownloadPrefix = environment.mediaLocation.productFiles;

		this.translateService.get('PRODUCT_FILES').subscribe((resp: any) => (this.translate = resp));

		this.additionalTypes$ = this.productAdditionalTypesStore.select(
			productAdditionalTypeSelectors.selectAllProductAdditionalTypes
		);

		this.additionalTypes$
			.pipe(
				mergeMap(types =>
					this.translateService.get('PRODUCT_ADDITIONAL_TYPES').pipe(map(translate => [translate, types]))
				)
			)
			.subscribe(([translate, types]) => {
				if (!types?.length) {
					this.productAdditionalTypesStore.dispatch(productAdditionalTypeActions.loadProductAdditionalTypes());
				}
				this.additionalTypes = types;
				if (this.additionalTypes?.length) {
					this.translateProductAdditionalTypes = translate;
					for (const file of this.additionalTypes) {
						if (file.name === productAdditionalType.SPECIFICATION) {
							this.fileTypeDescriptionDropdown.unshift({
								value: file.id,
								label: this.translateProductAdditionalTypes[file.title],
							});
						} else if (file.name !== productAdditionalType.VIDEO) {
							this.fileTypeDescriptionDropdown.push({
								value: file.id,
								label: this.translateProductAdditionalTypes[file.title],
							});
						}
					}
				}
			});
	}

	showProductFiles() {
		this.productFilesLoading$ = this.productExtraStore.select(productExtraSelectors.selectProductExtrasLoading);

		this.productExtraStore
			.select(productExtraSelectors.selectStoredProductId)
			.subscribe(data => (this.storedProductId = data));

		this.productExtraStore
			.select(productExtraSelectors.selectProductFiles)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				let dataCopy = cloneDeep(data);
				dataCopy = dataCopy.map(productFile => {
					productFile.fileName = productFile.fileName.slice(0, productFile.fileName.lastIndexOf('.'));
					return productFile;
				});

				this.productFiles = dataCopy;
				if (this.inputProductId !== this.storedProductId && this.inputProductId !== '') {
					this.productExtraStore.dispatch(productExtraActions.loadProductExtras({ productId: this.inputProductId }));
				}
			});
	}

	onSelectFile(event: any) {
		if (!this.selectedFiles) {
			this.selectedFiles = [];
		}

		for (const file of event.target.files) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				if (file.size > 0) {
					const fileName = file.name;
					const base64File = fileToBase64(fileReader);

					const fileForUpload = {
						productNo: this.inputProductId,
						fileName,
						file: base64File,
						additionalTypeId: this.additionalTypes.find(type => type.name === productAdditionalType.SPECIFICATION).id,
					};

					if (this.selectedFiles.length === 0) {
						this.selectedFiles.push(fileForUpload);
					} else if (!this.selectedFiles.some(productFile => productFile.file === base64File)) {
						this.selectedFiles.push(fileForUpload);
					}
				} else {
					this.toastr.warning(this.translate.FILE_NOT_UPLOADED + file.name);
				}
			};
		}
		this.uploadFiles = null;
	}

	fileUpload() {
		this.productExtraStore.dispatch(
			productExtraActions.addProductFiles({
				productFiles: this.selectedFiles,
			})
		);
		this.selectedFiles = [];
	}

	setDropdownType(event: any, i: number) {
		const type: string = event.value;
		this.selectedFiles[i].additionalTypeId = type;
	}

	removeFromUploadQueue(i: number) {
		this.selectedFiles.splice(i, 1);
	}

	openConfirmationModal(deleteProductFileTemplate: TemplateRef<any>, productFile: ProductExtra) {
		this.currentProductFile = productFile;
		this.deleteProductFileModal = this.modalService.show(deleteProductFileTemplate, { class: 'modal-md' });
	}

	deleteProductFile() {
		this.productExtraStore.dispatch(productExtraActions.deleteProductFile({ id: this.currentProductFile.id }));
		this.deleteProductFileModal.hide();
	}
}
