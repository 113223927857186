import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromTargetActions from './targets.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TargetService } from '../../core/services/target.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Target } from 'src/app/shared/models/target';

@Injectable()
export class TargetEffects {
	constructor(
		private actions$: Actions,
		private targetService: TargetService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadTargetsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTargetActions.loadTargets),
			mergeMap(action =>
				this.targetService.getTargets({ startDate: action.startDate, endDate: action.endDate }).pipe(
					map(targets => {
						targets = targets.map(target => {
							target.quantityRealizationPercentage = target.quantityRealization
								? (target.quantity / 100) * target.quantityRealization
								: null;
							target.priceRealizationPercentage = target.priceRealization
								? (target.price / 100) * target.priceRealization
								: null;

							return target;
						});

						return fromTargetActions.loadTargetsSuccess({ targets });
					}),
					catchError(error => of(fromTargetActions.loadTargetsFailure({ error })))
				)
			)
		)
	);

	addTargetEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTargetActions.addTarget),
			mergeMap(action =>
				this.targetService.addTarget(action.target).pipe(
					switchMap(target =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(successMessage => {
								this.toastr.success(successMessage);

								const expandedTargetData: Target = {
									...target,
									customer: action.target.customer,
									customerNo: action.target.customerNo,
									manufacturer: action.target.manufacturer,
									manufacturerCode: action.target.manufacturerCode,
									productGroup: action.target.productGroup,
									productGroupCode: action.target.productGroupCode,
								};

								return fromTargetActions.addTargetSuccess({
									target: {
										...expandedTargetData,
									},
								});
							}),
							catchError(error => of(fromTargetActions.addTargetFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateTargetEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromTargetActions.updateTarget),
			mergeMap(action =>
				this.targetService.updateTarget(action.target).pipe(
					switchMap(target =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromTargetActions.updateTargetSuccess({ target });
							}),
							catchError(error => of(fromTargetActions.updateTargetFailure({ error })))
						)
					)
				)
			)
		)
	);
}
