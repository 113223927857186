<ng-container *ngIf="translate && { promotionsLoading: promotionsLoading$ | async } as observables">
	<div>
		<div class="page-title">
			<h2>{{ translate.PROMOTIONS }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openPromotionModal(updatePromotionTemplate, null)"
			[disabled]="observables.promotionsLoading"
			data-tid="addPromotion"
		>
			<fa-icon icon="plus"></fa-icon>
			{{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.promotionsLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.promotionsLoading">
			<p-table
				#promotionTable
				[value]="promotions$ | async"
				[autoLayout]="true"
				class="promotions-prime-table"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				sortField="id"
				[sortOrder]="-1"
				[(first)]="firstPageElementIndex"
				[globalFilterFields]="['id', 'title', 'dataType', 'extra', 'detailUrl', 'date', 'active']"
			>
				<ng-template pTemplate="caption">
					<div class="only-active-checkbox">
						<label class="chbx-container mr-3" [class.checked]="selectedPromotionsCategories">
							{{ translate.ONLY_ACTIVE }}
							<input
								type="checkbox"
								[(ngModel)]="selectedPromotionsCategories"
								name="promotionsCategories"
								(change)="categoryFilterChange($event)"
								[ngClass]="{ checked: selectedPromotionsCategories }"
							/>
							<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
						</label>
					</div>
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="promotionTable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'title'">
							{{ translate.TITLE }}
							<p-sortIcon [field]="'title'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'dataType'">
							{{ translate.TYPE }}
							<p-sortIcon [field]="'dataType'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'extra'" class="text-center">
							{{ translate.SHOW_ON_MAIN }}
							<p-sortIcon [field]="'extra'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'detailUrlDisplayText'">
							{{ translate.LINK_TO_DETAILS }}
							<p-sortIcon [field]="'detailUrlDisplayText'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'date'">
							{{ translate.DATE }}
							<p-sortIcon [field]="'date'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'active'" [style.width.px]="140">
							{{ translate.STATUS }}
							<p-sortIcon [field]="'active'"></p-sortIcon>
						</th>

						<th [style.width.px]="90">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-promotion let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ promotion.id }}</b>
						</td>
						<td class="line-breaker">{{ promotion.title }}</td>
						<td>{{ promotion.dataType === 0 ? translate.PICTURE : translate.TEXT }}</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="promotion.extra"></fa-icon></td>
						<td>
							<a
								href="{{ promotion.detailUrl }}"
								*ngIf="promotion.detailUrl || promotion.detailUrlDisplayText"
								[title]="promotion.detailUrl"
								target="_blank"
							>
								{{ promotion.detailUrlDisplayText }}
							</a>
						</td>
						<td>{{ promotion.date | date: 'dd.MM.yyyy' }}</td>
						<td>
							<button
								type="button"
								class="button button-gray mr-2"
								(click)="openConfirmationModal(changePromotionStatusTemplate, promotion)"
							>
								{{ promotion.active ? ('DEACTIVATE' | translate) : ('ACTIVATE' | translate) }}
								<fa-icon icon="chevron-right"></fa-icon>
							</button>
						</td>
						<td>
							<button
								type="button"
								class="button button-gray"
								(click)="openPromotionModal(updatePromotionTemplate, promotion)"
								data-tid="editPromotion"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<ng-template #updatePromotionTemplate>
		<div class="modal-header">
			<h4 class="modal-title">{{ promotionForm.value.id ? translate.EDIT_PROMOTION : translate.ADD_PROMOTION }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updatePromotionModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="promotionForm" (ngSubmit)="submitPromotionForm()">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.TITLE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="title"
							[style.max-width.px]="400"
							[ngClass]="{ 'is-invalid': submitted && promotionForm.controls.title.errors }"
							maxlength="64"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && promotionForm.controls.title.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.INTRO_TEXT }}:</div>
					<div class="col-lg-8 col-sm-12">
						<textarea
							class="form-control"
							type="text"
							formControlName="introText"
							[style.max-width.px]="400"
							rows="3"
						></textarea>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.TEXT }}:</div>
					<div class="col-lg-8 col-sm-12">
						<textarea
							class="form-control"
							type="text"
							formControlName="description"
							[style.max-width.px]="400"
							rows="3"
						></textarea>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.TYPE }}:</div>
					<div class="col-8">
						<label class="radio-btn mb-0 mr-3">
							<input type="radio" name="dataType" formControlName="dataType" [value]="0" /> {{ translate.PICTURE }}
						</label>
						<label class="radio-btn mb-0">
							<input type="radio" name="dataType" formControlName="dataType" [value]="1" /> {{ translate.TEXT }}
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.CONNECT_TO_FILE }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="promotionForm.value.linkFile">
							<input
								type="checkbox"
								formControlName="linkFile"
								[(ngModel)]="promotionForm.value.linkFile"
								[ngClass]="{ checked: promotionForm.value.linkFile }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.SMALL_PIC }}
						{{ environment.mediaRestrictions.promotions.small_image.width }}×{{
							environment.mediaRestrictions.promotions.small_image.height
						}}
						(W×H): <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#smallImageViewChild
							type="file"
							name="smallImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="select($event, 'smallImage', smallImageViewChild)"
							(onRemove)="remove('smallImage')"
							[ngClass]="{ 'is-invalid': submitted && !selectedSmallImage && !userSmallImage }"
							data-tid="chooseFileSmallImage"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedSmallImage && userSmallImage"
							[src]="userSmallImage"
							class="modal-responsive-image"
							alt="small image"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && !selectedSmallImage && !userSmallImage"
							class="invalid-feedback"
							[errors]="customErrors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.LARGE_PIC }}
						{{ environment.mediaRestrictions.promotions.big_image.width }}×{{
							environment.mediaRestrictions.promotions.big_image.height
						}}
						(W×H): <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#bigImageViewChild
							name="bigImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="select($event, 'bigImage', bigImageViewChild)"
							(onRemove)="remove('bigImage')"
							[ngClass]="{ 'is-invalid': submitted && !selectedBigImage && !userBigImage }"
							data-tid="chooseFileBigImage"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedBigImage && userBigImage"
							[src]="userBigImage"
							class="modal-responsive-image"
							alt="file"
							data-tid="chooseFile"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && !selectedBigImage && !userBigImage"
							class="invalid-feedback"
							[errors]="customErrors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.FILE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#fileViewChild
							name="file"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							(onSelect)="select($event, 'file', fileViewChild)"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onRemove)="remove('file')"
						>
						</p-fileUpload>
						<p *ngIf="!promotionForm.value.file && userFile">
							<a href="{{ userFileDownloadLink }}" download="{{ userFile }}" target="_blank">{{ userFile }}</a>
						</p>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.SHOW_ON_MAIN }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="promotionForm.value.extra">
							<input
								type="checkbox"
								formControlName="extra"
								[(ngModel)]="promotionForm.value.extra"
								[ngClass]="{ checked: promotionForm.value.extra }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.LINK_TO_DETAILS }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="linkedToDetails">
							<input
								type="checkbox"
								[(ngModel)]="linkedToDetails"
								[ngModelOptions]="{ standalone: true }"
								[ngClass]="{ checked: linkedToDetails }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<div class="row mb-3" *ngIf="linkedToDetails">
					<div class="col-lg-4 col-sm-12 text-right form-control-text">{{ translate.LINK_TITLE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input type="text" class="form-control" formControlName="detailUrlDisplayText" />
					</div>
				</div>
				<div class="row mb-3" *ngIf="linkedToDetails">
					<div class="col-lg-4 col-sm-12 text-right form-control-text">{{ translate.LINK_URL }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input type="text" class="form-control" formControlName="detailUrl" />
					</div>
				</div>
				<div class="row mb-1">
					<div class="col-4 form-control-text">{{ translate.CONTENT_DETAILS }}:</div>
					<div class="col-8">
						<label class="chbx-container mr-3" [class.checked]="contentToDetails">
							<input
								type="checkbox"
								[(ngModel)]="contentToDetails"
								[ngModelOptions]="{ standalone: true }"
								[ngClass]="{ checked: contentToDetails }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<div class="row mb-3" *ngIf="contentToDetails">
					<div class="col-lg-4 col-sm-12">&nbsp;</div>
					<div class="col-lg-8 col-sm-12">
						<textarea type="text" rows="8" class="form-control" formControlName="htmlContent"></textarea>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FROM_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="fromDate"
							[ngClass]="{ 'is-invalid': submitted && promotionForm.controls.fromDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.FROM_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && promotionForm.controls.fromDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.TO_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="toDate"
							[ngClass]="{ 'is-invalid': submitted && promotionForm.controls.toDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[placeholder]="translate.TO_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && promotionForm.controls.toDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateErrors.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updatePromotionModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="submit"
						class="button"
						[disabled]="
							promotionForm.dirty || selectedBigImage || selectedSmallImage || fileSelected
								? false
								: true || observables.promotionsLoading
						"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>

	<!-- Change promotion status Modal -->
	<ng-template #changePromotionStatusTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<p>{{ selectedPromotion.active ? translate.DEACTIVATE_PROM_TITLE : translate.ACTIVATE_PROM_TITLE }}</p>
			</h4>
			<button type="button" class="close pull-right" (click)="changePromotionStatusModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ selectedPromotion.active ? translate.DEACTIVATE_PROM_BODY : translate.ACTIVATE_PROM_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button" (click)="changePromotionStatus()">
					{{ 'SUBMIT' | translate }}
				</button>
				<button type="button" class="button button-gray" (click)="changePromotionStatusModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
