import { Action, createReducer, on } from '@ngrx/store';
import * as ProductExtraActions from './product-extras.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductExtra } from 'src/app/shared/models/product-extra';

export const productExtrasFeatureKey = 'productExtra';

export interface ProductExtraState extends EntityState<ProductExtra> {
	productId: string;
	statusState: StatusState;
}

export const adapter: EntityAdapter<ProductExtra> = createEntityAdapter<ProductExtra>();

export const initialState: ProductExtraState = adapter.getInitialState({
	// additional entity state properties
	productId: '',
	statusState: LoadingState.INIT,
});

const productExtrasReducer = createReducer(
	initialState,
	on(ProductExtraActions.storeProductId, (state, action) => {
		return {
			...state,
			productId: action.productId,
		};
	}),

	on(ProductExtraActions.loadProductExtras, state => {
		return {
			...state,
			productId: '',
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.loadProductExtrasSuccess, (state, action) =>
		adapter.setAll(action.productExtras, {
			...state,
			productId: action.productId,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.loadProductExtrasFailure, (state, action) => {
		return {
			...state,
			productId: '',
			statusState: { error: action.error },
		};
	}),

	// ProductFile

	on(ProductExtraActions.addProductFiles, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.addProductFilesSuccess, (state, action) =>
		adapter.addMany(action.productFiles, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.addProductFilesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductExtraActions.deleteProductFile, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.deleteProductFileSuccess, (state, action) =>
		adapter.removeOne(action.id, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.deleteProductFileFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	// ProductLink

	on(ProductExtraActions.addProductLink, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.addProductLinkSuccess, (state, action) =>
		adapter.addOne(action.productLink, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.addProductLinkFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductExtraActions.updateProductLink, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.updateProductLinkSuccess, (state, action) =>
		adapter.updateOne(action.productLink, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.updateProductLinkFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductExtraActions.removeProductLink, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductExtraActions.removeProductLinkSuccess, (state, action) =>
		adapter.removeOne(action.id, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductExtraActions.removeProductLinkFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductExtraState | undefined, action: Action) {
	return productExtrasReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
