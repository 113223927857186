import { Component, OnInit, TemplateRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Observable } from 'rxjs';
import { CustomerState } from '../root-store/customers-store/customer.reducer';
import * as customerSelectors from '../root-store/customers-store/customer.selectors';
import * as customerActions from '../root-store/customers-store/customer.actions';
import { Customer } from '../shared/models/customer';
import { deeperCopy } from '../shared/utils/deeper-copy';
import { environment } from 'src/environments/sr/environment';
import { fileValidator, imageValidator } from '../shared/utils/file-validator';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { ToastrService } from 'ngx-toastr';
import { fileExtensionParser } from '../shared/utils/file-extension-parser';
import { CustomerFile } from '../shared/models/customer-file';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
	viewCustomerModal: BsModalRef;
	environment = environment;
	translate: any;

	firstPageElementIndex = 0;

	validUpload = true;

	customers$: Observable<Customer[]>;
	customersLoading$: Observable<boolean>;

	selectedCustomer$: Observable<Customer>;
	selectedCustomersLoading$: Observable<boolean>;

	selectedFiles: any[];
	customerFileDownloadPrefix = environment.mediaLocation.customerFiles;
	uploadedFiles: any[];
	uploadedFilesDescription: string[];

	fileExtensionParser = fileExtensionParser;
	allowedFileType = ['.doc', '.docx', '.xls', '.xlsx', '.pdf', '.zip', '.rar', '.txt'];

	columns: any[] = [
		{ name: 'no', title: 'ID_NO', width: 17 },
		{ name: 'name', title: 'NAME', width: 80 },
		{ name: '', title: '', width: 13 },
	];

	constructor(
		private translateService: TranslateService,
		private modalService: BsModalService,
		private store: Store<CustomerState>,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.translateService.get('CUSTOMERS').subscribe((resp: any) => (this.translate = resp));

		this.customers$ = this.store.select(customerSelectors.selectAllCustomers);

		this.customersLoading$ = this.store.select(customerSelectors.selectCustomersLoading);
		this.selectedCustomersLoading$ = this.store.select(customerSelectors.selectCustomerInfoByIdLoading);
		this.store.dispatch(customerActions.loadCustomers());
	}

	openCustomerModal(viewCustomerTemplate: TemplateRef<any>, customer: Customer) {
		if (!customer.address) {
			this.store.dispatch(customerActions.loadCustomerInfoById({ customer }));
		}

		this.selectedCustomer$ = this.store.select(customerSelectors.selectCustomerById, {
			no: customer.no,
		});

		this.viewCustomerModal = this.modalService.show(viewCustomerTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	uploadCustomerLogo(event: any, customer: Customer) {
		const fileType = event.target.files[0].type.toString().slice(0, event.target.files[0].type.toString().indexOf('/'));
		if ('image' === fileType) {
			const reader = new FileReader();
			for (const file of event.target.files) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					const img = new Image();
					img.src = reader.result as string;
					img.onload = () => {
						if (fileValidator(file, 'size', environment.mediaRestrictions.customer_logo)) {
							if (imageValidator(img, 'sizeEq', environment.mediaRestrictions.customer_logo)) {
								const pom = deeperCopy(customer);
								pom.customerId = customer.no;
								pom.logo = fileToBase64(reader);
								this.store.dispatch(customerActions.updateCustomerExtraInfo({ customer: pom }));
							} else {
								this.translateService
									.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_EQ', {
										width: environment.mediaRestrictions.customer_logo.width,
										height: environment.mediaRestrictions.customer_logo.height,
									})
									.subscribe((text: string) => this.toastr.warning(text));
							}
						} else {
							this.translateService
								.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
									size: environment.mediaRestrictions.customer_logo.size / 1048576 + 'MB',
								})
								.subscribe((text: string) => this.toastr.warning(text));
						}
					};
				};
			}
		} else {
			this.toastr.warning(this.translate.ERROR.IMAGE_TYPE);
		}
	}

	selectCustomerFiles(customer: Customer, event: any) {
		this.uploadedFilesDescription = [];

		if (!this.selectedFiles) {
			this.selectedFiles = [];
		}

		for (const file of event.target.files) {
			if (this.allowedFileType.includes(fileExtensionParser(file.name).toLowerCase())) {
				if (file.name.length <= 23) {
					const fileReader = new FileReader();
					fileReader.readAsDataURL(file);
					fileReader.onload = () => {
						const fileName = file.name;
						const base64File = fileToBase64(fileReader);

						const fileForUpload = {
							customerID: customer.no,
							fileName,
							file: base64File,
						};

						if (this.selectedFiles.length === 0) {
							this.selectedFiles.push(fileForUpload);
							this.uploadedFilesDescription.push('');
						} else if (!this.selectedFiles.some(c => c.file === base64File)) {
							this.selectedFiles.push(fileForUpload);
							this.uploadedFilesDescription.push('');
						}
					};
				} else {
					this.translateService
						.get('ERROR.FILE_RESTRICTIONS.FILE.FILE_NAME_TOO_LONG', {
							fileName: file.name,
							length: 23,
						})
						.subscribe((text: string) => this.toastr.warning(text));
				}
			} else {
				this.translateService
					.get('ERROR.FILE_RESTRICTIONS.FILE.ONLY_FILES', {
						fileName: file.name,
					})
					.subscribe((text: string) => this.toastr.warning(text));
			}
		}

		this.uploadedFiles = null;
	}

	submitCustomerFiles(customer: Customer) {
		this.validUpload = true;
		for (let i = 0; i < this.selectedFiles.length; i++) {
			if (this.uploadedFilesDescription[i].length < 250) {
				this.selectedFiles[i].description = this.uploadedFilesDescription[i];
			} else {
				this.translateService
					.get('ERROR.FORM_VALIDATIONS.FIELD_SIZE_LIMIT_EXCEEDED_LENGTH', { length: 200 })
					.subscribe((text: string) => this.toastr.warning(text));

				this.validUpload = false;
			}
		}

		if (this.validUpload) {
			this.store.dispatch(customerActions.addCustomerFile({ customer, files: this.selectedFiles }));
			this.uploadedFilesDescription = this.uploadedFiles = this.selectedFiles = [];
			this.validUpload = true;
		}
	}

	removeFromUploadQueue(i: number) {
		this.selectedFiles.splice(i, 1);
	}

	emptyUploadQueue() {
		this.selectedFiles = [];
		this.validUpload = true;
	}

	removeLogo(customer: Customer) {
		this.store.dispatch(customerActions.removeCustomerLogo({ customer }));
	}

	removeFile(customer: Customer, file: CustomerFile) {
		this.store.dispatch(customerActions.removeCustomerFile({ customer, file }));
	}

	closeModalAndCleanUp() {
		this.viewCustomerModal.hide();
		this.validUpload = true;
		this.uploadedFilesDescription = this.selectedFiles = this.uploadedFiles = [];
	}
}
