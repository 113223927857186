export class Promotion {
	id: number;
	title: string;
	description: string;
	date: string;
	extra: boolean;
	active: boolean;
	smallImageType: string;
	bigImageType: string;
	showInPopUp: boolean;
	extraFile: string;
	dataType: number;
	introText: string;
	linkFile: number;
	htmlContent: string;
	detailUrl: string;
	detailUrlDisplayText: string;
	isPrizeGame: boolean;
	useCounter: boolean;
	useCounterForSmall: boolean;
	usePrizeGameTable: boolean;
	bigImage: string;
	smallImage: string;
	fromDate: Date;
	toDate: Date;

	constructor() {
		this.id = 0;
		this.title = '';
		this.description = '';
		this.date = '';
		this.extra = null;
		this.active = true;
		this.smallImageType = '';
		this.bigImageType = '';
		this.showInPopUp = false;
		this.extraFile = '';
		this.dataType = 0;
		this.introText = '';
		this.linkFile = 0;
		this.htmlContent = '';
		this.detailUrl = '';
		this.detailUrlDisplayText = '';
		this.isPrizeGame = false;
		this.useCounter = false;
		this.useCounterForSmall = false;
		this.usePrizeGameTable = false;
	}
}
