export function imageValidator(image: any, validateOption: string, targetResolution: any) {
	switch (validateOption) {
		case 'aspectRatio':
			return (image.naturalWidth * targetResolution.width) / targetResolution.height === image.naturalHeight
				? true
				: false;
		case 'sizeEq':
			return image.naturalHeight === targetResolution.height && image.naturalWidth === targetResolution.width
				? true
				: false;
		case 'sizeLt':
			return image.naturalHeight <= targetResolution.height && image.naturalWidth <= targetResolution.width;
		default:
			return false;
	}
}
export function fileValidator(file: any, validateOption: string, targetedFile: any) {
	switch (validateOption) {
		case 'size':
			return file.size <= targetedFile.size ? true : false;
		case 'type':
			return targetedFile.type === file.type ? true : false;
		default:
			return false;
	}
}
