import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/sr/environment';
import { DashboardLog } from 'src/app/shared/models/dashboard-log';

@Injectable({
	providedIn: 'root',
})
export class DashboardService {
	constructor(private http: HttpClient) {}

	getDashboardLogs(year: number): Observable<DashboardLog[]> {
		return this.http.get<DashboardLog[]>(environment.baseApiUrl + `api/Logging/${year}/dashboard/`);
	}
}
