import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/sr/environment';

export const authConfig: AuthConfig = {
	issuer: environment.identityServerConf.url,
	clientId: environment.identityServerConf.clientId, // The "Auth Code + PKCE" client
	responseType: 'code',
	redirectUri: environment.rootAppUrl + 'index.html',
	postLogoutRedirectUri: environment.rootAppUrl + 'index.html',
	silentRefreshRedirectUri: environment.rootAppUrl + 'silent-refresh.html',
	scope: environment.identityServerConf.scope, // Ask offline_access to support refresh token refreshes
	useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
	sessionChecksEnabled: true,
	requireHttps: false,
	clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
	customQueryParams: {
		country: environment.identityServerConf.country,
		username: localStorage.getItem('admin_id_token_claims_obj')
			? JSON.parse(localStorage.getItem('admin_id_token_claims_obj')).preferred_username
			: '',
	},
};
