import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';

import * as groupSelectors from '../root-store/groups-store/group.selectors';
import * as groupActions from '../root-store/groups-store/group.actions';
import { GroupState } from '../root-store/groups-store/group.reducer';
import * as featureSelectors from '../root-store/features-store/feature.selectors';
import * as featureActions from '../root-store/features-store/feature.actions';
import { FeatureState } from '../root-store/features-store/feature.reducer';

import { Group } from '../shared/models/group';
import { Feature } from 'src/app/shared/models/feature';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Update } from '@ngrx/entity';
import { cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-group',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.scss'],
})
export class GroupComponent implements OnInit, OnDestroy {
	updateGroupModal: BsModalRef;
	translate: any;
	translateFormValidationErrorMsgs: any;
	submitted: boolean;
	groupForm: any;
	ngUnsubscribe: Subject<object> = new Subject();
	groups$: Observable<Group[]>;
	groupsLoading$: Observable<boolean>;
	groupsSubscriber: Subscription;
	features$: Observable<Feature[]>;
	featuresLoading$: Observable<boolean>;
	features: Feature[];
	featuresChanged: boolean;

	firstPageElementIndex = 0;
	groupTitles = [];

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'title', title: 'GROUP_TITLE', width: 80 },
		{ name: 'isAdmin', title: 'IS_ADMIN', width: 10 },
		{ name: '', title: '', width: 7 },
	];

	constructor(
		private translateService: TranslateService,
		private groupStore: Store<GroupState>,
		private featureStore: Store<FeatureState>,
		private formBuilder: FormBuilder,
		private modalService: BsModalService,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('GROUPS').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
		this.groupsLoading$ = this.groupStore.select(groupSelectors.selectGroupsLoading);

		this.featureStore
			.select(featureSelectors.selectAllFeatures)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(features => (this.features = cloneDeep(features)));
		this.featuresLoading$ = this.featureStore.select(featureSelectors.selectFeaturesLoading);

		this.groups$ = this.groupStore.select(groupSelectors.selectAllGroups);

		this.featureStore.dispatch(featureActions.loadFeatures());
		this.groupStore
			.select(groupSelectors.selectAllGroups)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data.length) {
					this.groupStore.dispatch(groupActions.loadGroups());
				} else {
					for (const element of data) this.groupTitles.push(element.title);
				}
			});
	}

	openGroupsModal(updateGroupTemplate: TemplateRef<any>, group: Group) {
		this.groupForm = this.formBuilder.group(
			group
				? {
						...group,
						title: [
							group.title,
							[Validators.required, Validators.maxLength(128), Validators.required, this.validateUsername()],
						],
				  }
				: {
						...new Group(),
						title: ['', [Validators.required, Validators.maxLength(128), Validators.required, this.validateUsername()]],
				  }
		);
		this.featuresChanged = false;
		this.submitted = false;
		if (this.groupForm.value.featuresArray) {
			const featuresIds = this.groupForm.value.featuresArray.split(',');
			this.features.forEach(feature => {
				feature.checked = featuresIds.some(id => Number(id) === feature.id) ? true : false;
			});
		} else {
			this.features.forEach(feature => {
				feature.checked = false;
			});
		}
		this.updateGroupModal = this.modalService.show(updateGroupTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	private validateUsername(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			let titles = [];
			if (this.groupForm?.value?.id) {
				titles = this.groupTitles.filter(x => x !== control.value);
			} else {
				titles = this.groupTitles;
			}
			return titles.includes(control.value) ? { alreadyExist: true } : null;
		};
	}

	submitGroupForm() {
		this.submitted = true;
		if (this.groupForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		const tempGroup = this.groupForm;
		tempGroup.value.featuresArray = '';
		if (this.featuresChanged && this.features.some(ft => ft.checked)) {
			this.features.forEach((ft, i) => {
				if (ft.checked) {
					if (i !== 0 && tempGroup.value.featuresArray !== '') {
						tempGroup.value.featuresArray += ',';
					}
					tempGroup.value.featuresArray += ft.id.toString();
				}
			});
		}
		if (this.groupForm.value.id) {
			const tempForm: Update<Group> = {
				id: tempGroup.value.id,
				changes: tempGroup.value,
			};
			this.groupStore.dispatch(groupActions.updateGroup({ group: tempForm }));
			this.updateGroupModal.hide();
		} else {
			this.groupStore.dispatch(groupActions.addGroup({ group: tempGroup.value }));
			this.updateGroupModal.hide();

			this.firstPageElementIndex = 0;
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
