<ng-container
	*ngIf="
		translate && {
			administratorsLoading: administratorsLoading$ | async,
			featuresLoading: featuresLoading$ | async
		} as observables
	"
>
	<div class="page-title">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>

	<div class="loading-cont" *ngIf="observables.featuresLoading || observables.administratorsLoading; else myProfile">
		<div class="spinner-border"></div>
		<span>{{ 'LOADING' | translate }}</span>
	</div>

	<ng-template #myProfile>
		<div class="my-profile-cont col-lg-8 col-md-12 col-sm-12" *ngIf="currentUser && features">
			<div class="row mb-3">
				<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.USERNAME }}:</div>
				<div class="col-lg-8 col-sm-12">
					<input class="form-control" type="text" disabled="true" value="{{ currentUser.username }}" />
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.EMAIL }}:</div>
				<div class="col-lg-8 col-sm-12">
					<input class="form-control" type="text" disabled="true" value="{{ currentUser.email }}" />
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.NAME }}:</div>
				<div class="col-lg-8 col-sm-12">
					<input
						class="form-control"
						type="text"
						disabled="true"
						value="{{ currentUser.name }} {{ currentUser.surname }}"
					/>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.GROUP }}:</div>
				<div class="col-lg-8 col-sm-12">
					<input class="form-control" type="text" disabled="true" value="{{ currentUser.groupName }}" />
				</div>
			</div>

			<hr />

			<div class="row list">
				<div class="col-lg-6 col-md-4 col-sm-12" *ngFor="let item of features">
					<label class="chbx-container" [class.checked]="item.checked">
						{{ translateFeatures[item.identifier] ? translateFeatures[item.identifier] : item.identifier }}
						<input
							type="checkbox"
							[ngClass]="{ checked: item.checked }"
							disabled="true"
							(change)="item.checked = !item.checked"
						/>
						<span class="chbx-checkmark"><svg-icon class="modal-form-svg-icon-mb-12" key="checkmark"></svg-icon></span>
					</label>
				</div>
			</div>

			<div *ngIf="canDeactivate" class="row">
				<div class="col-12">
					<button type="button" class="button" (click)="deactivateComponents()">
						{{ translate.DEACTIVATE }}
					</button>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
