<ng-container *ngIf="translate && { sellersLoading: sellersLoading$ | async } as observables">
	<div class="sellers-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openSellersModal(updateSellerTemplate, null)"
			[disabled]="observables.sellersLoading"
			data-tid="addSeller"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.sellersLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.sellersLoading">
			<p-table
				#sellertable
				[value]="sellers$ | async"
				[autoLayout]="true"
				class="sellers-prime-table"
				[paginator]="true"
				[(first)]="firstPageElementIndex"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'firstName', 'lastName', 'program', 'position', 'active']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="sellertable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'firstName'">
							{{ translate.FULL_NAME }}
							<p-sortIcon [field]="'firstName'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'program'">
							{{ translate.PROGRAM }}
							<p-sortIcon [field]="'program'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'position'">
							{{ translate.FUNCTION }}
							<p-sortIcon [field]="'position'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'active'" [style.width.px]="140">
							{{ translate.ACTIVE }}
							<p-sortIcon [field]="'active'"></p-sortIcon>
						</th>
						<th [style.width.px]="90">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-user let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ user.id }}</b>
						</td>
						<td>{{ user.firstName }} {{ user.lastName }}</td>
						<td>{{ user.program }}</td>
						<td>{{ user.position }}</td>
						<td>
							<button
								type="button"
								class="button button-gray"
								(click)="openConfirmationModal(deactivateSellerTemplate, user)"
							>
								{{ user.active ? ('DEACTIVATE' | translate) : ('ACTIVATE' | translate) }}
								<fa-icon icon="chevron-right"></fa-icon>
							</button>
						</td>
						<td>
							<button
								type="button"
								class="button button-gray"
								(click)="openSellersModal(updateSellerTemplate, user)"
								data-tid="editSeller"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- Add/Edit seller modal -->
	<ng-template #updateSellerTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left" *ngIf="sellerForm.value.id">{{ translate.EDIT_SELLER }}</h4>
			<h4 class="modal-title pull-left" *ngIf="!sellerForm.value.id">{{ translate.ADD_SELLER }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updateSellerModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="sellerForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.firstName.errors }"
							formControlName="firstName"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.firstName.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.LNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.lastName.errors }"
							formControlName="lastName"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.lastName.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PROGRAM }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.program.errors }"
							formControlName="program"
							maxlength="255"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.program.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.EMAIL }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.email.errors }"
							formControlName="email"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.email.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.MSN_MSG }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.msn.errors }"
							formControlName="msn"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.msn.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.PHONE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.phone.errors }"
							formControlName="phone"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.phone.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.MOBILE_PHONE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.mobile.errors }"
							formControlName="mobile"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.mobile.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FUNCTION }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.position.errors }"
							formControlName="position"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.position.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.USERNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && sellerForm.controls.username.errors }"
							formControlName="username"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && sellerForm.controls.username.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.ORDER }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input type="number" numbersOnly class="form-control" formControlName="order" />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PICTURE }}:
						<br />
						<p class="image-restriction-message">({{ imageRestrictionMessage }})</p>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-fileUpload
							#sellerImage
							name="adultImage"
							chooseLabel="{{ 'CHOOSE_FILE' | translate }}"
							accept="image/*"
							[showUploadButton]="false"
							[showCancelButton]="false"
							(onSelect)="select($event, sellerImage); showExistingImage = false"
							(onRemove)="resetImageUpload(); sellerImage.clear()"
							data-tid="sellerImage"
						>
						</p-fileUpload>
						<img
							*ngIf="!selectedSellerImage && sellerAdultImage && showExistingImage"
							[src]="sellerAdultImage"
							class="modal-responsive-image"
						/>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateSellerModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="sellerForm.dirty || selectedSellerImage ? false : true || observables.sellersLoading"
						(click)="submitSellerForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>

	<!-- Confirmation Modal -->
	<ng-template #deactivateSellerTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ currentSeller.active ? translate.DEACTIVATE_IMAGE_TITLE : translate.ACTIVATE_IMAGE_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deactivateSellerModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ currentSeller.active ? translate.DEACTIVATE_IMAGE_BODY : translate.ACTIVATE_IMAGE_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button" (click)="deactivateSelectedSeller()">
					{{ 'SUBMIT' | translate }}
				</button>
				<button type="button" class="button button-gray" (click)="deactivateSellerModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
