import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delayedRetry } from 'src/app/shared/utils/delayed-retry';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// retry HTTP request 3 times with 1 second delay before raise an error
		if (req.url.includes('identityserver') || req.method === 'GET') {
			return next.handle(req).pipe(delayedRetry(1000, 3));
		}
		return next.handle(req);
	}
}
