<ng-container *ngIf="translate && { priceListLoading: priceListLoading$ | async } as observables">
	<div class="price-list-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openPriceListModal(updatePriceListTemplate, null)"
			[disabled]="observables.priceListLoading"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>

		<app-skeleton-screen
			*ngIf="observables.priceListLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>

		<div *ngIf="!observables.priceListLoading">
			<p-table
				#priceListtable
				[value]="priceList$ | async"
				[autoLayout]="true"
				class="priceList-prime-table"
				[paginator]="true"
				[(first)]="firstPageElementIndex"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'name', 'file', 'description', 'date', 'active']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="priceListtable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>

				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'name'">
							{{ translate.NAME }}
							<p-sortIcon [field]="'name'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'file'">
							{{ translate.FILE }}
							<p-sortIcon [field]="'file'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'description'">
							{{ translate.DESCRIPTION }}
							<p-sortIcon [field]="'description'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'date'">
							{{ translate.DATE }}
							<p-sortIcon [field]="'date'"></p-sortIcon>
						</th>
						<th [style.width.px]="100">&nbsp;</th>
						<th [style.width.px]="100">&nbsp;</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-priceList let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ priceList.id }}</b>
						</td>
						<td>{{ priceList.name }}</td>
						<td>
							<a
								href="{{ priceListFileDownloadPrefix }}{{ priceList.file }}"
								download="{{ priceList.file }}"
								target="_blank"
								class="ellipsis-breaker"
							>
								{{ priceList.file }}</a
							>
						</td>
						<td>{{ priceList.description }}</td>
						<td>{{ priceList.date | date: 'dd.MM.yyyy.' }}</td>
						<td class="text-right red">
							<button
								type="button"
								class="button button-gray"
								(click)="openConfirmationModal(deactivatePriceListTemplate, priceList)"
							>
								{{ priceList.active ? ('DEACTIVATE' | translate) : ('ACTIVATE' | translate) }}
								<fa-icon icon="chevron-right"></fa-icon>
							</button>
						</td>
						<td class="text-right">
							<button
								type="button"
								class="button button-gray"
								(click)="openPriceListModal(updatePriceListTemplate, priceList)"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- Add/Edit priceList modal -->
	<ng-template #updatePriceListTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left" *ngIf="priceListForm.value.id">{{ translate.EDIT_PRICE_LIST }}</h4>
			<h4 class="modal-title pull-left" *ngIf="!priceListForm.value.id">{{ translate.ADD_PRICE_LIST }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updatePriceListModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="priceListForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.NAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && priceListForm.controls.name.errors }"
							formControlName="name"
							maxlength="255"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && priceListForm.controls.name.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FILE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<label class="button button-gray" [ngClass]="{ enabled: !priceList }">
							<fa-icon icon="plus"></fa-icon>
							{{ 'CHOOSE_FILE' | translate }}

							<input
								type="file"
								(change)="onFileChange($event)"
								[ngClass]="{ 'is-invalid': submitted && priceListForm.controls.fileString.errors }"
								hidden
								formControlName="fileString"
							/>

							<app-form-validation-error-msg
								*ngIf="submitted && priceListForm.controls.fileString.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateFormValidationErrorMsgs"
							>
							</app-form-validation-error-msg>
						</label>
						<span class="ml-3 uploaded-file-title">
							{{ fileName }}
							<fa-icon icon="check" [style.color]="'green'" *ngIf="fileUploaded" title="{{ 'FILE_READY' | translate }}">
							</fa-icon>
						</span>
						<div *ngIf="priceListForm.controls.file.value">
							<a
								href="{{ priceListFileDownloadPrefix }}{{ priceListForm.controls.file.value }}"
								download="{{ priceListForm.controls.file.value }}"
								target="_blank"
								class="line-breaker"
							>
								{{ priceListForm.controls.file.value }}
							</a>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.DESCRIPTION }}:</div>
					<div class="col-lg-8 col-sm-12">
						<textarea class="form-control" formControlName="description" rows="6" cols="50"></textarea>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updatePriceListModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						(click)="submitPriceListForm()"
						[disabled]="priceListForm.dirty ? false : true || observables.priceListLoading"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>

	<!-- Deactivation Confirm Modal -->
	<ng-template #deactivatePriceListTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ selectedPriceList.active ? translate.DEACTIVATE_PROM_TITLE : translate.ACTIVATE_PROM_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deactivatePriceListModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ selectedPriceList.active ? translate.DEACTIVATE_PROM_BODY : translate.ACTIVATE_PROM_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deactivatePriceListModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button" (click)="deactivateSelectedPriceList()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
