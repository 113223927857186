import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AllowedQuantity } from 'src/app/shared/models/allowed-quantity';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class AllowedQuantityService {
	constructor(private http: HttpClient) {}

	getAllowedQuantity(): Observable<AllowedQuantity[]> {
		return this.http.get<AllowedQuantity[]>(environment.baseApiUrl + 'api/ProductGroupsOrderLimit');
	}

	addAllowedQuantity(allowedQuantity: AllowedQuantity): Observable<AllowedQuantity> {
		return this.http.post<AllowedQuantity>(environment.baseApiUrl + 'api/ProductGroupsOrderLimit', allowedQuantity);
	}

	deleteAllowedQuantity(allowedQuantityId: number): Observable<number> {
		return this.http
			.delete(environment.baseApiUrl + 'api/ProductGroupsOrderLimit/' + allowedQuantityId)
			.pipe(map(() => allowedQuantityId));
	}

	updateAllowedQuantity(allowedQuantity: Update<AllowedQuantity>): Observable<Update<AllowedQuantity>> {
		return this.http
			.put<Update<AllowedQuantity>>(environment.baseApiUrl + 'api/ProductGroupsOrderLimit', allowedQuantity.changes)
			.pipe(map(() => allowedQuantity));
	}
}
