import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromGroup from './group.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GroupEffects } from './group.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromGroup.groupsFeatureKey, fromGroup.reducer),
		EffectsModule.forFeature([GroupEffects]),
	],
})
export class GroupsStoreModule {}
