import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { ProductFiltersState } from '../root-store/product-filters-store/product-filter.reducer';
import { SpecialOffer } from '../shared/enums/special-offer';
import { Product } from '../shared/models/product';
import { AppliedFilter, PriceFilter, ProductListFilter } from '../shared/models/product-list-filter';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ProductFilters } from '../shared/models/product-filters';
import * as productFilterSelectors from '../root-store/product-filters-store/product-filter.selectors';
import * as productFilterActions from '../root-store/product-filters-store/product-filter.actions';
import { ProductListState } from '../root-store/product-list-store/product-list.reducer';
import * as productListSelectors from '../root-store/product-list-store/product-list.selectors';
import * as productListActions from '../root-store/product-list-store/product-list.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LabelType, Options } from '@m0t0r/ngx-slider';
import { SpecialPriceService } from '../core/services/special-price.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LatestChange, SpecialPrice, SpecialPriceDeactivate, SpecialPriceQuery } from '../shared/models/special-price';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { renameKey } from '../shared/utils/rename-key';
import { exportAsExcel } from '../shared/utils/export-to-excel';
import { DatePipe } from '@angular/common';
import { ReservedProduct } from '../shared/models/reserved-product';
import { PurchasedProduct } from '../shared/models/purchased-product';
import { CustomerAdvancedFilter } from '../shared/models/customer-filter';
import { AuthService } from '../core/authentication/auth.service';
import { environment } from 'src/environments/sr/environment';
import { Deputy } from '../shared/models/deputy';
import { HttpClient } from '@angular/common/http';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { ExcelKeys_en } from '../shared/enums/excel-keys-en';
import { ExcelKeys_sr } from '../shared/enums/excel-keys-sr';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ExchangeRate } from 'src/app/shared/models/exchange-rate';
import { ExchangeRateState } from 'src/app/root-store/exchange-rates-store/exchange-rates.reducer';
import * as exchangeRatesActions from 'src/app/root-store/exchange-rates-store/exchange-rates.actions';
import * as exchangeRatesSelectors from 'src/app/root-store/exchange-rates-store/exchange-rates.selectors';
import { ProductDetailState } from '../root-store/product-details-store/product-detail.reducer';
import * as productDetailsActions from '../root-store/product-details-store/product-detail.actions';
import * as productDetailsSelectors from '../root-store/product-details-store/product-detail.selectors';
import { ProductDetails } from '../shared/models/product-details';
import { ProductProcurement } from '../shared/models/product-procurement';
import { numberFormatter } from '../shared/utils/number-formatter';
import { faViber } from '@fortawesome/free-brands-svg-icons';
import { SpecialOfferValuePipe } from '../shared/pipes/special-offer-value.pipe';
import { CustomerGroup } from '../shared/models/customer-group';
import { CustomerGroupState } from '../root-store/customer-group-store/customer-group.reducer';
import * as customerGroupsActions from '../root-store/customer-group-store/customer-group.actions';
import * as customerGroupsSelectors from '../root-store/customer-group-store/customer-group.selectors';
@Component({
	selector: 'app-special-price',
	templateUrl: './special-price.component.html',
	styleUrls: ['./special-price.component.scss', '../../assets/styles/special-price.scss'],
})
export class SpecialPriceComponent implements OnInit, OnDestroy {
	translate: any;
	translateErrors: any;
	ngUnsubscribe = new Subject();
	viewProductDetailsModal: BsModalRef;
	reservationsModal: BsModalRef;
	purchasesModal: BsModalRef;
	customerFilterModal: BsModalRef;
	removeSpecialsModal: BsModalRef;
	addSpecialActionModal: BsModalRef;
	productProcurementModal: BsModalRef;
	excelUploadModal: BsModalRef;
	deputyModal: BsModalRef;
	latestChangesModal: BsModalRef;
	faCog = faCog;
	showEAN = false;
	showStockPrice = false;
	showPMPrice = false;
	showForWeb = true;
	showOldQty = true;
	showTotalQty = true;
	showCustomerPrice = true;
	showSpecialOffer = true;

	columns: any[] = [
		{ name: 'forWeb', title: 'FOR_WEB', width: 12, selected: this.showForWeb, edit: 'showForWeb' },
		{ name: 'productName', title: 'PRODUCT', width: 10, selected: this.showSpecialOffer, edit: null },
		{ name: 'specialOffer', title: 'SPECIAL_OFFER', width: 13, selected: true, edit: 'showSpecialOffer' },
		{ name: 'manufacturer', title: 'MANUFACTURER', width: 13, selected: true, edit: null },
		{ name: 'productGroup', title: 'GROUP', width: 9, selected: true, edit: null },
		{ name: 'qty', title: 'IN_STOCKS', width: 9, selected: true, edit: null },
		{ name: 'reservedQty', title: 'RESERVED', width: 8, selected: true, edit: null },
		{ name: 'totalQty', title: 'TOTAL_QTY', width: 12, selected: this.showTotalQty, edit: 'showTotalQty' },
		{ name: 'oldQty', title: 'OLD_QTY', width: 12, selected: this.showOldQty, edit: 'showOldQty' },
		{ name: 'price', title: 'PRICE', width: 7, selected: true, edit: null },
		{ name: 'priceWithRabat', title: 'MIN_PRICE', width: 10, selected: true, edit: null },
		{ name: 'maxRabat', title: 'MAX_DISCOUNT', width: 12, selected: true, edit: null },
		{
			name: 'customerPrice',
			title: 'CUSTOMER_PRICE',
			width: 12,
			selected: this.showCustomerPrice,
			edit: 'showCustomerPrice',
		},
		{ name: 'specialPrice', title: 'SPECIAL_PRICE', width: 12, selected: true, edit: null },
		{ name: 'pmpPrice', title: 'PMP_INFO', width: 12, selected: this.showPMPrice, edit: 'showPMPrice' },
		{ name: 'lagerPrice', title: 'LAGER_PRICE', width: 12, selected: this.showStockPrice, edit: 'showStockPrice' },
		{ name: 'ean', title: 'EAN_CODE', width: 12, selected: this.showEAN, edit: 'showEAN' },
		{ name: 'warehouse', title: 'WAREHOUSE', width: 10, selected: true, edit: null },
	];
	fifoLegend: any[] = [
		{ status: 'statusGreen', color: 'green', title: '60-120' },
		{ status: 'statusBlue', color: 'blue', title: '121-240' },
		{ status: 'statusPink', color: 'purple', title: '241-360' },
		{ status: 'statusRed', color: 'red', title: '>360' },
	];
	customerDropdown: any[];
	customerDropdownBackup: any[] = [];
	customerData: any;
	selectedCustomer: any = null;
	selectedCustomerGroup: CustomerGroup = null;
	selectedProduct: Product = null;
	sliderOptions: Options;
	minPriceValue: number;
	maxPriceValue: number;

	// Filters
	listFilters = new ProductListFilter();
	selectedFilters: AppliedFilter[] = [];
	// productFilters: any;
	specialOffer = SpecialOffer;
	selectedManufacturer: any;
	lastChangedFilter: ProductFilters;
	lastChangedFilterCode: string;
	productFilters: ProductFilters;
	productFiltersLoading$: Observable<boolean>;
	// Products list
	products: Product[];
	productsCopy: Product[];
	filterSelected = false;
	filtersInitialized: boolean;
	filtersApplied: boolean;
	productsLoading$: Observable<boolean>;
	filterGlobalSearch = '';
	specialPriceForm: any;
	deputyForm: any;
	submitted = false;
	// Special price view
	selectedSpecialPrice: SpecialPriceDeactivate[];
	unlimitedDuration = true;
	// Import excel
	excelData: any;
	// Reservations and purchases
	reservations: ReservedProduct[] = [];
	purchases: PurchasedProduct[] = [];
	importedExcelData: any;
	@ViewChild('inputFile') inputFile: ElementRef;
	@ViewChild('excelUploadModalTemplate') excelUploadModalTemplate: TemplateRef<any>;
	isExcelFile: boolean;
	advancedSearchData: CustomerAdvancedFilter[] = [];
	customersLoading: boolean;
	latestChangesLoading: boolean;
	reservationsLoading: boolean;
	purchasesLoading: boolean;
	advancedSearchLoading: boolean;
	viewSpecialPriceLoading: boolean;
	productProcurementLoading: boolean;
	defaultTableRows: any;
	selectedCustomerTable: any;
	isUserAbleToSet: boolean;
	excelFileName: string;
	environment = environment;
	selectedDeputy: any;
	deputyList: Deputy[];
	selectedDeputies: Deputy[] = [];
	deputySearch: string;
	disabledAddDeputy = true;
	disabledDeputyModal: boolean;
	searchCustomerAdvancedFilter: string;
	warehouses: string[] = [];
	selectedWarehouse: string;
	exchangeRates: ExchangeRate[] = [];
	exchangeRatesCopy: ExchangeRate[] = [];
	exchangeRatesLoading$: Observable<boolean>;
	productDetails$: Observable<ProductDetails>;
	productDetailsLoading$: Observable<boolean>;
	productDetails: ProductDetails;
	first = 0;
	productProcurement: ProductProcurement[];
	firstPageAdvancedCustomerFilter = 0;
	latestChangesList: LatestChange[] = [];
	selectedCustomerLatestChanges: any;
	latestChangesListBackup: LatestChange[];
	minPriceViolation = false;
	priceValueChange = new Subject<string>();
	hasSalesCode: boolean;
	firstPageLatestChanges = 0;
	expandedMobileFilter: string;
	showFiltersMenu = false;
	faViber = faViber;
	selectedCustomersDeactivate: any[];
	selectedCustomerGroupsDeactivate: any[];
	deactivateCustomerGroups: any[] = [];

	customerGroupsLoading$: Observable<boolean>;
	customerGroups$: Observable<CustomerGroup[]>;
	customerGroups: CustomerGroup[] = [];

	currentUserUsername = '';

	constructor(
		private translateService: TranslateService,
		private specialPriceService: SpecialPriceService,
		private productFilterStore: Store<ProductFiltersState>,
		private productListStore: Store<ProductListState>,
		private productDetailsStore: Store<ProductDetailState>,
		private exchangeRateStore: Store<ExchangeRateState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private datePipe: DatePipe,
		private authService: AuthService,
		private http: HttpClient,
		private specialOfferValuePipe: SpecialOfferValuePipe,
		private customerGroupStore: Store<CustomerGroupState>
	) {
		// Debounce validation.
		this.priceValueChange.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
			if (
				this.specialPriceForm.value.price !== '' &&
				this.specialPriceForm.value.price < Number(this.selectedProduct.priceWithRabat.toFixed(2))
			) {
				this.minPriceViolation = true;
			} else {
				this.minPriceViolation = false;
			}
		});
	}

	ngOnInit(): void {
		this.listFilters.inStockOnly = 1;
		this.listFilters.forWebOnly = 1;
		this.listFilters.priceFilter = new PriceFilter();
		this.listFilters.filterItems = [];
		this.listFilters.type = '';
		this.listFilters.code = '';
		this.listFilters.customerGroupID = -1;

		this.defaultTableRows =
			localStorage.getItem('sp_table_rows') && localStorage.getItem('sp_table_rows') !== 'All'
				? Number(localStorage.getItem('sp_table_rows'))
				: 10;

		this.translateService.get('SPECIAL_PRICE').subscribe((resp: any) => {
			this.translate = resp;
			this.excelFileName = this.translate.EXCEL_FILENAME + '.xlsx';
		});
		this.customerGroups$ = this.customerGroupStore.select(customerGroupsSelectors.selectAllCustomerGroups);
		this.customerGroupStore.dispatch(customerGroupsActions.loadCustomerGroups());

		this.customerGroupsLoading$ = this.customerGroupStore.select(customerGroupsSelectors.selectCustomerGroupsLoading);
		this.customerGroupStore
			.select(customerGroupsSelectors.selectAllCustomerGroups)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(customerGroups => {
				if (!customerGroups.length) {
					this.customerGroupStore.dispatch(customerGroupsActions.loadCustomerGroups());
				} else {
					this.customerGroups = cloneDeep(customerGroups);
					this.deactivateCustomerGroups = cloneDeep(customerGroups);
					if (this.translate) {
						this.customerGroups.unshift({
							id: -1,
							name: '- ' + this.translate.SELECT_CUSTOMER_GROUP + ' -',
							value: null,
						});
					}
				}
			});

		this.translateService.get('ERROR.FORM_VALIDATIONS').subscribe((resp: any) => (this.translateErrors = resp));
		this.customersLoading = true;
		this.hasSalesCode = this.authService.hasPermission('Users.HasSalesCode');
		this.specialPriceService.getCustomersForAdvancedFilter(this.hasSalesCode).subscribe(data => {
			this.customerDropdown = [];
			if (data) {
				this.customerData = data;
				for (const customer of data) {
					this.customerDropdown.push({
						label: customer.name,
						value: customer,
						searchText: customer.name + ' ' + customer.no,
						no: customer.no,
					});
				}
				this.customerDropdownBackup = cloneDeep(this.customerDropdown);
				this.customerDropdown.unshift({
					label: '- ' + this.translate.SELECT_CUSTOMER + ' -',
					value: -1,
					searchText: '- ' + this.translate.SELECT_CUSTOMER + ' -',
				});

				const unique = [...new Set(this.customerDropdownBackup.map(item => item.value.type))];
				this.customerGroups = this.customerGroups.filter(x => unique.includes(x.value));
				this.customerGroups.unshift({ id: -1, name: '- ' + this.translate.SELECT_CUSTOMER_GROUP + ' -', value: null });
			}
			this.customersLoading = false;
		});
		this.initProducts();
		this.dispatchGetProducts();
		this.dispatchGetProductFilters();
		this.initProductFilters();
		this.getExchangeRates();
		this.isUserAbleToSet = this.authService.hasPermission('Users.SpecialPriceSet');
		this.disabledDeputyModal = !this.authService.isUserInRole('Sales');
	}

	customerGroupFilter(event: any) {
		this.listFilters.customerGroupID = event.value;
		if (event.value !== null) {
			this.customerDropdown = cloneDeep(
				this.customerDropdownBackup.filter(x => x.value.type === event.value || x.value === null)
			);
		} else {
			this.customerDropdown = cloneDeep(this.customerDropdownBackup);
		}
		this.customerDropdown.unshift({
			label: '- ' + this.translate.SELECT_CUSTOMER + ' -',
			value: -1,
			searchText: '- ' + this.translate.SELECT_CUSTOMER + ' -',
		});
		this.selectedCustomer = null;
		this.dispatchGetProducts();
	}

	listFiltersChanged(
		code: string,
		codeName: string,
		value: string,
		valueDesc: string,
		isAttribute: boolean,
		isChecked: boolean
	) {
		this.first = 0;
		this.filterGlobalSearch = null;
		if (!(code === 'OFFER' && Number(value) === 0)) {
			this.filterSelected = true;
		}
		if (!value && isAttribute) {
			value = '';
			valueDesc = this.translate.NOT_SPECIFIED;
		}
		if (!isAttribute) {
			codeName = this.translate[codeName];
		}
		if (code === 'OFFER' && value.toString() === '0') {
			this.listFilters.inStockOnly = isChecked ? 1 : 0;
		} else if (code === 'OFFER' && value.toString() === '-1') {
			this.listFilters.forWebOnly = isChecked ? 1 : 0;
		} else if (isChecked) {
			this.listFilters.filterItems.push({ code, codeName, value, valueDesc, isAttribute });
		} else {
			let index = this.listFilters.filterItems.findIndex(x => x.code === code && x.value === value);
			if (index >= 0) {
				this.listFilters.filterItems.splice(index, 1);
				const selFilterIndex = this.selectedFilters.findIndex(x => x.code === code);
				if (selFilterIndex >= 0 && this.selectedFilters[selFilterIndex].filteredItems.length > 1) {
					index = this.selectedFilters[selFilterIndex].filteredItems.findIndex(x => x.value === value);
					this.selectedFilters[selFilterIndex].filteredItems.splice(index, 1);
				} else {
					this.selectedFilters.splice(index, 1);
				}
			}
		}
		this.setLastChangedFilter(code);
		this.filtersInitialized = true;
		this.applySelectedFilters();
	}

	setLastChangedFilter(code: string) {
		this.lastChangedFilterCode = null;
		this.lastChangedFilter = new ProductFilters();
		if (code === 'GROUP' || code === 'MANUFACTURER' || code === 'OFFER' || code === 'WARRANTY') {
			if (this.productFilters[code.toLowerCase()]?.some(p => p.selected)) {
				this.lastChangedFilterCode = code;
				this.lastChangedFilter[code.toLowerCase()] = cloneDeep(this.productFilters[code.toLowerCase()]);
			}
		} else {
			if (this.productFilters.attributes.find(p => p.attributeCode === code).values.some(p => p.selected)) {
				this.lastChangedFilterCode = code;
				this.lastChangedFilter.attributes = cloneDeep([
					this.productFilters.attributes.find(filter => filter.attributeCode === code),
				]);
			}
		}
	}

	setFiltersFromLastChanged() {
		if (!isEmpty(this.lastChangedFilter) && this.lastChangedFilterCode !== 'PRICE') {
			if (!isEmpty(this.lastChangedFilter.attributes)) {
				if (this.productFilters.attributes.some(filter => filter.attributeCode === this.lastChangedFilterCode)) {
					this.productFilters.attributes.find(
						filter => filter.attributeCode === this.lastChangedFilterCode
					).values = this.lastChangedFilter.attributes[0].values;
				} else {
					this.productFilters.attributes.push(this.lastChangedFilter.attributes[0]);
				}
			} else {
				this.productFilters[this.lastChangedFilterCode.toLowerCase()] = cloneDeep(
					this.lastChangedFilter[this.lastChangedFilterCode.toLowerCase()]
				);
			}
		}
	}

	setFilterOptions() {
		// check selected filters
		for (const selFilter of this.selectedFilters) {
			if (selFilter.filteredItems.some(item => item.isAttribute)) {
				const tempFilter = this.productFilters.attributes.find(filter => filter.attributeCode === selFilter.code);
				if (!isEmpty(tempFilter)) {
					for (const item of selFilter.filteredItems) {
						if (tempFilter.values.some(value => value.optionValue === item.value)) {
							tempFilter.values.find(value => value.optionValue === item.value).selected = true;
						}
					}
				}
			} else {
				for (const item of selFilter.filteredItems) {
					switch (selFilter.code) {
						case 'GROUP':
							if (this.productFilters.group.some(x => x.productGroup === item.value)) {
								this.productFilters.group.find(x => x.productGroup === item.value).selected = true;
							}
							break;
						case 'MANUFACTURER':
							if (this.productFilters.manufacturer.some(x => x.manufacturerID === item.value)) {
								this.productFilters.manufacturer.find(x => x.manufacturerID === item.value).selected = true;
							}
							break;
						case 'OFFER':
							if (this.productFilters.offer.some(x => x.specialOffer.toString() === item.value.toString())) {
								this.productFilters.offer.find(
									x => x.specialOffer.toString() === item.value.toString()
								).selected = true;
							}
							break;
						case 'WARRANTY':
							if (this.productFilters.warranty.some(x => x.warranty === item.value)) {
								this.productFilters.warranty.find(x => x.warranty === item.value).selected = true;
							}
							break;
					}
				}
			}
		}

		// keep all from last selected filter
		this.setFiltersFromLastChanged();

		// add 'IN STOCK' offer
		if (!isEmpty(this.productFilters.offer)) {
			if (isEmpty(this.lastChangedFilter) || isEmpty(this.lastChangedFilter.offer)) {
				this.productFilters.offer.unshift(
					{
						specialOffer: 0,
						specialOfferValue: 'L',
						selected: !(this.listFilters && this.listFilters.inStockOnly === 0),
					},
					{
						specialOffer: -1,
						specialOfferValue: 'FW',
						selected: !(this.listFilters && this.listFilters.forWebOnly === 0),
					}
				);
			}
		} else {
			this.productFilters.offer = [
				{
					specialOffer: 0,
					specialOfferValue: 'L',
					selected: !(this.listFilters && this.listFilters.inStockOnly === 0),
				},
				{
					specialOffer: -1,
					specialOfferValue: 'FW',
					selected: !(this.listFilters && this.listFilters.forWebOnly === 0),
				},
			];
		}
		// set price slider
		this.setPriceSlider();
	}

	applySelectedFilters() {
		this.filtersApplied = true;
		this.dispatchGetProducts();
		this.dispatchGetProductFilters();
	}

	dispatchGetProducts() {
		this.listFilters.customerID =
			this.selectedCustomer && this.selectedCustomer.value !== -1 ? this.selectedCustomer.value.no : '';
		this.listFilters.customerGroupID =
			this.selectedCustomer && this.selectedCustomer.value !== -1
				? this.selectedCustomer.value.type
				: this.selectedCustomerGroup
					? this.selectedCustomerGroup.value
					: -1;
		this.productListStore.dispatch(
			productListActions.loadProductListItems({ listFilter: cloneDeep(this.listFilters) })
		);
	}

	dispatchGetProductFilters() {
		this.productFilterStore.dispatch(
			productFilterActions.loadProductFilters({ listFilter: cloneDeep(this.listFilters) })
		);
	}

	initProductFilters() {
		this.productFiltersLoading$ = this.productFilterStore.select(productFilterSelectors.selectProductFiltersLoading);
		this.productFilterStore
			.select(productFilterSelectors.selectProductFilters)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(productFilters => {
				this.productFilters = cloneDeep(productFilters);
				if (this.productFilters) {
					this.setFilterOptions();
				}
			});
	}

	initProducts() {
		this.productsLoading$ = this.productListStore.select(productListSelectors.selectProductsLoading);
		this.productListStore
			.select(productListSelectors.selectProducts)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(products => {
				this.products = cloneDeep(products);
				this.productsCopy = cloneDeep(products);
				if (this.products.length) {
					setTimeout(() => {
						this.setTableNotScrollable();
					}, 0);
				}
				this.warehouses = ['- ' + this.translate.SELECT_WAREHOUSE + ' -'];
				if (this.products.length && localStorage.getItem('sp_table_rows') === 'All') {
					this.defaultTableRows = this.products.length;
				}
				this.products.forEach(p => {
					if (p.warehouse && !this.warehouses.some(w => w === p.warehouse)) {
						this.warehouses.push(p.warehouse);
					}
				});
				if (this.selectedWarehouse) {
					const temp: Product[] = cloneDeep(this.productsCopy);
					this.products = [];
					temp.forEach(product => {
						if (product.warehouse === this.selectedWarehouse) {
							this.products.push(product);
						}
					});
				}
				if (localStorage.getItem('sp_table_rows') === '' + this.products.length) {
					this.defaultTableRows = this.products.length;
				}
				this.listAppliedFilters();
			});
	}

	getProductSpecifications(viewProductDetailsTemplate: TemplateRef<any>, productNo, productName: string) {
		this.viewProductDetailsModal = this.modalService.show(viewProductDetailsTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});

		this.productDetailsLoading$ = this.productDetailsStore.select(productDetailsSelectors.selectProductDetailsLoading);
		this.productDetails$ = this.productDetailsStore.select(productDetailsSelectors.selectProductDetails, {
			productNo,
		});

		this.productDetailsStore
			.select(productDetailsSelectors.selectProductDetails, { productNo })
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(productDetails => {
				this.productDetails = cloneDeep(productDetails);
				if (!productDetails) {
					this.productDetailsStore.dispatch(productDetailsActions.loadProductDetails({ productNo }));
				} else {
					this.productDetails.productName = productName;
				}
			});
	}

	getExchangeRates() {
		this.exchangeRatesLoading$ = this.exchangeRateStore.select(exchangeRatesSelectors.selectExchangeRatesLoading);
		this.exchangeRateStore.dispatch(exchangeRatesActions.loadExchangeRates());
		this.exchangeRateStore
			.select(exchangeRatesSelectors.selectExchangeRates)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(exchangeRates => {
				this.exchangeRates = exchangeRates;
				if (exchangeRates.length) {
					this.exchangeRatesCopy = [];
					this.environment.exchangeCurrencies.forEach(exCur => {
						if (this.exchangeRates.some(rate => rate.currencyCode === exCur)) {
							this.exchangeRatesCopy.push({
								currencyCode: exCur,
								exchangeRate: Number(
									this.exchangeRates.find(rate => rate.currencyCode === exCur)?.exchangeRate.toFixed(2)
								),
								startingDate: new Date(),
							});
						}
					});
				}
			});
	}

	setPriceSlider() {
		if (this.productFilters) {
			this.minPriceValue = this.productFilters.price[0]?.minPrice;
			this.maxPriceValue = this.productFilters.price[0]?.maxPrice;

			this.sliderOptions = {
				floor: this.minPriceValue,
				ceil: this.maxPriceValue,
				translate: (value: number, label: LabelType): string => {
					switch (label) {
						case LabelType.Low:
							return value + ' €';
						case LabelType.High:
							return value + ' €';
						default:
							return value.toString();
					}
				},
			};
		}
	}

	openAddSpecialActionModal(addSpecialActionTemplate: TemplateRef<any>, product: Product, customer: any, group: any) {
		this.currentUserUsername = this.authService.currentUserValue.name;
		this.minPriceViolation = false;
		this.submitted = false;
		const customerNo = customer && customer.value !== -1 ? customer.value.no : '';
		const selectedCustomerGroupId =
			customer && customer.value !== -1 ? customer.value.type : group && group.value !== null ? group.value : '';
		let customerGroupId;
		if (!customer || customer.value === -1) {
			if (group && group.value !== null) {
				customerGroupId = group.value;
			}
		}
		// const customerGroupId =
		// 	!this.selectedCustomer || this.selectedCustomer.value === -1
		// 		? this.selectedCustomerGroup && this.selectedCustomerGroup.value
		// 			? this.selectedCustomerGroup.value
		// 			: ''
		// 		: '';

		this.specialPriceForm = this.formBuilder.group({
			...new SpecialPrice(),
			productNo: [product.productNo],
			customerNo: [customerNo],
			customerGroupId: [customerGroupId],
			date: [null, [Validators.required]],
			price: ['', [Validators.required]],
			active: [1],
		});

		this.selectedSpecialPrice = [];
		this.viewSpecialPriceLoading = true;
		const specialPriceQuery: SpecialPriceQuery = {
			productNo: product.productNo,
			customerNo,
			customerGroupId,
			selectedCustomerGroupId,
		};
		// selectedCustomerGroupId,
		this.specialPriceService.getSpecialPriceListById(specialPriceQuery).subscribe(data => {
			this.selectedSpecialPrice = data;
			this.viewSpecialPriceLoading = false;
		});
		this.addSpecialActionModal = this.modalService.show(addSpecialActionTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
		this.selectedProduct = product;
	}

	openReservationsModal(reservationsModalTemplate: TemplateRef<any>, product: Product) {
		this.reservations = [];
		this.reservationsLoading = true;
		this.specialPriceService.getReservationsByProductNo(product.productNo).subscribe(data => {
			this.reservations = data;
			this.reservationsLoading = false;
		});
		this.reservationsModal = this.modalService.show(reservationsModalTemplate, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
		});
		this.selectedProduct = product;
	}

	openPurchasesModal(purchasesModalTemplate: TemplateRef<any>, product: Product) {
		this.purchases = [];
		this.purchasesLoading = true;
		this.specialPriceService.getPurchasesByProductNo(product.productNo).subscribe(data => {
			this.purchases = data;
			this.purchasesLoading = false;
		});
		this.purchasesModal = this.modalService.show(purchasesModalTemplate, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
		});
		this.selectedProduct = product;
	}

	openCustomerFilterModal(customerModalTemplate: TemplateRef<any>) {
		this.searchCustomerAdvancedFilter = '';
		this.advancedSearchData = [];
		this.advancedSearchLoading = true;
		this.specialPriceService.getCustomersForAdvancedFilter(this.hasSalesCode).subscribe(data => {
			this.advancedSearchData = data;
			this.advancedSearchLoading = false;
		});
		this.customerFilterModal = this.modalService.show(customerModalTemplate, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
		});
	}

	openDeactivateSpecialsModal(removeSpecialsModalTemplate: TemplateRef<any>) {
		this.selectedCustomerGroupsDeactivate = [];
		this.selectedCustomersDeactivate = [];
		this.removeSpecialsModal = this.modalService.show(removeSpecialsModalTemplate, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
		});
	}

	openDeputyModal(deputyModalTemplate: TemplateRef<any>) {
		this.deputyList = [];
		this.selectedDeputies = [];
		this.specialPriceService.getSalespersons().subscribe(data => {
			for (const element of data) {
				this.deputyList.push({
					deputyCode: element.code,
					fromDB: false,
					deputyName: element.name,
					sellerCode: null,
					selected: false,
				});
			}
		});
		this.specialPriceService.getDeputes().subscribe(data => {
			for (const element of data) {
				const deputy = this.deputyList.find(x => x.deputyCode === element.deputyCode);
				if (deputy) {
					deputy.id = element.id;
					deputy.selected = true;
					deputy.fromDB = true;
					this.selectedDeputies.push(deputy);
				}
			}
		});
		this.deputyModal = this.modalService.show(deputyModalTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	selectDeputy(deputy: Deputy) {
		deputy.selected = !deputy.selected;
		if (deputy.selected) {
			this.selectedDeputies.push(deputy);
		} else {
			let indexToRemove: number;
			for (let i = 0; i <= this.selectedDeputies.length; i++) {
				if (this.selectedDeputies[i].deputyCode === deputy.deputyCode) {
					indexToRemove = i;
					break;
				}
			}
			this.removeDeputy(deputy, indexToRemove);
		}
		this.disabledAddDeputy = !this.selectedDeputies.some(x => x.fromDB === false);
	}

	removeDeputy(deputy: Deputy, index: number) {
		if (deputy.fromDB) {
			this.specialPriceService
				.deleteDeputy(deputy.id)
				.subscribe(() => this.toastr.success(this.translate.SUCCESS_MESSAGES.DEPUTY_SUCCESSFULLY_REMOVED));
		}
		deputy.fromDB = false;
		this.selectedDeputies.splice(index, 1);
		if (this.deputyList.some(x => x.deputyCode === deputy.deputyCode)) {
			this.deputyList.find(x => x.deputyCode === deputy.deputyCode).selected = false;
		}
		if (this.selectedDeputies.length === 0) {
			this.disabledAddDeputy = true;
		}
	}

	submitAddDeputy() {
		this.disabledAddDeputy = true;
		const deputies: Deputy[] = this.selectedDeputies.filter(x => !x.fromDB);
		this.specialPriceService.addDeputies(deputies).subscribe(() => {
			this.toastr.success(this.translate.SUCCESS_MESSAGES.DEPUTY_SUCCESSFULLY_ADDED);
			for (const element of this.selectedDeputies) {
				element.fromDB = true;
			}
			this.deputyModal.hide();
		});
	}

	submitCustomerSpecialPrice() {
		this.submitted = true;
		if (this.specialPriceForm.invalid) {
			this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
			return;
		} else {
			// TODO: Add "!this.unlimitedDuration" if unlimited is enabled
			if (this.specialPriceForm.value.date) {
				const fromDate = this.specialPriceForm.value.date[0];
				const toDate = this.specialPriceForm.value.date[1];
				this.specialPriceForm.value.fromDate =
					fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
				this.specialPriceForm.value.toDate =
					toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
			} else {
				const fromDate = new Date();
				this.specialPriceForm.value.fromDate =
					fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
				this.specialPriceForm.value.toDate = null;
			}
			delete this.specialPriceForm.value.date;
			this.addSpecialActionModal.hide();
			const array = [];
			array.push(this.specialPriceForm.value);
			this.specialPriceService.createSpecialPriceArray(array, this.hasSalesCode).subscribe(() => {
				this.toastr.success(this.translate.SUCCESS_MESSAGES.SPECIAL_PRICE_SET);
				this.dispatchGetProducts();
			});
		}
	}

	clearFilters() {
		this.listFilters.filterItems = [];
		this.listFilters.priceFilter = new PriceFilter();
		this.selectedFilters = [];
		this.lastChangedFilter = new ProductFilters();
		this.filterGlobalSearch = '';
		this.filterSelected = false;
		this.applySelectedFilters();
	}

	deactivateSpecialPrice(specialPrice: SpecialPrice) {
		const deactivationSpecial = cloneDeep(specialPrice);
		this.specialPriceService.deactivateSpecialPrice(specialPrice.id).subscribe((data: SpecialPrice) => {
			const sp = this.selectedSpecialPrice.find(x => x.id === specialPrice.id);
			sp.active = data.active;
			sp.fromDate = data.fromDate;
			sp.toDate = data.toDate;
			if (!data.active) {
				if (this.products.some(x => x.productNo === sp.productNo)) {
					const product = this.products.find(
						x =>
							x.productNo === deactivationSpecial.productNo &&
							x.specialPriceFromDate === deactivationSpecial.fromDate &&
							x.specialPriceToDate === deactivationSpecial.toDate &&
							x.specialPrice === deactivationSpecial.price
					);
					if (product) {
						product.specialPrice = 0;
					}
				}
				this.toastr.success(this.translate.SUCCESS_MESSAGES.SPECIAL_PRICE_DEACTIVATED);
			}
		});
	}

	listAppliedFilters() {
		this.selectedFilters = [];
		if (!isEmpty(this.listFilters.priceFilter)) {
			const tempFilter = {
				code: 'PRICE',
				codeName: this.translate.PRICE,
				filteredItems: [
					{
						code: 'PRICE',
						codeName: this.translate.PRICE,
						value: this.listFilters.priceFilter.priceFrom + '-' + this.listFilters.priceFilter.priceTo,
						valueDesc: this.listFilters.priceFilter.priceFrom + ' - ' + this.listFilters.priceFilter.priceTo,
						isAttribute: false,
					},
				],
			};
			this.selectedFilters.push(tempFilter);
		}
		this.listFilters.filterItems.forEach(item => {
			if (this.selectedFilters && this.selectedFilters.some(x => x.code === item.code)) {
				this.selectedFilters.find(x => x.code === item.code).filteredItems.push(item);
			} else {
				this.selectedFilters.push({ code: item.code, codeName: item.codeName, filteredItems: [item] });
			}
		});

		if (!this.selectedFilters.length) {
			this.filterSelected = false;
		}
	}

	priceFilterChanged() {
		if (
			isEmpty(this.listFilters.priceFilter) ||
			this.minPriceValue !== this.listFilters.priceFilter.priceFrom ||
			this.maxPriceValue !== this.listFilters.priceFilter.priceTo
		) {
			this.first = 0;
			this.filterGlobalSearch = null;
			this.listFilters.priceFilter = {
				priceFrom: this.minPriceValue,
				priceTo: this.maxPriceValue,
				currency: '',
			};
			this.lastChangedFilterCode = 'PRICE';
			this.lastChangedFilter = new ProductFilters();
			this.lastChangedFilter.price = cloneDeep(this.productFilters.price);
			this.filtersInitialized = true;
			this.applySelectedFilters();
		}
	}

	importExcel(event: any) {
		const target: DataTransfer = event.target;
		let validator = false;
		this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
		if (target.files.length > 1) {
			this.inputFile.nativeElement.value = '';
		}
		if (this.isExcelFile) {
			const reader: FileReader = new FileReader();
			reader.onload = (e: any) => {
				/* read workbook */
				const bstr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

				/* grab first sheet */
				const wsname: string = wb.SheetNames[0];
				const key = 'P1';
				if (wb.Sheets[wsname][key]?.v === 'B2BSupport') {
					validator = true;
				}
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];
				/* save data */
				this.excelData = XLSX.utils.sheet_to_json(ws);
			};
			reader.readAsBinaryString(target.files[0]);
			reader.onloadend = () => {
				if (!validator) {
					this.toastr.error(this.translate.ERROR_MESSAGES.INVALID_EXCEL_SCHEMA);
					return;
				}
				const parsedData = JSON.parse(JSON.stringify(cloneDeep(this.excelData)));
				this.excelData = [];
				const spOldEng = 'Special price';
				const spOldRS = 'Specijalna cena';
				parsedData.forEach(element => {
					this.excelData.push({
						productNo: element[ExcelKeys_en.PRODUCT_NO]
							? element[ExcelKeys_en.PRODUCT_NO]
							: element[ExcelKeys_sr.PRODUCT_NO],
						customerNo: element[ExcelKeys_en.CUSTOMER_NO]
							? element[ExcelKeys_en.CUSTOMER_NO]
							: element[ExcelKeys_sr.CUSTOMER_NO],
						customerGroupId: element[ExcelKeys_en.CUSTOMER_GROUP_ID]
							? element[ExcelKeys_en.CUSTOMER_GROUP_ID]
							: element[ExcelKeys_sr.CUSTOMER_GROUP_ID],
						fromDate: element[ExcelKeys_en.FROM_DATE]
							? element[ExcelKeys_en.FROM_DATE]
							: element[ExcelKeys_sr.FROM_DATE],
						toDate: element[ExcelKeys_en.TO_DATE] ? element[ExcelKeys_en.TO_DATE] : element[ExcelKeys_sr.TO_DATE],
						price: element[ExcelKeys_en.PRICE]
							? element[ExcelKeys_en.PRICE]
							: element[ExcelKeys_sr.PRICE]
								? element[ExcelKeys_sr.PRICE]
								: element[spOldEng]
									? element[spOldEng]
									: element[spOldRS],
						errorField: element[ExcelKeys_en.ERROR_FIELD]
							? element[ExcelKeys_en.ERROR_FIELD]
							: element[ExcelKeys_sr.ERROR_FIELD],
					});
				});

				// Translating fields
				for (let i = 0; i < this.excelData.length; i++) {
					if (!this.excelData[i].price) {
						this.excelData.splice(i, 1);
						i--;
					} else {
						const element = this.excelData[i];
						if (element.productNo === ' ') {
							this.excelData.splice(i, 1);
							i--;
						} else {
							element.active = true;
							if (!element.fromDate) {
								this.toastr.error(this.translate.FROM_TO_DATE_REQUIRED);
								return;
							} else {
								if (this.serialDateToJSDate(element.fromDate).toString() !== 'Invalid Date') {
									element.fromDate = this.serialDateToJSDate(element.fromDate);
								} else {
									element.fromDate = new Date(element.fromDate);
								}
							}
							if (element.toDate) {
								if (this.serialDateToJSDate(element.toDate).toString() !== 'Invalid Date') {
									const toDate = this.serialDateToJSDate(element.toDate);
									element.toDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
								} else {
									const fromDate = new Date(element.toDate);
									element.toDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
								}
							} else if (!element.toDate) {
								this.toastr.error(this.translate.FROM_TO_DATE_REQUIRED);
								return;
							}
							// CustomerNo and Customer Group validation, checks excel file and dropdown
							element.customerNo = element.customerNo
								? element.customerNo
								: element.customerGroupId
									? ''
									: this.selectedCustomer
										? this.selectedCustomer.value.no
										: '';
							const customerGroup = element.customerNo
								? ''
								: element.customerGroupId
									? element.customerGroupId
									: this.selectedCustomer
										? ''
										: this.selectedCustomerGroup
											? this.selectedCustomerGroup.name
											: '';
							element.price = element.price.toFixed(2);
							if (!element.customerNo && !customerGroup) {
								this.toastr.error(this.translate.ERROR_MESSAGES.CUSTOMER_AND_CUSTOMER_GROUP_NOT_DEFINED);
								return;
							}
							element.customerGroupId = customerGroup
								? this.customerGroups.find(x => x.name === customerGroup)
									? this.customerGroups.find(x => x.name === customerGroup).value
									: ''
								: '';
						}
					}
				}
				this.specialPriceService.createSpecialPriceArray(this.excelData, this.hasSalesCode).subscribe(data => {
					this.importedExcelData = data;
					if (this.importedExcelData.warning && this.importedExcelData.warning.length > 0) {
						const customerNo = this.selectedCustomer ? this.selectedCustomer.value.no : '';
						const customerGroupId =
							this.selectedCustomer && this.selectedCustomer.value !== -1
								? ''
								: this.selectedCustomerGroup
									? this.selectedCustomerGroup.value
									: '';
						if (customerNo !== '' || customerGroupId !== '') {
							for (const element of this.excelData) {
								const product = this.products.find(
									x =>
										x.productNo === '' + element.productNo &&
										(element.customerNo === customerNo || element.customerGroupId === customerGroupId)
								);
								if (product) {
									product.specialPrice = element.price;
								}
							}
						}
					}
					this.excelUploadModal = this.modalService.show(this.excelUploadModalTemplate, {
						class: 'modal-md',
						ignoreBackdropClick: true,
					});
				});
			};
		} else {
			this.inputFile.nativeElement.value = '';
			this.toastr.error(this.translate.ERROR_MESSAGES.WRONG_FILE_TYPE);
		}
	}

	exportToExcel() {
		const header = [
			this.translate.EXCEL_KEYS.PRODUCT_NO,
			this.translate.EXCEL_KEYS.CUSTOMER_NO,
			this.translate.EXCEL_KEYS.CUSTOMER_GROUP_ID,
			this.translate.EXCEL_KEYS.FROM_DATE,
			this.translate.EXCEL_KEYS.TO_DATE,
			this.translate.EXCEL_KEYS.PRICE,
			this.translate.EXCEL_KEYS.ERROR_FIELD,
		];
		const specialPriceError: SpecialPrice[] = [];
		const errorMessage = [];

		const translatedData = JSON.parse(JSON.stringify(this.importedExcelData.error));
		for (const element of translatedData) {
			// Date formatting
			element.item1.fromDate = this.datePipe.transform(element.item1.fromDate, 'yyyy-MM-dd');
			element.item1.toDate = element.item1.toDate ? this.datePipe.transform(element.item1.toDate, 'yyyy-MM-dd') : '';
			// Removing unused fields
			delete element.item1.id;
			delete element.item1.active;
			delete element.item1.createdBy;
			delete element.item1.created;
			delete element.item1.productName;
			if (element.errorField) {
				delete element.errorField;
			}
			if (element.item2 === 'CustomerNo') {
				element.item2 = this.translate.EXCEL_KEYS.CUSTOMER_NO;
			} else if (element.item2 === 'CustomerGroupId') {
				element.item2 = this.translate.EXCEL_KEYS.CUSTOMER_GROUP_ID;
			}
			element.item1.errorField = element.item2;

			// Translating fields
			renameKey(element.item1, 'productNo', this.translate.EXCEL_KEYS.PRODUCT_NO);
			renameKey(element.item1, 'customerNo', this.translate.EXCEL_KEYS.CUSTOMER_NO);
			renameKey(element.item1, 'customerGroupId', this.translate.EXCEL_KEYS.CUSTOMER_GROUP_ID);
			renameKey(element.item1, 'fromDate', this.translate.EXCEL_KEYS.FROM_DATE);
			renameKey(element.item1, 'toDate', this.translate.EXCEL_KEYS.TO_DATE);
			renameKey(element.item1, 'price', this.translate.EXCEL_KEYS.PRICE);
			renameKey(element.item1, 'errorField', this.translate.EXCEL_KEYS.ERROR_FIELD);
			specialPriceError.push(element.item1);
			errorMessage.push(element.item2);
		}

		exportAsExcel(specialPriceError, this.translate.TITLE, this.translate.TITLE, header);
	}

	selectCustomer() {
		this.selectedCustomer = this.customerDropdown.find(x => x.no === this.selectedCustomerTable.no);
		this.selectedCustomerGroup = this.customerGroups[0];
		this.dispatchGetProducts();
		this.customerFilterModal.hide();
	}

	downloadExcelReport() {
		// create a workbook with a worksheet
		this.http
			.get('assets/SpecialPrice/ExcelTemplate/' + this.excelFileName, { responseType: 'blob' })
			.subscribe(async (data: any) => {
				const workbook = new Workbook();
				await workbook.xlsx.load(data).then(() => {
					const worksheet = workbook.worksheets[0];
					worksheet.getCell('G1').value = this.translate.EXCEL_KEYS.MESSAGE_TYPE;
					worksheet.getCell('H1').value = this.translate.EXCEL_KEYS.MESSAGE_TEXT;
					worksheet.getColumn('G').width = 14;
					worksheet.getColumn('H').width = 44;
					const translatedData = [...this.importedExcelData.error, ...this.importedExcelData.warning];
					translatedData.forEach((element, index) => {
						const el = element.item1 ? element.item1 : element;
						const msg = element.item2 ? element.item2 : 'MinPriceViolation';
						const t = [
							el.productNo,
							el.customerNo,
							el.customerGroupId,
							new Date(el.fromDate),
							new Date(el.toDate),
							el.price,
							msg === 'MinPriceViolation' ? this.translate.WARNING : this.translate.ERROR,
							msg === 'MinPriceViolation'
								? this.translate.MIN_PRICE_VIOLATION
								: msg === 'CustomerNo'
									? this.translate.CUSTOMER_NO_VIOLATION
									: msg,
						];
						const cells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
						cells.forEach((cell, i) => {
							worksheet.getRow(index + 2).getCell(cell).value = t[i];
							if (cell === 'G' || cell === 'H') {
								if (t[6] === this.translate.WARNING) {
									worksheet.getRow(index + 2).getCell(cell).font = {
										bold: true,
										color: { argb: 'FF9201' },
									};
								} else {
									worksheet.getRow(index + 2).getCell(cell).font = {
										bold: true,
										color: { argb: 'FF0000' },
									};
								}
							} else if (cell === 'D' || cell === 'E') {
								worksheet.getRow(index + 2).getCell(cell).numFmt = 'dd/MM/yyyy';
							}
						});
					});

					workbook.xlsx.writeBuffer().then(buffer => {
						const blob = new Blob([buffer], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						FileSaver.saveAs(
							blob,
							this.translate.EXCEL_FILENAME + '_' + this.translate.REPORT + '_' + new Date().getTime() + '.xlsx'
						);
					});
				});
			});
	}

	exportDataFromTable() {
		// create a workbook with a worksheet
		this.http
			.get('assets/SpecialPrice/ExcelTemplate/' + this.excelFileName, { responseType: 'blob' })
			.subscribe(async (data: any) => {
				const workbook = new Workbook();
				await workbook.xlsx.load(data).then(() => {
					const worksheet = workbook.getWorksheet('Special');
					worksheet.getCell('G1').value = this.translate.PRICE;
					worksheet.getColumn('G').width = 14;
					worksheet.getCell('H1').value = this.translate.PRODUCT_NAME;
					worksheet.getColumn('H').width = 64;
					const translatedData = JSON.parse(JSON.stringify(this.products));
					const customer = this.selectedCustomer
						? this.selectedCustomer.value !== -1
							? this.selectedCustomer.value.no
							: ''
						: '';
					const customerGroup = this.selectedCustomerGroup
						? this.selectedCustomerGroup.value != null
							? this.selectedCustomerGroup.name
							: ''
						: '';
					translatedData.forEach((element, index) => {
						let fromDate = element.specialPriceFromDate;
						let toDate = element.specialPriceToDate;
						if (fromDate) {
							fromDate = new Date(fromDate);
							fromDate =
								('0' + fromDate.getDate()).slice(-2) +
								'-' +
								('0' + (fromDate.getMonth() + 1)).slice(-2) +
								'-' +
								fromDate.getFullYear();
						}
						if (toDate) {
							toDate = new Date(toDate);
							toDate =
								('0' + toDate.getDate()).slice(-2) +
								'-' +
								('0' + (toDate.getMonth() + 1)).slice(-2) +
								'-' +
								toDate.getFullYear();
						}
						const t = [
							element.productNo,
							customer,
							customerGroup,
							fromDate,
							toDate,
							element.specialPrice ? element.specialPrice : '',
							element.price,
							element.productName,
						];
						const cells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
						cells.forEach((cell, i) => {
							worksheet.getRow(index + 2).getCell(cell).value = t[i];
						});
					});

					workbook.xlsx.writeBuffer().then(buffer => {
						const blob = new Blob([buffer], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						FileSaver.saveAs(blob, this.translate.EXCEL_FILENAME + '_' + new Date().getTime() + '.xlsx');
					});
				});
			});
	}

	changeUnlimitedDate() {
		this.unlimitedDuration = !this.unlimitedDuration;

		// change validation for date
		if (this.unlimitedDuration) {
			this.specialPriceForm.get('date').clearValidators([Validators.required]);
			this.specialPriceForm.get('date').setErrors(null);
		} else {
			this.specialPriceForm.get('date').setValidators([Validators.required]);
			this.specialPriceForm.get('date').setErrors({ required: true });
		}
		this.specialPriceForm.updateValueAndValidity(); // update validation
	}

	warehouseFilter() {
		this.first = 0;
		if (this.selectedWarehouse === '- ' + this.translate.SELECT_WAREHOUSE + ' -') {
			this.selectedWarehouse = null;
			this.products = cloneDeep(this.productsCopy);
		} else {
			const temp: Product[] = cloneDeep(this.productsCopy);
			this.products = [];
			temp.forEach(product => {
				if (product.warehouse === this.selectedWarehouse) {
					this.products.push(product);
				}
			});
		}
	}

	onRowsNumberChange(event: LazyLoadEvent) {
		if (
			event.rows !== this.products.length ||
			(event.rows === this.products.length && [10, 20, 50].includes(event.rows))
		) {
			localStorage.setItem('sp_table_rows', event.rows.toString());
			this.defaultTableRows = event.rows;
		} else {
			localStorage.setItem('sp_table_rows', 'All');
			this.defaultTableRows = this.products.length;
		}
		setTimeout(() => {
			this.setTableNotScrollable();
		}, 0);
	}

	setTableNotScrollable() {
		if (document.getElementById('special-price-table')) {
			const el = document.getElementById('special-price-table').offsetHeight;
			if (window.innerHeight >= el) {
				document.getElementById('special-price-table').style.top = '10px';
			} else {
				document.getElementById('special-price-table').style.top = window.innerHeight - el - 27 + 'px';
			}
		}
	}

	// serialDate is whole number of days since Dec 30, 1899
	// offsetUTC is -(24 - your timezone offset)
	serialDateToJSDate(serialDate) {
		return new Date(Date.UTC(0, 0, serialDate - 1));
	}

	downloadCatalog() {
		const workbook = new Workbook();
		const translatedData = this.products;
		const objectKeys = [
			{ cell: 'A', property: 'productNo', translation: this.translate.PRODUCT_NO, width: null },
			{ cell: 'B', property: 'productName', translation: this.translate.PRODUCT, width: 64 },
			{ cell: 'C', property: 'specialOffer', translation: this.translate.SPECIAL_OFFER, width: 10 },
			{ cell: 'D', property: 'manufacturer', translation: this.translate.MANUFACTURER, width: null },
			{ cell: 'E', property: 'productGroupName', translation: this.translate.GROUP, width: 30 },
			{ cell: 'F', property: 'qty', translation: this.translate.STOCKS, width: 7 },
			{ cell: 'G', property: 'reservedQty', translation: this.translate.RESERVED, width: 7 },
			{ cell: 'H', property: 'totalQty', translation: this.translate.TOTAL_QTY, width: 7 },
			{ cell: 'I', property: 'oldQty', translation: this.translate.OLD_QTY, width: 7 },
			{ cell: 'J', property: 'warehouse', translation: this.translate.WAREHOUSE, width: 7 },
			{ cell: 'K', property: 'price', translation: this.translate.PRICE, width: 12 },
			{ cell: 'L', property: 'priceWithRabat', translation: this.translate.MIN_PRICE, width: 12 },
			{ cell: 'M', property: 'customerPrice', translation: this.translate.CUSTOMER_PRICE, width: 12 },
			{ cell: 'N', property: 'specialPrice', translation: this.translate.SPECIAL_PRICE, width: 12 },
			{ cell: 'O', property: 'eanCode', translation: this.translate.EAN_CODE, width: 15 },
		];
		const worksheet = workbook.addWorksheet(this.translate.PRODUCT_LIST);

		for (const key of objectKeys) {
			worksheet.getRow(1).getCell(key.cell).value = key.translation;
			if (key.width !== null) {
				worksheet.getColumn(key.cell).width = key.width;
			}
		}
		translatedData.forEach((element, index) => {
			for (const key of objectKeys) {
				if (key.property === 'specialOffer') {
					worksheet.getRow(index + 2).getCell(key.cell).value = this.translate.SPECIAL_OFFERS[
						this.specialOffer[this.specialOfferValuePipe.transform(element[key.property])]
					];
				} else {
					worksheet.getRow(index + 2).getCell(key.cell).value = element[key.property];
					if (key.cell === 'K' || key.cell === 'L' || key.cell === 'M' || key.cell === 'N') {
						worksheet.getRow(index + 2).getCell(key.cell).numFmt = '0.00';
					}
				}
			}
		});
		workbook.xlsx.writeBuffer().then(buffer => {
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			FileSaver.saveAs(blob, this.translate.PRODUCT_LIST + '_' + new Date().getTime() + '.xlsx');
		});
	}

	openProductProcurementModal(productProcurementTemplate: TemplateRef<any>, product: Product) {
		this.productProcurementLoading = true;
		this.selectedProduct = product;
		this.specialPriceService.getProductProcurement(product.productNo).subscribe(data => {
			this.productProcurement = data;
			this.productProcurementLoading = false;
		});
		this.productProcurementModal = this.modalService.show(productProcurementTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	downloadReservations() {
		const data = [];
		for (const element of this.reservations) {
			data.push({
				[this.translate.NO]: element.transactionID,
				[this.translate.PARTNER]: element.customer,
				[this.translate.DATE]: this.datePipe.transform(element.date, 'dd-MM-yyyy'),
				[this.translate.SALES_PERSON]: element.salesPerson,
				[this.translate.QTY]: element.qty,
				[this.translate.WAREHOUSE]: element.warehouse,
			});
		}
		exportAsExcel(data, this.translate.RESERVATIONS, this.translate.RESERVATIONS);
	}

	downloadPurchases() {
		const data = [];
		for (const element of this.purchases) {
			data.push({
				[this.translate.DOCUMENT]: element.transactionID,
				[this.translate.PARTNER]: element.partner,
				[this.translate.PARTNER_ID]: element.partnerNumber,
				[this.translate.POSTING_DATE]: this.datePipe.transform(element.postingDate, 'dd-MM-yyyy'),
				[this.translate.QTY]: element.qty,
				[this.translate.UNIT_PRICE]: numberFormatter(element.unitPrice),
				[this.translate.UNIT_COST]: numberFormatter(element.unitCost),
				[this.translate.PROFIT]: numberFormatter(element.profit),
				[this.translate.CURRENCY_CODE]: element.currency,
				[this.translate.SELLER]: element.seller,
			});
		}
		exportAsExcel(data, this.translate.PURCHASES, this.translate.PURCHASES);
	}

	downloadProcurements() {
		const data = [];
		for (const element of this.productProcurement) {
			data.push({
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.PARTNER]: element.partner,
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.DATE]: this.datePipe.transform(element.date, 'dd-MM-yyyy'),
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.QTY]: element.qty,
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.PRICE]: numberFormatter(element.price),
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.CURRENCY]: element.currency,
				[this.translate.PRODUCT_PROCUREMENT.COLUMNS.WAREHOUSE]: element.warehouse,
			});
		}
		exportAsExcel(data, this.translate.PRODUCT_PROCUREMENT.TITLE, this.translate.PRODUCT_PROCUREMENT.TITLE);
	}

	setTablePage() {
		this.firstPageAdvancedCustomerFilter = 0;
	}

	downloadProductDetails() {
		const header = [this.translate.ATTRIBUTE_NAME, this.translate.ATTRIBUTE_VALUE];
		let data = [];
		if (this.productDetails.specs) {
			const translatedDataDetails = JSON.parse(JSON.stringify(this.productDetails));
			renameKey(translatedDataDetails, 'productNo', this.translate.PRODUCT_NO);
			renameKey(translatedDataDetails, 'productName', this.translate.PRODUCT_NAME);
			data = [
				{
					[this.translate.ATTRIBUTE_NAME]: this.translate.PRODUCT_NO,
					[this.translate.ATTRIBUTE_VALUE]: translatedDataDetails[this.translate.PRODUCT_NO],
				},
				{
					[this.translate.ATTRIBUTE_NAME]: this.translate.PRODUCT_NAME,
					[this.translate.ATTRIBUTE_VALUE]: translatedDataDetails[this.translate.PRODUCT_NAME],
				},
			];
			for (const element of this.productDetails.specs) {
				if (element.value && element.code !== 'PMPPrice')
					data.push({ [this.translate.ATTRIBUTE_NAME]: element.name, [this.translate.ATTRIBUTE_VALUE]: element.value });
			}
		}
		exportAsExcel(data, this.translate.PRODUCT_DETAILS, this.translate.PRODUCT_DETAILS, header);
	}

	openLatestChangesModal(latestChangesModalTemplate: TemplateRef<any>) {
		this.latestChangesLoading = true;
		this.selectedCustomerLatestChanges = null;
		this.specialPriceService.getLatestChanges().subscribe(data => {
			this.latestChangesList = data;
			this.latestChangesListBackup = data;
			this.latestChangesLoading = false;
		});
		this.latestChangesModal = this.modalService.show(latestChangesModalTemplate, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
		});
	}

	filterLatestChanges() {
		this.firstPageLatestChanges = 0;
		if (this.selectedCustomerLatestChanges) {
			this.latestChangesList =
				this.selectedCustomerLatestChanges.value === -1
					? this.latestChangesListBackup
					: this.latestChangesListBackup.filter(x => x.customerNo === this.selectedCustomerLatestChanges.value.no);
		} else {
			this.selectedCustomerLatestChanges = this.customerDropdown[0];
			this.latestChangesList = this.latestChangesListBackup;
		}
	}

	downloadLatestChanges() {
		const data = [];
		for (const element of this.latestChangesList) {
			data.push({
				[this.translate.PRODUCT_NAME]: element.productName,
				[this.translate.PRODUCT_NO]: element.productNo,
				[this.translate.CUSTOMER]: element.customerName,
				[this.translate.CUSTOMER_NO]: element.customerNo,
				[this.translate.MANUFACTURER]: element.manufacturer,
				[this.translate.PRODUCT_GROUP]: element.productGroup,
				[this.translate.SELLER]: element.sellerName,
				[this.translate.DATE_CHANGED]: this.datePipe.transform(element.created, 'dd-MM-yyyy'),
				[this.translate.PRICE]: numberFormatter(element.price),
				[this.translate.MIN_PRICE]: numberFormatter(element.priceWithRabat),
				[this.translate.SPECIAL_PRICE]: numberFormatter(element.specialPrice),
			});
		}
		exportAsExcel(data, this.translate.LATEST_CHANGES, this.translate.LATEST_CHANGES);
	}

	removeSpecialsByCustomer() {
		const ids = this.selectedCustomersDeactivate.map(({ no }) => no);
		this.specialPriceService
			.removeSpecialsByCustomer(ids)
			.subscribe(() =>
				this.toastr.success(this.translate.SUCCESS_MESSAGES.SPECIALS_FOR_CUSTOMERS_SUCCESSFULLY_DEACTIVATED)
			);
		this.removeSpecialsModal.hide();
		this.resetProductsTable();
	}

	removeSpecialsByCustomerGroup() {
		const ids = this.selectedCustomerGroupsDeactivate.map(({ value }) => value);
		this.specialPriceService
			.removeSpecialsByGroup(ids)
			.subscribe(() =>
				this.toastr.success(this.translate.SUCCESS_MESSAGES.SPECIALS_FOR_CUSTOMER_GROUPS_SUCCESSFULLY_DEACTIVATED)
			);
		this.removeSpecialsModal.hide();
		this.resetProductsTable();
	}

	resetProductsTable() {
		this.selectedCustomerGroup = this.customerGroups[0];
		this.selectedCustomer = this.customerDropdown[0];
		this.dispatchGetProducts();
	}

	switchTab() {
		this.selectedCustomerGroupsDeactivate = [];
		this.selectedCustomersDeactivate = [];
	}

	setExpandedMenuFilter(filter: string) {
		this.expandedMobileFilter = filter === this.expandedMobileFilter ? '' : filter;
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
