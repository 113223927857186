import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductData } from 'src/app/shared/models/product-data';
import { environment } from 'src/environments/sr/environment';

@Injectable()
export class ProductDataService {
	constructor(private http: HttpClient) {}

	getProductCommonNames(): Observable<ProductData[]> {
		return this.http.get<ProductData[]>(`${environment.baseApiUrl}api/Product/Items`);
	}

	getProductCommonNamesByFilter(searchItem: string): Observable<ProductData[]> {
		return this.http.get<ProductData[]>(
			`${environment.baseApiUrl}api/Product/common-no/${encodeURIComponent(searchItem)}`
		);
	}
}
