<ng-container
	*ngIf="
		translate && {
			productLinksLoading: productLinksLoading$ | async,
			productLinks: productLinks$ | async
		} as observables
	"
>
	<div class="loading-cont" *ngIf="observables.productLinksLoading">
		<div class="spinner-border"></div>
		<span>{{ 'LOADING' | translate }}</span>
	</div>
	<div id="product-links">
		<div class="col-12" *ngIf="translateProductAdditionalTypes">
			<div *ngIf="!observables.productLinksLoading">
				<ng-template #noLinks>
					<div class="product-configuration-preview">
						<h6>{{ translate.NO_PRODUCT_LINKS }}</h6>
					</div>

					<div class="mb-3">
						<button
							type="button"
							class="add-link-button button my-3"
							(click)="openProductLinkModal(updateProductLinkTemplate, null)"
							[disabled]="observables.productLinksLoading"
						>
							<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
						</button>
					</div>
				</ng-template>

				<div *ngIf="productLinks?.length > 0; else noLinks">
					<div class="product-configuration-preview">
						{{ translate.PRODUCT_LINKS_PREVIEW }} #{{ productLinks[0].productNo }}
					</div>

					<div class="mb-3">
						<button
							type="button"
							class="add-link-button button my-3"
							(click)="openProductLinkModal(updateProductLinkTemplate, null)"
							[disabled]="observables.productLinksLoading"
						>
							<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
						</button>
					</div>

					<div>
						<div class="mx-3 my-2"></div>
						<p-table
							#productLinksTable
							[value]="productLinks"
							[autoLayout]="true"
							[paginator]="true"
							[rows]="10"
							[(first)]="firstPageElementIndex"
							[rowsPerPageOptions]="[5, 10, 20, 50]"
							[globalFilterFields]="['id', 'url', 'type']"
						>
							<ng-template pTemplate="caption">
								<div class="global-filter-cont">
									<fa-icon icon="search"></fa-icon>
									<input
										type="text"
										class="form-control"
										pInputText
										size="50"
										[placeholder]="'GLOBAL_FILTER' | translate"
										data-tid="globalFilter"
										(input)="productLinksTable.filterGlobal($event.target.value, 'contains')"
									/>
								</div>
							</ng-template>

							<ng-template pTemplate="header">
								<tr>
									<th [pSortableColumn]="'id'" class="text-center" [style.padding-right.px]="10">
										{{ translate.ID }}
										<p-sortIcon [field]="'id'"></p-sortIcon>
									</th>
									<th [pSortableColumn]="'url'" class="th-url-width-correction">
										{{ translate.URL }}
										<p-sortIcon [field]="'url'"></p-sortIcon>
									</th>
									<th [pSortableColumn]="'additionalType'">
										{{ translate.TYPE }}
										<p-sortIcon [field]="'additionalType'"></p-sortIcon>
									</th>
									<th class="text-center"></th>
								</tr>
							</ng-template>

							<ng-template pTemplate="body" let-item let-i="rowIndex">
								<tr>
									<td class="text-center">
										{{ item.id }}
									</td>
									<td>
										<a href="{{ item.url }}" target="_blank">{{ item.url }}</a>
									</td>
									<td>
										<ng-container *ngIf="translateProductAdditionalTypes">
											{{ translateProductAdditionalTypes[item.additionalType] }}
										</ng-container>
									</td>
									<td>
										<button
											type="button"
											class="button button-gray mr-2"
											(click)="openProductLinkModal(updateProductLinkTemplate, item)"
										>
											<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
										</button>
										<button
											type="button"
											class="button button-gray button-delete mr-2"
											(click)="openConfirmationModal(deleteProductLinkTemplate, item)"
											[style.color]="'#e61c1c'"
										>
											<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
										</button>
									</td>
								</tr>
							</ng-template>

							<ng-template pTemplate="emptymessage">
								<tr>
									<td [attr.colspan]="columns.length" class="empty-msg">
										{{ 'NO_DATA' | translate }}
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
			</div>
		</div>

		<!-- Add/update product link modal -->
		<ng-template #updateProductLinkTemplate>
			<div class="modal-header">
				<h4 class="modal-title">{{ productLinkForm.value.id ? translate.EDIT_LINKS : translate.ADD_LINKS }}</h4>
				<button type="button" class="close pull-right" (click)="updateProductLinkModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<form [formGroup]="productLinkForm" (ngSubmit)="submitProductLinkForm()">
				<div class="modal-body">
					<div class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.URL }}: <span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<input
								class="form-control"
								type="text"
								formControlName="url"
								[ngClass]="{ 'is-invalid': submitted && productLinkForm.controls.url.errors }"
							/>

							<app-form-validation-error-msg
								*ngIf="submitted && productLinkForm.controls.url.errors; let errors"
								class="invalid-feedback"
								[errors]="errors"
								[errorMessages]="translateErrors.FORM_VALIDATIONS"
							>
							</app-form-validation-error-msg>
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-lg-4 col-sm-12 form-control-text">
							{{ translate.TYPE }}: <span class="required-field">*</span>
						</div>
						<div class="col-lg-8 col-sm-12">
							<p-dropdown
								type="text"
								styleClass="form-control"
								[style.max-width.px]="400"
								[options]="linkTypeDescriptionDropdown"
								formControlName="additionalTypeId"
								[(ngModel)]="selectedType"
								[filter]="true"
							></p-dropdown>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<div class="required-field-text">
						{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
					</div>
					<div class="modal-footer-buttons">
						<button type="button" class="button button-gray" (click)="updateProductLinkModal.hide()">
							{{ 'CLOSE' | translate }}
						</button>
						<button
							type="submit"
							[disabled]="productLinkForm.dirty ? false : true || (observables.productLinksLoading | async)"
							class="button"
						>
							{{ 'SUBMIT' | translate }}
						</button>
					</div>
				</div>
			</form>
		</ng-template>

		<!-- Delete Product Link Confirmation Modal -->
		<ng-template #deleteProductLinkTemplate>
			<div class="modal-header">
				<h4 class="modal-title">
					<span>{{ translate.DELETE_PRODUCT_LINK_TITLE }}</span>
				</h4>
				<button type="button" class="close pull-right" (click)="deleteProductLinkModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{ translate.DELETE_PRODUCT_LINK_BODY }}</p>
			</div>
			<div class="modal-footer modal-footer-remove">
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="deleteProductLinkModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						[disabled]="observables.productLinksLoading"
						class="button"
						(click)="deleteProductLink()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</ng-template>
	</div>
</ng-container>
