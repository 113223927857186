import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as groupConnectionActions from './group-connections.actions';
import { GroupConnectionsService } from '../../core/services/group-connections.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { deeperCopy } from 'src/app/shared/utils/deeper-copy';

@Injectable()
export class GroupConnectionsEffects {
	constructor(
		private actions$: Actions,
		private groupConnectionsService: GroupConnectionsService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}
	loadGroupConnectionsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupConnectionActions.loadGroupConnections),
			mergeMap(() =>
				this.groupConnectionsService.getGroupConnections().pipe(
					map(groupConnections => groupConnectionActions.loadGroupConnectionsSuccess({ groupConnections })),
					catchError(error => of(groupConnectionActions.loadGroupConnectionsFailure({ error })))
				)
			)
		)
	);
	addGroupConnectionEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupConnectionActions.addGroupConnection),
			mergeMap(action =>
				this.groupConnectionsService.addGroupConnection(action.groupConnection).pipe(
					switchMap(groupConnection =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								const groupConnectionDeeperCopy = deeperCopy(action.groupConnection);
								groupConnectionDeeperCopy.id = groupConnection.id;
								this.toastr.success(translate);
								return groupConnectionActions.addGroupConnectionSuccess({ groupConnection: groupConnectionDeeperCopy });
							}),
							catchError(error => of(groupConnectionActions.addGroupConnectionFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateGroupConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupConnectionActions.updateGroupConnection),
			mergeMap(action =>
				this.groupConnectionsService.updateGroupConnection(action.groupConnection).pipe(
					switchMap(groupConnection =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return groupConnectionActions.updateGroupConnectionSuccess({
									groupConnection,
								});
							}),
							catchError(error => of(groupConnectionActions.updateGroupConnectionFailure({ error })))
						)
					)
				)
			)
		)
	);
	removeGroupConnection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(groupConnectionActions.removeGroupConnection),
			mergeMap(action =>
				this.groupConnectionsService.removeGroupConnection(action.id).pipe(
					switchMap(id =>
						this.translateService.get('SUCCESS.FORM_DELETE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return groupConnectionActions.removeGroupConnectionSuccess({ id });
							}),
							catchError(error => of(groupConnectionActions.removeGroupConnectionFailure({ error })))
						)
					)
				)
			)
		)
	);
}
