import { priceListFeatureKey, PriceListState, selectAll } from './price-list.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectPriceListState = createFeatureSelector<PriceListState>(priceListFeatureKey);

export const selectAllPriceList = createSelector(selectPriceListState, selectAll);

export const selectPriceListLoading = createSelector(
	selectPriceListState,
	state => state.statusState === LoadingState.LOADING
);

export const selectPriceListError = createSelector(selectPriceListState, (state: PriceListState) =>
	getError(state.statusState)
);
