import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { Update } from '@ngrx/entity';

import * as webUserSelectors from '../root-store/web-users-store/web-user.selectors';
import * as webUserActions from '../root-store/web-users-store/web-user.actions';
import * as customerSelectors from '../root-store/customers-store/customer.selectors';
import * as customerActions from '../root-store/customers-store/customer.actions';
import { WebUserState } from '../root-store/web-users-store/web-user.reducer';
import { WebUser } from '../shared/models/web-user';
import { CustomerState } from '../root-store/customers-store/customer.reducer';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-web-service-users',
	templateUrl: './web-service-users.component.html',
	styleUrls: ['./web-service-users.component.scss'],
})
export class WebServiceUsersComponent implements OnInit {
	updateWebUserModal: BsModalRef;
	deleteWebUserModal: BsModalRef;
	ngUnsubscribe: Subject<object> = new Subject();
	webUserForm: any;
	translate: any;
	translateFormValidationErrorMsgs: any;
	submitted: boolean;

	firstPageElementIndex = 0;

	customerList: SelectItem[];

	faPaperPlane = faPaperPlane;

	webUsersLoading$: Observable<boolean>;
	webUsers$: Observable<WebUser[]>;
	webUsersSubscriber: Subscription;

	currentWebUser: WebUser = new WebUser();

	columns: any[] = [
		{ name: 'id', title: 'USER_ID', width: 5 },
		{ name: 'username', title: 'USERNAME', width: 9 },
		{ name: 'password', title: 'PASSWORD', width: 12 },
		{ name: 'customerNo', title: 'CUSTOMER_LABEL', width: 12 },
		{ name: 'customerName', title: 'CUSTOMER_NAME', width: 17 },
		{ name: 'ip', title: 'IP_ADDRESS', width: 12 },
		{ name: 'lastDateModifiedPassword', title: 'LAST_UPDATED', width: 7 },
		{ name: 'showQtyAsProtected', title: 'SHOW_PROTECTED_QTYS', width: 10 },
		{ name: '', title: '', width: 16 },
	];

	constructor(
		private translateService: TranslateService,
		private store: Store<WebUserState>,
		private customerStore: Store<CustomerState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('WEB_SERVICE_USERS').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
		this.webUsers$ = this.store.select(webUserSelectors.selectAllWebUsers);

		this.customerStore
			.select(customerSelectors.selectAllCustomers)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(customers => {
				if (!customers.length) {
					this.customerStore.dispatch(customerActions.loadCustomers());
				} else {
					this.customerList = customers.map(customer => ({
						label: customer.name,
						value: customer.no,
					}));
					this.customerList.unshift({
						label: this.translate.SELECT_CUSTOMER,
						value: null,
					});
				}
			});

		this.webUsersLoading$ = this.store.select(webUserSelectors.selectWebUsersLoading);

		this.store.dispatch(webUserActions.loadWebUsers());
	}

	loadSelectedWebUserLabel(selectedWebUser: SelectItem, customerDropDown: Dropdown) {
		this.webUserForm.patchValue({
			customerNo: selectedWebUser.value,
			customerName: customerDropDown.selectedOption.label,
		});
	}

	openWebUserModal(updateWebUserTemplate: TemplateRef<any>, user) {
		this.submitted = false;
		this.webUserForm = this.formBuilder.group(
			user
				? {
						...user,
						username: [user.username, [Validators.required, Validators.maxLength(32)]],
						password: [user.password, [Validators.required, Validators.maxLength(64)]],
						customerNo: [user.customerNo, [Validators.required, Validators.maxLength(20)]],
				  }
				: {
						...new WebUser(),
						username: ['', [Validators.required, Validators.maxLength(32)]],
						password: ['', [Validators.required, Validators.maxLength(64)]],
						customerNo: ['', [Validators.required, Validators.maxLength(20)]],
				  }
		);
		this.updateWebUserModal = this.modalService.show(updateWebUserTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	submitWebUserForm() {
		this.submitted = true;
		if (this.webUserForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		if (this.webUserForm.value.id) {
			const tempWebUser: Update<WebUser> = {
				id: this.webUserForm.value.id,
				changes: this.webUserForm.value,
			};
			this.store.dispatch(webUserActions.updateWebUser({ webUser: tempWebUser }));
		} else {
			const tempWebUser = this.webUserForm.value;
			tempWebUser.lastDateModifiedPassword = new Date().toISOString();
			this.store.dispatch(webUserActions.addWebUser({ webUser: this.webUserForm.value }));

			this.firstPageElementIndex = 0;
		}
		this.updateWebUserModal.hide();
	}

	openConfirmationModal(deleteWebUserTemplate: TemplateRef<any>, user) {
		this.currentWebUser = user;
		this.deleteWebUserModal = this.modalService.show(deleteWebUserTemplate, { class: 'modal-md' });
	}

	deleteWebUser() {
		this.store.dispatch(webUserActions.deleteWebUser({ webUserId: this.currentWebUser.id }));
		this.deleteWebUserModal.hide();
	}
}
