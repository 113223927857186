<div class="skeleton-container" *ngIf="translate">
	<div class="skeleton-screen">
		<div class="global-filter-cont">
			<fa-icon icon="search"></fa-icon>
			<input
				type="text"
				class="form-control"
				[placeholder]="'GLOBAL_FILTER' | translate"
				data-tid="globalFilter"
				size="50"
				disabled
			/>
		</div>
		<div class="head">
			<span *ngFor="let col of columns" [style.width.%]="col.width"> {{ translate[col.title] }}</span>
		</div>
		<div class="body">
			<div class="post" *ngFor="let number of rows">
				<div class="cols" *ngFor="let col of columns" [style.width.%]="col.width">
					<span class="line" [style.width.%]="number.width"></span>
				</div>
			</div>
		</div>
		<div class="paging">
			<span class="line"></span>
		</div>
	</div>
</div>
