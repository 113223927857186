import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-skeleton-screen',
	templateUrl: './skeleton-screen.component.html',
	styleUrls: ['./skeleton-screen.component.scss'],
})
export class SkeletonScreenComponent implements OnInit {
	@Input() columns: any;
	@Input() translate: TranslateService;

	rows: any[] = [];

	constructor() {}

	ngOnInit() {
		for (let i = 0; i < 10; i++) {
			this.rows.push({ width: Math.floor(Math.random() * 20) + 50 });
		}
	}
}
