<div id="side-navbar">
	<div class="navigation" *ngIf="translate && menuItems">
		<ul class="section-list">
			<li
				class="section-item"
				*ngFor="let menuItem of menuItems"
				(mouseout)="selectedSection = ''"
				[ngClass]="{ selected: selectedNav === menuItem.selectedNav, collapsed: menuItem.selectedNav === collapsedNav }"
			>
				<span
					*ngIf="menuItem.childMenuItems"
					class="navbar-menuitem"
					data-toggle="collapse"
					data-target="#collapseList"
					(mouseover)="selectedSection = menuItem.title"
					(click)="collapsedNav = collapsedNav !== menuItem.selectedNav ? menuItem.selectedNav : null"
					[ngClass]="{ active: selectedSection === menuItem.title }"
					id="menuItem.title"
				>
					<div class="box-title-container">
						<span class="icon-box">
							<svg-icon [key]="menuItem.icon" color="selectedNav === menuItem.selectedNav ? $red : $black"></svg-icon>
						</span>
						<span class="section-title">
							<span class="title-text"> {{ menuItem.title }} </span> <i class="pi pi-angle-down"></i>
						</span>
					</div>
				</span>

				<div class="collapse-list" [hidden]="!showNavbar">
					<ul>
						<li
							*ngFor="let childMenuItem of menuItem.childMenuItems"
							routerLinkActive="router-link-active"
							[routerLink]="childMenuItem.routerLink"
							(click)="selectedNav = menuItem.selectedNav"
						>
							<a [routerLink]="childMenuItem.routerLink" [id]="childMenuItem.title"> {{ childMenuItem.title }} </a>
						</li>
					</ul>
				</div>

				<div class="side-hover-menu" (mouseover)="selectedSection = menuItem.title">
					<ul>
						<li class="first">{{ menuItem.title }}</li>

						<li
							*ngFor="let childMenuItem of menuItem.childMenuItems"
							[routerLink]="childMenuItem.routerLink"
							routerLinkActive="router-link-active"
							(click)="selectedNav = menuItem.selectedNav"
						>
							<a [routerLink]="childMenuItem.routerLink"> {{ childMenuItem.title }} </a>
						</li>
					</ul>
				</div>
			</li>
		</ul>
	</div>
</div>
