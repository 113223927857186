import { createAction, props } from '@ngrx/store';
import { UserLog } from '../../shared/models/user-log';
import { Update } from '@ngrx/entity';

export const loadUserLogs = createAction('[User Log Page] Load User Logs');

export const loadUserLogsSuccess = createAction(
	'[User Log API] Loaded User Logs Success',
	props<{ userLogs: UserLog[] }>()
);

export const loadUserLogsFailure = createAction('[User Log API] Loaded User Log Failure', props<{ error: any }>());

export const loadUserLogsByUserName = createAction(
	'[User Log API] Load User Logs By UserName',
	props<{ userLog: Update<UserLog> }>()
);

export const loadUserLogsByUserNameSuccess = createAction(
	'[User Log API] Loaded User Logs By UserName Success',
	props<{ userLog: Update<UserLog> }>()
);

export const loadUserLogsByUserNameFailure = createAction(
	'[User Log API] Loaded User Logs By UserName Failure',
	props<{ error: any }>()
);
