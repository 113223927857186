export class SpecialPrice {
	id: number;
	productName: string;
	productNo: string;
	customerNo: string;
	customerGroupId: number;
	fromDate: Date;
	toDate: Date;
	price: number;
	active: boolean;
}

export class SpecialPriceQuery {
	productNo: string;
	customerNo: string;
	customerGroupId: number;
	selectedCustomerGroupId: number;
}

export class LatestChange {
	productNo: string;
	productName: string;
	manufacturer: string;
	productGroup: string;
	sellerName: string;
	customerNo: string;
	customerName: string;
	created: Date;
	specialPrice: number;
	oldSpecialPrice: number;
	price: number;
	priceWithRabat: number;
	portalPrice: number;
}

export class SpecialPriceDeactivate {
	id: number;
	productName: string;
	productNo: string;
	customerNo: string;
	customerGroupId: number;
	fromDate: Date;
	toDate: Date;
	price: number;
	active: boolean;
	createdByUsername: string;
}
