import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromManufacturerActions from './manufacturer.actions';
import { ManufacturerService } from '../../core/services/manufacturer.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ManufacturerEffects {
	constructor(private actions$: Actions, private manufacturerService: ManufacturerService) {}

	loadManufacturersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromManufacturerActions.loadManufacturers),
			mergeMap(() =>
				this.manufacturerService.getManufacturers().pipe(
					map(manufacturers => fromManufacturerActions.loadManufacturersSuccess({ manufacturers })),
					catchError(error => of(fromManufacturerActions.loadManufacturersFailure({ error })))
				)
			)
		)
	);
}
