<div
	id="product-files"
	*ngIf="
		translate && {
			productFilesLoading: productFilesLoading$ | async,
			productFiles: productFiles$ | async
		} as observables
	"
>
	<div class="loading-cont" *ngIf="observables.productFilesLoading">
		<div class="spinner-border"></div>
		<span>{{ 'LOADING' | translate }}</span>
	</div>
	<div class="col-12" *ngIf="translateProductAdditionalTypes">
		<div>
			<ng-template #noFiles>
				<div class="product-configuration-preview">
					<h6>{{ translate.NO_PRODUCT_FILES }}</h6>
				</div>
			</ng-template>

			<div *ngIf="!observables.productFilesLoading">
				<div *ngIf="productFiles?.length > 0; else noFiles">
					<div class="product-configuration-preview">
						{{ translate.PRODUCT_FILES_PREVIEW }} #{{ productFiles[0].productNo }}
					</div>
				</div>

				<div class="mb-3">
					<label for="add-product-file" class="button upload-files mr-2 mb-0 d-inline-flex pt-2">
						<input
							type="file"
							accept=".docx, .doc, .xls, .xlsx, application/pdf, .txt"
							id="add-product-file"
							[(ngModel)]="uploadFiles"
							(change)="onSelectFile($event)"
							hidden
							multiple
						/>

						<fa-icon icon="plus" class="mr-1" [style.font-size.px]="14"></fa-icon>
						{{ translate.FILES }}
					</label>
					<ng-container *ngIf="selectedFiles?.length > 0">
						<button type="button" class="button button-white mr-2" (click)="selectedFiles = []">
							{{ 'CANCEL' | translate }}
						</button>
						<button
							type="button"
							class="button mr-1"
							(click)="fileUpload()"
							[disabled]="observables.productFilesLoading"
						>
							{{ 'SUBMIT' | translate }}
						</button>
					</ng-container>
				</div>

				<div class="upload-files-preview" *ngIf="selectedFiles && selectedFiles.length">
					<div class="mb-3">{{ translate.INSERT_FILES_INFO }}</div>
					<div *ngFor="let file of selectedFiles; let i = index">
						<i
							class="pi pi-file-pdf file-icon"
							[style.color]="'red'"
							*ngIf="fileExtensionParser(file.fileName).toUpperCase() === '.PDF'"
						></i>
						<i
							class="pi pi-file file-icon"
							[style.color]="'gray'"
							*ngIf="fileExtensionParser(file.fileName).toUpperCase() === '.TXT'"
						></i>
						<i
							class="pi pi-file-excel file-icon"
							[style.color]="'green'"
							*ngIf="
								fileExtensionParser(file.fileName).toUpperCase() === '.XLS' ||
								fileExtensionParser(file.fileName).toUpperCase() === '.XLSX'
							"
						></i>
						<i
							class="pi pi-file file-icon"
							[style.color]="'blue'"
							*ngIf="
								fileExtensionParser(file.fileName).toUpperCase() === '.DOC' ||
								fileExtensionParser(file.fileName).toUpperCase() === '.DOCX'
							"
						></i>

						{{ file.fileName }}
						<fa-icon
							icon="times"
							class="remove"
							(click)="removeFromUploadQueue(i)"
							tooltip="{{ translate.DISCARD }}"
						></fa-icon>

						<br />
						<p-dropdown
							type="text"
							styleClass="form-control col-3 mb-3"
							[style.max-width.px]="400"
							[options]="fileTypeDescriptionDropdown"
							[filter]="true"
							(onChange)="setDropdownType($event, i)"
						></p-dropdown>
					</div>
				</div>

				<div class="documents-cont col-12" *ngIf="productFiles && productFiles.length">
					<div class="row">
						<div class="col-lg-6 col-12" *ngFor="let doc of productFiles">
							<a
								class="file-link"
								href="{{ productFileDownloadPrefix }}{{ doc.fileName }}{{ doc.fileExtension }}"
								download="{{ doc.fileName }}{{ doc.fileExtension }}"
								target="_blank"
								title="{{ doc.fileName }}{{ doc.fileExtension }}"
							>
								<div class="document-card">
									<svg-icon key="pdf" *ngIf="doc.fileExtension === '.pdf'"></svg-icon>
									<svg-icon key="zip" *ngIf="doc.fileExtension === '.zip'"></svg-icon>
									<svg-icon key="text" *ngIf="doc.fileExtension !== '.pdf' && doc.fileExtension !== '.zip'"></svg-icon>
									<div class="doc-info">
										<div class="doc-title">
											<span class="file-title">{{ doc.fileName }}</span>
											<span class="file-ext">{{ doc.fileExtension }}</span>
										</div>
										<div class="muted-text">
											{{ doc.createdDate | date: 'dd-MM-yyyy' }} -
											{{ translateProductAdditionalTypes[doc.additionalType] }}
										</div>
									</div>

									<button
										class="button button-gray delete-file-button"
										(click)="$event.preventDefault(); openConfirmationModal(deleteProductFileTemplate, doc)"
									>
										<fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
									</button>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Delete Product File Confirmation Modal -->
	<ng-template #deleteProductFileTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ translate.DELETE_PRODUCT_FILE_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deleteProductFileModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.DELETE_PRODUCT_FILE_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deleteProductFileModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" [disabled]="observables.productFilesLoading" class="button" (click)="deleteProductFile()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</div>
