import { createAction, props } from '@ngrx/store';

import { ServiceReply } from 'src/app/shared/models/service-reply';

export const loadServiceReplies = createAction('[Service Reports Page] Load Service Replies', props<{ id: number }>());

export const loadServiceRepliesSuccess = createAction(
	'[Service Report Replies API] Loaded Service Replies Success',
	props<{ serviceReplies: ServiceReply[] }>()
);

export const loadServiceRepliesFailure = createAction(
	'[Service Report Replies API] Loaded Service Replies Failure',
	props<{ error: any }>()
);

export const addServiceReply = createAction(
	'[Service Reports Page] Add Service Reply',
	props<{ serviceReply: ServiceReply }>()
);

export const addServiceReplySuccess = createAction(
	'[Service Report Replies API] Add Service Reply Success',
	props<{ serviceReply: ServiceReply }>()
);

export const addServiceReplyFailure = createAction(
	'[Service Report Replies API] Add Service Reply Failure',
	props<{ error: any }>()
);
