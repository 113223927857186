import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromPriceList from './price-list.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PriceListEffects } from './price-list.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromPriceList.priceListFeatureKey, fromPriceList.reducer),
		EffectsModule.forFeature([PriceListEffects]),
	],
})
export class PriceListStoreModule {}
