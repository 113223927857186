import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserLog } from 'src/app/shared/models/user-log';
import { environment } from 'src/environments/sr/environment';
@Injectable()
export class UserLogService {
	constructor(private http: HttpClient) {}
	getUserLogs(): Observable<UserLog[]> {
		return this.http.get<UserLog[]>(environment.baseApiUrl + 'api/Logging/get-loggings');
	}
	getUserLogsByUserName(userName: string): Observable<UserLog[]> {
		return this.http.get<UserLog[]>(environment.baseApiUrl + 'api/Logging/' + userName + '/get-loggings/');
	}
}
