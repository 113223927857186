import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromFeatureActions from './feature.actions';
import { FeatureService } from '../../core/services/feature.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class FeatureEffects {
	constructor(private actions$: Actions, private featureService: FeatureService) {}

	loadFeaturesEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromFeatureActions.loadFeatures),
			mergeMap(() =>
				this.featureService.getFeatures().pipe(
					map(features => fromFeatureActions.loadFeaturesSuccess({ features })),
					catchError(error => of(fromFeatureActions.loadFeaturesFailure({ error })))
				)
			)
		)
	);
}
