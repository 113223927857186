import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PromotionSlider } from '../../shared/models/promotion-slider';
import { PromotionSliderItem } from 'src/app/shared/models/promotion-slider-item';

export const loadPromotionSliders = createAction('[Promotion Sliders Page] Load Promotion Sliders');
export const loadPromotionSlidersSuccess = createAction(
	'[Promotion Sliders API] Loaded Promotion Sliders Success',
	props<{ promotionSliders: PromotionSlider[] }>()
);
export const loadPromotionSlidersFailure = createAction(
	'[Promotion Sliders API] Loaded Promotion Sliders Failure',
	props<{ error: any }>()
);

export const addPromotionSlider = createAction(
	'[Promotion Sliders Page] Add Promotion Slider',
	props<{ promotionSlider: PromotionSlider }>()
);
export const addPromotionSliderSuccess = createAction(
	'[Promotion Sliders API] Add Promotion Slider Success',
	props<{ promotionSlider: PromotionSlider }>()
);
export const addPromotionSliderFailure = createAction(
	'[Promotion Sliders API] Add Promotion Slider Failure',
	props<{ error: any }>()
);

export const updatePromotionSlider = createAction(
	'[Promotion Sliders Page] Update Promotion Slider',
	props<{ promotionSlider: Update<PromotionSlider> }>()
);
export const updatePromotionSliderSuccess = createAction(
	'[Promotion Sliders API] Update Promotion Slider Success',
	props<{ promotionSlider: Update<PromotionSlider> }>()
);
export const updatePromotionSliderFailure = createAction(
	'[Promotion Sliders API] Update Promotion Slider Failure',
	props<{ error: any }>()
);

export const uploadModalImages = createAction(
	'[Promotion Sliders Page] Upload Modal Images',
	props<{
		selectedPromotionSlider: PromotionSlider;
		extraItems: any[];
	}>()
);
export const uploadModalImagesSuccess = createAction(
	'[Promotion Sliders API] Upload Modal Images Success',
	props<{ modalImages: PromotionSlider }>()
);
export const uploadModalImagesFailure = createAction(
	'[Promotion Sliders API] Upload Modal Images Failure',
	props<{ error: any }>()
);

export const deleteModalImage = createAction(
	'[Promotion Sliders Page] Delete Modal Image',
	props<{ promoSlider: PromotionSlider; item: PromotionSliderItem }>()
);
export const deleteModalImageSuccess = createAction(
	'[Promotion Sliders API] Delete Modal Image Success',
	props<{ promoSlider: PromotionSlider }>()
);
export const deleteModalImageFailure = createAction(
	'[Promotion Sliders API] Delete Modal Image Failure',
	props<{ error: any }>()
);

export const deleteModalFile = createAction(
	'[Promotion Sliders Page] Delete Modal File',
	props<{ promoSlider: PromotionSlider }>()
);
export const deleteModalFileSuccess = createAction(
	'[Promotion Sliders API] Delete Modal File Success',
	props<{ promoSlider: PromotionSlider }>()
);
export const deleteModalFileFailure = createAction(
	'[Promotion Sliders API] Delete Modal File Failure',
	props<{ error: any }>()
);

export const changeModalImagesOrder = createAction(
	'[Promotion Sliders Page] Change Modal Image Order',
	props<{ promotionSlider: PromotionSlider }>()
);
export const changeModalImagesOrderSuccess = createAction(
	'[Promotion Sliders API] Change Modal Image Order Success',
	props<{ promotionSlider: PromotionSlider }>()
);
export const changeModalImagesOrderFailure = createAction(
	'[Promotion Sliders API] Change Modal Image Order Failure',
	props<{ error: any }>()
);
