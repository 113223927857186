import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromVendorlandActions from './vendorland.actions';
import { VendorlandService } from '../../core/services/vendorland.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VendorlandEffects {
	constructor(
		private actions$: Actions,
		private vendorlandService: VendorlandService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadVendorlandEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromVendorlandActions.loadVendorland),
			mergeMap(() =>
				this.vendorlandService.getVendorlandGameResult().pipe(
					map(vendorland => fromVendorlandActions.loadVendorlandSuccess({ vendorland })),
					catchError(error => of(fromVendorlandActions.loadVendorlandFailure({ error })))
				)
			)
		)
	);

	updateVendorlandEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromVendorlandActions.updateVendorland),
			mergeMap(action =>
				this.vendorlandService.updateVendorland(action.vendorland).pipe(
					switchMap(vendorland =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromVendorlandActions.updateVendorlandSuccess({ vendorland });
							}),
							catchError(error => of(fromVendorlandActions.updateVendorlandFailure({ error })))
						)
					)
				)
			)
		)
	);
}
