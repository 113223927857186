import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as serviceActions from './service.actions';
import { ServiceReportsService } from 'src/app/core/services/service-reports.service';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ServiceEffects {
	constructor(
		private actions$: Actions,
		private serviceReportsService: ServiceReportsService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadServiceItems$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceActions.loadServices),
			mergeMap(() =>
				this.serviceReportsService.getAllService().pipe(
					map(services => serviceActions.loadServiceSuccess({ services })),
					catchError(error => of(serviceActions.loadServicesFailure({ error })))
				)
			)
		)
	);

	addServiceEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceActions.addService),
			mergeMap(action =>
				this.serviceReportsService.addRegisteredService(action.service).pipe(
					switchMap(service =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return serviceActions.addServiceSuccess({ service });
							}),
							catchError(error => of(serviceActions.addServiceFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateServiceEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceActions.updateService),
			mergeMap(action =>
				this.serviceReportsService.updateRegisteredService(action.service).pipe(
					switchMap(service =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return serviceActions.updateServiceSuccess({ service });
							}),
							catchError(error => of(serviceActions.updateServiceFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteServiceEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceActions.deleteService),
			mergeMap(action =>
				this.serviceReportsService.deleteRegisteredService(action.id).pipe(
					switchMap(id =>
						this.translateService.get('SUCCESS.FORM_DELETE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return serviceActions.deleteServiceSuccess({ id });
							}),
							catchError(error => of(serviceActions.deleteServiceFailure({ error })))
						)
					)
				)
			)
		)
	);
}
