export class ProductGroup {
	id: number;
	code: string;
	description: string;
	selected?: boolean;
	parentCode: string;
	level: number;
	image: string;
	actionLink: string;
	isNav: boolean;
	order: number;
	modifiedDate: Date;
	modifiedBy: string;
	columns: number;
	imageUrl: string;
	changed?: boolean;
	children?: ProductGroup[];
	parent?: ProductGroup;
	bannerFromDate?: Date;
	bannerToDate?: Date;

	constructor() {
		this.id = 0;
		this.code = '';
		this.description = '';
		this.parentCode = '';
		this.level = 0;
		this.image = '';
		this.actionLink = '';
		this.order = 0;
		this.columns = 0;
		this.imageUrl = '';
	}
}
