import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AllowedQuantity } from '../../shared/models/allowed-quantity';
import * as AllowedQuantityActions from './allowed-quantity.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const allowedQuantityFeatureKey = 'allowedQuantity';

export interface AllowedQuantityState extends EntityState<AllowedQuantity> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<AllowedQuantity> = createEntityAdapter<AllowedQuantity>();

export const initialState: AllowedQuantityState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const allowedQuantityReducer = createReducer(
	initialState,
	on(AllowedQuantityActions.loadAllowedQuantity, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AllowedQuantityActions.loadAllowedQuantitySuccess, (state, action) =>
		adapter.setAll(action.allowedQuantity, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AllowedQuantityActions.loadAllowedQuantityFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(AllowedQuantityActions.addAllowedQuantity, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AllowedQuantityActions.addAllowedQuantitySuccess, (state, action) =>
		adapter.addOne(action.allowedQuantity, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AllowedQuantityActions.addAllowedQuantityFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(AllowedQuantityActions.updateAllowedQuantity, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AllowedQuantityActions.updateAllowedQuantitySuccess, (state, action) =>
		adapter.updateOne(action.allowedQuantity, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AllowedQuantityActions.updateAllowedQuantityFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(AllowedQuantityActions.deleteAllowedQuantity, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AllowedQuantityActions.deleteAllowedQuantitySuccess, (state, action) =>
		adapter.removeOne(action.AllowedQuantityId, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AllowedQuantityActions.deleteAllowedQuantityFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: AllowedQuantityState | undefined, action: Action) {
	return allowedQuantityReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
