import { cloneDeep } from 'lodash-es';
import { utils as XLSXUtils, writeFile } from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx/types';

export function exportAsExcel(data?: any, fileName?: any, sheetName = 'Data', header = [], table?: any): void {
	let wb: WorkBook;
	let ws: WorkSheet;
	const fileExtension = '.xlsx';
	if (table) {
		wb = XLSXUtils.table_to_book(table);
	} else {
		// column width resizing
		const columnsWidth = [];
		const longestData = cloneDeep(data);
		for (const element of Object.keys(data[0])) {
			const longest = longestData.sort((a, b) => {
				if (!a[element]) {
					if (b[element]) {
						return b[element].length;
					} else {
						return 0;
					}
				}
				if (!b[element]) {
					if (a[element]) {
						return a[element].length;
					} else {
						return 0;
					}
				}
				return b[element].length - a[element].length;
			})[0];
			if (element?.length > longest[element]?.length) {
				columnsWidth.push({ wch: element.length });
			} else {
				let len;
				if (longest[element]?.length) {
					len = longest[element].length;
				} else {
					len = element.length;
				}
				columnsWidth.push({ wch: len });
			}
		}
		ws = XLSXUtils.json_to_sheet(data, { header });
		ws['!cols'] = columnsWidth;
		wb = XLSXUtils.book_new();
		XLSXUtils.book_append_sheet(wb, ws, sheetName);
	}
	writeFile(wb, `${fileName}${fileExtension}`);
}
