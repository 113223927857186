import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductFilter from './product-filter.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductFilterEffects } from './product-filter.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductFilter.productFiltersFeatureKey, fromProductFilter.reducer),
		EffectsModule.forFeature([ProductFilterEffects]),
	],
})
export class ProductFiltersStoreModule {}
