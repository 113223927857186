export function dateRangeFilter(value: any, rangeDates: any) {
	if (rangeDates) {
		const from = new Date(rangeDates[0]).toISOString();
		if (rangeDates[0] && rangeDates[1]) {
			if (rangeDates[0].toISOString() === rangeDates[1].toISOString()) {
				const to = new Date(rangeDates[0]);
				to.setDate(to.getDate() + 1);
				return value > from && value < to.toISOString();
			} else {
				const to = new Date(rangeDates[1]).toISOString();
				return value > from && value < to;
			}
		} else {
			if (rangeDates[0] && !rangeDates[1]) {
				return value > from;
			}
			return false;
		}
	} else {
		return true;
	}
}
