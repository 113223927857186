<div
	class="header-cont"
	[ngClass]="{ toggled: showNavbar }"
	*ngIf="
		translate && {
			canImpersonate: authService.canImpersonate$ | async,
			isImpersonating: authService.isImpersonating$ | async
		} as observables
	"
>
	<div>
		<div class="logo-container">
			<a [routerLink]="['/home']" class="site-logo">
				<img [src]="environment.localImagesLocation + 'CT4-admin-portal-logo.svg'" alt="B2B Logo" />
			</a>
		</div>
	</div>
	<div class="lang-header">
		<div class="lang-cont">
			<a
				class="lang text-uppercase"
				*ngFor="let lang of languages"
				(click)="changeLanguage(lang)"
				[ngClass]="{ active: lang == selectedLanguage }"
				id="{{ lang }}"
			>
				{{ lang }}
			</a>
		</div>
	</div>

	<div class="user-header">
		<div class="user-container">
			<div class="user-panel">
				<div class="panel-item">
					<a>{{ (authService.currentUser$ | async)?.name }}</a>
				</div>

				<div class="user-circle">
					<svg-icon key="users"></svg-icon>
				</div>
			</div>

			<div class="user-dropdown">
				<div class="user-dropdown-container">
					<a [routerLink]="['/my-profile']" *ngIf="environment.isOAuthEnabled" class="nav-link">
						<svg-icon key="users"></svg-icon>
						{{ translate.MY_PROFILE }}
					</a>

					<a *ngIf="environment.isOAuthEnabled" class="nav-link" (click)="authenticationService.changePassword()">
						<fa-icon icon="key"> </fa-icon>
						{{ translate.CHANGE_PASSWORD }}
					</a>

					<a
						*ngIf="environment.isOAuthEnabled && (observables.canImpersonate || observables.isImpersonating)"
						class="nav-link"
						(click)="authService.impersonate()"
					>
						<fa-icon icon="sign-in-alt"> </fa-icon>
						{{ translate.IMPERSONATE }}
					</a>

					<button
						class="button text-center w-100 mt-3"
						*ngIf="!observables.isImpersonating"
						(click)="authService.logout()"
					>
						{{ translate.LOGOUT }}
					</button>
					<button class="button text-center w-100 mt-3" *ngIf="observables.isImpersonating" (click)="stopImpersonate()">
						{{ translate.STOP_IMPERSONATE }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
