import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PromotionSlider } from 'src/app/shared/models/promotion-slider';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';
import { PromotionSliderItem } from 'src/app/shared/models/promotion-slider-item';

@Injectable()
export class PromotionSliderService {
	constructor(private http: HttpClient) {}

	getPromotionSliders(): Observable<PromotionSlider[]> {
		return this.http.get<PromotionSlider[]>(environment.baseApiUrl + 'api/ProductActionSlider');
	}

	addPromotionSlider(promotion: PromotionSlider): Observable<PromotionSlider> {
		return this.http.post<PromotionSlider>(environment.baseApiUrl + 'api/ProductActionSlider', promotion);
	}

	updatePromotionSlider(promotion: Update<PromotionSlider>): Observable<Update<PromotionSlider>> {
		return this.http
			.put<Update<PromotionSlider>>(environment.baseApiUrl + 'api/ProductActionSlider', promotion.changes)
			.pipe(map(() => promotion));
	}

	uploadModalImages(modalImages: PromotionSliderItem[]): Observable<PromotionSliderItem[]> {
		return this.http.post<PromotionSliderItem[]>(
			`${environment.baseApiUrl}api/ProductActionSlider/modal/images`,
			modalImages
		);
	}

	updateModalImagesOrder(modalImages: PromotionSliderItem[]): Observable<PromotionSliderItem[]> {
		return this.http
			.put<PromotionSliderItem[]>(`${environment.baseApiUrl}api/ProductActionSlider/modal/images/order`, modalImages)
			.pipe(map(() => modalImages));
	}

	deleteModalImage(id: number) {
		return this.http
			.delete<PromotionSlider>(`${environment.baseApiUrl}api/ProductActionSlider/modal/images/${id}`)
			.pipe(map(() => id));
	}

	deleteModalFile(id: number) {
		return this.http
			.delete<PromotionSlider>(`${environment.baseApiUrl}api/ProductActionSlider/modal/file/${id}`)
			.pipe(map(() => id));
	}
}
