import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCustomerGroup from './customer-group.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerGroupEffects } from './customer-group.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromCustomerGroup.customerGroupFeatureKey, fromCustomerGroup.reducer),
		EffectsModule.forFeature([CustomerGroupEffects]),
	],
})
export class CustomerGroupStoreModule {}
