export enum Permissions {
	// invidual permissions
	// TODO: some permissions should be removed (probably)
	ActiveCustomers = 'ActiveCustomers', // currently does not exist (maybe delete)
	Adriatico = 'Adriatico', // currently does not exist (maybe delete)
	BusinessHelp = 'BusinessHelp', // currently does not exist (maybe delete)
	Games = 'Games', // currently does not exist (maybe delete)
	GuestBookLog = 'GuestBookLog', // currently does not exist (maybe delete)
	HomePage = 'HomePage',
	HPConfigurator = 'HPConfigurator', // not being used anywhere in old portal
	InfoDesk = 'InfoDesk', // currently does not exist (maybe delete)
	IsAdmin = 'IsAdmin',
	MyAccount = 'MyAccount', // it's being used on PortalFront not here...
	MyPortal = 'MyPortal', // not being used anywhere in old portal
	Orders = 'Orders', // currently does not exist (maybe delete)
	Polls = 'Polls', // currently does not exist (maybe delete)
	PortalAccessLog = 'PortalAccessLog',
	PortalActiveAccessLog = 'PortalActiveAccessLog', // currently does not exist (maybe delete)
	Pricelists = 'Pricelists',
	Promotions = 'Promotions',
	Sellers = 'Sellers',
	Service = 'Service',
	Stock = 'Stock', // not being used anywhere in old portal
	Users = 'Users',
	ProductChooseGroupSearch = 'Product.ChooseGroup.Search', // it's being used on PortalFront not here...
	ProductImagesUpdate = 'ProductImages.Update',

	// Adriatico - currently does not exist (maybe delete)
	AdriaticoGroups = 'Adriatico.Groups',
	AdriaticoProducts = 'Adriatico.Products',

	// BusinessHelp - currently does not exist (maybe delete)
	BusinessHelpCreditApplication = 'BusinessHelp.CreditApplication',
	BusinessHelpDownload = 'BusinessHelp.Download',
	BusinessHelpHelp = 'BusinessHelp.Help',
	BusinessHelpPortalHelp = 'BusinessHelp.PortalHelp',

	// Games - currently does not exist (maybe delete)
	GamesProductAction = 'Games.ProductAction',
	GamesQuiz = 'Games.Quiz',

	// InfoDesk - currently does not exist (maybe delete)
	InfoDeskArrivals = 'InfoDesk.Arrivals',
	InfoDeskCompanyProfile = 'InfoDesk.CompanyProfile',
	InfoDeskDeliveries = 'InfoDesk.Deliveries',
	InfoDeskDeliveryRequest = 'InfoDesk.DeliveryRequest',
	InfoDeskNews = 'InfoDesk.News',
	InfoDeskNotifications = 'InfoDesk.Notifications',
	InfoDeskProductRequest = 'InfoDesk.ProductRequest',
	InfoDeskSalesContract = 'InfoDesk.SalesContract',

	MenuProductDeclarations = 'Menu.ProductDeclarations', // currently does not exist (maybe delete)
	MenuPromotionSlider = 'Menu.PromotionSlider',
	MenuVendorland = 'Menu.Vendorland',

	// MyPortal - not being used anywhere in old portal
	MyPortalCompletedOrders = 'MyPortal.CompletedOrders',
	MyPortalLedger = 'MyPortal.Ledger',
	MyPortalOrders = 'MyPortal.Orders',
	MyPortalPayments = 'MyPortal.Payments',
	MyPortalPersonalInfo = 'MyPortal.PersonalInfo',
	MyPortalPersonalization = 'MyPortal.Personalization',

	// Orders - currently does not exist (maybe delete)
	OrdersConfigurator = 'Orders.Configurator',
	OrdersExtra = 'Orders.Extra',
	OrdersGroups = 'Orders.Groups',
	OrdersManufacturers = 'Orders.Manufacturers',
	OrdersPersonalizedList = 'Orders.PersonalizedList',
	OrdersShipmentMethod = 'Orders.ShipmentMethod',
	OrdersStockSearch = 'Orders.StockSearch',

	// Pricelists - currently does not exist (maybe delete)
	PricelistsExcel = 'Pricelists.Excel',
	PricelistsOther = 'Pricelists.Other',
	PricelistsXML = 'Pricelists.XML',

	// Service
	ServiceCanViewClosingComment = 'Service.CanViewClosingComment', // it's being used on PortalFront not here...
	ServiceCanViewOpeningComment = 'Service.CanViewOpeningComment', // it's being used on PortalFront not here...
	ServiceReportsClosed = 'Service.ReportsClosed',
	ServiceReportsOpen = 'Service.ReportsOpen',
	ServiceReportsQueue = 'Service.ReportsQueue',
	ServiceStatus = 'Service.Status', // not being used anywhere in old portal
	RegisteredService = 'RegisteredService',
	ServiceSupportRequest = 'Service.SupportRequest', // not being used anywhere in old portal

	// Stock - not being used anywhere in old portal
	StockExcelList = 'Stock.ExcelList',
	StockGroupsList = 'Stock.GroupsList',
	StockList = 'Stock.List',
	StockManufacturersList = 'Stock.ManufacturersList',
	StockPersonalizedList = 'Stock.PersonalizedList',

	// Users
	UsersAdmins = 'Users.Admins',
	UsersChangingPassword = 'Users.ChangingPassword', // - currently does not exist (maybe delete)
	UsersCustomersAddGroupOrManufacturerCode = 'Users.CustomersAddGroupOrManufacturerCode',
	UsersGroups = 'Users.Groups',
	UsersPortalUsers = 'Users.PortalUsers',
	UsersRegistrations = 'Users.Registrations', // - currently does not exist (maybe delete)
	UsersUsersSW = 'Users.UsersSW',
	UsersDashboard = 'Users.Dashboard',
	UsersSpecialPrice = 'Users.SpecialPrice',

	// Products
	MenuAddSpecification = 'Menu.AddSpecification',
	MenuPictureOrder = 'Menu.PictureOrder', // TODO: maybe rename? it's permission related to button (currently)
	MenuProductImages = 'Menu.ProductImages',
	ProductGroupConnections = 'Product.GroupConnections',
	ProductProductGroups = 'Product.ProductGroups',
}
