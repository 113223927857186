import { createAction, props } from '@ngrx/store';
import { ProductAdditionalType } from '../../shared/models/product-additional-type';

export const loadProductAdditionalTypes = createAction('[Product Additional Types Page] Load ProductAdditionalTypes');

export const loadProductAdditionalTypesSuccess = createAction(
	'[ProductAdditionalTypes API] Loaded ProductAdditionalTypes Success',
	props<{ productAdditionalTypes: ProductAdditionalType[] }>()
);

export const loadProductAdditionalTypesFailure = createAction(
	'[ProductAdditionalTypes API] Loaded ProductAdditionalTypes Failure',
	props<{ error: any }>()
);
