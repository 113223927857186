import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ViberPromotionActions from './viber-promotion.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { ViberPromotion } from 'src/app/shared/models/viber-promotion';

export const viberPromotionsFeatureKey = 'viberPromotions';

export interface ViberPromotionState extends EntityState<ViberPromotion> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ViberPromotion> = createEntityAdapter<ViberPromotion>();

export const initialState: ViberPromotionState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const viberPromotionReducer = createReducer(
	initialState,
	on(ViberPromotionActions.loadViberPromotions, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ViberPromotionActions.loadViberPromotionsSuccess, (state, action) =>
		adapter.setAll(action.viberPromotions, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ViberPromotionActions.loadViberPromotionsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ViberPromotionActions.addViberPromotion, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ViberPromotionActions.addViberPromotionSuccess, (state, action) => {
		return adapter.upsertMany(action.updatedPromotions, {
			...state,
			statusState: LoadingState.LOADED,
		});
	}),
	on(ViberPromotionActions.addViberPromotionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ViberPromotionActions.updateViberPromotion, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ViberPromotionActions.updateViberPromotionSuccess, (state, action) =>
		adapter.updateMany(action.promotion, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ViberPromotionActions.updateViberPromotionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ViberPromotionState | undefined, action: Action) {
	return viberPromotionReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
