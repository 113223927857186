import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductAdditionalTypeEffects } from './product-additional-types.effects';
import * as fromProductAdditionalType from './product-additional-types.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromProductAdditionalType.productAdditionalTypesFeatureKey,
			fromProductAdditionalType.reducer
		),
		EffectsModule.forFeature([ProductAdditionalTypeEffects]),
	],
})
export class ProductAdditionalTypesStoreModule {}
