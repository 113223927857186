import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductAdditionaTypesActions from './product-additional-types.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProductAdditionalTypesService } from 'src/app/core/services/product-additional-types.service';

@Injectable()
export class ProductAdditionalTypeEffects {
	constructor(private actions$: Actions, private productAdditionalTypeService: ProductAdditionalTypesService) {}

	loadProductExtrasEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductAdditionaTypesActions.loadProductAdditionalTypes),
			mergeMap(() =>
				this.productAdditionalTypeService.getProductAdditionalTypes().pipe(
					map(productAdditionalTypes =>
						fromProductAdditionaTypesActions.loadProductAdditionalTypesSuccess({
							productAdditionalTypes,
						})
					),
					catchError(error => of(fromProductAdditionaTypesActions.loadProductAdditionalTypesFailure({ error })))
				)
			)
		)
	);
}
