import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CustomerGroup } from '../../shared/models/customer-group';
import * as CustomerGroupActions from './customer-group.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const customerGroupFeatureKey = 'customer-group';

export interface CustomerGroupState extends EntityState<CustomerGroup> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<CustomerGroup> = createEntityAdapter<CustomerGroup>({
	selectId: customerGroup => customerGroup.id,
});
export const initialState: CustomerGroupState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const customerReducer = createReducer(
	initialState,
	on(CustomerGroupActions.loadCustomerGroups, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerGroupActions.loadCustomerGroupsSuccess, (state, action) =>
		adapter.setAll(action.customerGroups, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerGroupActions.loadCustomerGroupsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: CustomerGroupState | undefined, action: Action) {
	return customerReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
