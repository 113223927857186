import { createAction, props } from '@ngrx/store';
import { UserRegistration } from '../../shared/models/user-registration';

export const loadUserRegistration = createAction('[UserRegistration/API] Load UserRegistrations');

export const loadUserRegistrationSuccess = createAction(
	'[UserRegistration/API] Loaded UserRegistrations Success',
	props<{ userRegistration: UserRegistration[] }>()
);

export const loadUserRegistrationFailure = createAction(
	'[UserRegistration/API] Loaded UserRegistrations Failure',
	props<{ error: any }>()
);
