import { Component, OnInit, TemplateRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { faAward, faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';

import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import * as vendorlandSelectors from '../root-store/vendorland-store/vendorland.selectors';
import * as vendorlandActions from '../root-store/vendorland-store/vendorland.actions';

import { VendorlandState } from '../root-store/vendorland-store/vendorland.reducer';
import { VendorlandGameResult } from '../shared/models/vendorlandGameResult';
import { Update } from '@ngrx/entity';

@Component({
	selector: 'app-vendorland',
	templateUrl: './vendorland.component.html',
	styleUrls: ['./vendorland.component.scss'],
})
export class VendorlandComponent implements OnInit {
	updateVendorlandModal: BsModalRef;

	firstPageElementIndex = 0;

	faAward = faAward;
	faTrophy = faTrophy;
	faMedal = faMedal;

	translate: any;
	vendorlandLoading$: Observable<boolean>;
	vendorland$: Observable<VendorlandGameResult[]>;
	currentVendorlandCustomer: VendorlandGameResult = new VendorlandGameResult();

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'customerId', title: 'CUSTOMER_ID', width: 10 },
		{ name: 'companyName', title: 'COMPANY_NAME', width: 12 },
		{ name: 'customerName', title: 'CUSTOMER_NAME', width: 12 },
		{ name: 'gameName', title: 'GAME_NAME', width: 11 },
		{ name: 'time', title: 'TIME', width: 7 },
		{ name: 'moves', title: 'MOVES', width: 6 },
		{ name: 'totalScore', title: 'TOTAL_SCORE', width: 10 },
		{ name: 'playedOn', title: 'PLAYED_ON', width: 10 },
		{ name: 'isWinner', title: 'IS_WINNER', width: 9 },
		{ name: '', title: '', width: 9 },
	];

	constructor(
		private translateService: TranslateService,
		private store: Store<VendorlandState>,
		private modalService: BsModalService
	) {}

	ngOnInit() {
		this.translateService.get('VENDORLAND').subscribe((resp: any) => (this.translate = resp));

		this.vendorland$ = this.store.select(vendorlandSelectors.selectAllVendorland);
		this.vendorlandLoading$ = this.store.select(vendorlandSelectors.selectVendorlandLoading);

		this.store.dispatch(vendorlandActions.loadVendorland());
	}

	openVendorlandModal(updateVendorlandTemplate: TemplateRef<any>, vendorland: VendorlandGameResult) {
		this.currentVendorlandCustomer = vendorland;
		this.updateVendorlandModal = this.modalService.show(updateVendorlandTemplate, { class: 'modal-md' });
	}

	submitVendorlandForm() {
		this.currentVendorlandCustomer.isWinner = this.currentVendorlandCustomer.isWinner ? 0 : 1;
		const tempVendorland: Update<VendorlandGameResult> = {
			id: this.currentVendorlandCustomer.id,
			changes: this.currentVendorlandCustomer,
		};
		this.store.dispatch(vendorlandActions.updateVendorland({ vendorland: tempVendorland }));
		this.updateVendorlandModal.hide();
	}
}
