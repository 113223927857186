import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductImage } from '../../shared/models/product-image';
import * as ProductImageActions from './product-image.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { cloneDeep } from 'lodash-es';

export const productImagesFeatureKey = 'productImages';

export interface ProductImageState extends EntityState<ProductImage> {
	// additional entities state properties
	statusState: StatusState;
}

export function sortByOrderNumber(a: ProductImage, b: ProductImage): number {
	if (a.orderNumber < b.orderNumber) {
		return -1;
	}

	if (a.orderNumber > b.orderNumber) {
		return 1;
	}

	return 0;
}

export const adapter: EntityAdapter<ProductImage> = createEntityAdapter<ProductImage>({
	sortComparer: sortByOrderNumber,
});

export const initialState: ProductImageState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const productImageReducer = createReducer(
	initialState,
	on(ProductImageActions.loadProductImages, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductImageActions.loadProductImagesSuccess, (state, action) =>
		adapter.setAll(action.productImages, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductImageActions.loadProductImagesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ProductImageActions.addProductImages, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductImageActions.addProductImagesSuccess, (state, action) =>
		adapter.setAll(action.productImages, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductImageActions.addProductImagesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ProductImageActions.changeImageStatus, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductImageActions.changeImageStatusSuccess, (state, action) =>
		adapter.updateOne(
			{ id: action.productImage.id, changes: action.productImage },
			{
				...state,
				statusState: LoadingState.LOADED,
			}
		)
	),
	on(ProductImageActions.changeImageStatusFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ProductImageActions.changeImagesOrder, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductImageActions.changeImagesOrderSuccess, (state, action) => {
		return adapter.setAll(action.productImages, {
			...state,
			statusState: LoadingState.LOADED,
		});
	}),
	on(ProductImageActions.changeImagesOrderFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ProductImageActions.setDefaultImage, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductImageActions.setDefaultImageSuccess, (state, action) => {
		let newState: ProductImageState = cloneDeep(state);

		newState = adapter.map(image => {
			if (image.id === action.productImage.id) {
				image = {
					...image,
					default: true,
					orderNumber: 0,
				};
			} else if (image.orderNumber < action.productImage.orderNumber) {
				image = {
					...image,
					default: false,
					orderNumber: image.orderNumber + 1,
				};
			} else {
				image = {
					...image,
					default: false,
				};
			}
			return image;
		}, state);

		return {
			...newState,
			statusState: LoadingState.LOADED,
		};
	}),
	on(ProductImageActions.setDefaultImageFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductImageState | undefined, action: Action) {
	return productImageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
