export class ProductExtra {
	id: number;
	productNo: string;
	fileName: string;
	fileExtension: string;
	url: string;
	type: string;
	additionalTypeId: number;
	additionalType: string;
	createdDate: Date;
}
