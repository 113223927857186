import { productGroupsFeatureKey, ProductGroupState, selectAll } from './product-group.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { ProductGroup } from 'src/app/shared/models/product-group';
import { sortBy } from 'lodash-es';

export const selectProductGroupState = createFeatureSelector<ProductGroupState>(productGroupsFeatureKey);

export const selectAllProductGroups = createSelector(selectProductGroupState, selectAll);

export const selectAllProductGroupsSortedByOrder = createSelector(
	selectAllProductGroups,
	(productGroups: ProductGroup[]) => {
		return sortBy(productGroups, 'order');
	}
);

export const selectProductGroupsLoading = createSelector(
	selectProductGroupState,
	state => state.statusState === LoadingState.LOADING
);

export const selectProductGroupsError = createSelector(selectProductGroupState, (state: ProductGroupState) =>
	getError(state.statusState)
);

export const selectProductGroupByCode = createSelector(
	selectAllProductGroups,
	(productGroups: ProductGroup[], params: any) => {
		return productGroups.find(c => c.code === params.productGroup);
	}
);
