import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { BrowserUsageChartComponent } from './browser-usage-chart/browser-usage-chart.component';
import { DeviceUsageChartComponent } from './device-usage-chart/device-usage-chart.component';
import { OperatingSystemsUsageChartComponent } from './operating-systems-usage-chart/operating-systems-usage-chart.component';
import { NumberOfVisitsChartComponent } from './number-of-visits-chart/number-of-visits-chart.component';

@NgModule({
	declarations: [
		DashboardComponent,
		BrowserUsageChartComponent,
		DeviceUsageChartComponent,
		OperatingSystemsUsageChartComponent,
		NumberOfVisitsChartComponent,
	],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class DashboardModule {}
