import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductImagesActions from './product-image.actions';
import { ProductImageService } from '../../core/services/product-images.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ProductImageEffects {
	constructor(
		private actions$: Actions,
		private productImageService: ProductImageService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadProductImagesEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductImagesActions.loadProductImages),
			mergeMap(action =>
				this.productImageService.getProductImages(action.commonItemNo).pipe(
					map(productImages => {
						if (productImages.length) {
							if (!productImages.some(image => image.default) && productImages.some(img => img.active)) {
								this.translateService.get('PRODUCT_IMAGES.NO_MAIN_IMAGE').subscribe((noMainImage: string) => {
									this.toastr.toastrConfig.preventDuplicates = true;
									this.toastr.warning(noMainImage);
								});
							}
						}
						return fromProductImagesActions.loadProductImagesSuccess({ productImages });
					}),
					catchError(error => of(fromProductImagesActions.loadProductImagesFailure({ error })))
				)
			)
		)
	);

	addProductImagesEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductImagesActions.addProductImages),
			mergeMap(action =>
				this.productImageService.addProductImages(action.productImages).pipe(
					switchMap(() => {
						return forkJoin({
							translation: this.translateService.get('SUCCESS.FORM_SUBMIT'),
							productImageReload: this.productImageService.getProductImages(action.productId),
						}).pipe(
							map(response => {
								this.toastr.success(response.translation);

								return fromProductImagesActions.addProductImagesSuccess({
									productImages: response.productImageReload,
								});
							}),
							catchError(error => of(fromProductImagesActions.addProductImagesFailure({ error })))
						);
					})
				)
			)
		)
	);

	changeImageStatusEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductImagesActions.changeImageStatus),
			mergeMap(action =>
				this.productImageService.changeImageStatus(action.productImage).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(successfulEdit => {
								this.toastr.success(successfulEdit);
								return fromProductImagesActions.changeImageStatusSuccess({ productImage: action.productImage });
							}),
							catchError(error => of(fromProductImagesActions.changeImageStatusFailure({ error })))
						)
					)
				)
			)
		)
	);

	setDefaultImageEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductImagesActions.setDefaultImage),
			mergeMap(action =>
				this.productImageService.setDefaultImage(action.productImage).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(successfulEdit => {
								this.toastr.success(successfulEdit);
								return fromProductImagesActions.setDefaultImageSuccess({
									productImage: action.productImage,
								});
							}),
							catchError(error => of(fromProductImagesActions.setDefaultImageFailure({ error })))
						)
					)
				)
			)
		)
	);

	changeImageOrderEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductImagesActions.changeImagesOrder),
			mergeMap(action =>
				this.productImageService.changeImageOrder(action.productImages).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(successfulEdit => {
								this.toastr.success(successfulEdit);
								return fromProductImagesActions.changeImagesOrderSuccess({
									productImages: action.productImages,
								});
							}),
							catchError(error => of(fromProductImagesActions.changeImagesOrderFailure({ error })))
						)
					)
				)
			)
		)
	);
}
