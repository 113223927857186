import { createAction, props } from '@ngrx/store';

import { Group } from '../../shared/models/group';
import { Update } from '@ngrx/entity';

export const loadGroups = createAction('[Groups Page] Load Groups');

export const loadGroupsSuccess = createAction('[Groups API] Loaded Groups Success', props<{ groups: Group[] }>());

export const loadGroupsFailure = createAction('[Groups API] Loaded Groups Failure', props<{ error: any }>());

export const addGroup = createAction('[Groups Page] Add Group', props<{ group: Group }>());

export const addGroupSuccess = createAction('[Groups API] Add Group Success', props<{ group: Group }>());

export const addGroupFailure = createAction('[Groups API] Add Group Failure', props<{ error: any }>());

export const updateGroup = createAction('[Groups Page] Update Group', props<{ group: Update<Group> }>());

export const updateGroupSuccess = createAction('[Groups API] Update Group Success', props<{ group: Update<Group> }>());

export const updateGroupFailure = createAction('[Groups API] Update Group Failure', props<{ error: any }>());
