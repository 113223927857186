import { inactiveUserFeatureKey, InactiveUserState, selectAll } from './inactive-users.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectInactiveUserState = createFeatureSelector<InactiveUserState>(inactiveUserFeatureKey);

export const selectAllInactiveUser = createSelector(selectInactiveUserState, selectAll);

export const selectInactiveUserLoading = createSelector(
	selectInactiveUserState,
	state => state.statusState === LoadingState.LOADING
);

export const selectInactiveUserError = createSelector(selectInactiveUserState, (state: InactiveUserState) =>
	getError(state.statusState)
);
