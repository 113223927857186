import { Observable, throwError, of } from 'rxjs';
import { retryWhen, mergeMap, delay } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_DELAY_MS = 1000;

export function delayedRetry(delayMs = DEFAULT_DELAY_MS, maxRetry = DEFAULT_MAX_RETRIES) {
	let retries = maxRetry;

	return (src: Observable<any>) =>
		src.pipe(
			retryWhen((errors: Observable<any>) =>
				errors.pipe(
					delay(delayMs),
					mergeMap(error => (retries-- > 0 ? of(error) : throwError(error)))
				)
			)
		);
}
