import { createAction, props } from '@ngrx/store';

import { GuestBook } from '../../shared/models/guest-book';

export const loadGuestBookItems = createAction('[GuestBook Page] Load Guest Book Items');

export const loadGuestBookItemsSuccess = createAction(
	'[GuestBook API] Loaded Guest Book Items Success',
	props<{ guestBook: GuestBook[] }>()
);

export const loadGuestBookItemsFailure = createAction(
	'[GuestBook API] Loaded Guest Book Items Failure',
	props<{ error: any }>()
);
