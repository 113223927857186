import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-form-validation-error-msg',
	templateUrl: './form-validation-error-msg.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./form-validation-error-msg.component.scss'],
})
export class FormValidationErrorMsgComponent implements OnInit {
	@Input() errors: any;
	@Input() errorMessages: any;

	constructor() {}

	ngOnInit(): void {}
}
