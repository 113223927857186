<ng-container *ngIf="translate && { groupsLoading: groupsLoading$ | async } as observables">
	<div class="groups-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openGroupsModal(updateGroupTemplate, null)"
			[disabled]="observables.groupsLoading"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.groupsLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.groupsLoading">
			<p-table
				#grouptable
				[value]="groups$ | async"
				[(first)]="firstPageElementIndex"
				[autoLayout]="true"
				class="groups-prime-table"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'title', 'isAdmin']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="grouptable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="60">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'title'">
							{{ translate.GROUP_TITLE }}
							<p-sortIcon [field]="'title'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'isAdmin'" class="text-center" [style.width.px]="220">
							{{ translate.IS_ADMIN }}
							<p-sortIcon [field]="'isAdmin'"></p-sortIcon>
						</th>
						<th [style.width.px]="120">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-group let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ group.id }}</b>
						</td>
						<td>{{ group.title }}</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="group.isAdmin"></fa-icon></td>
						<td class="text-right">
							<button type="button" class="button button-gray" (click)="openGroupsModal(updateGroupTemplate, group)">
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<!-- Add/Edit group modal -->
	<ng-template #updateGroupTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left" *ngIf="groupForm.value.id">{{ translate.EDIT_GROUP }}</h4>
			<h4 class="modal-title pull-left" *ngIf="!groupForm.value.id">{{ translate.ADD_GROUP }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updateGroupModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="groupForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.GROUP_TITLE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							formControlName="title"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && groupForm.controls.title.errors }"
							maxlength="128"
						/><app-form-validation-error-msg
							*ngIf="submitted && groupForm.controls.title.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-4 form-control-text">{{ translate.IS_ADMIN }}:</div>
					<div class="col">
						<label class="chbx-container mr-3" [class.checked]="groupForm.value.isAdmin">
							<input
								type="checkbox"
								formControlName="isAdmin"
								[(ngModel)]="groupForm.value.isAdmin"
								[ngClass]="{ checked: groupForm.value.isAdmin }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
				<hr />
				<div class="row list mb-3">
					<div class="col-lg-6 col-sm-12" *ngFor="let item of features">
						<label class="chbx-container" [class.checked]="item.checked">
							<input
								type="checkbox"
								[ngClass]="{ checked: item.checked }"
								(change)="item.checked = !item.checked; featuresChanged = true"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-10" key="checkmark"></svg-icon
							></span>
							{{ translate[item.identifier] ? translate[item.identifier] : item.identifier + '!' }}
						</label>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateGroupModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="groupForm.dirty || featuresChanged ? false : true || observables.groupsLoading"
						(click)="submitGroupForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
