import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductListState, productListFeatureKey, selectAll } from './product-list.reducer';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductState = createFeatureSelector<ProductListState>(productListFeatureKey);

// Select Product List
export const selectProducts = createSelector(selectProductState, selectAll);

// TODO: check if necessary
export const selectListTitle = createSelector(selectProductState, (state: ProductListState) => state.listTitle);
export const selectBanner = createSelector(selectProductState, (state: ProductListState) => state.banner);
// Common
export const selectProductsLoading = createSelector(
	selectProductState,
	(state: ProductListState) => state.statusState === LoadingState.LOADING
);
export const selectProductsError = createSelector(selectProductState, (state: ProductListState) =>
	getError(state.statusState)
);
