import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AdditionalSpecificationService {
	constructor(private http: HttpClient) {}

	addSpecification(file: any): Observable<boolean> {
		return this.http.post(environment.baseApiUrl + 'api/AddSpecification', file).pipe(map(() => true));
	}
}
