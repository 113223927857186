export class Target {
	id: number;
	targetId: string;
	customer: string;
	customerNo: string;
	manufacturer: string;
	manufacturerCode: string;
	productGroup: string;
	productGroupCode: string;
	price: number;
	quantity: number;
	fromDate: string;
	toDate: string;
	bonusPercentage: number;
	completed: boolean;
	createdBy: string;
	createdDate: Date;
	active: boolean;
	priceRealization: number;
	priceRealizationPercentage: number;
	quantityRealization: number;
	quantityRealizationPercentage: number;
	visibleOnPortal: boolean;
}
