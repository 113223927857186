<ng-container
	*ngIf="
		translate && {
			targetsLoading: targetsLoading$ | async,
			customersLoading: customersLoading$ | async,
			manufacturersLoading: manufacturersLoading$ | async,
			productGroupsLoading: productGroupsLoading$ | async
		} as observables
	"
>
	<div class="targets-comp">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openTargetModal(updateTargetTemplate, null)"
			[disabled]="
				observables.targetsLoading ||
				observables.customersLoading ||
				observables.productGroupsLoading ||
				observables.manufacturersLoading
			"
			data-tid="addTarget"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="
				(observables.targetsLoading ||
					observables.customersLoading ||
					observables.productGroupsLoading ||
					observables.manufacturersLoading) &&
				columns
			"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>

		<div
			*ngIf="
				!observables.targetsLoading &&
				!observables.customersLoading &&
				!observables.productGroupsLoading &&
				!observables.manufacturersLoading
			"
		>
			<p-table
				#targetsTable
				[value]="targets"
				[autoLayout]="true"
				[paginator]="true"
				[rows]="10"
				[loading]="observables.customersLoading && observables.targetsLoading"
				[(first)]="firstPageElementIndex"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="[
					'id',
					'targetId',
					'customer',
					'manufacturer',
					'productGroup',
					'price',
					'quantity',
					'fromDate',
					'toDate',
					'bonusPercentage',
					'completed',
					'createdBy',
					'createdDate'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="p-grid">
						<div class="p-col-4">
							<p-dropdown
								#customerFilterDropdown
								class="table-dropdown-options"
								[appendTo]="targetsTable"
								[options]="customersFilterDropdown"
								[showClear]="true"
								[virtualScroll]="true"
								[(ngModel)]="selectedCustomer"
								itemSize="60"
								[filter]="true"
								[placeholder]="this.translate.SELECT_CUSTOMER_DROPDOWN"
								filterBy="value"
								(onChange)="manageCustomerDropdownChange()"
							></p-dropdown>
						</div>
						<div class="p-col-3">
							<input
								bsDaterangepicker
								class="form-control date-picker"
								[bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
								[placeholder]="selectDateRangeFilter"
								(bsValueChange)="filterByDate($event)"
								[(bsValue)]="daterangeFilter"
								readonly
							/>
						</div>
						<div class="p-col-1">
							<button *ngIf="daterangeFilter || selectedCustomer" class="button button-gray" (click)="clearFilters()">
								{{ translate.CLEAR_DATERANGE_FILTER }}
							</button>
						</div>
						<div class="p-col-4">
							<div class="global-filter-cont">
								<fa-icon icon="search"></fa-icon>
								<input
									type="text"
									class="form-control"
									pInputText
									size="50"
									[placeholder]="'GLOBAL_FILTER' | translate"
									data-tid="globalFilter"
									(input)="targetsTable.filterGlobal($event.target.value, 'contains')"
								/>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'customer'">
							{{ translate.CUSTOMER }}
							<p-sortIcon [field]="'customer'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'manufacturer'">
							{{ translate.MANUFACTURER }}
							<p-sortIcon [field]="'manufacturer'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'productGroup'" [style.width.px]="15">
							{{ translate.PRODUCT_GROUP }}
							<p-sortIcon [field]="'productGroup'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'price'">
							{{ translate.AMOUNT }}
							<p-sortIcon [field]="'price'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'quantity'" [style.width.px]="50">
							{{ translate.QUANTITY }}
							<p-sortIcon [field]="'quantity'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'fromDate'">
							{{ translate.FROM_DATE }}
							<p-sortIcon [field]="'fromDate'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'toDate'">
							{{ translate.TO_DATE }}
							<p-sortIcon [field]="'toDate'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'bonusPercentage'">
							{{ translate.BONUS_PERCENTAGE }} (%)
							<p-sortIcon [field]="'bonusPercentage'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'visibleOnPortal'" [style.width.px]="100">
							{{ translate.VISIBLE_ON_PORTAL }}
							<p-sortIcon [field]="'visibleOnPortal'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'completed'">
							{{ translate.COMPLETED }}
							<p-sortIcon [field]="'completed'"></p-sortIcon>
						</th>
						<th [style.width.%]="6">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-target let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ target.targetId }}</b>
						</td>
						<td>{{ target.customer }}</td>
						<td>{{ target.manufacturer }}</td>
						<td>{{ target.productGroup }}</td>
						<td class="nowrap">
							{{ target.price | number: '1.2-2' }}
							<span *ngIf="target.price !== null && target.price !== 0">&euro;</span>
						</td>
						<td>{{ target.quantity | number }}</td>
						<td class="nowrap">{{ target.fromDate | date: 'dd-MM-yyyy' }}</td>
						<td class="nowrap">{{ target.toDate | date: 'dd-MM-yyyy' }}</td>
						<td class="text-center">{{ target.bonusPercentage | number: '1.2-2' }}%</td>
						<td class="text-center">
							<fa-icon *ngIf="target.visibleOnPortal" icon="check" [style.color]="'green'"></fa-icon>

							<fa-icon *ngIf="!target.visibleOnPortal" icon="times" [style.color]="'red'"></fa-icon>
						</td>

						<td class="text-center">
							<div
								class="target-completion-percentage"
								*ngIf="target.quantityRealizationPercentage"
								[ngClass]="{
									'completion-success': target.quantityRealizationPercentage > 100,
									'completion-gt-75': target.quantityRealizationPercentage >= 75,
									'completion-gt-25-lt-75':
										target.quantityRealizationPercentage >= 25 && target.quantityRealizationPercentage < 75,
									'completion-lt-25': target.quantityRealizationPercentage < 25,
									'completion-failure': target.quantityRealizationPercentage < 100 && target.toDate < currentDate
								}"
							>
								<div class="percentage">{{ target.quantityRealizationPercentage | number: '1.2-2' }}%</div>
								<div class="label">{{ translate.QUANTITY_COMPLETION }}</div>
							</div>
							<div
								class="target-completion-percentage"
								*ngIf="target.priceRealizationPercentage"
								[ngClass]="{
									'completion-success': target.priceRealizationPercentage > 100,
									'completion-gt-75':
										target.priceRealizationPercentage >= 75 && target.priceRealizationPercentage < 100,
									'completion-gt-25-lt-75':
										target.priceRealizationPercentage >= 25 && target.priceRealizationPercentage < 75,
									'completion-lt-25': target.priceRealizationPercentage < 25,
									'completion-failure': target.priceRealizationPercentage < 100 && target.toDate < currentDate
								}"
							>
								<div class="percentage">{{ target.priceRealizationPercentage | number: '1.2-2' }}%</div>
								<div class="label">{{ translate.PRICE_COMPLETION }}</div>
							</div>
						</td>

						<td class="text-right">
							<button
								type="button"
								class="button button-gray"
								(click)="openTargetModal(updateTargetTemplate, target)"
								[disabled]="observables.targetsLoading || observables.customersLoading"
								data-tid="editTarget"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- Add/edit Target Modal -->
	<ng-template #updateTargetTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				{{ targetForm.value.id ? translate.EDIT_TARGET : translate.ADD_TARGET }}
				{{ targetForm.value.id ? targetForm.value.targetId : '' }}
			</h4>
			<button type="button" class="close pull-right" (click)="updateTargetModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<form [formGroup]="targetForm" (ngSubmit)="submitTargetForm()">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.CUSTOMER }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							*ngIf="!targetForm.value.id; else targetEdit"
							#customerDropdown
							id="customer"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.customerNo.errors }"
							formControlName="customerNo"
							[style.max-width.px]="400"
							[filter]="true"
							(onChange)="getSelectedCustomerName(customerDropdown)"
							[options]="customers"
							[virtualScroll]="true"
							itemSize="60"
							[placeholder]="selectFromDropdown"
						></p-dropdown>

						<ng-template #targetEdit>
							<input class="form-control" formControlName="customerNo" />
						</ng-template>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.customerNo.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.MANUFACTURER }}:</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							#manufacturerDropdown
							id="manufacturer"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.manufacturer.errors }"
							formControlName="manufacturer"
							[style.max-width.px]="400"
							[filter]="true"
							(onChange)="getSelectedManufacturerName(manufacturerDropdown)"
							[options]="manufacturers"
							[virtualScroll]="true"
							itemSize="30"
							[placeholder]="selectFromDropdown"
						></p-dropdown>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.manufacturer.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.PRODUCT_GROUP }}:</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							#productGroupDropdown
							id="productGroup"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.productGroup.errors }"
							formControlName="productGroup"
							[style.max-width.px]="400"
							[filter]="true"
							(onChange)="getSelectedProductGroupName(productGroupDropdown)"
							[options]="productGroups"
							[virtualScroll]="true"
							itemSize="30"
							[placeholder]="selectFromDropdown"
						></p-dropdown>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.productGroup.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.AMOUNT }} (&euro;):</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							numbersOnly
							decimal="true"
							[pattern]="decimalNegativePattern"
							maxlength="15"
							formControlName="price"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.price.errors }"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.price.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.QUANTITY }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							numbersOnly
							maxlength="15"
							formControlName="quantity"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.quantity.errors }"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.quantity.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FROM_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="fromDate"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.fromDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[minDate]="minDate"
							[placeholder]="translate.SELECT_FROM_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.fromDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.TO_DATE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							bsDatepicker
							class="form-control date-picker"
							formControlName="toDate"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.toDate.errors }"
							[bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showClearButton: true, showWeekNumbers: false }"
							[minDate]="targetForm.value.fromDate ? targetForm.value.fromDate : minDate"
							[placeholder]="translate.SELECT_TO_DATE"
							placement="top"
							readonly
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.fromDate.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.BONUS_PERCENTAGE }} (%): <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							numbersOnly
							decimal="true"
							[pattern]="decimalNegativePattern"
							maxlength="15"
							formControlName="bonusPercentage"
							[ngClass]="{ 'is-invalid': submitted && targetForm.controls.bonusPercentage.errors }"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && targetForm.controls.bonusPercentage.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.VISIBLE_ON_PORTAL }}:</div>
					<div class="col-lg-8 col-sm-12">
						<label class="chbx-container mr-3" [class.checked]="targetForm.value.visibleOnPortal">
							<input
								type="checkbox"
								formControlName="visibleOnPortal"
								[ngClass]="{ checked: targetForm.value.visibleOnPortal }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateTargetModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="submit"
						[disabled]="targetForm.dirty ? false : true || observables.targetsLoading"
						class="button"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
