export class WebUser {
	id: number;
	username: string;
	password: string;
	customerNo: string;
	customerName: string;
	ip: string;
	showQtyAsProtected: boolean;
	lastDateModifiedPassword: string;
	notAllowedCols: string;
	timeOfAction: string;

	constructor() {
		this.id = 0;
		this.username = null;
		this.password = null;
		this.customerNo = null;
		this.customerName = null;
		this.ip = null;
		this.showQtyAsProtected = null;
		this.lastDateModifiedPassword = null;
		this.notAllowedCols = null;
		this.timeOfAction = null;
	}
}
