import { createAction, props } from '@ngrx/store';

import { ProductImage } from '../../shared/models/product-image';

export const loadProductImages = createAction(
	'[ProductImages Page] Load Product Images',
	props<{ commonItemNo: string }>()
);
export const loadProductImagesSuccess = createAction(
	'[Product Images API] Loaded Product Images Success',
	props<{ productImages: ProductImage[] }>()
);
export const loadProductImagesFailure = createAction(
	'[Product Images API] Loaded Product Images Failure',
	props<{ error: any }>()
);

export const addProductImages = createAction(
	'[ProductImage Page] Add Product Images',
	props<{ productImages: any; productId: string }>()
);
export const addProductImagesSuccess = createAction(
	'[Product Images API] Added Product Images Success',
	props<{ productImages: ProductImage[] }>()
);
export const addProductImagesFailure = createAction(
	'[Product Images API] Added Product Images Failure',
	props<{ error: any }>()
);

export const changeImageStatus = createAction(
	'[ProductImage Page] Change Image Status',
	props<{ productImage: ProductImage }>()
);
export const changeImageStatusSuccess = createAction(
	'[Product Images API] Change Image Status Success',
	props<{ productImage: ProductImage }>()
);
export const changeImageStatusFailure = createAction(
	'[Product Images API] Change Image Status Failure',
	props<{ error: any }>()
);

export const changeImagesOrder = createAction(
	'[ProductImage Page] Change Image Order',
	props<{ productImages: ProductImage[] }>()
);
export const changeImagesOrderSuccess = createAction(
	'[Product Image Api] Change Image Order Success',
	props<{ productImages: ProductImage[] }>()
);
export const changeImagesOrderFailure = createAction(
	'[Product Image Api] Change Image Order Failure',
	props<{ error: any }>()
);

export const setDefaultImage = createAction(
	'[ProductImage Page] Set Default Image',
	props<{ productImage: ProductImage }>()
);
export const setDefaultImageSuccess = createAction(
	'[Product Images API] Set Default Image Success',
	props<{ productImage: ProductImage }>()
);
export const setDefaultImageFailure = createAction(
	'[Product Images API] Set Default Image Failure',
	props<{ error: any }>()
);
