import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Customer } from '../../shared/models/customer';
import * as CustomerActions from './customer.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const customersFeatureKey = 'customers';

export interface CustomerState extends EntityState<Customer> {
	// additional entities state properties
	statusState: StatusState;
	selectedCustomerStatusState: StatusState;
}

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
	selectId: customer => customer.no,
});
export const initialState: CustomerState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	selectedCustomerStatusState: LoadingState.INIT,
});

export const customerReducer = createReducer(
	initialState,
	on(CustomerActions.loadCustomers, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.loadCustomersSuccess, (state, action) =>
		adapter.setAll(action.customers, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.loadCustomersFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(CustomerActions.loadCustomerInfoById, state => {
		return {
			...state,
			selectedCustomerStatusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.loadCustomerInfoByIdSuccess, (state, action) =>
		adapter.setOne(action.customer, {
			...state,
			selectedCustomerStatusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.loadCustomerInfoByIdFailure, (state, action) => {
		return {
			...state,
			selectedCustomerStatusState: { error: action.error },
		};
	}),

	on(CustomerActions.updateCustomerExtraInfo, state => {
		return {
			...state,
			selectedCustomerStatusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.updateCustomerExtraInfoSuccess, (state, action) =>
		adapter.setOne(action.customer, {
			...state,
			selectedCustomerStatusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.updateCustomerExtraInfoFailure, (state, action) => {
		return {
			...state,
			selectedCustomerStatusState: { error: action.error },
		};
	}),

	on(CustomerActions.removeCustomerLogo, state => {
		return {
			...state,
			selectedCustomerStatusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.removeCustomerLogoSuccess, (state, action) =>
		adapter.setOne(action.customer, {
			...state,
			selectedCustomerStatusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.removeCustomerLogoFailure, (state, action) => {
		return {
			...state,
			selectedCustomerStatusState: { error: action.error },
		};
	}),

	on(CustomerActions.removeCustomerFile, state => {
		return {
			...state,
			selectedCustomerStatusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.removeCustomerFileSuccess, (state, action) =>
		adapter.setOne(action.customer, {
			...state,
			selectedCustomerStatusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.removeCustomerFileFailure, (state, action) => {
		return {
			...state,
			selectedCustomerStatusState: { error: action.error },
		};
	}),

	on(CustomerActions.addCustomerFile, state => {
		return {
			...state,
			selectedCustomerStatusState: LoadingState.LOADING,
		};
	}),
	on(CustomerActions.addCustomerFileSuccess, (state, action) =>
		adapter.setOne(action.customer, {
			...state,
			selectedCustomerStatusState: LoadingState.LOADED,
		})
	),
	on(CustomerActions.addCustomerFileFailure, (state, action) => {
		return {
			...state,
			selectedCustomerStatusState: { error: action.error },
		};
	})
);

export function reducer(state: CustomerState | undefined, action: Action) {
	return customerReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
