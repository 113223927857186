import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromViberPromotion from './viber-promotion.reducer';
import { ViberPromotionEffects } from './viber-promotion.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromViberPromotion.viberPromotionsFeatureKey, fromViberPromotion.reducer),
		EffectsModule.forFeature([ViberPromotionEffects]),
	],
})
export class ViberPromotionsStoreModule {}
