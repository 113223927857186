import { Action, createReducer, on } from '@ngrx/store';
import * as ProductAdditionalTypeActions from './product-additional-types.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductAdditionalType } from '../../shared/models/product-additional-type';

export const productAdditionalTypesFeatureKey = 'productAdditionalType';

export interface ProductAdditionalTypeState extends EntityState<ProductAdditionalType> {
	productId: string;
	statusState: StatusState;
}

export const adapter: EntityAdapter<ProductAdditionalType> = createEntityAdapter<ProductAdditionalType>();

export const initialState: ProductAdditionalTypeState = adapter.getInitialState({
	// additional entity state properties
	productId: '',
	statusState: LoadingState.INIT,
});

const productLinksReducer = createReducer(
	initialState,
	on(ProductAdditionalTypeActions.loadProductAdditionalTypes, state => {
		return {
			...state,
			productId: '',
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductAdditionalTypeActions.loadProductAdditionalTypesSuccess, (state, action) =>
		adapter.setAll(action.productAdditionalTypes, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductAdditionalTypeActions.loadProductAdditionalTypesFailure, (state, action) => {
		return {
			...state,
			productId: '',
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductAdditionalTypeState | undefined, action: Action) {
	return productLinksReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
