import { createAction, props } from '@ngrx/store';
import { ProductDetails } from '../../shared/models/product-details';

// load product details
export const loadProductDetails = createAction(
	'[Product Info] Load Product Details Request',
	props<{ productNo: string }>()
);
export const loadProductDetailsSuccess = createAction(
	'[Product API] Load Product Details Success',
	props<{ productDetails: ProductDetails }>()
);
export const loadProductDetailsFailure = createAction(
	'[Product API] Load Product Details Failure',
	props<{ error: any }>()
);
