import { vendorlandFeatureKey, VendorlandState, selectAll } from './vendorland.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectVendorlandState = createFeatureSelector<VendorlandState>(vendorlandFeatureKey);

export const selectAllVendorland = createSelector(selectVendorlandState, selectAll);

export const selectVendorlandLoading = createSelector(
	selectVendorlandState,
	state => state.statusState === LoadingState.LOADING
);

export const selectVendorlandError = createSelector(selectVendorlandState, (state: VendorlandState) =>
	getError(state.statusState)
);
