import { promotionsFeatureKey, PromotionState, selectAll } from './promotion.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectPromotionState = createFeatureSelector<PromotionState>(promotionsFeatureKey);

export const selectAllPromotions = createSelector(selectPromotionState, selectAll);

export const selectPromotionsLoading = createSelector(
	selectPromotionState,
	(state: PromotionState) => state.statusState === LoadingState.LOADING
);

export const selectPromotionsError = createSelector(selectPromotionState, (state: PromotionState) =>
	getError(state.statusState)
);
export const selectActivePromotions = createSelector(selectPromotionState, selectAll);

export const selectActivePromotionsLoading = createSelector(
	selectPromotionState,
	(state: PromotionState) => state.statusState === LoadingState.LOADING
);

export const selectActivePromotionsError = createSelector(selectPromotionState, (state: PromotionState) =>
	getError(state.statusState)
);
