import { serviceRepliesFeatureKey, ServiceReplyState, selectAll } from './service-reply.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectServiceReplyState = createFeatureSelector<ServiceReplyState>(serviceRepliesFeatureKey);

export const selectAllServiceReplies = createSelector(selectServiceReplyState, selectAll);

export const selectServiceRepliesLoading = createSelector(
	selectServiceReplyState,
	state => state.statusState === LoadingState.LOADING
);

export const selectServiceRepliesError = createSelector(selectServiceReplyState, (state: ServiceReplyState) =>
	getError(state.statusState)
);
