import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromAllowedQuantity from './allowed-quantity.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AllowedQuantityEffects } from './allowed-quantity.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromAllowedQuantity.allowedQuantityFeatureKey, fromAllowedQuantity.reducer),
		EffectsModule.forFeature([AllowedQuantityEffects]),
	],
})
export class AllowedQuantityStoreModule {}
