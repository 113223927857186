import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductDetailState, productDetailsFeatureKey } from './product-detail.reducer';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductDetailsState = createFeatureSelector<ProductDetailState>(productDetailsFeatureKey);

export const selectProductDetails = createSelector(
	selectProductDetailsState,
	(state: ProductDetailState, props) => state.entities[props.productNo]
);

export const selectProductDetailsLoading = createSelector(
	selectProductDetailsState,
	(state: ProductDetailState) => state.statusState === LoadingState.LOADING
);

export const selectProductDetailsError = createSelector(selectProductDetailsState, (state: ProductDetailState) =>
	getError(state.statusState)
);
