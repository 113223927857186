import { createAction, props } from '@ngrx/store';
import { ProductFilters } from '../../shared/models/product-filters';
import { ProductListFilter } from '../../shared/models/product-list-filter';

export const loadProductFilters = createAction(
	'[Product List] Load Product Filters Request',
	props<{ listFilter: ProductListFilter }>()
);
export const loadProductFiltersSuccess = createAction(
	'[Product API] Load Product Filters Success',
	props<{ productFilters: ProductFilters }>()
);
export const loadProductFiltersFailure = createAction(
	'[Product API] Load Product Filters Failure',
	props<{ error: any }>()
);
