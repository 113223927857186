import { guestBookItemsFeatureKey, GuestBookState, selectAll } from './guest-book.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectGuestBookState = createFeatureSelector<GuestBookState>(guestBookItemsFeatureKey);

export const selectAllGuestBookItems = createSelector(selectGuestBookState, selectAll);

export const selectGuestBookItemsLoading = createSelector(
	selectGuestBookState,
	state => state.statusState === LoadingState.LOADING
);

export const selectGuestBookItemsError = createSelector(selectGuestBookState, (state: GuestBookState) =>
	getError(state.statusState)
);
