import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { SpecialPriceService } from 'src/app/core/services/special-price.service';
import * as productActions from './product-list.actions';

@Injectable()
export class ProductListEffects {
	constructor(private actions$: Actions, private specialPriceService: SpecialPriceService) {}

	loadProductListItems$ = createEffect(() =>
		this.actions$.pipe(
			ofType(productActions.loadProductListItems),
			mergeMap(action =>
				this.specialPriceService.getProductListItems(action.listFilter).pipe(
					map(productListItems => productActions.loadProductListItemsSuccess({ productListItems })),
					catchError(error => of(productActions.loadProductListItemsFailure({ error })))
				)
			)
		)
	);
}
