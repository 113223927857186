import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SellersStoreModule } from './sellers-store/sellers-store.module';
import { ProductAdditionalTypesStoreModule } from './product-additional-types-store/product-additional-types-store.module';
import { ProductDataStoreModule } from './product-data-store/product-data-store.module';
import { ProductExtrasStoreModule } from './product-extras-store/product-extras-store.module';
import { ProductImagesStoreModule } from './product-images-store/product-images-store.module';
import { PromotionsStoreModule } from './promotions-store/promotions-store.module';
import { AdministratorsStoreModule } from './administrators-store/administrators-store.module';
import { WebUsersStoreModule } from './web-users-store/web-users-store.module';
import { PromotionSlidersStoreModule } from './promotion-sliders-store/promotion-sliders-store.module';
import { GroupsStoreModule } from './groups-store/groups-store.module';
import { FeaturesStoreModule } from './features-store/features-store.module';
import { VendorlandStoreModule } from './vendorland-store/vendorland-store.module';
import { AllowedQuantityStoreModule } from './allowed-quantity-store/allowed-quantity-store.module';
import { ProductGroupsStoreModule } from './product-groups-store/product-groups-store.module';
import { ProductGroupsNavStoreModule } from './product-groups-nav-store/product-groups-nav-store.module';
import { ServiceReportsStoreModule } from './service-reports-store/service-reports-store.module';
import { ServiceRepliesStoreModule } from './service-replies-store/service-replies-store.module';
import { PriceListStoreModule } from './price-list-store/price-list-store.module';
import { CustomersStoreModule } from './customers-store/customers-store.module';
import { CustomerRecordsStoreModule } from './customer-records-store/customer-records-store.module';
import { ManufacturersStoreModule } from './manufacturers-store/manufacturers-store.module';
import { ManufacturersFilteredStoreModule } from './manufacturers-filtered-store/manufacturers-filtered-store.module';
import { UserRegistrationStoreModule } from './user-registration-store/user-registration-store.module';
import { DashboardStoreModule } from './dashboard-store/dashboard-store.module';
import { UserLogsStoreModule } from './user-logs-store/user-logs.store.module';
import { GroupConnectionsStoreModule } from './group-connections-store/group-connections-store.module';
import { TargetsStoreModule } from './targets-store/targets-store.module';
import { HomePageStoreModule } from './home-page-store/home-page-store.module';
import { InactiveUsersStoreModule } from './inactive-users-store/inactive-users-store.module';
import { ViberPromotionsStoreModule } from './viber-promotions-store/viber-promotions-store.module';
import { GuestBookStoreModule } from './guest-book-store/guest-book.module';
import { ProductFiltersStoreModule } from './product-filters-store/product-filters-store.module';
import { ProductListStoreModule } from './product-list-store/product-list-store.module';
import { ExchangeRatesStoreModule } from './exchange-rates-store/exchange-rates-store.module';
import { ProductDetailsStoreModule } from './product-details-store/product-details-store.module';
import { MenuStoreModule } from './menu-store/menu-store.module';
import { ServiceStoreModule } from './service-store/service.store.module';
import { CustomerGroupStoreModule } from './customer-group-store/customer-group.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		AdministratorsStoreModule,
		AllowedQuantityStoreModule,
		CustomerRecordsStoreModule,
		CustomersStoreModule,
		DashboardStoreModule,
		FeaturesStoreModule,
		GroupConnectionsStoreModule,
		GroupsStoreModule,
		HomePageStoreModule,
		InactiveUsersStoreModule,
		ManufacturersFilteredStoreModule,
		ManufacturersStoreModule,
		PriceListStoreModule,
		MenuStoreModule,
		ProductAdditionalTypesStoreModule,
		ProductDataStoreModule,
		ProductExtrasStoreModule,
		ProductGroupsNavStoreModule,
		ProductGroupsStoreModule,
		ProductImagesStoreModule,
		PromotionSlidersStoreModule,
		PromotionsStoreModule,
		SellersStoreModule,
		ServiceRepliesStoreModule,
		ServiceReportsStoreModule,
		ServiceStoreModule,
		TargetsStoreModule,
		UserLogsStoreModule,
		UserRegistrationStoreModule,
		VendorlandStoreModule,
		ViberPromotionsStoreModule,
		WebUsersStoreModule,
		GuestBookStoreModule,
		ProductFiltersStoreModule,
		ProductListStoreModule,
		ExchangeRatesStoreModule,
		ProductDetailsStoreModule,
		CustomerGroupStoreModule,
	],
})
export class RootStoreModule {}
