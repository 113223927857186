<ng-container *ngIf="translate && { inactiveUsersLoading: inactiveUsersLoading$ | async } as observables">
	<div>
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>

		<div class="mt-5">
			<app-skeleton-screen
				*ngIf="observables.inactiveUsersLoading && columns"
				[columns]="columns"
				[translate]="translate"
			></app-skeleton-screen>
		</div>

		<div class="mt-5" *ngIf="!observables.inactiveUsersLoading">
			<p-table
				#inactiveUserTable
				[value]="inactiveUsers$ | async"
				[autoLayout]="true"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="[
					'clientId',
					'customerId',
					'customerName',
					'username',
					'loginDate',
					'orderDate',
					'haveOrder'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="row mb-3">
						<div class="col-lg-9 d-inline-flex">
							<div class="row">
								<div class="center-text form-control-text col-lg-3 col-md-12">{{ translate.SEARCH_PERIOD }}</div>
								<div class="col-lg-6 col-md-12">
									<input class="inactive-users-search" [(ngModel)]="lookupDays" [value]="lookupDays" />
								</div>

								<div class="col-lg-3 col-md-12">
									<button
										type="button"
										class="button"
										(click)="searchInactiveUsersByDays()"
										[disabled]="observables.inactiveUsersLoading"
									>
										<fa-icon icon="search"></fa-icon> {{ translate.SEARCH }}
									</button>
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="global-filter-cont">
								<fa-icon icon="search"></fa-icon>
								<input
									type="text"
									class="form-control"
									pInputText
									size="50"
									[placeholder]="'GLOBAL_FILTER' | translate"
									data-tid="globalFilter"
									(input)="inactiveUserTable.filterGlobal($event.target.value, 'contains')"
								/>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'clientId'" class="text-center" [style.width.px]="100">
							{{ translate.CLIENT_ID }}
							<p-sortIcon [field]="'clientId'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'customerName'">
							{{ translate.CUSTOMER_NAME }}
							<p-sortIcon [field]="'customerName'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'username'">
							{{ translate.USERNAME }}
							<p-sortIcon [field]="'username'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'loginDate'">
							{{ translate.LOGIN_DATE }}
							<p-sortIcon [field]="'loginDate'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'orderDate'">
							{{ translate.ORDER_DATE }}
							<p-sortIcon [field]="'orderDate'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'haveOrder'">
							{{ translate.HAVE_ORDER }}
							<p-sortIcon [field]="'haveOrder'"></p-sortIcon>
						</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-inactiveUser let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ inactiveUser.clientId }}</b>
						</td>
						<td>{{ inactiveUser.customerName }}</td>
						<td>{{ inactiveUser.username }}</td>
						<td>{{ inactiveUser.loginDate | date: 'dd-MM-yyyy' }}</td>
						<td>{{ inactiveUser.orderDate | date: 'dd-MM-yyyy' }}</td>
						<td class="text-center">
							<fa-icon *ngIf="!inactiveUser.haveOrder" icon="check" [style.color]="'green'"></fa-icon>
							<fa-icon *ngIf="inactiveUser.haveOrder" icon="times" [style.color]="'red'"></fa-icon>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</ng-container>
