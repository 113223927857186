import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ErrorModel } from 'src/app/shared/models/error-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
	public errorState$: Observable<ErrorModel>;
	public translate$: Observable<any>;

	constructor(public activatedRoute: ActivatedRoute, private translateService: TranslateService) {}

	ngOnInit(): void {
		this.errorState$ = this.activatedRoute.paramMap.pipe(map(() => window.history.state?.error));

		this.translate$ = this.translateService.get('ERROR');
	}
}
