import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

import { Store } from '@ngrx/store';

import { CustomerRecordState } from '../root-store/customer-records-store/customer-record.reducer';
import * as customerRecordSelectors from '../root-store/customer-records-store/customer-record.selectors';
import * as customerRecordActions from '../root-store/customer-records-store/customer-record.actions';
import { CustomerRecord } from '../shared/models/customer-record';

import { ManufacturerFilteredState } from '../root-store/manufacturers-filtered-store/manufacturer-filtered.reducer';
import * as manufacturerFilteredSelectors from '../root-store/manufacturers-filtered-store/manufacturer-filtered.selectors';
import * as manufacturerFilteredActions from '../root-store/manufacturers-filtered-store/manufacturer-filtered.actions';
import { ManufacturerFiltered } from '../shared/models/manufacturer-filtered';

import { CustomerState } from '../root-store/customers-store/customer.reducer';
import * as customerSelectors from '../root-store/customers-store/customer.selectors';
import * as customerActions from '../root-store/customers-store/customer.actions';
import { Customer } from '../shared/models/customer';

import { ManufacturerState } from '../root-store/manufacturers-store/manufacturer.reducer';
import * as manufacturerSelectors from '../root-store/manufacturers-store/manufacturer.selectors';
import * as manufacturerActions from '../root-store/manufacturers-store/manufacturer.actions';
import { Manufacturer } from '../shared/models/manufacturer';

import { ProductGroupNavState } from '../root-store/product-groups-nav-store/product-groups-nav.reducer';
import * as productGroupNavSelectors from '../root-store/product-groups-nav-store/product-groups-nav.selectors';
import * as productGroupNavActions from '../root-store/product-groups-nav-store/product-groups-nav.actions';
import { ProductGroup } from '../shared/models/product-group';

import { cloneDeep } from 'lodash-es';
import { DatePipe } from '@angular/common';
import { exportAsExcel } from '../shared/utils/export-to-excel';
import { Update } from '@ngrx/entity';
import { CustomerGroupState } from '../root-store/customer-group-store/customer-group.reducer';
import { CustomerGroup } from '../shared/models/customer-group';
import * as customerGroupsActions from '../root-store/customer-group-store/customer-group.actions';
import * as customerGroupsSelectors from '../root-store/customer-group-store/customer-group.selectors';
@Component({
	selector: 'app-user-product-assign',
	templateUrl: './user-product-assign.component.html',
	styleUrls: ['./user-product-assign.component.scss'],
})
export class UserProductAssignComponent implements OnInit, OnDestroy {
	deleteAssignedRecordModal: BsModalRef;
	groupOrManufacturersForm: any;
	groupAndManufacturersForm: any;
	translate: any;
	ngUnsubscribe: Subject<object> = new Subject();

	faAngleDoubleRight = faAngleDoubleRight;
	faAngleDoubleLeft = faAngleDoubleLeft;

	firstPageElementIndex = 0;

	recordAssignOption = 'groups';
	filterGroups: string;
	filterManufacturers: string;
	filterGroupManufacturer: string;
	groupsOrManuftacturersCollapsed = true;
	groupsAndManuftacturersCollapsed = true;
	selectedGroupItems: ProductGroup[] = [];
	selectedManufacturerItems: Manufacturer[] = [];
	selectedGroupAndManufactItems: ManufacturerFiltered[] = [];
	selectedProductGroup: ProductGroup = null;
	manufacturersByGroupAndCustomer: ManufacturerFiltered[] = [];
	currentRecord: any;
	customers: Customer[] = [];
	customerRecords: CustomerRecord[] = [];
	filteredCustomerRecords: CustomerRecord[] = [];
	selectedCustomer: Customer;

	customersLoading$: Observable<boolean>;
	customers$: Observable<Customer[]>;
	customerRecords$: Observable<CustomerRecord[]>;
	customerRecordsLoading$: Observable<boolean>;
	productGroups$: Observable<ProductGroup[]>;
	productGroupsLoading$: Observable<boolean>;
	manufacturers$: Observable<Manufacturer[]>;
	manufacturersLoading$: Observable<boolean>;
	manufacturersByGroupAndCustomerLoading$: Observable<boolean>;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'no', title: 'CUSTOMER_NO', width: 7 },
		{ name: 'name', title: 'NAME', width: 9 },
		{ name: 'groupCode', title: 'GROUP_LABEL', width: 9 },
		{ name: 'manufacturerCode', title: 'PRODUCT_LABEL', width: 8 },
		{ name: 'discount', title: 'PRICE_CORRECTION', width: 8 },
		{ name: 'isBlockedForBrand', title: 'BLOCK_COMBINATION', width: 11 },
		{ name: 'isExclusiveForBrand', title: 'EXCLUSIVE_RIGHTS', width: 9 },
		{ name: 'isAllowedForBrand', title: 'LIMIT_THIS_COMBINATION', width: 11 },
		{ name: 'dateInserted', title: 'DATE_ASSIGNED', width: 7 },
		{ name: 'updatedDemandedBy', title: 'ASSIGNED_BY', width: 9 },
		{ name: '', title: '', width: 6 },
	];
	optionsDropdown: any;
	selectedDropdownOption: any;
	selectedCustomerGroup: any;
	customerGroupDropdown: CustomerGroup[] = [];
	backDiscount: any;
	customersDropdownOption = [];
	selectedCustomerDropdownOption: any;
	customerGroupsLoading$: Observable<boolean>;
	customerGroups$: Observable<CustomerGroup[]>;
	constructor(
		private translateService: TranslateService,
		private customerStore: Store<CustomerState>,
		private customerRecordStore: Store<CustomerRecordState>,
		private productGroupNavStore: Store<ProductGroupNavState>,
		private manufacturerStore: Store<ManufacturerState>,
		private manufacturerFilteredStore: Store<ManufacturerFilteredState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private datePipe: DatePipe,
		private customerGroupStore: Store<CustomerGroupState>
	) {
		this.groupOrManufacturersForm = this.formBuilder.group({
			priceCorrection: 0,
			blockCombination: false,
			exclusiveRights: false,
			limitOnCombination: false,
		});

		this.groupAndManufacturersForm = this.formBuilder.group({
			priceCorrection: 0,
			blockCombination: false,
			exclusiveRights: false,
			limitOnCombination: false,
		});
	}

	productGroups: ProductGroup[] = [];
	manufacturers: Manufacturer[] = [];

	config: any = {
		displayKey: 'name',
		search: true,
		limitTo: 30,
	};

	selectedOption = '';
	selectedGroupsAndManufOption = '';

	ngOnInit() {
		this.translateService.get('PRODUCT_ASSIGN').subscribe((resp: any) => {
			this.translate = resp;
			this.optionsDropdown = [
				{ name: 'selectOption', title: resp.SELECT_OPTION },
				{ name: 'chooseCustomer', title: resp.CHOOSE_CUSTOMER },
				{ name: 'chooseCustomerGroup', title: resp.CHOOSE_CUSTOMER_GROUP },
				// { name: 'backDiscount', title: resp.BACK_DISCOUNT },
			];
			this.selectedDropdownOption = this.optionsDropdown[0];
			this.customersDropdownOption.unshift({
				searchText: '- ' + this.translate.CHOOSE + '-',
				value: null,
				name: '- ' + this.translate.CHOOSE + '-',
			});
		});

		this.customers$ = this.customerStore.select(customerSelectors.selectAllCustomers);
		this.customerRecords$ = this.customerRecordStore.select(customerRecordSelectors.selectAllCustomerRecords);
		this.customersLoading$ = this.customerStore.select(customerSelectors.selectCustomersLoading);
		this.productGroups$ = this.productGroupNavStore.select(productGroupNavSelectors.selectAllProductGroupsNav);
		this.manufacturers$ = this.manufacturerStore.select(manufacturerSelectors.selectAllManufacturers);
		this.customerRecordsLoading$ = this.customerRecordStore.select(
			customerRecordSelectors.selectCustomerRecordsLoading
		);
		this.productGroupsLoading$ = this.productGroupNavStore.select(
			productGroupNavSelectors.selectProductGroupsNavLoading
		);
		this.manufacturersLoading$ = this.manufacturerStore.select(manufacturerSelectors.selectManufacturersLoading);

		this.manufacturers$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			this.manufacturers = cloneDeep(data);
		});
		this.productGroups$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			this.productGroups = cloneDeep(data);
		});
		this.customerRecords$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			this.customerRecords = data;
			this.filterCustomerRecords();
		});

		this.customerRecordStore.dispatch(customerRecordActions.loadCustomerRecords());

		this.customerStore
			.select(customerSelectors.selectAllCustomers)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(customers => {
				if (!customers.length) {
					this.customerStore.dispatch(customerActions.loadCustomers());
				} else {
					this.customersDropdownOption = customers;
				}
			});

		this.productGroupNavStore
			.select(productGroupNavSelectors.selectAllProductGroupsNav)
			.pipe(take(1))
			.subscribe(productGroups => {
				if (!productGroups.length) {
					this.productGroupNavStore.dispatch(productGroupNavActions.loadProductGroupsNav());
				}
			});

		this.manufacturerStore
			.select(manufacturerSelectors.selectAllManufacturers)
			.pipe(take(1))
			.subscribe(manufacturers => {
				if (!manufacturers.length) {
					this.manufacturerStore.dispatch(manufacturerActions.loadManufacturers());
				}
			});
		this.customerGroups$ = this.customerGroupStore.select(customerGroupsSelectors.selectAllCustomerGroups);
		this.customerGroupStore.dispatch(customerGroupsActions.loadCustomerGroups());

		this.customerGroupsLoading$ = this.customerGroupStore.select(customerGroupsSelectors.selectCustomerGroupsLoading);
		this.customerGroupStore
			.select(customerGroupsSelectors.selectAllCustomerGroups)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(customerGroups => {
				if (!customerGroups.length) {
					this.customerGroupStore.dispatch(customerGroupsActions.loadCustomerGroups());
				} else {
					this.customerGroupDropdown = cloneDeep(customerGroups);
				}
			});
	}

	filterCustomerRecords() {
		this.filteredCustomerRecords = [];
		if (this.selectedCustomer?.no && this.selectedCustomer.no !== 'KU000000') {
			for (const record of this.customerRecords) {
				if (record.no === this.selectedCustomer.no) {
					this.filteredCustomerRecords.push(record);
				}
			}
		} else if (this.selectedCustomerGroup) {
			this.filteredCustomerRecords = this.customerRecords.filter(
				x => x.customerGroupId === this.selectedCustomerGroup.value
			);
		} else if (this.backDiscount || this.backDiscount === 0) {
			this.filteredCustomerRecords = this.customerRecords.filter(x => x.backDiscount === this.backDiscount);
		} else {
			this.filteredCustomerRecords = this.customerRecords;
		}
	}

	changeCustomer() {
		this.selectedProductGroup = null;
		this.manufacturersByGroupAndCustomer = [];
		this.unselectAllManufacturersByGroup();
		this.filterCustomerRecords();
		this.firstPageElementIndex = 0;
	}

	changeRadioGroupOrManuf() {
		this.selectedOption = '';
	}

	selectGroup(item: ProductGroup) {
		item.selected = !item.selected;
		if (item.selected) {
			this.selectedGroupItems.push(item);
		} else {
			let indexToRemove: number;
			for (let i = 0; i <= this.selectedGroupItems.length; i++) {
				if (this.selectedGroupItems[i].id === item.id) {
					indexToRemove = i;
					break;
				}
			}
			this.selectedGroupItems.splice(indexToRemove, 1);
		}
	}

	selectAllItems() {
		if (this.recordAssignOption === 'groups') {
			this.selectedGroupItems = [];
			for (const item of this.productGroups) {
				item.selected = true;
				this.selectedGroupItems.push(item);
			}
		} else {
			this.selectedManufacturerItems = [];
			for (const item of this.manufacturers) {
				item.selected = true;
				this.selectedManufacturerItems.push(item);
			}
		}
	}

	removeGroup(item: ProductGroup, index: number) {
		this.selectedGroupItems.splice(index, 1);
		if (this.productGroups.some(group => group.id === item.id && group.code === item.code)) {
			this.productGroups.find(group => group.id === item.id && group.code === item.code).selected = false;
		}
	}

	selectManufacturer(item: Manufacturer) {
		item.selected = !item.selected;
		if (item.selected) {
			this.selectedManufacturerItems.push(item);
		} else {
			let indexToRemove: number;
			for (let i = 0; i <= this.selectedManufacturerItems.length; i++) {
				if (this.selectedManufacturerItems[i].id === item.id) {
					indexToRemove = i;
					break;
				}
			}
			this.selectedManufacturerItems.splice(indexToRemove, 1);
		}
	}

	selectAllManufacturerGroups() {
		for (const item of this.manufacturersByGroupAndCustomer) {
			const exist = this.selectedGroupAndManufactItems.some(
				manufact =>
					manufact.id === item.id &&
					manufact.productGroup === this.selectedProductGroup.code &&
					manufact.customerId === item.customerId
			);
			if (!exist) {
				item.selected = true;
				this.selectedGroupAndManufactItems.push(item);
			}
		}
	}

	unselectAllItems() {
		this.selectedManufacturerItems = [];
		this.selectedGroupItems = [];
		for (const group of this.productGroups) {
			group.selected = false;
		}
		for (const manufact of this.manufacturers) {
			manufact.selected = false;
		}
	}

	removeManufacturer(item: Manufacturer, index: number) {
		this.selectedManufacturerItems.splice(index, 1);
		if (this.manufacturers.some(manufact => manufact.id === item.id && manufact.name === item.name)) {
			this.manufacturers.find(manufact => manufact.id === item.id && manufact.name === item.name).selected = false;
		}
	}

	selectGroupManufacturer(item: Manufacturer) {
		item.selected = !item.selected;
		if (item.selected) {
			const itemTemp: ManufacturerFiltered = {
				id: item.id,
				name: item.name,
				manufacturerId: item.id,
				manufacturerName: item.name,
				customerId: this.selectedCustomer.no,
				productGroup: this.selectedProductGroup.code,
				selected: item.selected,
			};
			this.selectedGroupAndManufactItems.push(itemTemp);
		} else {
			let indexToRemove: number;
			for (let i = 0; i <= this.selectedGroupAndManufactItems.length; i++) {
				if (
					this.selectedGroupAndManufactItems[i].id === item.id &&
					this.selectedGroupAndManufactItems[i].productGroup === this.selectedProductGroup.code
				) {
					indexToRemove = i;
					break;
				}
			}
			this.selectedGroupAndManufactItems.splice(indexToRemove, 1);
		}
	}

	removeGroupManufacturer(item: ManufacturerFiltered, index: number) {
		this.selectedGroupAndManufactItems.splice(index, 1);
		const tempCustomer = this.manufacturersByGroupAndCustomer.find(
			manuf => manuf.id === item.id && manuf.name === item.name && manuf.productGroup === item.productGroup
		);
		if (tempCustomer) {
			tempCustomer.selected = false;
		}
	}

	selectManufacturersByGroup() {
		if (this.selectedProductGroup && this.selectedCustomer) {
			this.manufacturersByGroupAndCustomerLoading$ = this.manufacturerFilteredStore.select(
				manufacturerFilteredSelectors.selectManufacturersLoading
			);

			this.manufacturerFilteredStore
				.select(manufacturerFilteredSelectors.selectManufacturersFilteredOnAction, {
					productGroupId: this.selectedProductGroup.code,
					customerId: this.selectedCustomer.no,
				})
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(data => {
					if (data.length) {
						this.manufacturersByGroupAndCustomer = cloneDeep(data);
						for (const item of this.selectedGroupAndManufactItems) {
							if (
								this.manufacturersByGroupAndCustomer.some(
									manuf => manuf.id === item.id && manuf.name === item.name && manuf.productGroup === item.productGroup
								)
							) {
								this.manufacturersByGroupAndCustomer.find(
									manuf => manuf.id === item.id && manuf.name === item.name && manuf.productGroup === item.productGroup
								).selected = true;
							}
						}
					} else {
						this.manufacturersByGroupAndCustomer = [];
					}
				});

			this.manufacturerFilteredStore
				.select(manufacturerFilteredSelectors.selectManufacturersFilteredOnAction, {
					productGroupId: this.selectedProductGroup.code,
					customerId: this.selectedCustomer.no,
				})
				.pipe(take(1))
				.subscribe(data => {
					if (!data.length) {
						this.manufacturerFilteredStore.dispatch(
							manufacturerFilteredActions.loadManufacturersByCustomerAndGroup({
								productGroupId: this.selectedProductGroup.code,
								customerId: this.selectedCustomer.no,
							})
						);
					}
				});
		}
	}

	unselectAllManufacturersByGroup() {
		this.selectedGroupAndManufactItems = [];
		for (const manufact of this.manufacturersByGroupAndCustomer) {
			manufact.selected = false;
		}
	}

	selectManufacturersByCustomerAndGroup() {
		this.manufacturersByGroupAndCustomer = [];
	}

	submitGroupOrManufacturerForm() {
		if (this.selectedGroupItems.length) {
			for (const item of this.selectedGroupItems) {
				const updateArray = this.filteredCustomerRecords.filter(
					x => x.no === this.selectedCustomer.no && x.manufacturerCode === null && x.groupCode === item.code
				);
				const name = this.selectedCustomer
					? this.selectedCustomer.name
					: this.selectedCustomerGroup
					? this.selectedCustomerGroup.label
					: '';
				const tempRecord: CustomerRecord = {
					id: 0,
					no: this.selectedCustomer ? this.selectedCustomer.no : '',
					name,
					customerGroupId: this.selectedCustomerGroup ? this.selectedCustomerGroup.value : '',
					backDiscount: this.backDiscount ? this.backDiscount : null,
					discount: Number(this.groupOrManufacturersForm.value.priceCorrection) * -1,
					groupCode: item.code,
					manufacturerCode: null,
					isBlockedForBrand: this.selectedOption === 'blockCombination',
					isExclusiveForBrand: this.selectedOption === 'exclusiveRights',
					isAllowedForBrand: this.selectedOption === 'limitOnCombination',
					dateInserted: '',
					updatedDemandedBy: '',
					dateUpdated: '',
				};
				if (!updateArray.length) {
					this.customerRecordStore.dispatch(customerRecordActions.addCustomerRecord({ customerRecord: tempRecord }));
				} else {
					for (const element of updateArray) {
						const updateObject: Update<CustomerRecord> = {
							id: element.id,
							changes: {
								...element,
								dateUpdated: this.datePipe.transform(new Date(), 'yyyy-MM-dd H:mm:ss'),
								discount: tempRecord.discount,
								isAllowedForBrand: tempRecord.isAllowedForBrand,
								isBlockedForBrand: tempRecord.isBlockedForBrand,
								isExclusiveForBrand: tempRecord.isExclusiveForBrand,
							},
						};
						this.customerRecordStore.dispatch(
							customerRecordActions.updateCustomerRecord({ customerRecord: updateObject })
						);
					}
				}
				this.productGroups.find(group => group.id === item.id && group.code === item.code).selected = false;
				this.firstPageElementIndex = 0;
			}
			this.selectedGroupItems = [];
		}
		if (this.selectedManufacturerItems.length) {
			for (const item of this.selectedManufacturerItems) {
				const updateArray = this.filteredCustomerRecords.filter(
					x => x.no === this.selectedCustomer.no && x.manufacturerCode === '' + item.id && x.groupCode === null
				);
				const name = this.selectedCustomer
					? this.selectedCustomer.name
					: this.selectedCustomerGroup
					? this.selectedCustomerGroup.label
					: '';
				const tempRecord: CustomerRecord = {
					id: 0,
					no: this.selectedCustomer ? this.selectedCustomer.no : '',
					name,
					customerGroupId: this.selectedCustomerGroup ? this.selectedCustomerGroup.value : '',
					backDiscount: this.backDiscount ? this.backDiscount : null,
					discount: Number(this.groupOrManufacturersForm.value.priceCorrection) * -1,
					groupCode: null,
					manufacturerCode: '' + item.id,
					isBlockedForBrand: this.selectedOption === 'blockCombination',
					isExclusiveForBrand: this.selectedOption === 'exclusiveRights',
					isAllowedForBrand: this.selectedOption === 'limitOnCombination',
					dateInserted: '',
					updatedDemandedBy: '',
					dateUpdated: '',
				};
				if (!updateArray.length) {
					this.customerRecordStore.dispatch(customerRecordActions.addCustomerRecord({ customerRecord: tempRecord }));
				} else {
					for (const element of updateArray) {
						const updateObject: Update<CustomerRecord> = {
							id: element.id,
							changes: {
								...element,
								dateUpdated: this.datePipe.transform(new Date(), 'yyyy-MM-dd H:mm:ss'),
								discount: tempRecord.discount,
								isAllowedForBrand: tempRecord.isAllowedForBrand,
								isBlockedForBrand: tempRecord.isBlockedForBrand,
								isExclusiveForBrand: tempRecord.isExclusiveForBrand,
							},
						};
						this.customerRecordStore.dispatch(
							customerRecordActions.updateCustomerRecord({ customerRecord: updateObject })
						);
					}
				}
				this.manufacturers.find(manufact => manufact.id === item.id && manufact.name === item.name).selected = false;

				this.firstPageElementIndex = 0;
			}
			this.selectedManufacturerItems = [];
		}
		this.groupOrManufacturersForm = this.formBuilder.group({
			priceCorrection: 0,
			blockCombination: false,
			exclusiveRights: false,
			limitOnCombination: false,
		});
	}

	submitGroupAndManufacturerForm() {
		if (this.selectedGroupAndManufactItems.length) {
			for (const item of this.selectedGroupAndManufactItems) {
				const updateArray = this.filteredCustomerRecords.filter(
					x =>
						x.no === this.selectedCustomer.no &&
						x.manufacturerCode === '' + item.manufacturerId &&
						x.groupCode === item.productGroup
				);
				const name = this.selectedCustomer
					? this.selectedCustomer.name
					: this.selectedCustomerGroup
					? this.selectedCustomerGroup.label
					: '';
				const tempRecord: CustomerRecord = {
					id: 0,
					no: this.selectedCustomer ? this.selectedCustomer.no : '',
					name,
					customerGroupId: this.selectedCustomerGroup ? this.selectedCustomerGroup.value : '',
					backDiscount: this.backDiscount ? this.backDiscount : null,
					discount: Number(this.groupAndManufacturersForm.value.priceCorrection) * -1,
					groupCode: item.productGroup,
					manufacturerCode: '' + item.id,
					isBlockedForBrand: this.selectedGroupsAndManufOption === 'blockCombination',
					isExclusiveForBrand: this.selectedGroupsAndManufOption === 'exclusiveRights',
					isAllowedForBrand: this.selectedGroupsAndManufOption === 'limitOnCombination',
					dateInserted: '',
					updatedDemandedBy: '',
					dateUpdated: '',
				};
				if (!updateArray.length) {
					this.customerRecordStore.dispatch(customerRecordActions.addCustomerRecord({ customerRecord: tempRecord }));
				} else {
					for (const element of updateArray) {
						const updateObject: Update<CustomerRecord> = {
							id: element.id,
							changes: {
								...element,
								dateUpdated: this.datePipe.transform(new Date(), 'yyyy-MM-dd H:mm:ss'),
								discount: tempRecord.discount,
								isAllowedForBrand: tempRecord.isAllowedForBrand,
								isBlockedForBrand: tempRecord.isBlockedForBrand,
								isExclusiveForBrand: tempRecord.isExclusiveForBrand,
							},
						};
						this.customerRecordStore.dispatch(
							customerRecordActions.updateCustomerRecord({ customerRecord: updateObject })
						);
					}
				}

				const tempCustomer = this.manufacturersByGroupAndCustomer.find(
					manuf => manuf.id === item.id && manuf.name === item.name && manuf.productGroup === item.productGroup
				);
				if (tempCustomer) {
					tempCustomer.selected = false;
				}

				this.firstPageElementIndex = 0;
			}
			this.selectedGroupAndManufactItems = [];
		}
		this.groupAndManufacturersForm = this.formBuilder.group({
			priceCorrection: 0,
			blockCombination: false,
			exclusiveRights: false,
			limitOnCombination: false,
		});
	}

	openDeleteRecordModal(deleteAssignedRecordTemplate: TemplateRef<any>, record) {
		this.currentRecord = record;
		this.deleteAssignedRecordModal = this.modalService.show(deleteAssignedRecordTemplate, { class: 'modal-md' });
	}

	deleteAssignedRecord(event: any) {
		(event.target as HTMLButtonElement).disabled = true;
		this.customerRecordStore.dispatch(
			customerRecordActions.deleteCustomerRecord({ customerRecordId: this.currentRecord.id })
		);
		this.deleteAssignedRecordModal.hide();
	}

	changeOptionsDropdown() {
		this.selectedCustomer = null;
		this.backDiscount = null;
		this.selectedCustomerGroup = null;
		this.filteredCustomerRecords = this.customerRecords;

		this.selectedProductGroup = null;
		this.manufacturersByGroupAndCustomer = [];
		this.unselectAllManufacturersByGroup();
		this.firstPageElementIndex = 0;
		switch (this.selectedDropdownOption.name) {
			case 'chooseCustomer': {
				this.filteredCustomerRecords = this.customerRecords.filter(x => x.no);
				break;
			}
			case 'chooseCustomerGroup': {
				this.filteredCustomerRecords = this.customerRecords.filter(x => x.customerGroupId);
				break;
			}
			case 'backDiscount': {
				this.filteredCustomerRecords = this.customerRecords.filter(x => x.backDiscount);
				break;
			}
			default: {
				this.filteredCustomerRecords = this.customerRecords;
				break;
			}
		}
	}

	downloadExcel() {
		const data = [];
		const sortedData = this.filteredCustomerRecords.sort((a, b) => 0 - (a.id > b.id ? 1 : -1));
		for (const element of sortedData) {
			data.push({
				[this.translate.CUSTOMER_NO]: element.no,
				[this.translate.NAME]: element.customerGroupId ? '' : element.name,
				[this.translate.CUSTOMER_GROUP]: element.customerGroupId
					? this.customerGroupDropdown.find(x => x.value === element.customerGroupId).name
					: '',
				[this.translate.BACK_DISCOUNT]: element.backDiscount,
				[this.translate.GROUP_LABEL]: element.groupCode,
				[this.translate.PRODUCT_LABEL]: element.manufacturerCode,
				[this.translate.PRICE_CORRECTION]: element.discount,
				[this.translate.BLOCK_COMBINATION]: element.isBlockedForBrand ? '✔' : '',
				[this.translate.EXCLUSIVE_RIGHTS]: element.isExclusiveForBrand ? '✔' : '',
				[this.translate.DATE_ASSIGNED]: this.datePipe.transform(element.dateInserted, 'yyyy-MM-dd'),
				[this.translate.ASSIGNED_BY]: element.updatedDemandedBy,
			});
		}
		exportAsExcel(data, this.translate.ASSIGNED_PRODUCTS_OR_GROUPS, this.translate.ASSIGNED_PRODUCTS_OR_GROUPS);
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
