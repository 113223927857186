import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PriceList } from '../../shared/models/price-list';
import * as PriceListActions from './price-list.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const priceListFeatureKey = 'priceList';

export interface PriceListState extends EntityState<PriceList> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<PriceList> = createEntityAdapter<PriceList>();

export const initialState: PriceListState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const PriceListReducer = createReducer(
	initialState,
	on(PriceListActions.loadPriceList, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PriceListActions.loadPriceListSuccess, (state, action) =>
		adapter.setAll(action.PriceList, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PriceListActions.loadPriceListFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(PriceListActions.addPriceList, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PriceListActions.addPriceListSuccess, (state, action) =>
		adapter.addOne(action.PriceList, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PriceListActions.addPriceListFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(PriceListActions.updatePriceList, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PriceListActions.updatePriceListSuccess, (state, action) =>
		adapter.updateOne(action.PriceList, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PriceListActions.updatePriceListFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: PriceListState | undefined, action: Action) {
	return PriceListReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
