import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/sr/environment';
import { LanguageService } from '../services/language.service';
import { AuthService } from '../authentication/auth.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	faSignOutAlt = faSignOutAlt;
	environment = environment;
	translate: any;
	selectedLanguage: string;
	languages: string[];
	router: any;
	showNavbar = false;

	constructor(
		public authenticationService: AuthenticationService,
		private languageService: LanguageService,
		public translateService: TranslateService,
		public authService: AuthService
	) {}

	ngOnInit(): void {
		// declare token invalid if portal-front token found in localStorage
		if (
			localStorage.getItem('access_token') ||
			(this.authService.userRoles && !this.authService.userRoles.includes('AdminAccess'))
		) {
			setTimeout(() => {
				this.authService.logout();
				sessionStorage.setItem('emptyLocalStorage', '1');
				this.authService.navigateToLoginPage();
				return;
			}, 100);
		}
		this.selectedLanguage = this.languageService.currentLanguageValue
			? this.languageService.currentLanguageValue
			: environment.defaultLanguage;
		this.languages = this.translateService.getLangs();

		this.translateService.get('HEADER').subscribe((resp: any) => (this.translate = resp));
	}

	changeNav() {
		this.showNavbar = !this.showNavbar;
	}

	changeLanguage(language: string) {
		if (language !== this.selectedLanguage) {
			this.selectedLanguage = language;
			this.languageService.setLanguage(this.selectedLanguage);
			this.translateService.use(this.selectedLanguage);
			location.reload();
		}
	}

	stopImpersonate() {
		this.authService.stopImpersonate();
	}
}
