import { productDataFeatureKey, ProductDataState } from './product-data.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductDataState = createFeatureSelector<ProductDataState>(productDataFeatureKey);

export const selectFilteredProductData = createSelector(
	selectProductDataState,
	(state: ProductDataState, params: any) => {
		if (params.searchString.includes(state.searchItem)) {
			let productCommonIds: string[] = [];

			if (state.productData) {
				productCommonIds = state.productData
					.filter(product => product.commonItemId.toLowerCase().includes(params.searchString))
					.map(product => product.commonItemId);
			} else {
				return null;
			}

			return productCommonIds;
		} else {
			return null;
		}
	}
);

export const selectProductDataLoading = createSelector(
	selectProductDataState,
	state => state.statusState === LoadingState.LOADING
);

export const selectStoredProductId = createSelector(selectProductDataState, state => state.productId);

export const selectProductDataError = createSelector(selectProductDataState, (state: ProductDataState) =>
	getError(state.statusState)
);
