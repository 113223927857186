import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Seller } from '../../shared/models/seller';
import * as SellerActions from './seller.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const sellersFeatureKey = 'sellers';

export interface SellerState extends EntityState<Seller> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Seller> = createEntityAdapter<Seller>();

export const initialState: SellerState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const sellerReducer = createReducer(
	initialState,
	on(SellerActions.loadSellers, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(SellerActions.loadSellersSuccess, (state, action) =>
		adapter.setAll(action.sellers, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(SellerActions.loadSellersFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(SellerActions.addSeller, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(SellerActions.addSellerSuccess, (state, action) =>
		adapter.addOne(action.seller, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(SellerActions.addSellerFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(SellerActions.updateSeller, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(SellerActions.updateSellerSuccess, (state, action) =>
		adapter.updateOne(action.seller, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(SellerActions.updateSellerFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: SellerState | undefined, action: Action) {
	return sellerReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
