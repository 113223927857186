import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GroupConnection } from '../../shared/models/group-connection';
import * as groupConnectionActions from './group-connections.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const groupConnectionsFeatureKey = 'groupConnections';

export interface GroupConnectionState extends EntityState<GroupConnection> {
	// additional entities state properties
	statusState: StatusState;
	selectedProductGroupConnectionStatusState: StatusState;
}

export const adapter: EntityAdapter<GroupConnection> = createEntityAdapter<GroupConnection>({
	selectId: groupConnection => groupConnection.id,
});
export const initialState: GroupConnectionState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	selectedProductGroupConnectionStatusState: LoadingState.INIT,
});

export const groupConnectionReducer = createReducer(
	initialState,
	on(groupConnectionActions.loadGroupConnections, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(groupConnectionActions.loadGroupConnectionsSuccess, (state, action) =>
		adapter.setAll(action.groupConnections, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(groupConnectionActions.loadGroupConnectionsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(groupConnectionActions.addGroupConnection, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(groupConnectionActions.addGroupConnectionSuccess, (state, action) =>
		adapter.addOne(action.groupConnection, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(groupConnectionActions.addGroupConnectionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(groupConnectionActions.updateGroupConnection, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(groupConnectionActions.updateGroupConnectionSuccess, (state, action) =>
		adapter.updateOne(action.groupConnection, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(groupConnectionActions.updateGroupConnectionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(groupConnectionActions.removeGroupConnection, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(groupConnectionActions.removeGroupConnectionSuccess, (state, action) =>
		adapter.removeOne(action.id, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(groupConnectionActions.removeGroupConnectionFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: GroupConnectionState | undefined, action: Action) {
	return groupConnectionReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
