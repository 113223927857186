import { Component, OnInit, TemplateRef } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { PriceList } from '../shared/models/price-list';
import { Observable, Subscription } from 'rxjs';
import * as priceListSelectors from '../root-store/price-list-store/price-list.selector';
import * as priceListActions from '../root-store/price-list-store/price-list.actions';
import { PriceListState } from '../root-store/price-list-store/price-list.reducer';
import { Update } from '@ngrx/entity';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { environment } from 'src/environments/sr/environment';

@Component({
	selector: 'app-pricelists',
	templateUrl: './pricelists.component.html',
	styleUrls: ['./pricelists.component.scss'],
})
export class PricelistsComponent implements OnInit {
	updatePriceListModal: BsModalRef;
	deactivatePriceListModal: BsModalRef;
	priceListForm: any;

	firstPageElementIndex = 0;

	translate: any;
	translateFormValidationErrorMsgs: any;

	selectedPriceList: PriceList;
	priceList$: Observable<PriceList[]>;
	priceList: any;
	priceListLoading$: Observable<boolean>;
	priceListSubscriber: Subscription;

	fileUploaded: boolean = null;
	fileName: string = null;
	fileString: string = null;

	environment = environment;
	priceListFileDownloadPrefix = environment.mediaLocation.priceListFiles;
	priceListFileNameLengthLimit = environment.mediaRestrictions.price_list.file_name_limit;
	priceListSizeLimit = environment.mediaRestrictions.price_list.file_size;

	submitted: boolean;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'name', title: 'NAME', width: 30 },
		{ name: 'file', title: 'FILE', width: 30 },
		{ name: 'description', title: 'DESCRIPTION', width: 25 },
		{ name: 'date', title: 'DATE', width: 15 },
		{ name: 'active', title: 'ACTIVE', width: 25 },
		{ name: '', title: '', width: 10 },
	];

	constructor(
		private translateService: TranslateService,
		private store: Store<PriceListState>,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private priceListStore: Store<PriceListState>,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.translateService.get('PRICELISTS').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
		this.priceList$ = this.store.select(priceListSelectors.selectAllPriceList);
		this.priceListLoading$ = this.store.select(priceListSelectors.selectPriceListLoading);

		this.store.dispatch(priceListActions.loadPriceList());
	}

	openPriceListModal(updatePriceListTemplate: TemplateRef<any>, priceList: PriceList) {
		this.fileUploaded = false;
		this.submitted = false;
		this.priceListForm = this.formBuilder.group(
			priceList
				? {
						...priceList,
						name: [priceList.name, [Validators.required, Validators.maxLength(255)]],
				  }
				: {
						...new PriceList(),
						name: ['', [Validators.required, Validators.maxLength(255)]],
						fileString: ['', [Validators.required]],
				  }
		);
		this.fileName = null;
		this.fileString = null;
		this.updatePriceListModal = this.modalService.show(updatePriceListTemplate, {
			class: 'modal-md',
			ignoreBackdropClick: true,
		});
	}

	openConfirmationModal(deactivatePriceListTemplate: TemplateRef<any>, priceList: PriceList) {
		this.selectedPriceList = { ...priceList };
		this.deactivatePriceListModal = this.modalService.show(deactivatePriceListTemplate, { class: 'modal-md' });
	}

	deactivateSelectedPriceList() {
		this.selectedPriceList.active = !this.selectedPriceList.active;
		const tempPriceList: Update<PriceList> = {
			id: this.selectedPriceList.id,
			changes: this.selectedPriceList,
		};
		this.store.dispatch(priceListActions.updatePriceList({ PriceList: tempPriceList }));
		this.deactivatePriceListModal.hide();
	}

	submitPriceListForm() {
		this.submitted = true;
		if (this.priceListForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		if (this.priceListForm.value.id) {
			const tempPriceList: Update<PriceList> = {
				id: this.priceListForm.value.id,
				changes: this.priceListForm.value,
			};
			if (this.fileName && this.fileString) {
				tempPriceList.changes.fileString = this.fileString;
				tempPriceList.changes.file = this.fileName;
			}
			this.priceListStore.dispatch(priceListActions.updatePriceList({ PriceList: tempPriceList }));
		} else {
			if (this.fileName && this.fileString) {
				this.priceListForm.value.fileString = this.fileString;
				this.priceListForm.value.file = this.fileName;
			}
			this.priceListStore.dispatch(priceListActions.addPriceList({ PriceList: this.priceListForm.value }));

			this.firstPageElementIndex = 0;
		}
		this.updatePriceListModal.hide();
	}

	onFileChange(event) {
		this.fileUploaded = false;
		this.fileName = null;
		this.fileString = null;
		if (event.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = () => {
				const file = event.target.files[0];
				this.fileString = fileToBase64(reader);
				if (file.size <= this.priceListSizeLimit) {
					this.fileName = file.name;
					if (this.fileName.length > this.priceListFileNameLengthLimit) {
						this.fileName =
							this.fileName.substr(0, 10) +
							'...' +
							this.fileName.substr(this.fileName.length - 10, this.fileName.length);
					}
					this.fileUploaded = true;
				} else if (file.size > this.priceListSizeLimit) {
					this.fileUploaded = null;
					this.toastr.warning(this.translate.UPLOAD_LARGE_FILE);
				} else {
					this.fileUploaded = null;
					this.toastr.warning(this.translate.UPLOAD_WRONG_FORMAT);
				}
			};
		}
	}
}
