import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './authentication/authentication.service';

import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './authentication/auth.service';

import { LanguageService } from './services/language.service';
import { ProductAdditionalTypesService } from './services/product-additional-types.service';
import { ProductImageService } from './services/product-images.service';
import { ProductExtraService } from './services/product-extra.service';
import { ProductDataService } from './services/product-data.service';
import { SellerService } from './services/seller.service';
import { PromotionService } from './services/promotion.service';
import { AdministratorService } from './services/administrator.service';
import { WebUserService } from './services/web-user.service';
import { AdditionalSpecificationService } from './services/additional-specification.service';
import { PromotionSliderService } from './services/promotion-slider.service';
import { GroupService } from './services/group.service';
import { FeatureService } from './services/feature.service';
import { VendorlandService } from './services/vendorland.service';
import { authConfig } from './authentication/auth-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './authentication/auth-interceptor.service';
import { AllowedQuantityService } from './services/allowed-quantity.service';
import { ProductGroupService } from './services/product-group.service';
import { ServiceReportsService } from './services/service-reports.service';
import { PriceListService } from './services/price-list.service';
import { CustomerService } from './services/customer.service';
import { CustomerRecordsService } from './services/customer-records.service';
import { ManufacturerService } from './services/manufacturer.service';
import { ErrorInterceptor } from './interceptors/error-interceptor.service';
import { RetryInterceptor } from './interceptors/retry-interceptor.service';
import { ErrorComponent } from './error/error.component';
import { LoggingService } from './services/logging.service';
import { GlobalErrorHandler } from './error/global-error-handler';
import { UserRegistrationService } from './services/user-registration.service';
import { UserLogService } from './services/user-log.service';
import { MenuItemService } from './services/menu-item.service';
import { TargetService } from './services/target.service';
import { HomePageService } from './services/home-page.service';
import { StorageService } from './services/storage.service';
import { InactiveUsersService } from './services/inactive-users.service';
import { SpecialPriceService } from './services/special-price.service';

export function storageFactory(): OAuthStorage {
	return new StorageService('admin');
}

@NgModule({
	declarations: [HeaderComponent, LoginComponent, NavbarComponent, ErrorComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule, OAuthModule.forRoot()],
	exports: [HeaderComponent, NavbarComponent],
	providers: [
		AuthGuard,
		AuthenticationService,
		LanguageService,
		ProductAdditionalTypesService,
		ProductImageService,
		ProductExtraService,
		ProductDataService,
		SellerService,
		TargetService,
		PromotionService,
		AdministratorService,
		WebUserService,
		AdditionalSpecificationService,
		PromotionSliderService,
		GroupService,
		FeatureService,
		AllowedQuantityService,
		VendorlandService,
		ProductGroupService,
		ServiceReportsService,
		SpecialPriceService,
		PriceListService,
		CustomerService,
		CustomerRecordsService,
		ManufacturerService,
		AuthService,
		LoggingService,
		UserRegistrationService,
		UserLogService,
		MenuItemService,
		HomePageService,
		InactiveUsersService,
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{ provide: AuthConfig, useValue: authConfig },
				// { provide: OAuthModuleConfig, useValue: authModuleConfig },
				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ErrorInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: RetryInterceptor,
					multi: true,
				},
				{ provide: ErrorHandler, useClass: GlobalErrorHandler },
				{ provide: OAuthStorage, useFactory: storageFactory },
			],
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
		}
	}
}
