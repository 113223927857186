import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MenuItemDB } from '../../shared/models/menu-item';
import * as MenuActions from './menu.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const menuFeatureKey = 'Menu';

export interface MenuState extends EntityState<MenuItemDB> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<MenuItemDB> = createEntityAdapter<MenuItemDB>();

export const initialState: MenuState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const MenuReducer = createReducer(
	initialState,
	on(MenuActions.loadDeactivatedMenu, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(MenuActions.loadDeactivatedMenuSuccess, (state, action) =>
		adapter.setAll(action.Menu, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(MenuActions.loadDeactivatedMenuFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: MenuState | undefined, action: Action) {
	return MenuReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
