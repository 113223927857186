import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Group } from 'src/app/shared/models/group';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class GroupService {
	constructor(private http: HttpClient) {}

	getGroups(): Observable<Group[]> {
		return this.http.get<Group[]>(environment.baseApiUrl + 'api/PortalGroup');
	}

	addGroup(group: Group): Observable<Group> {
		return this.http.post<Group>(environment.baseApiUrl + 'api/PortalGroup', group);
	}

	updateGroup(group: Update<Group>): Observable<Update<Group>> {
		return this.http
			.put<Update<Group>>(environment.baseApiUrl + 'api/PortalGroup', group.changes)
			.pipe(map(() => group));
	}
}
