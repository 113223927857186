<div
	class="service-reports-cont"
	*ngIf="
		translate && {
			serviceReportsLoading: serviceReportsLoading$ | async,
			serviceReplies: serviceReplies$ | async,
			serviceRepliesLoading: serviceRepliesLoading$ | async
		} as observables
	"
>
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>

	<app-skeleton-screen
		*ngIf="observables.serviceReportsLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<div *ngIf="!observables.serviceReportsLoading" class="service-reports-table">
		<p-table
			#serviceReportsTable
			[value]="filteredServiceReports"
			[autoLayout]="true"
			class="service-reports-prime-table"
			[paginator]="true"
			[rows]="10"
			[(first)]="currentPageFirstElementNumber"
			[rowsPerPageOptions]="[5, 10, 20, 50]"
			[globalFilterFields]="['id', 'caseNumber', 'subject', 'contactPerson', 'date', 'status']"
		>
			<ng-template pTemplate="caption">
				<div class="reports-filter pt-1">
					<label *ngIf="perms['Service.ReportsOpen']" class="chbx-container mr-3" [class.checked]="openReports">
						{{ translate.OPEN_REPORTS }}
						<input
							type="checkbox"
							[(ngModel)]="openReports"
							name="open"
							(change)="filterServiceReports('open')"
							[ngClass]="{ checked: openReports }"
							data-tid="openReports"
						/>
						<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
					</label>

					<label *ngIf="perms['Service.ReportsQueue']" class="chbx-container mr-3" [class.checked]="pendingReports">
						{{ translate.PENDING_REPORTS }}
						<input
							type="checkbox"
							[(ngModel)]="pendingReports"
							name="pending"
							(change)="filterServiceReports('pending')"
							[ngClass]="{ checked: pendingReports }"
							data-tid="pendingReports"
						/>
						<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
					</label>

					<label *ngIf="perms['Service.ReportsClosed']" class="chbx-container mr-3" [class.checked]="closedReports">
						{{ translate.CLOSED_REPORTS }}
						<input
							type="checkbox"
							[(ngModel)]="closedReports"
							name="closed"
							(change)="filterServiceReports('closed')"
							[ngClass]="{ checked: closedReports }"
							data-tid="closedReports"
						/>
						<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
					</label>
				</div>
				<div class="global-filter-cont">
					<fa-icon icon="search"></fa-icon>
					<input
						type="text"
						class="form-control"
						pInputText
						size="50"
						[placeholder]="'GLOBAL_FILTER' | translate"
						data-tid="globalFilter"
						(input)="serviceReportsTable.filterGlobal($event.target.value, 'contains')"
					/>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="60">
						{{ translate.ID }}
						<p-sortIcon [field]="'id'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'caseNumber'">
						{{ translate.CASE_NUMBER }}
						<p-sortIcon [field]="'caseNumber'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'subject'">
						{{ translate.SHORT_DESC }}
						<p-sortIcon [field]="'subject'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'contactPerson'">
						{{ translate.CONTACT_PERSON }}
						<p-sortIcon [field]="'contactPerson'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'date'">
						{{ translate.REPORT_DATE }}
						<p-sortIcon [field]="'date'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'status'">
						{{ translate.STATUS }}
						<p-sortIcon [field]="'status'"></p-sortIcon>
					</th>
					<th>&nbsp;</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-report let-i="rowIndex">
				<tr>
					<td class="text-center">
						<b>{{ report.id }}</b>
					</td>
					<td>{{ report.caseNumber }}</td>
					<td>{{ report.subject }}</td>
					<td>{{ report.contactPerson }}</td>
					<td>{{ report.date | date: 'dd.MM.yyyy' }}</td>
					<td class="status-cont nowrap">
						{{
							report.status === enumStatuses.OPEN
								? translate.IS_OPEN
								: report.status === enumStatuses.PENDING
								? translate.IS_PENDING
								: translate.IS_CLOSED
						}}
						<ng-select
							class="ml-2"
							[searchable]="false"
							(change)="openConfirmStatusModal(confirmStatusChangeTemplate, report)"
							[(ngModel)]="selectedStatus"
							[title]="translate.CHANGE_STATUS"
						>
							<ng-option
								*ngFor="let st of statuses | filterByProperty: { status: report.status }:'status'"
								[value]="st.status"
							>
								{{ translate[st.title] }}
							</ng-option>
						</ng-select>
					</td>
					<td class="text-right" [style.white-space]="'nowrap'">
						<button
							type="button"
							class="button button-gray mr-2"
							(click)="openServiceRepliesModal(viewServiceRepliesTemplate, report)"
						>
							{{ translate.VIEW_REPLIES }}
						</button>
						<button
							type="button"
							class="button button-gray"
							(click)="openReportModal(viewServiceReportTemplate, report)"
						>
							{{ 'SHOW' | translate }}
						</button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td [attr.colspan]="columns.length" class="empty-msg">
						{{ 'NO_DATA' | translate }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

	<!-- View Service Report modal -->
	<ng-template #viewServiceRepliesTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{ translate.VIEW_SERVICE_REPLIES }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="viewServiceRepliesModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<button
				type="button"
				class="button mb-3"
				(click)="showAddReplyCont = true; serviceReplyText = ''"
				*ngIf="!showAddReplyCont"
			>
				<fa-icon [icon]="faReply"></fa-icon> {{ translate.ADD_REPLY }}
			</button>
			<div class="row mb-3" *ngIf="showAddReplyCont">
				<div class="col-2 form-control-text">{{ translate.REPLY }}:</div>
				<div class="col-10"><textarea type="text" class="form-control" [(ngModel)]="serviceReplyText"></textarea></div>
				<div class="col-12 text-right mt-2 modal-footer modal-footer-remove">
					<div class="modal-footer-buttons">
						<button type="button" class="button button-gray" (click)="showAddReplyCont = false">
							{{ 'CANCEL' | translate }}
						</button>
						<button
							type="button"
							class="button"
							(click)="submitNewReply()"
							[disabled]="!serviceReplyText || observables.serviceReportsLoading"
						>
							{{ 'SUBMIT' | translate }}
						</button>
					</div>
				</div>
			</div>
			<div *ngIf="!observables.serviceRepliesLoading">
				<div class="reply-card" *ngFor="let reply of observables.serviceReplies">
					<div class="reply-info">
						{{ translate.POSTED_BY }} <span>{{ reply.author }}</span> {{ translate.ON }}
						<span>{{ reply.date | date: 'dd.MM.yyyy' }}</span> {{ translate.IN }}
						<span>{{ reply.date | date: 'HH:mm' }}</span>
					</div>
					<div class="reply-text">{{ reply.text }}</div>
				</div>
				<div *ngIf="!observables.serviceReplies.length">
					<div class="reply-text">{{ translate.NO_REPLIES }}.</div>
				</div>
			</div>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="viewServiceRepliesModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>

	<!-- View Service Report modal -->
	<ng-template #viewServiceReportTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{ translate.VIEW_REPORT_DETAILS }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="viewServiceReportModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.CASE_NUMBER }}:</div>
				<div class="col-8">{{ selectedServiceReport.caseNumber }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.SHORT_DESC }}:</div>
				<div class="col-8">{{ selectedServiceReport.subject }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.STATUS }}:</div>
				<div class="col-8">
					{{
						selectedServiceReport.status === enumStatuses.OPEN
							? translate.IS_OPEN
							: selectedServiceReport.status === enumStatuses.PENDING
							? translate.IS_PENDING
							: translate.IS_CLOSED
					}}
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.DESCRIPTION }}:</div>
				<div class="col-8">{{ selectedServiceReport.description }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.CONTACT_PERSON }}:</div>
				<div class="col-8">{{ selectedServiceReport.contactPerson }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.REPORT_DATE }}:</div>
				<div class="col-8">{{ selectedServiceReport.date | date: 'dd.MM.yyyy' }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.DEVICE_NAME }}:</div>
				<div class="col-8">{{ selectedServiceReport.deviceName }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.DEVICE_MODEL }}:</div>
				<div class="col-8">{{ selectedServiceReport.model }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.SERIAL_NUMBER }}:</div>
				<div class="col-8">{{ selectedServiceReport.serialNumber }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.WARRANTY }}:</div>
				<div class="col-8">{{ selectedServiceReport.warranty === '1' ? translate.YES : translate.NO }}</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 form-control-text">{{ translate.WARRANTY_NUMBER }}:</div>
				<div class="col-8">{{ selectedServiceReport.warrantyNumber }}</div>
			</div>
			<div class="row">
				<div class="col-4 form-control-text">{{ translate.FACTURE_NUMBER }}:</div>
				<div class="col-8">{{ selectedServiceReport.factureNumber }}</div>
			</div>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="viewServiceReportModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>

	<!-- Confirm Status change modal -->
	<ng-template #confirmStatusChangeTemplate class="test">
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{ translate.STATUS_CHANGE_TITLE }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="confirmStatusChangeModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			{{ translate.STATUS_CHANGE_DESC }}
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button
					type="button"
					class="button button-gray"
					(click)="confirmStatusChangeModal.hide(); selectedStatus = null"
				>
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button" (click)="submitStatusChange(); selectedStatus = null">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</div>
