import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/sr/environment';
import { ServiceReport } from 'src/app/shared/models/service-report';
import { ServiceReply } from 'src/app/shared/models/service-reply';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { Service } from 'src/app/shared/models/service';

@Injectable()
export class ServiceReportsService {
	constructor(private http: HttpClient) {}

	getServiceReports(open: boolean, closed: boolean, waiting: boolean): Observable<ServiceReport[]> {
		return this.http.get<ServiceReport[]>(
			environment.baseApiUrl + `api/DeviceFailure?open=${open}&closed=${closed}&pending=${waiting}`
		);
	}

	getServiceReportReplies(id: number): Observable<ServiceReply[]> {
		return this.http.get<ServiceReply[]>(environment.baseApiUrl + 'api/DeviceFailure/DeviceFailureReply?id=' + id);
	}

	addServiceReportReply(reply: ServiceReply): Observable<ServiceReply> {
		return this.http.post<ServiceReply>(environment.baseApiUrl + 'api/DeviceFailure/DeviceFailureReply', reply);
	}

	changeServiceReportStatus(serviceReport: Update<ServiceReport>): Observable<Update<ServiceReport>> {
		return this.http
			.put<Update<ServiceReport>>(environment.baseApiUrl + 'api/DeviceFailure', serviceReport.changes)
			.pipe(map(() => serviceReport));
	}

	/**
	 * Returns a list of all registered services
	 */
	getAllService() {
		return this.http.get<Service[]>(environment.baseApiUrl + 'api/PortalService/registered-service');
	}

	/**
	 * Creates new registered services
	 */
	addRegisteredService(registeredService: Service) {
		return this.http.post<Service>(environment.baseApiUrl + 'api/PortalService/register-service', registeredService);
	}

	/**
	 * Updates registered services
	 */
	updateRegisteredService(registeredService: Update<Service>) {
		return this.http
			.put<Service>(environment.baseApiUrl + 'api/PortalService/register-service', registeredService.changes)
			.pipe(map(() => registeredService));
	}

	/**
	 * Deletes registered services
	 */
	deleteRegisteredService(id: number) {
		return this.http
			.delete<number>(environment.baseApiUrl + 'api/PortalService/register-service/' + id)
			.pipe(map(() => id));
	}
}
