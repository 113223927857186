export enum ExcelKeys_en {
	PRODUCT_NO = 'Product ID',
	CUSTOMER_NO = 'Customer ID',
	CUSTOMER_GROUP_ID = 'Customer group',
	FROM_DATE = 'From date',
	TO_DATE = 'To date',
	PRICE = 'Special',
	ERROR_FIELD = 'Error field',
	MESSAGE_TYPE = 'Message Type',
	MESSAGE_TEXT = 'Message Text',
}
