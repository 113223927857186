import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromServiceReportActions from './service-report.actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ServiceReportsService } from 'src/app/core/services/service-reports.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ServiceReportEffects {
	constructor(
		private actions$: Actions,
		private serviceReportsService: ServiceReportsService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadServiceReportsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromServiceReportActions.loadServiceReports),
			mergeMap(action =>
				this.serviceReportsService
					.getServiceReports(action.reportsToLoad.open, action.reportsToLoad.closed, action.reportsToLoad.pending)
					.pipe(
						map(serviceReports =>
							fromServiceReportActions.loadServiceReportsSuccess({
								serviceReports,
								reportsLoadedFlags: action.reportsLoadedFlags,
							})
						),
						catchError(error => of(fromServiceReportActions.loadServiceReportsFailure({ error })))
					)
			)
		)
	);

	updateServiceReportsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromServiceReportActions.updateServiceReport),
			mergeMap(action =>
				this.serviceReportsService.changeServiceReportStatus(action.serviceReport).pipe(
					switchMap(serviceReport =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromServiceReportActions.updateServiceReportSuccess({ serviceReport });
							}),
							catchError(error => of(fromServiceReportActions.updateServiceReportFailure({ error })))
						)
					)
				)
			)
		)
	);
}
