import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customSearchByProperty',
	pure: false,
})
export class CustomSearchByPropertyPipe implements PipeTransform {
	transform(items: any[], searchText: string, propertyName: string): any {
		// return empty array if array is falsy
		if (!items) {
			return [];
		}

		// return the original array if search text is empty
		if (!searchText) {
			return items;
		}

		// filter items array, items which match and return true will be
		// kept, false will be filtered out
		return items.filter(item => {
			if (item && item[propertyName]) {
				return item[propertyName].toLowerCase().includes(searchText.toLowerCase());
			}
			return false;
		});
	}
}
