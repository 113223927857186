import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { ProductGroup } from 'src/app/shared/models/product-group';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductGroupService {
	constructor(private http: HttpClient) {}

	getProductGroups(): Observable<ProductGroup[]> {
		return this.http.get<ProductGroup[]>(environment.baseApiUrl + 'api/ProductGroup');
	}

	getNavProductGroups(): Observable<ProductGroup[]> {
		return this.http.get<ProductGroup[]>(`${environment.baseApiUrl}api/ProductGroup/Nav`);
	}

	createProductGroup(productGroup: ProductGroup): Observable<ProductGroup> {
		return this.http.post<ProductGroup>(environment.baseApiUrl + 'api/ProductGroup', productGroup);
	}

	updateProductGroup(productGroup: Update<ProductGroup>) {
		return this.http
			.put<Update<ProductGroup>>(environment.baseApiUrl + 'api/ProductGroup', productGroup.changes)
			.pipe(map(() => productGroup));
	}

	updateProductGroupArray(productGroup: Update<ProductGroup>[]) {
		const array: ProductGroup[] = [];
		for (const element of productGroup) {
			array.push(element.changes as ProductGroup);
		}

		return this.http
			.put<ProductGroup[]>(environment.baseApiUrl + 'api/ProductGroup/UpdateProductGroupArray', array)
			.pipe(map(() => productGroup));
	}

	removeProductGroup(id: number) {
		return this.http.delete<ProductGroup>(environment.baseApiUrl + 'api/ProductGroup/' + id);
	}

	deleteProductGroupArray(keys: number[]) {
		return this.http
			.post<ProductGroup>(environment.baseApiUrl + 'api/ProductGroup/DeleteProductGroupArray', keys)
			.pipe(map(() => keys));
	}

	deleteProductGroupImage(productGroup: Update<ProductGroup>) {
		return this.http
			.delete<ProductGroup>(environment.baseApiUrl + 'api/ProductGroup/menu-banner/' + productGroup.id, {})
			.pipe(map(() => productGroup));
	}
}
