import { serviceReportsFeatureKey, ServiceReportState, selectAll } from './service-report.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { ServiceReport } from 'src/app/shared/models/service-report';

export const selectServiceReportState = createFeatureSelector<ServiceReportState>(serviceReportsFeatureKey);

export const selectAllServiceReports = createSelector(selectServiceReportState, selectAll);

export const selectServiceReportsLoading = createSelector(
	selectServiceReportState,
	state => state.statusState === LoadingState.LOADING
);

export const selectServiceReportsError = createSelector(selectServiceReportState, (state: ServiceReportState) =>
	getError(state.statusState)
);

export const selectServiceReportsFlags = createSelector(selectServiceReportState, (state: ServiceReportState) => {
	return {
		open: state.openLoaded,
		pending: state.pendingLoaded,
		closed: state.closedLoaded,
	};
});

export const selectReportsByStatus = createSelector(selectAllServiceReports, (reports: ServiceReport[], params) =>
	reports.filter(report => report.status === params.status)
);
