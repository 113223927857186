<ng-container *ngFor="let item of errors | keyvalue">
	<ng-container [ngSwitch]="item.key">
		<ng-container *ngSwitchCase="'required'">
			{{ errorMessages.FIELD_REQUIRED }}
		</ng-container>
		<ng-container *ngSwitchCase="'email'">
			{{ errorMessages.EMAIL_FORMAT }}
		</ng-container>
		<ng-container *ngSwitchCase="'minlength'">
			{{ errorMessages.FIELD_SIZE_BELOW_LIMIT }}
		</ng-container>
		<ng-container *ngSwitchCase="'maxlength'">
			{{ errorMessages.FIELD_SIZE_LIMIT_EXCEEDED }}
		</ng-container>
		<ng-container *ngSwitchCase="'min'">
			{{ errorMessages.FIELD_VALUE_BELOW_LIMIT }}
		</ng-container>
		<ng-container *ngSwitchCase="'max'">
			{{ errorMessages.FIELD_VALUE_EXCEEDED }}
		</ng-container>
		<ng-container *ngSwitchCase="'pattern'">
			{{ errorMessages.PATTERN_MISMATCH }}
		</ng-container>
		<ng-container *ngSwitchCase="'alreadyExist'">
			{{ errorMessages.GROUP_NAME_ALREADY_EXISTS }}
		</ng-container>
	</ng-container>
</ng-container>
