import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebUser } from 'src/app/shared/models/web-user';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class WebUserService {
	constructor(private http: HttpClient) {}

	getWebUsers(): Observable<WebUser[]> {
		return this.http.get<WebUser[]>(environment.baseApiUrl + 'api/WSUser');
	}

	addWebUser(webUser: WebUser): Observable<WebUser> {
		return this.http.post<WebUser>(environment.baseApiUrl + 'api/WSUser', webUser);
	}

	updateWebUser(webUser: Update<WebUser>): Observable<Update<WebUser>> {
		return this.http
			.put<Update<WebUser>>(environment.baseApiUrl + 'api/WSUser', webUser.changes)
			.pipe(map(() => webUser));
	}

	deleteWebUser(webUserId: number): Observable<number> {
		return this.http.delete(environment.baseApiUrl + 'api/WSUser/' + webUserId).pipe(map(() => webUserId));
	}
}
