import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { cloneDeep, extend } from 'lodash-es';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Manufacturer } from 'src/app/shared/models/manufacturer';
import { ManufacturerFiltered } from 'src/app/shared/models/manufacturer-filtered';
import { map } from 'rxjs/operators';

@Injectable()
export class ManufacturerService {
	constructor(private http: HttpClient) {}

	getManufacturers(): Observable<Manufacturer[]> {
		return this.http.get<Manufacturer[]>(environment.baseApiUrl + 'api/Manufacturer');
	}

	getManufacturersByCustomerAndGroup(productGroup: string, customerId: string): Observable<ManufacturerFiltered[]> {
		return this.http
			.get<ManufacturerFiltered[]>(
				environment.baseApiUrl +
					'api/Manufacturer/GetManufacturerByProductGroupAndCustomer/' +
					productGroup +
					'/' +
					customerId
			)
			.pipe(
				map(data => {
					const manufacturers: ManufacturerFiltered[] = [];
					const tempExtendObj = { customerId, productGroup, selected: false };
					data.forEach(manufact => {
						manufacturers.push(cloneDeep(extend(manufact, tempExtendObj)));
					});
					return manufacturers;
				})
			);
	}
}
