import { userLogFeatureKey, UserLogState, selectAll, selectIds } from './user-logs.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectUserLogsState = createFeatureSelector<UserLogState>(userLogFeatureKey);

export const selectAllUserLogs = createSelector(selectUserLogsState, selectAll);
export const selectAllUserLogsIds = createSelector(selectUserLogsState, selectIds);

export const selectUserLogsLoading = createSelector(
	selectUserLogsState,
	state => state.statusState === LoadingState.LOADING
);
export const selectUserLogsByUserNameState = createFeatureSelector<UserLogState>(userLogFeatureKey);

export const selectAllUserLogsByUserName = createSelector(selectUserLogsState, selectAll);

export const selectUserLogsByUserNameLoading = createSelector(
	selectUserLogsState,
	state => state.statusState === LoadingState.LOADING
);
export const selectUserLogsError = createSelector(selectUserLogsState, (state: UserLogState) =>
	getError(state.statusState)
);
