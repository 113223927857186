import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Group } from '../../shared/models/group';
import * as GroupActions from './group.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const groupsFeatureKey = 'groups';

export interface GroupState extends EntityState<Group> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>();

export const initialState: GroupState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const groupReducer = createReducer(
	initialState,
	on(GroupActions.loadGroups, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(GroupActions.loadGroupsSuccess, (state, action) =>
		adapter.setAll(action.groups, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(GroupActions.loadGroupsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(GroupActions.addGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(GroupActions.addGroupSuccess, (state, action) =>
		adapter.addOne(action.group, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(GroupActions.addGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(GroupActions.updateGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(GroupActions.updateGroupSuccess, (state, action) =>
		adapter.updateOne(action.group, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(GroupActions.updateGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: GroupState | undefined, action: Action) {
	return groupReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
