<ng-container
	*ngIf="
		translate && {
			productDataLoading: productDataLoading$ | async
		} as observables
	"
>
	<div class="add-spec-cont">
		<div class="page-title mb-5">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<div class="inner-addit-spec-container">
			<div class="row mb-3">
				<div class="col-md-3 form-control-text">{{ translate.ITEM_NO }}: <span class="required-field">*</span></div>
				<div class="col-4 col-md-4 col-sm-4 col-xs-6">
					<div class="loading-cont" *ngIf="observables.productDataLoading">
						<div class="spinner-border"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>
					<div *ngIf="!observables.productDataLoading">
						<div class="autocomplete-container">
							<p-autoComplete
								class="form-control-autocomplete live-search-padding"
								[class]="!itemSearch ? 'hide-autocomplete-loader-icon' : ''"
								[(ngModel)]="itemSearch"
								[suggestions]="results"
								(onSelect)="selectProduct()"
								(completeMethod)="filterProductList($event)"
								(onFocus)="forceReferenceReload()"
								emptyMessage="{{ noResultsMessage }}"
								[placeholder]="translate.ITEM_NO"
								completeOnFocus="true"
								minLength="3"
								delay="250"
							></p-autoComplete>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 form-control-text">{{ translate.SELECT_FILE }}: <span class="required-field">*</span></div>
				<div class="col-md-5">
					<label
						class="button button-gray"
						style="display: inline-flex"
						[ngClass]="{ disabled: !product }"
						[style.padding-top.px]="9"
					>
						<fa-icon icon="plus" class="mr-1"></fa-icon>
						{{ 'CHOOSE_FILE' | translate }}
						<input
							type="file"
							[(ngModel)]="uploadFile"
							accept="application/pdf"
							(change)="onFileChange($event)"
							[disabled]="!product"
							hidden
						/>
					</label>
					<span class="ml-3">
						{{ fileName }}
						<fa-icon
							icon="check"
							[style.color]="'green'"
							*ngIf="product && uploadFile && fileUploaded"
							title="{{ 'FILE_READY' | translate }}"
						></fa-icon>
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">&nbsp;</div>
				<div class="col-md-5">
					<button
						type="button"
						class="button"
						(click)="onSubmit()"
						[disabled]="!uploadFile || !fileUploaded"
						[ngClass]="{ disabled: !uploadFile || !fileUploaded || uploading }"
					>
						{{ 'SUBMIT' | translate }}
						<div class="spinner-border small" *ngIf="uploading"></div>
					</button>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12">
					<p class="mb-2" style="font-size: 13px; font-weight: 500">
						<b>{{ translate.HELP_NOTE }}</b> {{ translate.HELP_MSG }}
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<a href="assets/documents/AddSpecification.pdf" target="_blank" class="user-manual-link">
						<fa-icon icon="info-circle"> </fa-icon>
						<span>{{ translate.USER_MANUAL }}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</ng-container>
