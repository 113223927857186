import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { TargetState } from '../root-store/targets-store/targets.reducer';
import * as targetSelectors from '../root-store/targets-store/targets.selectors';
import * as targetActions from '../root-store/targets-store/targets.actions';

import { CustomerState } from '../root-store/customers-store/customer.reducer';
import * as customerSelectors from '../root-store/customers-store/customer.selectors';
import * as customerActions from '../root-store/customers-store/customer.actions';

import { ManufacturerState } from '../root-store/manufacturers-store/manufacturer.reducer';
import * as manufacturerActions from '../root-store/manufacturers-store/manufacturer.actions';
import * as manufacturerSelectors from '../root-store/manufacturers-store/manufacturer.selectors';

import { ProductGroupNavState } from '../root-store/product-groups-nav-store/product-groups-nav.reducer';
import * as productGroupNavSelectors from '../root-store/product-groups-nav-store/product-groups-nav.selectors';
import * as productGroupNavActions from '../root-store/product-groups-nav-store/product-groups-nav.actions';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Target } from '../shared/models/target';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { Update } from '@ngrx/entity';
import { Dropdown } from 'primeng/dropdown';
import { Table } from 'primeng/table';

@Component({
	selector: 'app-targets',
	templateUrl: './targets.component.html',
	styleUrls: ['./targets.component.scss'],
})
export class TargetsComponent implements OnInit {
	@ViewChild('targetsTable') targetsTable: Table;
	updateTargetModal: BsModalRef;
	targetForm: any;

	firstPageElementIndex = 0;

	submitted: boolean;
	targetId: number;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 3 },
		{ name: 'customer', title: 'CUSTOMER', width: 11 },
		{ name: 'manufacturer', title: 'MANUFACTURER', width: 9 },
		{ name: 'product_group', title: 'PRODUCT_GROUP', width: 9 },
		{ name: 'price', title: 'AMOUNT', width: 9 },
		{ name: 'quantity', title: 'QUANTITY', width: 9 },
		{ name: 'from_date', title: 'FROM_DATE', width: 9 },
		{ name: 'to_date', title: 'TO_DATE', width: 9 },
		{ name: 'bonus_percentage', title: 'BONUS_PERCENTAGE', width: 9 },
		{ name: 'visibleOnPortal', title: 'VISIBLE_ON_PORTAL', width: 9 },
		{ name: 'completed', title: 'COMPLETED', width: 9 },
	];

	ngUnsubscribe: Subject<object> = new Subject();

	translate: any;
	selectFromDropdown: string;
	selectCustomerFromDropdown: string;
	selectDateRangeFilter: string;
	translateErrors: any;
	translateFormValidationErrorMsgs: any;

	startOfYear = Date.parse(new Date(new Date().getFullYear(), 0, 1).toISOString());
	endOfYear = Date.parse(new Date(new Date().getFullYear(), 11, 31).toISOString());
	currentDate = new Date().toISOString();

	targets: Target[];
	targetsLoading$: Observable<boolean>;

	customerName: string;
	manufacturerName: string;
	productGroupName: string;

	customers: SelectItem[];
	customersFilterDropdown: SelectItem[];
	customersLoading$: Observable<boolean>;
	selectedCustomer: string;

	productGroups: SelectItem[];
	productGroupsLoading$: Observable<boolean>;

	manufacturers: SelectItem[];
	manufacturersLoading$: Observable<boolean>;

	daterangeFilter: Date[];

	filterStartDate: Date;
	filterEndDate: Date;

	preventReload = true;
	emptyResultFlag = false;

	decimalNegativePattern = new RegExp('^-?[0-9]+([.][0-9]+)?$');

	minDate: Date;

	constructor(
		private translateService: TranslateService,
		private targetStore: Store<TargetState>,
		private customerStore: Store<CustomerState>,
		private productGroupNavStore: Store<ProductGroupNavState>,
		private manufacturerStore: Store<ManufacturerState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.translateService.get('TARGETS').subscribe((resp: any) => {
			this.translate = resp;
			this.selectFromDropdown = this.translate.SELECT_DROPDOWN;
			this.selectCustomerFromDropdown = this.translate.SELECT_CUSTOMER_DROPDOWN;
			this.selectDateRangeFilter = this.translate.SELECT_ACTIVE_RANGE_FILTER;
		});

		this.customers = [];
		this.customersFilterDropdown = [];

		this.translateService.get('ERROR').subscribe((resp: any) => (this.translateErrors = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));

		this.loadAdditionalData();

		this.targetsLoading$ = this.targetStore.select(targetSelectors.selectTargetsLoading);

		this.manufacturersLoading$ = this.manufacturerStore.select(manufacturerSelectors.selectManufacturersLoading);
		this.customersLoading$ = this.customerStore.select(customerSelectors.selectCustomersLoading);
		this.productGroupsLoading$ = this.productGroupNavStore.select(
			productGroupNavSelectors.selectProductGroupsNavLoading
		);

		this.targetStore
			.select(targetSelectors.selectEmptyResultFlag)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => (this.emptyResultFlag = data));

		this.targetStore
			.select(targetSelectors.selectAllTargets)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data?.length) {
					this.targetStore.dispatch(
						targetActions.loadTargets({
							startDate: new Date(new Date().getFullYear(), 0, 1),
							endDate: new Date(new Date().getFullYear(), 11, 31),
						})
					);
				} else {
					if (data) {
						this.targets = data;
					}
					if (this.filterStartDate && this.filterEndDate) {
						this.targets = this.targets.filter(
							t => t.fromDate >= this.filterStartDate.toISOString() && t.fromDate <= this.filterEndDate.toISOString()
						);
					} else {
						this.targets = this.targets.filter(
							t => Date.parse(t.fromDate) >= this.startOfYear && Date.parse(t.fromDate) <= this.endOfYear
						);
					}
				}
			});
	}

	/**
	 * Loads data - manufacturers, customers, product groups - for dropdown options/filters
	 */
	loadAdditionalData() {
		this.manufacturerStore
			.select(manufacturerSelectors.selectAllManufacturers)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data?.length) {
					this.manufacturerStore.dispatch(manufacturerActions.loadManufacturers());
				} else {
					if (data) {
						this.manufacturers = data.map(manufacturer => ({
							label: manufacturer.name,
							value: manufacturer.id,
						}));
					}
				}
			});

		this.customerStore
			.select(customerSelectors.selectAllCustomers)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data?.length) {
					this.customerStore.dispatch(customerActions.loadCustomers());
				} else {
					if (data) {
						for (const customer of data) {
							this.customers.push({
								label: customer.name,
								value: customer.no,
							});
							this.customersFilterDropdown.push({
								label: customer.name,
								value: customer.name,
							});
						}
					}
				}
			});

		this.productGroupNavStore
			.select(productGroupNavSelectors.selectAllProductGroupsNav)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data?.length) {
					this.productGroupNavStore.dispatch(productGroupNavActions.loadProductGroupsNav());
				} else {
					if (data) {
						this.productGroups = data.map(productGroup => ({
							label: productGroup.description,
							value: productGroup.code,
						}));
					}
				}
			});
	}

	/**
	 * Filters Targets by fetching and filtering by target's fromDate\
	 * Only Targets with fromDate between filter start and end date are shown\
	 * Cases:
	 * * If there is a selectedCustomer, it will filter data according to that filter
	 * * If the selected range is within current year, it will filter data from state,
	 * otherwise it will pull data from service for the given date range
	 *
	 * @param event An array of dates; first date in array is the start date, and second is the end date of the target
	 */
	filterByDate(event: Date[]) {
		if (!this.emptyResultFlag) {
			this.preventReload = true;
		}

		if (event && this.preventReload) {
			this.filterStartDate = new Date(event[0].setHours(0, 0, 0, 0));
			this.filterEndDate = new Date(event[1].setHours(0, 0, 0, 0));

			if (this.filterStartDate >= new Date(this.startOfYear) && this.filterEndDate <= new Date(this.endOfYear)) {
				this.targetStore
					.select(targetSelectors.selectAllTargets)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(data => {
						this.targets = data;

						this.targets = this.targets.filter(t => {
							if (this.selectedCustomer) {
								return (
									t.fromDate >= this.filterStartDate.toISOString() &&
									t.fromDate <= this.filterEndDate.toISOString() &&
									t.customer === this.selectedCustomer
								);
							} else {
								return (
									t.fromDate >= this.filterStartDate.toISOString() && t.fromDate <= this.filterEndDate.toISOString()
								);
							}
						});
					});
			} else {
				this.targetStore
					.select(targetSelectors.selectAllTargets)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe(data => {
						this.targets = data;

						this.targets = this.targets.filter(
							t => t.fromDate >= this.filterStartDate.toISOString() && t.fromDate <= this.filterEndDate.toISOString()
						);
					});

				this.targetStore.dispatch(
					targetActions.loadTargets({
						startDate: this.filterStartDate,
						endDate: this.filterEndDate,
					})
				);
			}

			this.preventReload = false;
		}

		this.targetStore.dispatch(targetActions.setEmptyResultFlag({ emptyResultFlag: false }));

		this.firstPageElementIndex = 0;
	}

	/**
	 * Manages how customer dropdown filter engages with the rest of the table:
	 * * If the customer is selected, it will use PrimeNg filter service to filter the table
	 * * If the customer filter is cleared, it will reset filters and pull data from store;
	 * > in case there were date filters applied prior to customer filter reset,
	 * 	 it will filter target data according to those filters
	 */
	manageCustomerDropdownChange() {
		if (this.selectedCustomer) {
			this.targetsTable.filter(this.selectedCustomer, 'customer', 'equals');
		} else {
			this.targetsTable.reset();
			this.targetStore
				.select(targetSelectors.selectAllTargets)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(data => {
					this.targets = data;

					if (this.filterStartDate && this.filterEndDate) {
						this.targets = this.targets.filter(
							t => t.fromDate >= this.filterStartDate.toISOString() && t.fromDate <= this.filterEndDate.toISOString()
						);
					}
				});
		}

		this.firstPageElementIndex = 0;
	}

	/**
	 * Clears date and customer filters from table\
	 * Pulls data from store and filters it so only data from current year remains, for all customers
	 */
	clearFilters() {
		this.daterangeFilter = null;
		this.selectedCustomer = null;

		this.preventReload = true;

		this.targetStore
			.select(targetSelectors.selectAllTargets)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				this.targets = data;

				this.targets = this.targets.filter(t => {
					return Date.parse(t.fromDate) >= this.startOfYear && Date.parse(t.fromDate) <= this.endOfYear;
				});
			});

		this.targetsTable.filter(this.selectedCustomer, 'customer', 'equals');

		this.firstPageElementIndex = 0;
	}

	openTargetModal(updateTargetTemplate: TemplateRef<any>, target: Target) {
		this.customerName = '';
		this.manufacturerName = '';
		this.productGroupName = '';

		this.submitted = false;
		this.targetId = 0;

		if (target) {
			const fromDate = new Date(target.fromDate);
			const toDate = new Date(target.toDate);

			this.targetForm = this.formBuilder.group({
				...target,
				targetId: target.targetId,
				customerNo: new FormControl({ value: target.customer, disabled: true }, Validators.required),
				productGroup: target.productGroupCode,
				manufacturer: target.manufacturerCode,
				price: [target.price, [Validators.maxLength(15)]],
				quantity: [target.quantity, [Validators.maxLength(15)]],
				fromDate: [fromDate, [Validators.required]],
				toDate: [toDate, [Validators.required]],
				bonusPercentage: [target.bonusPercentage, [Validators.required, Validators.maxLength(15)]],
				visibleOnPortal: [target.visibleOnPortal],
			});
		} else {
			this.minDate = new Date();

			this.targetForm = this.formBuilder.group({
				...new Target(),
				customerNo: ['', [Validators.required]],
				productGroup: '',
				manufacturer: '',
				price: '',
				quantity: '',
				fromDate: ['', [Validators.required]],
				toDate: ['', [Validators.required]],
				bonusPercentage: ['', [Validators.required, Validators.maxLength(15)]],
				visibleOnPortal: false,
			});
		}

		if (target) {
			this.targetId = target.id;
		}

		this.updateTargetModal = this.modalService.show(updateTargetTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	getSelectedCustomerName(customerDropdown: Dropdown) {
		this.customerName = customerDropdown.selectedOption.label;
	}

	getSelectedManufacturerName(manufacturerDropdown: Dropdown) {
		this.manufacturerName = manufacturerDropdown.selectedOption.label;
	}

	getSelectedProductGroupName(productGroupDropdown: Dropdown) {
		this.productGroupName = productGroupDropdown.selectedOption.label;
	}

	submitTargetForm() {
		this.submitted = true;

		if (!this.targetForm.value.price && !this.targetForm.value.quantity) {
			this.toastr.warning(this.translate.PRICE_OR_QUANTITY_REQUIRED, this.translate.PRICE_OR_QUANTITY_REQUIRED_TITLE, {
				timeOut: 10000,
			});
			return;
		}

		if (this.targetForm.value.toDate <= this.targetForm.value.fromDate) {
			this.toastr.warning(this.translate.TO_DATE_BEFORE_FROM_DATE, this.translate.TO_DATE_BEFORE_FROM_DATE_TITLE, {
				timeOut: 10000,
			});
			return;
		}

		if (this.targetForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}

		if (this.targetForm.value.id) {
			if (this.targetForm.value.manufacturer) {
				this.manufacturerName = this.manufacturers.find(m => m.value === this.targetForm.value.manufacturer).label;
			}

			if (this.targetForm.value.productGroup) {
				this.productGroupName = this.productGroups.find(pg => pg.value === this.targetForm.value.productGroup).label;
			}

			const tempTarget: Update<Target> = {
				id: this.targetForm.value.id,
				changes: {
					...this.targetForm.value,
					productGroupCode: this.targetForm.value.productGroup,
					productGroup: this.productGroupName,
					manufacturerCode: this.targetForm.value.manufacturer,
					manufacturer: this.manufacturerName,
					fromDate: new Date(this.targetForm.value.fromDate.setHours(0, 0, 0, 0)),
					toDate: new Date(this.targetForm.value.toDate.setHours(0, 0, 0, 0)),
				},
			};

			this.targetStore.dispatch(targetActions.updateTarget({ target: tempTarget }));
		} else {
			this.targetStore.dispatch(
				targetActions.addTarget({
					target: {
						...this.targetForm.value,
						customer: this.customerName,
						customerNo: this.targetForm.value.customerNo,
						manufacturer: this.manufacturerName,
						manufacturerCode: this.targetForm.value.manufacturer,
						productGroup: this.productGroupName,
						productGroupCode: this.targetForm.value.productGroup,
						fromDate: new Date(this.targetForm.value.fromDate.setHours(0, 0, 0, 0)),
						toDate: new Date(this.targetForm.value.toDate.setHours(0, 0, 0, 0)),
						visibleOnPortal: this.targetForm.value.visibleOnPortal,
					},
				})
			);

			this.firstPageElementIndex = 0;
		}

		this.updateTargetModal.hide();
	}
}
