import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromWebUserActions from './web-user.actions';
import { WebUserService } from '../../core/services/web-user.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WebUserEffects {
	constructor(
		private actions$: Actions,
		private webUserService: WebUserService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadWebUsersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromWebUserActions.loadWebUsers),
			mergeMap(() =>
				this.webUserService.getWebUsers().pipe(
					map(webUsers => fromWebUserActions.loadWebUsersSuccess({ webUsers })),
					catchError(error => of(fromWebUserActions.loadWebUsersFailure({ error })))
				)
			)
		)
	);

	addWebUserEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromWebUserActions.addWebUser),
			mergeMap(action =>
				this.webUserService.addWebUser(action.webUser).pipe(
					switchMap(webUser =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromWebUserActions.addWebUserSuccess({ webUser });
							}),
							catchError(error => of(fromWebUserActions.addWebUserFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateWebUserEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromWebUserActions.updateWebUser),
			mergeMap(action =>
				this.webUserService.updateWebUser(action.webUser).pipe(
					switchMap(webUser =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromWebUserActions.updateWebUserSuccess({ webUser });
							}),
							catchError(error => of(fromWebUserActions.updateWebUserFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteWebUserEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromWebUserActions.deleteWebUser),
			mergeMap(action =>
				this.webUserService.deleteWebUser(action.webUserId).pipe(
					switchMap(webUserId =>
						this.translateService.get('SUCCESS.FORM_DELETE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromWebUserActions.deleteWebUserSuccess({ webUserId });
							}),
							catchError(error => of(fromWebUserActions.deleteWebUserFailure({ error })))
						)
					)
				)
			)
		)
	);
}
