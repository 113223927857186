import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InactiveUser } from 'src/app/shared/models/inactive-user';
import { environment } from 'src/environments/sr/environment';

@Injectable({
	providedIn: 'root',
})
export class InactiveUsersService {
	constructor(private http: HttpClient) {}

	getInactiveUsers(fromDate: string): Observable<InactiveUser[]> {
		return this.http.get<InactiveUser[]>(`${environment.baseApiUrl}api/Customer/active/${fromDate}`);
	}
}
