import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/sr/environment';
import { AuthService } from '../core/authentication/auth.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Store } from '@ngrx/store';
import { HomePageState } from '../root-store/home-page-store/home-page.reducer';
import * as homePageSelectors from '../root-store/home-page-store/home-page.selectors';
import * as homePageActions from '../root-store/home-page-store/home-page.actions';
import { Observable } from 'rxjs';
import { HomePage } from '../shared/models/home-page';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	translate: any;
	translateMonths: any;
	isAuthenticated: boolean;
	data: any;
	options: any;
	plugin: any = ChartDataLabels;
	widgetsLoading$: Observable<boolean>;
	widgets: HomePage;
	username: string;
	environment = environment;

	constructor(
		private authService: AuthService,
		private translateService: TranslateService,
		private store: Store<HomePageState>
	) {}

	ngOnInit() {
		this.translateService.get('HOME_PAGE').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('MONTHS').subscribe((resp: any) => (this.translateMonths = resp));
		this.store.select(homePageSelectors.selectHomePage).subscribe(data => {
			if (data.length) {
				this.widgets = data[0];
				let firstData = [];
				let secondData = [];
				for (const element of data[0].ordersOverview) {
					if (element.id <= 12) {
						firstData.push(element.numberOfOrders);
					} else {
						secondData.push(element.numberOfOrders);
					}
				}
				const currentMonth = data[0].ordersOverview[0].month;
				firstData = firstData.slice().reverse();
				secondData = secondData.slice().reverse();
				const labels = this.translateMonths;
				let formattedLabels = labels.slice(currentMonth, labels.length);
				formattedLabels = formattedLabels.concat(labels.slice(0, currentMonth));

				this.data = {
					labels: formattedLabels,
					datasets: [
						{
							label: this.translate.LAST_12_MONTHS,
							data: firstData,
							fill: false,
							backgroundColor: '#2892ff',
							borderRadius: 50,
						},
						{
							label: this.translate.PREVIOUS_12_MONTHS,
							data: secondData,
							fill: false,
							backgroundColor: '#f80d50',
						},
					],
				};
			}
		});

		this.widgetsLoading$ = this.store.select(homePageSelectors.selectHomePageLoading);
		this.store.dispatch(homePageActions.loadHomePage());
		this.authService.currentUser$.subscribe(data => {
			if (data) {
				this.username = data.name;
			}
		});
		if (environment.isOAuthEnabled) {
			this.authService.canActivateProtectedRoutes$.subscribe(isAuth => {
				this.isAuthenticated = isAuth;
				if (isAuth) {
					// some service call
				}
			});
		} else {
			this.isAuthenticated = true;
			// some service call if IS is not enabled
		}

		this.options = {
			plugins: {
				datalabels: {
					formatter: value => {
						return value;
					},
					color: 'white',
				},
				align: 'end',
				anchor: 'end',
				borderRadius: 4,
				font: {
					weight: 'bold',
				},
			},
			legend: {
				display: true,
				position: 'top',
				align: 'end',
			},
			responsive: true,
			scales: {
				xAxes: [
					{
						gridLines: {
							color: 'rgba(0, 0, 0, 0)',
						},
					},
				],
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
			},
			borderRadius: 50,
		};
	}
}
