import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductGroup } from '../../shared/models/product-group';
import * as ProductGroupNavActions from './product-groups-nav.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const productGroupsNavFeatureKey = 'productGroupsNav';

export interface ProductGroupNavState extends EntityState<ProductGroup> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ProductGroup> = createEntityAdapter<ProductGroup>({
	selectId: productGroup => productGroup.code,
});

export const initialState: ProductGroupNavState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const productGroupReducer = createReducer(
	initialState,
	on(ProductGroupNavActions.loadProductGroupsNav, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupNavActions.loadProductGroupsNavSuccess, (state, action) =>
		adapter.setAll(action.productGroups, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupNavActions.loadProductGroupsNavFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductGroupNavState | undefined, action: Action) {
	return productGroupReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
