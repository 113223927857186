import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductDetail from './product-detail.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductDetailEffects } from './product-detail.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductDetail.productDetailsFeatureKey, fromProductDetail.reducer),
		EffectsModule.forFeature([ProductDetailEffects]),
	],
})
export class ProductDetailsStoreModule {}
