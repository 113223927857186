import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductDetailActions from './product-detail.actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/core/services/product-details.service';

@Injectable()
export class ProductDetailEffects {
	constructor(private actions$: Actions, private productDetailsService: ProductDetailsService) {}

	loadProductSpecs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductDetailActions.loadProductDetails),
			mergeMap(action =>
				this.productDetailsService.getProductSpecs(action.productNo).pipe(
					map(productDetails => fromProductDetailActions.loadProductDetailsSuccess({ productDetails })),
					catchError(error => of(fromProductDetailActions.loadProductDetailsFailure({ error })))
				)
			)
		)
	);
}
