import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromUserLog from './user-logs.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserLogEffects } from './user-logs.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromUserLog.userLogFeatureKey, fromUserLog.reducer),
		EffectsModule.forFeature([UserLogEffects]),
	],
})
export class UserLogsStoreModule {}
