export class ProductListFilter {
	customerID: string;
	type: string;
	code: string;
	inStockOnly: number;
	forWebOnly: number;
	priceFilter: PriceFilter;
	filterItems: FilterItem[];
	selectedFilterItems: AppliedFilter[];
	customerGroupID: number;
}

export class PriceFilter {
	priceFrom: number;
	priceTo: number;
	currency: string;
}

export class FilterItem {
	code: string;
	codeName: string;
	value: string;
	valueDesc: string;
	isAttribute: boolean;
}

export class AppliedFilter {
	code: string;
	codeName: string;
	filteredItems: FilterItem[];
}
