import { createAction, props } from '@ngrx/store';

import { AllowedQuantity } from '../../shared/models/allowed-quantity';
import { Update } from '@ngrx/entity';

export const loadAllowedQuantity = createAction('[AllowedQuantity Page] Load AllowedQuantity');

export const loadAllowedQuantitySuccess = createAction(
	'[AllowedQuantity API] Loaded AllowedQuantity Success',
	props<{ allowedQuantity: AllowedQuantity[] }>()
);

export const loadAllowedQuantityFailure = createAction(
	'[AllowedQuantity API] Loaded AllowedQuantity Failure',
	props<{ error: any }>()
);

export const addAllowedQuantity = createAction(
	'[AllowedQuantity Page] Add AllowedQuantity',
	props<{ allowedQuantity: AllowedQuantity }>()
);

export const addAllowedQuantitySuccess = createAction(
	'[AllowedQuantity API] Add AllowedQuantity Success',
	props<{ allowedQuantity: AllowedQuantity }>()
);

export const addAllowedQuantityFailure = createAction(
	'[AllowedQuantity API] Add AllowedQuantity Failure',
	props<{ error: any }>()
);

export const updateAllowedQuantity = createAction(
	'[AllowedQuantity Page] Update AllowedQuantity',
	props<{ allowedQuantity: Update<AllowedQuantity> }>()
);

export const updateAllowedQuantitySuccess = createAction(
	'[AllowedQuantity API] Update AllowedQuantity Success',
	props<{ allowedQuantity: Update<AllowedQuantity> }>()
);

export const updateAllowedQuantityFailure = createAction(
	'[AllowedQuantity API] Update AllowedQuantity Failure',
	props<{ error: any }>()
);

export const deleteAllowedQuantity = createAction(
	'[AllowedQuantity Page] Delete AllowedQuantity',
	props<{ allowedQuantityId: number }>()
);

export const deleteAllowedQuantitySuccess = createAction(
	'[AllowedQuantity API] Delete AllowedQuantity Success',
	props<{ AllowedQuantityId: number }>()
);

export const deleteAllowedQuantityFailure = createAction(
	'[AllowedQuantity API] Delete AllowedQuantity Failure',
	props<{ error: any }>()
);
