import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { exchangeRatesFeatureKey, ExchangeRateState, selectAll } from './exchange-rates.reducer';

export const selectExchangeRatesState = createFeatureSelector<ExchangeRateState>(exchangeRatesFeatureKey);

// Select ExchangeRate List
export const selectExchangeRates = createSelector(selectExchangeRatesState, selectAll);

// Common
export const selectExchangeRatesLoading = createSelector(
	selectExchangeRatesState,
	(state: ExchangeRateState) => state.statusState === LoadingState.LOADING
);

export const selectExchangeRatesError = createSelector(selectExchangeRatesState, (state: ExchangeRateState) =>
	getError(state.statusState)
);
