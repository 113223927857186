import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GuestBook } from '../../shared/models/guest-book';
import * as GuestBookActions from './guest-book.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const guestBookItemsFeatureKey = 'guestBook';

export interface GuestBookState extends EntityState<GuestBook> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<GuestBook> = createEntityAdapter<GuestBook>();

export const initialState: GuestBookState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const guestBookReducer = createReducer(
	initialState,
	on(GuestBookActions.loadGuestBookItems, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(GuestBookActions.loadGuestBookItemsSuccess, (state, action) =>
		adapter.setAll(action.guestBook, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(GuestBookActions.loadGuestBookItemsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: GuestBookState | undefined, action: Action) {
	return guestBookReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
