import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { RootStoreModule } from './root-store/root-store.module';
import { environment } from '../environments/sr/environment';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { PromotionsModule } from './promotions/promotions.module';
import { ProductsModule } from './products/products.module';
import { PromotionSliderModule } from './promotion-slider/promotion-slider.module';
import { SellersModule } from './sellers/sellers.module';
import { AdministratorsModule } from './administrators/administrators.module';
import { WebServiceUsersModule } from './web-service-users/web-service-users.module';
import { UserProductAssignModule } from './user-product-assign/user-product-assign.module';
import { AdditionalSpecificationModule } from './additional-specification/additional-specification.module';
import { GroupsModule } from './groups/groups.module';
import { VendorlandModule } from './vendorland/vendorland.module';
import { AllowedQuantityModule } from './allowed-quantity/allowed-quantity.module';
import { ServiceReportsModule } from './service-reports/service-reports.module';
import { PriceListModule } from './pricelists/pricelists.module';
import { CustomersModule } from './customers/customers.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserLogsModule } from './user-logs/user-logs.module';
import { GroupConnectionsModule } from './group-connections/group-connections.module';
import { TargetsModule } from './targets/targets.module';
import { ProductGroupsModule } from './product-groups/product-groups.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MyProfileModule } from './my-profile/my-profile.module';
import { InactiveUsersModule } from './inactive-users/inactive-users.module';
import { ViberPromotionsModule } from './viber-promotions/viber-promotions.module';
import { GuestBookModule } from './guest-book/guest-book.module';
import { SpecialPriceModule } from './special-price/special-price.module';
import { RegisteredServiceModule } from './registered-service/registered-service.module';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, environment.translateFilesLocation, '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		ModalModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
		}),
		CoreModule.forRoot(),
		SharedModule,
		HomeModule,
		ProductsModule,
		PromotionsModule,
		RegisteredServiceModule,
		PromotionSliderModule,
		SellersModule,
		TargetsModule,
		BsDatepickerModule,
		AdministratorsModule,
		WebServiceUsersModule,
		UserProductAssignModule,
		AdditionalSpecificationModule,
		VendorlandModule,
		AllowedQuantityModule,
		GroupsModule,
		ServiceReportsModule,
		PriceListModule,
		CustomersModule,
		SpecialPriceModule,
		DashboardModule,
		UserLogsModule,
		RootStoreModule,
		GroupConnectionsModule,
		ProductGroupsModule,
		MyProfileModule,
		ViberPromotionsModule,
		GuestBookModule,
		InactiveUsersModule,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
