import { targetFeatureKey, TargetState, selectAll } from './targets.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectTargetState = createFeatureSelector<TargetState>(targetFeatureKey);

export const selectAllTargets = createSelector(selectTargetState, selectAll);

export const selectTargetsLoading = createSelector(
	selectTargetState,
	state => state.statusState === LoadingState.LOADING
);

export const selectTargetsError = createSelector(selectTargetState, (state: TargetState) =>
	getError(state.statusState)
);

export const selectEmptyResultFlag = createSelector(selectTargetState, state => state.emptyResult);
