import { Action, createReducer, on } from '@ngrx/store';
import { ProductFilters } from '../../shared/models/product-filters';
import * as productFilterActions from './product-filter.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const productFiltersFeatureKey = 'productFilters';

export interface ProductFiltersState {
	productFilters: ProductFilters;
	statusState: StatusState;
}

const initialState: ProductFiltersState = {
	productFilters: null,
	statusState: LoadingState.INIT,
};

const productFilterReducer = createReducer(
	initialState,
	on(productFilterActions.loadProductFilters, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(productFilterActions.loadProductFiltersSuccess, (state, action) => {
		return {
			...state,
			productFilters: action.productFilters,
			statusState: LoadingState.LOADED,
		};
	}),
	on(productFilterActions.loadProductFiltersFailure, (state, action) => {
		return {
			...state,
			productFilters: null,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductFiltersState | undefined, action: Action) {
	return productFilterReducer(state, action);
}
