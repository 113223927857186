export class Seller {
	id: number;
	firstName: string;
	lastName: string;
	program: string;
	email: string;
	msn: string;
	phone: string;
	mobile: string;
	position: string;
	username: string;
	adultImage: string;
	imageFile: string;
	adultImageExtension: string;
	childImage: string;
	order: number;
	active: boolean;

	constructor() {
		this.id = 0;
		this.firstName = '';
		this.lastName = '';
		this.program = '';
		this.email = '';
		this.msn = '';
		this.phone = '';
		this.mobile = '';
		this.position = '';
		this.username = '';
		this.adultImage = '';
		this.imageFile = '';
		this.adultImageExtension = '';
		this.childImage = '';
		this.order = null;
		this.active = true;
	}
}
