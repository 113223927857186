import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromCustomerRecordActions from './customer-record.actions';
import { CustomerRecordsService } from 'src/app/core/services/customer-records.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomerRecordEffects {
	constructor(
		private actions$: Actions,
		private customerService: CustomerRecordsService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadCustomerRecordsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerRecordActions.loadCustomerRecords),
			mergeMap(() =>
				this.customerService.getCustomerRecords().pipe(
					map(customerRecords => fromCustomerRecordActions.loadCustomerRecordsSuccess({ customerRecords })),
					catchError(error => of(fromCustomerRecordActions.loadCustomerRecordsFailure({ error })))
				)
			)
		)
	);

	addCustomerRecordEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerRecordActions.addCustomerRecord),
			mergeMap(action =>
				this.customerService.addCustomerRecord(action.customerRecord).pipe(
					switchMap(customerRecord =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromCustomerRecordActions.addCustomerRecordSuccess({ customerRecord });
							}),
							catchError(error => of(fromCustomerRecordActions.addCustomerRecordFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateCustomerRecordEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerRecordActions.updateCustomerRecord),
			mergeMap(action =>
				this.customerService.updateCustomerRecord(action.customerRecord).pipe(
					switchMap(customerRecord =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromCustomerRecordActions.updateCustomerRecordSuccess({ customerRecord });
							}),
							catchError(error => of(fromCustomerRecordActions.updateCustomerRecordFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteAllowedQuantityEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerRecordActions.deleteCustomerRecord),
			mergeMap(action =>
				this.customerService.deleteCustomerRecord(action.customerRecordId).pipe(
					switchMap(customerRecordId =>
						this.translateService.get('SUCCESS.FORM_DELETE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromCustomerRecordActions.deleteCustomerRecordSuccess({ customerRecordId });
							}),
							catchError(error => of(fromCustomerRecordActions.deleteCustomerRecordFailure({ error })))
						)
					)
				)
			)
		)
	);
}
