import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromCustomerGroupActions from './customer-group.actions';
import { CustomerService } from '../../core/services/customer.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CustomerGroupEffects {
	constructor(private actions$: Actions, private customerService: CustomerService) {}

	loadCustomerGroupsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerGroupActions.loadCustomerGroups),
			mergeMap(() =>
				this.customerService.getCustomerGroups().pipe(
					map(customerGroups => {
						return fromCustomerGroupActions.loadCustomerGroupsSuccess({ customerGroups });
					}),
					catchError(error => of(fromCustomerGroupActions.loadCustomerGroupsFailure({ error })))
				)
			)
		)
	);
}
