<ng-container *ngIf="{ userLogsLoading: userLogsLoading$ | async } as observables">
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<app-skeleton-screen
		*ngIf="observables.userLogsLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<div *ngIf="!observables.userLogsLoading">
		<ng-container *ngIf="userLogs$ | async">
			<p-table
				#userlogstable
				[value]="userLogs$ | async"
				dataKey="userName"
				[autoLayout]="true"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['userName', 'created', 'browser', 'os', 'device', 'ipAddress', 'country', 'url']"
			>
				<ng-template pTemplate="caption">
					<div class="p-grid">
						<div class="p-col">
							<p-dropdown
								class="table-dropdown-options"
								[appendTo]="userlogstable"
								[options]="dropdownOptions"
								[(ngModel)]="selectedUserName"
								[filter]="true"
								filterBy="value"
								(onChange)="userlogstable.filter(selectedUserName, 'userName', 'equals')"
							></p-dropdown>
						</div>
						<div class="p-col">
							<input
								bsDaterangepicker
								class="form-control date-picker"
								[(ngModel)]="rangeDates"
								[bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
								(bsValueChange)="userlogstable.filter($event, 'created', 'dateRangeFilter')"
								[placeholder]="translate.SELECT_DATA_RANGE_FILTER"
								readonly
							/>
						</div>
						<div class="p-col">
							<div class="global-filter-cont">
								<fa-icon icon="search"></fa-icon>
								<input
									type="text"
									class="form-control"
									pInputText
									size="50"
									[placeholder]="'GLOBAL_FILTER' | translate"
									data-tid="globalFilter"
									(input)="userlogstable.filterGlobal($event.target.value, 'contains')"
								/>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem"></th>
						<th *ngFor="let col of columns" pSortableColumn="{{ col.pSortableColumn }}">
							{{ col.name | translate }} <p-sortIcon field="{{ col.field }}"></p-sortIcon>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-user let-expanded="expanded">
					<tr>
						<td>
							<p-button
								(click)="getUserLogs(user)"
								type="button"
								[pRowToggler]="user"
								[icon]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"
							></p-button>
						</td>
						<td class="line-breaker">{{ user.userName }}</td>
						<td>{{ user.created | date: 'dd.MM.yyyy.' }}</td>
						<td>{{ user.browser }} {{ user.browserVersion }}</td>
						<td>{{ user.os }} {{ user.osVersion }}</td>
						<td>{{ user.device }} {{ user.deviceBrand }} {{ user.deviceModel }}</td>
						<td>{{ user.ipAddress }}</td>
						<td>{{ user.country }}</td>
						<td class="line-breaker">{{ user.url }}</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="rowexpansion" let-user>
					<tr>
						<td colspan="13">
							<div class="p-p-3">
								<p-table
									[loading]="!user.childrenUserLogs"
									[value]="user.childrenUserLogs"
									dataKey="userName"
									[paginator]="true"
									[rows]="10"
									[rowsPerPageOptions]="[5, 10, 20, 50]"
								>
									<ng-template pTemplate="header">
										<tr>
											<th *ngFor="let col of columns">
												{{ col.name | translate }}
											</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-log>
										<tr>
											<td class="line-breaker">{{ log.userName }}</td>
											<td>{{ log.created | date: 'dd.MM.yyyy.' }}</td>
											<td>{{ log.browser }} {{ log.browserVersion }}</td>
											<td>{{ log.os }} {{ log.osVersion }}</td>
											<td>{{ log.device }} {{ log.deviceBrand }} {{ log.deviceModel }}</td>
											<td>{{ log.ipAddress }}</td>
											<td>{{ log.country }}</td>
											<td class="line-breaker">{{ log.url }}</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="6">{{ 'NO_DATA' | translate }}</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">{{ 'NO_DATA' | translate }}</td>
					</tr>
				</ng-template>
			</p-table>
		</ng-container>
	</div>
</ng-container>
