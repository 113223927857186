import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CustomerService } from '../../core/services/customer.service';
import * as fromGuestBookActions from './guest-book.actions';

@Injectable()
export class GuestBookEffects {
	constructor(private actions$: Actions, private customerService: CustomerService) {}

	loadGuestBookItemsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromGuestBookActions.loadGuestBookItems),
			mergeMap(() =>
				this.customerService.getGuestBook().pipe(
					map(guestBook => fromGuestBookActions.loadGuestBookItemsSuccess({ guestBook })),
					catchError(error => of(fromGuestBookActions.loadGuestBookItemsFailure({ error })))
				)
			)
		)
	);
}
