import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromCustomerActions from './customer.actions';
import { CustomerService } from '../../core/services/customer.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { deeperCopy } from 'src/app/shared/utils/deeper-copy';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { fileExtensionParser } from 'src/app/shared/utils/file-extension-parser';

@Injectable()
export class CustomerEffects {
	constructor(
		private actions$: Actions,
		private customerService: CustomerService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadCustomersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.loadCustomers),
			mergeMap(() =>
				this.customerService.getCustomers().pipe(
					map(customers => {
						for (const element of customers) {
							element.searchText = element.name + ' ' + element.no;
						}
						return fromCustomerActions.loadCustomersSuccess({ customers });
					}),
					catchError(error => of(fromCustomerActions.loadCustomersFailure({ error })))
				)
			)
		)
	);

	loadCustomerInfoByIdEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.loadCustomerInfoById),
			mergeMap(action =>
				this.customerService.getCustomerInfoById(action.customer.no).pipe(
					map(customer => {
						const customerCopy = cloneDeep(customer);

						customerCopy.customerFiles = customer.customerFiles.map(customerFile => {
							customerFile.fileName
								? (customerFile.fileExtension = fileExtensionParser(customerFile.fileName))
								: (customerFile.fileExtension = '');
							customerFile.fileName = customerFile.fileName.slice(0, customerFile.fileName.lastIndexOf('.'));

							return customerFile;
						});

						return fromCustomerActions.loadCustomerInfoByIdSuccess({ customer: customerCopy });
					}),
					catchError(error => of(fromCustomerActions.loadCustomerInfoByIdFailure({ error })))
				)
			)
		)
	);

	updateCustomerExtraInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.updateCustomerExtraInfo),
			mergeMap(action =>
				this.customerService.updateCustomerExtraInfo(action.customer).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromCustomerActions.updateCustomerExtraInfoSuccess({ customer: action.customer });
							}),
							catchError(error => of(fromCustomerActions.updateCustomerExtraInfoFailure({ error })))
						)
					)
				)
			)
		)
	);

	removeCustomerLogo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.removeCustomerLogo),
			mergeMap(action =>
				this.customerService.removeCustomerLogo(action.customer).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.IMAGE_REMOVE').pipe(
							map(translate => {
								const pom = deeperCopy(action.customer);
								pom.logo = null;
								this.toastr.success(translate);
								return fromCustomerActions.removeCustomerLogoSuccess({ customer: pom });
							}),
							catchError(error => of(fromCustomerActions.removeCustomerLogoFailure({ error })))
						)
					)
				)
			)
		)
	);

	removeCustomerFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.removeCustomerFile),
			mergeMap(action =>
				this.customerService.removeCustomerFile(action.file.id).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FILE_REMOVE').pipe(
							map(translate => {
								const pom = deeperCopy(action.customer);
								pom.customerFiles = pom.customerFiles.filter(file => file.id !== action.file.id);
								this.toastr.success(translate);
								return fromCustomerActions.removeCustomerFileSuccess({ customer: pom });
							}),
							catchError(error => of(fromCustomerActions.removeCustomerFileFailure({ error })))
						)
					)
				)
			)
		)
	);

	addCustomerFiles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCustomerActions.addCustomerFile),
			mergeMap(action =>
				this.customerService.createCustomerFile(action.files).pipe(
					switchMap(customerFiles =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								const pom = deeperCopy(action.customer);
								for (const file of customerFiles) {
									pom.customerFiles.push(file);
								}
								this.toastr.success(translate);
								return fromCustomerActions.addCustomerFileSuccess({ customer: pom });
							}),
							catchError(error => of(fromCustomerActions.addCustomerFileFailure({ error })))
						)
					)
				)
			)
		)
	);
}
