<div
	class="product-assign-cont"
	*ngIf="
		translate && {
			customersLoading: customersLoading$ | async,
			customerRecordsLoading: customerRecordsLoading$ | async,
			manufacturersFilteredLoading: manufacturersByGroupAndCustomerLoading$ | async,
			productGroupsLoading: productGroupsLoading$ | async
		} as observables
	"
>
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<div class="row mb-3">
		<div class="col-lg-2 col-12">
			<ng-select
				[items]="optionsDropdown"
				[(ngModel)]="selectedDropdownOption"
				(change)="changeOptionsDropdown()"
				bindLabel="title"
				[virtualScroll]="true"
				[disabled]="optionsDropdown?.length < 1"
				data-tid="optionsDropdown"
			></ng-select>
		</div>
		<div class="col-lg-3 col-8">
			<ng-select
				*ngIf="selectedDropdownOption?.name === 'chooseCustomer'"
				[items]="customersDropdownOption"
				[(ngModel)]="selectedCustomer"
				(change)="changeCustomer()"
				[disabled]="observables.customersLoading"
				bindLabel="searchText"
				placeholder="- {{ 'SELECT' | translate }} -"
				[virtualScroll]="true"
				data-tid="customersDropdownOption"
				><ng-template ng-option-tmp let-item="item" let-index="index">
					{{ item.name }}<span class="product-no"> {{ item.no }}</span>
				</ng-template>
			</ng-select>
			<ng-select
				*ngIf="selectedDropdownOption?.name === 'chooseCustomerGroup'"
				[items]="customerGroupDropdown"
				[(ngModel)]="selectedCustomerGroup"
				[disabled]="customerGroupDropdown?.length < 1"
				bindLabel="name"
				placeholder="- {{ 'SELECT' | translate }} -"
				(change)="changeCustomer()"
				[virtualScroll]="true"
				data-tid="customerGroupDropdown"
			></ng-select>
			<div *ngIf="selectedDropdownOption?.name === 'backDiscount'" class="col-6">
				<span class="back-discount-input">
					<input
						type="number"
						min="0"
						max="100"
						class="form-control form-control-white"
						[(ngModel)]="backDiscount"
						(change)="changeCustomer()"
						data-tid="backDiscountInput"
					/>%
				</span>
			</div>
		</div>
	</div>
	<hr *ngIf="selectedCustomer?.no && selectedCustomer.no !== 'KU000000'" />
	<div
		class="row"
		*ngIf="(selectedCustomer?.no && selectedCustomer.no !== 'KU000000') || selectedCustomerGroup || backDiscount"
	>
		<div class="col-lg-6 col-12">
			<div class="row">
				<div class="col-12">
					<div class="product-assign-box">
						<h6
							(click)="groupsOrManuftacturersCollapsed = !groupsOrManuftacturersCollapsed"
							[ngClass]="{ collapsed: groupsOrManuftacturersCollapsed === true }"
						>
							{{ translate.RECORD_GROUP_OR_CUSTOMER }}
							<fa-icon class="float-right" icon="chevron-right"></fa-icon>
						</h6>
						<div class="p-1" [ngClass]="{ collapsed: groupsOrManuftacturersCollapsed === true }">
							<div class="choose-option">
								<label class="mr-3">
									<input
										type="radio"
										name="recordAssignOption"
										[(ngModel)]="recordAssignOption"
										value="groups"
										(click)="changeRadioGroupOrManuf()"
									/>
									{{ translate.GROUP_PRODUCTS }}
								</label>
								<label>
									<input
										type="radio"
										name="recordAssignOption"
										[(ngModel)]="recordAssignOption"
										value="manufacturers"
										(click)="changeRadioGroupOrManuf()"
									/>
									{{ translate.MANUFACTURERS }}
								</label>
							</div>
							<div class="row m-0">
								<div class="select-box">
									<div class="items">
										<div class="filter-box p-2" *ngIf="recordAssignOption == 'groups'">
											<input
												type="text"
												class="form-control"
												[(ngModel)]="filterGroups"
												[placeholder]="'SEARCH' | translate"
											/>
											<fa-icon
												icon="times"
												class="remove-filter"
												(click)="filterGroups = null"
												*ngIf="filterGroups"
												[title]="translate.CLEAR"
											></fa-icon>
										</div>
										<ul *ngIf="recordAssignOption == 'groups'">
											<li
												*ngFor="let group of productGroups | customSearchByProperty: filterGroups:'description'"
												(click)="selectGroup(group)"
												[ngClass]="{ selected: group.selected }"
											>
												{{ group.description }}
												<fa-icon icon="check" class="float-right" *ngIf="group.selected"></fa-icon>
											</li>
										</ul>
										<div class="filter-box p-2" *ngIf="recordAssignOption == 'manufacturers'">
											<input
												type="text"
												class="form-control"
												[(ngModel)]="filterManufacturers"
												[placeholder]="'SEARCH' | translate"
											/>
											<fa-icon
												icon="times"
												class="remove-filter"
												(click)="filterManufacturers = null"
												*ngIf="filterManufacturers"
												[title]="translate.CLEAR"
											></fa-icon>
										</div>
										<ul *ngIf="recordAssignOption == 'manufacturers'">
											<li
												*ngFor="let mf of manufacturers | customSearchByProperty: filterManufacturers:'name'"
												(click)="selectManufacturer(mf)"
												[ngClass]="{ selected: mf.selected }"
											>
												{{ mf.name }}<fa-icon icon="check" class="float-right" *ngIf="mf.selected"></fa-icon>
											</li>
										</ul>
									</div>
								</div>
								<div class="arrow-btns">
									<button
										type="button"
										class="button button-gray mb-2"
										(click)="unselectAllItems()"
										title="{{ translate.UNSELECT_ALL }}"
									>
										<fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
									</button>
									<button
										type="button"
										class="button button-gray"
										(click)="selectAllItems()"
										title="{{ translate.SELECT_ALL_FILT }}"
									>
										<fa-icon [icon]="faAngleDoubleRight"></fa-icon>
									</button>
								</div>
								<div class="select-box">
									<div class="items selected-items">
										<ul class="m-0">
											<span *ngIf="!selectedGroupItems.length && !selectedManufacturerItems.length">{{
												translate.NO_ITEMS_SELECTED
											}}</span>
											<span *ngIf="selectedGroupItems.length">{{ translate.SELECTED_GROUPS }}</span>
											<li *ngFor="let item of selectedGroupItems; let i = index">
												<fa-icon icon="times" (click)="removeGroup(item, i)"></fa-icon>{{ item.description }}
											</li>
											<span *ngIf="selectedManufacturerItems.length">{{ translate.SELECTED_MANUFACTURERS }}</span>
											<li *ngFor="let item of selectedManufacturerItems; let i = index">
												<fa-icon icon="times" (click)="removeManufacturer(item, i)"></fa-icon>{{ item.name }}
											</li>
										</ul>
									</div>
									<div class="product-options">
										<form [formGroup]="groupOrManufacturersForm">
											<div class="mb-2">
												{{ translate.PRICE_CORRECTION }}:
												<input type="number" class="price-correction form-control" formControlName="priceCorrection" />
												%
											</div>
											<div>
												<label
													[class.checked]="selectedOption == 'blockCombination' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="blockCombination"
														[value]="'blockCombination'"
														[(ngModel)]="selectedOption"
														[class.checked]="selectedOption == 'blockCombination' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.BLOCK_COMBINATION }}</span>
												</label>
											</div>
											<div>
												<label
													[class.checked]="selectedOption == 'exclusiveRights' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="exclusiveRights"
														[value]="'exclusiveRights'"
														[(ngModel)]="selectedOption"
														[class.checked]="selectedOption == 'exclusiveRights' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.EXCLUSIVE_RIGHTS }}</span>
												</label>
											</div>
											<div>
												<label
													[class.checked]="selectedOption == 'limitOnCombination' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="limitOnCombination"
														[value]="'limitOnCombination'"
														[(ngModel)]="selectedOption"
														[class.checked]="selectedOption == 'limitOnCombination' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.LIMIT_THIS_COMBINATION }}</span>
												</label>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="text-right pt-3 mt-3" [style.border-top]="'1px solid #ddd'">
								<button
									type="button"
									class="button"
									(click)="submitGroupOrManufacturerForm()"
									[disabled]="
										(!selectedGroupItems.length && !selectedManufacturerItems.length) ||
										observables.productGroupsLoading
									"
									[ngClass]="{ disabled: !selectedGroupItems.length && !selectedManufacturerItems.length }"
								>
									{{ 'SUBMIT' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6 col-12">
			<div class="row">
				<div class="col-12">
					<div class="product-assign-box">
						<h6
							(click)="groupsAndManuftacturersCollapsed = !groupsAndManuftacturersCollapsed"
							[ngClass]="{ collapsed: groupsAndManuftacturersCollapsed === true }"
						>
							{{ translate.RECORD_GROUP_AND_CUSTOMER }}
							<fa-icon class="float-right" icon="chevron-right"></fa-icon>
						</h6>
						<div class="p-1" [ngClass]="{ collapsed: groupsAndManuftacturersCollapsed === true }">
							<div class="choose-group">
								<div>
									<label>{{ translate.CHOOSE_GROUP }}:</label>
									<ng-select
										class="product-group-filter-search"
										[items]="productGroups"
										[(ngModel)]="selectedProductGroup"
										(ngModelChange)="selectManufacturersByGroup()"
										[disabled]="observables.manufacturersFilteredLoading"
										bindLabel="description"
										placeholder="- {{ 'SELECT' | translate }} -"
									></ng-select>
								</div>
							</div>
							<div class="row m-0">
								<div class="select-box">
									<div class="items">
										<div class="filter-box p-2">
											<input
												type="text"
												class="form-control"
												[(ngModel)]="filterGroupManufacturer"
												[placeholder]="'SEARCH' | translate"
											/>
											<fa-icon
												icon="times"
												class="remove-filter"
												[title]="translate.CLEAR"
												(click)="filterGroupManufacturer = null"
												*ngIf="filterGroupManufacturer"
											></fa-icon>
										</div>

										<ul *ngIf="manufacturersByGroupAndCustomer.length; else empty">
											<li
												*ngFor="
													let manufacturer of manufacturersByGroupAndCustomer
														| customSearchByProperty: filterGroupManufacturer:'name'
												"
												(click)="selectGroupManufacturer(manufacturer)"
												[ngClass]="{ selected: manufacturer.selected }"
											>
												{{ manufacturer.name }}
												<fa-icon icon="check" class="float-right" *ngIf="manufacturer.selected"></fa-icon>
											</li>
										</ul>

										<ng-template #empty>
											<span class="empty-message"> {{ translate.NO_ITEMS }} </span>
										</ng-template>
									</div>
								</div>
								<div class="arrow-btns">
									<button
										type="button"
										class="button button-gray mb-2"
										(click)="unselectAllManufacturersByGroup()"
										title="{{ translate.UNSELECT_ALL }}"
									>
										<fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
									</button>
									<button
										type="button"
										class="button button-gray"
										(click)="selectAllManufacturerGroups()"
										title="{{ translate.SELECT_ALL_FILT }}"
									>
										<fa-icon [icon]="faAngleDoubleRight"></fa-icon>
									</button>
								</div>
								<div class="select-box">
									<div class="items selected-items">
										<ul>
											<span *ngIf="!selectedGroupAndManufactItems.length">{{ translate.NO_ITEMS_SELECTED }}</span>
											<span *ngIf="selectedGroupAndManufactItems.length">{{ translate.SELECTED_MANUFACTURERS }}</span>
											<li *ngFor="let item of selectedGroupAndManufactItems; let i = index">
												<fa-icon icon="times" (click)="removeGroupManufacturer(item, i)"></fa-icon
												>{{ item.productGroup }} - {{ item.name }}
											</li>
										</ul>
									</div>
									<div class="product-options">
										<form [formGroup]="groupAndManufacturersForm">
											<div class="mb-2">
												{{ translate.PRICE_CORRECTION }}:
												<input type="number" class="price-correction form-control" formControlName="priceCorrection" />
												%
											</div>
											<div>
												<label
													[class.checked]="selectedGroupsAndManufOption == 'blockCombination' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="blockCombination"
														[value]="'blockCombination'"
														[(ngModel)]="selectedGroupsAndManufOption"
														[class.checked]="selectedGroupsAndManufOption == 'blockCombination' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.BLOCK_COMBINATION }}</span>
												</label>
											</div>
											<div>
												<label
													[class.checked]="selectedGroupsAndManufOption == 'exclusiveRights' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="exclusiveRights"
														[value]="'exclusiveRights'"
														[(ngModel)]="selectedGroupsAndManufOption"
														[class.checked]="selectedGroupsAndManufOption == 'exclusiveRights' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.EXCLUSIVE_RIGHTS }}</span>
												</label>
											</div>
											<div>
												<label
													[class.checked]="selectedGroupsAndManufOption == 'limitOnCombination' ? true : false"
													class="status-box chbx-container"
												>
													<input
														type="radio"
														name="limitOnCombination"
														[value]="'limitOnCombination'"
														[(ngModel)]="selectedGroupsAndManufOption"
														[class.checked]="selectedGroupsAndManufOption == 'limitOnCombination' ? true : false"
														[ngModelOptions]="{ standalone: true }"
													/>
													<span class="chbx-checkmark radio-checkmark"><svg-icon key="checkmark"></svg-icon></span>
													<span class="checkmark-text">{{ translate.LIMIT_THIS_COMBINATION }}</span>
												</label>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="text-right pt-3 mt-3" [style.border-top]="'1px solid #ddd'">
								<button
									type="button"
									class="button"
									(click)="submitGroupAndManufacturerForm()"
									[disabled]="!selectedGroupAndManufactItems.length || observables.manufacturersFilteredLoading"
									[ngClass]="{ disabled: !selectedGroupAndManufactItems.length }"
								>
									{{ 'SUBMIT' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr />
	<app-skeleton-screen
		*ngIf="(observables.customerRecordsLoading || observables.customersLoading) && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>

	<!-- TABLE -->
	<div *ngIf="!observables.customerRecordsLoading && !observables.customersLoading">
		<p-table
			#userProductAssignTable
			[value]="filteredCustomerRecords"
			[resizableColumns]="true"
			[autoLayout]="true"
			[(first)]="firstPageElementIndex"
			[paginator]="true"
			[rows]="10"
			[rowsPerPageOptions]="[5, 10, 20, 50]"
			sortField="id"
			[sortOrder]="-1"
			[globalFilterFields]="[
				'id',
				'no',
				'name',
				'groupCode',
				'manufacturerCode',
				'discount',
				'isBlockedForBrand',
				'isExclusiveForBrand',
				'isAllowedForBrand',
				'dateUpdated',
				'updatedDemandedBy',
				'backDiscount',
				'customerGroupId'
			]"
		>
			<ng-template pTemplate="caption">
				<div>
					<button type="button" class="btn-cont float-left" (click)="downloadExcel()">
						<button class="button circle-button">
							<svg-icon key="excel" class="download-excel-button"></svg-icon>
						</button>
						<span class="button-label">{{ 'EXPORT_TO_EXCEL' | translate }}</span>
					</button>
				</div>
				<div class="global-filter-cont">
					<fa-icon icon="search"></fa-icon>
					<input
						type="text"
						class="form-control"
						pInputText
						size="50"
						[placeholder]="'GLOBAL_FILTER' | translate"
						data-tid="globalFilter"
						(input)="userProductAssignTable.filterGlobal($event.target.value, 'contains')"
					/>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
						{{ translate.ID }}
						<p-sortIcon [field]="'id'"></p-sortIcon>
					</th>
					<th
						*ngIf="
							!['backDiscount', 'chooseCustomerGroup'].includes(selectedDropdownOption.name) ||
							selectedDropdownOption.name === 'selectOption'
						"
						[pSortableColumn]="'no'"
					>
						{{ translate.CUSTOMER_NO }}
						<p-sortIcon [field]="'no'"></p-sortIcon>
					</th>
					<th
						*ngIf="['chooseCustomer', 'selectOption'].includes(selectedDropdownOption.name)"
						[pSortableColumn]="'name'"
					>
						{{ translate.NAME }}
						<p-sortIcon [field]="'name'"></p-sortIcon>
					</th>
					<th
						*ngIf="['chooseCustomerGroup', 'selectOption'].includes(selectedDropdownOption.name)"
						[pSortableColumn]="'customerGroupId'"
					>
						{{ translate.CUSTOMER_GROUP }}
						<p-sortIcon [field]="'name'"></p-sortIcon>
					</th>
					<th
						*ngIf="['backDiscount', 'selectOption'].includes(selectedDropdownOption.name)"
						[pSortableColumn]="'backDiscount'"
					>
						{{ translate.BACK_DISCOUNT }}
						<p-sortIcon [field]="'backDiscount'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'groupCode'">
						{{ translate.GROUP_LABEL }}
						<p-sortIcon [field]="'groupCode'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'manufacturerCode'">
						{{ translate.PRODUCT_LABEL }}
						<p-sortIcon [field]="'manufacturerCode'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'discount'" class="text-center">
						{{ translate.PRICE_CORRECTION }}
						<p-sortIcon [field]="'discount'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'isBlockedForBrand'" class="text-center">
						{{ translate.BLOCK_COMBINATION }}
						<p-sortIcon [field]="'isBlockedForBrand'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'isExclusiveForBrand'" class="text-center">
						{{ translate.EXCLUSIVE_RIGHTS }}
						<p-sortIcon [field]="'isExclusiveForBrand'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'isAllowedForBrand'" class="text-center">
						{{ translate.LIMIT_THIS_COMBINATION }}
						<p-sortIcon [field]="'isAllowedForBrand'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'dateInserted'">
						{{ translate.DATE_ASSIGNED }}
						<p-sortIcon [field]="'dateInserted'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'updatedDemandedBy'">
						{{ translate.ASSIGNED_BY }}
						<p-sortIcon [field]="'updatedDemandedBy'"></p-sortIcon>
					</th>
					<th>&nbsp;</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-record let-i="rowIndex">
				<tr>
					<td class="text-center">
						<b>{{ record.id }}</b>
					</td>
					<td *ngIf="['chooseCustomer', 'selectOption'].includes(selectedDropdownOption.name)">
						{{ record.no }}
					</td>
					<td *ngIf="['chooseCustomer', 'selectOption'].includes(selectedDropdownOption.name)">
						<span *ngIf="!record.customerGroupId">{{ record.name }}</span>
					</td>
					<td *ngIf="['selectOption', 'chooseCustomerGroup'].includes(selectedDropdownOption.name)">
						<span *ngIf="record.customerGroupId">{{ record.name }}</span>
					</td>
					<td *ngIf="['backDiscount', 'selectOption'].includes(selectedDropdownOption.name)">
						{{ record.backDiscount }}
					</td>
					<td>{{ record.groupCode }}</td>
					<td>{{ record.manufacturerCode }}</td>
					<td class="text-center">{{ record.discount | number: '1.2-2' }} %</td>
					<td class="text-center">
						<fa-icon *ngIf="record.isBlockedForBrand" icon="check" [style.color]="'green'"></fa-icon>

						<fa-icon *ngIf="!record.isBlockedForBrand" icon="times" [style.color]="'red'"></fa-icon>
					</td>
					<td class="text-center">
						<fa-icon *ngIf="record.isExclusiveForBrand" icon="check" [style.color]="'green'"></fa-icon>

						<fa-icon *ngIf="!record.isExclusiveForBrand" icon="times" [style.color]="'red'"></fa-icon>
					</td>
					<td class="text-center">
						<fa-icon *ngIf="record.isAllowedForBrand" icon="check" [style.color]="'green'"></fa-icon>

						<fa-icon *ngIf="!record.isAllowedForBrand" icon="times" [style.color]="'red'"></fa-icon>
					</td>
					<td>{{ record.dateInserted | date: 'dd-MM-yyyy HH:mm' }}</td>
					<td>{{ record.updatedDemandedBy }}</td>
					<td class="text-center">
						<button
							type="button"
							class="button button-gray button-delete"
							(click)="openDeleteRecordModal(deleteAssignedRecordTemplate, record)"
						>
							<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
						</button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td [attr.colspan]="columns.length" class="empty-msg">
						{{ 'NO_DATA' | translate }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<!-- Delete record confirmation modal -->
<ng-template #deleteAssignedRecordTemplate>
	<div class="modal-header">
		<h4 class="modal-title">{{ translate.DELETE_RECORD_TITLE }}</h4>
		<button type="button" class="close pull-right" (click)="deleteAssignedRecordModal.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			{{ translate.DELETE_RECORD_MSG }}
		</div>
	</div>
	<div class="modal-footer modal-footer-remove">
		<div class="modal-footer-buttons">
			<button type="button" class="button button-gray" (click)="deleteAssignedRecordModal.hide()">
				{{ 'CLOSE' | translate }}
			</button>
			<button type="button" class="button" (click)="deleteAssignedRecord($event)">
				{{ 'SUBMIT' | translate }}
			</button>
		</div>
	</div>
</ng-template>
