import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Promotion } from 'src/app/shared/models/promotion';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionService {
	constructor(private http: HttpClient) {}

	getPromotions(): Observable<Promotion[]> {
		return this.http.get<Promotion[]>(environment.baseApiUrl + 'api/Promotion');
	}
	getActivePromotions(): Observable<Promotion[]> {
		return this.http.get<Promotion[]>(environment.baseApiUrl + 'api/Promotion/active');
	}

	addPromotion(promotion: Promotion): Observable<Promotion> {
		return this.http.post<Promotion>(environment.baseApiUrl + 'api/Promotion', promotion);
	}

	updatePromotion(promotion: Update<Promotion>): Observable<Update<Promotion>> {
		return this.http
			.put<Update<Promotion>>(environment.baseApiUrl + 'api/Promotion', promotion.changes)
			.pipe(map(() => promotion));
	}
}
