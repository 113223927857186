import { createAction, props } from '@ngrx/store';
import { InactiveUser } from 'src/app/shared/models/inactive-user';

export const loadInactiveUsers = createAction(
	'[Inactive Users Page] Load Inactive Users',
	props<{ fromDate: string }>()
);

export const loadInactiveUsersSuccess = createAction(
	'[InactiveUsers API] Loaded Inactive Users Success',
	props<{ users: InactiveUser[] }>()
);

export const loadInactiveUsersFailure = createAction(
	'[InactiveUsers API] Loaded Inactive Users Failure',
	props<{ error: any }>()
);
