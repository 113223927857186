import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAdministratorActions from './administrator.actions';
import { AdministratorService } from '../../core/services/administrator.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AdministratorEffects {
	constructor(
		private actions$: Actions,
		private administratorService: AdministratorService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadAdministratorsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAdministratorActions.loadAdministrators),
			mergeMap(() =>
				this.administratorService.getAdministrators().pipe(
					map(administrators => fromAdministratorActions.loadAdministratorsSuccess({ administrators })),
					catchError(error => of(fromAdministratorActions.loadAdministratorsFailure({ error })))
				)
			)
		)
	);

	loadCurrentAdministratorDetailsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAdministratorActions.loadCurrentAdministratorDetails),
			mergeMap(() =>
				this.administratorService.getCurrentAdministratorDetails().pipe(
					map(administrator => fromAdministratorActions.loadCurrentAdministratorDetailsSuccess({ administrator })),
					catchError(error => of(fromAdministratorActions.loadCurrentAdministratorDetailsFailure({ error })))
				)
			)
		)
	);

	addAdministratorEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAdministratorActions.addAdministrator),
			mergeMap(action =>
				this.administratorService.addAdministrator(action.administrator).pipe(
					switchMap(administrator =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromAdministratorActions.addAdministratorSuccess({ administrator });
							}),
							catchError(error => of(fromAdministratorActions.addAdministratorFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateAdministratorEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAdministratorActions.updateAdministrator),
			mergeMap(action =>
				this.administratorService.updateAdministrator(action.administrator).pipe(
					switchMap(administrator =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromAdministratorActions.updateAdministratorSuccess({ administrator });
							}),
							catchError(error => of(fromAdministratorActions.updateAdministratorFailure({ error })))
						)
					)
				)
			)
		)
	);
}
