import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromVendorland from './vendorland.reducer';
import { EffectsModule } from '@ngrx/effects';
import { VendorlandEffects } from './vendorland.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromVendorland.vendorlandFeatureKey, fromVendorland.reducer),
		EffectsModule.forFeature([VendorlandEffects]),
	],
})
export class VendorlandStoreModule {}
