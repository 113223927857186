import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserRegistration } from '../../shared/models/user-registration';
import * as UserRegistrationActions from './user-registration.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const userRegistrationsFeatureKey = 'userRegistration';

export interface UserRegistrationState extends EntityState<UserRegistration> {
	statusState: StatusState;
}

export const adapter: EntityAdapter<UserRegistration> = createEntityAdapter<UserRegistration>();

export const initialState: UserRegistrationState = adapter.getInitialState({
	statusState: LoadingState.INIT,
});

export const userRegistrationReducer = createReducer(
	initialState,
	on(UserRegistrationActions.loadUserRegistration, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(UserRegistrationActions.loadUserRegistrationSuccess, (state, action) =>
		adapter.setAll(action.userRegistration, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(UserRegistrationActions.loadUserRegistrationFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: UserRegistrationState | undefined, action: Action) {
	return userRegistrationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
