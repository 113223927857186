<ng-container *ngIf="translate && { allowedQuantityLoading: allowedQuantityLoading$ | async } as observables">
	<div>
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openAllowedQuantityModal(updateAllowedQuantityTemplate, null)"
			[disabled]="observables.allowedQuantityLoading"
			data-tid="addAllowedQuantity"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.allowedQuantityLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.allowedQuantityLoading">
			<p-table
				#allowedQuantitytable
				[value]="allowedQuantity$ | async"
				[autoLayout]="true"
				class="allowedQuantity-prime-table"
				[paginator]="true"
				[rows]="10"
				[(first)]="firstPageElementIndex"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'productGroup', 'productName', 'allowedQtty']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="allowedQuantitytable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>

				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'productGroup'">
							{{ translate.PRODUCT_GROUP }}
							<p-sortIcon [field]="'productGroup'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'productName'">
							{{ translate.PRODUCT_NAME }}
							<p-sortIcon [field]="'productName'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'allowedQtty'">
							{{ translate.ALLOWED_QTTY }}
							<p-sortIcon [field]="'allowedQtty'"></p-sortIcon>
						</th>
						<th [style.width.px]="100">&nbsp;</th>
						<th [style.width.px]="100">&nbsp;</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-allowedQuantity let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ allowedQuantity.id }}</b>
						</td>
						<td>{{ allowedQuantity.productGroup }}</td>
						<td>{{ allowedQuantity.productName }}</td>
						<td>{{ allowedQuantity.allowedQtty }}</td>
						<td class="text-right">
							<button
								type="button"
								class="button button-gray"
								(click)="openAllowedQuantityModal(updateAllowedQuantityTemplate, allowedQuantity)"
								data-tid="editAllowedQuantity"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
						<td class="text-right">
							<button
								type="button"
								class="button button-gray button-delete"
								(click)="openConfirmationModal(deleteAllowedQuantityTemplate, allowedQuantity)"
							>
								<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<!-- Add/Edit allowedQuantity modal -->
	<ng-template #updateAllowedQuantityTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left">
				{{ allowedQuantityForm?.value?.id ? translate.EDIT_ALLOWED_QUANTITY : translate.ADD_ALLOWED_QUANTITY }}
			</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updateAllowedQuantityModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="allowedQuantityForm" *ngIf="dropdownOptions">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PRODUCT_NAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							id="productName"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && allowedQuantityForm.controls.productGroup.errors }"
							formControlName="productGroup"
							[style.max-width.px]="400"
							[options]="dropdownOptions"
							[filter]="true"
							(onChange)="selectProductGroup($event)"
						></p-dropdown>

						<app-form-validation-error-msg
							*ngIf="submitted && allowedQuantityForm.controls.productGroup.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.PRODUCT_GROUP }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input type="text" class="form-control" formControlName="productGroup" id="productGroup" disabled />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.ALLOWED_QTTY }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="number"
							min="0"
							[max]="maxIntValue"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && allowedQuantityForm.controls.allowedQtty.errors }"
							formControlName="allowedQtty"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && allowedQuantityForm.controls.allowedQtty.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<p class="allowed-quantity-already-exists" *ngIf="allowedQuantityForm.value.id">
					{{ translate.ALLOWED_QUANTITY_ALREADY_EXISTS }}
				</p>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateAllowedQuantityModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="!allowedQuantityForm.dirty || observables.allowedQuantityLoading"
						(click)="submitAllowedQuantityForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>

	<!-- Delete Web User Confirm Modal -->
	<ng-template #deleteAllowedQuantityTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ translate.DELETE_ALLOWED_QUANTITY_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deleteAllowedQuantityModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.DELETE_ALLOWED_QUANTITY_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deleteAllowedQuantityModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button" (click)="deleteAllowedQuantity()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
