export class Service {
	id: number;
	manufacturer: string;
	productType: string;
	city: string;
	serviceName: string;
	address: string;
	phone: string;
	workHour: string;
	web: string;
	created: string;
	createdBy: string;
}
