<div class="vendorland-cont" *ngIf="translate && { vendorlandLoading: vendorlandLoading$ | async } as observables">
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<app-skeleton-screen
		*ngIf="observables.vendorlandLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<div *ngIf="!observables.vendorlandLoading">
		<p-table
			#vendorlandtable
			[value]="vendorland$ | async"
			[autoLayout]="true"
			class="vendorland-prime-table"
			[paginator]="true"
			[rows]="10"
			[(first)]="firstPageElementIndex"
			[rowsPerPageOptions]="[5, 10, 20, 50]"
			[globalFilterFields]="[
				'id',
				'customerId',
				'companyName',
				'customerName',
				'gameName',
				'time',
				'moves',
				'totalScore',
				'playedOn',
				'isWinner'
			]"
		>
			<ng-template pTemplate="caption">
				<div class="global-filter-cont">
					<fa-icon icon="search"></fa-icon>
					<input
						type="text"
						class="form-control"
						pInputText
						size="50"
						[placeholder]="'GLOBAL_FILTER' | translate"
						data-tid="globalFilter"
						(input)="vendorlandtable.filterGlobal($event.target.value, 'contains')"
					/>
				</div>
			</ng-template>

			<ng-template pTemplate="header">
				<tr>
					<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
						{{ translate.ID }}
						<p-sortIcon [field]="'id'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'customerId'">
						{{ translate.CUSTOMER_ID }}
						<p-sortIcon [field]="'customerId'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'companyName'">
						{{ translate.COMPANY_NAME }}
						<p-sortIcon [field]="'companyName'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'customerName'">
						{{ translate.CUSTOMER_NAME }}
						<p-sortIcon [field]="'customerName'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'gameName'">
						{{ translate.GAME_NAME }}
						<p-sortIcon [field]="'gameName'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'time'">
						{{ translate.TIME }}
						<p-sortIcon [field]="'time'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'moves'">
						{{ translate.MOVES }}
						<p-sortIcon [field]="'moves'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'totalScore'">
						{{ translate.TOTAL_SCORE }}
						<p-sortIcon [field]="'totalScore'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'playedOn'">
						{{ translate.PLAYED_ON }}
						<p-sortIcon [field]="'playedOn'"></p-sortIcon>
					</th>
					<th [pSortableColumn]="'isWinner'" class="text-center">
						{{ translate.IS_WINNER }}
						<p-sortIcon [field]="'isWinner'"></p-sortIcon>
					</th>
					<th [style.width.px]="200">&nbsp;</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-vendorland let-i="rowIndex">
				<tr>
					<td class="text-center">
						<b>{{ vendorland.id }}</b>
					</td>
					<td>{{ vendorland.customerId }}</td>
					<td>{{ vendorland.companyName }}</td>
					<td>{{ vendorland.customerName }}</td>
					<td>{{ vendorland.gameName }}</td>
					<td>{{ vendorland.time }}</td>
					<td>{{ vendorland.moves }}</td>
					<td>{{ vendorland.totalScore }}</td>
					<td>{{ vendorland.playedOn | date: 'dd.MM.yyyy' }}</td>
					<td class="text-center">
						<fa-icon icon="check" *ngIf="vendorland.isWinner"></fa-icon>
					</td>
					<td class="text-right">
						<button
							type="button"
							class="button button-gray"
							(click)="openVendorlandModal(updateVendorlandTemplate, vendorland)"
						>
							<fa-icon [icon]="faMedal" class="icon pr-1"></fa-icon>
							{{ vendorland.isWinner ? translate.CANCEL_WINNER : translate.DECLARE_WINNER }}
						</button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td [attr.colspan]="columns.length" class="empty-msg">
						{{ 'NO_DATA' | translate }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

	<!-- Update vendorland modal -->
	<ng-template #updateVendorlandTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span *ngIf="!currentVendorlandCustomer.isWinner">{{ translate.SET_WINNER_TITLE }}</span>
				<span *ngIf="currentVendorlandCustomer.isWinner">{{ translate.CANCEL_WINNER_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="updateVendorlandModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p *ngIf="!currentVendorlandCustomer.isWinner">{{ translate.SET_WINNER_BODY }}</p>
			<p *ngIf="currentVendorlandCustomer.isWinner">{{ translate.CANCEL_WINNER_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="updateVendorlandModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button" (click)="submitVendorlandForm()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</div>
