import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ServiceReply } from '../../shared/models/service-reply';
import * as ServiceReplyActions from './service-reply.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const serviceRepliesFeatureKey = 'serviceReplies';

export interface ServiceReplyState extends EntityState<ServiceReply> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ServiceReply> = createEntityAdapter<ServiceReply>();

export const initialState: ServiceReplyState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const serviceReplyReducer = createReducer(
	initialState,
	on(ServiceReplyActions.loadServiceReplies, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServiceReplyActions.loadServiceRepliesSuccess, (state, action) =>
		adapter.setAll(action.serviceReplies, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServiceReplyActions.loadServiceRepliesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ServiceReplyActions.addServiceReply, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServiceReplyActions.addServiceReplySuccess, (state, action) =>
		adapter.addOne(action.serviceReply, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServiceReplyActions.addServiceReplyFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ServiceReplyState | undefined, action: Action) {
	return serviceReplyReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
