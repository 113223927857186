import { productImagesFeatureKey, ProductImageState, selectAll } from './product-image.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductImageState = createFeatureSelector<ProductImageState>(productImagesFeatureKey);

export const selectAllProductImages = createSelector(selectProductImageState, selectAll);

export const selectProductImagesLoading = createSelector(
	selectProductImageState,
	state => state.statusState === LoadingState.LOADING
);

export const selectProductImagesError = createSelector(selectProductImageState, (state: ProductImageState) =>
	getError(state.statusState)
);
