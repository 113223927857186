import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { CustomerRecord } from 'src/app/shared/models/customer-record';

export const loadCustomerRecords = createAction('[Customers Page] Load Customer records');

export const loadCustomerRecordsSuccess = createAction(
	'[Customer Records API] Loaded Customer records Success',
	props<{ customerRecords: CustomerRecord[] }>()
);

export const loadCustomerRecordsFailure = createAction(
	'[Customer Records API] Loaded Customer records Failure',
	props<{ error: any }>()
);

export const addCustomerRecord = createAction(
	'[Customer Records Page] Add Customer Record',
	props<{ customerRecord: CustomerRecord }>()
);

export const addCustomerRecordSuccess = createAction(
	'[Customer Records API] Add CustomerRecord Success',
	props<{ customerRecord: CustomerRecord }>()
);

export const addCustomerRecordFailure = createAction(
	'[Customer Records API] Add Customer Record Failure',
	props<{ error: any }>()
);

export const updateCustomerRecord = createAction(
	'[Customer Records Page] Update Customer Record',
	props<{ customerRecord: Update<CustomerRecord> }>()
);

export const updateCustomerRecordSuccess = createAction(
	'[Customer Records API] Update CustomerRecord Success',
	props<{ customerRecord: Update<CustomerRecord> }>()
);

export const updateCustomerRecordFailure = createAction(
	'[Customer Records API] Update Customer Record Failure',
	props<{ error: any }>()
);

export const deleteCustomerRecord = createAction(
	'[Customer Records Page] Delete Customer Record',
	props<{ customerRecordId: number }>()
);

export const deleteCustomerRecordSuccess = createAction(
	'[Customer Records API] Delete Customer Record Success',
	props<{ customerRecordId: number }>()
);

export const deleteCustomerRecordFailure = createAction(
	'[Customer Records API] Delete Customer Record Failure',
	props<{ error: any }>()
);
