import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { GroupConnectionsComponent } from './group-connections.component';

@NgModule({
	declarations: [GroupConnectionsComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class GroupConnectionsModule {}
