import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MenuItem } from 'src/app/shared/models/menu-item';
import { MenuItemService } from '../services/menu-item.service';
import { forkJoin, Observable } from 'rxjs';
import { canAccessPage } from 'src/app/shared/utils/can-access-page';
import { AuthService } from '../authentication/auth.service';
import { environment } from 'src/environments/sr/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	@Input() showNavbar: string;
	translate: any;

	selectedNav: string;
	selectedSection: string;
	collapsedNav: string;

	menuItems: MenuItem[] = [];

	environment = environment;
	menu$: Observable<MenuItem[]>;
	menuLoading$: Observable<boolean>;

	constructor(
		private translateService: TranslateService,
		private menuItemsService: MenuItemService,
		private authService: AuthService,
		private router: Router
	) {}

	ngOnInit() {
		forkJoin([this.translateService.get('MENU'), this.menuItemsService.getMenuItems()]).subscribe(data => {
			this.translate = data[0];
			for (const menuItem of data[1]) {
				if (menuItem) {
					menuItem.title = this.translate[menuItem.title];

					// child items (first level)
					if (menuItem.childMenuItems) {
						menuItem.childMenuItems = menuItem.childMenuItems.filter(cmi =>
							canAccessPage(cmi.routerLink, this.authService)
						);

						for (const childMenuItem of menuItem.childMenuItems) {
							childMenuItem.title = childMenuItem.title ? this.translate[childMenuItem.title] : '';
						}
					}
				}
			}
			this.menuItems = data[1];
			this.selectedNav = this.menuItems.find(x =>
				x.childMenuItems?.find(y => y.routerLink === this.router.url)
			)?.selectedNav;
		});
	}
}
