import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Customer } from 'src/app/shared/models/customer';
import { map } from 'rxjs/operators';
import { CustomerExtraInfo } from 'src/app/shared/models/customer-extra-info';
import { deeperCopy } from 'src/app/shared/utils/deeper-copy';
import { CustomerFile } from 'src/app/shared/models/customer-file';
import { GuestBook } from 'src/app/shared/models/guest-book';
import { CustomerGroup } from 'src/app/shared/models/customer-group';

@Injectable()
export class CustomerService {
	constructor(private http: HttpClient) {}

	getCustomers(): Observable<Customer[]> {
		return this.http
			.get<Customer[]>(environment.baseApiUrl + 'api/Customer/list')
			.pipe(map(customers => customers.filter(customer => customer.no !== 'KU000000')));
	}

	getCustomerInfoById(customerId: string): Observable<Customer> {
		return forkJoin({
			customer: this.http.get<Customer>(environment.baseApiUrl + 'api/Customer/' + customerId),
			customerFiles: this.http.get<CustomerFile[]>(environment.baseApiUrl + 'api/Customer/files/' + customerId),
			customerExtraInfo: this.http.get<CustomerExtraInfo>(
				environment.baseApiUrl + 'api/Customer/extra-info/' + customerId
			),
		}).pipe(
			map(response => {
				const pom: Customer = deeperCopy(response.customer);
				pom.logo = response?.customerExtraInfo?.logo ? response.customerExtraInfo.logo : pom.logo;
				pom.customerFiles = response?.customerFiles ? response.customerFiles : pom.customerFiles;
				return pom;
			})
		);
	}

	updateCustomerExtraInfo(customer: Customer) {
		return this.http.put<Customer>(environment.baseApiUrl + 'api/Customer/extra-info', customer);
	}

	removeCustomerLogo(customer: Customer) {
		return this.http.delete<Customer>(environment.baseApiUrl + 'api/Customer/extra-info/' + customer.no);
	}

	createCustomerFile(files: any): Observable<CustomerFile[]> {
		return this.http.post<CustomerFile[]>(environment.baseApiUrl + 'api/Customer/files', files);
	}

	removeCustomerFile(id: number) {
		return this.http.delete<Customer>(environment.baseApiUrl + 'api/Customer/files/' + id);
	}

	getGuestBook(): Observable<GuestBook[]> {
		return this.http.get<GuestBook[]>(environment.baseApiUrl + 'api/Customer/guestbook-items');
	}

	/**
	 * Returns a list of all customer groups
	 */
	getCustomerGroups(): Observable<CustomerGroup[]> {
		return this.http.get<CustomerGroup[]>(environment.baseApiUrl + 'api/Customer/groups');
	}
}
