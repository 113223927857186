<div class="login-cont" *ngIf="translate && !environment.isOAuthEnabled">
	<h3>{{ translate.LOGIN_TITLE }}</h3>
	<div class="login-box">
		<form [formGroup]="loginForm" (ngSubmit)="login()">
			<div class="row mb-3">
				<div class="col-12">
					<label class="mb-1">{{ translate.USERNAME }}:</label>
					<input
						type="text"
						class="form-control"
						formControlName="username"
						[ngClass]="{ 'is-invalid': wrongFields || (missingFields && !user.username) }"
					/>
					<span class="red" *ngIf="missingFields && !user.username">{{ translate.FIELD_REQUIRED }}</span>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-12">
					<label class="mb-1">{{ translate.PASSWORD }}:</label>
					<input
						type="password"
						class="form-control"
						formControlName="password"
						[ngClass]="{ 'is-invalid': wrongFields || (missingFields && !user.password) }"
					/>
					<span class="red" *ngIf="missingFields && !user.password">{{ translate.FIELD_REQUIRED }}</span>
				</div>
			</div>
			<div class="row" *ngIf="wrongFields || missingFields">
				<div class="col-12">
					<div class="alert alert-danger">
						{{ wrongFields ? translate.WRONG_INPUTS : translate.MISSING_FIELDS }}
					</div>
				</div>
			</div>
			<div class="row py-2">
				<div class="col-12">
					<button class="btn btn-danger w-100" type="submit">{{ translate.LOGIN }}</button>
				</div>
			</div>
		</form>
	</div>
</div>
