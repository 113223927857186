import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromExchangeRates from './exchange-rates.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ExchangeRateEffects } from './exchange-rate.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromExchangeRates.exchangeRatesFeatureKey, fromExchangeRates.reducer),
		EffectsModule.forFeature([ExchangeRateEffects]),
	],
})
export class ExchangeRatesStoreModule {}
