import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Target } from '../../shared/models/target';

export const loadTargets = createAction('[Targets Page] Load Targets', props<{ startDate: Date; endDate: Date }>());

export const loadTargetsSuccess = createAction('[Targets API] Loaded Targets Success', props<{ targets: Target[] }>());

export const loadTargetsFailure = createAction('[Targets API] Loaded Targets Failure', props<{ error: any }>());

export const addTarget = createAction('[Targets Page] Add Target', props<{ target: Target }>());

export const addTargetSuccess = createAction('[Targets API] Add Target Success', props<{ target: Target }>());

export const addTargetFailure = createAction('[Targets API] Add Target Failure', props<{ error: any }>());

export const updateTarget = createAction('[Target Page] Update Target', props<{ target: Update<Target> }>());

export const updateTargetSuccess = createAction(
	'[Target API] Update Target Success',
	props<{ target: Update<Target> }>()
);

export const updateTargetFailure = createAction('[Target API] Update Target Failure', props<{ error: any }>());

export const setEmptyResultFlag = createAction(
	'[Target Page] Set Empty Result Flag',
	props<{ emptyResultFlag: boolean }>()
);
