import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServiceState, servicesFeatureKey, selectAll } from './service.reducer';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectServiceState = createFeatureSelector<ServiceState>(servicesFeatureKey);

export const selectService = createSelector(selectServiceState, selectAll);

export const selectServiceLoading = createSelector(
	selectServiceState,
	(state: ServiceState) => state.statusState === LoadingState.LOADING
);
export const selectServiceError = createSelector(selectServiceState, (state: ServiceState) =>
	getError(state.statusState)
);
