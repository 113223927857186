import { Pipe, PipeTransform } from '@angular/core';
import { normalizeText } from 'normalize-text';
@Pipe({
	name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (!value) return null;
		if (!args) return value;
		const argsNormalized = normalizeText(args.toLowerCase());
		const keys = Object.keys(value[0]);

		for (const element of value) {
			element.normalizedData = {};
			for (const key of keys) {
				element.normalizedData[key] = normalizeText([element[key]]);
			}
		}
		return value.filter(data => {
			return JSON.stringify(data.normalizedData).toLowerCase().includes(argsNormalized);
		});
	}
}
