import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCustomerRecord from './customer-record.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerRecordEffects } from './customer-record.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromCustomerRecord.customerRecordsFeatureKey, fromCustomerRecord.reducer),
		EffectsModule.forFeature([CustomerRecordEffects]),
	],
})
export class CustomerRecordsStoreModule {}
