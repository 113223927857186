import { manufacturersFilteredFeatureKey, ManufacturerFilteredState, selectAll } from './manufacturer-filtered.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { ManufacturerFiltered } from 'src/app/shared/models/manufacturer-filtered';

export const selectManufacturerFilteredState = createFeatureSelector<ManufacturerFilteredState>(
	manufacturersFilteredFeatureKey
);

export const selectManufacturersFiltered = createSelector(selectManufacturerFilteredState, selectAll);

export const selectManufacturersFilteredOnAction = createSelector(
	selectManufacturersFiltered,
	(manufacturers: ManufacturerFiltered[], params) =>
		manufacturers.filter(
			manufacturer =>
				manufacturer.customerId === params.customerId && manufacturer.productGroup === params.productGroupId
		)
);

export const selectManufacturersLoading = createSelector(
	selectManufacturerFilteredState,
	state => state.statusState === LoadingState.LOADING
);

export const selectManufacturersError = createSelector(
	selectManufacturerFilteredState,
	(state: ManufacturerFilteredState) => getError(state.statusState)
);
