import { createAction, props } from '@ngrx/store';

import { Feature } from '../../shared/models/feature';
import { Update } from '@ngrx/entity';

export const loadFeatures = createAction('[Features Page] Load Features');

export const loadFeaturesSuccess = createAction(
	'[Features API] Loaded Features Success',
	props<{ features: Feature[] }>()
);

export const loadFeaturesFailure = createAction('[Features API] Loaded Features Failure', props<{ error: any }>());

export const addFeature = createAction('[Features Page] Add Feature', props<{ feature: Feature }>());

export const addFeatureSuccess = createAction('[Features API] Add Feature Success', props<{ feature: Feature }>());

export const addFeatureFailure = createAction('[Features API] Add Feature Failure', props<{ error: any }>());

export const updateFeature = createAction('[Features Page] Update Feature', props<{ feature: Update<Feature> }>());

export const updateFeatureSuccess = createAction(
	'[Features API] Update Feature Success',
	props<{ feature: Update<Feature> }>()
);

export const updateFeatureFailure = createAction('[Features API] Update Feature Failure', props<{ error: any }>());
