export class ViberPromotion {
	id: number;
	name: string;
	products: string;
	url: string;
	imageUrl: string;
	imageFile: string | ArrayBuffer;
	updateDate: Date;
	activeSrb: boolean;
	activeMne: boolean;
	activeBih: boolean;
	activeMak: boolean;
	activeCro: boolean;
	activeSlo: boolean;
	orderNumber: number;
	fromDate: Date;
	toDate: Date;
	createdBy: string;
}
