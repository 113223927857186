import { createAction, props } from '@ngrx/store';
import { Customer } from 'src/app/shared/models/customer';
import { CustomerFile } from 'src/app/shared/models/customer-file';

export const loadCustomers = createAction('[Customers Page] Load Customers');

export const loadCustomersSuccess = createAction(
	'[Customers API] Loaded Customers Success',
	props<{ customers: Customer[] }>()
);

export const loadCustomersFailure = createAction('[Customers API] Loaded Customers Failure', props<{ error: any }>());

export const loadCustomerInfoById = createAction(
	'[Customers Page] Load Customer Info By Id',
	props<{ customer: Customer }>()
);

export const loadCustomerInfoByIdSuccess = createAction(
	'[Customers API] Loaded Customer Info By Id Success',
	props<{ customer: Customer }>()
);

export const loadCustomerInfoByIdFailure = createAction(
	'[Customers API] Loaded Customer Info By Id Failure',
	props<{ error: any }>()
);
export const updateCustomerExtraInfo = createAction(
	'[Customers Page] Upload Customer Extra Info',
	props<{ customer: Customer }>()
);

export const updateCustomerExtraInfoSuccess = createAction(
	'[Customers API] Loaded Customer Info By Id Success',
	props<{ customer: Customer }>()
);

export const updateCustomerExtraInfoFailure = createAction(
	'[Customers API] Loaded Customer Info By Id Failure',
	props<{ error: any }>()
);
export const removeCustomerLogo = createAction(
	'[Customers Page] Remove Customer Logo',
	props<{ customer: Customer }>()
);

export const removeCustomerLogoSuccess = createAction(
	'[Customers API] Remove Customer Logo By Id Success',
	props<{ customer: Customer }>()
);

export const removeCustomerLogoFailure = createAction(
	'[Customers API] Remove Customer Logo By Id Failure',
	props<{ error: any }>()
);

export const removeCustomerFile = createAction(
	'[Customers Page] Remove Customer File',
	props<{ customer: Customer; file: CustomerFile }>()
);

export const removeCustomerFileSuccess = createAction(
	'[Customers API] Remove Customer File Success',
	props<{ customer: Customer }>()
);

export const removeCustomerFileFailure = createAction(
	'[Customers API] Remove Customer File Failure',
	props<{ error: any }>()
);
export const addCustomerFile = createAction(
	'[Customers Page] Add Customer File',
	props<{ customer: Customer; files: CustomerFile[] }>()
);

export const addCustomerFileSuccess = createAction(
	'[Customers API] Add Customer File Success',
	props<{ customer: Customer }>()
);

export const addCustomerFileFailure = createAction(
	'[Customers API] Add Customer File Failure',
	props<{ error: any }>()
);
