import { createAction, props } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { Administrator } from '../../shared/models/administrator';

export const loadAdministrators = createAction('[Administrator Page] Load Administrators');

export const loadAdministratorsSuccess = createAction(
	'[Administrators API] Loaded Administrators Success',
	props<{ administrators: Administrator[] }>()
);

export const loadAdministratorsFailure = createAction(
	'[Administrators API] Loaded Administrators Failure',
	props<{ error: any }>()
);

export const loadCurrentAdministratorDetails = createAction('[My Profile Page] Load Current Administrator Details');

export const loadCurrentAdministratorDetailsSuccess = createAction(
	'[Administrators API] Loaded Current Administrator Details Success',
	props<{ administrator: Administrator }>()
);

export const loadCurrentAdministratorDetailsFailure = createAction(
	'[Administrators API] Loaded Current Administrator Details Failure',
	props<{ error: any }>()
);

export const addAdministrator = createAction(
	'[Administrators Page] Add Administrator',
	props<{ administrator: Administrator }>()
);

export const addAdministratorSuccess = createAction(
	'[Administrators API] Add Administrator Success',
	props<{ administrator: Administrator }>()
);

export const addAdministratorFailure = createAction(
	'[Administrators API] Add Administrator Failure',
	props<{ error: any }>()
);

export const updateAdministrator = createAction(
	'[Administrators Page] Update Administrator',
	props<{ administrator: Update<Administrator> }>()
);

export const updateAdministratorSuccess = createAction(
	'[Administrators API] Update Administrator Success',
	props<{ administrator: Update<Administrator> }>()
);

export const updateAdministratorFailure = createAction(
	'[Administrators API] Update Administrator Failure',
	props<{ error: any }>()
);
