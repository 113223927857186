import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './core/login/login.component';

import { HomeComponent } from './home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PromotionsComponent } from './promotions/promotions.component';
import { ProductsComponent } from './products/products.component';
import { PromotionSliderComponent } from './promotion-slider/promotion-slider.component';
import { SellersComponent } from './sellers/sellers.component';
import { AdministratorsComponent } from './administrators/administrators.component';
import { WebServiceUsersComponent } from './web-service-users/web-service-users.component';
import { UserProductAssignComponent } from './user-product-assign/user-product-assign.component';
import { AdditionalSpecificationComponent } from './additional-specification/additional-specification.component';
import { GroupComponent } from './groups/groups.component';
import { AllowedQuantityComponent } from './allowed-quantity/allowed-quantity.component';
import { VendorlandComponent } from './vendorland/vendorland.component';
import { ServiceReportsComponent } from './service-reports/service-reports.component';
import { PricelistsComponent } from './pricelists/pricelists.component';
import { CustomersComponent } from './customers/customers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorComponent } from './core/error/error.component';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { GroupConnectionsComponent } from './group-connections/group-connections.component';
import { TargetsComponent } from './targets/targets.component';
import { ProductGroupsComponent } from './product-groups/product-groups.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { InactiveUsersComponent } from './inactive-users/inactive-users.component';
import { ViberPromotionsComponent } from './viber-promotions/viber-promotions.component';
import { GuestBookComponent } from './guest-book/guest-book.component';
import { SpecialPriceComponent } from './special-price/special-price.component';
import { RegisteredServiceComponent } from './registered-service/registered-service.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
		canActivate: [AuthGuard],
		data: {
			title: 'Home',
			description: 'Welcome to ct4admin home page',
		},
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Home',
			description: 'Welcome to ct4admin home page',
		},
	},
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'Login',
			description: 'Welcome to ct4admin Login page',
		},
	},
	{
		path: 'promotions',
		component: PromotionsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Promotions',
			description: 'Welcome to ct4admin promotions page',
		},
	},
	{
		path: 'product-configuration',
		component: ProductsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Product Configuration',
			description: 'Welcome to ct4admin Product Configuration page',
		},
	},
	{
		path: 'promotion-slider',
		component: PromotionSliderComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Promotion Slider',
			description: 'Welcome to ct4admin Promotion Slider page',
		},
	},
	{
		path: 'sellers',
		component: SellersComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Sellers',
			description: 'Welcome to ct4admin Sellers page',
		},
	},
	{
		path: 'targets',
		component: TargetsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Targets',
			description: 'Welcome to ct4admin Targets page',
		},
	},
	{
		path: 'administrators',
		component: AdministratorsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Administrators',
			description: 'Welcome to ct4admin Administrators page',
		},
	},
	{
		path: 'service-users',
		component: WebServiceUsersComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Service Users',
			description: 'Welcome to ct4admin Service Users page',
		},
	},
	{
		path: 'product-assign',
		component: UserProductAssignComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Product Assign',
			description: 'Welcome to ct4admin Product Assign page',
		},
	},
	{
		path: 'easy-web',
		component: SpecialPriceComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Easy WEB',
			description: 'Welcome to ct4admin Easy web page',
		},
	},
	{
		path: 'additional-specification',
		component: AdditionalSpecificationComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Additional Specification',
			description: 'Welcome to ct4admin Additional Specification page',
		},
	},
	{
		path: 'groups',
		component: GroupComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Groups',
			description: 'Welcome to ct4admin Groups page',
		},
	},
	{
		path: 'allowed-quantity',
		component: AllowedQuantityComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Allowed Quantity',
			description: 'Welcome to ct4admin Allowed Quantity page',
		},
	},
	{
		path: 'vendorland',
		component: VendorlandComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Vendorland',
			description: 'Welcome to ct4admin Vendorland page',
		},
	},
	{
		path: 'service-reports',
		component: ServiceReportsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Service Reports',
			description: 'Welcome to ct4admin Service Reports page',
		},
	},
	{
		path: 'registered-service',
		component: RegisteredServiceComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Registered Service',
			description: 'Welcome to ct4admin Registered Service page',
		},
	},
	{
		path: 'pricelists',
		component: PricelistsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Price Lists',
			description: 'Welcome to ct4admin Price Lists page',
		},
	},
	{
		path: 'customers',
		component: CustomersComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Customers',
			description: 'Welcome to ct4admin Customers page',
		},
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Dashboard',
			description: 'Welcome to ct4admin Dashboard page',
		},
	},
	{
		path: 'user-logs',
		component: UserLogsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'User Logs',
			description: 'Welcome to ct4admin User Logs page',
		},
	},
	{
		path: 'group-connections',
		component: GroupConnectionsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Group Connections',
			description: 'Welcome to ct4admin Group Connections page',
		},
	},
	{
		path: 'product-groups',
		component: ProductGroupsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Product Groups',
			description: 'Welcome to ct4admin Product Groups page',
		},
	},
	{
		path: 'my-profile',
		component: MyProfileComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'My Profile',
			description: 'Welcome to ct4admin My Profile page',
		},
	},
	{
		path: 'inactive-users',
		component: InactiveUsersComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Inactive Users',
			description: 'Welcome to ct4admin Inactive Users page',
		},
	},
	{
		path: 'viber-promotions',
		component: ViberPromotionsComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Viber Promotions',
			description: 'Welcome to ct4admin Viber Promotions page',
		},
	},
	{
		path: 'guest-book',
		component: GuestBookComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Guest Book',
			description: 'Welcome to ct4admin Guest Book page',
		},
	},
	{
		path: 'error',
		component: ErrorComponent,
		data: {
			title: 'Error',
			description: 'Welcome to ct4admin Error page',
		},
	},
	{
		path: '**',
		component: HomeComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Home',
			description: 'Welcome to ct4admin Home page',
		},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
