import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { GuestBook } from '../shared/models/guest-book';
import { GuestBookState } from '../root-store/guest-book-store/guest-book.reducer';
import * as guestBookSelectors from '../root-store/guest-book-store/guest-book.selector';
import * as guestBookActions from '../root-store/guest-book-store/guest-book.actions';
import { takeUntil } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import { exportAsExcel } from '../shared/utils/export-to-excel';
import { dateRangeFilter } from '../shared/utils/date-range-filter';
import { FilterUtils } from 'primeng/utils';
import { FeedbackCategories } from '../shared/enums/feedback-categories';
import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'app-guest-book',
	templateUrl: './guest-book.component.html',
	styleUrls: ['./guest-book.component.scss'],
})
export class GuestBookComponent implements OnInit, OnDestroy {
	translate: any;
	ngUnsubscribe: Subject<object> = new Subject();
	guestBook$: Observable<GuestBook[]>;
	guestBookLoading$: Observable<boolean>;
	guestBook: GuestBook[];

	dropdownOptionsCustomerID = [];
	selectedCustomerID: string;

	dropdownOptionsCustomerName = [];
	selectedCustomerName: string;

	dropdownOptionsCompanyName = [];
	selectedCompanyName: string;

	columns: any[] = [
		{ name: 'categoryName', title: 'FEEDBACK_TYPE', width: 40 },
		{ name: 'userID', title: 'CUSTOMER_ID', width: 20 },
		{ name: 'userName', title: 'CUSTOMER', width: 80 },
		{ name: 'companyName', title: 'COMPANY_NAME', width: 10 },
		{ name: 'companyPIB', title: 'PIB', width: 10 },
		{ name: 'logDate', title: 'DATE', width: 10 },
		{ name: 'comment', title: 'COMMENT', width: 10 },
	];
	rangeDates: Date[];
	feedbackCategories = Object.values(FeedbackCategories);

	constructor(
		private translateService: TranslateService,
		private store: Store<GuestBookState>,
		private datePipe: DatePipe
	) {
		const rangeFilter = 'dateRangeFilter';
		FilterUtils[rangeFilter] = (value: any): boolean => {
			return dateRangeFilter(value, this.rangeDates);
		};
	}

	ngOnInit() {
		this.translateService.get('GUEST_BOOK').subscribe((resp: any) => {
			this.translate = resp;
		});

		this.guestBook$ = this.store.select(guestBookSelectors.selectAllGuestBookItems);
		this.guestBookLoading$ = this.store.select(guestBookSelectors.selectGuestBookItemsLoading);
		this.store
			.select(guestBookSelectors.selectAllGuestBookItems)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(guestBook => {
				if (!guestBook.length) {
					this.store.dispatch(guestBookActions.loadGuestBookItems());
				} else {
					this.guestBook = cloneDeep(guestBook);
					for (const element of this.guestBook) {
						this.dropdownOptionsCustomerID.push({ label: element.userID, value: element.userID });
						this.dropdownOptionsCustomerName.push({ label: element.userName, value: element.userName });
						this.dropdownOptionsCompanyName.push({ label: element.companyName, value: element.companyName });
						// Format guestbook category name
						element.categoryName = this.translate.OPTIONS[this.feedbackCategories[element.category]];
					}

					this.dropdownOptionsCustomerID = this.removeDuplicates(this.dropdownOptionsCustomerID, 'label');
					this.dropdownOptionsCustomerName = this.removeDuplicates(this.dropdownOptionsCustomerName, 'label');
					this.dropdownOptionsCompanyName = this.removeDuplicates(this.dropdownOptionsCompanyName, 'label');

					this.dropdownOptionsCustomerID.unshift({ label: this.translate.SELECT_CUSTOMER_ID, value: null });
					this.dropdownOptionsCustomerName.unshift({ label: this.translate.SELECT_CUSTOMER_NAME, value: null });
					this.dropdownOptionsCompanyName.unshift({ label: this.translate.SELECT_COMPANY_NAME, value: null });
				}
			});
	}

	removeDuplicates(originalArray, prop) {
		const newArray = originalArray.filter((v, i) => originalArray.findIndex(item => item[prop] === v[prop]) === i);
		return newArray;
	}

	exportToExcel() {
		const header = [
			this.translate.FEEDBACK_TYPE,
			this.translate.CUSTOMER_ID,
			this.translate.CUSTOMER,
			this.translate.COMPANY_NAME,
			this.translate.PIB,
			this.translate.DATE,
			this.translate.COMMENT,
		];

		const data = this.guestBook.filter(
			x =>
				(this.selectedCustomerName ? x.userName === this.selectedCustomerName : x.userName) &&
				(this.selectedCustomerID ? this.selectedCustomerID === x.userID : x.userID) &&
				(this.selectedCompanyName ? this.selectedCompanyName === x.companyName : x.companyName) &&
				(this.rangeDates && this.rangeDates[1]
					? formatDate(x.logDate, 'yyyy-MM-dd', 'en_US') <= formatDate(this.rangeDates[1], 'yyyy-MM-dd', 'en_US')
					: x.logDate) &&
				(this.rangeDates && this.rangeDates[0]
					? formatDate(x.logDate, 'yyyy-MM-dd', 'en_US') >= formatDate(this.rangeDates[0], 'yyyy-MM-dd', 'en_US')
					: x.logDate)
		);

		const exportData = [];
		for (const element of data) {
			exportData.push({
				[this.translate.FEEDBACK_TYPE]: element.categoryName,
				[this.translate.CUSTOMER_ID]: element.userID,
				[this.translate.CUSTOMER]: element.userName,
				[this.translate.COMPANY_NAME]: element.companyName,
				[this.translate.PIB]: element.companyPIB,
				[this.translate.DATE]: this.datePipe.transform(element.logDate, 'dd.MM.yyyy'),
				[this.translate.COMMENT]: element.comment,
			});
		}
		exportAsExcel(exportData, this.translate.TITLE, this.translate.TITLE, header);
	}

	clear() {
		this.rangeDates = null;
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
