import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalSpecificationService } from '../core/services/additional-specification.service';
import { ToastrService } from 'ngx-toastr';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProductDataState } from '../root-store/product-data-store/product-data.reducer';
import * as productDataActions from '../root-store/product-data-store/product-data.actions';
import * as productDataSelectors from '../root-store/product-data-store/product-data.selectors';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-additional-specification',
	templateUrl: './additional-specification.component.html',
	styleUrls: ['./additional-specification.component.scss'],
})
export class AdditionalSpecificationComponent implements OnInit {
	ngUnsubscribe: Subject<object> = new Subject();
	productInputField = new Subject<string>();

	product$: Observable<string[]>;
	productDataLoading$: Observable<boolean>;
	itemSearch = '';
	productId = '';
	results: string[];
	noResultsTranslate: string;
	noResultsMessage: string;

	activeIndex: number;

	productNoSelected: boolean;

	translate: any;
	translateSuccessMsgs: any;
	translateErrorMsgs: any;
	addSpecForm: any;
	uploadFile: any = null;
	uploading: boolean = null;
	fileName: string = null;
	fileUploaded: boolean = null;
	fileString: string = null;
	product = '';

	constructor(
		private translateService: TranslateService,
		private toastr: ToastrService,
		private productDataStore: Store<ProductDataState>,
		private specService: AdditionalSpecificationService
	) {}

	ngOnInit() {
		this.translateService.get('ADDITIONAL_SPECIFICATION').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('SUCCESS').subscribe((resp: any) => (this.translateSuccessMsgs = resp));
		this.translateService.get('NO_DATA').subscribe((resp: string) => (this.noResultsTranslate = resp));

		this.translateService
			.get('ERROR.FILE_RESTRICTIONS.FILE')
			.subscribe((resp: any) => (this.translateErrorMsgs = resp));

		this.productInputField = new Subject<string>();
		this.productDataStore.dispatch(
			productDataActions.resetSearchData({
				searchItem: '',
				productData: null,
			})
		);

		this.itemSearch = '';

		// !TODO - Make live search a separate component in due time

		this.product$ = this.productInputField.pipe(
			takeUntil(this.ngUnsubscribe),
			debounceTime(250),
			distinctUntilChanged(),
			filter((s: string) => s.length > 2),
			switchMap(searchString =>
				this.productDataStore.select(productDataSelectors.selectFilteredProductData, {
					searchString: searchString.toLowerCase(),
				})
			)
		);

		this.product$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(products => {
			if (products) {
				if (products.length) {
					this.results = [...products];
					this.noResultsMessage = '';
				} else {
					this.results = [];
					this.noResultsMessage = this.noResultsTranslate;
					this.product = null;
				}
			} else {
				this.productDataStore.dispatch(
					productDataActions.loadProductDataFiltered({
						searchItem: this.itemSearch.substr(0, 3),
						savedSearchItem: this.itemSearch.toLowerCase().substr(0, 3),
					})
				);
			}
		});
	}

	forceReferenceReload() {
		if (this.results) {
			this.results = [...this.results];
		}
		if (this.itemSearch?.length === 0) {
			this.results = [];
			this.noResultsMessage = '';
		}
	}

	filterProductList(productSearch) {
		this.productInputField.next(productSearch.query);
	}

	selectProduct() {
		this.product = this.itemSearch;
	}

	onFileChange(event) {
		this.fileUploaded = false;
		this.fileName = null;
		this.fileString = null;
		if (event.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = () => {
				const file = event.target.files[0];
				this.fileString = fileToBase64(reader);
				if (file.type === 'application/pdf' && file.size <= 5242880) {
					this.fileName = file.name;
					if (this.fileName.length > 24) {
						this.fileName =
							this.fileName.substr(0, 10) +
							'...' +
							this.fileName.substr(this.fileName.length - 10, this.fileName.length);
					}
					this.fileUploaded = true;
				} else if (file.type === 'application/pdf' && file.size > 5242880) {
					this.fileUploaded = null;
					this.toastr.error(this.translateErrorMsgs.FILE_TOO_LARGE);
				} else {
					this.fileUploaded = null;
					this.toastr.error(this.translateErrorMsgs.ONLY_PDF_FILES);
				}
			};
		}
	}

	onSubmit() {
		this.uploading = true;
		this.specService
			.addSpecification({ productID: this.product, pdfFile: this.fileString })
			.subscribe((result: boolean) => {
				this.uploading = false;
				if (result) {
					this.fileUploaded = false;
					this.fileName = null;
					this.fileString = null;
					this.toastr.success(this.translateSuccessMsgs.FORM_SUBMIT);
				} else {
					this.toastr.error(this.translateErrorMsgs.SOMETHING_WENT_WRONG);
				}
			});
	}
}
