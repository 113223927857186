<div *ngIf="{ widgetsLoading: widgetsLoading$ | async } as observables">
	<div
		*ngIf="observables.widgetsLoading"
		class="spinner-border small"
		style="position: absolute; right: 50%; top: 40%"
	></div>
	<div *ngIf="!observables.widgetsLoading">
		<div class="homepage-cont" *ngIf="isAuthenticated && translate">
			<div class="content">
				<div class="row">
					<div class="col-12 page-title mb-5">
						<h2>{{ translate.WELCOME }}, {{ username }}</h2>
						<p>{{ translate.WELCOME_TO_DASHBOARD }}</p>
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-lg-3">
						<div class="block block-rounded homepage-widget-block">
							<span class="first-widget-bar"></span>
							<div class="homepage-widget-bar">
								<div class="font-size-h2">
									{{ widgets.numberOfPendingAndOrdersToday.numberOfPendingOrders }}
								</div>
								<p class="font-w600 font-size-sm">{{ translate.PENDING_ORDERS }}</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-3">
						<div class="block block-rounded text-xs-center homepage-widget-block">
							<span class="second-widget-bar"> </span>
							<div class="homepage-widget-bar">
								<div class="font-size-h2">
									{{
										environment.defaultFinancialCurrency +
											' ' +
											(widgets.numberOfPendingAndOrdersToday.pendingRevenue | number: '1.2-2')
									}}
								</div>
								<p class="font-w600 font-size-sm">{{ translate.PENDING_REVENUE }}</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-3">
						<div class="block block-rounded text-xs-center homepage-widget-block">
							<span class="third-widget-bar"> </span>
							<div class="homepage-widget-bar">
								<div class="font-size-h2">
									{{ widgets.numberOfPendingAndOrdersToday.numberOfOrdersToday }}
								</div>
								<p class="font-w600 font-size-sm">{{ translate.ORDERS_TODAY }}</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-3">
						<div class="block block-rounded text-xs-center homepage-widget-block">
							<span class="fourth-widget-bar"> </span>
							<div class="homepage-widget-bar">
								<div class="font-size-h2">
									{{
										environment.defaultFinancialCurrency +
											' ' +
											(widgets.numberOfPendingAndOrdersToday.earningsToday | number: '1.2-2')
									}}
								</div>
								<p class="font-w600 font-size-sm">{{ translate.EARNINGS_TODAY }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="block block-rounded">
					<div class="block-header">
						<h3 class="block-title">{{ translate.ORDERS_OVERVIEW }}</h3>
					</div>
					<div>
						<div style="height: 400px">
							<p-chart type="bar" [data]="data" [options]="options" height="400"></p-chart>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-6">
						<div class="block block-rounded top-products-table">
							<div class="block-header">
								<h3 class="block-title">{{ translate.TOP_PRODUCTS }}</h3>
							</div>
							<div>
								<table class="table table-borderless table-striped table-vcenter font-size-sm">
									<thead>
										<tr>
											<th>{{ translate.PRODUCT_CODE }}</th>
											<th>{{ translate.PRODUCT_NAME }}</th>
											<th>{{ translate.QUANTITY }}</th>
											<th>{{ translate.PRICE }}</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let element of widgets.topPurchasedProducts">
											<td class="text-xs-center">
												<span class="font-w600">{{ element.id }}</span>
											</td>
											<td>
												<span>{{ element.description }}</span>
											</td>
											<td class="text-xs-right">
												{{ element.numberOfPurchases }}
											</td>
											<td class="text-xs-right">
												{{ environment.defaultFinancialCurrency + ' ' + (element.averagePrice | number: '1.2-2') }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col-xl-6">
						<div class="block block-rounded latest-orders-table">
							<div class="block-header">
								<h3 class="block-title">{{ translate.LATEST_ORDERS }}</h3>
							</div>
							<div>
								<table class="table table-borderless table-striped table-vcenter font-size-sm">
									<thead>
										<tr>
											<th>{{ translate.ID }}</th>
											<th>{{ translate.USER_NAME }}</th>
											<th>{{ translate.STATUS }}</th>
											<th>{{ translate.PRICE }}</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let element of widgets.latestOrders">
											<td>
												<span>{{ element.id }}</span>
											</td>
											<td>
												<span>{{ element.name }}</span>
											</td>
											<td>
												<ng-container *ngIf="element.orderStatus === 0; else elseBlock">
													<span class="tag tag-pill processing-status">{{ translate.PROCESSING }}</span>
												</ng-container>
												<ng-template #elseBlock>
													<span class="tag tag-pill delivered-status">{{ translate.TAKEN_OVER_BY_NAVISION }}</span>
												</ng-template>
											</td>
											<td class="text-xs-right">{{ element.currency + ' ' + (element.price | number: '1.2-2') }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
