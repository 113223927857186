<div class="page-title mb-5">
	<h2>{{ translate.TITLE }}</h2>
	<p>{{ translate.PAGE_DESCRIPTION }}</p>
</div>
<div class="year-dropdown">
	<p-dropdown
		[options]="years"
		optionLabel="name"
		[(ngModel)]="selectedYear"
		(onChange)="loadDataForSelectedYear($event.value)"
	></p-dropdown>
</div>

<div *ngIf="!(dashboardLoading$ | async); else loader">
	<div class="row" *ngIf="visitsChartData && visitsChartData.labels.length; else noData">
		<div class="col-lg-6 p-3">
			<div class="dashboard-chart">
				<app-number-of-visits-chart *ngIf="visitsChartData" [data]="visitsChartData"> </app-number-of-visits-chart>
			</div>
		</div>
		<div class="col-lg-6 p-3">
			<div class="dashboard-chart">
				<app-browser-usage-chart *ngIf="browserChartData" [data]="browserChartData"> </app-browser-usage-chart>
			</div>
		</div>
		<div class="col-lg-6 p-3">
			<div class="dashboard-chart">
				<app-device-usage-chart *ngIf="deviceChartData" [data]="deviceChartData"> </app-device-usage-chart>
			</div>
		</div>
		<div class="col-lg-6 p-3">
			<div class="dashboard-chart">
				<app-operating-systems-usage-chart *ngIf="osChartData" [data]="osChartData">
				</app-operating-systems-usage-chart>
			</div>
		</div>
	</div>
</div>
<ng-template #loader>
	<div class="center">
		<p-progressSpinner></p-progressSpinner>
	</div>
</ng-template>
<ng-template #noData>
	<div class="center">
		<p>{{ 'NO_DATA' | translate }}</p>
	</div>
</ng-template>
