import {
	productAdditionalTypesFeatureKey,
	ProductAdditionalTypeState,
	selectAll,
} from './product-additional-types.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from '../status-store/status.reducer';

export const selectProductAdditionalTypesState = createFeatureSelector<ProductAdditionalTypeState>(
	productAdditionalTypesFeatureKey
);

export const selectProductAdditionallTypesLoading = createSelector(
	selectProductAdditionalTypesState,
	state => state.statusState === LoadingState.LOADING
);

export const selectAllProductAdditionalTypes = createSelector(selectProductAdditionalTypesState, selectAll);
