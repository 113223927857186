import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Permissions } from '../shared/enums/permissions.enum';
import { takeUntil, debounceTime, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { ProductDataState } from '../root-store/product-data-store/product-data.reducer';
import * as productDataActions from '../root-store/product-data-store/product-data.actions';
import * as productDataSelectors from '../root-store/product-data-store/product-data.selectors';
import { AuthService } from '../core/authentication/auth.service';
import { ProductImagesComponent } from './product-images/product-images.component';
import { ProductFilesComponent } from './product-files/product-files.component';
import { ProductLinksComponent } from './product-links/product-links.component';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
	ngUnsubscribe: Subject<object> = new Subject();
	productInputField = new Subject<string>();
	perms = {};
	translate: any;

	product$: Observable<string[]>;
	productDataLoading$: Observable<boolean>;
	itemSearch = '';
	productId = '';
	results: string[];
	noResultsTranslate: string;
	noResultsMessage: string;

	title: string;

	activeIndex: number;

	productNoSelected: boolean;

	@ViewChild(ProductImagesComponent) productImagesComponent: ProductImagesComponent;
	@ViewChild(ProductFilesComponent) productFilesComponent: ProductFilesComponent;
	@ViewChild(ProductLinksComponent) productLinksComponent: ProductLinksComponent;

	previousSearches: string[] = [];
	showPreviousSearches = false;
	noPreviousSearches = true;

	@HostListener('document:click', ['$event'])
	clickOutside(event: { target: any }) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showPreviousSearches = false;
		}
	}

	constructor(
		private translateService: TranslateService,
		private productDataStore: Store<ProductDataState>,
		private authService: AuthService,
		private cdr: ChangeDetectorRef,
		private elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.previousSearches = [];

		this.productInputField = new Subject<string>();
		this.productDataStore.dispatch(
			productDataActions.resetSearchData({
				searchItem: '',
				productData: null,
			})
		);

		this.itemSearch = '';

		this.activeIndex = 0;
		this.perms[Permissions.MenuProductImages] = this.authService.hasPermission(Permissions.MenuProductImages);

		this.translateService.get('PRODUCT_CONFIGURATION').subscribe((resp: any) => {
			this.translate = resp;
			this.title = this.translate.TITLE;
		});
		this.translateService.get('NO_DATA').subscribe((resp: string) => (this.noResultsTranslate = resp));

		this.product$ = this.productInputField.pipe(
			takeUntil(this.ngUnsubscribe),
			debounceTime(250),
			distinctUntilChanged(),
			filter((s: string) => s.length > 2),
			switchMap(searchString =>
				this.productDataStore.select(productDataSelectors.selectFilteredProductData, {
					searchString: searchString.toLowerCase(),
				})
			)
		);

		this.product$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(products => {
			if (products) {
				if (products.length) {
					this.results = [...products];
					this.noResultsMessage = '';
				} else {
					this.results = [];
					this.noResultsMessage = this.noResultsTranslate;
				}
			} else {
				this.productDataStore.dispatch(
					productDataActions.loadProductDataFiltered({
						searchItem: this.itemSearch.substr(0, 3),
						savedSearchItem: this.itemSearch.toLowerCase().substr(0, 3),
					})
				);
			}
		});
	}

	/**
	 * * Forces PrimeNg autocomplete to show suggestions when user clicks on the input field
	 * if it has product id in it.
	 * * Shows recent ```product common no``` searches, if user has any, or an empty recent searches message
	 */
	forceReferenceReload() {
		if (this.results) {
			this.results = [...this.results];
		}

		if (this.itemSearch?.length === 0) {
			this.results = [];
			this.noResultsMessage = '';

			this.authService.currentUser$.pipe(take(1)).subscribe(user => {
				if (user?.name) {
					this.previousSearches = JSON.parse(localStorage.getItem(`${user.name}_search`));
					if (this.previousSearches?.length > 0) {
						this.noPreviousSearches = false;
					} else {
						this.noPreviousSearches = true;
						this.previousSearches = [];
					}

					this.showPreviousSearches = true;
				}
			});
		}
	}

	filterProductList(productSearch) {
		this.productInputField.next(productSearch.query);
	}

	/**
	 * * Shows corresponding child component, by its tab index
	 * * Saves the selected ```product common no``` to previous searches array,
	 *   which is then pushed to localStorage
	 */
	showProductData() {
		this.productId = this.itemSearch;
		this.productNoSelected = true;

		switch (this.activeIndex) {
			case 0:
				this.title = this.translate.TITLE_IMAGES;
				break;
			case 1:
				this.title = this.translate.TITLE_FILES;
				break;
			case 2:
				this.title = this.translate.TITLE_LINKS;
				break;

			default:
				this.title = this.translate.TITLE;
				break;
		}

		this.authService.currentUser$.pipe(take(1)).subscribe(user => {
			if (user?.name) {
				const index = this.previousSearches.indexOf(this.productId);
				if (index > -1) {
					this.previousSearches.splice(index, 1);
					this.previousSearches.unshift(this.productId);
					localStorage.setItem(`${user.name}_search`, JSON.stringify(this.previousSearches));
				} else {
					this.previousSearches.unshift(this.productId);
					localStorage.setItem(`${user.name}_search`, JSON.stringify(this.previousSearches));
				}

				if (this.previousSearches.length > 10) {
					this.previousSearches.pop();
				}
			}
		});

		this.showPreviousSearches = false;

		this.cdr.detectChanges();
	}

	clearFilterAndCloseTabs() {
		this.itemSearch = '';
		this.productNoSelected = false;
		this.activeIndex = 0;
		this.title = this.translate.TITLE;

		if (this.productFilesComponent) {
			this.productFilesComponent.inputProductId = '';
		}

		if (this.productLinksComponent) {
			this.productLinksComponent.inputProductId = '';
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
