import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromPromotionSliderActions from './promotion-slider.actions';
import { PromotionSliderService } from '../../core/services/promotion-slider.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class PromotionSliderEffects {
	constructor(
		private actions$: Actions,
		private promotionSliderService: PromotionSliderService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadPromotionSlidersEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.loadPromotionSliders),
			mergeMap(() =>
				this.promotionSliderService.getPromotionSliders().pipe(
					map(promotionSliders => fromPromotionSliderActions.loadPromotionSlidersSuccess({ promotionSliders })),
					catchError(error => of(fromPromotionSliderActions.loadPromotionSlidersFailure({ error })))
				)
			)
		)
	);

	addPromotionSliderEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.addPromotionSlider),
			mergeMap(action =>
				this.promotionSliderService.addPromotionSlider(action.promotionSlider).pipe(
					switchMap(promotionSlider =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromPromotionSliderActions.addPromotionSliderSuccess({ promotionSlider });
							}),
							catchError(error => of(fromPromotionSliderActions.addPromotionSliderFailure({ error })))
						)
					)
				)
			)
		)
	);

	updatePromotionSliderEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.updatePromotionSlider),
			mergeMap(action =>
				this.promotionSliderService.updatePromotionSlider(action.promotionSlider).pipe(
					switchMap(promotionSlider =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);

								return fromPromotionSliderActions.updatePromotionSliderSuccess({ promotionSlider });
							}),
							catchError(error => of(fromPromotionSliderActions.updatePromotionSliderFailure({ error })))
						)
					)
				)
			)
		)
	);

	uploadModalImages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.uploadModalImages),
			mergeMap(action =>
				this.promotionSliderService.uploadModalImages(action.extraItems).pipe(
					switchMap(modalImages =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);

								let updatedPromotionSlider = action.selectedPromotionSlider;

								updatedPromotionSlider = {
									...updatedPromotionSlider,
									productActionSliderItems: [...updatedPromotionSlider.productActionSliderItems, ...modalImages],
								};

								return fromPromotionSliderActions.uploadModalImagesSuccess({
									modalImages: updatedPromotionSlider,
								});
							}),
							catchError(error => of(fromPromotionSliderActions.uploadModalImagesFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteModalImageEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.deleteModalImage),
			mergeMap(action =>
				this.promotionSliderService.deleteModalImage(action.item.id).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FILE_REMOVE').pipe(
							map(translate => {
								const promoSliderCopy = cloneDeep(action.promoSlider);

								for (let i = action.item.order; i < promoSliderCopy.productActionSliderItems.length; i++) {
									const newOrder = promoSliderCopy.productActionSliderItems[i].order - 1;
									promoSliderCopy.productActionSliderItems[i].order = newOrder;
								}

								promoSliderCopy.productActionSliderItems.splice(action.item.order - 1, 1);

								this.toastr.success(translate);

								return fromPromotionSliderActions.deleteModalImageSuccess({ promoSlider: promoSliderCopy });
							}),
							catchError(error => of(fromPromotionSliderActions.deleteModalImageFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteModalFileEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.deleteModalFile),
			mergeMap(action =>
				this.promotionSliderService.deleteModalFile(action.promoSlider.id).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FILE_REMOVE').pipe(
							map(translate => {
								const promoSliderCopy = cloneDeep(action.promoSlider);

								promoSliderCopy.documentUrl = '';

								this.toastr.success(translate);

								return fromPromotionSliderActions.deleteModalFileSuccess({ promoSlider: promoSliderCopy });
							}),
							catchError(error => of(fromPromotionSliderActions.deleteModalFileFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateModalImagesOrderEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPromotionSliderActions.changeModalImagesOrder),
			mergeMap(action =>
				this.promotionSliderService.updateModalImagesOrder(action.promotionSlider.productActionSliderItems).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);

								return fromPromotionSliderActions.changeModalImagesOrderSuccess({
									promotionSlider: action.promotionSlider,
								});
							}),
							catchError(error => of(fromPromotionSliderActions.changeModalImagesOrderFailure({ error })))
						)
					)
				)
			)
		)
	);
}
