import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PromotionSlider } from '../../shared/models/promotion-slider';
import * as PromotionSliderActions from './promotion-slider.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const promotionSlidersFeatureKey = 'promotionSliders';

export interface PromotionSliderState extends EntityState<PromotionSlider> {
	// additional entities state properties
	statusState: StatusState;
	selectedPromotionSliderState: StatusState;
	selectedPromotionSliderFileState: StatusState;
}

export const adapter: EntityAdapter<PromotionSlider> = createEntityAdapter<PromotionSlider>();

export const initialState: PromotionSliderState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	selectedPromotionSliderState: LoadingState.INIT,
	selectedPromotionSliderFileState: LoadingState.INIT,
});

const promotionSliderReducer = createReducer(
	initialState,
	on(PromotionSliderActions.loadPromotionSliders, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.loadPromotionSlidersSuccess, (state, action) =>
		adapter.setAll(action.promotionSliders, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.loadPromotionSlidersFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.addPromotionSlider, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.addPromotionSliderSuccess, (state, action) =>
		adapter.addOne(action.promotionSlider, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.addPromotionSliderFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.updatePromotionSlider, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.updatePromotionSliderSuccess, (state, action) =>
		adapter.updateOne(action.promotionSlider, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.updatePromotionSliderFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.uploadModalImages, state => {
		return {
			...state,
			selectedPromotionSliderState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.uploadModalImagesSuccess, (state, action) =>
		adapter.setOne(action.modalImages, {
			...state,
			selectedPromotionSliderState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.uploadModalImagesFailure, (state, action) => {
		return {
			...state,
			selectedPromotionSliderState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.deleteModalImage, state => {
		return {
			...state,
			selectedPromotionSliderState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.deleteModalImageSuccess, (state, action) =>
		adapter.setOne(action.promoSlider, {
			...state,
			selectedPromotionSliderState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.deleteModalImageFailure, (state, action) => {
		return {
			...state,
			selectedPromotionSliderState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.deleteModalFile, state => {
		return {
			...state,
			selectedPromotionSliderFileState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.deleteModalFileSuccess, (state, action) =>
		adapter.setOne(action.promoSlider, {
			...state,
			selectedPromotionSliderFileState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.deleteModalFileFailure, (state, action) => {
		return {
			...state,
			selectedPromotionSliderFileState: { error: action.error },
		};
	}),

	on(PromotionSliderActions.changeModalImagesOrder, state => {
		return {
			...state,
			selectedPromotionSliderState: LoadingState.LOADING,
		};
	}),
	on(PromotionSliderActions.changeModalImagesOrderSuccess, (state, action) =>
		adapter.setOne(action.promotionSlider, {
			...state,
			selectedPromotionSliderState: LoadingState.LOADED,
		})
	),
	on(PromotionSliderActions.changeModalImagesOrderFailure, (state, action) => {
		return {
			...state,
			selectedPromotionSliderState: { error: action.error },
		};
	})
);

export function reducer(state: PromotionSliderState | undefined, action: Action) {
	return promotionSliderReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
