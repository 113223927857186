import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Administrator } from '../../shared/models/administrator';
import * as AdministratorActions from './administrator.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const administratorsFeatureKey = 'administrators';

export interface AdministratorState extends EntityState<Administrator> {
	// additional entities state properties
	statusState: StatusState;
	currentUser: Administrator;
}

export const adapter: EntityAdapter<Administrator> = createEntityAdapter<Administrator>();

export const initialState: AdministratorState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	currentUser: null,
});

const administratorReducer = createReducer(
	initialState,
	on(AdministratorActions.loadAdministrators, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AdministratorActions.loadAdministratorsSuccess, (state, action) =>
		adapter.setAll(action.administrators, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AdministratorActions.loadAdministratorsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(AdministratorActions.loadCurrentAdministratorDetails, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AdministratorActions.loadCurrentAdministratorDetailsSuccess, (state, action) => {
		return {
			...state,
			currentUser: action.administrator,
			statusState: LoadingState.LOADED,
		};
	}),
	on(AdministratorActions.loadCurrentAdministratorDetailsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(AdministratorActions.addAdministrator, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AdministratorActions.addAdministratorSuccess, (state, action) =>
		adapter.addOne(action.administrator, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AdministratorActions.addAdministratorFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(AdministratorActions.updateAdministrator, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(AdministratorActions.updateAdministratorSuccess, (state, action) =>
		adapter.updateOne(action.administrator, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(AdministratorActions.updateAdministratorFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: AdministratorState | undefined, action: Action) {
	return administratorReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
