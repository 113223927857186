import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromMenuActions from './menu.actions';
import { MenuItemService } from '../../core/services/menu-item.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class MenuEffects {
	constructor(private actions$: Actions, private menuItemService: MenuItemService) {}

	loadDeactivatedMenuItemEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMenuActions.loadDeactivatedMenu),
			mergeMap(() =>
				this.menuItemService.getDeactivatedMenuItems().pipe(
					map(Menu => fromMenuActions.loadDeactivatedMenuSuccess({ Menu })),
					catchError(error => of(fromMenuActions.loadDeactivatedMenuFailure({ error })))
				)
			)
		)
	);
}
