import { Component, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/sr/environment';
import { ViberPromotionState } from '../root-store/viber-promotions-store/viber-promotion.reducer';
import * as viberPromotionSelectors from '../root-store/viber-promotions-store/viber-promotion.selectors';
import * as viberPromotionActions from '../root-store/viber-promotions-store/viber-promotion.actions';
import { ViberPromotion } from '../shared/models/viber-promotion';
import { FormBuilder, Validators } from '@angular/forms';
import { preventImageCache } from '../shared/utils/add-timestamp-prevent-cache';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { ToastrService } from 'ngx-toastr';
import { fileValidator, imageValidator } from '../shared/utils/file-validator';
import { Update } from '@ngrx/entity';
import { removeDiacritics } from '../shared/utils/remove-diacritics';

@Component({
	selector: 'app-viber-promotions',
	templateUrl: './viber-promotions.component.html',
	styleUrls: ['./viber-promotions.component.scss'],
})
export class ViberPromotionsComponent implements OnInit {
	updateViberPromotionModal: BsModalRef;
	viberPromotionForm: any;
	environment = environment;

	firstPageElementIndex = 0;

	translate: any;
	translateErrors: any;
	submitted: boolean;

	imagePreviewUrl: string;
	selectedImage: any;
	selectedImageName: string;
	oldFileName: string;

	selectedViberPromotion: any;

	viberPromotionImage: string | ArrayBuffer;

	viberPromotionsLoading$: Observable<boolean>;
	viberPromotions$: Observable<ViberPromotion[]>;

	currentViberPromotion: ViberPromotion = new ViberPromotion();
	customErrors = { required: true };

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'name', title: 'NAME', width: 20 },
		{ name: 'url', title: 'URL', width: 12 },
		{ name: 'imageUrl', title: 'IMAGE_URL', width: 12 },
		{ name: 'activeSrb', title: 'ACTIVE', width: 12 },
		{ name: 'orderNumber', title: 'ORDER_NUMBER', width: 12 },
		{ name: 'fromDate', title: 'FROM_DATE', width: 12 },
		{ name: 'toDate', title: 'TO_DATE', width: 12 },
	];

	constructor(
		private translateService: TranslateService,
		private store: Store<ViberPromotionState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) { }

	ngOnInit(): void {
		this.translateService.get('VIBER_PROMOTIONS').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('ERROR.FORM_VALIDATIONS').subscribe((resp: any) => (this.translateErrors = resp));
		this.viberPromotions$ = this.store.select(viberPromotionSelectors.selectAllViberPromotions);
		this.viberPromotionsLoading$ = this.store.select(viberPromotionSelectors.selectViberPromotionsLoading);
		this.store.dispatch(viberPromotionActions.loadViberPromotions());
	}

	openViberPromotionModal(updateViberPromotionTemplate: TemplateRef<any>, promotion: ViberPromotion) {
		this.submitted = false;
		this.selectedImage = null;
		this.selectedImageName = null;
		this.imagePreviewUrl = null;

		let fromDate: any;
		let toDate: any;

		if (promotion) {
			if (promotion.fromDate && promotion.toDate) {
				fromDate = new Date(promotion.fromDate);
				toDate = new Date(promotion.toDate);
			} else {
				fromDate = '';
				toDate = '';
			}
		}

		this.viberPromotionForm = this.formBuilder.group(
			promotion
				? {
					...promotion,
					name: [promotion.name, [Validators.required, Validators.maxLength(100)]],
					products: [promotion.products, [Validators.required, Validators.maxLength(200)]],
					url: [promotion.url, [Validators.required, Validators.maxLength(1000)]],
					activeSrb: [promotion.activeSrb],
					orderNumber: [promotion.orderNumber, [Validators.required, Validators.min(1)]],
					fromDate: [fromDate, [Validators.required]],
					toDate: [toDate, [Validators.required]],
				}
				: {
					...new ViberPromotion(),
					name: ['', [Validators.required, Validators.maxLength(100)]],
					products: ['', [Validators.required, Validators.maxLength(200)]],
					url: ['', [Validators.required, Validators.maxLength(1000)]],
					activeSrb: [true],
					orderNumber: [0, [Validators.required, Validators.min(1)]],
					fromDate: ['', [Validators.required]],
					toDate: ['', [Validators.required]],
				}
		);

		if (promotion?.imageUrl) {
			this.oldFileName = promotion.imageUrl;
			this.imagePreviewUrl = preventImageCache(environment.mediaLocation.viberPromotionImages + promotion.imageUrl);
		}

		this.updateViberPromotionModal = this.modalService.show(updateViberPromotionTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	selectPromoImage(event: any, fileUploadViewChild: any) {
		const fileReader = new FileReader();
		for (const file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				const uploadedFile = fileToBase64(fileReader);
				const uploadedFileName = file.name;

				const img = new Image();
				img.src = fileReader.result as string;
				img.onload = () => {
					if (fileValidator(file, 'size', environment.mediaRestrictions.viber_action_images)) {
						if (imageValidator(img, 'sizeEq', environment.mediaRestrictions.viber_action_images)) {
							this.selectedImage = uploadedFile;
							this.selectedImageName = uploadedFileName;
							this.viberPromotionForm.value.imageUrl = this.selectedImageName;
						} else {
							fileUploadViewChild.clear();
							this.translateService
								.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_EQ', {
									width: environment.mediaRestrictions.viber_action_images.width,
									height: environment.mediaRestrictions.viber_action_images.height,
								})
								.subscribe((text: string) => this.toastr.warning(text));
						}
					} else {
						fileUploadViewChild.clear();
						const size = environment.mediaRestrictions.promotions.small_image.size / 1024 + 'KB';
						this.translateService
							.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
								size,
							})
							.subscribe((text: string) => this.toastr.warning(text));
					}
				};
			};
		}
	}

	removeViberPromoImage() {
		this.selectedImage = null;
		this.selectedImageName = this.oldFileName;
	}

	submitViberPromotionForm() {
		this.submitted = true;
		this.viberPromotionForm.value.imageUrl = this.selectedImageName
			? removeDiacritics(this.selectedImageName)
			: this.oldFileName;

		const fromDate = new Date(this.viberPromotionForm.value.fromDate);
		fromDate.setHours(1, 0, 0)
		const toDate = new Date(this.viberPromotionForm.value.toDate);
		toDate.setHours(23, 59, 0);
		this.viberPromotionForm.value.fromDate = fromDate.toLocaleString();
		this.viberPromotionForm.value.toDate = toDate.toLocaleString();
		if (this.viberPromotionForm.value.id) {
			if (this.viberPromotionForm.invalid || (!this.selectedImage && !this.imagePreviewUrl)) {
				this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
				return;
			}

			const tempViberPromotion: Update<ViberPromotion> = {
				id: this.viberPromotionForm.value.id,
				changes: this.viberPromotionForm.value,
			};

			if (this.selectedImage) {
				tempViberPromotion.changes.imageFile = this.selectedImage;
				tempViberPromotion.changes.imageUrl = removeDiacritics(this.selectedImageName);
			}

			this.store.dispatch(viberPromotionActions.updateViberPromotion({ promotion: tempViberPromotion }));
		} else {
			if (this.viberPromotionForm.invalid || !this.selectedImage) {
				this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
				return;
			}

			const tempViberPromotion: ViberPromotion = this.viberPromotionForm.value;
			tempViberPromotion.imageFile = this.selectedImage;
			tempViberPromotion.imageUrl = removeDiacritics(this.selectedImageName);
			this.store.dispatch(viberPromotionActions.addViberPromotion({ promotion: tempViberPromotion }));

			this.firstPageElementIndex = 0;
		}

		this.updateViberPromotionModal.hide();
	}
}
