import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductExtraEffects } from './product-extras.effects';
import * as fromProductExtra from './product-extras.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductExtra.productExtrasFeatureKey, fromProductExtra.reducer),
		EffectsModule.forFeature([ProductExtraEffects]),
	],
})
export class ProductExtrasStoreModule {}
