import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromManufacturerFilteredActions from './manufacturer-filtered.actions';
import { ManufacturerService } from '../../core/services/manufacturer.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ManufacturerFilteredEffects {
	constructor(private actions$: Actions, private manufacturerService: ManufacturerService) {}

	loadManufacturersFilteredEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromManufacturerFilteredActions.loadManufacturersByCustomerAndGroup),
			mergeMap(action =>
				this.manufacturerService.getManufacturersByCustomerAndGroup(action.productGroupId, action.customerId).pipe(
					map(manufacturersFiltered =>
						fromManufacturerFilteredActions.loadManufacturersByCustomerAndGroupSuccess({ manufacturersFiltered })
					),
					catchError(error => of(fromManufacturerFilteredActions.loadManufacturersByCustomerAndGroupFailure({ error })))
				)
			)
		)
	);
}
