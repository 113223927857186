import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserRegistration } from 'src/app/shared/models/user-registration';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';

@Injectable()
export class UserRegistrationService {
	constructor(private http: HttpClient) {}

	getUserRegistration(): Observable<UserRegistration[]> {
		return this.http.get<UserRegistration[]>(environment.baseApiUrl + 'api/Registration');
	}
}
