import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const dashboardFeatureKey = 'dashboard';

export interface DashboardState {
	browsersUsed: { keys: string[]; values: unknown[] };
	devicesUsed: any[][];
	operatingSystemsUsed: { keys: string[]; values: unknown[] };
	visitsPerMonth: { keys: string[]; values: unknown[] };
	statusState: StatusState;
}

export const initialState: DashboardState = {
	browsersUsed: null,
	devicesUsed: null,
	operatingSystemsUsed: null,
	visitsPerMonth: null,
	statusState: LoadingState.INIT,
};

export const dashboardReducer = createReducer(
	initialState,
	on(DashboardActions.loadDashboardLogsPerYear, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(DashboardActions.loadDashboardLogsPerYearSuccess, (state, action) => {
		return {
			...state,
			browsersUsed: action.browsersUsed,
			devicesUsed: action.devicesUsed,
			operatingSystemsUsed: action.operatingSystemsUsed,
			visitsPerMonth: action.visitsPerMonth,
			statusState: LoadingState.LOADED,
		};
	}),
	on(DashboardActions.loadDashboardLogsPerYearFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: DashboardState | undefined, action: Action) {
	return dashboardReducer(state, action);
}
