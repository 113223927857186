import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { VendorlandGameResult } from '../../shared/models/vendorlandGameResult';
import * as VendorlandActions from './vendorland.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const vendorlandFeatureKey = 'vendorland';

export interface VendorlandState extends EntityState<VendorlandGameResult> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<VendorlandGameResult> = createEntityAdapter<VendorlandGameResult>();

export const initialState: VendorlandState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const vendorlandReducer = createReducer(
	initialState,
	on(VendorlandActions.loadVendorland, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(VendorlandActions.loadVendorlandSuccess, (state, action) =>
		adapter.setAll(action.vendorland, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(VendorlandActions.loadVendorlandFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(VendorlandActions.updateVendorland, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(VendorlandActions.updateVendorlandSuccess, (state, action) =>
		adapter.updateOne(action.vendorland, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(VendorlandActions.updateVendorlandFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: VendorlandState | undefined, action: Action) {
	return vendorlandReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
