import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	// here insert all desired HTTP requests that should raise an error (navigate to error page)
	private shouldRaiseErrorRequests: { url: string; method: string }[] = [
		{ url: 'api/PortalUser', method: 'GET' },
		{ url: 'api/PortalGroup', method: 'GET' },
		// ...
	];

	constructor(private _injector: Injector) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(
				() => {},
				(err: HttpErrorResponse) => {
					if (err.status && err.status !== 401 && this.isNavigableError(req)) {
						err.error.detail = err.message + '<br>' + err.error.detail;
						const router = this._injector.get(Router);
						router.navigate(['/error'], { state: { error: err.error } });
					}
				}
			)
		);
	}

	private isNavigableError(req: HttpRequest<any>): boolean {
		return this.shouldRaiseErrorRequests.some(er => req.method === er.method && req.url.includes(er.url));
	}
}
