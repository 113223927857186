import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { authConfig } from './auth-config';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/sr/environment';
import jwt_decode from 'jwt-decode';
import { MenuItemDB } from 'src/app/shared/models/menu-item';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private canImpersonateSubject = new BehaviorSubject<boolean>(false);
	public canImpersonate$ = this.canImpersonateSubject.asObservable();

	private isImpersonatingSubject = new BehaviorSubject<boolean>(false);
	public isImpersonating$ = this.isImpersonatingSubject.asObservable();

	private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

	public isDoneLoadingSubject$ = new ReplaySubject<boolean>();
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

	private currentUserSubject: BehaviorSubject<User>;
	public currentUser$: Observable<User>;

	public deactivatedMenu: MenuItemDB[] = [];
	public deactivatedMenu$: Observable<MenuItemDB[]>;
	public currentUrl: string;

	userRoleSubject = new BehaviorSubject<string | string[]>(this.userRoles);
	public get userRoles(): string | string[] {
		try {
			return (jwt_decode(localStorage.getItem('admin_access_token')) as any)?.role;
		} catch (Error) {
			return null;
		}
	}

	permissionsSubject = new BehaviorSubject<string | string[]>(this.permissions);
	private get permissions(): string | string[] {
		try {
			return (jwt_decode(localStorage.getItem('admin_access_token')) as any)?.permission;
		} catch (Error) {
			return null;
		}
	}

	private logoutClicked = false;

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
		this.isAuthenticated$,
		this.isDoneLoading$,
	]).pipe(map(values => values.every(b => b)));

	public isUserInRole(role: string): boolean {
		const exist = this.userRoles === role || (this.userRoles instanceof Array && this.userRoles.includes(role));
		return exist;
	}

	public hasPermission(permission: string): boolean {
		return (
			this.permissions === permission || (this.permissions instanceof Array && this.permissions.includes(permission))
		);
	}

	public navigateToLoginPage() {
		// TODO: Remember current URL maybe?
		this.router.navigateByUrl('/login');
	}
	public navigateToHomePage() {
		// TODO: Remember current URL maybe?
		this.router.navigateByUrl('/home');
	}

	constructor(private oauthService: OAuthService, private router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('admin_id_token_claims_obj')));
		this.currentUser$ = this.currentUserSubject.asObservable();

		if (this.userRoles && !this.userRoles?.includes('AdminAccess')) {
			setTimeout(() => {
				this.logout();
				sessionStorage.setItem('emptyLocalStorage', '1');
				this.navigateToLoginPage();
			}, 100);
		}

		// Useful for debugging:
		this.oauthService.events.subscribe(event => {
			if (event instanceof OAuthErrorEvent) {
				console.error(event);
			} else {
				if (event.type.toString() === 'logout') {
					this.logoutClicked = true;
				}
				console.warn(event);
			}
		});

		// This is tricky, as it might cause race conditions (where access_token is set in another
		// tab before everything is said and done there.
		// TODO: Improve this setup.
		window.addEventListener('storage', event => {
			// The `key` is `null` if the event was caused by `.clear()`
			if (event.key !== 'access_token' && event.key !== null) {
				return;
			}

			console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

			if (!this.oauthService.hasValidAccessToken()) {
				this.navigateToLoginPage();
			}
		});

		this.oauthService.events.subscribe(_ => {
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
		});

		this.oauthService.events
			.pipe(filter(e => ['token_received'].includes(e.type)))
			.subscribe(() =>
				this.oauthService
					.loadUserProfile()
					.then(() => this.currentUserSubject.next(JSON.parse(localStorage.getItem('admin_id_token_claims_obj'))))
			);

		this.oauthService.events
			.pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
			.subscribe(() => {
				if (!this.logoutClicked) {
					this.logoutClicked = false;
					this.navigateToLoginPage();
				}
			});

		this.oauthService.setupAutomaticSilentRefresh();
	}

	public runInitialLoginSequence(): Promise<void> {
		if (sessionStorage.getItem('emptyLocalStorage')) {
			this.emptyLocalStorage();
			sessionStorage.removeItem('emptyLocalStorage');
		}
		if (location.hash) {
			console.log('Encountered hash fragment, plotting as table...');
			console.table(
				location.hash
					.substr(1)
					.split('&')
					.map(kvp => kvp.split('='))
			);
		}

		// 0. LOAD CONFIG:
		// First we have to check to see how the IdServer is
		// currently configured:
		return (
			this.oauthService
				.loadDiscoveryDocument()

				// For demo purposes, we pretend the previous call was very slow
				// .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))

				// 1. HASH LOGIN:
				// Try to log in via hash fragment after redirect back
				// from IdServer from initImplicitFlow:
				.then(() => this.oauthService.tryLogin())

				.then(() => {
					if (this.oauthService.hasValidAccessToken()) {
						return Promise.resolve();
					}

					// 2. SILENT LOGIN:
					// Try to log in via a refresh because then we can prevent
					// needing to redirect the user:
					return this.oauthService
						.silentRefresh()
						.then(() => Promise.resolve())
						.catch(result => {
							// Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
							// Only the ones where it's reasonably sure that sending the
							// user to the IdServer will help.
							const errorResponsesRequiringUserInteraction = [
								'interaction_required',
								'login_required',
								'account_selection_required',
								'consent_required',
							];

							if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
								// 3. ASK FOR LOGIN:
								// At this point we know for sure that we have to ask the
								// user to log in, so we redirect them to the IdServer to
								// enter credentials.
								//
								// Enable this to ALWAYS force a user to login.
								// this.oauthService.initImplicitFlow();
								//
								// Instead, we'll now do this:
								console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
								return Promise.resolve();
							}

							// We can't handle the truth, just pass on the problem to the
							// next handler.
							return Promise.reject(result);
						});
				})

				.then(() => {
					this.isDoneLoadingSubject$.next(true);
					if (this.oauthService.hasValidAccessToken()) {
						this.checkRoles();
					}
					// Check for the strings 'undefined' and 'null' just to be sure. Our current
					// login(...) should never have this, but in case someone ever calls
					// initImplicitFlow(undefined | null) this could happen.
					if (
						this.oauthService.state &&
						this.oauthService.state !== 'undefined' &&
						this.oauthService.state !== 'null'
					) {
						let stateUrl = this.oauthService.state;
						if (stateUrl.startsWith('/') === false) {
							stateUrl = decodeURIComponent(stateUrl);
						}
						console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
						this.router.navigateByUrl(stateUrl);
					}
				})
				.catch(() => {
					this.isDoneLoadingSubject$.next(true);
					this.navigateToLoginPage();
				})
		);
	}

	public login(targetUrl?: string) {
		this.oauthService.initLoginFlow(targetUrl || this.router.url);
	}

	public logout() {
		this.oauthService.logOut(false);
	}
	public refresh(): Observable<any> {
		// tslint:disable-next-line: no-string-literal
		authConfig.customQueryParams['username'] = this.currentUserValue ? this.currentUserValue.preferred_username : '';
		return from(this.oauthService.silentRefresh());
	}

	public hasValidToken() {
		return this.oauthService.hasValidAccessToken();
	}

	public impersonate(): void {
		const returnUrl = encodeURIComponent(this.createReturnUrl());
		sessionStorage.setItem('emptyLocalStorage', '1');
		document.location.href =
			environment.identityServerConf.url + environment.identityServerConf.impersonificationPath + returnUrl;
	}

	public stopImpersonate(): void {
		sessionStorage.setItem('emptyLocalStorage', '1');
		document.location.href = environment.identityServerConf.url + environment.identityServerConf.stopImpersonatePath;
	}

	private createReturnUrl(): string {
		const state = localStorage.getItem('admin_nonce');
		return (
			this.oauthService.loginUrl.replace(authConfig.issuer, '') +
			'/callback?' +
			'response_type=' +
			this.oauthService.responseType +
			'&client_id=' +
			this.oauthService.clientId +
			'&state=' +
			state +
			'&redirect_uri=' +
			encodeURIComponent(this.oauthService.redirectUri) +
			'&scope=' +
			encodeURIComponent(this.oauthService.scope) +
			'&code_challenge=' +
			localStorage.getItem('admin_PKCI_verifier') +
			'&code_challenge_method=S256' +
			'&nonce=' +
			state +
			'&country=' +
			environment.identityServerConf.country
		);
	}

	public checkRoles() {
		this.isImpersonatingSubject.next(this.isUserInRole('StopAdminImpersonate'));
		this.canImpersonateSubject.next(this.isUserInRole('UserImpersonateAdmin'));
	}

	private emptyLocalStorage(): void {
		localStorage.removeItem('admin_access_token');
		localStorage.removeItem('admin_id_token');
		localStorage.removeItem('admin_refresh_token');
		localStorage.removeItem('admin_expires_at');
		localStorage.removeItem('admin_id_token_claims_obj');
		localStorage.removeItem('admin_id_token_expires_at');
		localStorage.removeItem('admin_id_token_stored_at');
		localStorage.removeItem('admin_access_token_stored_at');
		localStorage.removeItem('admin_granted_scopes');
		localStorage.removeItem('admin_session_state');
		localStorage.removeItem('admin_nonce');
		localStorage.removeItem('admin_PKCE_verifier');

		// portal storage
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('expires_at');
		localStorage.removeItem('id_token_claims_obj');
		localStorage.removeItem('id_token_expires_at');
		localStorage.removeItem('id_token_stored_at');
		localStorage.removeItem('access_token_stored_at');
		localStorage.removeItem('granted_scopes');
		localStorage.removeItem('session_state');
		localStorage.removeItem('B2BData');
		localStorage.removeItem('userTokens');
		localStorage.removeItem('authDate');
		localStorage.removeItem('nonce');
		localStorage.removeItem('PKCE_verifier');
	}
}
