import { createAction, props } from '@ngrx/store';

import { PriceList } from '../../shared/models/price-list';
import { Update } from '@ngrx/entity';

export const loadPriceList = createAction('[PriceList Page] Load PriceList');

export const loadPriceListSuccess = createAction(
	'[PriceList API] Loaded PriceList Success',
	props<{ PriceList: PriceList[] }>()
);

export const loadPriceListFailure = createAction('[PriceList API] Loaded PriceList Failure', props<{ error: any }>());

export const addPriceList = createAction('[PriceList Page] Add PriceList', props<{ PriceList: PriceList }>());

export const addPriceListSuccess = createAction(
	'[PriceList API] Add PriceList Success',
	props<{ PriceList: PriceList }>()
);

export const addPriceListFailure = createAction('[PriceList API] Add PriceList Failure', props<{ error: any }>());

export const updatePriceList = createAction(
	'[PriceList Page] Update PriceList',
	props<{ PriceList: Update<PriceList> }>()
);

export const updatePriceListSuccess = createAction(
	'[PriceList API] Update PriceList Success',
	props<{ PriceList: Update<PriceList> }>()
);

export const updatePriceListFailure = createAction('[PriceList API] Update PriceList Failure', props<{ error: any }>());
