import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { CustomerRecord } from 'src/app/shared/models/customer-record';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class CustomerRecordsService {
	constructor(private http: HttpClient) {}

	getCustomerRecords(): Observable<CustomerRecord[]> {
		return this.http.get<CustomerRecord[]>(environment.baseApiUrl + 'api/Customer');
	}

	addCustomerRecord(record: CustomerRecord): Observable<CustomerRecord> {
		return this.http.post<CustomerRecord>(environment.baseApiUrl + 'api/Customer', record);
	}

	updateCustomerRecord(record: Update<CustomerRecord>): Observable<Update<CustomerRecord>> {
		return this.http
			.put<Update<CustomerRecord>>(environment.baseApiUrl + 'api/Customer', record.changes)
			.pipe(map(() => record));
	}

	deleteCustomerRecord(customerRecordId: number): Observable<number> {
		return this.http
			.delete(environment.baseApiUrl + 'api/Customer/' + customerRecordId)
			.pipe(map(() => customerRecordId));
	}
}
