import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductExtra } from 'src/app/shared/models/product-extra';
import { environment } from 'src/environments/sr/environment';
@Injectable({
	providedIn: 'root',
})
export class ProductExtraService {
	constructor(private http: HttpClient) {}

	getProductExtras(filter: string): Observable<ProductExtra[]> {
		return this.http.get<ProductExtra[]>(`${environment.baseApiUrl}api/ProductExtras/${encodeURIComponent(filter)}`);
	}

	// ProductFile methods

	uploadProductFiles(productFiles: ProductExtra[]): Observable<ProductExtra[]> {
		return this.http.post<ProductExtra[]>(`${environment.baseApiUrl}api/ProductExtras/files/`, productFiles);
	}

	deleteProductFile(productFileId: number) {
		return this.http
			.delete<ProductExtra>(`${environment.baseApiUrl}api/ProductExtras/files/${productFileId}`)
			.pipe(map(() => productFileId));
	}

	// ProductLink methods

	addProductLink(productLink: ProductExtra): Observable<ProductExtra> {
		return this.http.post<ProductExtra>(`${environment.baseApiUrl}api/ProductExtras/links/`, productLink);
	}

	updateProductLink(productLink: Update<ProductExtra>): Observable<Update<ProductExtra>> {
		return this.http
			.put<Update<ProductExtra>>(`${environment.baseApiUrl}api/ProductExtras/links/`, productLink.changes)
			.pipe(map(() => productLink));
	}

	deleteProductLink(productLinkId: number) {
		return this.http
			.delete<ProductExtra>(`${environment.baseApiUrl}api/ProductExtras/links/${productLinkId}`)
			.pipe(map(() => productLinkId));
	}
}
