import { administratorsFeatureKey, AdministratorState, selectAll } from './administrator.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { Administrator } from 'src/app/shared/models/administrator';

export const selectAdministratorState = createFeatureSelector<AdministratorState>(administratorsFeatureKey);

export const selectAllAdministrators = createSelector(selectAdministratorState, selectAll);

export const selectAdministratorsLoading = createSelector(
	selectAdministratorState,
	state => state.statusState === LoadingState.LOADING
);

export const selectCurrentUser = createSelector(selectAdministratorState, state => state.currentUser);

/**
 * Selects administrator by administrator username.
 * @returns True, if administrator with the given username exists, or false, if no admin is found.
 */
export const selectAdministratorByUsername = createSelector(
	selectAllAdministrators,
	(administrators: Administrator[], params: any) => {
		return administrators.some(
			administrator =>
				administrator.username.toLowerCase() === params.username.toLowerCase() && administrator.id !== params.id
		);
	}
);

/**
 * Selects administrator by user id.
 * @returns Administrator entity with the selected user id.
 */
export const selectAdministratorById = createSelector(
	selectAllAdministrators,
	(administrators: Administrator[], params: any) => {
		return administrators.find(administrator => administrator.id === params.id);
	}
);

export const selectAdministratorsError = createSelector(selectAdministratorState, (state: AdministratorState) =>
	getError(state.statusState)
);
