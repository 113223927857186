import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSeller from './seller.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SellerEffects } from './seller.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromSeller.sellersFeatureKey, fromSeller.reducer),
		EffectsModule.forFeature([SellerEffects]),
	],
})
export class SellersStoreModule {}
