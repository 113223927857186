<ng-container *ngIf="translate && { groupConnectionsLoading: groupConnectionsLoading$ | async } as observables">
	<div class="page-title">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<app-skeleton-screen
		*ngIf="observables.groupConnectionsLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<button
		type="button"
		class="button my-3"
		(click)="openGroupConnectionModal(groupConnectionTemplate)"
		[disabled]="observables.groupConnectionsLoading"
		*ngIf="!observables.groupConnectionsLoading"
	>
		<fa-icon icon="plus"></fa-icon>
		{{ 'ADD' | translate }}
	</button>
	<div *ngIf="!observables.groupConnectionsLoading">
		<ng-container *ngIf="groupConnections$ | async">
			<p-table
				#groupConnectionsTable
				[value]="groupConnections$ | async"
				[autoLayout]="true"
				[(first)]="firstPageElementIndex"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'productGroup', 'connectedProductGroup', 'connectionType']"
			>
				<ng-template pTemplate="caption">
					<div class="p-grid">
						<div class="p-col">
							<p-dropdown
								class="table-dropdown-options"
								[appendTo]="groupConnectionsTable"
								[options]="filterDropdownOptions"
								[(ngModel)]="selectedProductGroup"
								[filter]="true"
								filterBy="value"
								(onClick)="getFilterDropdownOptions()"
								(onChange)="groupConnectionsTable.filter(selectedProductGroup, 'productGroup', 'equals')"
							></p-dropdown>
						</div>
						<div class="p-col pt-3">
							<label class="chbx-container mr-3" [class.checked]="otherGroupFilter">
								{{ translate.FILTER_OTHER_GROUP }}
								<input
									type="checkbox"
									[(ngModel)]="otherGroupFilter"
									(change)="filterGroupConnections(groupConnectionsTable)"
									name="otherGroup"
									[ngClass]="{ checked: otherGroupFilter }"
								/>
								<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
							</label>
							<label class="chbx-container mr-3" [class.checked]="connectedSaleFilter">
								{{ translate.FILTER_CONNECTED_SALE }}
								<input
									type="checkbox"
									[(ngModel)]="connectedSaleFilter"
									(change)="filterGroupConnections(groupConnectionsTable)"
									name="connectedSale"
									[ngClass]="{ checked: connectedSaleFilter }"
								/>
								<span class="chbx-checkmark"><svg-icon key="checkmark" class="caption-chbx"></svg-icon></span>
							</label>
						</div>

						<div class="p-col">
							<div class="global-filter-cont">
								<fa-icon icon="search"></fa-icon>
								<input
									type="text"
									class="form-control"
									pInputText
									size="50"
									[placeholder]="'GLOBAL_FILTER' | translate"
									data-tid="globalFilter"
									(input)="groupConnectionsTable.filterGlobal($event.target.value, 'contains')"
								/>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="width: 3rem"></th>
						<th *ngFor="let col of columns" pSortableColumn="{{ col.pSortableColumn }}">
							{{ col.name | translate }} <p-sortIcon field="{{ col.field }}"></p-sortIcon>
						</th>
						<th [style.width.px]="100">&nbsp;</th>
						<th [style.width.px]="100">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-groupConnection let-i="rowIndex">
					<tr>
						<th style="width: 3rem"></th>
						<td>{{ groupConnection.productGroupDescription }} ( {{ groupConnection.productGroup }} )</td>
						<td>{{ groupConnection.connectedProductGroup }}</td>
						<td>{{ groupConnection.connectionType }}</td>
						<td class="text-right red">
							<button
								type="button"
								class="button button-gray button-delete"
								(click)="openConfirmationModal(deleteGroupConnectionTemplate, groupConnection)"
							>
								<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">{{ 'NO_DATA' | translate }}</td>
					</tr>
				</ng-template>
			</p-table>
		</ng-container>
	</div>

	<ng-template #groupConnectionTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				{{ translate.ADD_GROUP_CONNECTION }}
			</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="groupConnectionModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="groupConnectionForm" (ngSubmit)="submitGroupConnectionForm()">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.PRODUCT_GROUP }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							*ngIf="!observables.productGroupsLoading"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && groupConnectionForm.controls.productGroup.errors }"
							formControlName="productGroup"
							[style.max-width.px]="400"
							[options]="dropdownOptions"
							[filter]="true"
							(onChange)="select($event.value)"
							placeholder="{{ 'GROUP_CONNECTIONS.SELECT_PRODUCT_GROUP' | translate }}"
						></p-dropdown>
						<app-form-validation-error-msg
							*ngIf="submitted && groupConnectionForm.controls.productGroup.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.CONNECTED_PRODUCT_GROUP }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							*ngIf="!observables.productGroupsLoading"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && groupConnectionForm.controls.connectedProductGroup.errors }"
							formControlName="connectedProductGroup"
							[style.max-width.px]="400"
							[options]="connectedProductGroupDropdownOptions"
							[filter]="true"
							placeholder="{{ 'GROUP_CONNECTIONS.SELECT_CONNECTED_PRODUCT_GROUP' | translate }}"
						></p-dropdown>
						<app-form-validation-error-msg
							*ngIf="submitted && groupConnectionForm.controls.connectedProductGroup.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.CONNECTION_TYPE }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							[options]="groupConnectionType"
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && groupConnectionForm.controls.connectionType.errors }"
							formControlName="connectionType"
							[style.max-width.px]="400"
							placeholder="{{ 'GROUP_CONNECTIONS.SELECT_CONNECTION_TYPE' | translate }}"
						></p-dropdown>
						<app-form-validation-error-msg
							*ngIf="submitted && groupConnectionForm.controls.connectionType.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="groupConnectionModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="submit"
						[disabled]="groupConnectionForm.dirty ? false : true || observables.groupConnectionsLoading"
						class="button"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
	<!-- Delete Group Connection Confirm Modal -->
	<ng-template #deleteGroupConnectionTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ translate.DELETE_GROUP_CONNECTION_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deleteGroupConnectionModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.DELETE_GROUP_CONNECTION_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deleteGroupConnectionModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button
					type="button"
					class="button"
					[disabled]="observables.groupConnectionsLoading"
					(click)="deleteGroupConnection()"
				>
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
