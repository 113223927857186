import { groupConnectionsFeatureKey, GroupConnectionState, selectAll } from './group-connections.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { GroupConnection } from 'src/app/shared/models/group-connection';
import { groupBy } from 'lodash-es';

export const selectGroupConnectionState = createFeatureSelector<GroupConnectionState>(groupConnectionsFeatureKey);

export const selectAllGroupConnections = createSelector(selectGroupConnectionState, selectAll);

export const selectGroupConnectionsLoading = createSelector(
	selectGroupConnectionState,
	state => state.statusState === LoadingState.LOADING
);

export const selectGroupConnectionsError = createSelector(selectGroupConnectionState, (state: GroupConnectionState) =>
	getError(state.statusState)
);
export const selectAllConnectedProductGroupsByCode = createSelector(
	selectAllGroupConnections,
	(groupConnection: GroupConnection[], params: any) => {
		const array = [{ connectedProductGroup: params.code }];
		for (const item of groupConnection) {
			if (item.productGroup === params.code) {
				array.push(item);
			}
		}
		return array;
	}
);
export const selectDistinctGroupConnectionByID = createSelector(
	selectAllGroupConnections,
	(groupConnection: GroupConnection[]) => {
		const array = groupBy(groupConnection, g => g.productGroup);
		const returnArray = [];
		Object.entries(array).forEach(element => {
			returnArray.push({
				label: element[1][0].productGroupDescription + ' ( ' + element[0] + ' ) ',
				value: element[0],
			});
		});
		return returnArray;
	}
);
