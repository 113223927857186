<div *ngIf="
		translate && {
			productFiltersLoading: productFiltersLoading$ | async,
			productsLoading: productsLoading$ | async,
			productDetailsLoading: productDetailsLoading$ | async,
			customerGroupsLoading: customerGroupsLoading$ | async
		} as observables
	">
	<div class="page-title mb-0">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<div class="special-price-actions row">
		<div class="deputy-container col-md-3 col-lg-6">
			<button type="button" class="button mr-1" (click)="openDeputyModal(deputyModalTemplate)"
				[disabled]="!products?.length" [hidden]="disabledDeputyModal">
				{{ translate.DEPUTY }}
			</button>
			<button type="button" class="button" [disabled]="!products?.length"
				(click)="openLatestChangesModal(latestChangesModalTemplate)" data-tid="openLatestChanges">
				{{ translate.LATEST_CHANGES }}
			</button>
		</div>
		<div id="filter-button" class="d-lg-none col-md-6 col-6 option-item"
			(click)="showFiltersMenu = !showFiltersMenu">
			<fa-icon [icon]="'filter'"></fa-icon> {{ translate.FILTER_PRODUCTS }}
		</div>
		<div class="download-upload-excel-container col-md-12 col-lg-6" *ngIf="isUserAbleToSet">
			<label class="export-excel" [title]="translate.IMPORT_TITLE">
				<img [src]="environment.localImagesLocation + 'xls-import.png'" />
				<span class="text-label">{{ translate.IMPORT }}</span>
				<input #inputFile type="file" accept="application/excel" (change)="importExcel($event)"
					(click)="$event.target.value = null" hidden />
			</label>
			<label class="import-excel" [title]="translate.EXPORT_TITLE">
				<a class="file-link" (click)="exportDataFromTable()">
					<img [src]="environment.localImagesLocation + 'xls-export.png'" />
					<span class="text-label">{{ translate.EXPORT }}</span>
				</a>
			</label>
			<label class="import-excel" [title]="translate.TEMPLATE_TITLE">
				<a class="file-link" href="{{ environment.mediaLocation.specialPricesExcelFiles + excelFileName }}"
					download target="_blank">
					<img [src]="environment.localImagesLocation + 'xls-download.png'" />
					<span class="text-label">{{ translate.TEMPLATE }}</span>
				</a>
			</label>
		</div>
	</div>
	<div class="row">
		<div class="special-price-cont col col-12">
			<!--------------------->
			<!-- PRODUCT FILTERS -->
			<!--------------------->
			<div id="special-price-filters" class="col-lg-3 d-none d-lg-block">
				<div *ngIf="observables.productFiltersLoading && !filtersInitialized"
					class="row filter-boxes-wrapper loading">
					<div class="loader-spinner">
						<div class="spinner-border d-block"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>
				</div>
				<div class="row filter-boxes-wrapper"
					*ngIf="(!observables.productFiltersLoading || filtersInitialized) && productFilters">
					<div class="global-filter-cont global-filter-pg">
						<fa-icon icon="search"></fa-icon>
						<input type="text" class="form-control" pInputText size="50" [(ngModel)]="filterGlobalSearch"
							[placeholder]="translate.FILTER_SEARCH" />
					</div>
					<div class="clear-filter-container" [ngClass]="{ hidden: !filterSelected }">
						<button type="button" class="button button-gray" (click)="clearFilters()">
							{{ translate.CLEAR_FILTERS }}
						</button>
					</div>
					<!-- Additional filters -->
					<div *ngIf="productFilters?.offer?.length" class="col-12">
						<div class="filter-box">
							<h6 class="box-title mb-3">{{ translate.ADDITIONAL_FILTERS }}</h6>
							<div class="row filter-values u-fancy-scrollbar">
								<div *ngFor="let offer of productFilters.offer" class="col-12">
									<label *ngIf="
											offer &&
											(((offer.specialOffer === 0 || offer.specialOffer === -1) && !filterSelected) || filterSelected)
										" class="chbx-container offer" [class.checked]="offer.selected"
										[ngClass]="{ disabled: observables.productFiltersLoading || !productFilters }">
										{{ translate.SPECIAL_OFFERS[specialOffer[offer.specialOfferValue]] }}
										<input type="checkbox" [(ngModel)]="offer.selected"
											[ngClass]="{ checked: offer.selected }" (change)="
												listFiltersChanged(
													'OFFER',
													'ADDITIONAL_FILTERS',
													offer.specialOffer,
													translate.SPECIAL_OFFERS[specialOffer[offer.specialOfferValue]],
													false,
													offer.selected
												)
											" />

										<span class="chbx-checkmark">
											<svg-icon key="checkmark"></svg-icon>
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>
					<!-- Group filters -->
					<div *ngIf="listFilters?.type !== 'group' && productFilters?.group?.length" class="col-12">
						<div class="filter-box">
							<h6 class="box-title mb-3">{{ translate.GROUPS }}</h6>
							<div class="row filter-values u-fancy-scrollbar">
								<div *ngFor="let group of productFilters.group | searchFilter: filterGlobalSearch"
									class="col-12">
									<label *ngIf="group.productGroupName" class="chbx-container"
										[class.checked]="group.selected"
										[ngClass]="{ disabled: observables.productFiltersLoading || !productFilters }">
										{{ group.productGroupName }}
										<input type="checkbox" [(ngModel)]="group.selected"
											[ngClass]="{ checked: group.selected }" (change)="
												listFiltersChanged(
													'GROUP',
													'PRODUCT_GROUP',
													group.productGroup,
													group.productGroupName,
													false,
													group.selected
												)
											" />
										<span class="chbx-checkmark">
											<svg-icon key="checkmark"></svg-icon>
										</span>
									</label>
								</div>
								<div class="empty-filter"
									*ngIf="!(productFilters.group | searchFilter: filterGlobalSearch).length">
									{{ translate.EMPTY_GROUPS }}
								</div>
							</div>
						</div>
					</div>
					<!-- Manufacturer filters -->
					<div *ngIf="listFilters?.type !== 'brand' && productFilters?.manufacturer?.length" class="col-12">
						<div class="filter-box">
							<h6 class="box-title mb-3">{{ translate.MANUFACTURERS }}</h6>
							<div class="row filter-values u-fancy-scrollbar">
								<div *ngFor="let manufacturer of productFilters.manufacturer | searchFilter: filterGlobalSearch"
									class="col-12">
									<label *ngIf="manufacturer.manufacturerName" class="chbx-container"
										[class.checked]="manufacturer.selected"
										[ngClass]="{ disabled: observables.productFiltersLoading || !productFilters }">
										{{ manufacturer.manufacturerName }}
										<input type="checkbox" [(ngModel)]="manufacturer.selected"
											[ngClass]="{ checked: manufacturer.selected }" (change)="
												listFiltersChanged(
													'MANUFACTURER',
													'MANUFACTURER',
													manufacturer.manufacturerID,
													manufacturer.manufacturerName,
													false,
													manufacturer.selected
												)
											" />

										<span class="chbx-checkmark">
											<svg-icon key="checkmark"></svg-icon>
										</span>
									</label>
								</div>
								<div class="empty-filter"
									*ngIf="!(productFilters.manufacturer | searchFilter: filterGlobalSearch).length">
									{{ translate.EMPTY_MANUFACTURERS }}
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="filterSelected" class="w-100">
						<!-- Price filter box-->
						<div id="price-filter" class="col-12 mb-2" *ngIf="productFilters?.price?.length">
							<div class="filter-box">
								<h6 class="box-title mb-3">{{ translate.PRICE }}</h6>
								<div class="price-slider-cont">
									<ngx-slider [(value)]="minPriceValue" [(highValue)]="maxPriceValue"
										[options]="sliderOptions" (userChangeEnd)="priceFilterChanged()"></ngx-slider>
								</div>
							</div>
						</div>
						<!-- Attribute filters -->
						<div *ngIf="productFilters?.attributes">
							<ng-container *ngFor="let filter of productFilters.attributes">
								<div *ngIf="
										!filter.isAdvancedFilter &&
										filter.values.length &&
										(filter.values | atributeSearch: filterGlobalSearch).length
									" class="col-12">
									<div class="filter-box">
										<h6 class="box-title mb-3">{{ filter.descriptionLocal }}</h6>
										<div class="row filter-values u-fancy-scrollbar">
											<div *ngFor="let item of filter.values | atributeSearch: filterGlobalSearch"
												class="col-12">
												<label class="chbx-container" [class.checked]="item.selected"
													[ngClass]="{ disabled: observables.productFiltersLoading || !productFilters }">
													{{ item.optionValueLocal ? item.optionValueLocal :
													translate.NOT_SPECIFIED }}
													<input type="checkbox" [(ngModel)]="item.selected"
														[ngClass]="{ checked: item.selected }" (change)="
															listFiltersChanged(
																filter.attributeCode,
																filter.descriptionLocal,
																item.optionValue,
																item.optionValue,
																true,
																item.selected
															)
														" />
													<span class="chbx-checkmark">
														<svg-icon key="checkmark"></svg-icon>
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						<!-- Warranty filters -->
						<div *ngIf="productFilters?.warranty?.length" class="col-12"
							[hidden]="!(productFilters.warranty | searchFilter: filterGlobalSearch).length">
							<div class="filter-box">
								<h6 class="box-title mb-3">{{ translate.WARRANTY }}</h6>
								<div class="row filter-values u-fancy-scrollbar">
									<div *ngFor="let warranty of productFilters.warranty | searchFilter: filterGlobalSearch"
										class="col-12">
										<label *ngIf="warranty.warranty" class="chbx-container"
											[class.checked]="warranty.selected"
											[ngClass]="{ disabled: observables.productFiltersLoading || !productFilters }">
											{{ warranty.warranty }}
											<input type="checkbox" [(ngModel)]="warranty.selected"
												[ngClass]="{ checked: warranty.selected }" (change)="
													listFiltersChanged(
														'WARRANTY',
														'WARRANTY',
														warranty.warranty,
														warranty.warranty,
														false,
														warranty.selected
													)
												" />
											<span class="chbx-checkmark">
												<svg-icon key="checkmark"></svg-icon>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="filter-fixed-menu" class="d-lg-none" *ngIf="showFiltersMenu">
				<div class="filter-head">
					<h5 class="d-inline-block">{{ translate.FILTER_PRODUCTS }}</h5>
					<button class="button btn-default float-right" (click)="showFiltersMenu = false">
						<fa-icon icon="chevron-left"></fa-icon> {{ 'BACK' | translate }}
					</button>
				</div>
				<div *ngIf="!observables.productFiltersLoading" class="filter-cont">
					<div *ngIf="productFilters" class="row h-100">
						<ul class="col-12 titles-list nav-list">
							<!-- Group filters -->
							<li class="filter-title" *ngIf="productFilters.group.length">
								<span class="nav-header" (click)="setExpandedMenuFilter('group')"
									[ngClass]="{ collapsed: expandedMobileFilter !== 'group' }">{{ translate.GROUPS }}
									<fa-icon icon="chevron-right" class="float-right"></fa-icon>
								</span>
								<ul id="group" class="filter-values nav-list"
									[ngClass]="expandedMobileFilter !== 'group' ? 'collapse' : 'show'">
									<li *ngFor="let group of productFilters.group">
										<label class="chbx-container mb-0" [class.checked]="group.selected">
											{{ group.productGroupName }}
											<input type="checkbox" [(ngModel)]="group.selected" (change)="
													listFiltersChanged(
														'GROUP',
														'PRODUCT_GROUP',
														group.productGroup,
														group.productGroupName,
														false,
														group.selected
													)
												" [ngClass]="{ checked: group.selected }" />
											<span class="chbx-checkmark">
												<svg-icon key="checkmark"></svg-icon>
											</span>
										</label>
									</li>
								</ul>
							</li>
							<!-- Manufacturer filters -->
							<li class="filter-title" *ngIf="productFilters.manufacturer.length">
								<span class="nav-header" (click)="setExpandedMenuFilter('manufacturer')"
									[ngClass]="{ collapsed: expandedMobileFilter !== 'manufacturer' }">{{
									translate.MANUFACTURER }}<fa-icon icon="chevron-right" class="float-right">
									</fa-icon></span>
								<ul class="filter-values nav-list" id="manufacturer"
									[ngClass]="expandedMobileFilter !== 'manufacturer' ? 'collapse' : 'show'">
									<li *ngFor="let manufacturer of productFilters.manufacturer">
										<label class="chbx-container mb-0" [class.checked]="manufacturer.selected">
											{{ manufacturer.manufacturerName }}
											<input type="checkbox" [(ngModel)]="manufacturer.selected" (change)="
													listFiltersChanged(
														'MANUFACTURER',
														'MANUFACTURER',
														manufacturer.manufacturerName,
														manufacturer.manufacturerID,
														false,
														manufacturer.selected
													)
												" [ngClass]="{ checked: manufacturer.selected }" />
											<span class="chbx-checkmark">
												<svg-icon key="checkmark"></svg-icon>
											</span>
										</label>
									</li>
								</ul>
							</li>
							<div *ngIf="filterSelected" class="w-100">
								<!-- Price filter box-->
								<li class="filter-title" *ngIf="productFilters.price">
									<span class="nav-header" (click)="setExpandedMenuFilter('price')"
										[ngClass]="{ collapsed: expandedMobileFilter !== 'price' }">{{ translate.PRICE
										}}<fa-icon icon="chevron-right" class="float-right"></fa-icon></span>
									<ul class="filter-values price-slider-cont nav-list px-3"
										[ngClass]="expandedMobileFilter !== 'price' ? 'collapse' : 'show'" id="price">
										<ngx-slider class="mb-4" [(value)]="minPriceValue" [(highValue)]="maxPriceValue"
											[options]="sliderOptions" (userChangeEnd)="priceFilterChanged()">
										</ngx-slider>
									</ul>
								</li>
								<!-- Additional filters -->
								<li class="filter-title">
									<span class="nav-header" (click)="setExpandedMenuFilter('offer')"
										[ngClass]="{ collapsed: expandedMobileFilter !== 'offer' }">{{
										translate.ADDITIONAL_FILTERS }}<fa-icon icon="chevron-right"
											class="float-right"></fa-icon></span>
									<ul class="filter-values nav-list" id="offer"
										[ngClass]="expandedMobileFilter !== 'offer' ? 'collapse' : 'show'">
										<li *ngFor="let offer of productFilters.offer">
											<label class="chbx-container mb-0" [class.checked]="offer.selected">
												<span class="special-offer" [ngClass]="[offer.specialOfferValue]"
													*ngIf="offer.specialOfferValue !== 'V'">{{ offer.specialOfferValue
													}}</span>
												<fa-icon [icon]="faViber" class="viber-icon"
													*ngIf="offer.specialOfferValue === 'V'"></fa-icon>
												- {{ translate.SPECIAL_OFFERS[specialOffer[offer.specialOfferValue]] }}
												<input type="checkbox" [(ngModel)]="offer.selected" (change)="
														listFiltersChanged(
															'OFFER',
															'ADDITIONAL_FILTERS',
															offer.specialOfferValue,
															translate.SPECIAL_OFFERS[specialOffer[offer.specialOfferValue]],
															false,
															offer.selected
														)
													" [ngClass]="{ checked: offer.selected }" />
												<span class="chbx-checkmark">
													<svg-icon key="checkmark"></svg-icon>
												</span>
											</label>
										</li>
									</ul>
								</li>
								<!-- Attribute filters -->
								<li class="filter-title" *ngFor="let filter of productFilters.attributes"
									[hidden]="filter.isAdvancedFilter || filter.values.length < 2">
									<span *ngIf="!filter.isAdvancedFilter && filter.values.length" class="nav-header"
										(click)="setExpandedMenuFilter(filter.attributeCode)"
										[ngClass]="{ collapsed: expandedMobileFilter !== filter.attributeCode }">
										{{ filter.descriptionLocal }}<fa-icon icon="chevron-right" class="float-right">
										</fa-icon>
									</span>
									<ul class="filter-values nav-list" id="{{ filter.attributeCode }}"
										*ngIf="!filter.isAdvancedFilter && filter.values.length"
										[ngClass]="expandedMobileFilter !== filter.attributeCode ? 'collapse' : 'show'">
										<li *ngFor="let item of filter.values">
											<label class="chbx-container mb-0" [class.checked]="item.selected">
												{{ item.optionValueLocal ? item.optionValueLocal :
												translate.NOT_SPECIFIED }}
												<input type="checkbox" [(ngModel)]="item.selected" (change)="
														listFiltersChanged(
															filter.attributeCode,
															filter.description,
															item.optionValue,
															item.optionValue,
															true,
															item.selected
														)
													" [ngClass]="{ checked: item.selected }" />
												<span class="chbx-checkmark">
													<svg-icon key="checkmark"></svg-icon>
												</span>
											</label>
										</li>
									</ul>
								</li>
								<!-- Warranty filters -->
								<li class="filter-title" *ngIf="productFilters.warranty.length">
									<span class="nav-header" (click)="setExpandedMenuFilter('warranty')"
										[ngClass]="{ collapsed: expandedMobileFilter !== 'warranty' }">{{
										translate.WARRANTY }}<fa-icon icon="chevron-right" class="float-right">
										</fa-icon></span>
									<ul class="filter-values nav-list" id="warranty"
										[ngClass]="expandedMobileFilter !== 'warranty' ? 'collapse' : 'show'">
										<li *ngFor="let warranty of productFilters.warranty" class="chbx-container">
											<label class="chbx-container mb-0" [class.checked]="warranty.selected">
												{{ warranty.warranty }}
												<label>
													<input type="checkbox" [(ngModel)]="warranty.selected" (change)="
															listFiltersChanged(
																'WARRANTY',
																'WARRANTY',
																warranty.warranty,
																warranty.warranty,
																false,
																warranty.selected
															)
														" [ngClass]="{ checked: warranty.selected }" />
													<span class="chbx-checkmark">
														<svg-icon key="checkmark"></svg-icon>
													</span>
												</label>
											</label>
										</li>
									</ul>
								</li>
							</div>
						</ul>
					</div>
				</div>
			</div>
			<app-skeleton-screen class="col-12 col-lg-9 special-price-table sp-table"
				*ngIf="columns && observables.productsLoading" [columns]="columns" [translate]="translate">
			</app-skeleton-screen>
			<div id="special-price-table" class="col-12 col-lg-9 special-price-table u-fancy-scrollbar sp-table"
				*ngIf="!observables.productsLoading">
				<p-table #specialPriceTable [value]="products" [autoLayout]="true" [paginator]="true"
					[rows]="defaultTableRows" [rowsPerPageOptions]="[10, 20, 50, { showAll: 'All' }]"
					(onPage)="onRowsNumberChange($event)" [(first)]="first" [showCurrentPageReport]="true"
					currentPageReportTemplate="{{ translate.SHOWING }} {first} {{ translate.TO }} {last} {{
						translate.OF
					}} {totalRecords} {{ translate.ENTRIES }}" [globalFilterFields]="[
						'productName',
						'oldQty',
						'totalQty',
						'productNo',
						'customerPrice',
						'manufacturer',
						'productGroup',
						'qty',
						'reservedQty',
						'warehouse',
						'maxRabat',
						'priceWithRabat',
						'price',
						'specialPrice',
						'productGroupName',
						'eanCode',
						'stockPrice',
						'pmpPrice',
						'forWeb'
					]">
					<ng-template pTemplate="caption">
						<div class="special-price-dropdown"
							*ngIf="isUserAbleToSet && !observables.customerGroupsLoading">
							<ng-select [items]="customerGroups" [(ngModel)]="selectedCustomerGroup" bindLabel="name"
								placeholder="- {{ translate.SELECT_CUSTOMER_GROUP }} -" [virtualScroll]="true"
								(change)="customerGroupFilter($event)" [disabled]="!customerGroups"
								data-tid="customerGroupsDropdown"></ng-select>
							<svg-icon key="down-small"></svg-icon>
						</div>
						<div class="special-price-dropdown select-customer">
							<ng-select placeholder="- {{ translate.SELECT_CUSTOMER }} -" [items]="customerDropdown"
								[(ngModel)]="selectedCustomer" bindLabel="searchText" [virtualScroll]="true"
								(change)="dispatchGetProducts()" [disabled]="!customerDropdown"
								[ngClass]="{ disabled: customersLoading }" data-tid="customerDropdown">
								<ng-template ng-option-tmp let-item="item" let-index="index">
									{{ item.label }}<span class="product-no"> {{ item.no }}</span>
								</ng-template>
							</ng-select>
							<svg-icon key="down-small"></svg-icon>
							<div class="loading-cont customer-loader" *ngIf="customersLoading">
								<div class="spinner-border"></div>
							</div>
						</div>
						<div id="selectWarehouse" class="special-price-dropdown">
							<ng-select [items]="warehouses" [(ngModel)]="selectedWarehouse" bindLabel="label"
								placeholder="- {{ translate.SELECT_WAREHOUSE }} -" (change)="warehouseFilter()"
								[disabled]="!warehouses" data-tid="warehousesDropdown"></ng-select>
							<svg-icon key="down-small"></svg-icon>
						</div>
						<button type="button" class="button px-3"
							(click)="openCustomerFilterModal(customerModalTemplate)"
							[tooltip]="translate.CUSTOMER_FILTER" data-tid="openCustomerFilterModal">
							<fa-icon icon="filter"></fa-icon>
						</button>
						<button type="button" class="button px-3 ml-1"
							(click)="openDeactivateSpecialsModal(removeSpecialsModalTemplate)"
							[tooltip]="translate.REMOVE_SPECIALS" data-tid="openDeactivateSpecialsModal">
							<fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
						</button>
						<button type="button" class="button button-gray download-catalog ml-1"
							(click)="downloadCatalog()" [tooltip]="translate.DOWNLOAD_CATALOG"
							[disabled]="products.length === 0" data-tid="downloadCatalog">
							<button class="empty-button" [disabled]="products.length === 0">
								<fa-icon icon="download"></fa-icon>
							</button>
						</button>
						<div class="global-filter-cont table-search ml-1">
							<fa-icon icon="search" class="search"></fa-icon>
							<input type="text" class="form-control" pInputText size="50"
								[placeholder]="'GLOBAL_FILTER' | translate" data-tid="globalFilter"
								(input)="specialPriceTable.filterGlobal($event.target.value, 'contains')" />
						</div>
						<br />
						<div class="edit-columns-cont float-left mt-1 mr-1">
							<button type="button" class="button button-gray" data-toggle="dropdown"
								[tooltip]="translate.SHOW_COLUMNS" data-tid="showColumns">
								<fa-icon [icon]="faCog"></fa-icon>
							</button>
							<ul class="dropdown-menu" (click)="$event.stopPropagation()">
								<div class="menu-header">{{ translate.SHOW_COLUMNS }}</div>
								<li class="menu-item" *ngFor="let column of columns">
									<label class="chbx-container checked disabled" *ngIf="!column.edit">
										{{ translate[column.title] }}
										<input type="checkbox" [(ngModel)]="column.selected" class="checked" />
										<span class="chbx-checkmark">
											<svg-icon key="checkmark"></svg-icon>
										</span>
									</label>
									<label class="chbx-container" *ngIf="column.edit"
										[ngClass]="{ checked: this[column.edit] }">
										{{ translate[column.title] }}
										<input type="checkbox" [(ngModel)]="this[column.edit]"
											[ngClass]="{ checked: this[column.edit] }" />
										<span class="chbx-checkmark">
											<svg-icon key="checkmark"></svg-icon>
										</span>
									</label>
								</li>
							</ul>
						</div>
						<ul class="currency-menu d-none d-lg-inline-block float-left">
							<li *ngFor="let rate of exchangeRatesCopy" class="exch-rate">
								{{ rate.currencyCode }}: {{ rate.exchangeRate }} {{ environment.defaultExchangeCurrency
								}}
							</li>
						</ul>
						<div class="fifo-legend float-right">
							<div *ngFor="let legend of fifoLegend" class="legend-row">
								<span class="d-inline-block backgr-color" ngClass="{{ legend.color }}"></span> {{
								legend.title }}
							</div>
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th [pSortableColumn]="'forWeb'" *ngIf="showForWeb">
								{{ translate.FOR_WEB }}
								<p-sortIcon [field]="'forWeb'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'productName'">
								{{ translate.PRODUCT }}
								<p-sortIcon [field]="'productName'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'specialOffer'" *ngIf="showSpecialOffer">
								{{ translate.SPECIAL_OFFER }}
								<p-sortIcon [field]="'specialOffer'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'manufacturer'" [style.width.px]="50">
								{{ translate.MANUFACTURER }}
								<p-sortIcon [field]="'manufacturer'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'productGroup'" [style.width.px]="90">
								{{ translate.GROUP }}
								<p-sortIcon [field]="'productGroup'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'qty'" class="text-center">
								{{ translate.IN_STOCKS }}
								<p-sortIcon [field]="'qty'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'reservedQty'" class="text-center">
								{{ translate.RESERVED }}
								<p-sortIcon [field]="'reservedQty'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'totalQty'" *ngIf="showTotalQty">
								{{ translate.TOTAL_QTY }}
								<p-sortIcon [field]="'totalQty'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'oldQty'" *ngIf="showOldQty">
								{{ translate.OLD_QTY }}
								<p-sortIcon [field]="'oldQty'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'price'" class="text-right">
								{{ translate.PRICE }}
								<p-sortIcon [field]="'price'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'priceWithRabat'" class="text-right">
								{{ translate.MIN_PRICE }}
								<p-sortIcon [field]="'priceWithRabat'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'maxRabat'" class="text-center">
								{{ translate.MAX_DISCOUNT }}
								<p-sortIcon [field]="'maxRabat'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'customerPrice'" *ngIf="showCustomerPrice" class="text-right">
								{{ translate.CUSTOMER_PRICE }}
								<p-sortIcon [field]="'customerPrice'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'specialPrice'">
								{{ translate.SPECIAL_PRICE }}
								<p-sortIcon [field]="'specialPrice'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'pmpPrice'" *ngIf="showPMPrice">
								{{ translate.PMP_INFO }}
								<p-sortIcon [field]="'pmpPrice'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'stockPrice'" *ngIf="showStockPrice">
								{{ translate.LAGER_PRICE }}
								<p-sortIcon [field]="'stockPrice'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'ean'" *ngIf="showEAN">
								{{ translate.EAN_CODE }}
								<p-sortIcon [field]="'ean'"></p-sortIcon>
							</th>
							<th [pSortableColumn]="'warehouse'" class="text-center">
								{{ translate.WAREHOUSE }}
								<p-sortIcon [field]="'warehouse'"></p-sortIcon>
							</th>
							<th [style.width.px]="90">&nbsp;</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-product let-i="rowIndex">
						<tr>
							<td class="text-center" *ngIf="showForWeb">
								<svg-icon key="checkmark" *ngIf="product.forWeb" [style.font-size.px]="14"></svg-icon>
							</td>
							<td class="product-name-row">
								<span class="d-block ellipsis-breaker cursor-pointer cursor-pointer" [ngClass]="
										product.statusRed > 0
											? 'red'
											: product.statusPink > 0
											? 'purple'
											: product.statusBlue > 0
											? 'blue'
											: product.statusGreen > 0
											? 'green'
											: ''
									" (click)="getProductSpecifications(viewProductDetailsTemplate, product.productNo, product.productName)"
									[title]="product.productName">{{ product.productName }}</span>
								<span class="product-no">{{ product.productNo }}</span>
							</td>
							<td *ngIf="showSpecialOffer">
								{{
								product.specialOffer
								? translate.SPECIAL_OFFERS[specialOffer[product.specialOffer | specialOfferValue]]
								: ''
								}}
							</td>
							<td>
								{{ product.manufacturer }}
							</td>
							<td class="ellipsis-breaker">
								{{ product.productGroupName }}
							</td>
							<td class="text-center">
								{{ product.qty }}
							</td>
							<td class="text-center">
								{{ product.reservedQty }}
							</td>
							<td class="text-center" *ngIf="showTotalQty">
								{{ product.totalQty }}
							</td>
							<td class="text-center" *ngIf="showOldQty">
								<div class="fifo-box" *ngIf="product.oldQty > 0"
									[ngClass]="{ 'show-on-top': specialPriceTable._first + specialPriceTable._rows - i < 4 }">
									<div class="fifo-title">{{ product.oldQty }}<fa-icon icon="info-circle"></fa-icon>
									</div>
									<div class="info-note">
										<div class="fifo-legend pt-0">
											<h5>{{ translate.FIFO_TITLE }}</h5>
											<div class="text-left d-inline-block m-auto">
												<div *ngFor="let legend of fifoLegend" class="legend-row d-block ml-0">
													<span class="d-inline-block" [style.width.px]="80">
														<span class="d-inline-block backgr-color"
															ngClass="{{ legend.color }}"></span>
														{{ legend.title }}:
													</span>
													<span class="text-color" [ngClass]="legend.color">{{
														product[legend.status] }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</td>
							<td class="text-right">
								{{ product.price | number: '1.2-2' }}
							</td>
							<td class="text-right">
								{{ product.priceWithRabat | number: '1.2-2' }}
							</td>
							<td class="text-center">{{ product.maxRabat }}%</td>
							<td class="text-center" *ngIf="showCustomerPrice">
								{{ product.customerPrice | number: '1.2-2' }}
							</td>
							<td class="text-right nowrap">
								<span>{{ product.specialPrice | number: '1.2-2' }}</span>
								<button type="button" class="button align-middle px-3 ml-1" (click)="
										openAddSpecialActionModal(
											addSpecialActionTemplate,
											product,
											selectedCustomer,
											selectedCustomerGroup
										)
									" [disabled]="
										!(
											(selectedCustomer && selectedCustomer.value !== -1) ||
											(selectedCustomerGroup && selectedCustomerGroup.value !== null)
										)
									" [title]="translate.ADD_SPECIAL_PRICE">
									<div *ngIf="isUserAbleToSet; else viewOnly">
										<fa-icon icon="pencil-alt"></fa-icon>
									</div>
									<ng-template #viewOnly>
										<fa-icon icon="eye"></fa-icon>
									</ng-template>
								</button>
							</td>
							<td class="text-right" *ngIf="showPMPrice">
								{{ product.pmpPrice | number: '1.2-2' }}
							</td>
							<td class="text-right" *ngIf="showStockPrice">
								{{ product.stockPrice | number: '1.2-2' }}
							</td>
							<td class="text-center" *ngIf="showEAN">
								{{ product.eanCode }}
							</td>
							<td class="text-center">
								{{ product.warehouse }}
							</td>
							<td class="text-right nowrap">
								<div class="modal-btns">
									<button type="button" class="button button-gray mr-1"
										(click)="openReservationsModal(reservationsModalTemplate, product)">
										{{ translate.RESERVATIONS }}
									</button>
									<button type="button" class="button button-gray mr-1"
										(click)="openPurchasesModal(purchasesModalTemplate, product)">
										{{ translate.PURCHASES }}
									</button>
									<button type="button" class="button button-gray mr-1"
										(click)="openProductProcurementModal(productProcurementTemplate, product)">
										{{ translate.PRODUCT_PROCUREMENT.OPEN_BUTTON }}
									</button>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td [attr.colspan]="columns.length" class="empty-msg">
								{{ 'NO_DATA' | translate }}
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
	<!-- Add customer special action -->
	<ng-template #addSpecialActionTemplate>
		<div class="add-modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.ADD_SPECIAL_PRICE }}</p>
					</h4>
					<h6 class="product-label mt-4 mb-3 col-12">
						<div class="product-name">{{ translate.PRODUCT }}:</div>
						<div class="overflow-hidden">
							<span class="ellipsis-breaker-modal" [title]="selectedProduct.productName">{{
								selectedProduct.productName
								}}</span>
							<br /><span class="product-no">{{ selectedProduct.productNo }}</span>
						</div>
					</h6>
					<h6 class="product-label mt-4 mb-3 col-12">
						<ng-container *ngIf="selectedCustomer && selectedCustomer.value !== -1">
							<div class="product-name">{{ translate.CUSTOMER }}:</div>
							<div class="overflow-hidden">
								<span>{{ selectedCustomer.value.name }}</span>
								<br /><span class="product-no">{{ selectedCustomer.value.no }}</span>
							</div>
						</ng-container>
						<ng-container *ngIf="
								selectedCustomerGroup &&
								selectedCustomerGroup.value !== null &&
								!(selectedCustomer && selectedCustomer.value !== -1)
							">
							<div class="product-name customer-group-lh">{{ translate.CUSTOMER_GROUP }}:</div>
							<div class="overflow-hidden">
								<span>{{ selectedCustomerGroup.name }}</span>
								<br /><span class="product-no">{{ selectedCustomerGroup.value }}</span>
							</div>
						</ng-container>
					</h6>
				</div>
				<button type="button" class="close pull-right" (click)="addSpecialActionModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form *ngIf="isUserAbleToSet" [formGroup]="specialPriceForm" (ngSubmit)="submitCustomerSpecialPrice()">
					<div class="row mb-2">
						<div class="col-lg-5 col-sm-12 form-control-text">
							{{ translate.SPECIAL_PRICE }}: <span class="required-field">*</span>
						</div>
						<div class="col col-7">
							<input class="form-control" formControlName="price" type="text" numbersOnly decimal="true"
								maxlength="15"
								[ngClass]="{ 'is-invalid': submitted && specialPriceForm.controls.price.errors }"
								(ngModelChange)="this.priceValueChange.next($event)" />
							<div *ngIf="minPriceViolation" class="warring-message-price">
								<fa-icon icon="info-circle"></fa-icon><span>{{ ' ' +
									this.translate.MAX_RABAT_VIOLATION_INFO }} (
									{{ this.selectedProduct.priceWithRabat | number: '1.2-2' }} )
								</span>
							</div>
							<app-form-validation-error-msg
								*ngIf="submitted && specialPriceForm.controls.price.errors; let errors"
								class="invalid-feedback" [errors]="errors" [errorMessages]="translateErrors">
							</app-form-validation-error-msg>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-lg-5 col-sm-12 form-control-text">
							{{ translate.FROM_DATE }} - {{ translate.TO_DATE }}: <span class="required-field">*</span>
						</div>
						<!-- <label class="chbx-container col-lg-3 col-sm-4" [class.checked]="unlimitedDuration">
							<input type="checkbox" [ngClass]="{ checked: unlimitedDuration }" (change)="changeUnlimitedDate()" />
							<span class="chbx-checkmark chbx-checkmark-modal">
								<svg-icon key="checkmark" class="caption-chbx"></svg-icon>
							</span>
							<span class="unlimited">{{ translate.UNLIMITED }}</span>
						</label>
						*ngIf="!unlimitedDuration" -->
						<div class="col col-md-4 col-sm-8">
							<input bsDaterangepicker class="form-control date-picker"
								[bsConfig]="{ rangeInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
								formControlName="date"
								[ngClass]="{ 'is-invalid': submitted && specialPriceForm.controls.date.errors }"
								readonly />
							<app-form-validation-error-msg
								*ngIf="submitted && specialPriceForm.controls.date.errors; let errors"
								class="invalid-feedback" [errors]="errors" [errorMessages]="translateErrors">
							</app-form-validation-error-msg>
						</div>
						<div class="col-12">
							<button type="submit" class="button float-right">
								{{ 'SUBMIT' | translate }}
							</button>
						</div>
					</div>
				</form>
				<div *ngIf="selectedSpecialPrice" id="special-price-view-table" class="row mb-2 sp-table">
					<p-table #specialPriceViewTable [value]="selectedSpecialPrice"
						[paginator]="selectedSpecialPrice.length > 5" [rows]="10" [autoLayout]="true"
						[rowsPerPageOptions]="[5, 10, 20, 50]" [globalFilterFields]="['price']">
						<ng-template pTemplate="header">
							<tr>
								<th></th>
								<th [pSortableColumn]="'price'">
									{{ translate.SPECIAL_PRICE }}
									<p-sortIcon [field]="'price'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'fromDate'">
									{{ translate.DATE }}
									<p-sortIcon [field]="'fromDate'"></p-sortIcon>
								</th>
								<th></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-specialPrice let-i="rowIndex">
							<tr>
								<td class="text-center" *ngIf="specialPrice.customerNo !== null">
									<svg-icon key="users" class="success-message-icon"
										[tooltip]="translate.SPECIAL_PRICE_SET_FOR_CUSTOMER"
										*ngIf="selectedCustomer && selectedCustomer.value !== -1 && specialPrice.customerNo">
									</svg-icon>
								</td>
								<td class="text-center" *ngIf="specialPrice.customerGroupId !== null">
									<svg-icon key="group" class="success-message-icon"
										[tooltip]="translate.SPECIAL_PRICE_SET_FOR_CUSTOMER_GROUP"
										[style.width.px]="24"></svg-icon>
								</td>
								<td>
									{{ specialPrice.price | number: '1.2-2' }}
								</td>
								<td>
									<ng-container *ngIf="specialPrice.toDate">
										{{ specialPrice.fromDate | date: 'dd.MM.yyyy' }} - {{ specialPrice.toDate |
										date: 'dd.MM.yyyy' }}
									</ng-container>
									<ng-container *ngIf="!specialPrice.toDate">
										{{ translate.UNLIMITED }}
									</ng-container>
								</td>
								<td class="nowrap">
									<button *ngIf="specialPrice.active" type="button" class="button button-gray mr-1"
										(click)="deactivateSpecialPrice(specialPrice)" [hidden]="!isUserAbleToSet"
										[disabled]="specialPrice.createdByUsername !== currentUserUsername">
										{{ translate.DEACTIVATE }}
									</button>
									<div *ngIf="!isUserAbleToSet && specialPrice.active" class="ml-3">
										<span class="fw-600">{{ translate.ACTIVE }}</span>
									</div>
									<div *ngIf="!specialPrice.active" class="ml-3">
										<span class="fw-600">{{ translate.DEACTIVATED }}</span>
									</div>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="viewSpecialPriceLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!viewSpecialPriceLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer modal-footer-remove">
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="addSpecialActionModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
				</div>
			</div>
		</div>
	</ng-template>
	<!-- Excel upload error modal -->
	<ng-template #excelUploadModalTemplate>
		<div class="modal-header">
			<div class="w-100 text-center">
				<div *ngIf="
						!importedExcelData.error.length &&
						(!this.importedExcelData.warning || (this.importedExcelData.warning && !importedExcelData.warning.length))
					" class="col-12">
					<div class="icon-container">
						<fa-icon class="success-message-icon" [icon]="['far', 'check-circle']"></fa-icon>
						<br />
						<h2 class="success-message-title">{{ translate.SUCCESS }}</h2>
						<br />
						<h3 class="success-message-subtitle">{{ translate.SUCCESS_EXCEL_IMPORT }}</h3>
						<span class="d-block green" *ngIf="importedExcelData.success.length">
							{{ translate.SUCCESS_ROWS }}: {{ importedExcelData.success.length }}
						</span>
					</div>
				</div>
				<div *ngIf="!importedExcelData.error.length && importedExcelData.warning && importedExcelData.warning.length"
					class="col-12 error-message-container">
					<div class="icon-container">
						<fa-icon class="warning-message-icon" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
						<br />
						<h2 class="error-message-title">{{ translate.WARNING }}</h2>
						<br />
						<h3 class="error-message-subtitle">{{ translate.MAX_RABAT_VIOLATION }}</h3>
						<span class="d-block orange"
							*ngIf="importedExcelData.warning && importedExcelData.warning.length > 0">
							{{ translate.WARNING_ROWS }}: {{ importedExcelData.warning.length }}
						</span>
						<span class="d-block green" *ngIf="importedExcelData.success.length">
							{{ translate.SUCCESS_ROWS }}: {{ importedExcelData.success.length }}
						</span>
					</div>
				</div>
				<div *ngIf="importedExcelData.error.length" class="col-12 error-message-container">
					<div class="icon-container">
						<fa-icon class="error-message-icon" [icon]="['far', 'times-circle']"></fa-icon>
						<br />
						<h2 class="error-message-title">{{ translate.ERROR }}</h2>
						<br />
						<h3 class="error-message-subtitle">{{ translate.ERROR_EXCEL_IMPORT }}</h3>
						<span class="d-block red" *ngIf="importedExcelData.error.length > 0">
							{{ translate.ERROR_ROWS }}: {{ importedExcelData.error.length }}
						</span>
						<span class="d-block orange"
							*ngIf="importedExcelData.warning && importedExcelData.warning.length > 0">
							{{ translate.WARNING_ROWS }}: {{ importedExcelData.warning.length }}
						</span>
						<span class="d-block green" *ngIf="importedExcelData.success.length">
							{{ translate.SUCCESS_ROWS }}: {{ importedExcelData.success.length }}
						</span>
					</div>
				</div>
				<button type="button" class="btn-cont button button-gray mt-3" (click)="downloadExcelReport()"
					*ngIf="importedExcelData.error.length || importedExcelData.warning.length">
					<button class="empty-button">
						<svg-icon key="excel" class="download-excel-button"></svg-icon>
					</button>
					<span class="button-label">{{ translate.DOWNLOAD_REPORT }}</span>
				</button>
			</div>
			<button type="button" class="close pull-right" (click)="excelUploadModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-footer">
			<button type="button" class="button button-gray float-right" (click)="excelUploadModal.hide()">
				{{ 'CLOSE' | translate }}
			</button>
		</div>
	</ng-template>

	<!-- Reservations modal -->
	<ng-template #reservationsModalTemplate>
		<div class="modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.RESERVATIONS }}</p>
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="reservationsModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="reservations" class="reservations-table sp-table">
					<p-table #reservationsTable [value]="reservations" [paginator]="reservations.length > 5" [rows]="10"
						[autoLayout]="true" [rowsPerPageOptions]="[5, 10, 20, 50]"
						[globalFilterFields]="['no', 'partner', 'date', 'salesPerson', 'qty', 'warehouse']">
						<ng-template pTemplate="caption">
							<h6 class="product-label mb-3 col-6 float-left pl-1">
								<div class="product-name">{{ translate.PRODUCT }}:</div>
								<div class="overflow-hidden">
									<span class="ellipsis-breaker-modal" [title]="selectedProduct.productName">{{
										selectedProduct.productName
										}}</span>
									<br /><span class="product-no">{{ selectedProduct.productNo }}</span>
								</div>
							</h6>
							<div class="global-filter-cont">
								<button type="button" class="btn-cont button button-gray mr-1"
									(click)="downloadReservations()" [style.vertical-align]="'bottom'"
									[tooltip]="translate.DOWNLOAD_RESERVATIONS" [disabled]="reservations.length === 0">
									<button class="empty-button" [disabled]="reservations.length === 0">
										<fa-icon icon="download"></fa-icon>
									</button>
								</button>
								<fa-icon icon="search"></fa-icon>
								<input type="text" class="form-control" pInputText size="50"
									[placeholder]="'GLOBAL_FILTER' | translate" data-tid="globalFilterReservation"
									(input)="reservationsTable.filterGlobal($event.target.value, 'contains')" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th [pSortableColumn]="'no'">
									{{ translate.NO }}
									<p-sortIcon [field]="'no'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'partner'">
									{{ translate.PARTNER }}
									<p-sortIcon [field]="'partner'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'date'">
									{{ translate.DATE }}
									<p-sortIcon [field]="'date'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'salesPerson'">
									{{ translate.SALES_PERSON }}
									<p-sortIcon [field]="'salesPerson'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'qty'">
									{{ translate.QTY }}
									<p-sortIcon [field]="'qty'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'warehouse'">
									{{ translate.WAREHOUSE }}
									<p-sortIcon [field]="'warehouse'"></p-sortIcon>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-reservation let-i="rowIndex">
							<tr>
								<td>
									{{ reservation.transactionID }}
								</td>
								<td>
									{{ reservation.customer }}
								</td>
								<td>
									{{ reservation.date | date: 'dd.MM.yyyy' }}
								</td>
								<td>
									{{ reservation.salesPerson }}
								</td>
								<td class="text-center">
									{{ reservation.qty }}
								</td>
								<td>
									{{ reservation.warehouse }}
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="reservationsLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!reservationsLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="reservationsModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Purchases modal -->
	<ng-template #purchasesModalTemplate>
		<div class="modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.PURCHASES }}</p>
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="purchasesModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="purchases" class="purchases-table sp-table">
					<p-table #purchasesTable [value]="purchases" [paginator]="purchases.length > 5" [rows]="10"
						[autoLayout]="true" [rowsPerPageOptions]="[5, 10, 20, 50]" [globalFilterFields]="[
							'documentType',
							'transactionID',
							'partner',
							'partnerNumber',
							'postingDate',
							'qty',
							'unitPrice',
							'unitCost',
							'profit',
							'currency',
							'seller'
						]">
						<ng-template pTemplate="caption">
							<h6 class="product-label mb-3 col-6 float-left pl-1">
								<div class="product-name">{{ translate.PRODUCT }}:</div>
								<div class="overflow-hidden">
									<span class="ellipsis-breaker-modal" [title]="selectedProduct.productName">{{
										selectedProduct.productName
										}}</span>
									<br /><span class="product-no">{{ selectedProduct.productNo }}</span>
								</div>
							</h6>
							<div class="global-filter-cont">
								<button type="button" class="btn-cont button button-gray mr-1"
									(click)="downloadPurchases()" [style.vertical-align]="'bottom'"
									[tooltip]="translate.DOWNLOAD_PURCHASES" [disabled]="purchases.length === 0">
									<button class="empty-button" [disabled]="purchases.length === 0">
										<fa-icon icon="download"></fa-icon>
									</button>
								</button>
								<fa-icon icon="search"></fa-icon>
								<input type="text" class="form-control" pInputText size="50"
									[placeholder]="'GLOBAL_FILTER' | translate" data-tid="globalFilterPurchases"
									(input)="purchasesTable.filterGlobal($event.target.value, 'contains')" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th [pSortableColumn]="'transactionID'">
									{{ translate.DOCUMENT }}
									<p-sortIcon [field]="'transactionID'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'partner'">
									{{ translate.PARTNER }}
									<p-sortIcon [field]="'partner'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'postingDate'">
									{{ translate.POSTING_DATE }}
									<p-sortIcon [field]="'postingDate'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'qty'">
									{{ translate.QTY }}
									<p-sortIcon [field]="'qty'"></p-sortIcon>
								</th>
								<th class="text-right" [pSortableColumn]="'unitPrice'">
									{{ translate.UNIT_PRICE }}
									<p-sortIcon [field]="'unitPrice'"></p-sortIcon>
								</th>
								<th class="text-right" [pSortableColumn]="'unitCost'">
									{{ translate.UNIT_COST }}
									<p-sortIcon [field]="'unitCost'"></p-sortIcon>
								</th>
								<th class="text-right" [pSortableColumn]="'profit'">
									{{ translate.PROFIT }}
									<p-sortIcon [field]="'profit'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'currency'">
									{{ translate.CURRENCY_CODE }}
									<p-sortIcon [field]="'currency'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'seller'">
									{{ translate.SELLER }}
									<p-sortIcon [field]="'seller'"></p-sortIcon>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-purchased let-i="rowIndex">
							<tr>
								<td>
									{{ purchased.transactionID }}
								</td>
								<td class="product-name-row ellipsis-breaker-partner">
									<span>{{ purchased.partner }}</span>
									<span class="product-no"> <br />{{ purchased.partnerNumber }}</span>
								</td>
								<td class="text-center">{{ purchased.postingDate | date: 'dd.MM.yyyy.' }}</td>
								<td class="text-center">
									{{ purchased.qty }}
								</td>
								<td class="text-right">
									{{ purchased.unitPrice | number: '1.2-2' }}
								</td>
								<td class="text-right">
									{{ purchased.unitCost | number: '1.2-2' }}
								</td>
								<td class="text-right">{{ purchased.profit | number: '1.2-2' }}%</td>
								<td class="text-center">
									{{ purchased.currency }}
								</td>
								<td>{{ purchased.seller }}</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="purchasesLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!purchasesLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="purchasesModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Customer filter modal -->
	<ng-template #customerModalTemplate>
		<div class="modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.CUSTOMER_FILTER }}</p>
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="customerFilterModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="advancedSearchData" class="customers-table sp-table">
					<p-table #customersTable [value]="advancedSearchData | searchFilter: searchCustomerAdvancedFilter"
						[paginator]="advancedSearchData.length > 5" [rows]="10" [autoLayout]="true"
						[rowsPerPageOptions]="[5, 10, 20, 50]"
						[globalFilterFields]="['name', 'no', 'address', 'city', 'phoneNumber', 'email']"
						selectionMode="single" [(selection)]="selectedCustomerTable" dataKey="no"
						[(first)]="firstPageAdvancedCustomerFilter">
						<ng-template pTemplate="caption">
							<div class="global-filter-cont mr-3">
								<fa-icon icon="search"></fa-icon>
								<input type="text" class="form-control" pInputText size="50"
									[placeholder]="'GLOBAL_FILTER' | translate" data-tid="globalFilterAdvancedSearch"
									[(ngModel)]="searchCustomerAdvancedFilter" (input)="setTablePage()"
									[disabled]="advancedSearchLoading" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th [pSortableColumn]="'customer'">
									{{ translate.CUSTOMER }}
									<p-sortIcon [field]="'customer'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'address'">
									{{ translate.ADDRESS }}
									<p-sortIcon [field]="'address'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'city'">
									{{ translate.CITY }}
									<p-sortIcon [field]="'city'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'phoneNumber'">
									{{ translate.PHONE_NUMBER }}
									<p-sortIcon [field]="'phoneNumber'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'email'">
									{{ translate.EMAIL }}
									<p-sortIcon [field]="'email'"></p-sortIcon>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-customer let-i="rowIndex">
							<tr [pSelectableRow]="customer">
								<td class="product-name-row">
									<span class="d-block ellipsis-breaker" [title]="customer.name">{{ customer.name
										}}</span>
									<span class="product-no"> {{ customer.no }}</span>
								</td>
								<td>
									{{ customer.address }}
								</td>
								<td>
									{{ customer.city }}
								</td>
								<td>
									{{ customer.phoneNumber }}
								</td>
								<td>
									{{ customer.email }}
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="advancedSearchLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!advancedSearchLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="customerFilterModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button float-right" (click)="selectCustomer()"
					[disabled]="!advancedSearchData.length || !selectedCustomerTable">
					{{ translate.SELECT_CUSTOMER }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Deputy modal -->
	<ng-template #deputyModalTemplate>
		<div class="deputy-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.ADD_DEPUTY }}</p>
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="deputyModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body deputy-select-container" *ngIf="deputyList.length > 0">
				<div class="row m-0 deputy-select-box">
					<div class="select-box col-6">
						<div class="items">
							<div class="filter-box p-2">
								<input type="text" class="form-control" [(ngModel)]="deputySearch"
									[placeholder]="'SEARCH' | translate" />
								<fa-icon icon="times" class="remove-filter" (click)="deputySearch = null"
									*ngIf="deputySearch" [title]="'CLEAR_FILTER' | translate"></fa-icon>
							</div>
							<ul class="u-fancy-scrollbar">
								<li *ngFor="let deputy of deputyList | customSearchByProperty: deputySearch:'deputyName'"
									(click)="selectDeputy(deputy)" [ngClass]="{ selected: deputy.selected }">
									{{ deputy.deputyName }}
									<fa-icon icon="check" class="float-right" *ngIf="deputy.selected"></fa-icon>
								</li>
							</ul>
						</div>
					</div>
					<div class="select-box col-6">
						<div class="items selected-items">
							<span class="title">
								{{ selectedDeputies.length ? translate.SELECTED_DEPUTIES :
								translate.NO_DEPUTIES_SELECTED }}
							</span>
							<ul class="m-0 u-fancy-scrollbar">
								<li *ngFor="let item of selectedDeputies; let i = index">
									<fa-icon icon="times" (click)="removeDeputy(item, i)"></fa-icon>{{ item.deputyName
									}}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="deputyModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button float-right" (click)="submitAddDeputy()"
					[disabled]="disabledAddDeputy">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Product details modal -->
	<ng-template #viewProductDetailsTemplate>
		<div class="product-details-container">
			<div class="modal-header">
				<div class="row m-0">
					<h4 class="modal-title col-12">
						{{ translate.PRODUCT_DETAILS }}
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="viewProductDetailsModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="observables.productDetailsLoading" class="row filter-boxes-wrapper loading py-5">
					<div class="loader-spinner m-auto">
						<div class="spinner-border d-block m-auto"></div>
						<span>{{ 'LOADING' | translate }}</span>
					</div>
				</div>
				<div id="specifications-cont" class="col-12 product-tech-spec py-3"
					*ngIf="!observables.productDetailsLoading && productDetails">
					<table>
						<tbody>
							<tr>
								<td class="left-col">{{ translate.PRODUCT_NO }}</td>
								<td class="right-col">{{ productDetails.productNo }}</td>
							</tr>
							<tr>
								<td class="left-col">{{ translate.PRODUCT_NAME }}</td>
								<td class="right-col">{{ productDetails.productName }}</td>
							</tr>
							<tr *ngFor="let spec of productDetails.specs"
								[hidden]="!spec.value || spec.code === 'PMPPrice'">
								<td class="left-col">{{ spec.name }}</td>
								<td class="right-col">
									{{ spec.code !== 'ADDSPECIFICATIONS' ? spec.value : '' }}
									<a *ngIf="spec.code === 'ADDSPECIFICATIONS'"
										[href]="environment.mediaLocation.specificationData + productDetails.productNo + '.pdf'"
										target="_blank">{{ productDetails.productNo + '.pdf' }}</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-12 product-tech-spec py-3"
					*ngIf="!observables.productDetailsLoading && !productDetails">
					<p>{{ translate.EMPTY_DETAILS }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="viewProductDetailsModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button button-gray mr-1 float-right" (click)="downloadProductDetails()"
					[style.vertical-align]="'bottom'" [tooltip]="translate.DOWNLOAD_PRODUCT_DETAILS"
					[disabled]="!observables.productDetailsLoading && !productDetails">
					<button class="empty-button" [disabled]="!observables.productDetailsLoading && !productDetails">
						<fa-icon icon="download"></fa-icon>
					</button>
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Product procurement modal -->
	<ng-template #productProcurementTemplate>
		<div class="modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.PRODUCT_PROCUREMENT.TITLE }}</p>
					</h4>
				</div>
				<button type="button" class="close pull-right" (click)="productProcurementModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="productProcurement" class="product-procurement-table sp-table">
					<p-table #productProcurementTable [value]="productProcurement"
						[paginator]="productProcurement.length > 5" [rows]="10" [autoLayout]="true"
						[rowsPerPageOptions]="[5, 10, 20, 50]"
						[globalFilterFields]="['partner', 'date', 'qty', 'price', 'currency', 'warehouse']">
						<ng-template pTemplate="caption">
							<h6 class="product-label mb-3 col-6 float-left pl-1">
								<div class="product-name">{{ translate.PRODUCT }}:</div>
								<div class="overflow-hidden">
									<span class="ellipsis-breaker-modal" [title]="selectedProduct.productName">{{
										selectedProduct.productName
										}}</span>
									<br /><span class="product-no">{{ selectedProduct.productNo }}</span>
								</div>
							</h6>
							<div class="global-filter-cont">
								<button type="button" class="btn-cont button button-gray mr-1"
									(click)="downloadProcurements()" [style.vertical-align]="'bottom'"
									[tooltip]="translate.DOWNLOAD_PROCUREMENTS"
									[disabled]="productProcurement.length === 0">
									<button class="empty-button" [disabled]="productProcurement.length === 0">
										<fa-icon icon="download"></fa-icon>
									</button>
								</button>
								<fa-icon icon="search"></fa-icon>
								<input type="text" class="form-control" pInputText size="50"
									[placeholder]="'GLOBAL_FILTER' | translate"
									data-tid="globalFilterProductProcurement"
									(input)="productProcurementTable.filterGlobal($event.target.value, 'contains')" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th [pSortableColumn]="'partner'">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.PARTNER }}
									<p-sortIcon [field]="'partner'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'date'" class="text-center">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.DATE }}
									<p-sortIcon [field]="'date'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'qty'" class="text-right">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.QTY }}
									<p-sortIcon [field]="'qty'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'price'" class="text-right">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.PRICE }}
									<p-sortIcon [field]="'price'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'currency'" class="text-center">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.CURRENCY }}
									<p-sortIcon [field]="'currency'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'warehouse'" class="text-center">
									{{ translate.PRODUCT_PROCUREMENT.COLUMNS.WAREHOUSE }}
									<p-sortIcon [field]="'warehouse'"></p-sortIcon>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-procurement let-i="rowIndex">
							<tr>
								<td>
									{{ procurement.partner }}
								</td>
								<td class="text-center">
									{{ procurement.date | date: 'dd.MM.yyyy' }}
								</td>
								<td class="text-right">
									{{ procurement.qty }}
								</td>
								<td class="text-right">
									{{ procurement.price }}
								</td>
								<td class="text-center">
									{{ procurement.currency }}
								</td>
								<td class="text-center">
									{{ procurement.warehouse }}
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="productProcurementLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!productProcurementLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="productProcurementModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Latest changes modal -->
	<ng-template #latestChangesModalTemplate>
		<div class="latest-changes-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.LATEST_CHANGES }}</p>
					</h4>
					<h3 class="modal-subtitle col-12 text-center">
						<p>{{ translate.LAST_SEVEN_DAYS_CHANGES }}</p>
					</h3>
				</div>
				<button type="button" class="close pull-right" (click)="latestChangesModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row m-0 latest-changes-table-container sp-table">
					<p-table #latestChangesTable [value]="latestChangesList" [autoLayout]="true" [paginator]="true"
						[rows]="10" class="col-12" [rowsPerPageOptions]="[5, 10, 20, 50]" [globalFilterFields]="[
							'productName',
							'productNo',
							'customerName',
							'customerNo',
							'created',
							'specialPrice',
							'oldSpecialPrice',
							'manufacturer',
							'productGroup',
							'sellerName',
							'price',
							'priceWithRabat',
							'portalPrice'
						]" [(first)]="firstPageLatestChanges">
						<ng-template pTemplate="caption">
							<div class="special-price-dropdown select-customer latest-changes-dropdown">
								<ng-select placeholder="- {{ translate.SELECT_CUSTOMER }} -" [items]="customerDropdown"
									[(ngModel)]="selectedCustomerLatestChanges" (change)="filterLatestChanges()"
									bindLabel="searchText" [virtualScroll]="true">
									<ng-template ng-option-tmp let-item="item" let-index="index">
										{{ item.label }}<span class="product-no"> {{ item.no }}</span>
									</ng-template>
								</ng-select>
								<svg-icon key="down-small"></svg-icon>
								<div class="loading-cont customer-loader" *ngIf="latestChangesLoading">
									<div class="spinner-border"></div>
								</div>
							</div>
							<div class="global-filter-cont">
								<button type="button" class="button button-gray mr-1" (click)="downloadLatestChanges()"
									[style.vertical-align]="'bottom'" [tooltip]="translate.DOWNLOAD_LATEST_CHANGES"
									[disabled]="latestChangesList.length === 0">
									<button class="empty-button" [disabled]="latestChangesList.length === 0">
										<fa-icon icon="download"></fa-icon>
									</button>
								</button>
								<fa-icon icon="search" class="search"></fa-icon>
								<input type="text" class="form-control" pInputText size="50"
									[placeholder]="'GLOBAL_FILTER' | translate" data-tid="globalFilterLatestChanges"
									(input)="latestChangesTable.filterGlobal($event.target.value, 'contains')" />
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th [pSortableColumn]="'productName'">
									{{ translate.PRODUCT }}
									<p-sortIcon [field]="'productName'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'customerName'">
									{{ translate.CUSTOMER }}
									<p-sortIcon [field]="'customerName'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'manufacturer'">
									{{ translate.MANUFACTURER }}
									<p-sortIcon [field]="'manufacturer'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'productGroup'">
									{{ translate.PRODUCT_GROUP }}
									<p-sortIcon [field]="'productGroup'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'sellerName'">
									{{ translate.SELLER }}
									<p-sortIcon [field]="'sellerName'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'created'">
									{{ translate.DATE_CHANGED }}
									<p-sortIcon [field]="'created'"></p-sortIcon>
								</th>
								<!-- <th [pSortableColumn]="'oldSpecialPrice'">
									{{ translate.OLD_SPECIAL_PRICE }}
									<p-sortIcon [field]="'oldSpecialPrice'"></p-sortIcon>
								</th> -->
								<th [pSortableColumn]="'price'">
									{{ translate.PRICE }}
									<p-sortIcon [field]="'price'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'priceWithRabat'">
									{{ translate.MIN_PRICE }}
									<p-sortIcon [field]="'priceWithRabat'"></p-sortIcon>
								</th>
								<th [pSortableColumn]="'specialPrice'">
									{{ translate.SPECIAL_PRICE }}
									<p-sortIcon [field]="'specialPrice'"></p-sortIcon>
								</th>
								<!-- <th [pSortableColumn]="'customerPrice'">
									{{ translate.CUSTOMER_PRICE }}
									<p-sortIcon [field]="'customerPrice'"></p-sortIcon>
								</th> -->
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-specialPrice let-i="rowIndex">
							<tr>
								<td class="product-name-row">
									<span class="d-block ellipsis-breaker" [title]="specialPrice.productName">{{
										specialPrice.productName
										}}</span>
									<span class="product-no">{{ specialPrice.productNo }}</span>
								</td>
								<td class="product-name-row">
									<span class="d-block ellipsis-breaker" [title]="specialPrice.customerName">{{
										specialPrice.customerName
										}}</span>
									<span class="product-no">{{ specialPrice.customerNo }}</span>
								</td>
								<td>
									{{ specialPrice.manufacturer }}
								</td>
								<td class="text-nowrap">
									{{ specialPrice.productGroup }}
								</td>
								<td class="text-nowrap">
									{{ specialPrice.sellerName }}
								</td>
								<td class="text-center">
									{{ specialPrice.created | date: 'dd.MM.yyyy' }}
								</td>
								<!-- <td class="text-right">
									{{ specialPrice.oldSpecialPrice | number: '1.2-2' }}
								</td> -->
								<td class="text-right">
									{{ specialPrice.price | number: '1.2-2' }}
								</td>
								<td class="text-right">
									{{ specialPrice.priceWithRabat | number: '1.2-2' }}
								</td>
								<td class="text-right">
									{{ specialPrice.specialPrice | number: '1.2-2' }}
								</td>
								<!-- <td class="text-right">
									{{ specialPrice.portalPrice | number: '1.2-2' }}
								</td> -->
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr *ngIf="latestChangesLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									<div class="loading-cont mt-4">
										<div class="spinner-border"></div>
										<span class="d-block">{{ 'LOADING' | translate }}</span>
									</div>
								</td>
							</tr>
							<tr *ngIf="!latestChangesLoading">
								<td [attr.colspan]="columns.length" class="empty-msg">
									{{ 'NO_DATA' | translate }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="button button-gray float-right" (click)="latestChangesModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
	<!-- Remove specials modal -->
	<ng-template #removeSpecialsModalTemplate>
		<div class="modal-container">
			<div class="modal-header">
				<div class="row">
					<h4 class="modal-title col-12">
						<p>{{ translate.REMOVE_SPECIALS }}</p>
					</h4>
					<h3 class="modal-subtitle col-12 text-center">
						<p>{{ translate.REMOVE_SPECIALS_SUBTITLE_CUSTOMERS }}</p>
					</h3>
				</div>
				<button type="button" class="close pull-right" (click)="removeSpecialsModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p-tabView (onChange)="switchTab()">
					<p-tabPanel header="Customers">
						<div *ngIf="advancedSearchData" class="deactivate-special-table customers-table sp-table">
							<p-table #deactivateSpecialTable [autoLayout]="true" class="col-12" [value]="customerData"
								[(selection)]="selectedCustomersDeactivate" selectionMode="multiple" [paginator]="true"
								[rows]="10" [rowsPerPageOptions]="[5, 10, 20, 50]" dataKey="no"
								[globalFilterFields]="['no', 'name', 'customer', 'address', 'city', 'phoneNumber', 'email']">
								<ng-template pTemplate="caption">
									<div class="global-filter-cont table-search ml-1">
										<fa-icon icon="search" class="search"></fa-icon>
										<input type="text" class="form-control" pInputText size="50"
											[placeholder]="'GLOBAL_FILTER' | translate"
											data-tid="globalFilterDeactivate"
											(input)="deactivateSpecialTable.filterGlobal($event.target.value, 'contains')" />
									</div>
								</ng-template>
								<ng-template pTemplate="header">
									<tr>
										<th [pSortableColumn]="'customer'">
											{{ translate.CUSTOMER }}
											<p-sortIcon [field]="'customer'"></p-sortIcon>
										</th>
										<th [pSortableColumn]="'address'">
											{{ translate.ADDRESS }}
											<p-sortIcon [field]="'address'"></p-sortIcon>
										</th>
										<th [pSortableColumn]="'city'">
											{{ translate.CITY }}
											<p-sortIcon [field]="'city'"></p-sortIcon>
										</th>
										<th [pSortableColumn]="'phoneNumber'">
											{{ translate.PHONE_NUMBER }}
											<p-sortIcon [field]="'phoneNumber'"></p-sortIcon>
										</th>
										<th [pSortableColumn]="'email'">
											{{ translate.EMAIL }}
											<p-sortIcon [field]="'email'"></p-sortIcon>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-customer>
									<tr [pSelectableRow]="customer">
										<td class="product-name-row">
											<span class="d-block ellipsis-breaker" [title]="customer.name">{{
												customer.name }}</span>
											<span class="product-no"> {{ customer.no }}</span>
										</td>
										<td>
											{{ customer.address }}
										</td>
										<td>
											{{ customer.city }}
										</td>
										<td>
											{{ customer.phoneNumber }}
										</td>
										<td>
											{{ customer.email }}
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage">
									<tr *ngIf="advancedSearchLoading">
										<td [attr.colspan]="columns.length" class="empty-msg">
											<div class="loading-cont mt-4">
												<div class="spinner-border"></div>
												<span class="d-block">{{ 'LOADING' | translate }}</span>
											</div>
										</td>
									</tr>
									<tr *ngIf="!advancedSearchLoading">
										<td [attr.colspan]="columns.length" class="empty-msg">
											{{ 'NO_DATA' | translate }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
						<div class="modal-footer">
							<button type="button" class="button button-gray float-right"
								(click)="removeSpecialsModal.hide()">
								{{ 'CLOSE' | translate }}
							</button>
							<button id="removeSpecialsByCustomer" type="button" class="button float-right"
								(click)="removeSpecialsByCustomer()"
								[disabled]="!selectedCustomersDeactivate.length || !selectedCustomersDeactivate">
								{{ translate.REMOVE_SPECIALS }}
							</button>
						</div>
					</p-tabPanel>
					<p-tabPanel header="Customer groups">
						<div *ngIf="deactivateCustomerGroups" class="deactivate-special-table customers-table sp-table">
							<p-table #deactivateSpecialGroupsTable [autoLayout]="true" class="col-12"
								[value]="deactivateCustomerGroups" [(selection)]="selectedCustomerGroupsDeactivate"
								selectionMode="multiple" [paginator]="true" [rows]="10"
								[rowsPerPageOptions]="[5, 10, 20, 50]" dataKey="value" [globalFilterFields]="['name']">
								<ng-template pTemplate="caption">
									<div class="global-filter-cont table-search ml-1">
										<fa-icon icon="search" class="search"></fa-icon>
										<input type="text" class="form-control" pInputText size="50"
											[placeholder]="'GLOBAL_FILTER' | translate"
											data-tid="globalFilterDeactivateGroup"
											(input)="deactivateSpecialGroupsTable.filterGlobal($event.target.value, 'contains')" />
									</div>
								</ng-template>
								<ng-template pTemplate="header">
									<tr>
										<th [pSortableColumn]="'name'">
											{{ translate.CUSTOMER_GROUP }}
											<p-sortIcon [field]="'name'"></p-sortIcon>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-customerGroup>
									<tr [pSelectableRow]="customerGroup">
										<td>
											{{ customerGroup.name }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
						<div class="modal-footer">
							<button type="button" class="button button-gray float-right"
								(click)="removeSpecialsModal.hide()">
								{{ 'CLOSE' | translate }}
							</button>
							<button id="removeSpecialsByCustomerGroup" type="button" class="button float-right"
								(click)="removeSpecialsByCustomerGroup()"
								[disabled]="!selectedCustomerGroupsDeactivate.length || !selectedCustomerGroupsDeactivate">
								{{ translate.REMOVE_SPECIALS }}
							</button>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</ng-template>
</div>