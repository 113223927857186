import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { User } from 'src/app/shared/models/user';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/sr/environment';
import { AuthService } from '../authentication/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	translate: any;
	wrongFields = false;
	missingFields = false;
	user: User = new User();
	loginForm: any;
	environment = environment;

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private authService: AuthService,
		private translateService: TranslateService,
		private formBuilder: FormBuilder
	) {
		this.loginForm = this.formBuilder.group({
			username: '',
			password: '',
		});
	}

	ngOnInit(): void {
		if (environment.isOAuthEnabled) {
			this.authService.login('/');
		} else {
			this.translateService.get('LOGIN').subscribe((resp: any) => (this.translate = resp));

			if (this.authenticationService.currentUserValue) {
				this.router.navigate(['/']);
			} else {
				this.user = new User();
			}
		}
	}

	login(): void {
		if (!this.loginForm.value.username || !this.loginForm.value.password) {
			this.missingFields = true;
			this.wrongFields = false;
		} else {
			this.missingFields = false;
			this.authenticationService.login(this.loginForm.value).subscribe(user => {
				if (user) {
					this.router.navigate(['/']);
				} else {
					this.wrongFields = true;
				}
			});
		}
	}
}
