import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ServiceState } from '../root-store/service-store/service.reducer';
import { Service } from '../shared/models/service';
import * as serviceSelectors from '../root-store/service-store/service.selectors';
import * as serviceActions from '../root-store/service-store/service.actions';
import { Update } from '@ngrx/entity';

@Component({
	selector: 'app-registered-service',
	templateUrl: './registered-service.component.html',
	styleUrls: ['./registered-service.component.scss'],
})
export class RegisteredServiceComponent implements OnInit {
	translate: any;
	translateFormValidationErrorMsgs: any;
	firstPageElementIndex = 0;
	serviceLoading$: Observable<boolean>;
	services$: Observable<Service[]>;
	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'manufacturer', title: 'MANUFACTURER', width: 30 },
		{ name: 'productType', title: 'PRODUCT_TYPE', width: 25 },
		{ name: 'city', title: 'CITY', width: 25 },
		{ name: 'serviceName', title: 'SERVICE_NAME', width: 15 },
		{ name: 'address', title: 'ADDRESS', width: 15 },
		{ name: 'phone', title: 'PHONE', width: 15 },
		{ name: 'workHour', title: 'WORK_HOUR', width: 15 },
		{ name: 'web', title: 'WEB', width: 15 },
	];

	submitted: boolean;
	registeredServiceModal: BsModalRef;
	deleteRegisteredServiceModal: BsModalRef;
	serviceForm: any;
	currentService: Service;

	constructor(
		private translateService: TranslateService,
		private store: Store<ServiceState>,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.translateService.get('REGISTERED_SERVICE').subscribe((resp: any) => (this.translate = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
		this.services$ = this.store.select(serviceSelectors.selectService);
		this.serviceLoading$ = this.store.select(serviceSelectors.selectServiceLoading);
		this.store.dispatch(serviceActions.loadServices());
	}

	openRegisteredServiceModal(registeredServiceTemplate: TemplateRef<any>, service: Service) {
		this.submitted = false;
		this.serviceForm = this.formBuilder.group(
			service
				? {
						...service,
						manufacturer: [service.manufacturer, [Validators.required, Validators.maxLength(100)]],
						productType: [service.productType, [Validators.maxLength(50)]],
						city: [service.city, [Validators.required, Validators.maxLength(100)]],
						serviceName: [service.serviceName, [Validators.required, Validators.maxLength(200)]],
						address: [service.address, [Validators.required, Validators.maxLength(255)]],
						phone: [service.phone, [Validators.maxLength(30)]],
						workHour: [service.workHour, [Validators.maxLength(20)]],
						web: [service.web, [Validators.maxLength(50)]],
				  }
				: {
						...new Service(),
						manufacturer: ['', [Validators.required, Validators.maxLength(100)]],
						productType: ['', [Validators.maxLength(50)]],
						city: ['', [Validators.required, Validators.maxLength(100)]],
						serviceName: ['', [Validators.required, Validators.maxLength(200)]],
						address: ['', [Validators.required, Validators.maxLength(255)]],
						phone: ['', [Validators.maxLength(30)]],
						workHour: ['', [Validators.maxLength(20)]],
						web: ['', [Validators.maxLength(50)]],
				  }
		);

		this.registeredServiceModal = this.modalService.show(registeredServiceTemplate, {
			class: 'modal-md',
			ignoreBackdropClick: true,
		});
	}

	submitRegisteredServiceForm(): void {
		this.submitted = true;
		if (this.serviceForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}
		if (this.serviceForm.value.id) {
			const tempService: Update<Service> = {
				id: this.serviceForm.value.id,
				changes: this.serviceForm.value,
			};
			this.store.dispatch(serviceActions.updateService({ service: tempService }));
		} else {
			const service: Service = this.serviceForm.value;
			this.store.dispatch(serviceActions.addService({ service }));
			this.firstPageElementIndex = 0;
		}

		this.registeredServiceModal.hide();
	}

	openConfirmationModal(deleteRegisteredServiceTemplate: TemplateRef<any>, service) {
		this.currentService = service;
		this.deleteRegisteredServiceModal = this.modalService.show(deleteRegisteredServiceTemplate, { class: 'modal-md' });
	}

	deleteRegisteredService(): void {
		this.store.dispatch(serviceActions.deleteService({ id: this.currentService.id }));
		this.deleteRegisteredServiceModal.hide();
		this.currentService = null;
	}
}
