import { featuresFeatureKey, FeatureState, selectAll } from './feature.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectFeatureState = createFeatureSelector<FeatureState>(featuresFeatureKey);

export const selectAllFeatures = createSelector(selectFeatureState, selectAll);

export const selectFeaturesLoading = createSelector(
	selectFeatureState,
	state => state.statusState === LoadingState.LOADING
);

export const selectFeaturesError = createSelector(selectFeatureState, (state: FeatureState) =>
	getError(state.statusState)
);
