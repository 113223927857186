import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/sr/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AuthService } from './core/authentication/auth.service';
import { LanguageService } from './core/services/language.service';
import { Observable, Subject } from 'rxjs';
import { MetaService } from './core/services/meta.service';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { MenuState } from './root-store/menu-store/menu.reducer';
import { Store } from '@ngrx/store';
import * as menuSelectors from './root-store/menu-store/menu.selector';
import * as menuActions from './root-store/menu-store/menu.actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	showNavbar = false;
	isDoneLoading$: Observable<boolean>;
	environment = environment;
	ngUnsubscribe: Subject<object> = new Subject();
	deactivatedMenuLoading$: Observable<boolean>;

	constructor(
		public translate: TranslateService,
		private router: Router,
		public authenticationService: AuthenticationService,
		private languageService: LanguageService,
		public authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private metaService: MetaService,
		private store: Store<MenuState>
	) {
		translate.addLangs(this.environment.options.languages);
		if (this.languageService.currentLanguageValue) {
			this.translate.setDefaultLang(this.languageService.currentLanguageValue);
		} else {
			this.translate.setDefaultLang(environment.defaultLanguage);
		}
	}

	ngOnInit(): void {
		const localStorageValue = localStorage.getItem('showNavbar');
		localStorageValue ? (this.showNavbar = true) : (this.showNavbar = false);
		if (environment.isOAuthEnabled) {
			this.isDoneLoading$ = this.authService.isDoneLoading$;
			this.authService.runInitialLoginSequence();
			this.router.events
				.pipe(
					filter(event => event instanceof NavigationEnd),
					map(() => this.activatedRoute),
					map(route => {
						while (route.firstChild) {
							route = route.firstChild;
						}
						return route;
					}),
					filter(route => route.outlet === 'primary'),
					mergeMap(route => route.data),
					tap(({ title, description }: Data) => {
						this.metaService.updateTitle('CT4 Admin - ' + title);
						this.metaService.updateDescription(description);
					})
				)
				.subscribe();
		} else {
			this.authService.isDoneLoadingSubject$.next(true);

			if (!this.authenticationService.currentUserValue) {
				this.router.navigate(['/login']);
			}
		}
		if (this.authService.currentUserValue) {
			this.store.dispatch(menuActions.loadDeactivatedMenu());
			this.deactivatedMenuLoading$ = this.store.select(menuSelectors.selectDeactivatedMenuLoading);
			this.store
				.select(menuSelectors.selectAllDeactivatedMenu)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(data => {
					this.authService.deactivatedMenu = data;
				});
		}
	}

	toggleNavbar() {
		this.showNavbar = !this.showNavbar;
		this.showNavbar
			? localStorage.setItem('showNavbar', this.showNavbar.toString())
			: localStorage.removeItem('showNavbar');
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
