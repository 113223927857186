import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { ViberPromotion } from '../../shared/models/viber-promotion';

export const loadViberPromotions = createAction('[Viber Promotions Page] Load Viber Promotions');
export const loadViberPromotionsSuccess = createAction(
	'[Viber Promotion API] Load Viber Promotions Success',
	props<{ viberPromotions: ViberPromotion[] }>()
);
export const loadViberPromotionsFailure = createAction(
	'[Viber Promotion API] Load Viber Promotions Failed',
	props<{ error: any }>()
);

export const addViberPromotion = createAction(
	'[Viber Promotion Page] Add Viber Promotion',
	props<{ promotion: ViberPromotion }>()
);
export const addViberPromotionSuccess = createAction(
	'[Viber Promotion API] Add Viber Promotion Success',
	props<{ updatedPromotions: ViberPromotion[] }>()
);
export const addViberPromotionFailure = createAction(
	'[Viber Promotion API] Add Viber Promotion Failure',
	props<{ error: any }>()
);

export const updateViberPromotion = createAction(
	'[Viber Promotion Page] Update Viber Promotion',
	props<{ promotion: Update<ViberPromotion> }>()
);
export const updateViberPromotionSuccess = createAction(
	'[Viber Promotion API] Update Viber Promotion Success',
	props<{ promotion: Update<ViberPromotion>[] }>()
);
export const updateViberPromotionFailure = createAction(
	'[Viber Promotion API] Update Viber Promotion Failure',
	props<{ error: any }>()
);
