import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { GroupConnection } from '../../shared/models/group-connection';

export const loadGroupConnections = createAction('[Group Connection Page] Load Group Connections');

export const loadGroupConnectionsSuccess = createAction(
	'[Group Connection API] Loaded Group Connections Success',
	props<{ groupConnections: GroupConnection[] }>()
);

export const loadGroupConnectionsFailure = createAction(
	'[Group Connection API] Loaded Group Connections Failure',
	props<{ error: any }>()
);

export const addGroupConnection = createAction(
	'[Group Connection Page] Add Group Connection',
	props<{ groupConnection: GroupConnection }>()
);

export const addGroupConnectionSuccess = createAction(
	'[Group Connection API] Add Group Connection Success',
	props<{ groupConnection: GroupConnection }>()
);

export const addGroupConnectionFailure = createAction(
	'[Group Connection API] Add Group Connection Failure',
	props<{ error: any }>()
);

export const updateGroupConnection = createAction(
	'[Group Connection Page] Update Group Connection',
	props<{ groupConnection: Update<GroupConnection> }>()
);

export const updateGroupConnectionSuccess = createAction(
	'[Group Connection API] Update Group Connection Success',
	props<{ groupConnection: Update<GroupConnection> }>()
);

export const updateGroupConnectionFailure = createAction(
	'[Group Connections API] Update Group Connection Failure',
	props<{ error: any }>()
);
export const removeGroupConnection = createAction(
	'[Group Connections Page] Remove Group Connection',
	props<{ id: number }>()
);

export const removeGroupConnectionSuccess = createAction(
	'[Group Connections API] Remove Group Connection By Id Success',
	props<{ id: number }>()
);

export const removeGroupConnectionFailure = createAction(
	'[Group Connections API] Remove Group Connection By Id Failure',
	props<{ error: any }>()
);
