import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GroupConnection } from 'src/app/shared/models/group-connection';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable({
	providedIn: 'root',
})
export class GroupConnectionsService {
	constructor(private http: HttpClient) {}

	getGroupConnections(): Observable<GroupConnection[]> {
		return this.http.get<GroupConnection[]>(environment.baseApiUrl + 'api/ProductGroup/Connections');
	}
	addGroupConnection(groupConnection: GroupConnection): Observable<GroupConnection> {
		return this.http.post<GroupConnection>(environment.baseApiUrl + 'api/ProductGroup/Connections', groupConnection);
	}
	updateGroupConnection(groupConnection: Update<GroupConnection>): Observable<Update<GroupConnection>> {
		return this.http
			.put<Update<GroupConnection>>(environment.baseApiUrl + 'api/ProductGroup/Connections', groupConnection.changes)
			.pipe(map(() => groupConnection));
	}
	removeGroupConnection(id: number) {
		return this.http.delete(environment.baseApiUrl + 'api/ProductGroup/Connections/' + id).pipe(map(() => id));
	}
}
