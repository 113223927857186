import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductImage } from 'src/app/shared/models/product-image';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';

@Injectable()
export class ProductImageService {
	constructor(private http: HttpClient) {}

	getProductImages(filter: string): Observable<ProductImage[]> {
		return this.http.get<ProductImage[]>(
			environment.baseImagesApiUrl + 'api/productimages/' + encodeURIComponent(filter)
		);
	}

	addProductImages(productImages: ProductImage[]): Observable<ProductImage[]> {
		return this.http.post<ProductImage[]>(environment.baseImagesApiUrl + 'api/productimages/', productImages);
	}

	changeImageStatus(productImage: ProductImage): Observable<ProductImage> {
		return this.http.put<ProductImage>(environment.baseImagesApiUrl + 'api/productimages/StatusImage/', productImage);
	}

	setDefaultImage(productImage: ProductImage): Observable<ProductImage> {
		return this.http.put<ProductImage>(environment.baseImagesApiUrl + 'api/productimages/DefaultImage/', productImage);
	}

	changeImageOrder(productImages: ProductImage[]): Observable<ProductImage[]> {
		return this.http.put<ProductImage[]>(environment.baseImagesApiUrl + 'api/productimages/OrderImage/', productImages);
	}
}
