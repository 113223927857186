import { createAction, props } from '@ngrx/store';

import { Promotion } from '../../shared/models/promotion';
import { Update } from '@ngrx/entity';

export const loadPromotions = createAction('[Promotions Page] Load Promotions');
export const loadPromotionsSuccess = createAction(
	'[Promotion API] Load Promotions Success',
	props<{ promotions: Promotion[] }>()
);
export const loadPromotionsFailure = createAction('[Promotion API] Load Promotions Failed', props<{ error: any }>());

export const loadActivePromotions = createAction('[Promotions Page] Load Active Promotions');
export const loadActivePromotionsSuccess = createAction(
	'[Promotion API] Load Active Promotions Success',
	props<{ promotions: Promotion[] }>()
);
export const loadActivePromotionsFailure = createAction(
	'[Promotion API] Load Active Promotions Failed',
	props<{ error: any }>()
);

export const addPromotion = createAction('[Promotion Page] Add Promotion', props<{ promotion: Promotion }>());
export const addPromotionSuccess = createAction(
	'[Promotion API] Add Promotion Success',
	props<{ promotion: Promotion }>()
);
export const addPromotionFailure = createAction('[Promotion API] Add Promotion Failure', props<{ error: any }>());

export const updatePromotion = createAction(
	'[Promotion Page] Update Promotion',
	props<{ promotion: Update<Promotion> }>()
);
export const updatePromotionSuccess = createAction(
	'[Promotion API] Update Promotion Success',
	props<{ promotion: Update<Promotion> }>()
);
export const updatePromotionFailure = createAction('[Promotion API] Update Promotion Failure', props<{ error: any }>());
