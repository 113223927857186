import { createAction, props } from '@ngrx/store';

import { HomePage } from '../../shared/models/home-page';

export const loadHomePage = createAction('[Home Page Page] Load Home Page');

export const loadHomePageSuccess = createAction(
	'[Home Page API] Loaded Home Page Success',
	props<{ homePage: HomePage }>()
);

export const loadHomePageFailure = createAction('[Home Page API] Loaded Home Page Failure', props<{ error: any }>());
