import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PriceList } from 'src/app/shared/models/price-list';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';

@Injectable()
export class PriceListService {
	constructor(private http: HttpClient) {}

	getPriceList(): Observable<PriceList[]> {
		return this.http.get<PriceList[]>(environment.baseApiUrl + 'api/Price');
	}

	addPriceList(priceList: PriceList): Observable<PriceList> {
		return this.http.post<PriceList>(environment.baseApiUrl + 'api/Price', priceList);
	}

	updatePriceList(priceList: Update<PriceList>): Observable<Update<PriceList>> {
		return this.http
			.put<Update<PriceList>>(environment.baseApiUrl + 'api/Price', priceList.changes)
			.pipe(map(() => priceList));
	}
}
