import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductGroupActions from './product-group.actions';
import { ProductGroupService } from '../../core/services/product-group.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProductGroupEffects {
	constructor(
		private actions$: Actions,
		private productGroupService: ProductGroupService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadProductGroupsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.loadProductGroups),
			mergeMap(() =>
				this.productGroupService.getProductGroups().pipe(
					map(productGroups => {
						return fromProductGroupActions.loadProductGroupsSuccess({ productGroups });
					}),
					catchError(error => of(fromProductGroupActions.loadProductGroupsFailure({ error })))
				)
			)
		)
	);

	addProductGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.addProductGroup),
			mergeMap(action =>
				this.productGroupService.createProductGroup(action.addObject).pipe(
					switchMap(productGroup =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.toastrConfig.preventDuplicates = true;
								this.toastr.success(translate);
								return fromProductGroupActions.addProductGroupSuccess({ productGroup });
							}),
							catchError(error => of(fromProductGroupActions.addProductGroupFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateProductGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.updateProductGroup),
			mergeMap(action =>
				this.productGroupService.updateProductGroup(action.updateObject).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.toastrConfig.preventDuplicates = true;
								this.toastr.success(translate);
								return fromProductGroupActions.updateProductGroupSuccess({ productGroup: action.updateObject });
							}),
							catchError(error => of(fromProductGroupActions.updateProductGroupFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateProductGroupArray$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.updateProductGroupArray),
			mergeMap(action =>
				this.productGroupService.updateProductGroupArray(action.updateObject).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.toastrConfig.preventDuplicates = true;
								this.toastr.success(translate);
								return fromProductGroupActions.updateProductGroupArraySuccess({ productGroup: action.updateObject });
							}),
							catchError(error => of(fromProductGroupActions.updateProductGroupArrayFailure({ error })))
						)
					)
				)
			)
		)
	);

	removeProductGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.removeProductGroup),
			mergeMap(action =>
				this.productGroupService.removeProductGroup(action.id).pipe(
					switchMap(() =>
						this.translateService.get('SUCCESS.ITEM_REMOVE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductGroupActions.removeProductGroupSuccess({ id: action.id });
							}),
							catchError(error => of(fromProductGroupActions.removeProductGroupFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteProductGroupArray$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.removeProductGroupArray),
			mergeMap(action =>
				this.productGroupService.deleteProductGroupArray(action.keys).pipe(
					switchMap(keys =>
						this.translateService.get('SUCCESS.ITEM_REMOVE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductGroupActions.removeProductGroupArraySuccess({ ids: keys });
							}),
							catchError(error => of(fromProductGroupActions.removeProductGroupArrayFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteProductGroupImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.removeProductGroupImage),
			mergeMap(action =>
				this.productGroupService.deleteProductGroupImage(action.productGroup).pipe(
					switchMap(productGroup =>
						this.translateService.get('SUCCESS.ITEM_REMOVE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductGroupActions.removeProductGroupImageSuccess({ productGroup });
							}),
							catchError(error => of(fromProductGroupActions.removeProductGroupImageFailure({ error })))
						)
					)
				)
			)
		)
	);
}
