import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterByProperty',
	pure: false,
})
export class FilterByProperty implements PipeTransform {
	transform(items: any[], filter: any, property: any): any {
		if (!items || !filter) {
			return items;
		}
		// filter items array, items which are not matched will be returned,
		// matched will be filtered out
		return items.filter(item => item[property].toLowerCase().indexOf(filter[property].toLowerCase()) === -1);
	}
}
