import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductGroupActions from './product-groups-nav.actions';
import { ProductGroupService } from '../../core/services/product-group.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ProductGroupsNavEffects {
	constructor(private actions$: Actions, private productGroupService: ProductGroupService) {}

	loadProductGroupsNavEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductGroupActions.loadProductGroupsNav),
			mergeMap(() =>
				this.productGroupService.getNavProductGroups().pipe(
					map(productGroups => {
						return fromProductGroupActions.loadProductGroupsNavSuccess({ productGroups });
					}),
					catchError(error => of(fromProductGroupActions.loadProductGroupsNavFailure({ error })))
				)
			)
		)
	);
}
