import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { ProductAttribute } from 'src/app/shared/models/product-details';
import { ProductDetails } from 'src/app/shared/models/product-details';
import { map } from 'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable({
	providedIn: 'root',
})
export class ProductDetailsService {
	constructor(private http: HttpClient, private language: LanguageService) {}

	getProductSpecs(productNo: string): Observable<ProductDetails> {
		const params = new HttpParams().set('No', encodeURIComponent(productNo));
		let language: string;
		this.language.currentLanguage.subscribe(data => (language = data));
		return this.http
			.get<ProductAttribute[]>(environment.baseApiUrl + 'api/Product/specs', { params })
			.pipe(
				map(data => {
					if (language === 'en') {
						for (const element of data) {
							element.name = element.nameEng;
							element.value = element.valueEng;
						}
					}
					const productDetails: ProductDetails = {
						productNo,
						specs: data,
					};
					return productDetails;
				})
			);
	}
}
