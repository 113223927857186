import { dashboardFeatureKey, DashboardState } from './dashboard.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectDashboardState = createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectAllBrowsers = createSelector(selectDashboardState, state => state.browsersUsed);

export const selectAllDevices = createSelector(selectDashboardState, state => state.devicesUsed);

export const selectAllOperatingSystems = createSelector(selectDashboardState, state => state.operatingSystemsUsed);

export const selectAllVisitsPerMonth = createSelector(selectDashboardState, state => state.visitsPerMonth);

export const selectDashboardLogsLoading = createSelector(
	selectDashboardState,
	state => state.statusState === LoadingState.LOADING
);

export const selectDashboardLogsError = createSelector(selectDashboardState, (state: DashboardState) =>
	getError(state.statusState)
);
