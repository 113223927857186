import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductGroup } from '../../shared/models/product-group';
import * as ProductGroupActions from './product-group.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const productGroupsFeatureKey = 'productGroups';

export interface ProductGroupState extends EntityState<ProductGroup> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ProductGroup> = createEntityAdapter<ProductGroup>({
	selectId: productGroup => productGroup.id,
});

export const initialState: ProductGroupState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const productGroupReducer = createReducer(
	initialState,
	on(ProductGroupActions.loadProductGroups, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.loadProductGroupsSuccess, (state, action) =>
		adapter.setAll(action.productGroups, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.loadProductGroupsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.addProductGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.addProductGroupSuccess, (state, action) =>
		adapter.addOne(action.productGroup, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.addProductGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.updateProductGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.updateProductGroupSuccess, (state, action) =>
		adapter.updateOne(action.productGroup, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.updateProductGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.updateProductGroupArray, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.updateProductGroupArraySuccess, (state, action) =>
		adapter.updateMany(action.productGroup, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.updateProductGroupArrayFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.removeProductGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.removeProductGroupSuccess, (state, action) =>
		adapter.removeOne(action.id, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.removeProductGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.removeProductGroupArray, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.removeProductGroupArraySuccess, (state, action) =>
		adapter.removeMany(action.ids, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.removeProductGroupArrayFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(ProductGroupActions.removeProductGroupImage, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductGroupActions.removeProductGroupImageSuccess, (state, action) =>
		adapter.updateOne(action.productGroup, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ProductGroupActions.removeProductGroupImageFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ProductGroupState | undefined, action: Action) {
	return productGroupReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
