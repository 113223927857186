import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Service } from 'src/app/shared/models/service';

export const loadServices = createAction('[Service API] Load Services');

export const loadServiceSuccess = createAction('[Service API] Load Services Success', props<{ services: Service[] }>());
export const loadServicesFailure = createAction('[Service API] Load Services Failure', props<{ error: any }>());

export const addService = createAction('[Service Page] Add Service', props<{ service: Service }>());

export const addServiceSuccess = createAction('[Service API] Add Service Success', props<{ service: Service }>());

export const addServiceFailure = createAction('[Service API] Add Service Failure', props<{ error: any }>());

export const updateService = createAction('[Service Page] Update Service', props<{ service: Update<Service> }>());

export const updateServiceSuccess = createAction(
	'[Service API] Update Service Success',
	props<{ service: Update<Service> }>()
);

export const updateServiceFailure = createAction('[Service API] Update Service Failure', props<{ error: any }>());

export const deleteService = createAction('[Service Page] Delete Service', props<{ id: number }>());

export const deleteServiceSuccess = createAction('[Service API] Delete Service Success', props<{ id: number }>());

export const deleteServiceFailure = createAction('[Service API] Delete Service Failure', props<{ error: any }>());
