import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AllowedQuantityState } from '../root-store/allowed-quantity-store/allowed-quantity.reducer';
import * as allowedQuantitySelectors from '../root-store/allowed-quantity-store/allowed-quantity.selectors';
import * as allowedQuantityActions from '../root-store/allowed-quantity-store/allowed-quantity.actions';
import { AllowedQuantity } from '../shared/models/allowed-quantity';

import { ProductGroupNavState } from '../root-store/product-groups-nav-store/product-groups-nav.reducer';
import * as productGroupNavSelectors from '../root-store/product-groups-nav-store/product-groups-nav.selectors';
import * as productGroupNavActions from '../root-store/product-groups-nav-store/product-groups-nav.actions';
import { ProductGroup } from '../shared/models/product-group';

import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/sr/environment';

@Component({
	selector: 'app-allowed-quantity',
	templateUrl: './allowed-quantity.component.html',
	styleUrls: ['./allowed-quantity.component.scss'],
})
export class AllowedQuantityComponent implements OnInit, OnDestroy {
	ngUnsubscribe: Subject<object> = new Subject();
	updateAllowedQuantityModal: BsModalRef;
	deleteAllowedQuantityModal: BsModalRef;
	allowedQuantityForm: any;

	firstPageElementIndex = 0;

	allowedQuantityLoading$: Observable<boolean>;
	selectedAllowedQuantity$: Observable<AllowedQuantity>;
	allowedQuantity$: Observable<AllowedQuantity[]>;

	productGroupsLoading$: Observable<boolean>;
	productGroups$: Observable<ProductGroup[]>;

	translate: any;
	translateFormValidationErrorMsgs: any;

	maxIntValue = environment.maxPositiveIntValue;

	submitted: boolean;
	currentAllowedQuantity: AllowedQuantity = new AllowedQuantity();

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'productGroup', title: 'PRODUCT_GROUP', width: 30 },
		{ name: 'productName', title: 'PRODUCT_NAME', width: 30 },
		{ name: 'allowedQtty', title: 'ALLOWED_QTTY', width: 25 },
		{ name: '', title: '', width: 10 },
	];
	dropdownOptions: any;

	constructor(
		private translateService: TranslateService,
		private allowedQuantityStore: Store<AllowedQuantityState>,
		private productGroupNavStore: Store<ProductGroupNavState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('ALLOWED_QUANTITY').subscribe((resp: any) => {
			this.translate = resp;
			this.dropdownOptions = [{ label: this.translate.SELECT_PRODUCT_NAME, value: null }];
		});
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));

		this.allowedQuantity$ = this.allowedQuantityStore.select(allowedQuantitySelectors.selectAllAllowedQuantity);
		this.allowedQuantityLoading$ = this.allowedQuantityStore.select(
			allowedQuantitySelectors.selectAllowedQuantityLoading
		);
		this.allowedQuantityStore.dispatch(allowedQuantityActions.loadAllowedQuantity());
		this.productGroupNavStore
			.select(productGroupNavSelectors.selectAllProductGroupsNav)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				if (!data.length) {
					this.productGroupNavStore.dispatch(productGroupNavActions.loadProductGroupsNav());
				}
				for (const element of data) {
					this.dropdownOptions.push({ label: element.description, value: element.code });
				}
			});
		this.productGroupsLoading$ = this.productGroupNavStore.select(
			productGroupNavSelectors.selectProductGroupsNavLoading
		);
	}

	openAllowedQuantityModal(updateAllowedQuantityTemplate: TemplateRef<any>, allowedQuantity: any) {
		this.submitted = false;
		this.allowedQuantityForm = this.formBuilder.group(
			allowedQuantity
				? {
						...allowedQuantity,
						productGroup: [allowedQuantity.productGroup, [Validators.required]],
						allowedQtty: [
							allowedQuantity.allowedQtty,
							[Validators.required, Validators.max(this.maxIntValue), Validators.min(0)],
						],
				  }
				: {
						...new AllowedQuantity(),
						productGroup: ['', [Validators.required]],
						allowedQtty: ['', [Validators.required, Validators.max(this.maxIntValue), Validators.min(0)]],
				  }
		);
		this.updateAllowedQuantityModal = this.modalService.show(updateAllowedQuantityTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	submitAllowedQuantityForm() {
		this.submitted = true;

		if (this.allowedQuantityForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}

		if (this.allowedQuantityForm.value.id) {
			let tempAllowedQuantity: Update<AllowedQuantity>;
			tempAllowedQuantity = {
				id: this.allowedQuantityForm.value.id,
				changes: this.allowedQuantityForm.value,
			};
			this.allowedQuantityStore.dispatch(
				allowedQuantityActions.updateAllowedQuantity({ allowedQuantity: tempAllowedQuantity })
			);
		} else {
			this.allowedQuantityStore.dispatch(
				allowedQuantityActions.addAllowedQuantity({ allowedQuantity: this.allowedQuantityForm.value })
			);

			this.firstPageElementIndex = 0;
		}
		this.updateAllowedQuantityModal.hide();
	}

	openConfirmationModal(deleteAllowedQuantityTemplate: TemplateRef<any>, allowedQuantity) {
		this.currentAllowedQuantity = allowedQuantity;
		this.deleteAllowedQuantityModal = this.modalService.show(deleteAllowedQuantityTemplate, { class: 'modal-md' });
	}

	selectProductGroup(event: any): void {
		this.allowedQuantityForm.controls.productGroup.setValue(event.value);
		this.selectedAllowedQuantity$ = this.allowedQuantityStore.select(
			allowedQuantitySelectors.selectAllowedQuantityByProductGroup,
			{
				productGroup: event.value,
			}
		);
		const productGroupName = this.dropdownOptions.find(x => x.value === event.value).label;
		this.selectedAllowedQuantity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response) {
				this.allowedQuantityForm.controls.allowedQtty.setValue(response.allowedQtty);
				this.allowedQuantityForm.controls.productName.setValue(productGroupName);
				this.allowedQuantityForm.controls.id.setValue(response.id);
			} else {
				this.allowedQuantityForm.controls.allowedQtty.setValue(0);
				this.allowedQuantityForm.controls.productName.setValue(productGroupName);
				this.allowedQuantityForm.controls.id.setValue(0);
			}
		});
	}

	deleteAllowedQuantity() {
		this.allowedQuantityStore.dispatch(
			allowedQuantityActions.deleteAllowedQuantity({ allowedQuantityId: this.currentAllowedQuantity.id })
		);
		this.deleteAllowedQuantityModal.hide();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
