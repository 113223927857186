import { sellersFeatureKey, SellerState, selectAll } from './seller.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectSellerState = createFeatureSelector<SellerState>(sellersFeatureKey);

export const selectAllSellers = createSelector(selectSellerState, selectAll);

export const selectSellersLoading = createSelector(
	selectSellerState,
	state => state.statusState === LoadingState.LOADING
);

export const selectSellersError = createSelector(selectSellerState, (state: SellerState) =>
	getError(state.statusState)
);
