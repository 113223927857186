<div
	*ngIf="{ error: errorState$ | async, translate: translate$ | async } as observables"
	class="alert alert-danger"
	role="alert"
>
	<h4 class="alert-heading">{{ observables.translate?.HEADER }}</h4>
	<hr />
	<p [innerHTML]="observables.error?.detail"></p>
	<hr />
	<h5 class="mb-0">{{ observables.translate?.FOOTER }}</h5>
</div>
