import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthErrorEvent, OAuthStorage } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { authModuleConfig } from './auth-module-config';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	constructor(private oauthStorage: OAuthStorage, private authService: AuthService) {}

	// function which will be called for all http calls
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			localStorage.getItem('access_token') ||
			(this.authService.userRoles && !this.authService.userRoles.includes('AdminAccess'))
		) {
			setTimeout(() => {
				this.authService.logout();
				sessionStorage.setItem('emptyLocalStorage', '1');
				this.authService.navigateToLoginPage();
				return;
			}, 100);
		}
		const url = req.url.toLowerCase();
		if (
			!authModuleConfig ||
			!authModuleConfig.resourceServer ||
			!authModuleConfig.resourceServer.allowedUrls ||
			!this.checkUrl(url)
		) {
			return next.handle(req);
		}
		let clonedReq: HttpRequest<any>;
		const httpHeaders: any = new Object();
		const reqHeaderKeys: string[] = req.headers.keys();
		reqHeaderKeys.forEach(key => {
			httpHeaders[key] = req.headers.get(key);
		});

		// 'admin_' prefix is added in storage.service.ts (StorageService)
		// Consider looking at `core.module.ts` for further clarification
		const accessToken = this.oauthStorage.getItem('access_token');
		if (accessToken) {
			if (this.authService.hasValidToken()) {
				httpHeaders.Authorization = 'Bearer ' + accessToken;
			} else {
				// access token expired, needs refresh
				return this.tokenRefreshener(httpHeaders, req, clonedReq, next);
			}
		} else if (!location.search.includes('code=')) {
			// user is not logged in
			this.authService.navigateToLoginPage();
		}

		clonedReq = req.clone({
			setHeaders: httpHeaders,
		});

		return next.handle(clonedReq ? clonedReq : req).pipe(
			tap(
				() => {},
				err => {
					if (err.status === 401) {
						return this.tokenRefreshener(httpHeaders, req, clonedReq, next);
					}
					return err;
				}
			)
		);
	}

	private checkUrl(url: string): boolean {
		const found = authModuleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
		return !!found;
	}

	private tokenRefreshener(
		httpHeaders: any,
		req: HttpRequest<any>,
		clonedReq: HttpRequest<any>,
		next: HttpHandler
	): Observable<any> {
		if (this.refreshTokenInProgress) {
			return this.refreshTokenSubject.pipe(
				filter(result => result !== null),
				take(1),
				switchMap(() => {
					httpHeaders.Authorization = 'Bearer ' + this.oauthStorage.getItem('access_token');
					clonedReq = req.clone({
						setHeaders: httpHeaders,
					});
					return next.handle(clonedReq);
				})
			);
		} else {
			this.refreshTokenInProgress = true;
			this.refreshTokenSubject.next(null);
			return this.authService.refresh().pipe(
				switchMap(() => {
					httpHeaders.Authorization = 'Bearer ' + this.oauthStorage.getItem('access_token');
					clonedReq = req.clone({
						setHeaders: httpHeaders,
					});
					this.refreshTokenInProgress = false;
					this.refreshTokenSubject.next(true);
					return next.handle(clonedReq);
				}),
				catchError((error: OAuthErrorEvent) => {
					if (error && error.type === 'silent_refresh_error') {
						this.authService.navigateToLoginPage();
						return of(null);
					}
					return of(null);
				})
			);
		}
	}
}
