import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ManufacturerFiltered } from 'src/app/shared/models/manufacturer-filtered';
import * as ManufacturerFilteredActions from './manufacturer-filtered.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const manufacturersFilteredFeatureKey = 'manufacturersFiltered';

export interface ManufacturerFilteredState extends EntityState<ManufacturerFiltered> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<ManufacturerFiltered> = createEntityAdapter<ManufacturerFiltered>({
	selectId: manuf => manuf.id + '-' + manuf.productGroup + '-' + manuf.customerId,
});

export const initialState: ManufacturerFilteredState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const manufacturerFilteredReducer = createReducer(
	initialState,
	on(ManufacturerFilteredActions.loadManufacturersByCustomerAndGroup, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ManufacturerFilteredActions.loadManufacturersByCustomerAndGroupSuccess, (state, action) =>
		adapter.addMany(action.manufacturersFiltered, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ManufacturerFilteredActions.loadManufacturersByCustomerAndGroupFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ManufacturerFilteredState | undefined, action: Action) {
	return manufacturerFilteredReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
