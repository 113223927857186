import { createAction, props } from '@ngrx/store';

import { VendorlandGameResult } from '../../shared/models/vendorlandGameResult';
import { Update } from '@ngrx/entity';

export const loadVendorland = createAction('[Vendorland Page] Load Vendorland');

export const loadVendorlandSuccess = createAction(
	'[Vendorland API] Loaded Vendorland Success',
	props<{ vendorland: VendorlandGameResult[] }>()
);

export const loadVendorlandFailure = createAction(
	'[Vendorland API] Loaded Vendorland Failure',
	props<{ error: any }>()
);

export const updateVendorland = createAction(
	'[Vendorland Page] Update Vendorland',
	props<{ vendorland: Update<VendorlandGameResult> }>()
);

export const updateVendorlandSuccess = createAction(
	'[Vendorland API] Update Vendorland Success',
	props<{ vendorland: Update<VendorlandGameResult> }>()
);

export const updateVendorlandFailure = createAction(
	'[Vendorland API] Update Vendorland Failure',
	props<{ error: any }>()
);
