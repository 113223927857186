import { IEnvironment } from '../ienvironment';

export const environment: IEnvironment = {
	production: false,
	defaultLanguage: 'en',
	translateFilesLocation: '/assets/i18n/',
	authTempAccessKey: 'ArtemisTheBestTeam2020',
	exchangeCurrencies: ['EUR', 'USD'],
	defaultExchangeCurrency: 'RSD',
	defaultFinancialCurrency: 'RSD',
	baseApiUrl: 'http://test.ct4partners.com/api.portal/',
	baseImagesApiUrl: 'http://test.ct4partners.com/portalimages/',
	localImagesLocation: '/assets/images/',
	rootAppUrl: 'http://www.ctdadmin.com/',
	mediaLocation: {
		productImageRoot: 'http://test.ct4partners.ba/UploadDownload/ProductImages/',
		productFiles: 'http://test.ct4partners.com/Media/Documents/Products/Files/',
		promotionsImages: 'http://test.ct4partners.com/Media/Images/Newsletter/',
		promotionsFiles: 'http://test.ct4partners.com/Media/Images/Newsletter/ExtraFiles/',
		viberPromotionImages: 'http://test.ct4partners.com/Media/Images/ViberAction/',
		sellerImages: 'http://test.ct4partners.com/Media/Images/Sellers/',
		priceListFiles: 'http://test.ct4partners.com/Media/Documents/PriceLists/',
		menuBanner: 'https://test.ct4partners.com/Media/Images/MenuBanner/',
		promotionSlider: {
			imagePath: 'http://test.ct4partners.com/Media/Images/ActionBanner/',
			imageVirtualPath: 'http://test.ct4partners.com/Media/Images/ActionBanner/',
			modalImagePath: 'http://test.ct4partners.com/Media/Images/ActionBanner/Modal/Images/',
			modalFile: 'http://test.ct4partners.com/Media/Images/ActionBanner/Modal/Files/',
		},
		customerFiles: 'http://test.ct4partners.com/Media/Documents/Customer/',
		specialPricesExcelFiles: '/assets/SpecialPrice/ExcelTemplate/',
		specificationData: 'http://test.ct4partners.com/Media/Documents/SpecificationData/',
	},
	mediaRestrictions: {
		promotions: {
			small_image: {
				width: 400,
				height: 250,
				size: 512000,
			},
			big_image: {
				width: 650,
				height: 2000,
				size: 512000,
			},
		},
		product_images: {
			aspect_ratio: {
				width: 4,
				height: 3,
			},
			resolution: {
				width: 1024,
				height: 768,
			},
			size: 1048576,
		},
		seller_images: {
			resolution: {
				width: 400,
				height: 400,
			},
			size: 409600,
		},
		customer_logo: {
			width: 250,
			height: 400,
			size: 1048576,
		},
		promotion_slider: {
			desktop_image: {
				width: 1920,
				height: 400,
				size: 819200,
			},
			mobile_image: {
				width: 1000,
				height: 600,
				size: 819200,
			},
			product_list_image: {
				desktop_image: {
					width: 900,
					height: 180,
					size: 512000,
				},
				mobile_image: {
					width: 450,
					height: 180,
					size: 512000,
				},
			},
			modal_image: {
				width: 1700,
				height: 1000,
				size: 1048576,
			},
		},
		price_list: {
			file_size: 5242880,
			file_name_limit: 24,
		},
		product_groups: {
			width: 200,
			height: 485,
			size: 512000,
		},
		viber_action_images: {
			width: 500,
			height: 500,
			size: 512000,
		},
	},
	options: {
		languages: ['en', 'sr'],
		home: {
			latestOrders: {
				status: {
					hidden: false,
				},
			},
		},
	},
	deactivateFunction: [113],
	identityServerConf: {
		url: 'http://test.ct4partners.com/identityserver',
		clientId: 'portal-admin-web',
		scope: 'openid profile email WebAPI ImagesAPI',
		country: 'sr',
		impersonificationPath: '/Auth/ImpersonateAdmin?ReturnUrl=',
		stopImpersonatePath: '/Auth/StopAdminImpersonation',
		changePasswordPath: '/Account/ChangePasswordAdmin',
	},
	isOAuthEnabled: false,
	maxPositiveIntValue: 2147483647,
};
