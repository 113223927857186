export function generateRandomColour(arrayLength: number): string[] {
	const coloursArray: string[] = [];

	for (let i = 0; i < arrayLength; i++) {
		const colour = getRandomColour();
		if (!coloursArray.includes(colour)) {
			coloursArray[i] = colour;
		}
	}

	return coloursArray;
}

function getRandomColour() {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}

	return color;
}
