import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProductGroup from './product-group.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductGroupEffects } from './product-group.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductGroup.productGroupsFeatureKey, fromProductGroup.reducer),
		EffectsModule.forFeature([ProductGroupEffects]),
	],
})
export class ProductGroupsStoreModule {}
