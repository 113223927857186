import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Administrator } from 'src/app/shared/models/administrator';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class AdministratorService {
	constructor(private http: HttpClient) {}

	getAdministrators(): Observable<Administrator[]> {
		return this.http.get<Administrator[]>(environment.baseApiUrl + 'api/PortalUser');
	}

	getCurrentAdministratorDetails(): Observable<Administrator> {
		return this.http.get<Administrator>(environment.baseApiUrl + 'api/PortalUser/current-user');
	}

	addAdministrator(administrator: Administrator): Observable<Administrator> {
		return this.http.post<Administrator>(environment.baseApiUrl + 'api/PortalUser', administrator).pipe(
			map(data => {
				data.groupName = administrator.groupName;
				return data;
			})
		);
	}

	updateAdministrator(administrator: Update<Administrator>): Observable<Update<Administrator>> {
		return this.http
			.put<Update<Administrator>>(environment.baseApiUrl + 'api/PortalUser', administrator.changes)
			.pipe(map(() => administrator));
	}
}
