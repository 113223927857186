import { viberPromotionsFeatureKey, ViberPromotionState, selectAll } from './viber-promotion.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectViberPromotionState = createFeatureSelector<ViberPromotionState>(viberPromotionsFeatureKey);

export const selectAllViberPromotions = createSelector(selectViberPromotionState, selectAll);

export const selectViberPromotionsLoading = createSelector(
	selectViberPromotionState,
	(state: ViberPromotionState) => state.statusState === LoadingState.LOADING
);

export const selectViberPromotionsError = createSelector(selectViberPromotionState, (state: ViberPromotionState) =>
	getError(state.statusState)
);
