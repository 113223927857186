import { promotionSlidersFeatureKey, PromotionSliderState, selectAll } from './promotion-slider.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { PromotionSlider } from 'src/app/shared/models/promotion-slider';

export const selectPromotionSliderState = createFeatureSelector<PromotionSliderState>(promotionSlidersFeatureKey);

export const selectAllPromotionSliders = createSelector(selectPromotionSliderState, selectAll);

export const selectPromotionSlidersLoading = createSelector(
	selectPromotionSliderState,
	state => state.statusState === LoadingState.LOADING
);

export const selectSelectedPromotionSlidersLoading = createSelector(
	selectPromotionSliderState,
	state => state.selectedPromotionSliderState === LoadingState.LOADING
);

export const selectSelectedPromotionSliderFileLoading = createSelector(
	selectPromotionSliderState,
	state => state.selectedPromotionSliderFileState === LoadingState.LOADING
);

export const selectPromotionSlidersError = createSelector(selectPromotionSliderState, (state: PromotionSliderState) =>
	getError(state.statusState)
);

export const selectPromotionSliderById = createSelector(
	selectAllPromotionSliders,
	(promotionSlider: PromotionSlider[], params: any) => {
		return promotionSlider.find(c => c.id === params.id);
	}
);
