import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUserRegistrationActions from './user-registration.actions';
import { UserRegistrationService } from '../../core/services/user-registration.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class UserRegistrationEffects {
	constructor(private actions$: Actions, private userRegistrationService: UserRegistrationService) {}

	loadUserRegistrationEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromUserRegistrationActions.loadUserRegistration),
			mergeMap(() =>
				this.userRegistrationService.getUserRegistration().pipe(
					map(userRegistration => fromUserRegistrationActions.loadUserRegistrationSuccess({ userRegistration })),
					catchError(error => of(fromUserRegistrationActions.loadUserRegistrationFailure({ error })))
				)
			)
		)
	);
}
