export class User {
	username: string;
	password: string;

	email?: string;
	name?: string;
	// tslint:disable-next-line: variable-name
	preferred_username?: string;
	sub?: string;
}
