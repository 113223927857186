import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromGroupConnections from './group-connections.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GroupConnectionsEffects } from './group-connections.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromGroupConnections.groupConnectionsFeatureKey, fromGroupConnections.reducer),
		EffectsModule.forFeature([GroupConnectionsEffects]),
	],
})
export class GroupConnectionsStoreModule {}
