import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGuestBook from './guest-book.reducer';
import { GuestBookEffects } from './guest-book.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromGuestBook.guestBookItemsFeatureKey, fromGuestBook.reducer),
		EffectsModule.forFeature([GuestBookEffects]),
	],
})
export class GuestBookStoreModule {}
