import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '../services/logging.service';
import { ErrorLog } from 'src/app/shared/models/error-log';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {}

	// handling only non HttpErrorResponse (client) errors
	handleError(error: Error) {
		const loggingService = this.injector.get(LoggingService);

		const errorLog: ErrorLog = {
			ErrorMessage: this.getClientMessage(error),
			Scope: 'PortalAdmin',
			StackTrace: error.stack,
			Url: window.location.href,
		};

		loggingService.logError(errorLog).subscribe();
		console.error(error);
	}

	private getClientMessage(error: Error): string {
		if (!navigator.onLine) {
			return 'No Internet Connection';
		}
		return error.message ? error.message : error.toString();
	}
}
