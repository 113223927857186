import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { AllowedQuantityComponent } from './allowed-quantity.component';

@NgModule({
	declarations: [AllowedQuantityComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class AllowedQuantityModule {}
