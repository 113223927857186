import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromGroupActions from './group.actions';
import { GroupService } from '../../core/services/group.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GroupEffects {
	constructor(
		private actions$: Actions,
		private groupService: GroupService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadGroupsEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromGroupActions.loadGroups),
			mergeMap(() =>
				this.groupService.getGroups().pipe(
					map(groups => fromGroupActions.loadGroupsSuccess({ groups })),
					catchError(error => of(fromGroupActions.loadGroupsFailure({ error })))
				)
			)
		)
	);

	addGroupEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromGroupActions.addGroup),
			mergeMap(action =>
				this.groupService.addGroup(action.group).pipe(
					switchMap(group =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromGroupActions.addGroupSuccess({ group });
							}),
							catchError(error => of(fromGroupActions.addGroupFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateGroupEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromGroupActions.updateGroup),
			mergeMap(action =>
				this.groupService.updateGroup(action.group).pipe(
					switchMap(group =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromGroupActions.updateGroupSuccess({ group });
							}),
							catchError(error => of(fromGroupActions.updateGroupFailure({ error })))
						)
					)
				)
			)
		)
	);
}
