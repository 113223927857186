import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { AdditionalSpecificationComponent } from './additional-specification.component';

@NgModule({
	declarations: [AdditionalSpecificationComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class AdditionalSpecificationModule {}
