import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromManufacturerFiltered from './manufacturer-filtered.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ManufacturerFilteredEffects } from './manufacturer-filtered.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromManufacturerFiltered.manufacturersFilteredFeatureKey, fromManufacturerFiltered.reducer),
		EffectsModule.forFeature([ManufacturerFilteredEffects]),
	],
})
export class ManufacturersFilteredStoreModule {}
