import { createAction, props } from '@ngrx/store';
import { ExchangeRate } from 'src/app/shared/models/exchange-rate';

export const loadExchangeRates = createAction('[ExchangeRates Page] Load ExchangeRates Request');

export const loadExchangeRatesSuccess = createAction(
	'[ExchangeRates API] Load ExchangeRates Success',
	props<{ exchangeRates: ExchangeRate[] }>()
);

export const loadExchangeRatesFailure = createAction(
	'[ExchangeRates API] Load ExchangeRates Failure',
	props<{ error: any }>()
);
