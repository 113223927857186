import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Feature } from '../../shared/models/feature';
import * as FeatureActions from './feature.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const featuresFeatureKey = 'features';

export interface FeatureState extends EntityState<Feature> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Feature> = createEntityAdapter<Feature>();

export const initialState: FeatureState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const featureReducer = createReducer(
	initialState,
	on(FeatureActions.loadFeatures, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(FeatureActions.loadFeaturesSuccess, (state, action) =>
		adapter.setAll(action.features, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(FeatureActions.loadFeaturesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: FeatureState | undefined, action: Action) {
	return featureReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
