import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HomePage } from '../../shared/models/home-page';
import * as HomePageActions from './home-page.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const homePageFeatureKey = 'home-page';

export interface HomePageState extends EntityState<HomePage> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<HomePage> = createEntityAdapter<HomePage>();

export const initialState: HomePageState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const homePageReducer = createReducer(
	initialState,
	on(HomePageActions.loadHomePage, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(HomePageActions.loadHomePageSuccess, (state, action) =>
		adapter.addOne(action.homePage, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(HomePageActions.loadHomePageFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: HomePageState | undefined, action: Action) {
	return homePageReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
