export class GroupConnection {
	id: number;
	productGroup: string;
	connectedProductGroup: string;
	connectionType: string;
	productGroupDescription: string;
	constructor() {
		this.id = 0;
		this.productGroup = '';
		this.connectedProductGroup = '';
		this.connectionType = '';
		this.productGroupDescription = '';
	}
}
