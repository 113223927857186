<ng-container *ngIf="translate && { guestBookLoading: guestBookLoading$ | async } as observables">
	<div class="page-title mb-5">
		<h2>{{ translate.TITLE }}</h2>
		<p>{{ translate.PAGE_DESCRIPTION }}</p>
	</div>
	<app-skeleton-screen
		*ngIf="observables.guestBookLoading && columns"
		[columns]="columns"
		[translate]="translate"
	></app-skeleton-screen>
	<div *ngIf="!observables.guestBookLoading" class="guest-book-reports-table">
		<p-table
			#guestBookTable
			[value]="guestBook"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			[rowsPerPageOptions]="[5, 10, 20, 50]"
			[globalFilterFields]="['categoryName', 'userID', 'userName', 'companyName', 'companyPIB', 'logDate', 'comment']"
		>
			<ng-template pTemplate="caption">
				<div class="p-grid">
					<div class="p-col">
						<p-dropdown
							class="table-dropdown-options"
							[appendTo]="guestBookTable"
							[options]="dropdownOptionsCustomerID"
							[(ngModel)]="selectedCustomerID"
							[filter]="true"
							filterBy="value"
							(onChange)="guestBookTable.filter(selectedCustomerID, 'userID', 'equals')"
							data-tid="customerIDDropdown"
						></p-dropdown>
					</div>
					<div class="p-col">
						<p-dropdown
							class="table-dropdown-options"
							[appendTo]="guestBookTable"
							[options]="dropdownOptionsCustomerName"
							[(ngModel)]="selectedCustomerName"
							[filter]="true"
							filterBy="value"
							(onChange)="guestBookTable.filter(selectedCustomerName, 'userName', 'equals')"
							data-tid="customerNameDropdown"
						></p-dropdown>
					</div>
					<div class="p-col">
						<p-dropdown
							class="table-dropdown-options"
							[appendTo]="guestBookTable"
							[options]="dropdownOptionsCompanyName"
							[(ngModel)]="selectedCompanyName"
							[filter]="true"
							filterBy="value"
							(onChange)="guestBookTable.filter(selectedCompanyName, 'companyName', 'equals')"
							data-tid="customerCompanyNameDropdown"
						></p-dropdown>
					</div>
					<div class="p-col">
						<input
							bsDaterangepicker
							class="form-control date-picker"
							[(ngModel)]="rangeDates"
							[bsConfig]="{ rangeInputFormat: 'DD.MM.YYYY', showWeekNumbers: false }"
							(bsValueChange)="guestBookTable.filter($event, 'logDate', 'dateRangeFilter')"
							[placeholder]="translate.SELECT_DATA_RANGE_FILTER"
							readonly
						/>
					</div>
					<div class="p-col">
						<button type="button" class="button" [disabled]="!rangeDates" (click)="clear()">
							{{ translate.CLEAR_FILTER }}
						</button>
					</div>
					<div class="p-col">
						<div class="global-filter-cont">
							<fa-icon icon="search"></fa-icon>
							<input
								type="text"
								class="form-control"
								pInputText
								size="50"
								[placeholder]="'GLOBAL_FILTER' | translate"
								data-tid="globalFilter"
								(input)="guestBookTable.filterGlobal($event.target.value, 'contains')"
							/>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th *ngFor="let element of columns" [pSortableColumn]="element.name" [style.width.px]="element.width">
						{{ translate[element.title] }}
						<p-sortIcon [field]="element.name"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-guestBook let-i="rowIndex">
				<tr>
					<td>{{ guestBook.categoryName }}</td>
					<td>
						<b>{{ guestBook.userID }}</b>
					</td>
					<td>{{ guestBook.userName }}</td>
					<td>{{ guestBook.companyName }}</td>
					<td>{{ guestBook.companyPIB }}</td>
					<td>{{ guestBook.logDate | date: 'dd.MM.yyyy, H:mm ' }}</td>
					<td>{{ guestBook.comment }}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="summary">
				<div class="svg-export-container">
					<button type="button" class="btn-cont" (click)="exportToExcel()" [disabled]="!guestBook.length">
						<button class="button circle-button">
							<svg-icon key="excel"></svg-icon>
						</button>
						<span class="button-label">{{ 'EXPORT_TO_EXCEL' | translate }}</span>
					</button>
				</div>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td [attr.colspan]="columns.length" class="empty-msg">
						{{ 'NO_DATA' | translate }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</ng-container>
