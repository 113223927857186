import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VendorlandGameResult } from 'src/app/shared/models/vendorlandGameResult';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class VendorlandService {
	constructor(private http: HttpClient) {}

	getVendorlandGameResult(): Observable<VendorlandGameResult[]> {
		return this.http.get<VendorlandGameResult[]>(environment.baseApiUrl + 'api/VendorlandGameResult');
	}

	updateVendorland(vendorland: Update<VendorlandGameResult>): Observable<Update<VendorlandGameResult>> {
		return this.http
			.put<Update<VendorlandGameResult>>(environment.baseApiUrl + 'api/VendorlandGameResult', vendorland.changes)
			.pipe(map(() => vendorland));
	}
}
