import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CustomerRecord } from '../../shared/models/customer-record';
import * as CustomerRecordActions from './customer-record.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const customerRecordsFeatureKey = 'customerRecords';

export interface CustomerRecordState extends EntityState<CustomerRecord> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<CustomerRecord> = createEntityAdapter<CustomerRecord>();

export const initialState: CustomerRecordState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const customerRecordReducer = createReducer(
	initialState,
	on(CustomerRecordActions.loadCustomerRecords, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerRecordActions.loadCustomerRecordsSuccess, (state, action) =>
		adapter.setAll(action.customerRecords, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerRecordActions.loadCustomerRecordsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(CustomerRecordActions.addCustomerRecord, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerRecordActions.addCustomerRecordSuccess, (state, action) =>
		adapter.addOne(action.customerRecord, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerRecordActions.addCustomerRecordFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(CustomerRecordActions.updateCustomerRecord, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerRecordActions.updateCustomerRecordSuccess, (state, action) =>
		adapter.updateOne(action.customerRecord, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerRecordActions.updateCustomerRecordFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),

	on(CustomerRecordActions.deleteCustomerRecord, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(CustomerRecordActions.deleteCustomerRecordSuccess, (state, action) =>
		adapter.removeOne(action.customerRecordId, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(CustomerRecordActions.deleteCustomerRecordFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: CustomerRecordState | undefined, action: Action) {
	return customerRecordReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
