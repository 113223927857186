import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Target } from 'src/app/shared/models/target';
import { environment } from 'src/environments/sr/environment';

@Injectable({
	providedIn: 'root',
})
export class TargetService {
	constructor(private http: HttpClient) {}

	getTargets(range: { startDate: Date; endDate: Date }): Observable<Target[]> {
		return this.http.post<Target[]>(`${environment.baseApiUrl}api/Target/range`, range);
	}

	addTarget(target: Target): Observable<Target> {
		return this.http.post<Target>(`${environment.baseApiUrl}api/Target/`, target);
	}

	updateTarget(target: Update<Target>): Observable<Update<Target>> {
		return this.http
			.put<Update<Target>>(`${environment.baseApiUrl}api/Target/`, target.changes)
			.pipe(map(() => target));
	}
}
