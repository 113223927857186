import { Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/Store';
import { ProductExtraState } from 'src/app/root-store/product-extras-store/product-extras.reducer';
import * as productExtraSelectors from '../../root-store/product-extras-store/product-extras.selectors';
import * as productExtraActions from '../../root-store/product-extras-store/product-extras.actions';
import { ProductAdditionalTypeState } from 'src/app/root-store/product-additional-types-store/product-additional-types.reducer';
import * as productAdditionalTypeSelectors from '../../root-store/product-additional-types-store/product-additional-types.selectors';
import * as productAdditionalTypeActions from '../../root-store/product-additional-types-store/product-additional-types.actions';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/sr/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Update } from '@ngrx/entity';
import { ProductAdditionalType } from 'src/app/shared/models/product-additional-type';
import { ProductExtra } from 'src/app/shared/models/product-extra';
import { productAdditionalType } from 'src/app/shared/enums/product-additional-type.enum';

@Component({
	selector: 'app-links',
	templateUrl: './product-links.component.html',
	styleUrls: ['./product-links.component.scss'],
})
export class ProductLinksComponent implements OnInit, OnChanges {
	@Input() inputProductId: string;
	@Input() activeIndex: number;
	ngUnsubscribe: Subject<object> = new Subject();

	firstPageElementIndex = 0;

	updateProductLinkModal: BsModalRef;
	deleteProductLinkModal: BsModalRef;
	productLinkForm: any;

	currentProductLink: ProductExtra = null;
	selectedType: any;

	translate: any;
	translateProductAdditionalTypes: any;
	translateErrors: any;
	translateFormValidationErrorMsgs: any;

	environment = environment;

	additionalTypesLoading$: Observable<boolean>;
	additionalTypes$: Observable<ProductAdditionalType[]>;
	additionalTypes: ProductAdditionalType[];

	productLinksLoading$: Observable<boolean>;
	productLinks$: Observable<ProductExtra[]>;
	productLinks: any[];
	storedProductId: string;

	productLinkId: number;
	submitted: boolean;

	linkTypeDescriptionDropdown: SelectItem[] = [];

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 4 },
		{ name: 'url', title: 'URL', width: 25 },
		{ name: 'additionalType', title: 'TYPE', width: 4 },
		{ name: '', title: '', width: 5 },
	];

	constructor(
		private translateService: TranslateService,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private productExtrasStore: Store<ProductExtraState>,
		private productAdditionalTypesStore: Store<ProductAdditionalTypeState>,
		private toastr: ToastrService
	) {}

	/**
	 * Triggers showing product links if product links tab is selected
	 */
	ngOnChanges(): void {
		if (this.activeIndex === 2) {
			this.showProductLinks();
		}
	}

	ngOnInit(): void {
		this.additionalTypes$ = this.productAdditionalTypesStore.select(
			productAdditionalTypeSelectors.selectAllProductAdditionalTypes
		);

		this.additionalTypes$
			.pipe(
				mergeMap(types =>
					this.translateService.get('PRODUCT_ADDITIONAL_TYPES').pipe(map(translate => [translate, types]))
				)
			)
			.subscribe(([translate, types]) => {
				if (!types?.length) {
					this.productAdditionalTypesStore.dispatch(productAdditionalTypeActions.loadProductAdditionalTypes());
				}
				this.additionalTypes = types;
				if (this.additionalTypes?.length) {
					this.translateProductAdditionalTypes = translate;
					for (const link of this.additionalTypes) {
						this.linkTypeDescriptionDropdown.push({
							value: link.id,
							label: this.translateProductAdditionalTypes[link.title],
						});
					}
				}
			});

		this.translateService.get('PRODUCT_LINKS').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('ERROR').subscribe((resp: any) => (this.translateErrors = resp));
		this.translateService
			.get('ERROR.FORM_VALIDATIONS')
			.subscribe((resp: any) => (this.translateFormValidationErrorMsgs = resp));
	}

	showProductLinks() {
		this.productLinksLoading$ = this.productExtrasStore.select(productExtraSelectors.selectProductExtrasLoading);

		this.productExtrasStore
			.select(productExtraSelectors.selectStoredProductId)
			.subscribe(data => (this.storedProductId = data));

		this.productExtrasStore
			.select(productExtraSelectors.selectProductLinks)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(data => {
				this.productLinks = data;
				if (this.inputProductId !== this.storedProductId && this.inputProductId !== '') {
					this.productExtrasStore.dispatch(productExtraActions.loadProductExtras({ productId: this.inputProductId }));
				}
			});
	}

	openProductLinkModal(updateProductLinkTemplate: TemplateRef<any>, productLink: ProductExtra) {
		this.submitted = false;

		if (productLink) {
			this.selectedType = productLink.additionalTypeId;

			this.productLinkForm = this.formBuilder.group({
				...productLink,
				url: [productLink.url, [Validators.required]],
				additionalTypeId: [productLink.additionalTypeId, [Validators.required]],
			});
		} else {
			this.selectedType = this.additionalTypes.find(type => type.name === productAdditionalType.SPECIFICATION).id;

			this.productLinkForm = this.formBuilder.group({
				...new ProductExtra(),
				url: ['', [Validators.required]],
				additionalTypeId: [this.selectedType, [Validators.required]],
			});
		}

		if (productLink) {
			this.productLinkId = productLink.id;
		}

		this.updateProductLinkModal = this.modalService.show(updateProductLinkTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	submitProductLinkForm() {
		this.submitted = true;
		if (this.productLinkForm.invalid) {
			this.toastr.warning(this.translateFormValidationErrorMsgs.FORM_INCOMPLETE);
			return;
		}

		if (this.productLinkForm.value.id) {
			const productLinkUpdate: Update<ProductExtra> = {
				id: this.productLinkForm.value.id,
				changes: {
					...this.productLinkForm.value,
					additionalType: this.additionalTypes.find(type => type.id === this.productLinkForm.value.additionalTypeId)
						.title,
				},
			};

			this.productExtrasStore.dispatch(productExtraActions.updateProductLink({ productLink: productLinkUpdate }));
		} else {
			this.productExtrasStore.dispatch(
				productExtraActions.addProductLink({
					productLink: {
						...this.productLinkForm.value,
						productNo: this.inputProductId,
					},
				})
			);

			this.firstPageElementIndex = 0;
		}

		this.updateProductLinkModal.hide();
	}

	openConfirmationModal(deleteProductLinkTemplate: TemplateRef<any>, productLink: ProductExtra) {
		this.currentProductLink = productLink;
		this.deleteProductLinkModal = this.modalService.show(deleteProductLinkTemplate, { class: 'modal-md' });
	}

	deleteProductLink() {
		this.productExtrasStore.dispatch(productExtraActions.removeProductLink({ id: this.currentProductLink.id }));
		this.deleteProductLinkModal.hide();
	}
}
