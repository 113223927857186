import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductDataActions from './product-data.actions';
import { ProductDataService } from '../../core/services/product-data.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ProductDataEffects {
	constructor(private actions$: Actions, private productDataService: ProductDataService) {}

	loadProductCommonNamesFilteredEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductDataActions.loadProductDataFiltered),
			mergeMap(action =>
				this.productDataService.getProductCommonNamesByFilter(action.searchItem).pipe(
					map(productData =>
						fromProductDataActions.loadProductDataFilteredSuccess({
							productData,
							savedSearchItem: action.savedSearchItem,
						})
					),
					catchError(error => of(fromProductDataActions.loadProductDataFilteredFailure({ error })))
				)
			)
		)
	);
}
