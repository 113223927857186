import { PromotionSliderItem } from './promotion-slider-item';

export class PromotionSlider {
	id: number;
	name: string;
	url: string;
	source: string;
	updateDate: string;
	synchDate: string;
	activeSrb: boolean;
	activeMne: boolean;
	activeBih: boolean;
	activeMak: boolean;
	activeCro: boolean;
	activeSlo: boolean;
	orderNumber: number;
	isPosted: boolean;
	modifiedBy: string;
	backgroundUrl: string;
	image1Url: string;
	image2Url: string;
	desktopImage: ArrayBuffer | string;
	mobileImage: ArrayBuffer | string;
	location: string;
	path: string;
	fromDate: Date;
	toDate: Date;

	// Extra modal images
	isModal: boolean;
	documentUrl: string;
	modalFile: ArrayBuffer | string;
	productActionSliderItems: PromotionSliderItem[];

	constructor() {
		this.id = 0;
		this.name = '';
		this.url = '';
		this.source = '';
		this.updateDate = '';
		this.synchDate = '';
		this.activeSrb = false;
		this.activeMne = false;
		this.activeBih = false;
		this.activeMak = false;
		this.activeCro = false;
		this.activeSlo = false;
		this.orderNumber = null;
		this.isPosted = false;
		this.modifiedBy = '';
		this.backgroundUrl = '';
		this.image1Url = '';
		this.image2Url = '';
		this.desktopImage = '';
		this.mobileImage = '';
		this.location = '';
		this.path = '';
		this.isModal = false;
		this.documentUrl = '';
		this.modalFile = '';
		this.productActionSliderItems = [];
	}
}
