import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromService from './service.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ServiceEffects } from './service.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromService.servicesFeatureKey, fromService.reducer),
		EffectsModule.forFeature([ServiceEffects]),
	],
})
export class ServiceStoreModule {}
