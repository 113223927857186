import { createAction, props } from '@ngrx/store';
import { ProductListItems } from 'src/app/shared/models/product-list-items';
import { ProductListFilter } from 'src/app/shared/models/product-list-filter';

// Load Product List Items
export const loadProductListItems = createAction(
	'[Product List] Load Product List Items Request',
	props<{ listFilter: ProductListFilter }>()
);
export const loadProductListItemsSuccess = createAction(
	'[Product API] Load Product List Items Success',
	props<{ productListItems: ProductListItems }>()
);
export const loadProductListItemsFailure = createAction(
	'[Product API] Load Product List Items Failure',
	props<{ error: any }>()
);
