import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/sr/environment';
import { ExchangeRate } from 'src/app/shared/models/exchange-rate';

@Injectable({
	providedIn: 'root',
})
export class ExchangeRateService {
	constructor(private http: HttpClient) {}

	getExchangeRates(): Observable<ExchangeRate[]> {
		return this.http.get<ExchangeRate[]>(environment.baseApiUrl + 'api/Orders/exchange-rates');
	}
}
