import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCustomer from './customer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerEffects } from './customer.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromCustomer.customersFeatureKey, fromCustomer.reducer),
		EffectsModule.forFeature([CustomerEffects]),
	],
})
export class CustomersStoreModule {}
