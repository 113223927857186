<div
	*ngIf="{
		isAuthenticated: authService.isAuthenticated$ | async,
		isDoneLoading: isDoneLoading$ | async,
		deactivatedMenuLoading: deactivatedMenuLoading$ | async
	} as observables"
>
	<div
		class="authenticating-loader"
		[ngClass]="{
			loading: !observables.isAuthenticated || !observables.isDoneLoading || observables.deactivatedMenuLoading
		}"
	>
		<img [src]="environment.localImagesLocation + 'ct4-logo-animacija.gif'" alt="B2B Logo" />
	</div>

	<div class="header" [ngClass]="{ toggled: showNavbar }">
		<app-header></app-header>
	</div>

	<div class="head-menu-cont" [ngClass]="{ toggled: showNavbar }">
		<div
			class="menu-icon"
			(click)="toggleNavbar()"
			[ngClass]="{ change: showNavbar, disabled: !observables.isAuthenticated }"
		>
			<span class="bar1"></span>
			<span class="bar2"></span>
			<span class="bar3"></span>
		</div>
	</div>

	<div *ngIf="environment.isOAuthEnabled" id="page-container" [ngClass]="{ toggled: showNavbar }">
		<div
			*ngIf="environment.isOAuthEnabled && observables.isAuthenticated"
			[ngClass]="{ toggled: showNavbar, 'logged-out': !observables.isAuthenticated }"
		></div>

		<div id="side-navigation" *ngIf="environment.isOAuthEnabled && observables.isAuthenticated">
			<app-navbar [showNavbar]="showNavbar"></app-navbar>
		</div>

		<div id="page-cont">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
