export class Group {
	id: number;
	title: string;
	featuresArray: string;
	isAdmin: boolean;

	constructor() {
		this.id = 0;
		this.title = '';
		this.featuresArray = '';
		this.isAdmin = false;
	}
}
