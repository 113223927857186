import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserLog } from '../../shared/models/user-log';
import * as UserLogsActions from './user-logs.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const userLogFeatureKey = 'userLog';

export interface UserLogState extends EntityState<UserLog> {
	// additional entities state properties
	statusState: StatusState;
	statusEntityState: StatusState;
}

export const adapter: EntityAdapter<UserLog> = createEntityAdapter<UserLog>();

export const initialState: UserLogState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
	statusEntityState: LoadingState.INIT,
});

export const userLogReducer = createReducer(
	initialState,
	on(UserLogsActions.loadUserLogs, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(UserLogsActions.loadUserLogsSuccess, (state, action) =>
		adapter.setAll(action.userLogs, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(UserLogsActions.loadUserLogsFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(UserLogsActions.loadUserLogsByUserName, state => {
		return {
			...state,
			statusEntityState: LoadingState.LOADING,
		};
	}),
	on(UserLogsActions.loadUserLogsByUserNameSuccess, (state, action) =>
		adapter.updateOne(action.userLog, {
			...state,
			statusEntityState: LoadingState.LOADED,
		})
	),
	on(UserLogsActions.loadUserLogsByUserNameFailure, (state, action) => {
		return {
			...state,
			statusEntityState: { error: action.error },
		};
	})
);

export function reducer(state: UserLogState | undefined, action: Action) {
	return userLogReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
