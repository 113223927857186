import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductFiltersState, productFiltersFeatureKey } from './product-filter.reducer';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectProductFiltersState = createFeatureSelector<ProductFiltersState>(productFiltersFeatureKey);

export const selectProductFilters = createSelector(
	selectProductFiltersState,
	(state: ProductFiltersState) => state.productFilters
);
export const selectProductFiltersLoading = createSelector(
	selectProductFiltersState,
	(state: ProductFiltersState) => state.statusState === LoadingState.LOADING
);
export const selectProductFiltersError = createSelector(selectProductFiltersState, (state: ProductFiltersState) =>
	getError(state.statusState)
);
