import { createAction, props } from '@ngrx/store';
import { ProductData } from '../../shared/models/product-data';

export const loadProductDataFiltered = createAction(
	'[Product Data Page] Load ProductData Filtered',
	props<{ searchItem: string; savedSearchItem: string }>()
);

export const loadProductDataFilteredSuccess = createAction(
	'[ProductData API] Loaded ProductData Success',
	props<{ productData: ProductData[]; savedSearchItem: string }>()
);

export const loadProductDataFilteredFailure = createAction(
	'[ProductData API] Loaded ProductData Failure',
	props<{ error: any }>()
);

export const resetSearchData = createAction(
	'[ProductData API] Set SearchItem',
	props<{ searchItem: string; productData: ProductData[] }>()
);
