import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { WebUser } from '../../shared/models/web-user';
import * as WebUserActions from './web-user.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const webUsersFeatureKey = 'webUsers';

export interface WebUserState extends EntityState<WebUser> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<WebUser> = createEntityAdapter<WebUser>();

export const initialState: WebUserState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

const webUserReducer = createReducer(
	initialState,
	on(WebUserActions.loadWebUsers, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(WebUserActions.loadWebUsersSuccess, (state, action) =>
		adapter.setAll(action.webUsers, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(WebUserActions.loadWebUsersFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(WebUserActions.addWebUser, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(WebUserActions.addWebUserSuccess, (state, action) =>
		adapter.addOne(action.webUser, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(WebUserActions.addWebUserFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(WebUserActions.updateWebUser, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(WebUserActions.updateWebUserSuccess, (state, action) =>
		adapter.updateOne(action.webUser, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(WebUserActions.updateWebUserFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(WebUserActions.deleteWebUser, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(WebUserActions.deleteWebUserSuccess, (state, action) =>
		adapter.removeOne(action.webUserId, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(WebUserActions.deleteWebUserFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: WebUserState | undefined, action: Action) {
	return webUserReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
