/* 🤖 this file was generated by svg-to-ts*/

export type myIcons =
	| 'checkmark'
	| 'down-small'
	| 'excel'
	| 'group'
	| 'main-page'
	| 'pdf'
	| 'pricelist'
	| 'products'
	| 'services'
	| 'text'
	| 'users'
	| 'zip';
export interface MyIcon {
	name: myIcons;
	data: string;
}
