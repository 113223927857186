import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Seller } from 'src/app/shared/models/seller';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class SellerService {
	constructor(private http: HttpClient) {}

	getSellers(): Observable<Seller[]> {
		return this.http.get<Seller[]>(environment.baseApiUrl + 'api/Seller');
	}

	addSeller(seller: Seller): Observable<Seller> {
		return this.http.post<Seller>(environment.baseApiUrl + 'api/Seller', seller);
	}

	updateSeller(seller: Update<Seller>): Observable<Update<Seller>> {
		return this.http.put<Update<Seller>>(environment.baseApiUrl + 'api/Seller', seller.changes).pipe(map(() => seller));
	}
}
