<ng-container *ngIf="translate && { webUsersLoading: webUsersLoading$ | async } as observables">
	<div class="web-users-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openWebUserModal(updateWebUserTemplate, null)"
			[disabled]="observables.webUsersLoading"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.webUsersLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.webUsersLoading">
			<p-table
				#webusertable
				[value]="webUsers$ | async"
				[autoLayout]="true"
				[responsive]="true"
				[(first)]="firstPageElementIndex"
				class="web-users-prime-table"
				[paginator]="true"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="[
					'id',
					'username',
					'password',
					'customerNo',
					'customerName',
					'ip',
					'lastDateModifiedPassword',
					'showQtyAsProtected'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="webusertable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" [style.width.px]="70">
							{{ translate.USER_ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'username'">
							{{ translate.USERNAME }}
							<p-sortIcon [field]="'username'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'password'">
							{{ translate.PASSWORD }}
							<p-sortIcon [field]="'password'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'customerNo'">
							{{ translate.CUSTOMER_LABEL }}
							<p-sortIcon [field]="'customerNo'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'customerName'">
							{{ translate.CUSTOMER_NAME }}
							<p-sortIcon [field]="'customerName'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'ip'">
							{{ translate.IP_ADDRESS }}
							<p-sortIcon [field]="'ip'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'lastDateModifiedPassword'" class="text-center" [style.width.px]="130">
							{{ translate.LAST_UPDATED }}
							<p-sortIcon [field]="'lastDateModifiedPassword'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'showQtyAsProtected'" class="text-center" [style.width.px]="130">
							{{ translate.SHOW_PROTECTED_QTYS }}
							<p-sortIcon [field]="'showQtyAsProtected'"></p-sortIcon>
						</th>
						<th>&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-user let-i="rowIndex">
					<tr>
						<td>
							<b>{{ user.id }}</b>
						</td>
						<td>{{ user.username }}</td>
						<td>{{ user.password }}</td>
						<td>{{ user.customerNo }}</td>
						<td>{{ user.customerName }}</td>
						<td>{{ user.ip }}</td>
						<td class="text-center">{{ user.lastDateModifiedPassword | date: 'dd.MM.yyyy' }}</td>
						<td class="text-center"><fa-icon icon="check" *ngIf="user.showQtyAsProtected"></fa-icon></td>
						<td class="text-right" [style.white-space]="'nowrap'">
							<button
								type="button"
								class="button button-gray mr-2"
								(click)="openWebUserModal(updateWebUserTemplate, user)"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
							<button type="button" class="button button-gray mr-2">
								<fa-icon [icon]="faPaperPlane" class="pr-1"></fa-icon>{{ translate.SEND_EMAIL }}
							</button>
							<button
								type="button"
								class="button button-gray button-delete"
								(click)="openConfirmationModal(deleteWebUserTemplate, user)"
							>
								<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<ng-template #updateWebUserTemplate>
		<div class="modal-header">
			<h4 class="modal-title">{{ webUserForm.value.id ? translate.EDIT_WEB_USER : translate.ADD_WEB_USER }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="updateWebUserModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="webUserForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-5 col-sm-12 form-control-text">
						{{ translate.USERNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-7 col-sm-12">
						<input
							type="text"
							formControlName="username"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && webUserForm.controls.username.errors }"
							maxlength="32"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && webUserForm.controls.username.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-5 col-sm-12 form-control-text">
						{{ translate.PASSWORD }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-7 col-sm-12">
						<input
							type="text"
							formControlName="password"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && webUserForm.controls.password.errors }"
							maxlength="32"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && webUserForm.controls.password.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-5 col-sm-12 form-control-text">
						{{ translate.CUSTOMER_NAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-7 col-sm-12">
						<p-dropdown
							#customerDropDown
							formControlName="customerNo"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && webUserForm.controls.customerNo.errors }"
							[style]="{ width: '100%' }"
							[options]="customerList"
							[filter]="true"
							[virtualScroll]="true"
							itemSize="30"
							(onChange)="loadSelectedWebUserLabel($event, customerDropDown)"
						></p-dropdown>
						<app-form-validation-error-msg
							*ngIf="submitted && webUserForm.controls.customerNo.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-5 col-sm-12 form-control-text">{{ translate.CUSTOMER_LABEL }}:</div>
					<div class="col-lg-7 col-sm-12">
						<input class="form-control" type="text" formControlName="customerNo" disabled="true" />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-5 col-sm-12 form-control-text">{{ translate.IP_ADDRESS }}:</div>
					<div class="col-lg-7 col-sm-12">
						<input class="form-control" type="text" formControlName="ip" />
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-5 form-control-text">{{ translate.SHOW_PROTECTED_QTYS }}:</div>
					<div class="col">
						<label class="chbx-container mr-3" [class.checked]="webUserForm.value.showQtyAsProtected">
							<input
								type="checkbox"
								formControlName="showQtyAsProtected"
								[(ngModel)]="webUserForm.value.showQtyAsProtected"
								[ngClass]="{ checked: webUserForm.value.showQtyAsProtected }"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-18" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateWebUserModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="webUserForm.dirty ? false : true || observables.webUsersLoading"
						(click)="submitWebUserForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>

	<!-- Delete Web User Confirm Modal -->
	<ng-template #deleteWebUserTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ translate.DELETE_WEB_USER_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deleteWebUserModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.DELETE_WEB_USER_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deleteWebUserModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" [disabled]="observables.webUsersLoading" class="button" (click)="deleteWebUser()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
