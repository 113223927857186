export class Administrator {
	id: number;
	name: string;
	surname: string;
	email: string;
	username: string;
	password: string;
	idRemote: string;
	idGroup: number;
	groupName: string;
	additionalFeaturesArray: string;
	lastDateModifiedPassword: string;

	constructor() {
		this.id = 0;
		this.name = '';
		this.surname = '';
		this.email = '';
		this.username = '';
		this.password = '';
		this.idRemote = '';
		this.idGroup = null;
		this.groupName = '';
		this.additionalFeaturesArray = '';
		this.lastDateModifiedPassword = '';
	}
}
