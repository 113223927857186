import { productExtrasFeatureKey, ProductExtraState, selectAll } from './product-extras.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { ProductExtra } from 'src/app/shared/models/product-extra';
import { productExtraType } from 'src/app/shared/enums/product-extra-type.enum';

export const selectProductExtraState = createFeatureSelector<ProductExtraState>(productExtrasFeatureKey);

export const selectProductExtrasLoading = createSelector(
	selectProductExtraState,
	state => state.statusState === LoadingState.LOADING
);

export const selectStoredProductId = createSelector(selectProductExtraState, state => state.productId);

export const selectAllProductExtras = createSelector(selectProductExtraState, selectAll);

// ProductFile selectors

export const selectProductFiles = createSelector(selectAllProductExtras, (productExtras: ProductExtra[]) =>
	productExtras.filter(extra => extra.type === productExtraType.FILE)
);

export const selectProductFileError = createSelector(selectProductExtraState, (state: ProductExtraState) =>
	getError(state.statusState)
);

// ProductLink selectors

export const selectProductLinks = createSelector(selectAllProductExtras, (productExtras: ProductExtra[]) =>
	productExtras.filter(extra => extra.type === productExtraType.URL)
);

export const selectProductLinkError = createSelector(selectProductExtraState, (state: ProductExtraState) =>
	getError(state.statusState)
);
