import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { Feature } from 'src/app/shared/models/feature';

@Injectable()
export class FeatureService {
	constructor(private http: HttpClient) {}

	getFeatures(): Observable<Feature[]> {
		return this.http.get<Feature[]>(environment.baseApiUrl + 'api/PortalFeature');
	}
}
