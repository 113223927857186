import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartLabelFormatter } from '../../../app/shared/utils/chart-label-formatter';

@Component({
	selector: 'app-browser-usage-chart',
	templateUrl: './browser-usage-chart.component.html',
	styleUrls: ['./browser-usage-chart.component.scss'],
})
export class BrowserUsageChartComponent implements OnInit {
	@Input() data: {
		labels: string[];
		datasets: {
			data: any[];
			backgroundColor: string[];
			hoverBackgroundColor: string[];
		}[];
	};
	options: any;
	plugin = ChartDataLabels;

	translate: any;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.translateService.get('DASHBOARD').subscribe((resp: any) => (this.translate = resp));
		this.options = {
			plugins: {
				datalabels: {
					formatter: (value: number, ctx: any) => {
						return chartLabelFormatter(value, ctx);
					},
					color: '#fff',
				},
				align: 'end',
				anchor: 'end',
				borderRadius: 4,
				backgroundColor: 'teal',
				font: {
					weight: 'bold',
				},
			},
			tooltips: {
				callbacks: {
					label: (tooltipItem, data) => {
						const allData = data.datasets[tooltipItem.datasetIndex].data;
						const tooltipLabel = data.labels[tooltipItem.index];
						const tooltipData = allData[tooltipItem.index];
						let total = 0;
						for (const i in allData) {
							if (allData.hasOwnProperty(i)) {
								total += allData[i];
							}
						}
						const tooltipPercentage = (tooltipData * 100) / total;

						return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage.toFixed(2) + '%)';
					},
				},
			},
		};
	}
}
