import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAllowedQuantityActions from './allowed-quantity.actions';
import { AllowedQuantityService } from '../../core/services/allowed-quantity.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AllowedQuantityEffects {
	constructor(
		private actions$: Actions,
		private allowedQuantityService: AllowedQuantityService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadAllowedQuantityEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAllowedQuantityActions.loadAllowedQuantity),
			mergeMap(() =>
				this.allowedQuantityService.getAllowedQuantity().pipe(
					map(allowedQuantity => fromAllowedQuantityActions.loadAllowedQuantitySuccess({ allowedQuantity })),
					catchError(error => of(fromAllowedQuantityActions.loadAllowedQuantityFailure({ error })))
				)
			)
		)
	);

	addAllowedQuantityEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAllowedQuantityActions.addAllowedQuantity),
			mergeMap(action =>
				this.allowedQuantityService.addAllowedQuantity(action.allowedQuantity).pipe(
					switchMap(allowedQuantity =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromAllowedQuantityActions.addAllowedQuantitySuccess({ allowedQuantity });
							}),
							catchError(error => of(fromAllowedQuantityActions.addAllowedQuantityFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateAllowedQuantityEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAllowedQuantityActions.updateAllowedQuantity),
			mergeMap(action =>
				this.allowedQuantityService.updateAllowedQuantity(action.allowedQuantity).pipe(
					switchMap(allowedQuantity =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromAllowedQuantityActions.updateAllowedQuantitySuccess({ allowedQuantity });
							}),
							catchError(error => of(fromAllowedQuantityActions.updateAllowedQuantityFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteAllowedQuantityEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAllowedQuantityActions.deleteAllowedQuantity),
			mergeMap(action =>
				this.allowedQuantityService.deleteAllowedQuantity(action.allowedQuantityId).pipe(
					switchMap(AllowedQuantityId =>
						this.translateService.get('SUCCESS.FORM_DELETE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromAllowedQuantityActions.deleteAllowedQuantitySuccess({ AllowedQuantityId });
							}),
							catchError(error => of(fromAllowedQuantityActions.deleteAllowedQuantityFailure({ error })))
						)
					)
				)
			)
		)
	);
}
