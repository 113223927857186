<ng-container *ngIf="translate && { administratorsLoading: administratorsLoading$ | async } as observables">
	<div class="administrators-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openAdministratorsModal(updateAdministratorTemplate, null)"
			[disabled]="observables.administratorsLoading"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.administratorsLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.administratorsLoading">
			<p-table
				#admintable
				[value]="administrators$ | async"
				[autoLayout]="true"
				class="administrators-prime-table"
				[paginator]="true"
				[rows]="10"
				[(first)]="firstPageElementIndex"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="['id', 'name', 'surname', 'email', 'username', 'groupName', 'active']"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="admintable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'name'">
							{{ translate.FNAME }}
							<p-sortIcon [field]="'name'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'surname'">
							{{ translate.LNAME }}
							<p-sortIcon [field]="'surname'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'email'">
							{{ translate.EMAIL }}
							<p-sortIcon [field]="'email'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'username'">
							{{ translate.UNAME }}
							<p-sortIcon [field]="'username'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'groupName'">
							{{ translate.GROUP }}
							<p-sortIcon [field]="'groupName'"></p-sortIcon>
						</th>
						<th [style.width.px]="100">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-user let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ user.id }}</b>
						</td>
						<td>{{ user.name }}</td>
						<td>{{ user.surname }}</td>
						<td>{{ user.email }}</td>
						<td>{{ user.username }}</td>
						<td>{{ user.groupName }}</td>
						<td class="text-right">
							<button
								type="button"
								class="button button-gray"
								(click)="openAdministratorsModal(updateAdministratorTemplate, user)"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<!-- Add/edit administrator modal -->
	<ng-template #updateAdministratorTemplate>
		<div class="modal-header">
			<h4 class="modal-title">{{ administratorForm.value.id ? translate.EDIT_ADMIN : translate.ADD_ADMIN }}</h4>
			<button
				type="button"
				class="close pull-right"
				(click)="updateAdministratorModal.hide(); isUserAvailableMessage = ''"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<form [formGroup]="administratorForm" (ngSubmit)="submitAdministratorForm()">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.FNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="name"
							[ngClass]="{ 'is-invalid': submitted && administratorForm.controls.name.errors }"
							maxlength="64"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && administratorForm.controls.name.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.LNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="surname"
							[ngClass]="{ 'is-invalid': submitted && administratorForm.controls.surname.errors }"
							maxlength="64"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && administratorForm.controls.surname.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.EMAIL }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="email"
							formControlName="email"
							[ngClass]="{ 'is-invalid': submitted && administratorForm.controls.email.errors }"
							maxlength="64"
						/>

						<app-form-validation-error-msg
							*ngIf="submitted && administratorForm.controls.email.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.UNAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							class="form-control"
							type="text"
							formControlName="username"
							[ngClass]="{ 'is-invalid': submitted && administratorForm.controls.username.errors }"
							(input)="filterUsernames($event.target.value)"
							maxlength="32"
						/>
						<span [className]="isUserAvailableMessageClass" *ngIf="isUserAvailableMessage">{{
							isUserAvailableMessage
						}}</span>

						<app-form-validation-error-msg
							*ngIf="submitted && administratorForm.controls.username.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateErrors.FORM_VALIDATIONS"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.GROUP }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<p-dropdown
							type="text"
							styleClass="form-control"
							[ngClass]="{ 'is-invalid': submitted && administratorForm.controls.idGroup.errors }"
							formControlName="idGroup"
							[style.max-width.px]="400"
							[options]="groupDropdown"
							[filter]="true"
							(onChange)="groupNameUpdate(); getAdditionalFeaturesArrayForGroup(this.administratorForm.value.idGroup)"
						></p-dropdown>
						<app-form-validation-error-msg
							*ngIf="submitted && administratorForm.controls.idGroup.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>

				<hr />
				<div class="row list" *ngIf="this.administratorForm.value.idGroup">
					<div class="col-lg-6 col-sm-12" *ngFor="let item of features">
						<label class="chbx-container" [class.checked]="item.checked">
							{{ translateFeatures[item.identifier] ? translateFeatures[item.identifier] : item.identifier + '!' }}
							<input
								type="checkbox"
								[ngClass]="{ checked: item.checked }"
								(change)="item.checked = !item.checked; featuresChanged = true"
								[disabled]="item.disabled"
							/>
							<span class="chbx-checkmark"
								><svg-icon class="modal-form-svg-icon-mb-12" key="checkmark"></svg-icon
							></span>
						</label>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="updateAdministratorModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="submit"
						[disabled]="
							administratorForm.dirty || featuresChanged
								? false
								: true || usernameFieldDisabled || observables.administratorsLoading
						"
						class="button"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
