import { menuFeatureKey, MenuState, selectAll } from './menu.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';

export const selectMenuState = createFeatureSelector<MenuState>(menuFeatureKey);

export const selectAllDeactivatedMenu = createSelector(selectMenuState, selectAll);

export const selectDeactivatedMenuLoading = createSelector(
	selectMenuState,
	state => state.statusState === LoadingState.LOADING
);

export const selectDeactivatedMenuError = createSelector(selectMenuState, (state: MenuState) =>
	getError(state.statusState)
);
