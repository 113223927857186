export class ProductFilters {
	group: GroupFilter[];
	price: PriceFilter[];
	manufacturer: ManufacturerFilter[];
	offer: OfferFilter[];
	attributes: AttributeFilter[];
	warranty: WarrantyFilter[];
}

export class GroupFilter {
	productGroup: string;
	productGroupName: string;
	order: number;
	selected: boolean;
}

export class PriceFilter {
	minPrice: number;
	maxPrice: number;
}

export class ManufacturerFilter {
	manufacturerName: string;
	manufacturerID: string;
	selected: boolean;
}

export class OfferFilter {
	specialOffer: number;
	specialOfferValue: string;
	selected: boolean;
}

export class AttributeFilter {
	productGroup: string;
	attributeCode: string;
	description: string;
	descriptionLocal: string;
	isAdvancedFilter: boolean;
	isSliderFilter: boolean;
	order: number;
	values: AttributeFilterValue[];
}

export class AttributeFilterValue {
	optionValue: string;
	optionValueLocal: string;
	selected: boolean;
}

export class WarrantyFilter {
	warranty: string;
	selected: boolean;
}
