import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ServicesActions from './service.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';
import { Service } from 'src/app/shared/models/service';

export const servicesFeatureKey = 'services';

export interface ServiceState extends EntityState<Service> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Service> = createEntityAdapter<Service>({
	selectId: (service: Service) => service.id,
});

export const initialState: ServiceState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const reducer = createReducer(
	initialState,
	on(ServicesActions.loadServices, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServicesActions.loadServiceSuccess, (state, action) =>
		adapter.setAll(action.services, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServicesActions.loadServicesFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ServicesActions.addService, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServicesActions.addServiceSuccess, (state, action) =>
		adapter.addOne(action.service, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServicesActions.addServiceFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ServicesActions.updateService, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServicesActions.updateServiceSuccess, (state, action) =>
		adapter.updateOne(action.service, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServicesActions.updateServiceFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	}),
	on(ServicesActions.deleteService, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ServicesActions.deleteServiceSuccess, (state, action) =>
		adapter.removeOne(action.id, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ServicesActions.deleteServiceFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
