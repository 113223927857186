import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileComponent } from './my-profile.component';

import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
	declarations: [MyProfileComponent],
	imports: [CommonModule, SharedModule, AppRoutingModule],
})
export class MyProfileModule {}
