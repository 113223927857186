import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromAdministrator from './administrator.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AdministratorEffects } from './administrator.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromAdministrator.administratorsFeatureKey, fromAdministrator.reducer),
		EffectsModule.forFeature([AdministratorEffects]),
	],
})
export class AdministratorsStoreModule {}
