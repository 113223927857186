import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProductExtra } from '../../shared/models/product-extra';

export const storeProductId = createAction('[ProductExtras API Store ProductId]', props<{ productId: string }>());

export const loadProductExtras = createAction(
	'[Product Extras Page] Load ProductExtras',
	props<{ productId: string }>()
);
export const loadProductExtrasSuccess = createAction(
	'[ProductExtras API] Loaded ProductExtras Success',
	props<{ productExtras: ProductExtra[]; productId: string }>()
);
export const loadProductExtrasFailure = createAction(
	'[ProductExtras API] Loaded ProductExtras Failure',
	props<{ error: any }>()
);

// ProductFile actions

export const addProductFiles = createAction(
	'[Product Files Page] Add Product Files',
	props<{ productFiles: ProductExtra[] }>()
);
export const addProductFilesSuccess = createAction(
	'[ProductFiles API] Add Product Files Success',
	props<{ productFiles: ProductExtra[] }>()
);
export const addProductFilesFailure = createAction(
	'[ProductFiles API] Add Product File Failure',
	props<{ error: any }>()
);

export const deleteProductFile = createAction('[ProductFiles API] Delete Product File', props<{ id: number }>());
export const deleteProductFileSuccess = createAction(
	'[ProductFiles API] Remove Product File Success',
	props<{ id: number }>()
);
export const deleteProductFileFailure = createAction(
	'[ProductFiles API] Remove Product File Failure',
	props<{ error: any }>()
);

// ProductLink actions

export const addProductLink = createAction(
	'[Product Links Page] Add Product Link',
	props<{ productLink: ProductExtra }>()
);
export const addProductLinkSuccess = createAction(
	'[ProductLinks API] Add Product Link Success',
	props<{ productLink: ProductExtra }>()
);
export const addProductLinkFailure = createAction(
	'[ProductLinks API] Add Product Link Failure',
	props<{ error: any }>()
);

export const updateProductLink = createAction(
	'[ProductLink Page] Update ProductLink',
	props<{ productLink: Update<ProductExtra> }>()
);
export const updateProductLinkSuccess = createAction(
	'[ProductLink API] Update ProductLink Success',
	props<{ productLink: Update<ProductExtra> }>()
);
export const updateProductLinkFailure = createAction(
	'[ProductLink API] Update ProductLink Failure',
	props<{ error: any }>()
);

export const removeProductLink = createAction('[ProductLinks API] Remove Product Link', props<{ id: number }>());
export const removeProductLinkSuccess = createAction(
	'[ProductLinks API] Remove Product Link Success',
	props<{ id: number }>()
);
export const removeProductLinkFailure = createAction(
	'[ProductLinks API] Remove Product Link Failure',
	props<{ error: any }>()
);
