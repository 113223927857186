import { Component, OnInit } from '@angular/core';

import * as administratorSelectors from '../root-store/administrators-store/administrator.selectors';
import * as administratorActions from '../root-store/administrators-store/administrator.actions';
import { AdministratorState } from '../root-store/administrators-store/administrator.reducer';

import * as groupSelectors from '../root-store/groups-store/group.selectors';
import * as groupActions from '../root-store/groups-store/group.actions';
import { GroupState } from '../root-store/groups-store/group.reducer';

import * as featureSelectors from '../root-store/features-store/feature.selectors';
import * as featureActions from '../root-store/features-store/feature.actions';
import { FeatureState } from '../root-store/features-store/feature.reducer';

import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { Administrator } from '../shared/models/administrator';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Feature } from '../shared/models/feature';
import { Group } from '../shared/models/group';
import { MenuItemService } from '../core/services/menu-item.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/sr/environment';

@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
	ngUnsubscribe: Subject<object> = new Subject();

	translate: any;
	translateFeatures: any;

	currentUser: Administrator;
	administratorsLoading$: Observable<boolean>;

	group$: Observable<Group>;

	features$: Observable<Feature[]>;
	features: Feature[];
	featuresLoading$: Observable<boolean>;
	environment = environment;
	canDeactivate = false;

	constructor(
		private translateService: TranslateService,
		private administratorStore: Store<AdministratorState>,
		private featureStore: Store<FeatureState>,
		private groupStore: Store<GroupState>,
		private menuItemsService: MenuItemService,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.translateService.get('MY_PROFILE').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('GROUPS').subscribe((resp: any) => (this.translateFeatures = resp));

		this.administratorStore.dispatch(administratorActions.loadCurrentAdministratorDetails());
		this.featureStore.dispatch(featureActions.loadFeatures());
		this.groupStore.dispatch(groupActions.loadGroups());

		this.administratorsLoading$ = this.administratorStore.select(administratorSelectors.selectAdministratorsLoading);
		this.featuresLoading$ = this.featureStore.select(featureSelectors.selectFeaturesLoading);

		this.administratorStore
			.select(administratorSelectors.selectCurrentUser)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(user => {
				if (user) {
					this.currentUser = user;
					this.group$ = this.groupStore.select(groupSelectors.selectGroupById, { id: this.currentUser?.idGroup });
					this.features$ = this.featureStore.select(featureSelectors.selectAllFeatures);

					combineLatest([this.features$, this.group$]).subscribe(([features, groups]) => {
						this.features = cloneDeep(features);
						if (features && groups && (user.additionalFeaturesArray || groups.featuresArray)) {
							const featuresIds = user.additionalFeaturesArray?.split(',');
							const baseGroupFeatures = groups?.featuresArray?.split(',');
							for (const feature of this.features) {
								feature.checked =
									featuresIds?.some(id => Number(id) === feature.id) ||
									baseGroupFeatures?.some(id => Number(id) === feature.id);
							}
						}
					});
					this.canDeactivate = environment.deactivateFunction.includes(this.currentUser.id);
				}
			});
	}

	/**
	 * Deactivates selected components
	 */
	deactivateComponents(): void {
		// 31 - id of Easy Web
		const array = [31];
		this.menuItemsService
			.deactivateMenuItems(array)
			.subscribe(() => this.toastr.success(this.translate.COMPONENTS_SUCCESSFULLY_DEACTIVATED));
	}
}
