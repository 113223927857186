import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import { ErrorLog } from 'src/app/shared/models/error-log';

@Injectable()
export class LoggingService {
	constructor(private http: HttpClient) {}

	logError(errorLog: ErrorLog): Observable<boolean> {
		return this.http.post<boolean>(environment.baseApiUrl + 'api/logging/error', errorLog);
	}
}
