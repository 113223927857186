<ng-container *ngIf="translate && { serviceLoading: serviceLoading$ | async } as observables">
	<div class="service-cont">
		<div class="page-title">
			<h2>{{ translate.TITLE }}</h2>
			<p>{{ translate.PAGE_DESCRIPTION }}</p>
		</div>
		<button
			type="button"
			class="button my-3"
			(click)="openRegisteredServiceModal(registeredServiceTemplate, null)"
			[disabled]="observables.serviceLoading"
		>
			<fa-icon icon="plus"></fa-icon> {{ 'ADD' | translate }}
		</button>
		<app-skeleton-screen
			*ngIf="observables.serviceLoading && columns"
			[columns]="columns"
			[translate]="translate"
		></app-skeleton-screen>
		<div *ngIf="!observables.serviceLoading">
			<p-table
				#registeredServiceTable
				[value]="services$ | async"
				[autoLayout]="true"
				[paginator]="true"
				[(first)]="firstPageElementIndex"
				[rows]="10"
				[rowsPerPageOptions]="[5, 10, 20, 50]"
				[globalFilterFields]="[
					'id',
					'manufacturer',
					'productType',
					'serviceName',
					'city',
					'phone',
					'address',
					'workHour',
					'web'
				]"
			>
				<ng-template pTemplate="caption">
					<div class="global-filter-cont">
						<fa-icon icon="search"></fa-icon>
						<input
							type="text"
							class="form-control"
							pInputText
							size="50"
							[placeholder]="'GLOBAL_FILTER' | translate"
							data-tid="globalFilter"
							(input)="registeredServiceTable.filterGlobal($event.target.value, 'contains')"
						/>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th [pSortableColumn]="'id'" class="text-center" [style.width.px]="70">
							{{ translate.ID }}
							<p-sortIcon [field]="'id'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'manufacturer'">
							{{ translate.MANUFACTURER }}
							<p-sortIcon [field]="'manufacturer'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'productType'">
							{{ translate.PRODUCT_TYPE }}
							<p-sortIcon [field]="'productType'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'city'">
							{{ translate.CITY }}
							<p-sortIcon [field]="'city'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'serviceName'">
							{{ translate.SERVICE_NAME }}
							<p-sortIcon [field]="'serviceName'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'address'">
							{{ translate.ADDRESS }}
							<p-sortIcon [field]="'address'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'phone'">
							{{ translate.PHONE }}
							<p-sortIcon [field]="'phone'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'workHour'">
							{{ translate.WORK_HOUR }}
							<p-sortIcon [field]="'workHour'"></p-sortIcon>
						</th>
						<th [pSortableColumn]="'web'">
							{{ translate.WEB }}
							<p-sortIcon [field]="'web'"></p-sortIcon>
						</th>
						<th [style.width.px]="200">&nbsp;</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-service let-i="rowIndex">
					<tr>
						<td class="text-center">
							<b>{{ service.id }}</b>
						</td>
						<td>{{ service.manufacturer }}</td>
						<td>{{ service.productType }}</td>
						<td>{{ service.city }}</td>
						<td>{{ service.serviceName }}</td>
						<td>{{ service.address }}</td>
						<td>{{ service.phone }}</td>
						<td>{{ service.workHour }}</td>
						<td>{{ service.web }}</td>
						<td>
							<button
								type="button"
								class="button button-gray mr-1"
								(click)="openRegisteredServiceModal(registeredServiceTemplate, service)"
							>
								<fa-icon icon="pencil-alt" class="pr-1"></fa-icon>{{ 'EDIT' | translate }}
							</button>
							<button
								type="button"
								class="button button-gray button-delete"
								(click)="openConfirmationModal(deleteRegisteredServiceTemplate, service)"
							>
								<fa-icon icon="times" class="pr-1"></fa-icon>{{ 'DELETE' | translate }}
							</button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td [attr.colspan]="columns.length" class="empty-msg">
							{{ 'NO_DATA' | translate }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- Add/Edit service modal -->
	<ng-template #registeredServiceTemplate>
		<div class="modal-header">
			<h4 class="modal-title pull-left" *ngIf="serviceForm.value.id">{{ translate.EDIT_SERVICE }}</h4>
			<h4 class="modal-title pull-left" *ngIf="!serviceForm.value.id">{{ translate.ADD_SERVICE }}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="registeredServiceModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="serviceForm">
			<div class="modal-body">
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.MANUFACTURER }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.manufacturer.errors }"
							formControlName="manufacturer"
							maxlength="100"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.manufacturer.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.PRODUCT_TYPE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.productType.errors }"
							formControlName="productType"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.productType.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.CITY }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.city.errors }"
							formControlName="city"
							maxlength="100"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.city.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.SERVICE_NAME }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceName.errors }"
							formControlName="serviceName"
							maxlength="200"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.serviceName.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">
						{{ translate.ADDRESS }}: <span class="required-field">*</span>
					</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }"
							formControlName="address"
							maxlength="255"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.address.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.PHONE }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.phone.errors }"
							formControlName="phone"
							maxlength="30"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.phone.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.WORK_HOUR }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.workHour.errors }"
							formControlName="workHour"
							maxlength="20"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.workHour.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-lg-4 col-sm-12 form-control-text">{{ translate.WEB }}:</div>
					<div class="col-lg-8 col-sm-12">
						<input
							type="text"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && serviceForm.controls.web.errors }"
							formControlName="web"
							maxlength="50"
						/>
						<app-form-validation-error-msg
							*ngIf="submitted && serviceForm.controls.web.errors; let errors"
							class="invalid-feedback"
							[errors]="errors"
							[errorMessages]="translateFormValidationErrorMsgs"
						>
						</app-form-validation-error-msg>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="required-field-text">
					{{ translateFormValidationErrorMsgs.REQUIRED_FIELDS_DESCRIPTION }} <span class="text-red">*</span>
				</div>
				<div class="modal-footer-buttons">
					<button type="button" class="button button-gray" (click)="registeredServiceModal.hide()">
						{{ 'CLOSE' | translate }}
					</button>
					<button
						type="button"
						class="button"
						[disabled]="!serviceForm.dirty || observables.serviceLoading"
						(click)="submitRegisteredServiceForm()"
					>
						{{ 'SUBMIT' | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
	<!-- Delete registered service confirm modal -->
	<ng-template #deleteRegisteredServiceTemplate>
		<div class="modal-header">
			<h4 class="modal-title">
				<span>{{ translate.DELETE_REGISTERED_SERVICE_TITLE }}</span>
			</h4>
			<button type="button" class="close pull-right" (click)="deleteRegisteredServiceModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<p>{{ translate.REGISTERED_SERVICE_QUANTITY_BODY }}</p>
		</div>
		<div class="modal-footer modal-footer-remove">
			<div class="modal-footer-buttons">
				<button type="button" class="button button-gray" (click)="deleteRegisteredServiceModal.hide()">
					{{ 'CLOSE' | translate }}
				</button>
				<button type="button" class="button" (click)="deleteRegisteredService()">
					{{ 'SUBMIT' | translate }}
				</button>
			</div>
		</div>
	</ng-template>
</ng-container>
