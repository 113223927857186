import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromProductExtraActions from './product-extras.actions';
import { ProductExtraService } from '../../core/services/product-extra.service';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { fileExtensionParser } from 'src/app/shared/utils/file-extension-parser';

@Injectable()
export class ProductExtraEffects {
	constructor(
		private actions$: Actions,
		private productExtraService: ProductExtraService,
		private toastr: ToastrService,
		private translateService: TranslateService
	) {}

	loadProductExtrasEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.loadProductExtras),
			mergeMap(action =>
				this.productExtraService.getProductExtras(action.productId).pipe(
					map(productExtras => {
						productExtras = productExtras.map(productExtra => {
							productExtra.fileName
								? (productExtra.fileExtension = fileExtensionParser(productExtra.fileName))
								: (productExtra.fileExtension = '');

							return productExtra;
						});

						return fromProductExtraActions.loadProductExtrasSuccess({
							productExtras,
							productId: action.productId,
						});
					}),
					catchError(error => of(fromProductExtraActions.loadProductExtrasFailure({ error })))
				)
			)
		)
	);

	// ProductFile effects

	addProductFilesEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.addProductFiles),
			mergeMap(action =>
				this.productExtraService.uploadProductFiles(action.productFiles).pipe(
					switchMap(productFiles => {
						productFiles = productFiles.map(productExtra => {
							productExtra.fileName
								? (productExtra.fileExtension = fileExtensionParser(productExtra.fileName))
								: (productExtra.fileExtension = '');

							return productExtra;
						});

						return this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(response => {
								this.toastr.success(response);
								return fromProductExtraActions.addProductFilesSuccess({ productFiles });
							}),
							catchError(error => of(fromProductExtraActions.addProductFilesFailure({ error })))
						);
					})
				)
			)
		)
	);

	deleteProductFileEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.deleteProductFile),
			mergeMap(action =>
				this.productExtraService.deleteProductFile(action.id).pipe(
					switchMap(id =>
						this.translateService.get('SUCCESS.FILE_REMOVE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductExtraActions.deleteProductFileSuccess({ id });
							}),
							catchError(error => of(fromProductExtraActions.deleteProductFileFailure({ error })))
						)
					)
				)
			)
		)
	);

	// ProductLink effects

	addProductLinkEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.addProductLink),
			mergeMap(action =>
				this.productExtraService.addProductLink(action.productLink).pipe(
					switchMap(productLink =>
						this.translateService.get('SUCCESS.FORM_SUBMIT').pipe(
							map(successMessage => {
								this.toastr.success(successMessage);
								return fromProductExtraActions.addProductLinkSuccess({ productLink });
							}),
							catchError(error => of(fromProductExtraActions.addProductLinkFailure({ error })))
						)
					)
				)
			)
		)
	);

	updateProductLinkEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.updateProductLink),
			mergeMap(action =>
				this.productExtraService.updateProductLink(action.productLink).pipe(
					switchMap(productLink =>
						this.translateService.get('SUCCESS.FORM_EDIT').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductExtraActions.updateProductLinkSuccess({ productLink });
							}),
							catchError(error => of(fromProductExtraActions.updateProductLinkFailure({ error })))
						)
					)
				)
			)
		)
	);

	deleteProductLinkEffect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromProductExtraActions.removeProductLink),
			mergeMap(action =>
				this.productExtraService.deleteProductLink(action.id).pipe(
					switchMap(id =>
						this.translateService.get('SUCCESS.FILE_REMOVE').pipe(
							map(translate => {
								this.toastr.success(translate);
								return fromProductExtraActions.removeProductLinkSuccess({ id });
							}),
							catchError(error => of(fromProductExtraActions.removeProductLinkFailure({ error })))
						)
					)
				)
			)
		)
	);
}
