import { createAction, props } from '@ngrx/store';

import { ProductGroup } from '../../shared/models/product-group';

export const loadProductGroupsNav = createAction('[Product Groups Page] Load Nav Product Groups');

export const loadProductGroupsNavSuccess = createAction(
	'[Product Groups Nav API] Loaded Nav Product Groups Success',
	props<{ productGroups: ProductGroup[] }>()
);

export const loadProductGroupsNavFailure = createAction(
	'[Product Groups Nav API] Loaded Nav Product Groups Failure',
	props<{ error: any }>()
);
