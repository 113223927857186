import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViberPromotion } from 'src/app/shared/models/viber-promotion';
import { environment } from 'src/environments/sr/environment';

@Injectable({
	providedIn: 'root',
})
export class ViberPromotionsService {
	constructor(private http: HttpClient) {}

	getViberPromotions(): Observable<ViberPromotion[]> {
		return this.http.get<ViberPromotion[]>(`${environment.baseApiUrl}api/ViberPromotions`);
	}

	addViberPromotion(promotion: ViberPromotion) {
		return this.http.post<ViberPromotion[]>(environment.baseApiUrl + 'api/ViberPromotions', promotion);
	}

	updateViberPromotion(promotion: Update<ViberPromotion>) {
		return this.http
			.put<Update<ViberPromotion>[]>(environment.baseApiUrl + 'api/ViberPromotions', promotion.changes)
			.pipe(
				map(viberPromotions => {
					const promotions: Update<ViberPromotion>[] = [];
					for (const pr of viberPromotions) {
						const temp: Update<ViberPromotion> = { id: null, changes: {} };
						temp.id = pr.id;
						temp.changes = pr as Partial<ViberPromotion>;
						promotions.push(temp);
					}

					return promotions;
				})
			);
	}
}
