import { groupsFeatureKey, GroupState, selectAll } from './group.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState, getError } from '../status-store/status.reducer';
import { Group } from 'src/app/shared/models/group';

export const selectGroupState = createFeatureSelector<GroupState>(groupsFeatureKey);

export const selectAllGroups = createSelector(selectGroupState, selectAll);

export const selectGroupsLoading = createSelector(
	selectGroupState,
	state => state.statusState === LoadingState.LOADING
);

/**
 * Selects group by group id.
 * @return Group entity with the selected group id.
 */
export const selectGroupById = createSelector(selectAllGroups, (groups: Group[], params) =>
	groups.find(group => group.id === params.id)
);

export const selectGroupsError = createSelector(selectGroupState, (state: GroupState) => getError(state.statusState));
