import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromPromotionSlider from './promotion-slider.reducer';
import { PromotionSliderEffects } from './promotion-slider.effects';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromPromotionSlider.promotionSlidersFeatureKey, fromPromotionSlider.reducer),
		EffectsModule.forFeature([PromotionSliderEffects]),
	],
})
export class PromotionSlidersStoreModule {}
