import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromExchangeRatesActions from './exchange-rates.actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ExchangeRateService } from 'src/app/core/services/exchange-rate.service';

@Injectable()
export class ExchangeRateEffects {
	constructor(private actions$: Actions, private exchangeRateService: ExchangeRateService) {}

	loadExchangeRate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromExchangeRatesActions.loadExchangeRates),
			mergeMap(() =>
				this.exchangeRateService.getExchangeRates().pipe(
					map(exchangeRates => fromExchangeRatesActions.loadExchangeRatesSuccess({ exchangeRates })),
					catchError(error => of(fromExchangeRatesActions.loadExchangeRatesFailure({ error })))
				)
			)
		)
	);
}
