import { createAction, props } from '@ngrx/store';
import { MenuItemDB } from '../../shared/models/menu-item';

export const loadDeactivatedMenu = createAction('[Menu Page] Load Deactivated Menu');

export const loadDeactivatedMenuSuccess = createAction(
	'[Menu API] Loaded Deactivated Menu Success',
	props<{ Menu: MenuItemDB[] }>()
);

export const loadDeactivatedMenuFailure = createAction(
	'[Menu API] Loaded Deactivated Menu Failure',
	props<{ error: any }>()
);
