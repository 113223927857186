import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/sr/environment';
import {
	HomePage,
	LatestOrders,
	NumberOfPendingAndOrdersToday,
	OrdersOverview,
	TopPurchasedProducts,
} from 'src/app/shared/models/home-page';

@Injectable()
export class HomePageService {
	constructor(private http: HttpClient) {}

	getHomePageWidgetData(): Observable<HomePage> {
		return forkJoin({
			ordersOverview: this.http.get<OrdersOverview[]>(environment.baseApiUrl + 'api/HomePage/GetOrdersOverview'),
			latestOrders: this.http.get<LatestOrders[]>(environment.baseApiUrl + 'api/HomePage/GetLatestOrders'),
			topPurchasedProducts: this.http.get<TopPurchasedProducts[]>(
				environment.baseApiUrl + 'api/HomePage/GetTopPurchasedProducts'
			),
			numberOfPendingAndOrdersToday: this.http.get<NumberOfPendingAndOrdersToday>(
				environment.baseApiUrl + 'api/HomePage/GetNumberOfPendingAndOrdersToday'
			),
		});
	}
}
