import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Manufacturer } from '../../shared/models/manufacturer';
import * as ManufacturerActions from './manufacturer.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const manufacturersFeatureKey = 'manufacturers';

export interface ManufacturerState extends EntityState<Manufacturer> {
	// additional entities state properties
	statusState: StatusState;
}

export const adapter: EntityAdapter<Manufacturer> = createEntityAdapter<Manufacturer>();

export const initialState: ManufacturerState = adapter.getInitialState({
	// additional entity state properties
	statusState: LoadingState.INIT,
});

export const manufacturerReducer = createReducer(
	initialState,
	on(ManufacturerActions.loadManufacturers, state => {
		return {
			...state,
			statusState: LoadingState.LOADING,
		};
	}),
	on(ManufacturerActions.loadManufacturersSuccess, (state, action) =>
		adapter.setAll(action.manufacturers, {
			...state,
			statusState: LoadingState.LOADED,
		})
	),
	on(ManufacturerActions.loadManufacturersFailure, (state, action) => {
		return {
			...state,
			statusState: { error: action.error },
		};
	})
);

export function reducer(state: ManufacturerState | undefined, action: Action) {
	return manufacturerReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
