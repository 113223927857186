import { Action, createReducer, on } from '@ngrx/store';
import { ProductData } from '../../shared/models/product-data';
import * as ProductDataActions from './product-data.actions';
import { LoadingState, StatusState } from '../status-store/status.reducer';

export const productDataFeatureKey = 'productData';

export interface ProductDataState {
	productData: ProductData[];
	searchItem: string;
	productId: string;
	statusState: StatusState;
}

export const initialState: ProductDataState = {
	productData: null,
	searchItem: null,
	productId: '',
	statusState: LoadingState.INIT,
};

const productDataReducer = createReducer(
	initialState,
	on(ProductDataActions.loadProductDataFiltered, state => {
		return {
			...state,
			searchItem: '',
			statusState: LoadingState.LOADING,
		};
	}),
	on(ProductDataActions.loadProductDataFilteredSuccess, (state, action) => {
		return {
			...state,
			searchItem: action.savedSearchItem,
			productData: action.productData,
			statusState: LoadingState.LOADED,
		};
	}),
	on(ProductDataActions.loadProductDataFilteredFailure, (state, action) => {
		return {
			...state,
			searchItem: '',
			statusState: { error: action.error },
		};
	}),
	on(ProductDataActions.resetSearchData, (state, action) => {
		return {
			...state,
			searchItem: action.searchItem,
			productData: action.productData,
		};
	})
);

export function reducer(state: ProductDataState | undefined, action: Action) {
	return productDataReducer(state, action);
}
