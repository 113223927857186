import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { map, tap } from 'rxjs/operators';
import { canAccessPage } from 'src/app/shared/utils/can-access-page';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (
			localStorage.getItem('access_token') ||
			(this.authService.userRoles && !this.authService.userRoles.includes('AdminAccess'))
		) {
			setTimeout(() => {
				this.authService.logout();
				sessionStorage.setItem('emptyLocalStorage', '1');
				this.authService.navigateToLoginPage();
				return;
			}, 100);
		}
		return this.authService.canActivateProtectedRoutes$.pipe(
			map(value => {
				return value && canAccessPage(state.url, this.authService);
			}),
			tap(x => {
				console.log('You tried to go to ' + state.url + ' and this guard said ' + x);
				if (!x) this.authService.navigateToHomePage();
			})
		);
	}
}
