import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Promotion } from '../shared/models/promotion';
import { Observable, Subscription } from 'rxjs';
import * as promotionSelectors from '../root-store/promotions-store/promotion.selectors';
import * as promotionActions from '../root-store/promotions-store/promotion.actions';
import { PromotionState } from '../root-store/promotions-store/promotion.reducer';
import { Update } from '@ngrx/entity';
import { deeperCopy } from 'src/app/shared/utils/deeper-copy';
import { environment } from 'src/environments/sr/environment';
import { imageValidator, fileValidator } from '../shared/utils/file-validator';
import { fileToBase64 } from '../shared/utils/file-to-base64';
import { fileExtensionParser } from '../shared/utils/file-extension-parser';
import { preventImageCache } from '../shared/utils/add-timestamp-prevent-cache';

@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit {
	updatePromotionModal: BsModalRef;
	changePromotionStatusModal: BsModalRef;

	firstPageElementIndex = 0;

	translate: any;
	translateErrors: any;
	faExternalLinkAlt = faExternalLinkAlt;
	selectedPromotion: any;
	linkedToDetails: boolean;
	contentToDetails: boolean;
	promotionForm: any;
	keepGoing = true;
	smallImage: string | ArrayBuffer;
	bigImage: string | ArrayBuffer;
	file: string | ArrayBuffer;
	environment = environment;
	userSmallImage: string;
	userBigImage: string;
	userFile: string;
	selectedBigImage: any;
	selectedBigImageType: any;
	selectedSmallImage: any;
	selectedSmallImageType: any;
	userFileDownloadLink: string;
	selectedPromotionsCategories: any[] = ['active'];

	promotionsLoading$: Observable<boolean>;
	promotions$: Observable<Promotion[]>;
	promotionsSubscriber: Subscription;

	currentPromotion: Promotion = new Promotion();
	customErrors = { required: true };
	fileSelected: boolean;

	columns: any[] = [
		{ name: 'id', title: 'ID', width: 5 },
		{ name: 'title', title: 'TITLE', width: 20 },
		{ name: 'dataType', title: 'TYPE', width: 12 },
		{ name: 'extra', title: 'SHOW_ON_MAIN', width: 15 },
		{ name: 'detailUrl', title: 'LINK_TO_DETAILS', width: 22 },
		{ name: 'date', title: 'DATE', width: 10 },
		{ name: 'active', title: 'STATUS', width: 15 },
	];

	submitted: boolean;

	constructor(
		private translateService: TranslateService,
		private store: Store<PromotionState>,
		private modalService: BsModalService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService
	) {}

	ngOnInit() {
		this.translateService.get('PROMOTIONS').subscribe((resp: any) => (this.translate = resp));
		this.translateService.get('ERROR.FORM_VALIDATIONS').subscribe((resp: any) => (this.translateErrors = resp));
		this.promotions$ = this.store.select(promotionSelectors.selectActivePromotions);
		this.promotionsLoading$ = this.store.select(promotionSelectors.selectActivePromotionsLoading);
		this.store.dispatch(promotionActions.loadActivePromotions());
	}

	openPromotionModal(updatePromotionTemplate: TemplateRef<any>, promotion: Promotion) {
		this.fileSelected = false;
		this.submitted = false;
		this.userSmallImage = null;
		this.userBigImage = null;
		this.userFile = null;
		this.userFileDownloadLink = null;
		this.selectedSmallImage = null;
		this.selectedSmallImageType = null;
		this.selectedBigImage = null;
		this.selectedBigImageType = null;
		this.linkedToDetails = promotion && (promotion.detailUrl || promotion.detailUrlDisplayText) ? true : false;
		this.contentToDetails = promotion && promotion.htmlContent ? true : false;
		let fromDate: any;
		let toDate: any;
		if (promotion) {
			if (promotion.fromDate && promotion.toDate) {
				fromDate = new Date(promotion.fromDate);
				toDate = new Date(promotion.toDate);
			} else {
				fromDate = '';
				toDate = '';
			}
		}
		this.promotionForm = this.formBuilder.group(
			promotion
				? {
						...promotion,
						title: [promotion.title, [Validators.required, Validators.maxLength(64)]],
						fromDate: [fromDate, [Validators.required]],
						toDate: [toDate, [Validators.required]],
				  }
				: {
						...new Promotion(),
						title: ['', [Validators.required, Validators.maxLength(64)]],
						fromDate: ['', [Validators.required]],
						toDate: ['', [Validators.required]],
				  }
		);

		if (this.promotionForm.value.bigImageType) {
			this.userBigImage = preventImageCache(
				environment.mediaLocation.promotionsImages +
					'image_big_' +
					this.promotionForm.value.id +
					'.' +
					this.promotionForm.value.bigImageType
			);
		}
		if (this.promotionForm.value.smallImageType) {
			this.userSmallImage = preventImageCache(
				environment.mediaLocation.promotionsImages +
					'image_small_' +
					this.promotionForm.value.id +
					'.' +
					this.promotionForm.value.smallImageType
			);
		}
		if (this.promotionForm.value.extraFile) {
			this.userFile = this.promotionForm.value.extraFile;
			this.userFileDownloadLink =
				environment.mediaLocation.promotionsFiles +
				'extra_file_' +
				this.promotionForm.value.id +
				fileExtensionParser(this.userFile);
		}
		this.updatePromotionModal = this.modalService.show(updatePromotionTemplate, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
		});
	}

	openConfirmationModal(changePromotionStatusTemplate: TemplateRef<any>, promotion) {
		this.selectedPromotion = promotion;
		this.changePromotionStatusModal = this.modalService.show(changePromotionStatusTemplate, { class: 'modal-md' });
	}

	submitPromotionForm() {
		this.submitted = true;

		if (this.promotionForm.value.id) {
			if (
				this.promotionForm.invalid ||
				(!this.selectedSmallImage && !this.userSmallImage) ||
				(!this.selectedBigImage && !this.userBigImage)
			) {
				this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
				return;
			}

			this.promotionForm.value.linkFile = this.promotionForm.value.linkFile ? 1 : 0;
			const tempPromotion: Update<Promotion> = {
				id: this.promotionForm.value.id,
				changes: this.promotionForm.value,
			};
			if (this.selectedBigImage) {
				tempPromotion.changes.bigImage = this.selectedBigImage;
				tempPromotion.changes.bigImageType = this.selectedBigImageType;
			}
			if (this.selectedSmallImage) {
				tempPromotion.changes.smallImage = this.selectedSmallImage;
				tempPromotion.changes.smallImageType = this.selectedSmallImageType;
			}
			this.store.dispatch(promotionActions.updatePromotion({ promotion: tempPromotion }));
		} else {
			if (this.promotionForm.invalid || !this.selectedSmallImage || !this.selectedBigImage) {
				this.toastr.warning(this.translateErrors.FORM_INCOMPLETE);
				return;
			}
			this.promotionForm.value.linkFile = this.promotionForm.value.linkFile ? 1 : 0;
			const tempPromotion: Promotion = this.promotionForm.value;
			tempPromotion.smallImage = this.selectedSmallImage;
			tempPromotion.smallImageType = this.selectedSmallImageType;
			tempPromotion.bigImage = this.selectedBigImage;
			tempPromotion.bigImageType = this.selectedBigImageType;
			const stringDate = new Date().toISOString();
			tempPromotion.date = stringDate;
			this.store.dispatch(promotionActions.addPromotion({ promotion: tempPromotion }));

			this.firstPageElementIndex = 0;
		}

		this.updatePromotionModal.hide();
	}

	changePromotionStatus() {
		const promotion = deeperCopy(this.selectedPromotion);
		promotion.active = !this.selectedPromotion.active;
		const tempPromotion: Update<Promotion> = {
			id: this.selectedPromotion.id,
			changes: promotion,
		};
		this.store.dispatch(promotionActions.updatePromotion({ promotion: tempPromotion }));
		this.changePromotionStatusModal.hide();
	}

	select(event: any, name: string, fileUploadViewChild: any) {
		const fileReader = new FileReader();
		for (const file of event.files) {
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				const uploadedFile = fileToBase64(fileReader);
				const uploadedFileExtension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
				if (name === 'file') {
					this.promotionForm.value.file = uploadedFile;
					this.promotionForm.value.extraFile = file.name;
					this.fileSelected = true;
				} else {
					const img = new Image();
					img.src = fileReader.result as string;
					img.onload = () => {
						switch (name) {
							case 'smallImage':
								if (fileValidator(file, 'size', environment.mediaRestrictions.promotions.small_image)) {
									if (imageValidator(img, 'sizeEq', environment.mediaRestrictions.promotions.small_image)) {
										this.selectedSmallImage = uploadedFile;
										this.selectedSmallImageType = uploadedFileExtension;
									} else {
										fileUploadViewChild.clear();
										this.remove('smallImage');
										this.translateService
											.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_EQ', {
												width: environment.mediaRestrictions.promotions.small_image.width,
												height: environment.mediaRestrictions.promotions.small_image.height,
											})
											.subscribe((text: string) => this.toastr.warning(text));
									}
								} else {
									fileUploadViewChild.clear();
									this.remove('smallImage');
									const size = environment.mediaRestrictions.promotions.small_image.size / 1024 + 'KB';
									this.translateService
										.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
											size,
										})
										.subscribe((text: string) => this.toastr.warning(text));
								}
								break;
							case 'bigImage':
								if (fileValidator(file, 'size', environment.mediaRestrictions.promotions.big_image)) {
									if (imageValidator(img, 'sizeLt', environment.mediaRestrictions.promotions.big_image)) {
										this.selectedBigImage = uploadedFile;
										this.selectedBigImageType = uploadedFileExtension;
									} else {
										fileUploadViewChild.clear();
										this.remove('bigImage');
										this.translateService
											.get('ERROR.FILE_RESTRICTIONS.IMAGE.RESOLUTION_EQ', {
												width: environment.mediaRestrictions.promotions.big_image.width,
												height: environment.mediaRestrictions.promotions.big_image.height,
											})
											.subscribe((text: string) => this.toastr.warning(text));
									}
								} else {
									fileUploadViewChild.clear();
									this.remove('bigImage');
									const size = environment.mediaRestrictions.promotions.big_image.size / 1024 + 'KB';
									this.translateService
										.get('ERROR.FILE_RESTRICTIONS.IMAGE.SIZE', {
											size,
										})
										.subscribe((text: string) => this.toastr.warning(text));
								}
								break;
						}
					};
				}
			};
		}
	}

	remove(name: string) {
		switch (name) {
			case 'smallImage':
				this.selectedSmallImage = null;
				this.selectedSmallImageType = null;
				break;
			case 'bigImage':
				this.selectedBigImage = null;
				this.selectedBigImageType = null;
				break;
			case 'file':
				this.promotionForm.value.file = null;
				break;
		}
	}

	categoryFilterChange(event: any) {
		if (!event.target.checked) {
			this.promotions$ = this.store.select(promotionSelectors.selectAllPromotions);
			this.promotionsLoading$ = this.store.select(promotionSelectors.selectPromotionsLoading);
			this.store.dispatch(promotionActions.loadPromotions());
		} else {
			this.promotions$ = this.store.select(promotionSelectors.selectActivePromotions);
			this.promotionsLoading$ = this.store.select(promotionSelectors.selectActivePromotionsLoading);
			this.store.dispatch(promotionActions.loadActivePromotions());
		}

		this.firstPageElementIndex = 0;
	}
}
