export class PriceList {
	id: number;
	name: string;
	file: string;
	description: string;
	date: string;
	active: boolean;
	fileString: string;

	constructor() {
		this.id = 0;
		this.name = '';
		this.file = null;
		this.description = '';
		this.date = '';
		this.active = true;
		this.fileString = '';
	}
}
