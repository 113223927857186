export enum SpecialOffer {
	L = 'IN_STOCK',
	A = 'ACTION',
	K = 'DISCOUNT_ON_QUANTITY',
	N = 'NEW_PRODUCT',
	U = 'SOON',
	P = 'BY_ORDER',
	E = 'END_OF_LIFE',
	C = 'CALL_FOR_PRICE',
	S = 'SPECIAL',
	V = 'VIBER_ACTION',
	W = 'VENDORLAND',
	FW = 'FOR_WEB',
}
